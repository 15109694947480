import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { Reseller } from '../models';
import { SearchParams } from '../models/objects/search-params';
import { isNil, omitBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ResellersService {
  constructor(private http: HttpClient) {}

  load(params?: SearchParams) {
    return this.http.get<IResponseSuccess<Reseller[]>>(
      `resellers${params ? '?' + generateSearchQuery(omitBy(params, isNil)) : ''}`,
    );
  }

  loadDetails(resellerId: number) {
    return this.http.get(`reseller/${resellerId}`);
  }

  create(item: Omit<Reseller, 'id'>) {
    return this.http.post('reseller', item) as Observable<
      IResponseSuccess<Reseller[]>
    >;
  }

  update(reseller: Reseller) {
    const { id: reseller_id, ...payload } = reseller;

    return this.http.put(`reseller/${reseller_id}`, payload);
  }

  delete(id: number) {
    return this.http.delete(`resellers/${id}`);
  }
}
