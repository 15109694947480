import { IPDFViewerApplication } from 'ngx-extended-pdf-viewer';

export const pdfDispatchEvent = (data: {
  eventName: string;
  options?: any;
}): void => {
  const PDFViewerApplication: IPDFViewerApplication = (window as any)
    .PDFViewerApplication;

  if (!PDFViewerApplication) {
    return;
  }

  PDFViewerApplication.eventBus.dispatch(data.eventName, data.options);
};

export const pdfDispatchOn = (data: {
  eventName: string;
  listener: (event: any) => void;
}): void => {
  const PDFViewerApplication: IPDFViewerApplication = (window as any)
    .PDFViewerApplication;

  if (!PDFViewerApplication) {
    return;
  }

  PDFViewerApplication.eventBus.on(data.eventName, data.listener);
};
