import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { last } from 'lodash';
import moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap } from 'rxjs/operators';
import * as txml from 'txml';

import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { observableSequence } from '../../../helpers';
import { HttpProxyService } from '../../../services';
import { FiscalPrinterLoadingService } from '../../../services/fiscal-printer-loading.service';
import {
  Command,
  FiscalPrinterCommandService,
} from '../fiscal-printer-command.service';
import { FiscalPrinterDriver } from '../fiscal-printer-driver';
import { FiscalPrinterError } from '../fiscal-printer-error';

import { getError } from './epson-fiscal-printer-errors';
import { EpsonFiscalPrinterResponse } from './epson-fiscal-printer-response';
import { EpsonFiscalPrinterRTStatusParser } from './epson-fiscal-printer-rt-status-parser';
import { EpsonFiscalPrinterStatusParser } from './epson-fiscal-printer-status-parser';

@Injectable({
  providedIn: 'root',
})
export class EpsonFiscalPrinterDriverService implements FiscalPrinterDriver {
  constructor(
    private http: HttpClient,
    private proxy: HttpProxyService,
    private dateFormatter: DateFormatterService,
    private loading: FiscalPrinterLoadingService,
    private commandService: FiscalPrinterCommandService,
  ) {}

  async getStatus(fiscalPrinterId: number) {
    return this.commandService
      .getStatusCommand(fiscalPrinterId)
      .pipe(
        switchMap((command) => {
          return this.execute(command, true).pipe(
            map((response) =>
              new EpsonFiscalPrinterStatusParser().parse(response),
            ),
          );
        }),
      )
      .toPromise();
  }

  async getRTStatus(fiscalPrinterId: number) {
    return this.commandService
      .getRTStatusCommand(fiscalPrinterId)
      .pipe(
        switchMap((command) => {
          return this.execute(command, true).pipe(
            map((response) =>
              new EpsonFiscalPrinterRTStatusParser().parse(response),
            ),
          );
        }),
      )
      .toPromise();
  }

  async toggleTrainingMode(printerId: number, status: boolean) {
    return this.commandService
      .getTrainingModeToggleCommand(printerId, status)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printNonTaxReceipt(printerId: number) {
    return this.commandService
      .getTestCommand(printerId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printReceipt(command: Command) {
    return this.execute(command)
      .pipe(
        map(({ addInfo, original }) => {
          return {
            description: original,
            number: addInfo.fiscalReceiptNumber,
            clousure_number: +addInfo.zRepNumber,
            document_date: this.dateFormatter.toServerFormat(
              moment(addInfo.fiscalReceiptDate, 'D/M/YYYY').toDate(),
            ),
          };
        }),
        catchError((error: FiscalPrinterError) => {
          return throwError({
            status: 'ERROR',
            description: error.message,
            notification: error.notification,
          });
        }),
      )
      .toPromise();
  }

  async dailyFiscalOperation(
    fiscalPrinterId: number,
    type: Array<'daily_closure' | 'daily_report'>,
  ) {
    return this.commandService
      .getDailyOperationCommand(fiscalPrinterId, type)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printReversalReceiptFiscal(printerId: number, invoiceId: number) {
    return this.commandService
      .getReversalCommand(printerId, invoiceId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(
            map(({ addInfo, original }) => {
              return {
                description: original,
                number: addInfo.fiscalReceiptNumber,
                clousure_number: +addInfo.zRepNumber,
                document_date: this.dateFormatter.toServerFormat(
                  moment(addInfo.fiscalReceiptDate, 'D/M/YYYY').toDate(),
                ),
              };
            }),
            catchError((error: FiscalPrinterError) => {
              return throwError({
                status: 'ERROR',
                description: error.message,
                notification: error.notification,
              });
            }),
          );
        }),
      )
      .toPromise();
  }

  async reset(fiscalPrinterId: number) {
    return this.commandService
      .getResetCommand(fiscalPrinterId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async reprint(fiscalPrinterId: number, invoiceId: number) {
    return this.commandService
      .getReprintCommand(fiscalPrinterId, invoiceId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async dailyReprint(fiscalPrinterId: number, date: string) {
    return this.commandService
      .getDailyReprintCommand(fiscalPrinterId, date)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printCustomCodeXml(command: Command) {
    return this.execute(command).toPromise();
  }

  /**
   * @description COMMANDS EXECUTION FUNCTION
   */
  private execute(command: Command, disableLoading = false) {
    if (!disableLoading) {
      this.loading.start();
    }

    return this.sendByHttpOrProxy(command).pipe(
      map((responses: Array<{ data: string }>) => {
        // Success flow

        if (!disableLoading) {
          this.loading.stop();
        }

        // PATCH: Se una risposta contiene FP_NO_ANSWER non deve essere parsata perchè andrà in errore
        if (responses.some(({ data }) => data.includes('FP_NO_ANSWER'))) {
          return null;
        }

        const parsedResponses = responses.map(
          ({ data }) =>
            txml.simplify(txml.parse(data))['soapenv:Envelope']['soapenv:Body'][
              'response'
            ],
        );

        const original = responses
          .map(({ data }) => data)
          .join('<!-- END RESPONSE -->');

        const parsed: EpsonFiscalPrinterResponse = last(parsedResponses);

        const errorResponse = parsedResponses.find(
          (parsedResponse) => parsedResponse._attributes.success === 'false',
        );

        if (errorResponse) {
          throw new FiscalPrinterError(
            original,
            getError(errorResponse._attributes.status),
          );
        }

        return { ...parsed, original, responses: parsedResponses };
      }),
      catchError((error: FiscalPrinterError) => {
        // Failure flow
        if (!disableLoading) {
          this.loading.stop();
        }

        if (!(error instanceof FiscalPrinterError)) {
          error = new FiscalPrinterError(JSON.stringify(error));
        }

        return throwError(error);
      }),
    );
  }

  private sendByHttpOrProxy(command: Command) {
    const { xml, ip, use_proxy } = command;

    if (use_proxy) {
      return this.proxy.send({
        requests: xml.map((xmlCommand) => {
          return {
            method: 'post',
            url: 'http://' + ip,
            payload: xmlCommand,
          };
        }),
      });
    }

    return observableSequence(
      xml.map((xmlCommand) => {
        return this.http
          .post('https://' + ip, xmlCommand, {
            responseType: 'text',
          })
          .pipe(
            map((xmlResponse) => {
              return { data: xmlResponse };
            }),
          );
      }),
    );
  }
}
