import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { removeNullishValues } from '../helpers';
import {
  EmailTemplatesType,
  IEmailTemplateCustomLabels,
} from '../features/commons/email-templates/models/email-template-custom.model';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateCustomService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`property/${propertyId}/templates_email/custom`);
  }

  loadLabels(property_id: number[], type: EmailTemplatesType = 'email') {
    return this.http.get<IResponseSuccess<IEmailTemplateCustomLabels[]>>(
      `properties/templates_email/custom?${generateSearchQuery(
        removeNullishValues({ property_id, type }),
      )}`,
    );
  }

  loadPreview(
    reservationId: number,
    templateCustomId: number,
    lang_iso_code = 'it',
  ) {
    return this.http.post(
      `reservation/${reservationId}/templates_email/custom/${templateCustomId}/preview?lang=${lang_iso_code}`,
      { reservation_id: reservationId, template_email_id: templateCustomId },
    );
  }

  send(reservationId: number, templateCustomId: number, lang_iso_code = 'it') {
    return this.http.post(
      `reservation/${reservationId}/templates_email/custom/${templateCustomId}/send?lang=${lang_iso_code}`,
      { reservation_id: reservationId, template_email_id: templateCustomId },
    );
  }
}
