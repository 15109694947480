import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { DigitalReceipt, DigitalReceiptChannel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DigitalReceiptService {
  constructor(private http: HttpClient) {}

  load(page: number) {
    return this.http.get<IResponseSuccess<DigitalReceipt[]>>(
      `admin/receipts_channels/vat_codes?${generateSearchQuery({ page })}`,
    );
  }

  loadReceiptsChannels() {
    return this.http.get<IResponseSuccess<DigitalReceiptChannel[]>>(
      `admin/receipts_channels`,
    );
  }

  create(digitalReceipt: DigitalReceipt) {
    return this.http.post<IResponseSuccess<[DigitalReceipt]>>(
      `admin/receipts_channels/vat_codes`,
      digitalReceipt,
    );
  }

  delete(digitalReceiptId: number) {
    return this.http.delete<IResponseSuccess>(
      `admin/receipts_channels/vat_codes/${digitalReceiptId}`,
    );
  }
}
