import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { IAddonRoot } from './../../models/objects/addons';
import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getAccommodationAddons = (state: State): IAddonRoot[] =>
  state.accommodationAddons;

export const selectAddonsState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('addons');

export const selectAllAddonsItems: (state: object) => IAddonRoot[] =
  featureAdapter.getSelectors(selectAddonsState).selectAll;

export const selectAddonsById = (id: string) =>
  createSelector(selectAllAddonsItems, (allAddons: IAddonRoot[]) => {
    if (allAddons) {
      return allAddons.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectAddonsSelect = createSelector(
  selectAllAddonsItems,
  (addons: IAddonRoot[]) => {
    return addons?.map(({ id, channel_addon }) => ({
      id,
      name: channel_addon.name,
    }));
  },
);

export const selectAddonsError: MemoizedSelector<object, any> = createSelector(
  selectAddonsState,
  getError,
);

export const selectAddonsIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectAddonsState, getIsLoading);

export const selectAccommodationsAddons: MemoizedSelector<
  object,
  IAddonRoot[]
> = createSelector(selectAddonsState, getAccommodationAddons);
