/**
 * @description Configurazioni statiche del tableau.
 */
export const CellHeight = 36;
export const CellWidth = 36;

export const BoxMargin = 2;

export const BoxIntersectionWidth = Math.trunc(CellWidth / 3);

/**
 * @description Numero di giorni da caricare al finire dello scroll orizzontale.
 */
export const MovingPeriodDays = 5;

export const PreferencesKey = 'tableau';

export const MinZoom = 1;
export const MaxZoom = 50;

export const SwapRoomId = 'tableau-swap-room';

export const FooterRows = 2;

export const BoxHeight = CellHeight - BoxMargin * 2;

export const DraggingDelayTime = 500;

export const MaxWidth = 3000;

export const BoxPopoverDelay = 0.5;
