import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { CityTaxSummaryLoadRequest } from '../models';

import { ExportService } from './export.service';

@Injectable({
  providedIn: 'root',
})
export class CityTaxSummaryService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
    private exportService: ExportService,
  ) {}

  load(request: CityTaxSummaryLoadRequest) {
    const {
      properties,
      viewType,
      dateFrom,
      dateTo,
      showDetails,
      showExemptions,
      filters,
      ...extra
    } = request;

    const params = generateSearchQuery(
      this.dateFormatter.formatObjectDates({
        ...extra,
        property_id: properties,
        date_from: dateFrom,
        date_to: dateTo,
        filters: this.getFiltersValueToString(filters),
      }),
    );

    if (viewType === 'grouped') {
      return this.http.get(`city_tax/grouped?${params}`);
    }

    return this.http.get(`city_tax?${params}`).pipe(
      map((response: IResponseSuccess) => ({
        ...response,
        data: { all_properties: response.data },
      })),
    );
  }

  export(
    request: CityTaxSummaryLoadRequest,
    filename: string,
    expands: Record<number, Record<string, boolean>>,
  ) {
    const {
      properties,
      viewType,
      dateFrom,
      dateTo,
      showDetails,
      showExemptions,
      filters,
    } = request;

    let link = 'city_tax/report';

    if (viewType === 'grouped') {
      link = 'city_tax/report/grouped';
    }

    return this.exportService.blobExport(
      {
        link,
        payload: this.dateFormatter.formatObjectDates({
          property_id: properties,
          date_from: dateFrom,
          date_to: dateTo,
          show_nights: showDetails,
          show_all_exemptions: showExemptions,
          expands,
          filters: this.getFiltersValueToString(filters),
        }),
      },
      filename,
    );
  }

  loadStatistics() {
    return this.http.get(`city_tax/statistics`);
  }

  getFiltersValueToString(filters: { [key: string]: string }): string[] {
    return Object.keys(filters).reduce((filtersValue, filterKey) => {
      if (!filters[filterKey]) {
        return filtersValue;
      }
      return [...filtersValue, `${filterKey}:${filters[filterKey]}`];
    }, []);
  }
}
