import { createAction, props } from '@ngrx/store';

import { Dealer } from '../../models';

export const loadRequest = createAction('[Dealers] Load Request');

export const loadSuccess = createAction(
  '[Dealers] Load Success',
  props<{ dealers: Dealer[] }>(),
);

export const loadFailure = createAction(
  '[Dealers] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Dealers] Reset State');
