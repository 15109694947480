import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PaymentMethod } from '../../models';

export const featureAdapter: EntityAdapter<PaymentMethod> = createEntityAdapter<
  PaymentMethod
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<PaymentMethod> {
  isLoading?: boolean;
  error?: any;
  propertiesPaymentMethods: { [propertyId: number]: PaymentMethod[] };
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  propertiesPaymentMethods: null,
});
