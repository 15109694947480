import { IReservationTag } from '@app/models/objects/reservation-tag';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<
  IReservationTag
> = createEntityAdapter<IReservationTag>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<IReservationTag> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
