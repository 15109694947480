import { createAction, props } from '@ngrx/store';
import { ProportionalDayPrice } from '../../models';

export const calculateRequest = createAction(
  '[Proportional Day Price] Calculate Request',
  props<{
    data: ProportionalDayPrice;
  }>(),
);

export const calculateSuccess = createAction(
  '[Proportional Day Price] Calculate Success',
  props<{ data: ProportionalDayPrice }>(),
);

export const calculateFailure = createAction(
  '[Proportional Day Price] Calculate Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Proportional Day Price] Reset State');
