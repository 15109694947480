import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import { omit } from 'lodash';

import { removeNullishValues } from '../helpers';
import { SearchParams } from '../models/objects/search-params';
import moment from 'moment';
import {
  ExportFileRequestSuccess,
  ExportFileRequestType,
} from '../models/types/export-file-request';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private http: HttpClient) {}

  load(properties: number[], params: Object) {
    return this.http.get(
      `companies?${generateParamArray(
        'property_id',
        properties,
      )}&${generateSearchQuery(params)}`,
    );
  }

  loadDetails(companyId: number) {
    return this.http.get(`company/${companyId}`);
  }

  create(request) {
    return this.http.post(`company`, request);
  }

  update(request) {
    return this.http.put<IResponseSuccess>(
      `company/${request.id}`,
      omit(request, 'customer_id'),
    );
  }

  search(property_id: number[], value: string, params?: SearchParams) {
    return this.http.get(
      `companies/lookup?${generateSearchQuery(
        removeNullishValues({
          property_id,
          value,
          ...params,
        }),
      )}`,
    );
  }

  delete(companyId: number) {
    return this.http.delete(`company/${companyId}`);
  }

  upload(properties: number[], file: File, dateFormat: string) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(
      `companies/import?${generateParamArray(
        'property_id',
        properties,
      )}&date_format=${dateFormat}`,
      formData,
    );
  }

  exportFile(data: ExportFileRequestType) {
    const { format, exportColumns, propertyIds, queryString } = data;

    const queryParams = {
      export: '1',
      export_format: format,
      current_date: moment(new Date()).format('YYYY-MM-DD'),
      download_pdf: '1',
      property_id: propertyIds,
      export_columns: exportColumns,
      ...queryString,
    };

    return this.http.get<IResponseSuccess<ExportFileRequestSuccess>>(
      `companies?${generateSearchQuery(removeNullishValues(queryParams))}`,
    );
  }
}
