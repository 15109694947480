import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import {
  PreviousReservationsDownloadRequest,
  PreviousReservationsTaskRecordsRequest,
  PreviousReservationsTasksRequest,
  PreviousReservationUpdateRecordsRequest,
} from '../models';
import { removeNullishValues } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class PreviousReservationsService {
  constructor(private http: HttpClient) {}

  download(request: PreviousReservationsDownloadRequest) {
    return this.http.get(
      `reservations_past?${generateSearchQuery(removeNullishValues(request))}`,
    );
  }

  loadTasks(request: PreviousReservationsTasksRequest) {
    return this.http.get(
      `admin/toolschannelmanager/past_reservations_task?${generateSearchQuery(
        removeNullishValues(request),
      )}`,
    );
  }

  loadTaskRecords(request: PreviousReservationsTaskRecordsRequest) {
    const { queue_past_reservation_task_id, ...requestValue } = request;
    return this.http.get(
      `admin/toolschannelmanager/past_reservation_task/${queue_past_reservation_task_id}/records?${generateSearchQuery(
        requestValue,
      )}`,
    );
  }

  updateRecords(request: PreviousReservationUpdateRecordsRequest) {
    const { queue_past_reservation_task_id, ...requestValue } = request;

    return this.http.put(
      `admin/toolschannelmanager/past_reservation_task/${queue_past_reservation_task_id}/records`,
      requestValue,
    );
  }

  deleteTask(queuePastReservationTaskId: number) {
    return this.http.delete(
      `admin/toolschannelmanager/past_reservation_task/${queuePastReservationTaskId}`,
    );
  }
}
