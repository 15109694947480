import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OsmPLace } from '../models/objects/osm-place';

const REVERSE_API_URL =
  'https://nominatim.openstreetmap.org/reverse?key=iTzWSiYpGxDvhATNtSrqx5gDcnMOkntL&format=json&addressdetails=1&lat={lat}&lon={lon}';

const LOOKUP_API_URL = 'https://nominatim.openstreetmap.org/search';

@Injectable({ providedIn: 'root' })
export class OsmService {
  constructor(private http: HttpClient) {}

  reverse(lon: number, lat: number) {
    const url = this.getReverseUrl(lon, lat);
    return this.http.get(url) as Observable<OsmPLace>;
  }

  search(q: string) {
    return this.http.get(LOOKUP_API_URL, {
      params: { q, format: 'json' },
    }) as Observable<OsmPLace[]>;
  }

  private getReverseUrl(lon: number, lat: number): string {
    return REVERSE_API_URL.replace(new RegExp('{lon}', 'ig'), `${lon}`).replace(
      new RegExp('{lat}', 'ig'),
      `${lat}`,
    );
  }
}
