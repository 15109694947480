import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SnippetsService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('general/templates_email/snippets');
  }
}
