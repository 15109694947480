import { Action } from '@ngrx/store';

import { PaymentMethod } from '../../models';

export enum ActionTypes {
  LOAD_REQUEST = '[Payment Methods] Load Request',
  LOAD_FAILURE = '[Payment Methods] Load Failure',
  LOAD_SUCCESS = '[Payment Methods] Load Success',
  LOAD_PROPERTIES_PAYMENT_METHODS_REQUEST = '[Payment Methods] Load Properties Payment Methods Request',
  LOAD_PROPERTIES_PAYMENT_METHODS_FAILURE = '[Payment Methods] Load Properties Payment Methods Failure',
  LOAD_PROPERTIES_PAYMENT_METHODS_SUCCESS = '[Payment Methods] Load Properties Payment Methods Success',

  RESET_STATE = '[Payment Methods] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId?: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: PaymentMethod[] }) {}
}

export class LoadPropertiesPaymentMethodsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PROPERTIES_PAYMENT_METHODS_REQUEST;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class LoadPropertiesPaymentMethodsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PROPERTIES_PAYMENT_METHODS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadPropertiesPaymentMethodsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PROPERTIES_PAYMENT_METHODS_SUCCESS;
  constructor(
    public payload: { items: { [propertyId: number]: PaymentMethod[] } },
  ) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadPropertiesPaymentMethodsRequestAction
  | LoadPropertiesPaymentMethodsFailureAction
  | LoadPropertiesPaymentMethodsSuccessAction
  | ResetSuccessAction;
