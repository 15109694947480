import { UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash';
import moment from 'moment';

export function datapickerDisabledStartDate(
  forms: UntypedFormGroup[],
  startValue: Date,
  keyTo: string = 'to',
  keyFrom: string = 'from',
): boolean {
  const momentStartValue = moment(startValue);

  if (momentStartValue.isBefore(moment(), 'day')) {
    return true;
  }

  const result = forms.some(({ value }, index) => {
    if (index === forms.length - 1) {
      return false;
    }
    const to = get(value, keyTo);
    const from = get(value, keyFrom);

    if (!from || !to) {
      return false;
    }

    const momentFrom = moment(from);
    const momentTo = moment(to);

    return momentStartValue.isBetween(momentFrom, momentTo, 'day', '[]');
  });

  return result;
}

export function datapickerDisabledStartDateFormArray(
  formArrayPeriods: any[],
  startValue: Date,
  keyTo: string = 'to',
  keyFrom: string = 'from',
  firstAvailableDate = new Date(),
): boolean {
  if (
    firstAvailableDate &&
    moment(startValue).isBefore(moment(firstAvailableDate), 'days')
  ) {
    return true;
  }

  return formArrayPeriods.some((value, index) => {
    if (index === formArrayPeriods.length - 1) {
      return false;
    }
    const { [keyFrom]: from, [keyTo]: to } = value;
    if (!from || !to) {
      return false;
    }
    return (
      moment(startValue).isSameOrAfter(moment(from), 'days') &&
      moment(startValue).isSameOrBefore(moment(to), 'days')
    );
  });
}

export function datapickerdisabledEndDateFormArray(
  formArrayPeriods: any[],
  endValue: Date,
  startValue: Date,
  keyTo: string = 'to',
  keyFrom: string = 'from',
): boolean {
  if (!endValue || !startValue) {
    return false;
  }
  if (moment(endValue).isSameOrBefore(moment(startValue))) {
    return true;
  }
  return formArrayPeriods.some((value, index) => {
    if (index === formArrayPeriods.length - 1) {
      return false;
    }
    const { [keyFrom]: from, [keyTo]: to } = value;
    if (!from || !to) {
      return false;
    }
    return (
      moment(endValue).isAfter(from, 'days') &&
      moment(endValue).isBefore(moment(to), 'days')
    );
  });
}
export function datapickerdisabledEndDate(
  forms: UntypedFormGroup[],
  endValue: Date,
  startValue: Date,
  keyTo: string = 'to',
  keyFrom: string = 'from',
): boolean {
  if (!endValue || !startValue) {
    return false;
  }
  const momentStartValue = moment(startValue);
  const momentEndValue = moment(endValue);
  if (momentEndValue.isSameOrBefore(momentStartValue, 'day')) {
    return true;
  }
  return forms.some(({ value }, index) => {
    if (index === forms.length - 1) {
      return false;
    }
    const to = get(value, keyTo);
    const from = get(value, keyFrom);

    if (!from || !to) {
      return false;
    }
    const momentFrom = moment(from);
    const momentTo = moment(to);
    return momentEndValue.isBetween(momentFrom, momentTo, 'day', '(]');
  });
}

export function datapickerDisabledStartDateToArray(
  dates: Array<{ from: Date; to: Date }>,
  startValue: Date,
  minStartValue: Date,
  maxEndValue: Date,
): boolean {
  if (maxEndValue && minStartValue) {
    if (moment(startValue).isSameOrAfter(moment(maxEndValue))) {
      return true;
    }
    if (
      moment(startValue).isAfter(moment(minStartValue)) &&
      moment(startValue).isBefore(moment(maxEndValue))
    ) {
      return false;
    }
  }

  if (moment(startValue).isBefore(moment())) {
    return true;
  }

  return dates.some((data) => {
    const { from, to } = data;
    if (!from) {
      return false;
    }

    return (
      moment(startValue).isAfter(moment(from)) &&
      moment(startValue).isBefore(moment(to))
    );
  });
}

export function datapickerdisabledEndDateToArray(
  dates: Array<{ from: Date; to: Date }>,
  endValue: Date,
  minStartValue: Date,
  maxEndValue: Date,
): boolean {
  const lastDate = moment(new Date()).add(11, 'month').toDate().getTime();
  if (maxEndValue && minStartValue) {
    if (
      endValue.getTime() <= maxEndValue.getTime() &&
      endValue.getTime() > minStartValue.getTime()
    ) {
      return false;
    }
  }

  if (endValue.getTime() > lastDate) {
    return true;
  }

  if (!endValue || !minStartValue) {
    return false;
  }
  if (endValue.getTime() <= minStartValue.getTime()) {
    return true;
  }
  return dates.some((data) => {
    const { from, to } = data;
    return (
      endValue.getTime() > from.getTime() &&
      endValue.getTime() < moment(to).add(1, 'day').toDate().getTime()
    );
  });
}

export function disableRangeFromDatesAndMaxValues(
  dates: Array<{ from: Date; to: Date }>,
  endValue: Date,
  minStartValue?: Date,
  maxEndValue?: Date,
): boolean {
  if (maxEndValue && moment(endValue).isSameOrAfter(moment(maxEndValue))) {
    return true;
  }

  if (minStartValue && moment(endValue).isSameOrBefore(moment(minStartValue))) {
    return true;
  }

  return dates.some((data) => {
    const { from, to } = data;
    return (
      moment(endValue).isSameOrAfter(moment(from)) &&
      moment(endValue).isSameOrBefore(moment(to))
    );
  });
}
