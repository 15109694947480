import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';
import { forkJoin, Observable } from 'rxjs';
import { IResponseSuccess } from '../core/models/response-sucess.model';

import { IResponse } from '../core/models/response.model';
import { INewChildrenRange } from '../features/commons/children-range/models/new-children-range.model';

@Injectable({
  providedIn: 'root',
})
export class ChildrenRangeRootService {
  constructor(private http: HttpClient) {}

  load(properties: number[]) {
    return this.http.get(
      `properties/children_ranges?${generateParamArray(
        'property_id',
        properties,
      )}`,
    );
  }

  create(
    propertyId: number,
    newChildrenRangeData: INewChildrenRange[],
  ): Observable<IResponse[]> {
    return forkJoin(
      newChildrenRangeData.map(
        (childrenRangeData: INewChildrenRange) =>
          this.http.post(
            `property/${propertyId}/children_ranges`,
            childrenRangeData,
          ) as Observable<IResponseSuccess>,
      ),
    );
  }

  delete(
    propertyId: number,
    childrenRangeIdsToDelete: number[],
  ): Observable<IResponse[]> {
    return forkJoin(
      childrenRangeIdsToDelete.map(
        (childrenRangeId: number) =>
          this.http.delete(
            `property/${propertyId}/children_ranges/${childrenRangeId}`,
          ) as Observable<IResponseSuccess>,
      ),
    );
  }

  update(
    propertyId: number,
    newChildrenRangeData: INewChildrenRange[],
  ): Observable<IResponse[]> {
    return forkJoin(
      newChildrenRangeData.map(
        (childrenRangeData: INewChildrenRange) =>
          this.http.put(
            `property/${propertyId}/children_ranges/${childrenRangeData.id}`,
            childrenRangeData,
          ) as Observable<IResponseSuccess>,
      ),
    );
  }

  loadTotalGuest(properties: number[]) {
    return this.http.get(
      `properties/max_guest?${generateParamArray('property_id', properties)}`,
    );
  }
}
