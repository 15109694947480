import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { QuoteRequestWidgetRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class QuoteRequestWidgetsService {
  constructor(private http: HttpClient) {}

  load(properties: number[]) {
    return this.http.get(
      `quote_request_widgets?${generateParamArray('property_id', properties)}`,
    );
  }

  loadById(id: number) {
    return this.http.get(`quote_request_widget/${id}`);
  }

  create(request: QuoteRequestWidgetRequest) {
    return this.http.post('quote_request_widget', request);
  }

  update(request: QuoteRequestWidgetRequest, id: number) {
    return this.http.put(`quote_request_widget/${id}`, request);
  }

  delete(id: number) {
    return this.http.delete(`quote_request_widget/${id}`);
  }
}
