import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { TokenService } from '../core/services/token.service';
import { removeNullishValues } from '../helpers';
import { NotificationTypeFilter } from '../models';

import { CloudMessagingService } from './cloud-messaging.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(
    private http: HttpClient,
    private cloudMessagingService: CloudMessagingService,
    private tokenService: TokenService,
  ) {}

  listen() {
    return this.cloudMessagingService.getMessages();
  }

  load(
    page: number,
    type: NotificationTypeFilter,
    dateRange?: { date_from: string; date_to: string },
  ): Observable<any> {
    return this.http.get(
      `user/me/notifications?${generateSearchQuery(
        removeNullishValues({
          page,
          type,
          date_from: dateRange?.date_from,
          date_to: dateRange?.date_to,
        }),
      )}`,
    );
  }

  flushCounter() {
    if (this.tokenService.isAdmin()) {
      return throwError('the admin user cannot perform this operation');
    }

    return this.http.post(`user/me/notifications/flush_counter`, {});
  }

  toggleReadStatus(operation: 'set_read' | 'set_unread', id?: string[]) {
    if (this.tokenService.isAdmin()) {
      return throwError('the admin user cannot perform this operation');
    }

    return this.http.post(
      `user/me/notifications/toggle_read`,
      removeNullishValues({ operation, id }),
    );
  }
}
