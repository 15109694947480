import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { last } from 'lodash';
import { map } from 'rxjs/operators';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { ExportScenariosLogsRequest, HousekeeperScenario } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HousekeeperScenariosService {
  constructor(private http: HttpClient) {}

  load(property_id: number[]) {
    return this.http.get(
      `properties/housekeeper/scenarios?${generateSearchQuery({
        property_id,
      })}`,
    );
  }

  create(scenario: HousekeeperScenario) {
    return this.http.post(`properties/housekeeper/scenarios`, scenario);
  }

  update({ id, ...scenario }: HousekeeperScenario) {
    return this.http.put(`properties/housekeeper/scenarios/${id}`, scenario);
  }

  delete(scenarioId: number) {
    return this.http.delete(`properties/housekeeper/scenarios/${scenarioId}`);
  }

  export({ format, ...request }: ExportScenariosLogsRequest) {
    return this.http
      .get(
        `properties/housekeeper/scenarios/export/${format}?${generateSearchQuery(
          request,
        )}`,
        { responseType: 'blob', observe: 'response' },
      )
      .pipe(
        map((response) => {
          const fileName = last(
            response.headers.get('Content-Disposition').split('filename='),
          );

          return new File([response.body], fileName, {
            type: response.body.type,
          });
        }),
      );
  }
}
