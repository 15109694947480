import { EntityAdapter, EntityState } from '@ngrx/entity';

export function sortData<T, U>(
  state: EntityState<T> & { originalOrder: number[] } & U,
  order: string,
  featureAdapter: EntityAdapter<T>,
): EntityState<T> & { originalOrder: number[] } & U;

export function sortData<T, U>(
  state: EntityState<T> & { originalOrder: string[] } & U,
  order: string,
  featureAdapter: EntityAdapter<T>,
): EntityState<T> & { originalOrder: string[] } & U;

export function sortData<T, U>(
  state: EntityState<T> & { originalOrder: any[] } & U,
  order: string,
  featureAdapter: EntityAdapter<T>,
): EntityState<T> & { originalOrder: any[] } & U {
  const [column, sortStatus] = (order || '').split(':');

  let items = Object.values(state.entities);

  if (!sortStatus) {
    items = state?.originalOrder?.map((key) => state.entities[key]);

    return featureAdapter.setAll(items, {
      ...state,
      order,
      isLoading: false,
      error: null,
    });
  }
  const ascFn = (a, b) => {
    if (typeof a[column] === 'string') {
      return a[column].localeCompare(b[column]);
    }

    return a[column] - b[column];
  };

  const descFn = (a, b) => {
    if (typeof a[column] === 'string') {
      return b[column].localeCompare(a[column]);
    }

    return b[column] - a[column];
  };

  items = items.sort(sortStatus === 'ASC' ? ascFn : descFn);

  return featureAdapter.setAll(items, {
    ...state,
    order,
    isLoading: false,
    error: null,
  });
}
