import { InvoiceDetails, InvoiceTotals } from '../models';

import { floatRound } from './float-round';

export function onLoadInvoiceDetails(
  invoice: InvoiceDetails,
): [InvoiceDetails, InvoiceTotals] {
  const documentMovementType =
    invoice.type === 'credit_note' ||
    invoice?.document_type_code_rules?.no_pre_payment
      ? 'out'
      : 'in';

  /**
   * Passano solo i pagamenti che rispettano i requisiti:
   * - Pagamenti disponibili o utilizzati in questo documento
   * - Tipo di movimento del pagamento consono al tipo di documento
   */
  let reservation_payments = (invoice.reservation_payments || []).filter(
    (payment) =>
      (+payment.amount_available || +payment.amount_used_here) &&
      payment.type === documentMovementType,
  );

  let totals: InvoiceTotals = {
    paid: +invoice.paid,
    to_pay: +invoice.to_pay,
    total: +invoice.total,
  };

  // Se sono in un conto aperto di una prenotazione preseleziono dei pagamenti di default
  if (invoice.reservation_id && !invoice.registered) {
    reservation_payments = reservation_payments.map((payment) => {
      // Se il pagamento è già associato al documento effettuo operazioni diverse
      if (+payment.amount_used_here > 0) {
        totals = {
          ...totals,
          paid: floatRound(+totals.paid - +payment.amount_used_here),
          to_pay: floatRound(+totals.to_pay + +payment.amount_used_here),
        };

        invoice = {
          ...invoice,
          ...totals,
        };

        payment = {
          ...payment,
          amount_used_here: 0,
          amount_available: floatRound(
            +payment.amount_available + +payment.amount_used_here,
          ),
        };
      }

      const amount_used_here =
        +payment.amount_available <= totals?.to_pay
          ? +payment.amount_available
          : totals?.to_pay;

      totals = {
        ...totals,
        paid: floatRound(+totals.paid + amount_used_here),
        to_pay: floatRound(+totals.to_pay - amount_used_here),
      };

      return {
        ...payment,
        amount_used_here,
      };
    });
  }

  // Se sono su un conto passante
  if (!invoice.reservation_id && !invoice.registered) {
    totals = {
      ...totals,
      paid: 0,
    };

    invoice.reservation_payments?.forEach((payment) => {
      totals = {
        ...totals,
        to_pay: floatRound(totals.to_pay + payment.amount_used_here),
      };
    });

    invoice = {
      ...invoice,
      ...totals,
    };
  }

  return [
    {
      ...invoice,
      reservation_payments,
    },
    totals,
  ];
}
