import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SubSink } from 'subsink';

import { CreditCardFormComponent } from '../../../../components/credit-card-form/credit-card-form.component';
import {
  CASH_PAYMENT_METHOD_ID,
  ENABLED_CREDIT_CARD_FORM,
} from '../../../../config';
import { IPaymentMethod } from '../../../../core/models/api/generics/payment-methods/payment-method.model';
import { replaceAll } from '../../../../helpers/replace-all';
import { FormGetter } from '../../../../models';
import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';

interface PaymentMethodConfig {
  credit_card_code: string;
  credit_card_expire_date: string;
  credit_card_holder: string;
  credit_card_number: string;
  credit_card_type: number;
  payment_method_id: number;
  payment_policy_id?: number;
}

type OnChange = (config: PaymentMethodConfig) => void;

@Component({
  selector: 'by-reservation-form-payment-method',
  templateUrl: './reservation-form-payment-method.component.html',
  styleUrls: ['./reservation-form-payment-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormPaymentMethodComponent),
      multi: true,
    },
  ],
})
export class ReservationFormPaymentMethodComponent
  implements OnChanges, ControlValueAccessor, FormGetter
{
  @ViewChild(CreditCardFormComponent)
  set _(creditCardFormComponent: CreditCardFormComponent) {
    this.creditCardFormComponent = creditCardFormComponent;

    if (!creditCardFormComponent) {
      this.form.patchValue({ credit_card: null });
    }
  }

  creditCardFormComponent: CreditCardFormComponent;

  @Input()
  paymentMethods: IPaymentMethod[];

  @Input()
  isDeposit: boolean;

  @Input()
  currency: ICurrency;

  @Input() isMobile = false;

  form = this.formBuilder.group({
    payment_method_id: [0],
    credit_card: [null],
    payment_policy_id: null,
  });

  saveCreditCard = this.formBuilder.control(false);

  onTouched: () => void;

  private subs = new SubSink();

  readonly ENABLED_CREDIT_CARD_FORM = ENABLED_CREDIT_CARD_FORM;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    const { isDeposit } = changes;

    if (isDeposit) {
      this.form.patchValue({
        payment_method_id: this.isDeposit ? CASH_PAYMENT_METHOD_ID : 0,
        credit_card: null,
      });
      this.saveCreditCard.setValue(false);
    }
  }

  writeValue(config: PaymentMethodConfig) {
    if (!config) {
      return;
    }

    const { payment_method_id, ...credit_card } = config;

    this.form.patchValue(
      { payment_method_id, credit_card },
      { emitEvent: false },
    );
  }

  registerOnChange(onChange: OnChange) {
    this.subs.add(
      this.form.valueChanges.subscribe(
        ({ payment_method_id, credit_card, payment_policy_id }) => {
          const credit_card_number =
            credit_card?.credit_card_number &&
            replaceAll(credit_card.credit_card_number, ' ', '');

          const credit_card_expire_date =
            credit_card?.credit_card_expire_date &&
            replaceAll(credit_card?.credit_card_expire_date, ' ', '').replace(
              '/',
              '-',
            );

          onChange({
            payment_method_id: payment_method_id || null,
            payment_policy_id: payment_policy_id || null,
            ...credit_card,
            credit_card_number,
            credit_card_expire_date,
          });
        },
      ),
    );
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }

  getForms() {
    return [this.form, this.creditCardFormComponent?.form].filter(
      (form) => !!form,
    );
  }

  get paymentMethodIdControl() {
    return this.form.get('payment_method_id');
  }

  get inputSize(): 'small' | 'default' {
    return this.isMobile ? 'default' : 'small';
  }
}
