import { Component, Input, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { INVOICING_ALLOWED_PAYMENT_METHODS_ID } from '../../../config/invoicing-allowed-payment-methods.config';
import { InvoiceDetails } from '../../../models';
import { PaymentMethodsStoreSelectors } from '../../../root-store/payment-methods-store';
import { RootState } from '../../../root-store/root-state';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-reversal-or-credit-note-register-modal',
  templateUrl: './reversal-or-credit-note-register-modal.component.html',
  styleUrls: ['./reversal-or-credit-note-register-modal.component.scss'],
})
export class ReversalOrCreditNoteRegisterModalComponent implements OnInit {
  readonly nzDataModal: Partial<ReversalOrCreditNoteRegisterModalComponent> =
    inject(NZ_MODAL_DATA);

  @Input()
  invoice: InvoiceDetails = this.nzDataModal.invoice;

  paymentMethodsGeneric$ = this.store.pipe(
    select(PaymentMethodsStoreSelectors.selectAllPaymentMethodsItems),
  );

  form: UntypedFormGroup;

  isDepositPayment: boolean;

  hasDiscounts: boolean;

  invoicingAllowedPaymentMethodsIds = INVOICING_ALLOWED_PAYMENT_METHODS_ID;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<RootState>,
  ) {
    this.form = this.formBuilder.group({
      payment_method_id: [0],
      mark_as_paid: [0],
      release_deposit: [0],
      include_discounts: [1],
    });
  }

  get value() {
    const { payment_method_id, mark_as_paid } = this.form.value;

    if (mark_as_paid && +payment_method_id > 0) {
      return this.form.value;
    }

    return { ...this.form.value, payment_method_id: null };
  }

  ngOnInit() {
    this.isDepositPayment = this.invoice.reservation_payments?.some(
      ({ deposit, amount_used_here }) => deposit && amount_used_here > 0,
    );

    this.hasDiscounts = this.invoice.bills_list.some(
      ({ discount_value }) => !!+discount_value,
    );

    if (this.hasDiscounts) {
      this.form.patchValue({ include_discounts: 1 });
    }

    if (this.isDepositPayment) {
      this.form.get('payment_method_id').setValue(15);
    }
  }
}
