import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { LoadPaymentOrdersRequest, PaymentOrder } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PaymentOrdersService {
  constructor(private http: HttpClient) {}

  load(request: LoadPaymentOrdersRequest) {
    return this.http.get(`payment_orders?${generateSearchQuery(request)}`);
  }

  capturePreAuthorization(paymentOrderId: number, propertyId: number) {
    return this.http.post(
      `payment_orders/${paymentOrderId}/capture_pre_authorization`,
      {
        property_id: propertyId,
      },
    );
  }

  releasePreAuthorization(paymentOrderId: number, propertyId: number) {
    return this.http.post(
      `payment_orders/${paymentOrderId}/release_pre_authorization`,
      {
        property_id: propertyId,
      },
    );
  }

  delete(paymentOrderId: number, propertyId: number) {
    return this.http.delete(
      `payment_orders/${paymentOrderId}?property_id=${propertyId}`,
    );
  }

  resendEmail(paymentOrderId: number, propertyId: number, email?: string) {
    return this.http.get(
      `payment_orders/${paymentOrderId}/resend_email/?property_id=${propertyId}${
        email ? '&' + email : ''
      }`,
    );
  }

  getWhatsappText(payment: PaymentOrder) {
    const { property, customer, id } = payment;
    return this.http.get(
      `payment_orders/${id}/whatsapp_text/?&lang=${
        customer?.language?.iso_code || 'it'
      }&property_id=${property.id}`,
    );
  }
}
