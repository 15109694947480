<div
  *nzModalTitle
  class="
    by-flex
    by-flex-middle
    by-tableau-actions-modal-title
    by-tableau-actions-modal-title--bordered
  "
>
  <i class="by-mr-10 fas fa-{{ action === 'open' ? 'unlock' : 'lock' }}"></i>
  <strong class="by-fs-16 by-mr-5">
    {{ params.row.data.label }}
  </strong>
  <span class="by-fs-11"
    >[{{ params.row.data.accommodation_details.name }}]</span
  >
</div>

<div class="by-tableau-closure__wrapper">
  <div class="by-tableau-closure__description">
    {{ action + '_period_message' | translate | upperfirst }}
  </div>

  <by-reservation-form-dates
    class="by-tableau-closure__dates"
    [formControl]="dates"
    [showNights]="false"
    [dateFromLabel]="'date_from' | translate | upperfirst"
    [dateToLabel]="'date_to' | translate | upperfirst"
  ></by-reservation-form-dates>
</div>

<div *nzModalFooter>
  <button nz-button (click)="onCancel()">
    <i class="by-mr-2 fas fa-undo"></i>
    {{ 'cancel' | translate | upperfirst }}
  </button>

  <button nz-button nzType="primary" [nzLoading]="saving" (click)="onSave()">
    {{ 'save' | translate | upperfirst }}
  </button>
</div>
