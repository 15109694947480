import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '@app/core/helpers/params-generator';
import { Observable } from 'rxjs';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { AdminStats, AdminStatsType } from '../models';
import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class AdminStatsService {
  constructor(private http: HttpClient) { }

  load(action: AdminStatsType, searchParams: SearchParams) {
    return this.http.get(
      `admin/properties/statistics/${action}?${generateSearchQuery(
        searchParams,
      )}`,
    ) as Observable<IResponseSuccess<AdminStats>>;
  }
}
