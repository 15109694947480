import { createAction, props } from '@ngrx/store';

import * as fromState from './state';

export const loadActiveModulesRequest = createAction(
  '[Active Modules] Load Request',
  props<{ properties: number[] }>(),
);

export const loadActiveModulesSuccess = createAction(
  '[Active Modules] Load Success',
  props<{ activeModules: fromState.Data }>(),
);

export const loadActiveModulesFailure = createAction(
  '[Active Modules] Load Failure',
  props<{ error: any }>(),
);

export const resetActiveModulesState = createAction(
  '[Active Modules] Reset State',
);
