export const openInNewTab = (url: string, fileName?: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';

  if (fileName) {
    link.download = fileName;
  }

  const event = new MouseEvent('click');

  link.dispatchEvent(event);
};
