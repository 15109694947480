import { FollowInvoiceConstructor } from '@app/models';
import { openInNewTab } from './open-in-new-tab';
import { generateSearchQuery } from '@app/core/helpers';
import { isNil, omitBy } from 'lodash';

export class FollowInvoice {
  receipt: FollowInvoiceConstructor;

  constructor(receipt: FollowInvoiceConstructor) {
    this.receipt = receipt;
  }

  get paid(): boolean {
    return (
      !!this.invoiceSummary?.registered ||
      !!this.receipt?.credit_notes?.length ||
      this.isReversal
    );
  }

  get unpaid(): boolean {
    return !this.paid || !!this.invoiceSummary?.credit_notes?.length;
  }

  get invoiceSummary() {
    return this.receipt?.invoiceSummary;
  }

  get isReversal(): boolean {
    return !!this.receipt?.reversal || this.receipt.type === 'reversal_receipt';
  }

  goToLinkenInvoice(): void {
    this.goToDocument(this.invoiceSummary);
  }

  goToFollowInvoice() {
    this.goToDocument(this.receipt);
  }

  goToDocument(invoice: FollowInvoiceConstructor) {
    const { property_id, id, reservation_id, roomreservation_id, registered } =
      invoice || {};

    if (reservation_id) {
      this.goToReservationBills({
        roomReservationID: roomreservation_id,
        reservationID: reservation_id,
        ID: id,
      });

      return;
    }

    if (!registered) {
      this.goToActiveCharge({ propertyID: property_id, chargeID: id });

      return;
    }

    if (!!registered) {
      this.goToRegisteredInvoice(this.invoiceSummary?.id || id);
    }
  }

  goToRegisteredInvoice(ID: number) {
    openInNewTab(`documents/invoice/${ID}`);
  }

  goToReservationBills(data: {
    roomReservationID: string;
    ID: number;
    reservationID: number;
  }): void {
    const params = generateSearchQuery(
      omitBy(
        {
          roomReservationId: data?.roomReservationID,
          invoiceId: data?.ID,
          subTab: 3,
        },
        isNil,
      ),
    );

    openInNewTab(`/reservation/${data?.reservationID}?${params}`);
  }

  goToActiveCharge(data: { propertyID: number; chargeID: number }): void {
    openInNewTab(
      `documents/open-charges/active-charges?${generateSearchQuery({ propertyId: data.propertyID, chargeId: data.chargeID })}`,
    );
  }
}
