import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SaveApiPartnerUserRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ApiPartnerUsersService {
  constructor(private http: HttpClient) {}

  load(apiPartnerId: number, page: number) {
    return this.http.get(`api_partners/${apiPartnerId}/users?page=${page}`);
  }

  loadDetails(apiPartnerId: number, apiPartnerUserId: number) {
    return this.http.get(
      `api_partners/${apiPartnerId}/user/${apiPartnerUserId}`,
    );
  }

  create(request: SaveApiPartnerUserRequest) {
    return this.http.post(
      `api_partners/${request.api_partner_id}/user`,
      request,
    );
  }

  update(request: SaveApiPartnerUserRequest) {
    return this.http.put(
      `api_partners/${request.api_partner_id}/user/${request.api_partner_user_id}`,
      request,
    );
  }

  delete(apiPartnerId: number, apiPartnerUserId: number) {
    return this.http.delete(
      `api_partners/${apiPartnerId}/user/${apiPartnerUserId}`,
    );
  }

  loadManager(apiPartnerId: number) {
    return this.http.get(`api_partner/${apiPartnerId}/manager`);
  }

  setManager(apiPartnerId: number, apiPartnerUserId: number) {
    return this.http.post(
      `api_partner/${apiPartnerId}/manager/set`,
      apiPartnerUserId
        ? {
            user_id: apiPartnerUserId,
          }
        : {},
    );
  }
}
