import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { flatten, get, uniqBy } from 'lodash';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  generateParamArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';
import { SearchParams } from '../models/objects/search-params';
import { Channel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('general/channels');
  }

  deletePropertyChannel(propertyIds: number[], channel_id: number) {
    return forkJoin(
      ...propertyIds.map((propertyId) => {
        return this.http.delete(
          `property/${propertyId}/detach_channel?channel_id=${channel_id}`,
        );
      }),
    );
  }

  deleteIdMultiPropertyChannel(
    property_id: number,
    channel_id: number,
    property_channel_multi_id,
  ) {
    return this.http.delete(
      `property/${property_id}/channel/${channel_id}/multi/${property_channel_multi_id}`,
    );
  }

  setMasterPropertyChannel(property_id: number, channel_id: number) {
    return this.http.post(
      `channelmanager/set_channel_master/${channel_id}/${property_id}`,
      {},
    );
  }

  createFakePropertyChannels(property_id: number, channel_id: number[]) {
    return this.http.post(
      `admin/demo_associations/${property_id}`,
      { channel_id },
      {},
    );
  }

  loadActive(
    properties: number[],
    params: SearchParams = {
      with_accommodation: 0,
      with_trashed: 0,
    },
  ) {
    return this.http
      .get(
        `channelmanager/active_channels?${generateParamArray(
          'property_id',
          properties,
        )}&${generateSearchQuery(params)}`,
      )
      .pipe(
        map(({ data }: any) => {
          const dataChannelPropertiesCheckinOnline =
            this.getChannelPropertiesCheckinOnline(data);
          return uniqBy(
            flatten(
              Object.keys(data).map((propertyId) =>
                data[propertyId].map((channel) => ({
                  ...channel,
                  checkin_online: get(dataChannelPropertiesCheckinOnline, [
                    channel.id,
                  ]),
                  properties: Object.keys(data).filter((p) =>
                    data[+p].find((c) => c.id === channel.id),
                  ),
                  properties_channel: this.getPropertiesChannel(
                    data,
                    channel.id,
                  ),
                })),
              ),
            ),
            'id',
          );
        }),
      );
  }

  getPropertiesChannel(
    data,
    channelId,
  ): { [propertyId: number]: Partial<Channel> } {
    return Object.keys(data).reduce((propertiesChannel, propertyId) => {
      const channel = (data[propertyId] || []).find(
        (PropertyChannel) => PropertyChannel.id === channelId,
      );
      return {
        ...propertiesChannel,
        [propertyId]: {
          disable_update: channel?.property_channel?.disable_update,
        },
      };
    }, {});
  }

  getChannelPropertiesCheckinOnline(data) {
    if (!data) {
      return;
    }
    let channelPropertiesCheckinOnline = {};
    Object.keys(data || {}).map((propertyId) =>
      data[propertyId].map((channel) => {
        channelPropertiesCheckinOnline = {
          ...channelPropertiesCheckinOnline,
          [channel.id]: {
            ...get(channelPropertiesCheckinOnline, channel.id, {}),
            [propertyId]: channel?.checkin_online,
          },
        };
      }),
    );
    return channelPropertiesCheckinOnline;
  }
}
