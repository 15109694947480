import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ScaConfirmationModuleConfiguration } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ScaConfirmationModuleConfigurationService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`sca/configuration?property_id=${propertyId}`);
  }

  update(
    propertyId: number,
    scaConfirmationModuleConfiguration: ScaConfirmationModuleConfiguration,
  ) {
    return this.http.put(`sca/configuration`, {
      property_id: propertyId,
      ...scaConfirmationModuleConfiguration,
    });
  }
}
