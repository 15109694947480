import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil, mapValues, uniq } from 'lodash';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  generateParamChildrenArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { EstimateRequest } from '../models/requests/estimate-request';
import { EstimateResponse } from '../models/responses/estimate.response';

function booleanOrNumber(value: boolean | string) {
  if (typeof value === 'boolean') {
    return value;
  }

  return +value || null;
}

@Injectable({
  providedIn: 'root',
})
export class EstimateService {
  constructor(private http: HttpClient) {}

  estimate(requests: EstimateRequest[]) {
    return forkJoin(
      requests.map(({ children, ...request }) => {
        let uri = `estimate?${generateSearchQuery(request)}`;

        if (children?.length) {
          uri += '&' + generateParamChildrenArray('children', children);
        }

        return this.http.get(uri);
      }),
    ).pipe(
      map((responses: IResponseSuccess<EstimateResponse>[]) => {
        let response: EstimateResponse;

        responses.forEach(({ data: responseData, meta }) => {
          let days = { ...response?.days };

          // Ogni tanto gli amici API ritornano un array -.-
          const data = responseData[0] || responseData;

          days = { ...days, ...data.days };

          response = {
            closed: response?.closed || data.closed,

            close_to_arrival:
              response?.close_to_arrival || data.close_to_arrival,

            close_to_departure:
              response?.close_to_departure || data.close_to_departure,

            min_stay: response?.min_stay || data.min_stay,

            max_stay: response?.max_stay || data.max_stay,

            has_door_key: response?.has_door_key || data.has_door_key,

            price_to_zero: response?.price_to_zero || data.price_to_zero,

            all_right: response?.all_right && data.all_right,

            guest_compatibility:
              response?.guest_compatibility && data.guest_compatibility,

            treatment_not_set:
              response?.treatment_not_set || data.treatment_not_set,

            availability: response?.availability && data.availability,

            rateplan_id_required: booleanOrNumber(
              response?.rateplan_id_required || data.rateplan_id_required,
            ),

            treatment_id_required: booleanOrNumber(
              response?.treatment_id_required || data.treatment_id_required,
            ),

            warnings: uniq([
              ...(response?.warnings || []),
              ...(meta.warnings || []),
            ]),

            accommodation_id: data.accommodation_id,

            days,

            total: (+data.total || 0) + (+response?.total || 0),
          };
        });

        return mapValues(response, (value) => (isNil(value) ? false : value));
      }),
    );
  }
}
