import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pos } from '@app/models';
import { generateParamArray } from '../core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class PosService {
  constructor(private http: HttpClient) {}

  load(propertyIDS: number[]) {
    return this.http.get(
      `properties/pos?${generateParamArray('property_id', propertyIDS)}`,
    );
  }

  create(propertyId: number, name: string) {
    return this.http.post(`property/${propertyId}/pos`, { name });
  }

  delete(propertyId: number, addonCategoryId: number) {
    return this.http.delete(`property/${propertyId}/pos/${addonCategoryId}`);
  }

  update(propertyId: number, pos: Pos) {
    return this.http.put(`property/${propertyId}/pos/${pos.id}`, {
      name: pos.name,
    });
  }
}
