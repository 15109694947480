import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';

import { DateFormatterService } from '../core/services/date-formatter.service';
import { Coupon } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(properties: number[]) {
    return this.http.get(
      `properties/coupons?${generateParamArray('property_id', properties)}`,
    );
  }

  delete(propertyId: number, couponId: number) {
    return this.http.delete(`property/${propertyId}/coupons/${couponId}`);
  }

  create(propertyId: number, newCouponData: Coupon) {
    return this.http.post(
      `property/${propertyId}/coupons`,
      this.dateFormatter.formatObjectDates(newCouponData),
    );
  }

  update(propertyId: number, coupondId: number, newCouponData: Coupon) {
    return this.http.put(
      `property/${propertyId}/coupons/${coupondId}`,
      this.dateFormatter.formatObjectDates(newCouponData),
    );
  }

  loadDetails(propertyId: number, couponId: number) {
    return this.http.get(`property/${propertyId}/coupons/${couponId}`);
  }
}
