import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const requiredIfValidator = <K>(
  conditionFn: (formValues: K) => boolean,
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const form = control.parent;

    if (!form) {
      return null;
    }

    const controlValue = control.value;

    if (conditionFn(form.value)) {
      if (controlValue) {
        return null;
      } else {
        return { required: true };
      }
    }
    return null;
  };
};
