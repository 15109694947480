import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';

import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { InvoiceTypes } from '../../../models';
import { RootState } from '../../../root-store/root-state';
import { InvoiceRegisterModalComponent } from '../invoice-register-modal/invoice-register-modal.component';

@Component({
  selector: 'by-invoice-register-as-receipt-modal',
  templateUrl: './invoice-register-as-receipt-modal.component.html',
  styleUrls: ['./invoice-register-as-receipt-modal.component.scss'],
})
export class InvoiceRegisterAsReceiptModalComponent extends InvoiceRegisterModalComponent {
  constructor(
    protected store: Store<RootState>,
    protected formBuilder: UntypedFormBuilder,
    protected dateFormatter: DateFormatterService,
  ) {
    super(store, formBuilder, dateFormatter);
  }

  /**
   * @override
   */
  invoiceType: InvoiceTypes = 'receipt';
}
