import { Pipe, PipeTransform } from '@angular/core';
import { get, isArray } from 'lodash';

@Pipe({
  name: 'get',
  pure: true,
})
export class GetPipe implements PipeTransform {
  transform(value: object, path: any, fallback: any = ''): any {
    if (isArray(path)) {
      path = path.reduce((fullPath, partial) => {
        return (fullPath += `[${partial}]`);
      }, '');
    }

    return get(value, path, fallback);
  }
}
