import { Injectable, Injector } from '@angular/core';
import { forEach } from 'lodash';

import { DateFormatterService } from '../core/services/date-formatter.service';
import { TableauRowComponents } from '../features/commons/tableau-2/tableau-components-map';
import {
  Floor,
  TableauMappingAccommodation,
  TableauMappingAccommodationTableauNumbers,
  TableauRow,
} from '../models';
import * as tableauState from '../root-store/tableau-store/state';

import { TableauRowsService } from '.';
import { RowsService } from './rows.service';
import { TableauRowIndexService } from './tableau-row-index.service';
import { TableauSumParentAccommodationReservationsService } from './tableau-sum-parent-accommodation-reservations.service';

@Injectable({ providedIn: 'root' })
export class TableauFloorsRowsService extends TableauRowsService {
  protected state: tableauState.State;

  private indexedMapping: {
    accommodations: { [accommodationId: number]: TableauMappingAccommodation };
    tableauNumbers: {
      [tableauNumberId: number]: TableauMappingAccommodationTableauNumbers;
    };
  };

  constructor(
    protected injector: Injector,
    protected rowsService: RowsService,
    protected dateFormatter: DateFormatterService,
    protected tableauRowIndexService: TableauRowIndexService,
    protected sumParentAccommodationReservationsService: TableauSumParentAccommodationReservationsService,
  ) {
    super(
      injector,
      rowsService,
      dateFormatter,
      tableauRowIndexService,
      sumParentAccommodationReservationsService,
    );
  }

  build(state: tableauState.State): TableauRow[] {
    this.state = state;

    const { mapping, floors } = state;

    if (!mapping || !floors) {
      return [];
    }

    this.setIndexedMapping();

    const rows = [
      ...this.buildEventsRows(),
      ...this.buildPropertiesRows(),
      ...this.buildFooterRows(),
    ];

    this.indexedMapping = null;

    return rows;
  }

  private setIndexedMapping() {
    const { mapping } = this.state;

    this.indexedMapping = {
      accommodations: {},
      tableauNumbers: {},
    };

    forEach(mapping, (propertyAccommodations) => {
      propertyAccommodations.forEach((accommodation) => {
        const { accommodation_details, tableau_numbers } = accommodation;

        const { id: accommodation_id } = accommodation_details;

        this.indexedMapping = {
          ...this.indexedMapping,
          accommodations: {
            ...this.indexedMapping.accommodations,
            [accommodation_id]: accommodation,
          },
        };

        tableau_numbers?.forEach((tableauNumber) => {
          this.indexedMapping = {
            ...this.indexedMapping,
            tableauNumbers: {
              ...this.indexedMapping.tableauNumbers,
              [tableauNumber.id]: tableauNumber,
            },
          };
        });
      });
    });
  }

  /**
   * @override
   */
  protected buildPropertyRows(propertyId: number): TableauRow[] {
    const { floors, mapping } = this.state;

    const propertyFloors = floors.floors[propertyId];

    const rows: TableauRow[] = [];

    rows.push(...this.getFloorObRows(propertyId));

    propertyFloors?.forEach((floor) => {
      rows.push(...this.getFloorRows(propertyId, floor));
    });

    rows.push(...this.getRoomsWithoutFloorRows(propertyId));

    if (!rows.length) {
      rows.push(this.getDefaultFloorRow(propertyId));

      mapping[propertyId].forEach((accommodation) => {
        rows.push(...this.buildRoomsRows(accommodation));
        rows.push(...this.buildObRows(accommodation));
      });
    }

    return rows;
  }

  private getFloorRows(propertyId: number, floor: Floor) {
    const rows: TableauRow[] = [];

    floor.tableau_numbers.forEach(({ accommodation_id, id }) => {
      rows.push(
        ...this.buildRoomRows(
          this.indexedMapping.accommodations[accommodation_id],
          this.indexedMapping.tableauNumbers[id],
        ),
      );
    });

    if (rows.length) {
      rows.unshift({
        id: this.tableauRowIndexService.encode({
          floor_id: floor.id,
          property_id: propertyId,
          index: 0,
          spanLength: 1,
        }),
        component: TableauRowComponents.Floor,
        data: floor,
        firstColumnRowspan: 0,
        spanIndex: 0,
        propertyId,
        items: null,
      });
    }

    return rows;
  }

  private getFloorObRows(propertyId: number) {
    const rows: TableauRow[] = [];

    const { mapping } = this.state;

    mapping[propertyId]?.forEach((accommodation) => {
      rows.push(...this.buildObRows(accommodation));
    });

    if (rows.length) {
      rows.unshift({
        id: this.tableauRowIndexService.encode({
          floor_id: 'OB',
          property_id: propertyId,
          index: 0,
          spanLength: 1,
        }),
        component: TableauRowComponents.Floor,
        data: { name: 'OB' },
        firstColumnRowspan: 0,
        spanIndex: 0,
        propertyId,
        items: null,
      });
    }

    return rows;
  }

  private getRoomsWithoutFloorRows(propertyId: number) {
    const rows: TableauRow[] = [];

    const { floors } = this.state;

    const tableauNumbersWithoutFloor =
      floors.tableau_numbers_without_floor[propertyId];

    tableauNumbersWithoutFloor?.forEach(({ accommodation_id, id }) => {
      rows.push(
        ...this.buildRoomRows(
          this.indexedMapping.accommodations[accommodation_id],
          this.indexedMapping.tableauNumbers[id],
        ),
      );
    });

    if (rows.length) {
      rows.unshift(this.getDefaultFloorRow(propertyId));
    }

    return rows;
  }

  private getDefaultFloorRow(propertyId: number): TableauRow {
    return {
      id: this.tableauRowIndexService.encode({
        floor_id: 0,
        property_id: propertyId,
        index: 0,
        spanLength: 1,
      }),
      component: TableauRowComponents.Floor,
      data: null,
      firstColumnRowspan: 0,
      spanIndex: 0,
      propertyId,
      items: null,
    };
  }
}
