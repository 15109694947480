import {
  IInvoiceLayout,
  ITaxSystem,
} from '@app/models/objects/invoices-layouts';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<IInvoiceLayout> =
  createEntityAdapter<IInvoiceLayout>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<IInvoiceLayout> {
  isLoading?: boolean;
  error?: any;
  invoiceLayouts: IInvoiceLayout[];
  allInvoiceLayouts: IInvoiceLayout[];
  invoiceLayoutsProperties: { [property_id: number]: IInvoiceLayout[] };
  details: IInvoiceLayout;
  taxSystem: ITaxSystem[];
  defaultPropertiesLayouts: { [property_id: number]: number };
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  invoiceLayouts: [],
  allInvoiceLayouts: [],
  details: null,
  taxSystem: null,
  defaultPropertiesLayouts: null,
  invoiceLayoutsProperties: null,
});
