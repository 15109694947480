import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SubSink } from 'subsink';
@Component({
  selector: 'by-attachment-viewer-paging-area',
  templateUrl: './attachment-viewer-paging-area.component.html',
  styleUrls: ['./attachment-viewer-paging-area.component.scss'],
})
export class AttachmentViewerPagingAreaComponent
  implements OnDestroy, OnChanges
{
  @Input()
  totalPages = 0;

  @Input()
  page = 0;

  @Output()
  pageChange = new EventEmitter<number>();

  disabledFirstPage = true;

  disabledLastPage = false;

  pageControl = new UntypedFormControl(0);

  private subs = new SubSink();

  constructor() {
    this.subs.add(
      this.pageControl.valueChanges.subscribe((page) => {
        this.onCurrentPageChange(page);
      }),
    );
  }

  ngOnChanges(): void {
    if (this.page && this.totalPages) {
      this.updateUIState();
    }
  }

  onFirstPage(): void {
    this.onCurrentPageChange(1);
  }

  onLastPage(): void {
    this.onCurrentPageChange(this.totalPages);
  }

  updateUIState(): void {
    this.pageControl.setValue(this.page, { emitEvent: false });

    this.disabledFirstPage = this.page <= 1;

    this.disabledLastPage = this.page === this.totalPages;
  }

  onCurrentPageChange(page: number) {
    this.pageChange.emit(page);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
