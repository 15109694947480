import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AdminAttachUserRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AdminAttachUserService {
  constructor(private http: HttpClient) {}

  loadPropertyRoles(propertyId: number) {
    return this.http.get(`property/${propertyId}/acl/roles`);
  }

  attachUser(data: AdminAttachUserRequest) {
    const { propertyId, user_id } = data;
    return this.http.post(
      `property/${propertyId}/acl/user/${user_id}/attach`,
      data,
    );
  }

  detachUser(data: AdminAttachUserRequest) {
    const { propertyId, user_id } = data;
    return this.http.post(
      `property/${propertyId}/acl/user/${user_id}/detach`,
      data,
    );
  }
}
