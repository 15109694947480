import { Component, Input, OnChanges } from '@angular/core';

import { take } from 'rxjs/operators';

import { FiscalPrinter, FiscalPrinterCheckMethodsType } from '../../models';
import {
  FiscalPrinterCheckResult,
  FiscalPrinterChecksService,
} from '../../use-cases/fiscal-printers/fiscal-printer-checks.service';
import { TypedSimpleChanges } from '../../models/types/simple-changes-typed';

@Component({
  selector: 'by-fiscal-printer-check',
  templateUrl: './fiscal-printer-check.component.html',
  styleUrls: ['./fiscal-printer-check.component.scss'],
})
export class FiscalPrinterCheckComponent implements OnChanges {
  @Input() selectedPrinter: FiscalPrinter;

  @Input() showLoading = false;

  @Input() checkMethodsToSkip: FiscalPrinterCheckMethodsType[] = [];

  @Input() warnings: string[];

  checkResults: FiscalPrinterCheckResult;

  checkResultsLoading = false;

  constructor(private fiscalPrinterChecksService: FiscalPrinterChecksService) {}

  ngOnChanges(
    changes: TypedSimpleChanges<{ selectedPrinter: FiscalPrinter }>,
  ): void {
    const { selectedPrinter } = changes;

    if (selectedPrinter && this.selectedPrinter) {
      this.onCheckStatus();
    }
  }

  onCheckStatus() {
    this.checkResultsLoading = true;
    this.fiscalPrinterChecksService
      .check(this.selectedPrinter, this.checkMethodsToSkip)
      .pipe(take(1))
      .subscribe((results) => {
        this.checkResults = results;
        this.checkResultsLoading = false;
      });
  }
}
