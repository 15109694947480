import { Media } from '@app/models';
import { get } from 'lodash';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { type, type_id, items } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [type]: {
            ...state.data[type],
            [type_id]: items,
          },
        },
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.DELETE_SUCCESS: {
      const { type, type_id, media_id } = action.payload;

      if (!state.data[type]) {
        return {
          ...state,
          isLoading: false,
          error: null,
        };
      }

      return {
        ...state,
        isLoading: false,
        error: null,
        data: {
          ...state.data,
          [type]: {
            ...state.data[type],
            [type_id]: state.data[type][type_id].filter(
              (media) => media.id !== media_id,
            ),
          },
        },
      };
    }

    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.CREATE_SUCCESS: {
      const {
        type,
        type_id,
        id,
        media,
        genre,
        replaceOld,
        label,
        formats_size,
      } = action.payload;
      const oldImages: Media[] = get(
        state.data,
        `[${type}][${type_id}]`,
        [],
      ) as Media[];
      const newImages = [
        ...(!replaceOld
          ? oldImages
          : oldImages.filter((m) => m.genre !== genre)),
        { id, media, genre, label, formats_size },
      ];
      return {
        ...state,
        data: {
          ...state.data,
          [type as any]: {
            ...state.data[type],
            [type_id]: newImages,
          },
        },
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.SORT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.SORT_SUCCESS: {
      const { type, type_id, ids, genre } = action.payload;
      const unthouched = state.data[type][type_id].filter(
        (m) => genre !== m.genre,
      );
      const touched = state.data[type][type_id].filter(
        (m) => genre === m.genre,
      );
      const sortedTouched = ids.map((sorterId) => {
        return touched.find(({ id }) => id === sorterId);
      });
      return {
        ...state,
        data: {
          ...state.data,
          [type]: {
            ...state.data[type],
            [type_id]: [...unthouched, ...sortedTouched],
          },
        },
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.SORT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
