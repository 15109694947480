import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { pdfDispatchEvent } from '../../../helpers';

@Component({
  selector: 'by-attachment-viewer-find-input-area',
  templateUrl: './attachment-viewer-find-input-area.component.html',
  styleUrls: ['./attachment-viewer-find-input-area.component.scss'],
})
export class AttachmentViewerFindInputAreaComponent {
  findControl = new UntypedFormControl(null);

  @Input()
  findState: 'success' | 'error' | 'validating';

  @ViewChild('findInput') findInput: ElementRef<HTMLElement>;

  constructor() {}

  onFocus(): void {
    this.findInput?.nativeElement.focus();
  }

  onFind(query: string): void {
    pdfDispatchEvent({
      eventName: 'find',
      options: {
        query,
        phraseSearch: true,
        type: 'find',

        pageRange: false,
      },
    });
  }

  onFindNext() {
    pdfDispatchEvent({
      eventName: 'find',
      options: {
        query: this.findControl.value,
        type: 'again',
        phraseSearch: true,
      },
    });
  }

  onFindPrevious() {
    pdfDispatchEvent({
      eventName: 'find',
      options: {
        query: this.findControl.value,
        findPrevious: true,
        type: 'again',
        phraseSearch: true,
      },
    });
  }
}
