import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@app/ui/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'lodash';

import { environment } from '../../../environments/environment';
import { ErrorHandlerServiceInterface } from '../../models';
import { IResponseSuccess } from '../models/response-sucess.model';
import { Meta } from '../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandlerServiceInterface {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private notifications: NotificationService,
  ) {}

  /**
   *
   * @param response from api
   *
   * @description generate warnings notifications
   *
   * @returns [boolean] Are there warnings?
   */

  getMessages(meta: Meta, type: 'warnings' | 'errors') {
    if (isObject(meta?.[type])) {
      return Object.values(meta[type]).map((value) => {
        if (typeof value === 'string') {
          return value;
        }

        if (typeof value?.toString === 'function') {
          return value.toString();
        }

        return this.translate.instant('warning');
      });
    } else {
      return meta[type] ? [meta[type]] : ['error'];
    }
  }

  handleWarnings({ meta }: IResponseSuccess): boolean {
    if (meta?.status !== 'warning') {
      return;
    }

    let warnings: string[] = this.getMessages(meta, 'warnings');

    warnings.forEach((warning) => this.notifications.warning(warning));

    return warnings.length > 0;
  }

  handleErrors({ error: { meta } }) {
    const errorMessages = this.getMessages(meta, 'errors');

    errorMessages.forEach((message) => this.notifications.error(message));
  }

  handle({ error, status }) {
    if (!error || !status) {
      return;
    }

    switch (status) {
      case 504: {
        this.notifications.warning('system_processing_warning');
        return;
      }
      case 400: {
        this.notifications.push({
          title: this.translate.instant('error'),
          content: error.message,
          type: 'error',
        });
        return;
      }
      case 503: {
        this.notifications.push({
          title: this.translate.instant('error'),
          content: error.message,
          type: 'error',
        });
        return;
      }
      case 401: {
        this.notifications.push({
          title: this.translate.instant('unauthorized'),
          content: error.message,
          type: 'error',
        });
        if (environment.production) {
          this.router.navigate(['/']);
        }
        return;
      }
      case 403: {
        this.notifications.push({
          title: this.translate.instant('unauthorized'),
          content: error.message,
          type: 'error',
        });
        if (environment.production) {
          this.router.navigate(['/']);
        }
        return;
      }
      case 404: {
        this.notifications.push({
          title: this.translate.instant('to_do_feature'),
          content: '',
          type: 'warning',
        });
        if (environment.production) {
          this.router.navigate(['/']);
        }
        return;
      }
      case 409: {
        this.notifications.push({
          title: this.translate.instant('error'),
          content: error.message || error.error_description,
          type: 'error',
        });
        return;
      }
      case 500: {
        this.notifications.push({
          title: this.translate.instant('server_error'),
          content:
            (error && error.message) || this.translate.instant('server_error'),
          type: 'error',
        });
        return;
      }
      case 422: {
        this.notifications.push({
          title: this.translate.instant('notifications.required_fields'),
          content: this.translate.instant('error'),
          type: 'error',
        });
        return;
      }
      default: {
        this.notifications.push({
          title: this.translate.instant('error'),
          content: this.translate.instant('notifications.update_failure', {
            param: '',
          }),
          type: 'error',
        });
        return;
      }
    }
  }
}
