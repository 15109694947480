import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import { CreateCustomer, CustomersLookupMethods } from '@app/models';
import { isNil, omitBy } from 'lodash';

import { DateFormatterService } from '../core/services/date-formatter.service';
import { removeNullishValues } from '../helpers';
import { SearchParams } from '../models/objects/search-params';
import moment from 'moment';
import {
  ExportFileRequestSuccess,
  ExportFileRequestType,
} from '../models/types/export-file-request';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(
    properties: number[],
    searchParams: Object,
    page: number,
    order: string,
  ) {
    const searchData: any = searchParams || {};
    const params = Object.keys(
      omitBy(searchData, (i) => i === null || i === undefined || i === ''),
    ).reduce((query, param) => {
      query += `&${param}=${searchParams[param]}`;
      return query;
    }, '');

    return this.http.get(
      `customers?${generateParamArray('property_id', properties)}${params}${
        page ? '&page=' + page : ''
      }&${order ? order : ''}`,
    );
  }

  searchLookup(
    property_id: number[],
    value: string,
    params: SearchParams,
    searchBy: CustomersLookupMethods = 'value',
  ) {
    return this.http.get(
      `customers/lookup?${generateSearchQuery(
        removeNullishValues({
          ...params,
          property_id,
          [searchBy]: value,
        }),
      )}`,
    );
  }

  delete(customerId: number) {
    return this.http.delete(`customer/${customerId}`);
  }

  public loadDocumenetTypes() {
    return this.http.get('general/customers/documents_types');
  }

  loadCustomerTypesRoot() {
    return this.http.get('general/customers/guest_types');
  }

  loadCustomerCategories() {
    return this.http.get('general/customers/categories');
  }

  loadAddressTypesRoot() {
    return this.http.get('general/customers/address_types');
  }

  updateCustomer(customer: Partial<CreateCustomer>, customerId: number) {
    /**
     *
     * @PATCH Avendo tolto il sesso not_defined l'update dei vecchi customers con sesso not_defined va in errore
     *
     * @author Sebastiano Maesano
     *
     */
    if (customer?.sex) {
      const sex =
        customer.sex === 'male' || customer.sex === 'female'
          ? customer.sex
          : 'male';

      customer = {
        ...customer,
        sex,
      };
    }

    return this.http.put(`customer/${customerId}`, customer);
  }

  updateGuestType(
    guest_type_id: number,
    res_acc_room_guest_id,
    city_tax_exemption_id?: number,
  ) {
    return this.http.put(
      `reservation/reservation_accommodation_room/guest/${res_acc_room_guest_id}`,
      omitBy(
        {
          guest_type_id,
          now: this.dateFormatter.toServerFormat(new Date()),
          city_tax_exemption_id,
        },
        isNil,
      ),
    );
  }

  updatePrivacy(customer_id, privacy_check, promotion_check) {
    return this.http.post(`customer/${customer_id}/privacy`, {
      privacy_check,
      promotion_check,
    });
  }

  createCustomer(customer: Partial<CreateCustomer>, property_id: number[]) {
    return this.http.post(
      `customer?${generateParamArray('property_id', property_id)}`,
      { ...customer, property_id },
    );
  }

  loadDetails(customerId: number, properties?: number[]) {
    return this.http.get(
      `customer/${customerId}${
        properties ? '?' + generateParamArray('property_id', properties) : ''
      }`,
    );
  }

  upload(properties: number[], file: File, dateFormat: string) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(
      `customers/import?${generateParamArray(
        'property_id',
        properties,
      )}&date_format=${dateFormat}`,
      formData,
    );
  }

  exportFile(data: ExportFileRequestType) {
    const { format, propertyIds, exportColumns, queryString } = data;

    const queryParams = {
      export: '1',
      export_format: format,
      current_date: moment(new Date()).format('YYYY-MM-DD'),
      download_pdf: '1',
      order: 'id:DESC',
      property_id: propertyIds,
      export_columns: exportColumns,
      ...queryString,
    };

    return this.http.get<IResponseSuccess<ExportFileRequestSuccess>>(
      `customers?${generateSearchQuery(removeNullishValues(queryParams))}`,
    );
  }
}
