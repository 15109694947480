import { createAction, props } from '@ngrx/store';

import { EstimateRateplan } from '../../models';
import { EstimateRequest } from '../../models/requests/estimate-request';
import { EstimateResponse } from '../../models/responses/estimate.response';

export const estimateRequest = createAction(
  '[Estimate] Estimate Request',
  props<{ requests: EstimateRequest[] }>(),
);
export const estimateSuccess = createAction(
  '[Estimate] Estimate Success',
  props<{ response: EstimateResponse }>(),
);
export const estimateFailure = createAction(
  '[Estimate] Estimate Failure',
  props<{ error: any }>(),
);

export const loadRateplansRequest = createAction(
  '[Estimate] Load Rateplans Request',
  props<{ accommodationId: number }>(),
);
export const loadRateplansSuccess = createAction(
  '[Estimate] Load Rateplans Success',
  props<{
    rateplans: EstimateRateplan[];
  }>(),
);
export const loadRateplansFailure = createAction(
  '[Estimate] Load Rateplans Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Estimate] Reset State');
