import { Injectable } from '@angular/core';
import { first, last } from 'lodash';
import moment from 'moment';
import { Moment } from 'moment';

type Item<T> = T & {
  date_from: string;
  date_to: string;
};

export type RowExtendedItem<T> = Item<T> & {
  length: number;
  dateIndex: string;
  leftEdgeTrucated: boolean;
  rightEdgeTruncated: boolean;
};

export interface Row<T = any> {
  [date: string]: RowExtendedItem<T>;
}

@Injectable({
  providedIn: 'root',
})
export class RowsService {
  createRows<T = any>(items: T[], visibleRange: Date[]): Row<T>[] {
    return (items as Item<T>[]).reduce(
      (rows: Row<T>[], item) =>
        this.addItemToRows<T>(rows, this.formatItem(item, visibleRange)),
      [{}],
    );
  }

  formatItem<T>(item: Item<T>, visibleRange: Date[]): RowExtendedItem<T> {
    let dateFrom = item.date_from;
    let dateTo = item.date_to;

    let leftEdgeTrucated = false;
    let rightEdgeTruncated = false;

    const visibleRangeFrom = first(visibleRange);
    const visibleRangeTo = last(visibleRange);

    if (
      (moment(dateFrom).isAfter(moment(visibleRangeTo), 'days') ||
        moment(dateTo).isSameOrBefore(moment(visibleRangeFrom), 'days')) &&
      !moment(dateFrom).isSame(moment(dateTo), 'days')
    ) {
      return null;
    }

    if (moment(dateFrom).isBefore(moment(visibleRangeFrom), 'days')) {
      dateFrom = moment(visibleRangeFrom).format('YYYY-MM-DD');
      leftEdgeTrucated = true;
    }

    if (moment(dateTo).isAfter(moment(visibleRangeTo), 'days')) {
      dateTo = moment(visibleRangeTo).add(1, 'day').format('YYYY-MM-DD');
      rightEdgeTruncated = true;
    }

    return {
      ...item,
      length: moment(dateTo).diff(moment(dateFrom), 'days') || 1,
      dateIndex: dateFrom,
      leftEdgeTrucated,
      rightEdgeTruncated,
    };
  }

  private addItemToRows<T>(
    rows: Row<T>[],
    itemData: RowExtendedItem<T>,
  ): Row<T>[] {
    if (!itemData) {
      return rows;
    }

    const nextItemRowIndex = this.getNextItemRowIndex<T>(rows, itemData);

    if (nextItemRowIndex === -1) {
      return [
        ...rows,
        {
          [itemData.dateIndex]: itemData,
        },
      ];
    }

    return rows.map((row, index: number) => {
      if (index !== nextItemRowIndex) {
        return row;
      }

      return {
        ...row,
        [itemData.dateIndex]: itemData,
      };
    });
  }

  private getNextItemRowIndex<T>(
    rows: Row<T>[],
    itemData: RowExtendedItem<T>,
  ): number {
    return rows.findIndex((row) => this.canItemFitInRow<T>(row, itemData));
  }

  private canItemFitInRow<T>(
    row: Row<T>,
    itemData: RowExtendedItem<T>,
  ): boolean {
    const [momentItemDateFrom, momentItemDateTo] =
      this.getMomentDatesForFittingCompare(itemData);

    return Object.values(row).every((rowItem) => {
      const [momentRowItemDateFrom, momentRowItemDateTo] =
        this.getMomentDatesForFittingCompare(rowItem);

      return !(
        momentItemDateFrom.isBefore(momentRowItemDateTo, 'days') &&
        momentRowItemDateFrom.isBefore(momentItemDateTo, 'days')
      );
    });
  }

  private getMomentDatesForFittingCompare({
    date_from,
    date_to,
  }: {
    date_from: string;
    date_to: string;
  }): [Moment, Moment] {
    const momentDateFrom = moment(date_from);
    const momentDateTo = moment(date_to);

    if (date_from === date_to) {
      momentDateTo.add(1, 'day');
    }

    return [momentDateFrom, momentDateTo];
  }
}
