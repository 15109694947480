type EventType = 'saveAndOpenFile' | 'setWebViewTitle' | 'openWhatsapp';

export function emitMobileAppEvent(event: EventType, payload: string): boolean {
  if (!window['ReactNativeWebView']) {
    return false;
  }

  window['ReactNativeWebView'].postMessage(btoa(event + '@b$y@' + payload));

  return true;
}
