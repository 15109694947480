import { isArray, isObject } from 'lodash';

import { isValidBase64String } from '../../helpers';

type itemType = string | number;

function encodeParameter(param: string | number | boolean): string {
  if (param && isValidBase64String(param.toString())) {
    return param.toString();
  }

  return encodeURIComponent(param);
}

export function generateParamArray(param: string, ids: itemType[]) {
  if (!ids || ids.length === 0) {
    return '';
  }

  return ids.map((id) => param + '[]=' + encodeParameter(id)).join('&');
}

export function generateParamArrayWithoutNull(param: string, ids: itemType[]) {
  if (!ids || ids.length === 0) {
    return '';
  }

  return ids.map((id) => param + '[]=' + encodeParameter(id)).join('&');
}

export function generateSearchQuery(
  filters: Object,
  encodeParams = true,
): string {
  return Object.keys(filters || {})
    .map((key) => {
      if (isArray(filters[key])) {
        return generateParamArray(key, filters[key]);
      }

      return `${key}=${
        encodeParams ? encodeParameter(filters[key]) : filters[key]
      }`;
    })
    .filter((param) => param !== '')
    .join('&');
}

export function generateMultiLevelArraySearchQuery(key: string, value: any) {
  if (isObject(value)) {
    return Object.keys(value)
      .map((index) =>
        generateMultiLevelArraySearchQuery(`${key}[${index}]`, value[index]),
      )
      .join('&');
  } else {
    return `${key}=${encodeParameter(value)}`;
  }
}

export function generateFormDataFromObject(data: any) {
  const formData = new FormData();
  generateMultiLevelArrayFromObject(data).forEach(({ key, value }) => {
    if (value === true) {
      formData.append(key, '1');
      return;
    }
    if (value === false) {
      formData.append(key, '0');
      return;
    }
    if (value === undefined || value === null) {
      return;
    }
    formData.append(key, value);
  });
  return formData;
}

export function generateMultiLevelArrayFromObject(value: any) {
  return Object.keys(value).reduce(
    (array, key) =>
      (array = [
        ...array,
        ...generateMultiLevelArrayFromKeyValue(key, value[key]),
      ]),
    [],
  );
}

export function generateMultiLevelArrayFromKeyValue(
  key: string,
  value: any,
): Array<{ key: string; value: string }> {
  if (isObject(value) && !(value instanceof File) && !(value instanceof Blob)) {
    return Object.keys(value).reduce(
      (array, index) =>
        (array = [
          ...array,
          ...generateMultiLevelArrayFromKeyValue(
            `${key}[${index}]`,
            value[index],
          ),
        ]),
      [],
    );
  } else {
    return [{ key, value }];
  }
}

export function generateParamChildrenArray(
  param: string,
  data: Array<{ age: number; quantity: number }>,
) {
  if (!data || data.length === 0) {
    return;
  }
  return data
    .map(
      (value, index) =>
        param +
        `[${index}][age]=` +
        value.age +
        '&' +
        param +
        `[${index}][quantity]=` +
        value.quantity,
    )
    .join('&');
}
