import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  NewsletterMailingListAddContactRequest,
  NewsletterMailingListContact,
  NewsletterMailingListRestoreContactRequest,
  NewsletterMailingListUpdateContactRequest,
  SearchParams,
} from '../models';
import { omit } from 'lodash';
import { generateSearchQuery } from '../core/helpers/params-generator';

@Injectable({ providedIn: 'root' })
export class NewsletterMailingListContactsService {
  constructor(private http: HttpClient) {}

  loadContacts(mailingListID: number, searchParams: SearchParams) {
    return this.http.get<IResponseSuccess<NewsletterMailingListContact[]>>(
      `newsletters/sending_lists/${mailingListID}/contacts?${generateSearchQuery(
        searchParams,
      )}`,
    );
  }

  addContact(data: NewsletterMailingListAddContactRequest) {
    return this.http.post<IResponseSuccess<NewsletterMailingListContact>>(
      `newsletters/sending_lists/${data.sending_list_id}/contacts`,
      data,
    );
  }

  updateContact(data: NewsletterMailingListUpdateContactRequest) {
    return this.http.put<IResponseSuccess<NewsletterMailingListContact>>(
      `newsletters/sending_lists/${data.sending_list_id}/contacts/${data.sending_list_contact_id}`,
      omit(data, 'hooks'),
    );
  }

  deleteContact(data: {
    sending_list_id: number;
    sending_list_contact_id: number;
  }) {
    return this.http.delete(
      `newsletters/sending_lists/${data.sending_list_id}/contacts/${data.sending_list_contact_id}`,
    );
  }

  restoreContact(data: Omit<NewsletterMailingListRestoreContactRequest, 'id'>) {
    return this.http.put(
      `newsletters/sending_lists/${data.sending_list_id}/contacts/restore`,
      {
        instance_id: data.instance_id,
        instance_type: data.instance_type,
      },
    );
  }
}
