const ERRORS = {
  '2': {
    code: '2',
    title: 'CARTA SCONTRINO',
    description:
      'It is a warning rather than an error. Flag SET 14/11 activates or deactivates the warning. If activated and the paper is low, the printer generates this warning in response to the payment or closure commands.The UPOS fiscal driver automatically deactivates this warning; paper status is determined through the regular printer status requests.',
  },
  '3': {
    code: '3',
    title: 'OFFLINE',
    description:
      'The printer has gone offline (“ERROR” LED ON).Either the paper has finished or the coverhas been opened.',
  },
  '7': {
    code: '7',
    title: 'SLIP KO',
    description:
      'Only valid with the FP-H6000 or if an external Slip printer is connected to the fiscal printer via the serial port. Indicates a slip printer problem.',
  },
  '8': { code: '8', title: 'TASTO ERRATO', description: 'Invalid key press.' },
  '9': {
    code: '9',
    title: 'DATA INFERIORE',
    description:
      'Invalid past date entered. The date cannot be earlier than the date of the last fiscal closure report.',
  },
  '10': {
    code: '10',
    title: 'DATA ERRATA',
    description: 'Bad date format. E.g. 33022017.',
  },
  '11': {
    code: '11',
    title: 'SEQUENZA ERRATA',
    description:
      'Command sequence not allowed.The command cannot be used at this point in the sequence. See examples below.',
  },
  '12': {
    code: '12',
    title: 'DATI INESISTENTI',
    description:
      'Inexistent data. For example, attempting to use a PLU that has not been programmed.',
  },
  '13': {
    code: '13',
    title: 'VALORE ERRATO',
    description:
      'Generic error. One or more fields contains an erroneous value.',
  },
  '14': {
    code: '14',
    title: 'ROG MATRICOLA',
    description: 'No fiscal serial number has been programmed.',
  },
  '15': {
    code: '15',
    title: 'GIA ESISTENTE',
    description:
      'An attempt has been made to perform an operation that has already been carried out. For example trying to program a PLU with a barcode that has already been set on another PLU.',
  },
  '16': {
    code: '16',
    title: 'NON PREVISTO',
    description:
      'Generic error. An invalid index parameter or an inexistent H1 H2 command pair has been received.',
  },
  '17': {
    code: '17',
    title: 'IMPOSSIBILE ORA',
    description:
      'Generic error. It is not possible to carry out the operation at this time.',
  },
  '18': {
    code: '18',
    title: 'NON POSSIBILE',
    description:
      'Generic error. It is not possible to carry out the operation.',
  },
  '19': { code: '19', title: 'SCRITTA INVALIDA', description: 'Obsolete.' },
  '20': {
    code: '20',
    title: 'SUPERA VALORE',
    description: 'The amount is greater than the maximum allowed.',
  },
  '21': {
    code: '21',
    title: 'SUPERA LIMITE',
    description: 'A parameter value is outside the permitted range.',
  },
  '22': {
    code: '22',
    title: 'NON PROGRAMMATO',
    description:
      'The printer has received a command that requires prior programming.',
  },
  '23': {
    code: '23',
    title: 'CHIUDI SCONTRINO',
    description:
      'The maximum number of operations has been reached and the receipt must be closed with a single payment or cancelled. The current limit is around 1000 operations.',
  },
  '24': {
    code: '24',
    title: 'CHIUDI PAGAMENTO',
    description:
      'The maximum number of operations has been reached whilst partial payments are being printed. The receipt must be closed with a single final payment or cancelled. The same 1000 operations limit applies.',
  },
  '25': {
    code: '25',
    title: 'MANCA OPERATORE',
    description:
      'Only valid if operator mode has been enabled. No operator has been selected.',
  },
  '26': {
    code: '26',
    title: 'CASSA INFERIORE',
    description:
      'An attempt has been made to perform a cash out operation or receipt change of an amount greater than the current cash drawer total.',
  },
  '27': {
    code: '27',
    title: 'OLTRE PROGRAMMAZIONE',
    description:
      'The sale price (unit price x quantity) is greater than the programmed department limit.',
  },
  '28': {
    code: '28',
    title: 'P.C. NON CONNESSO',
    description:
      'No PC or server connection or bad sequence termination. Server includes SMTP mail server or receipt as image server (SET 33).',
  },
  '29': {
    code: '29',
    title: 'MANCA MODULO',
    description:
      'Only valid with the FP-H6000 or if an external Slip printer is connected to the fiscal printer via the serial port. Indicates that no form has been inserted.',
  },
  '30': {
    code: '30',
    title: 'CHECKSUM ERRATO',
    description:
      'Partita IVA (business tax code) or codice fiscale (personal tax code) checksum error.',
  },
  '34': {
    code: '34',
    title: 'MANCA ATTIVAZIONE',
    description:
      'Missing activation. For example, attempt to open an invoice when invoice printing has been deactivated. Also, if receipt as image mobile number verification has failed.',
  },
  '35': {
    code: '35',
    title: '"SLIP:CONNESSIONE ?"',
    description:
      'Only valid with the FP-H6000 or if an external Slip printer is connected to the fiscal printer via the serial port. Indicates a slip printer connection problem.',
  },
  '37': {
    code: '37',
    title: 'RIMUOVERE MODULO',
    description:
      'Only valid with the FP-H6000 or if an external Slip printer is connected to the fiscal printer via the serial port. Indicates form removal. More of an instruction than an error.',
  },
  '38': {
    code: '38',
    title: 'EFT-POS in ERRORE',
    description: 'EFT-POS error',
  },
};

export function getError(code: string) {
  return (
    ERRORS[code] || {
      code,
      title: 'ERRORE SCONOSCIUTO',
      description:
        'È stato generato un errore sconosciuto. Comunica il codice errore al tecnico della stampante',
    }
  );
}
