<div class="by-attachment-viewer-tool-bar">
  <div class="by-attachment-viewer-tool-bar__block">
    <button
      id="sidebarToggle"
      nz-button
      class="by-mr-5 by-attachment-viewer-tool-bar--desktop-item ant-btn-icon-only"
      nz-tooltip
      [nzTooltipTitle]="'enable_disable_sidebar' | translate | upperfirst"
      nzTooltipPlacement="bottomLeft"
    >
      <i class="far fa-bars"></i>
    </button>
    <button
      nz-button
      class="by-mr-5 ant-btn-icon-only"
      nz-tooltip
      [nzTooltipTitle]="'find_in_document' | translate | upperfirst"
      nzTooltipPlacement="bottomLeft"
      nz-popover
      [nzPopoverContent]="customFindbarInputArea"
      nzPopoverTrigger="click"
      nzPopoverPlacement="bottomLeft"
      [nzPopoverVisible]="showFindInputArea"
      (nzPopoverVisibleChange)="onInputAreaVisibleChange($event)"
    >
      <i class="far fa-search"></i>
    </button>
    <ng-template #customFindbarInputArea>
      <by-attachment-viewer-find-input-area
        [findState]="findState"
      ></by-attachment-viewer-find-input-area>
    </ng-template>
    <by-attachment-viewer-paging-area
      [totalPages]="totalPages"
      [page]="page"
      (pageChange)="pageChange.emit($event)"
      class="by-attachment-viewer-tool-bar--desktop-item"
    ></by-attachment-viewer-paging-area>
  </div>
  <div class="by-attachment-viewer-tool-bar__block">
    <by-attachment-viewer-zoom-toolbar
      [zoom]="zoom"
      [pageScale]="pageScale"
      (zoomChange)="zoomChange.emit($event)"
    ></by-attachment-viewer-zoom-toolbar>
  </div>
  <div class="by-attachment-viewer-tool-bar__block">
    <button
      (click)="onPrint()"
      nz-button
      nzType="primary"
      class="by-mr-5 ant-btn-icon-only"
      nz-tooltip
      [nzTooltipTitle]="'print' | translate | upperfirst"
      nzTooltipPlacement="bottomLeft"
    >
      <i class="far fa-print"></i>
    </button>
    <button
      (click)="onDownload()"
      nz-button
      class="by-mr-5 ant-btn-icon-only"
      nz-tooltip
      [nzTooltipTitle]="'download' | translate | upperfirst"
      nzTooltipPlacement="bottomLeft"
    >
      <i class="far fa-cloud-download"></i>
    </button>
    <i
      nz-tooltip
      [nzTooltipTitle]="'close' | translate | upperfirst"
      (click)="onDestroy()"
      class="far fa-times close-icon pointer"
    ></i>
  </div>
</div>
