import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { InvoicesLayoutsStoreEffects } from './effects';
import { featureReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('invoicesLayouts', featureReducer),
    EffectsModule.forFeature([InvoicesLayoutsStoreEffects]),
  ],
})
export class InvoicesLayoutsStoreModule {}
