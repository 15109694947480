import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MonoTypeOperatorFunction, pipe, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { blobToJson } from './blob-to-json';

export function handleBlobResponseError<
  T = HttpResponse<Blob>,
  E = any,
>(): MonoTypeOperatorFunction<T> {
  return pipe(
    catchError((errorResponse: HttpErrorResponse) => {
      return blobToJson<E>(errorResponse.error).pipe(
        switchMap((error) => {
          return throwError({ ...errorResponse, error });
        }),
      );
    }),
  );
}
