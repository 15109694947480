import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { CompletedAccommodation } from '../models';
import { Observable } from 'rxjs';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class CompletedAccommodationsService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(properties: number[], referenceDate: Date) {
    return this.http.get(
      `reservations/accommodations/zero_availability?${generateParamArray(
        'property_id',
        properties,
      )}&reference_date=${this.dateFormatter.toServerFormat(referenceDate)}`,
    ) as Observable<IResponseSuccess<Record<string, CompletedAccommodation[]>>>;
  }
}
