import { AutomaticPaymentSummary } from '../../../models';

export interface State {
  automaticPaymentsSummary: AutomaticPaymentSummary[];
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  automaticPaymentsSummary: null,
  isLoading: false,
  error: null,
};
