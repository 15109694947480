import { PlaceVatQuote } from '@app/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[Place Vat Quote] Load Request',
  LOAD_FAILURE = '[Place Vat Quote] Load Failure',
  LOAD_SUCCESS = '[Place Vat Quote] Load Success',

  RESET_STATE = '[Place Vat Quote] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { place_id?: number; pagination: boolean }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { quotes: PlaceVatQuote[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadSuccessAction
  | LoadFailureAction
  | ResetSuccessAction;
