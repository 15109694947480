import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';

interface Payload {
  property_id: number[];
  reference_date: string;
}

@Injectable({
  providedIn: 'root',
})
export class HousekeeperStatisticsService {
  constructor(private http: HttpClient) {}

  load(payload: Payload) {
    return this.http.get(
      `properties/housekeeper/statistics?${generateSearchQuery(payload)}`,
    );
  }
}
