import { IProperty } from '@app/features/commons/properties/models/property.model';
import { Observable } from 'rxjs';
import { combineLatest, debounceTime, filter, map } from 'rxjs/operators';

import { filterPropertiesById } from './filter-properties-by-id';

export const combineProperties = (
  propertiesIds$: Observable<number[]>,
  properties$: Observable<IProperty[]>,
): Observable<[number[], IProperty[]]> => {
  return propertiesIds$.pipe(
    debounceTime(300),
    combineLatest(properties$),
    filter(
      ([ids, properties]: [number[], IProperty[]]) =>
        !!ids && !!ids.length && !!properties && !!properties.length,
    ),
    map(([ids, properties]: [number[], IProperty[]]) => {
      const propertiesFiltered = filterPropertiesById(ids, properties);
      return [ids, propertiesFiltered];
    }),
  );
};
