import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { EstimateResponse } from '../../models/responses/estimate.response';
import { EstimateService, TreatmentsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class EstimateStoreEffects {
  constructor(
    private estimateService: EstimateService,
    private treatmentsService: TreatmentsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  estimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.estimateRequest),
      switchMap(({ requests }) =>
        this.estimateService.estimate(requests).pipe(
          map((response: EstimateResponse) => {
            return fromActions.estimateSuccess({ response });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.estimateFailure(error));
          }),
        ),
      ),
    ),
  );

  loadRateplans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRateplansRequest),
      switchMap(({ accommodationId }) =>
        this.treatmentsService.loadWithRateplans(accommodationId, true).pipe(
          map(({ data }: IResponseSuccess) => {
            return fromActions.loadRateplansSuccess({ rateplans: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadRateplansFailure(error));
          }),
        ),
      ),
    ),
  );
}
