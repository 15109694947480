import { Action } from '@ngrx/store';

import { CartType } from '../../models';

export enum ActionTypes {
  LOAD_REQUEST = '[CartTypes] Load Request',
  LOAD_FAILURE = '[CartTypes] Load Failure',
  LOAD_SUCCESS = '[CartTypes] Load Success',

  RESET_STATE = '[CartTypes] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: CartType[] }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ResetSuccessAction;
