import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export function observableSequence<T = any>(
  observables: Observable<T>[],
  callBack: (status: Array<T>, sources: Observable<T>[]) => void = null,
  results = [],
): Observable<Array<T>> {
  const obs$ = observables[results.length];

  if (callBack) {
    callBack(results, observables);
  }
  if (!obs$) {
    return of(results);
  }

  return obs$.pipe(
    switchMap((result) => {
      return observableSequence(observables, callBack, [...results, result]);
    }),
  );
}
