import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

import { getBase64 } from '../core/helpers/get-base-64';
import { emitMobileAppEvent } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class FileSaverService {
  /**
   * @param data file
   * @param {String} filename nome del file comprensivo dell'estensione
   * @param {FileSaverOptions} options
   */
  saveAs(
    data: string | Blob,
    filename: string,
    options?: FileSaver.FileSaverOptions,
  ): void {
    this.getBase64(data, (base64) => {
      const payload = JSON.stringify({ base64, name: filename });

      if (!emitMobileAppEvent('saveAndOpenFile', payload)) {
        FileSaver.saveAs(data, filename, options);
      }
    });
  }

  private getBase64(data: string | Blob, onEncoded: (base64: string) => void) {
    if (typeof data === 'string') {
      onEncoded(data);
      return;
    }

    getBase64(data, onEncoded);
  }
}
