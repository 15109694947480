import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  checkCameraPermission(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if ('permissions' in navigator) {
        navigator.permissions
          .query({ name: 'camera' as PermissionName })
          .then((permissionStatus) => {
            observer.next(permissionStatus.state === 'granted');
            observer.complete();
          })
          .catch(() => {
            observer.next(false);
            observer.complete();
          });
      } else {
        // Fallback for browsers that do not support permissions API
        this.checkCameraPermissionViaGetUserMedia().subscribe(
          (hasPermission) => {
            observer.next(hasPermission);
            observer.complete();
          },
        );
      }
    });
  }

  private checkCameraPermissionViaGetUserMedia(): Observable<boolean> {
    return from(
      new Promise<boolean>((resolve) => {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            stream.getTracks().forEach((track) => track.stop());
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      }),
    );
  }
}
