<nz-breadcrumb
  *ngIf="!fromMobileApp"
  class="breadcrumb {{ customClass }}"
  [ngClass]="{ 'breadcrumb-flex': extraContent }"
>
  <nz-breadcrumb-item *ngIf="home">
    <a [routerLink]="'/dashboard'">{{ 'home' | translate }}</a>
  </nz-breadcrumb-item>
  <ng-container *ngFor="let item of items">
    <nz-breadcrumb-item *ngIf="item.routerLink">
      <a [routerLink]="item.routerLink" [queryParams]="item?.queryParams">{{
        item.label | translate
      }}</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item *ngIf="!item.routerLink">
      {{ item.label | translate }}
    </nz-breadcrumb-item>
  </ng-container>
  <div *ngIf="extraContent" class="extra-content">
    <ng-container *ngTemplateOutlet="extraContent"></ng-container>
  </div>
</nz-breadcrumb>
