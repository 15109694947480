import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { ChildrenRangeRootService } from '@app/services/children-range.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IChildrenRangeResponseSuccess } from '../../features/commons/children-range/models/children-range-response-success.model';
import { ChildrenRange } from '../../models';
import { NotificationService } from '../../ui/services/notification.service';

import * as featureActions from './actions';

@Injectable()
export class ChildrenRangeStoreEffects {
  constructor(
    private dataService: ChildrenRangeRootService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService.load(action.payload.propertyIds).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  loadMaxGuestsRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadMaxGuestsRequestAction>(
        featureActions.ActionTypes.LOAD_MAXGUESTS_REQUEST,
      ),
      switchMap((action: featureActions.LoadMaxGuestsRequestAction) =>
        this.dataService.loadTotalGuest(action.payload.propertyIds).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadMaxGuestsSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadMaxGuestsFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  public create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.CREATE_REQUEST),
      switchMap((action: featureActions.CreateRequestAction) => {
        const { propertyId, childrenRanges, disableNotify } = action.payload;

        return this.dataService.create(propertyId, childrenRanges).pipe(
          map((responses: IChildrenRangeResponseSuccess[]) => {
            if (!disableNotify) {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.create_success',
                  {
                    param: this.translate.instant('children_ranges'),
                  },
                ),
                type: 'success',
              });
            }

            return new featureActions.CreateSuccessAction({
              childrenRanges: responses.map(
                (response) => response.data[0] as ChildrenRange,
              ),
            });
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(new featureActions.CreateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  public delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.DELETE_REQUEST),
      switchMap((action: featureActions.DeleteRequestAction) => {
        const { propertyId, childrenRangesIds, disableNotify } = action.payload;

        return this.dataService.delete(propertyId, childrenRangesIds).pipe(
          map((responses: IChildrenRangeResponseSuccess[]) => {
            if (!disableNotify) {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('children_ranges'),
                  },
                ),
                type: 'success',
              });
            }

            return new featureActions.DeleteSuccessAction({
              ids: childrenRangesIds,
            });
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(new featureActions.DeleteFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  public update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.UPDATE_REQUEST),
      switchMap((action: featureActions.UpdateRequestAction) => {
        const { propertyId, childrenRange, disableNotify, onSuccess } =
          action.payload;

        return this.dataService.update(propertyId, childrenRange).pipe(
          map((responses: IChildrenRangeResponseSuccess[]) => {
            if (onSuccess) {
              onSuccess();
            }
            if (!disableNotify) {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('children_ranges'),
                  },
                ),
                type: 'success',
              });
            }

            return new featureActions.UpdateSuccessAction({
              childrenRanges: responses.map(
                (response) => response.data[0] as ChildrenRange,
              ),
            });
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(new featureActions.UpdateFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
