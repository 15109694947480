import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExternalServicesService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('admin/external_services');
  }

  loadMoreOptions(external_service_id: number) {
    return this.http.get(`external_services/options/${external_service_id}`);
  }
}
