import { GridBreakpoints } from '../models';

/**
 * @description
 * To be kept aligned with the styles of the file grid-breackpoints.scss.
 */

export const GRID_BREAKPOINT: GridBreakpoints = {
  xSmall: '(max-width: 575px)',
  small: '(min-width: 576px)',
  medium: '(min-width: 768px)',
  large: '(min-width: 992px)',
  xLarge: '(min-width: 1200px)',
  xxLarge: '(min-width: 1600px)',

  beforeMedium: '(max-width: 767px)',
  beforeLarge: '(max-width: 991px)',
  beforeXlarge: '(max-width: 1199px)',
  beforeXxLarge: '(max-width: 1599px)',
};
