import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get, merge } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  generateParamArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  SetRatesTreatmentsRequest,
  TreatmentEditRequest,
  TreatmentNewRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class TreatmentsService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`property/${propertyId}/treatments`);
  }

  loadCommon(propertyIds: number[]) {
    return this.http.get(
      `properties/common/treatments?&${generateParamArray(
        'property_id',
        propertyIds,
      )}`,
    );
  }

  loadTreatmentDetails(propertyId: number, property_treatment_id: number) {
    return this.http.get(
      `property/${propertyId}/treatment/${property_treatment_id}`,
    );
  }

  loadByRateplan(accommodationId: number, rateplanId: number) {
    return this.http.get(
      `accommodation/${accommodationId}/treatments/rateplans/${rateplanId}`,
    );
  }

  loadWithRateplans(
    accommodationId: number,
    withoutOnlyChannel: boolean = false,
  ) {
    return this.http
      .get(
        `accommodation/${accommodationId}/treatments/rateplans?without_only_channel=${+withoutOnlyChannel}`,
      )
      .pipe(
        map((response: IResponseSuccess) => {
          return {
            ...response,
            data: response.data[0].accommodation_rateplans.map(
              ({ property_rateplan, treatments }) => ({
                id: property_rateplan.my_rateplan.id,
                name: property_rateplan.my_rateplan.name,
                treatments: treatments.map(({ treatment }) => treatment),
              }),
            ),
          };
        }),
      );
  }

  loadTreatmentsAccommodationRateplan(
    allAccommodationRateplans,
    with_trashed: boolean,
  ) {
    return forkJoin(
      allAccommodationRateplans.map((data) =>
        this.http.get<IResponseSuccess>(
          `accommodation/${data.accommodation_id}/treatments/rateplans/${data.rateplan_id}/?${generateSearchQuery({ with_trashed })}`,
        ),
      ),
    ).pipe(
      map((response) => {
        return allAccommodationRateplans.reduce((acc, curr, index) => {
          let data = response[index].data;
          if (data) {
            data = get(data[0], 'accommodation_rateplans.[0].treatments', [])
              .map((treatment) => treatment.treatment)
              .map((trt) => ({
                ...trt,
                value: trt.id,
                label: trt.name,
              }));
            acc = merge({}, acc, {
              [curr.accommodation_id]: { [curr.rateplan_id]: data },
            });
          }
          return acc;
        }, {});
      }),
    );
  }

  attach(data: TreatmentNewRequest) {
    return this.http.post(`properties/attach_treatment`, data);
  }

  updateTreatmentAttacched(data: TreatmentEditRequest) {
    return this.http.put(
      `property/${data.property_id}/update_treatment/${data.property_treatment_id}`,
      data,
    );
  }

  detach(propertyId: number, treatmentId: number) {
    return this.http.delete(
      `property/${propertyId}/detach_treatment/${treatmentId}`,
    );
  }

  setRatesTreatments(data: SetRatesTreatmentsRequest) {
    return this.http.post(`treatments_rates`, data);
  }
}
