import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { AccessLogByDate, LoadAccessLogsRequest } from '../models';
@Injectable({
  providedIn: 'root',
})
export class AccessLogsService {
  constructor(private http: HttpClient) {}

  load(request: Partial<LoadAccessLogsRequest>, page: number) {
    const pageParam = page ? `?page=${page}` : '';

    return this.http.post(
      `access_logs/search${pageParam}`,
      request,
    ) as Observable<IResponseSuccess<AccessLogByDate>>;
  }
}
