import { orderBy } from 'lodash';
import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { items } = action.payload;
      return featureAdapter.setAll(
        orderBy(items, ['combination.from', 'combination.to'], 'asc'),
        {
          ...state,
          isLoading: false,
          error: null,
        },
      );
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_MAXGUESTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_MAXGUESTS_SUCCESS: {
      const { items } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        maxGuests: items,
      };
    }

    case ActionTypes.LOAD_MAXGUESTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.DELETE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case ActionTypes.DELETE_FAILURE: {
      return { ...state, isLoading: false, error: action.payload.error };
    }
    case ActionTypes.DELETE_SUCCESS: {
      const { ids } = action.payload;
      return featureAdapter.removeMany(ids, { ...state, isLoading: false });
    }

    case ActionTypes.UPDATE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case ActionTypes.UPDATE_FAILURE: {
      return { ...state, isLoading: false, error: action.payload.error };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      const { childrenRanges } = action.payload;
      const updates = childrenRanges.map((childrenRange) => {
        return { id: childrenRange.id, changes: childrenRange };
      });
      return featureAdapter.updateMany(updates, { ...state, isLoading: false });
    }

    case ActionTypes.CREATE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case ActionTypes.CREATE_FAILURE: {
      return { ...state, isLoading: false, error: action.payload.error };
    }
    case ActionTypes.CREATE_SUCCESS: {
      const { childrenRanges } = action.payload;
      return featureAdapter.addMany(childrenRanges, {
        ...state,
        isLoading: false,
      });
    }

    default: {
      return state;
    }
  }
}
