import { ExportFormat, UserPreferences } from '@app/models';
import { Action } from '@ngrx/store';
import { ExportFileRequestType } from '../../models/types/export-file-request';

export enum ActionTypes {
  LOAD_REQUEST = '[User Preferences] Load Request',
  LOAD_FAILURE = '[User Preferences] Load Failure',
  LOAD_SUCCESS = '[User Preferences] Load Success',

  UPDATE_REQUEST = '[User Preferences] Update Request',
  UPDATE_FAILURE = '[User Preferences] Update Failure',
  UPDATE_SUCCESS = '[User Preferences] Update Success',

  RESET_STATE = '[UserPreferences] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;

  constructor(public payload: { userId: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: UserPreferences }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;

  constructor(
    public payload: {
      preferences: {
        options: any;
      };
      onSuccess?: () => void;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;

  constructor(
    public payload: {
      preferences: any;
    },
  ) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | ResetSuccessAction;
