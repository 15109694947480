import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponse } from '@core/models/response.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminUsersService {
  constructor(private http: HttpClient) {}

  load(): Observable<IResponse> {
    return this.http.get(`user/admin/list`) as Observable<IResponse>;
  }
}
