import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { ChannelIdentifyPropertyResponse } from '@app/models/objects/channel-identify-property';

@Injectable({
  providedIn: 'root',
})
export class ChannelIdentifyPropertyService {
  constructor(private http: HttpClient) {}

  load(channel_id: number, accomodation_xml_id: number) {
    return this.http.get<IResponseSuccess<ChannelIdentifyPropertyResponse>>(
      `admin/toolschannelmanager/${channel_id}/accommodations/${accomodation_xml_id}/property`,
    );
  }
}
