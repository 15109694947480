import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { Newsletter, NewsletterTemplate } from '../models';
import { SearchParams } from '../models/objects/search-params';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { removeAllNullishValues } from '../helpers';
import { NewsletterRequest } from '../models/requests/newsletter.request';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  constructor(private http: HttpClient) {}

  load(searchParams: SearchParams) {
    return this.http.get<IResponseSuccess<Newsletter[]>>(
      `newsletters?${generateSearchQuery(
        removeAllNullishValues(searchParams),
      )}`,
    );
  }

  loadDetails(id: number) {
    return this.http.get<IResponseSuccess<Newsletter[]>>(`newsletters/${id}`);
  }

  loadPreview(id: number) {
    return this.http.get<IResponseSuccess<NewsletterTemplate[]>>(
      `newsletters/${id}/templates`,
    );
  }

  create(request: NewsletterRequest) {
    return this.http.post<IResponseSuccess<Newsletter>>(`newsletters`, request);
  }

  edit(request: NewsletterRequest, id: number) {
    return this.http.put<IResponseSuccess<Newsletter>>(
      `newsletters/${id}`,
      request,
    );
  }

  archive(id: number) {
    return this.http.put<IResponseSuccess<Newsletter[]>>(
      `newsletters/${id}/archive`,
      {},
    );
  }

  restore(id: number) {
    return this.http.put<IResponseSuccess<Newsletter[]>>(
      `newsletters/${id}/restore`,
      {},
    );
  }

  delete(id: number) {
    return this.http.delete<IResponseSuccess>(`newsletters/${id}`);
  }

  duplicate(id: number) {
    return this.http.post<IResponseSuccess<Newsletter[]>>(
      `newsletters/${id}/duplicate`,
      {},
    );
  }
}
