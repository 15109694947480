import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SetExternalServiceSchedulingRequest } from '../models';
import { UpdateExternalServicesMappingRequest } from '../models/requests/update-external-services-mapping-request';

@Injectable({
  providedIn: 'root',
})
export class ExternalServicesMappingService {
  constructor(private http: HttpClient) {}

  load(propertyId: number, externalServiceId: number) {
    return this.http.get(
      `external_services/get_accommodations_mapping/${externalServiceId}/${propertyId}`,
      {},
    );
  }

  download(propertyId: number, externalServiceId: number) {
    return this.http.get(
      `external_services/${externalServiceId}/${propertyId}/remote_action/getMapping`,
    );
  }

  export(
    propertyId: number,
    externalServiceId: number,
    from: string,
    to: string,
  ) {
    return this.http.post(
      `external_services/export_service/${externalServiceId}/${propertyId}`,
      {
        from,
        to,
      },
    );
  }

  update(request: UpdateExternalServicesMappingRequest) {
    return this.http.post(
      `external_services/set_accommodations_mapping/${request.external_service_id}/${request.property_id}`,
      request,
    );
  }

  setScheduling(request: SetExternalServiceSchedulingRequest) {
    return this.http.post(
      `external_services/scheduling/${request.external_service_id}/${request.property_id}`,
      request.payload,
    );
  }
}
