import { UserPreferences } from '@app/models';

export interface State {
  data: UserPreferences;
  isLoading: boolean;
  error?: any;
}

export const initialState: State = {
  data: null,
  isLoading: false,
  error: null,
};
