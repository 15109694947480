import { Injectable, TemplateRef, Type } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { capitalize } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RootState } from '../root-store/root-state';

@Injectable({
  providedIn: 'root',
})
export class WarningConfirmModalService {
  constructor(
    private store: Store<RootState>,
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {}

  open<T = {}>(options: {
    message?: string;
    action?: Action;
    labelAction?: string;
    labelDiscardAction?: string;
    discardAction?: Action;
    content?: string | TemplateRef<any> | Type<unknown>;
    contentParams?: T;
  }): Observable<'cancel' | 'save'> {
    const {
      message,
      action,
      labelAction,
      labelDiscardAction,
      discardAction,
      content,
      contentParams,
    } = options;

    const modal = this.modalService.create({
      nzTitle: capitalize(this.translate.instant('confirm_required')),
      nzContent: content || capitalize(message),
      nzData: contentParams,
      nzOnCancel: () => {
        if (discardAction) {
          this.store.dispatch(discardAction);
        }
        modal.close();
      },
      nzFooter: [
        {
          label: capitalize(
            this.translate.instant(labelDiscardAction || 'cancel'),
          ),
          onClick: () => {
            if (discardAction) {
              this.store.dispatch(discardAction);
            }
            modal.close();
          },
        },
        {
          label: capitalize(
            this.translate.instant(labelAction || 'ignore_and_continue'),
          ),
          type: 'primary',
          onClick: () => {
            if (action) {
              this.store.dispatch(action);
            }

            modal.close('save');
          },
        },
      ],
    });

    return modal.afterClose
      .asObservable()
      .pipe(map((result) => (result === 'save' ? 'save' : 'cancel')));
  }
}
