import {
  CurrentRTState,
  FiscalPrinterRTStatus,
  RTInactivePeriod,
} from '../fiscal-printer-rt-status';

import { CustomQ3XFiscalPrinterStatusResponse } from './custom-q3x-fiscal-printer-status-response';

const currentRTStateMapping = (code: string): CurrentRTState => {
  switch (code) {
    case '84':
      return 'missing_certificated';
    case '47':
      return 'not_registered';
    default:
      return 'active';
  }
};

const inactivePeriodMapping = (code: string): RTInactivePeriod => {
  if (code === '0') {
    return 'false';
  }

  if (['54', '61', '70'].includes(code)) {
    return 'waiting_for_daily_closure';
  }

  return 'indeterminate_state';
};

export class CustomQ3XFiscalPrinterRTStatusParser {
  parse({
    response,
  }: CustomQ3XFiscalPrinterStatusResponse): FiscalPrinterRTStatus {
    return {
      isActive: response._attributes.status === '0',
      inactivePeriod: inactivePeriodMapping(response._attributes.status),
      currentRTState: currentRTStateMapping(response._attributes.status),
    };
  }
}
