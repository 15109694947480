import { UntypedFormControl } from '@angular/forms';
import { get, isEqual, isNil } from 'lodash';

import { Places, PlacesInputIds, PlacesInputIdsKey } from '../models';

export const mergePlaces = (
  oldPlaces: PlacesInputIds,
  newPlaces: PlacesInputIds,
): PlacesInputIds => {
  let placesMerged: PlacesInputIds;
  const placesKeys = ['countryId', 'stateId', 'countyId', 'cityId'];
  placesKeys.forEach((key) => {
    const placeValueOld = oldPlaces[key];
    const placeValueNew = newPlaces[key];
    switch (key) {
      case 'countryId': {
        placesMerged = {
          ...placesMerged,
          [key]: isNil(placeValueOld) ? placeValueNew : placeValueOld,
        };
        break;
      }
      case 'stateId': {
        if (placesMerged.countryId === newPlaces.countryId) {
          placesMerged = {
            ...placesMerged,
            [key]: isNil(placeValueOld) ? placeValueNew : placeValueOld,
          };
        } else {
          placesMerged = {
            ...placesMerged,
            [key]: placeValueOld,
          };
        }
        break;
      }
      case 'countyId': {
        if (
          placesMerged.countryId === newPlaces.countryId &&
          placesMerged.stateId === newPlaces.stateId
        ) {
          placesMerged = {
            ...placesMerged,
            [key]: isNil(placeValueOld) ? placeValueNew : placeValueOld,
          };
        } else {
          placesMerged = {
            ...placesMerged,
            [key]: placeValueOld,
          };
        }
        break;
      }
      case 'cityId': {
        if (
          placesMerged.countryId === newPlaces.countryId &&
          placesMerged.stateId === newPlaces.stateId &&
          placesMerged.countyId === newPlaces.countyId
        ) {
          placesMerged = {
            ...placesMerged,
            [key]: isNil(placeValueOld) ? placeValueNew : placeValueOld,
          };
        } else {
          placesMerged = {
            ...placesMerged,
            [key]: placeValueOld,
          };
        }
        break;
      }
    }
  });
  return placesMerged;
};

export const mergePlacesWithName = (
  oldPlaces: Places = <Places>{},
  newPlaces: Places = <Places>{},
): Places => {
  let placesMerged: Places;
  const placesKeys: PlacesInputIdsKey[] = [
    'countryId',
    'stateId',
    'countyId',
    'cityId',
  ];
  placesKeys.forEach((key) => {
    const placeValueOld = get(oldPlaces, key);
    const placeValueNew = get(newPlaces, key);
    const placeValueOldName = get(oldPlaces, key.slice(0, key.length - 2));
    const placeValueNewName = get(newPlaces, key.slice(0, key.length - 2));
    switch (key) {
      case 'countryId': {
        placesMerged = {
          ...placesMerged,
          [key]: isNil(placeValueOld) ? placeValueNew : placeValueOld,
          country: isNil(placeValueOldName)
            ? placeValueNewName
            : placeValueOldName,
        };
        break;
      }
      case 'stateId': {
        if (placesMerged.countryId === newPlaces.countryId) {
          placesMerged = {
            ...placesMerged,
            [key]: isNil(placeValueOld) ? placeValueNew : placeValueOld,
            state: isNil(placeValueOldName)
              ? placeValueNewName
              : placeValueOldName,
          };
        } else {
          placesMerged = {
            ...placesMerged,
            [key]: placeValueOld,
            state: placeValueOldName,
          };
        }
        break;
      }
      case 'countyId': {
        if (
          placesMerged.countryId === newPlaces.countryId &&
          placesMerged.stateId === newPlaces.stateId
        ) {
          placesMerged = {
            ...placesMerged,
            [key]: isNil(placeValueOld) ? placeValueNew : placeValueOld,
            county: isNil(placeValueOldName)
              ? placeValueNewName
              : placeValueOldName,
          };
        } else {
          placesMerged = {
            ...placesMerged,
            [key]: placeValueOld,
            county: placeValueOldName,
          };
        }
        break;
      }
      case 'cityId': {
        if (
          placesMerged.countryId === newPlaces.countryId &&
          placesMerged.stateId === newPlaces.stateId &&
          placesMerged.countyId === newPlaces.countyId
        ) {
          placesMerged = {
            ...placesMerged,
            [key]: isNil(placeValueOld) ? placeValueNew : placeValueOld,
            city: isNil(placeValueOldName)
              ? placeValueNewName
              : placeValueOldName,
          };
        } else {
          placesMerged = {
            ...placesMerged,
            [key]: placeValueOld,
            city: placeValueOldName,
          };
        }
        break;
      }
    }
  });
  return placesMerged;
};

export function autocompletePlaces(
  placesControl: UntypedFormControl,
  newPlaces: Places,
): void {
  const oldPlaces = placesControl.value as Places;
  const placesMerged = mergePlacesWithName(oldPlaces, newPlaces);
  if (isEqual(oldPlaces, newPlaces)) {
    return;
  }
  placesControl.patchValue(placesMerged);
}

export const autoHideBillingPlacesFn = (
  places: PlacesInputIds,
): {
  country: boolean;
  state: boolean;
  county: boolean;
  city: boolean;
} => {
  let placesToShow: {
    country: boolean;
    state: boolean;
    county: boolean;
    city: boolean;
  } = {
    country: true,
    county: true,
    state: true,
    city: true,
  };
  const { countryId } = places;
  if (countryId === 1) {
    placesToShow = {
      country: true,
      state: true,
      county: true,
      city: true,
    };
  } else {
    placesToShow = {
      country: true,
      state: false,
      county: false,
      city: true,
    };
  }
  return placesToShow;
};

export const autoHidePlacesFn = (
  places: Partial<PlacesInputIds>,
): {
  country: boolean;
  state: boolean;
  county: boolean;
  city: boolean;
} => {
  let placesToShow: {
    country: boolean;
    state: boolean;
    county: boolean;
    city: boolean;
  } = {
    country: true,
    county: true,
    state: true,
    city: true,
  };
  const { countryId } = places;
  if (countryId === 1) {
    placesToShow = {
      country: true,
      state: true,
      county: true,
      city: true,
    };
  } else {
    placesToShow = {
      country: true,
      state: false,
      county: false,
      city: false,
    };
  }
  return placesToShow;
};
