import { mapValues } from 'lodash';

export function booleansToNumbers<T>(obj: any): T {
  return mapValues(obj, (value) => {
    if (typeof value === 'boolean') {
      return +value;
    }

    return value;
  });
}
