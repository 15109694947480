import { UntypedFormArray } from '@angular/forms';

export class FormArrayHelper {
  static removeInvalidControls(formArray: UntypedFormArray) {
    // Getting invalid indexes
    const controlsIndexToRemove = formArray.controls.reduce(
      (indexes, control, index) => {
        if (control.invalid) {
          return (indexes = [...indexes, index]);
        }

        return indexes;
      },
      [],
    );

    // Removing invalid indexes
    controlsIndexToRemove.forEach((controlIndex) =>
      formArray.removeAt(controlIndex),
    );
  }
}
