import { Action } from '@ngrx/store';

import { Media, MediaType } from '../../models';

export enum ActionTypes {
  LOAD_REQUEST = '[Medias] Load Request',
  LOAD_FAILURE = '[Medias] Load Failure',
  LOAD_SUCCESS = '[Medias] Load Success',

  DELETE_REQUEST = '[Medias] Delete Request',
  DELETE_FAILURE = '[Medias] Delete Failure',
  DELETE_SUCCESS = '[Medias] Delete Success',

  CREATE_REQUEST = '[Medias] Create Request',
  CREATE_FAILURE = '[Medias] Create Failure',
  CREATE_SUCCESS = '[Medias] Create Success',

  SORT_REQUEST = '[Medias] Sort Request',
  SORT_FAILURE = '[Medias] Sort Failure',
  SORT_SUCCESS = '[Medias] Sort Success',

  RESET_STATE = '[Medias] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(
    public payload: {
      type: MediaType;
      type_id: number;
      all_size?: boolean;
      format_size?: string;
    },
  ) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(
    public payload: { type: MediaType; type_id: number; items: Media[] },
  ) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(
    public payload: { type: MediaType; type_id: number; media_id: number },
  ) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(
    public payload: { type: MediaType; type_id: number; media_id: number },
  ) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: {
      type: MediaType;
      type_id: number;
      image: any;
      genre?: string;
      replaceOld?: boolean;
      disableNotify?: boolean;
    },
  ) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(
    public payload: {
      type: MediaType;
      type_id: number;
      genre: string;
      media: string;
      id: number;
      label?: string;
      replaceOld?: boolean;
      formats_size: {
        small: string;
        large: string;
        medium: string;
      };
    },
  ) {}
}

export class SortRequestAction implements Action {
  readonly type = ActionTypes.SORT_REQUEST;
  constructor(
    public payload: {
      type: MediaType;
      type_id: number;
      ids: number[];
      genre?: string;
    },
  ) {}
}

export class SortFailureAction implements Action {
  readonly type = ActionTypes.SORT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SortSuccessAction implements Action {
  readonly type = ActionTypes.SORT_SUCCESS;
  constructor(
    public payload: {
      type: MediaType;
      type_id: number;
      ids: number[];
      genre?: string;
    },
  ) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | SortRequestAction
  | SortFailureAction
  | SortSuccessAction
  | ResetSuccessAction;
