import { isEqual, sortBy } from 'lodash';

export const propertiesIsEqual = (
  oldProperties: number[] = [],
  newProperties: number[] = [],
): boolean => {
  if (!newProperties || !newProperties.length || !newProperties[0]) {
    return true;
  }
  if (isEqual(sortBy(oldProperties), sortBy(newProperties))) {
    return true;
  } else {
    return false;
  }
};
