import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { IUserDetails } from '../features/commons/user-details/models/user-detail.model';
import { removeNullishValues } from '../helpers';
import { Property, SavePropertyRequest } from '../models';
import { PropertySetAttributes } from '../models/objects/property-set-attributes';
import { SearchParams } from '../models/objects/search-params';

import { UserDetailsService } from './user-details.service';

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  constructor(
    private http: HttpClient,
    private userService: UserDetailsService,
  ) {}

  load(page: number, searchParams: SearchParams) {
    return this.http.get(
      `properties?page=${page || 1}&${generateSearchQuery(searchParams)}`,
    );
  }

  loadDetails(propertyId: number) {
    return this.http.get(`property/${propertyId}`);
  }

  loadLookup(search: string, channel_id: number, active?: number) {
    const data = { search, channel_id, active };
    return this.http.get(
      `properties/lookup?${generateSearchQuery(removeNullishValues(data))}`,
    );
  }

  loadGroupProperties(property_id: number) {
    return this.http.get<IResponseSuccess<[Property[]]>>(
      `admin/properties/${property_id}/properties_group_related`,
    );
  }

  update(request: SavePropertyRequest) {
    return this.http.put(`property/${request.id}`, request);
  }

  updateAttributes(request: PropertySetAttributes) {
    return this.http.post(
      `property/${request.property_id}/set_attributes`,
      request,
    );
  }

  create(request: SavePropertyRequest): Observable<any> {
    if (request.user) {
      return this.createPropertyWithUser(request);
    }
    const { user_id, ...payload } = request;
    if (!user_id) {
      return this.http.post('property', payload);
    }
    return this.http.post('property', payload).pipe(
      switchMap((propertyResponse: IResponseSuccess) => {
        return this.http.post('user/attach_property', {
          user_id: user_id,
          property_id: [propertyResponse.data.id],
        });
      }),
    );
  }

  private createPropertyWithUser(request: SavePropertyRequest) {
    return this.userService.create(request.user as IUserDetails).pipe(
      switchMap((createUserResponse: IResponseSuccess) => {
        request = omit(request, 'user');
        return this.create({
          ...request,
          user_id: createUserResponse.data[0].id,
        }).pipe(
          catchError((error) =>
            this.userService.delete(createUserResponse.data[0].id).pipe(
              map(() => {
                throw error;
              }),
            ),
          ),
        );
      }),
    );
  }

  delete(propertyId: number) {
    return this.http.put(`property/${propertyId}/change_status`, { status: 0 });
  }

  enable(propertyId: number) {
    return this.http.put(`property/${propertyId}/change_status`, {
      status: 1,
    });
  }

  clone(
    sourcePropertyId: number,
    destinationPropertyId: number,
    optionsToClone: string[],
  ) {
    return this.http.post<IResponseSuccess>(`properties/clone`, {
      source_property_id: sourcePropertyId,
      destination_property_id: destinationPropertyId,
      options_to_clone: optionsToClone,
    });
  }
}
