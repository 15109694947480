import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { observableSequence } from '../helpers';
import {
  LoadMaintenanceReportsRequest,
  MaintenanceReport,
  SaveMaintenanceReportRequest,
} from '../models';

import { MediasService } from './medias.service';

type MaintenanceRecordResponse = Observable<
  IResponseSuccess<MaintenanceReport[]>
>;

@Injectable({
  providedIn: 'root',
})
export class MaintenanceReportsService {
  constructor(private http: HttpClient, private mediasService: MediasService) {}

  load({ property_id, ...request }: LoadMaintenanceReportsRequest) {
    return this.http.get(
      `properties/${property_id}/maintenance/records?${generateSearchQuery(
        request,
      )}`,
    );
  }

  create(request: Omit<SaveMaintenanceReportRequest, 'id'>) {
    const { medias, property_id, ...partialRequest } = request;
    return this.handleMedias(
      this.http.post(
        `properties/${property_id}/maintenance/records`,
        partialRequest,
      ) as MaintenanceRecordResponse,
      request,
    );
  }

  update(request: SaveMaintenanceReportRequest) {
    const { medias, property_id, id: report_id, ...partialRequest } = request;
    return this.handleMedias(
      this.http.put(
        `properties/${property_id}/maintenance/records/${report_id}`,
        partialRequest,
      ) as MaintenanceRecordResponse,
      request,
    );
  }

  delete(request: { property_id: number; report_id: number }) {
    return this.http.delete(
      `properties/${request.property_id}/maintenance/records/${request.report_id}`,
    );
  }

  private handleMedias(
    httpRequest$: Observable<IResponseSuccess<MaintenanceReport[]>>,
    request: Partial<SaveMaintenanceReportRequest>,
  ): Observable<IResponseSuccess<MaintenanceReport[]>> {
    return httpRequest$.pipe(
      switchMap((response) => {
        const mediasRequests = [];

        if (request.medias?.delete && request.id) {
          mediasRequests.push(
            this.mediasService.delete(
              'housekeeper_maintainer',
              request.id,
              request.medias.delete,
            ),
          );
        }

        if (request.medias?.upload) {
          mediasRequests.push(
            this.mediasService.create(
              'housekeeper_maintainer',
              response.data[0].id,
              request.medias.upload,
              null,
            ),
          );
        }

        return observableSequence(mediasRequests).pipe(
          map((mediaResponses) => {
            const media = mediaResponses.find(
              (mediaResponse) => mediaResponse.data.id,
            );

            return {
              ...response,
              data: [{ ...response.data[0], media: media?.data }],
            };
          }),
        );
      }),
    );
  }
}
