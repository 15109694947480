import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, upperFirst } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { merge } from 'rxjs';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { IResponseSuccess } from '../../../core/models/response-sucess.model';
import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { removeNullishValues } from '../../../helpers';
import {
  FiscalPrinter,
  ICreditNoteRequestRoot,
  IInvoiceLayout,
  InvoiceDetails,
  InvoicePrintingSessionRequest,
  RegisterInvoice,
} from '../../../models';
import { InvoiceRegisterServiceParams } from '../../../models/objects/invoice-register-service-params';
import { InvoicesLayoutsService } from '../../../services/invoices-layouts.service';
import { TemporaryInvoiceService } from '../../../services/temporary-invoice.service';
import { WarningConfirmModalService } from '../../../services/warning-confirm-modal.service';
import { NotificationService } from '../../../ui/services/notification.service';
import { FiscalPrinterResolverService } from '../../../use-cases/fiscal-printers/fiscal-printer-resolver.service';
import { InvoiceRegisterModalOutput } from '../invoice-register-modal/invoice-register-modal.component';
import { ReversalOrCreditNoteRegisterModalComponent } from '../reversal-or-credit-note-register-modal/reversal-or-credit-note-register-modal.component';

import { InvoiceRegisterService } from './invoice-register.service';

@Injectable({ providedIn: 'root' })
export class CreditNoteRegisterService extends InvoiceRegisterService {
  registerLoading: boolean;

  constructor(
    protected modalService: NzModalService,
    protected message: NzMessageService,
    protected translate: TranslateService,
    protected notification: NotificationService,
    protected warningConfirmModalService: WarningConfirmModalService,
    protected dateFormatter: DateFormatterService,
    protected invoicesLayoutsService: InvoicesLayoutsService,
    private fiscalPrinterServiceResolver: FiscalPrinterResolverService,
    private temporaryInvoiceService: TemporaryInvoiceService,
  ) {
    super(
      modalService,
      message,
      translate,
      notification,
      warningConfirmModalService,
      dateFormatter,
      invoicesLayoutsService,
    );
  }

  /**
   * @override
   *
   * @description flusso di registrazione della nota di credito:
   * - apertura modale nota di credito
   * - richiesta xml (se si tratta di uno storno fiscale)
   * - stampa documento fiscale
   * - chiamata di register
   */
  register(params: InvoiceRegisterServiceParams): Observable<InvoiceDetails> {
    const { invoice, layout, registerService } = params;

    return this.getRegisterCreditNotePayload(invoice, layout).pipe(
      switchMap((payload) => {
        if (!payload) {
          throw new Error('Register credit note modal closed by user');
        }

        return registerService.createCreditNote(payload).pipe(
          switchMap((response: IResponseSuccess<InvoiceDetails[]>) => {
            const creditNote: InvoiceDetails = response.data[0];

            if (invoice.printer_id) {
              return this.registerFiscalReversalReceipt(
                params,
                creditNote,
                payload,
              );
            }

            const { sdi_send } = payload;

            const utils =
              this.registerUtilsByType[
                invoice.type as 'invoice' | 'receipt' | 'credit_note'
              ];

            const modalOutput$ = new Subject<InvoiceRegisterModalOutput>();

            this.registerLoading = false;

            const modal: NzModalRef = this.modalService.create({
              nzTitle: upperFirst(
                this.translate.instant(
                  invoice.type === 'receipt'
                    ? 'register_reversal_receipt'
                    : 'register_credit_note',
                ),
              ),
              nzContent: utils.class,
              nzData: {
                invoice: {
                  ...invoice,
                  number: null,
                  invoice_date: null,
                  sectional_id: null,
                },
                layout,
              },
              nzClosable: false,
              nzMaskClosable: false,
              nzFooter: [
                {
                  label: upperFirst(this.translate.instant('cancel')),
                  type: 'default',
                  disabled: () => this.registerLoading,
                  onClick: () => modal.close(),
                },
                {
                  label: upperFirst(
                    this.translate.instant(
                      sdi_send ? 'register_and_send_to_sdi' : 'save',
                    ),
                  ),
                  type: 'primary',
                  loading: () => this.registerLoading,
                  disabled: utils.disabled,
                  onClick: (component) => modalOutput$.next(component.value),
                },
              ],
            });

            return merge(
              modalOutput$.asObservable(),
              modal.afterClose.asObservable(),
            ).pipe(
              switchMap((modalOutput?: InvoiceRegisterModalOutput) => {
                if (!modalOutput) {
                  modal.close();
                  throw new Error('Invoice register modal closed by user');
                }

                const { number, invoice_layout_sectional_id, invoice_date } =
                  modalOutput;
                return this.updateInvoice({
                  ...params,
                  invoice: creditNote,
                }).pipe(
                  switchMap(() =>
                    this.registerRequest(creditNote, payload, {
                      number,
                      invoice_layout_sectional_id,
                      invoice_date: invoice_date,
                    }).pipe(
                      tap(() => {
                        modal.close();
                      }),
                    ),
                  ),
                  catchError((error) => {
                    modal.close();
                    return throwError(error);
                  }),
                );
              }),
            );
          }),
        );
      }),
    );
  }

  private registerRequest(
    creditNote: InvoiceDetails,
    request: ICreditNoteRequestRoot,
    partialPayload?: Partial<RegisterInvoice>,
  ) {
    this.registerLoading = true;
    return this.temporaryInvoiceService
      .register(
        this.dateFormatter.formatObjectDates({
          ...request,
          type: 'credit_note',
          invoice_id: creditNote.id,
          invoice_date: new Date(),
          force_operation: true,
          invoice_layout_sectional_id: creditNote.invoice_sectional_id,
          ...partialPayload,
        }),
      )
      .pipe(
        map(({ data }: IResponseSuccess<InvoiceDetails[]>) => {
          this.registerLoading = false;
          return data[0];
        }),
      );
  }

  /**
   * @override
   */
  protected updateInvoice(params: InvoiceRegisterServiceParams) {
    const { invoice, updatePayload } = params;

    return updatePayload
      ? this.temporaryInvoiceService.update(invoice.id, updatePayload)
      : of({});
  }

  private registerFiscalReversalReceipt(
    params: InvoiceRegisterServiceParams,
    creditNote: InvoiceDetails,
    request: ICreditNoteRequestRoot,
  ) {
    const { invoice, layout } = params;

    return this.getFiscalPrinter(invoice, layout).pipe(
      switchMap((userInput) => {
        if (!userInput) {
          throw new Error('Fiscal printer modal closed by user');
        }

        const {
          printer,
          clousure_number: clousureNumber,
          number: printedReceiptNumber,
          invoice_date: printedReceiptInvoiceDate,
        } = userInput;

        let flow$: Observable<{
          number: string;
          clousure_number: number;
          invoice_date?: string;
          print_session_id?: number;
          description?: string;
          external_id?: string;
        }>;

        if (!!printedReceiptNumber) {
          flow$ = of({
            number: printedReceiptNumber,
            clousure_number: clousureNumber,
            invoice_date: this.dateFormatter.toServerFormat(
              printedReceiptInvoiceDate,
            ),
          });
        } else {
          const print_session_id = first(creditNote.printing_sessions)?.id;

          flow$ = this.printReversalFiscalReceipt(invoice, printer).pipe(
            map((printerResponse) => ({
              ...printerResponse,
              print_session_id,
            })),
            catchError((error: InvoicePrintingSessionRequest) => {
              return this.temporaryInvoiceService
                .updatePrintingSession(invoice.id, print_session_id, error)
                .pipe(
                  switchMap(() => {
                    if (error.notification) {
                      this.notification.push({
                        type: 'error',
                        title: `[${error.notification.code}] ${error.notification.title}`,
                        content: error.notification.description,
                      });
                    } else {
                      this.notification.error('fiscal_printer_error');
                    }

                    return throwError(error);
                  }),
                );
            }),
          );
        }

        return flow$.pipe(
          switchMap(
            ({
              number,
              clousure_number,
              invoice_date,
              print_session_id,
              description,
              external_id,
            }) => {
              // Registro il documento
              const messageRef = this.createRegisterLoading();
              return this.registerRequest(
                creditNote,
                request,
                removeNullishValues({
                  number,
                  external_id,
                  clousure_number,
                  invoice_date,
                  printer_id: printer.id,
                  sdi_send: 0,
                  fp_send: 1,
                  print_session_id,
                  description,
                }),
              ).pipe(
                tap(() => this.removeRegisterLoading(messageRef)),
                catchError((error) => {
                  this.removeRegisterLoading(messageRef);
                  return throwError(error);
                }),
              );
            },
          ),
          catchError((error) => {
            return throwError(error);
          }),
        );
      }),
    );
  }

  private printReversalFiscalReceipt(
    invoice: InvoiceDetails,
    fiscalPrinter: FiscalPrinter,
  ) {
    const service = this.fiscalPrinterServiceResolver.getInstance(
      fiscalPrinter.device_model_id,
    );

    return from(
      service.printReversalReceiptFiscal(fiscalPrinter.id, invoice.id),
    );
  }

  private getRegisterCreditNotePayload(
    invoice: InvoiceDetails,
    layout: IInvoiceLayout,
  ): Observable<ICreditNoteRequestRoot> {
    let payload: Partial<ICreditNoteRequestRoot> = {
      total: 1,
      invoice_id: invoice.id,
      invoice_date: invoice.invoice_date,
      property_id: invoice.property_id,
      now: this.dateFormatter.toServerFormat(new Date()),
      sdi_send: +(
        !!layout.invoice_module?.status && invoice.type === 'invoice'
      ),
    };

    if (invoice.printer_id) {
      payload = {
        ...payload,
        clousure_number: invoice.clousure_number,
        printer_id: invoice.printer_id,
        type: 'receipt',
        sdi_send: 0,
        fp_send: 1,
      };
    }

    if (invoice && +invoice.paid === 0) {
      return of({ ...payload, payment_method_id: null, mark_as_paid: 1 });
    }

    const modal = this.modalService.create<
      ReversalOrCreditNoteRegisterModalComponent,
      Partial<ReversalOrCreditNoteRegisterModalComponent>
    >({
      nzContent: ReversalOrCreditNoteRegisterModalComponent,
      nzData: { invoice },
      nzTitle: null,
      nzFooter: [
        {
          label: upperFirst(this.translate.instant('cancel')),
          type: 'default',
          onClick: () => {
            modal.close();
          },
        },
        {
          label: upperFirst(this.translate.instant('save')),
          type: 'primary',
          onClick: (content) => {
            modal.close({ ...payload, ...content.value });
          },
        },
      ],
    });

    return modal.afterClose.asObservable();
  }
}
