import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CartTypesService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('general/credit_cards_types');
  }
}
