import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateEventRequest, UpdateEventRequest } from '@app/models';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { removeAllNullishValues } from '../helpers';
import { SearchParams } from '../models/objects/search-params';
import { LoadEventRequest } from '../models/requests/load-event-request';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private http: HttpClient) {}

  load(payload: LoadEventRequest) {
    return this.http.get(
      `tableau/events?${generateSearchQuery(removeAllNullishValues(payload))}`,
    );
  }

  create(propertyId: number, request: CreateEventRequest) {
    return this.http.post(`tableau/${propertyId}/event`, request);
  }

  update(propertyId: number, eventId: number, request: UpdateEventRequest) {
    return this.http.put(`tableau/${propertyId}/event/${eventId}`, request);
  }

  delete(propertyId: number, eventId: number) {
    return this.http.delete(`tableau/${propertyId}/event/${eventId}`);
  }
}
