import { IReservationTag } from '@app/models/objects/reservation-tag';
import { Action } from '@ngrx/store';

import { TableauMapping, Treatment } from '../../../../models';
import { EmailPreview } from '../models/email-preview.model';
import { NoteApi, NoteDetails } from '../models/note.model';
import { QuoteApi } from '../models/quote.model';
import { TableauOptions } from '../models/tableau-options.model';
import { TableauReservationSwap } from '../models/tableau-reservation-swap.model';
import {
  IReservationTableau,
  TableauReservationsRoom,
} from '../models/tableau-reservation.model';
import { TableauAvailability } from '../models/tableau-reservation.model.1';
import { TableauCheckInCheckOut } from '../models/tableau-udpate-checkin-checkout.model';
import { TableauUpdateRoomLabel } from '../models/tableau-udpate-room-label.model';
import { ITreatmentReservations } from '../models/treatmentReservation.model';

import { IRateplan } from './../../rateplans/models/rateplan.model';
import {
  InfoPriceReservationData,
  InfoPriceReservationRequest,
} from './../models/info-price-reservation.model';
import { TableauCloseRoom } from './../models/tableau-close-room.model';
import { TableauUpdateRoomOnSwap } from './../models/tableau-udpate-room-onswap.model.1';

export enum TableauActionTypes {
  SetTableauPeriod = '[Tableau] Set Period',

  SetTableauPeriodAddRight = '[Tableau] Set Period Add Right',
  SetTableauPeriodAddLeft = '[Tableau] Set Period Add Left',

  SetTableauOptions = '[Tableau] Set Options',

  SetTableauPropertyIds = '[Tableau] Set PropertyIds',

  NewReservationEmailPreviewRequest = '[Tableau] New Reservation Email Preview Request',
  NewReservationEmailPreviewSuccess = '[Tableau] New Reservation Email Preview Success',
  NewReservationEmailPreviewFailure = '[Tableau] New Reservation Email Preview Failure',

  LoadTableauEventsRequest = '[Tableau] Load Events Request',
  LoadTableauEventsSuccess = '[Tableau] Load Events Success',
  LoadTableauEventsFailure = '[Tableau] Load Events Failure',

  LoadTableauReservationsRequest = '[Tableau] Load Reservations Request',
  LoadTableauReservationsSuccess = '[Tableau] Load Reservations Success',
  LoadTableauReservationsFailure = '[Tableau] Load Reservations Failure',

  LoadTreatmentsReservationsRequest = '[Tableau] Load Treatments Reservations Request',
  LoadTreatmentsReservationsSuccess = '[Tableau] Load Treatments Reservations Success',
  LoadTreatmentsReservationsFailure = '[Tableau] Load Treatments Reservations Failure',

  LoadTableauAvailabilitiesRequest = '[Tableau] Load Availabilities Request',
  LoadTableauAvailabilitiesSuccess = '[Tableau] Load Availabilities Success',
  LoadTableauAvailabilitiesFailure = '[Tableau] Load Availabilities Failure',

  LoadTableauMappingRequest = '[Tableau] Load Mapping Request',
  LoadTableauMappingSuccess = '[Tableau] Load Mapping Success',
  LoadTableauMappingFailure = '[Tableau] Load Mapping Failure',

  LoadTableauTreatmentsRequest = '[Tableau] Load Treatments Request',
  LoadTableauTreatmentsSuccess = '[Tableau] Load Treatments Success',
  LoadTableauTreatmentsFailure = '[Tableau] Load Treatments Failure',

  LoadTableauRateplansRequest = '[Tableau] Load Rateplans Request',
  LoadTableauRateplansSuccess = '[Tableau] Load Rateplans Success',
  LoadTableauRateplansFailure = '[Tableau] Load Rateplans Failure',

  UpdateRoomLabelRequest = '[Tableau] Edit Tableau Room Label Request',
  UpdateRoomLabelSuccess = '[Tableau] Edit Tableau Room Label Success',
  UpdateRoomLabelFailure = '[Tableau] Edit Tableau Room Label Failure',

  UpdateRoomOnSwapRequest = '[Tableau] Edit Tableau Room On Swap Request',
  UpdateRoomOnSwapSuccess = '[Tableau] Edit Tableau Room On Swap Success',
  UpdateRoomOnSwapFailure = '[Tableau] Edit Tableau Room On Swap Failure',

  UpdateCheckinCheckoutReservationRequest = '[Tableau] Edit Tableau Reservation Checkin Checkout Request',
  UpdateCheckinCheckoutReservationSuccess = '[Tableau] Edit Tableau Reservation Checkin Checkout Success',
  UpdateCheckinCheckoutReservationFailure = '[Tableau] Edit Tableau Reservation Checkin Checkout Failure',

  CloseRoomRequest = '[Tableau] Close Room Request',
  CloseRoomSuccess = '[Tableau] Close Room Success',
  CloseRoomFailure = '[Tableau] Close Room Failure',

  DeleteCloseRoomRequest = '[Tableau] Delete Close Room Request',
  DeleteCloseRoomSuccess = '[Tableau] Delete Close Room Success',
  DeleteCloseRoomFailure = '[Tableau] Delete Close Room Failure',

  LoadNotesRequest = '[Tableau] Load Notes Request',
  LoadNotesSuccess = '[Tableau] Load Notes Success',
  LoadNotesFailure = '[Tableau] Load Notes Failure',

  LoadQuotesRequest = '[Tableau] Load Quotes Request',
  LoadQuotesSuccess = '[Tableau] Load Quotes Success',
  LoadQuotesFailure = '[Tableau] Load Quotes Failure',

  CreateNoteRequest = '[Tableau] Create Note Request',
  CreateNoteSuccess = '[Tableau] Create Note Success',
  CreateNoteFailure = '[Tableau] Create Note Failure',

  DeleteNoteRequest = '[Tableau] Delete Note Request',
  DeleteNoteSuccess = '[Tableau] Delete Note Success',
  DeleteNoteFailure = '[Tableau] Delete Note Failure',

  UpdateNoteRequest = '[Tableau] Update Note Request',
  UpdateNoteSuccess = '[Tableau] Update Note Success',
  UpdateNoteFailure = '[Tableau] Update Note Failure',

  SplitLocalReservation = '[Tableau] Split Local Reservation',

  LoadInfoPriceReservationRequest = '[Tableau] Load Info Reservation Request',
  LoadInfoPriceReservationSuccess = '[Tableau] Load Info Reservation Success',
  LoadInfoPriceReservationFailure = '[Tableau] Load Info Reservation Failure',

  SetLoading = '[Tableau] Set Loading',

  ResetTableauState = '[Tableau] Reset Tableau State',

  UpdateTableauReservationTag = '[Tableau] Update ReservationTag',

  ResetInfoPriceReservation = '[Tableau] Reset Info Price State',
}

export class NewReservationEmailPreviewRequest implements Action {
  public readonly type = TableauActionTypes.NewReservationEmailPreviewRequest;
  constructor(
    public payload: {
      newReservationData: any;
      templateId: number;
      lang_iso_code?: string;
    },
  ) {}
}

export class NewReservationEmailPreviewSuccess implements Action {
  public readonly type = TableauActionTypes.NewReservationEmailPreviewSuccess;
  constructor(public payload: { emailPreview: EmailPreview }) {}
}

export class NewReservationEmailPreviewFailure implements Action {
  public readonly type = TableauActionTypes.NewReservationEmailPreviewFailure;
  constructor(public payload: { error: Error }) {}
}

export class SetTableauPeriod implements Action {
  public readonly type = TableauActionTypes.SetTableauPeriod;
  constructor(
    public payload: { startDate: Date; endDate: Date; propertiesIds: number[] },
  ) {}
}

export class SetTableauPeriodAddRight implements Action {
  public readonly type = TableauActionTypes.SetTableauPeriodAddRight;
  constructor(
    public payload: { startDate: Date; endDate: Date; propertiesIds: number[] },
  ) {}
}

export class SetTableauPeriodAddLeft implements Action {
  public readonly type = TableauActionTypes.SetTableauPeriodAddLeft;
  constructor(
    public payload: { startDate: Date; endDate: Date; propertiesIds: number[] },
  ) {}
}

export class LoadTableauEventsRequest implements Action {
  public readonly type = TableauActionTypes.LoadTableauEventsRequest;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class LoadTableauEventsSuccess implements Action {
  public readonly type = TableauActionTypes.LoadTableauEventsSuccess;
  constructor(public payload: { events: any[] }) {}
}

export class LoadTableauEventsFailure implements Action {
  public readonly type = TableauActionTypes.LoadTableauEventsFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadTreatmentsReservationsRequest implements Action {
  public readonly type = TableauActionTypes.LoadTreatmentsReservationsRequest;
  constructor(
    public payload: {
      reservation_id: number;
      reservation_accommodation_room_id: number;
    },
  ) {}
}

export class LoadTreatmentsReservationsSuccess implements Action {
  public readonly type = TableauActionTypes.LoadTreatmentsReservationsSuccess;
  constructor(
    public payload: { treatmentsReservation: ITreatmentReservations },
  ) {}
}

export class LoadTreatmentsReservationsFailure implements Action {
  public readonly type = TableauActionTypes.LoadTreatmentsReservationsFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadTableauTreatmentsRequest implements Action {
  readonly type = TableauActionTypes.LoadTableauTreatmentsRequest;
  constructor(
    public payload: { accommodation_id: number; rateplan_id: number },
  ) {}
}

export class LoadTableauTreatmentsSuccess implements Action {
  public readonly type = TableauActionTypes.LoadTableauTreatmentsSuccess;
  constructor(public payload: { treatments: Treatment[] }) {}
}

export class LoadTableauTreatmentsFailure implements Action {
  public readonly type = TableauActionTypes.LoadTableauTreatmentsFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadTableauRateplansRequest implements Action {
  readonly type = TableauActionTypes.LoadTableauRateplansRequest;
  constructor(public payload: { accommodation_id: number }) {}
}
export class LoadTableauRateplansSuccess implements Action {
  public readonly type = TableauActionTypes.LoadTableauRateplansSuccess;
  constructor(public payload: { rateplans: IRateplan[] }) {}
}

export class LoadTableauRateplansFailure implements Action {
  public readonly type = TableauActionTypes.LoadTableauRateplansFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadTableauReservationsRequest implements Action {
  readonly type = TableauActionTypes.LoadTableauReservationsRequest;
  constructor(
    public payload: {
      propertiesIds: number[];
      withDetails: boolean;
      noReload?: boolean;
    },
  ) {}
}

export class LoadTableauReservationsSuccess implements Action {
  readonly type = TableauActionTypes.LoadTableauReservationsSuccess;
  constructor(
    public payload: { reservations: IReservationTableau; properties: number[] },
  ) {}
}

export class LoadTableauReservationsFailure implements Action {
  readonly type = TableauActionTypes.LoadTableauReservationsFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadTableauAvailabilitiesRequest implements Action {
  readonly type = TableauActionTypes.LoadTableauAvailabilitiesRequest;
  constructor(
    public payload: {
      propertiesIds: number[];
      startDate?: Date;
      endDate?: Date;
    },
  ) {}
}

export class LoadTableauAvailabilitiesSuccess implements Action {
  readonly type = TableauActionTypes.LoadTableauAvailabilitiesSuccess;
  constructor(
    public payload: {
      availabilities: TableauAvailability;
    },
  ) {}
}

export class LoadTableauAvailabilitiesFailure implements Action {
  readonly type = TableauActionTypes.LoadTableauAvailabilitiesFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadTableauMappingRequest implements Action {
  readonly type = TableauActionTypes.LoadTableauMappingRequest;
  constructor(
    public payload: { propertiesIds: number[]; referenceDate: string },
  ) {}
}

export class LoadTableauMappingSuccess implements Action {
  readonly type = TableauActionTypes.LoadTableauMappingSuccess;
  constructor(public payload: { mappings: TableauMapping }) {}
}

export class LoadTableauMappingFailure implements Action {
  readonly type = TableauActionTypes.LoadTableauMappingFailure;
  constructor(public payload: { error: Error }) {}
}

export class UpdateRoomLabelRequest implements Action {
  readonly type = TableauActionTypes.UpdateRoomLabelRequest;
  constructor(
    public payload: { propertyId: number; data: TableauUpdateRoomLabel },
  ) {}
}

export class UpdateRoomLabelSuccess implements Action {
  readonly type = TableauActionTypes.UpdateRoomLabelSuccess;
  constructor(
    public payload: {
      propertyId: number;
      data: TableauUpdateRoomLabel;
      roomLabelChange?: TableauUpdateRoomLabel[];
    },
  ) {}
}

export class UpdateRoomLabelFailure implements Action {
  readonly type = TableauActionTypes.UpdateRoomLabelFailure;
  constructor(public payload: { error: Error }) {}
}

export class UpdateRoomOnSwapRequest implements Action {
  readonly type = TableauActionTypes.UpdateRoomOnSwapRequest;
  constructor(
    public payload: {
      onSwapData: TableauReservationSwap;
      data: TableauUpdateRoomOnSwap;
    },
  ) {}
}

export class UpdateRoomOnSwapSuccess implements Action {
  readonly type = TableauActionTypes.UpdateRoomOnSwapSuccess;
  constructor(
    public payload: {
      onSwapData: TableauReservationSwap;
      data: TableauUpdateRoomOnSwap;
    },
  ) {}
}

export class UpdateRoomOnSwapFailure implements Action {
  readonly type = TableauActionTypes.UpdateRoomOnSwapFailure;
  constructor(public payload: { error: Error }) {}
}

export class UpdateCheckinCheckoutReservationRequest implements Action {
  readonly type = TableauActionTypes.UpdateCheckinCheckoutReservationRequest;
  constructor(
    public payload: {
      roomreservation_id: string;
      checkin: boolean;
      checkout: boolean;
    },
  ) {}
}

export class UpdateCheckinCheckoutReservationSuccess implements Action {
  readonly type = TableauActionTypes.UpdateCheckinCheckoutReservationSuccess;
  constructor(
    public payload: {
      data: TableauCheckInCheckOut[];
      checkin: boolean;
      checkout: boolean;
    },
  ) {}
}

export class UpdateCheckinCheckoutReservationFailure implements Action {
  readonly type = TableauActionTypes.UpdateCheckinCheckoutReservationFailure;
  constructor(public payload: { error: Error }) {}
}

export class CloseRoomRequest implements Action {
  readonly type = TableauActionTypes.CloseRoomRequest;
  constructor(public payload: { data: TableauCloseRoom }) {}
}

export class CloseRoomSuccess implements Action {
  readonly type = TableauActionTypes.CloseRoomSuccess;
  constructor(public payload: { data: TableauCloseRoom }) {}
}

export class CloseRoomFailure implements Action {
  readonly type = TableauActionTypes.CloseRoomFailure;
  constructor(public payload: { error: Error }) {}
}
export class DeleteCloseRoomRequest implements Action {
  readonly type = TableauActionTypes.DeleteCloseRoomRequest;
  constructor(public payload: { data: TableauCloseRoom }) {}
}

export class DeleteCloseRoomSuccess implements Action {
  readonly type = TableauActionTypes.DeleteCloseRoomSuccess;
  constructor(public payload: { data: TableauCloseRoom }) {}
}

export class DeleteCloseRoomFailure implements Action {
  readonly type = TableauActionTypes.DeleteCloseRoomFailure;
  constructor(public payload: { error: Error }) {}
}

export class SetTableauOptions implements Action {
  readonly type = TableauActionTypes.SetTableauOptions;
  constructor(public payload: { options: TableauOptions }) {}
}

export class SetTableauPropertyIds implements Action {
  readonly type = TableauActionTypes.SetTableauPropertyIds;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class LoadNotesRequest implements Action {
  readonly type = TableauActionTypes.LoadNotesRequest;
  constructor(
    public payload: { propertiesIds: number[]; noReload?: boolean },
  ) {}
}

export class LoadNotesSuccess implements Action {
  readonly type = TableauActionTypes.LoadNotesSuccess;
  constructor(public payload: { notes: NoteApi }) {}
}

export class LoadNotesFailure implements Action {
  readonly type = TableauActionTypes.LoadNotesFailure;
  constructor(public payload: { error: Error }) {}
}
export class LoadQuotesRequest implements Action {
  readonly type = TableauActionTypes.LoadQuotesRequest;
  constructor(
    public payload: { propertiesIds?: number[]; noReload?: boolean },
  ) {}
}

export class LoadQuotesSuccess implements Action {
  readonly type = TableauActionTypes.LoadQuotesSuccess;
  constructor(
    public payload: {
      quotes: QuoteApi;
      noMerge?: boolean;
      propertiesIds: number[];
    },
  ) {}
}

export class LoadQuotesFailure implements Action {
  readonly type = TableauActionTypes.LoadQuotesFailure;
  constructor(public payload: { error: Error }) {}
}

export class CreateNoteRequest implements Action {
  readonly type = TableauActionTypes.CreateNoteRequest;
  constructor(public payload: { newNote: NoteDetails }) {}
}

export class CreateNoteSuccess implements Action {
  readonly type = TableauActionTypes.CreateNoteSuccess;
  constructor(public payload: { newNote: NoteDetails }) {}
}

export class CreateNoteFailure implements Action {
  readonly type = TableauActionTypes.CreateNoteFailure;
  constructor(public payload: { error: Error }) {}
}

export class UpdateNoteRequest implements Action {
  readonly type = TableauActionTypes.UpdateNoteRequest;
  constructor(public payload: { editNote: NoteDetails }) {}
}

export class UpdateNoteSuccess implements Action {
  readonly type = TableauActionTypes.UpdateNoteSuccess;
  constructor(public payload: { editNote: NoteDetails }) {}
}

export class UpdateNoteFailure implements Action {
  readonly type = TableauActionTypes.UpdateNoteFailure;
  constructor(public payload: { error: Error }) {}
}

export class DeleteNoteRequest implements Action {
  readonly type = TableauActionTypes.DeleteNoteRequest;
  constructor(public payload: { deleteNote: NoteDetails }) {}
}

export class DeleteNoteSuccess implements Action {
  readonly type = TableauActionTypes.DeleteNoteSuccess;
  constructor(public payload: { deleteNote: NoteDetails }) {}
}

export class DeleteNoteFailure implements Action {
  readonly type = TableauActionTypes.DeleteNoteFailure;
  constructor(public payload: { error: Error }) {}
}

export class SplitLocalReservation implements Action {
  readonly type = TableauActionTypes.SplitLocalReservation;
  constructor(
    public payload: {
      reservation: TableauReservationsRoom;
      daySplit: Date;
      accommodationId?: number;
      accommodation_tableau_number_id?: number;
    },
  ) {}
}

export class ResetInfoPriceReservation implements Action {
  readonly type = TableauActionTypes.ResetInfoPriceReservation;
}
export class LoadInfoPriceReservationRequest implements Action {
  readonly type = TableauActionTypes.LoadInfoPriceReservationRequest;
  constructor(
    public payload: { dataGetInfoPrice: InfoPriceReservationRequest },
  ) {}
}

export class LoadInfoPriceReservationSuccess implements Action {
  readonly type = TableauActionTypes.LoadInfoPriceReservationSuccess;
  constructor(
    public payload: { infoPricesReservation: InfoPriceReservationData },
  ) {}
}

export class LoadInfoPriceReservationFailure implements Action {
  readonly type = TableauActionTypes.LoadInfoPriceReservationFailure;
  constructor(public payload: { error: Error }) {}
}

export class SetLoading implements Action {
  readonly type = TableauActionTypes.SetLoading;

  constructor(public payload: { loading: boolean }) {}
}

export class ResetTableauState implements Action {
  readonly type = TableauActionTypes.ResetTableauState;
}

export class UpdateTableauReservationTag implements Action {
  readonly type = TableauActionTypes.UpdateTableauReservationTag;
  constructor(
    public payload: {
      reservation_id: number;
      tag?: IReservationTag;
      reset?: boolean;
    },
  ) {}
}

export type TableauActions =
  | SetTableauPeriod
  | SetTableauPeriodAddRight
  | SetTableauPeriodAddLeft
  | LoadTableauEventsRequest
  | LoadTableauEventsSuccess
  | LoadTableauEventsFailure
  | LoadTableauReservationsRequest
  | LoadTableauReservationsSuccess
  | LoadTableauReservationsFailure
  | LoadTableauAvailabilitiesRequest
  | LoadTableauAvailabilitiesSuccess
  | LoadTableauAvailabilitiesFailure
  | LoadTableauMappingRequest
  | LoadTableauMappingSuccess
  | LoadTableauMappingFailure
  | LoadTreatmentsReservationsRequest
  | LoadTreatmentsReservationsSuccess
  | LoadTreatmentsReservationsFailure
  | LoadTableauRateplansRequest
  | LoadTableauRateplansSuccess
  | LoadTableauRateplansFailure
  | LoadTableauTreatmentsRequest
  | LoadTableauTreatmentsSuccess
  | LoadTableauTreatmentsFailure
  | SetTableauOptions
  | SetTableauPropertyIds
  | UpdateRoomLabelRequest
  | UpdateRoomLabelSuccess
  | UpdateRoomLabelFailure
  | CloseRoomRequest
  | CloseRoomSuccess
  | CloseRoomFailure
  | UpdateRoomOnSwapRequest
  | UpdateRoomOnSwapSuccess
  | UpdateRoomOnSwapFailure
  | DeleteCloseRoomRequest
  | DeleteCloseRoomSuccess
  | DeleteCloseRoomFailure
  | LoadNotesRequest
  | LoadNotesSuccess
  | LoadNotesFailure
  | LoadQuotesRequest
  | LoadQuotesSuccess
  | LoadQuotesFailure
  | CreateNoteRequest
  | CreateNoteSuccess
  | CreateNoteFailure
  | DeleteNoteRequest
  | DeleteNoteSuccess
  | DeleteNoteFailure
  | UpdateNoteRequest
  | UpdateNoteSuccess
  | UpdateNoteFailure
  | UpdateCheckinCheckoutReservationRequest
  | UpdateCheckinCheckoutReservationSuccess
  | UpdateCheckinCheckoutReservationFailure
  | SplitLocalReservation
  | LoadInfoPriceReservationRequest
  | LoadInfoPriceReservationSuccess
  | LoadInfoPriceReservationFailure
  | SetLoading
  | NewReservationEmailPreviewRequest
  | NewReservationEmailPreviewSuccess
  | NewReservationEmailPreviewFailure
  | ResetTableauState
  | UpdateTableauReservationTag
  | ResetInfoPriceReservation;
