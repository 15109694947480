import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class AccommodationsService {
  constructor(private http: HttpClient) {}

  loadLookup(propertyIds: number[], withTrashed = false) {
    return this.http.get(
      `accommodations/lookup?${generateParamArray(
        'property_id',
        propertyIds,
      )}&order=order:ASC&with_trashed=${+withTrashed}`,
    );
  }

  load(propertyId: number) {
    return this.http.get(`property/${propertyId}/accommodations`);
  }

  loadBedTypesBol() {
    return this.http.get(`general/accommodation/beds_types_bol`);
  }

  loadMultiProperty(propertiesIds: number[]) {
    return this.http.get(
      `accommodations?${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  delete(accommodationId: number) {
    return this.http.delete(`accommodation/${accommodationId}`);
  }

  order(accommodationsIds: number[]) {
    return this.http.post(`accommodation/order`, {
      id: accommodationsIds,
    });
  }

  setRoomDetails(data: {
    accommodation_id: number;
    accommodation_tableau_number_id: number;
    label: string;
    door_key_codes: string;
  }) {
    return this.http.put(
      `accommodation/number/${data.accommodation_tableau_number_id}`,
      data,
    );
  }
}
