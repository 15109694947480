import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ChannelsCheckInActivation } from '../models';
import { ChannelsCheckInRequest } from '../models/requests/channels-check-in.request';

@Injectable({
  providedIn: 'root',
})
export class ChannelsCheckInService {
  constructor(private http: HttpClient) {}

  load(reservationId: number) {
    return this.http.get(`reservation/${reservationId}/channel_checkin`);
  }

  update(reservationId: number, payload: ChannelsCheckInRequest) {
    return this.http.post(
      `reservation/${reservationId}/channel_checkin`,
      payload,
    );
  }

  activation(payload: ChannelsCheckInActivation) {
    const { channel_id, property_id } = payload;
    return this.http.put(
      `channelmanager/checkinonline/${channel_id}/${property_id}`,
      payload,
    );
  }
}
