import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';

import { handleFormSubmit } from '../core/helpers/handleFormSubmit';
import { BookingPromotionFormComponent } from '../features/commons/booking-promotion-details/booking-promotion-form/booking-promotion-form.component';
import { BookingPromotionFormModal } from '../models';

@Injectable()
export class BookingDetailsPromotionModalSerivice {
  constructor(
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {}

  open(data: Partial<BookingPromotionFormModal>) {
    const {
      type,
      targetOptions,
      showXmlSelect,
      xmlOptions,
      property_xml_id,
      promotion,
    } = data;
    const modal = this.modalService.create<
      BookingPromotionFormComponent,
      Partial<BookingPromotionFormComponent>
    >({
      nzContent: BookingPromotionFormComponent,
      nzData: {
        _type: type,
        _showXmlSelect: showXmlSelect,
        _targetOptions: targetOptions,
        _xmlOptions: xmlOptions,
        _property_xml_id: property_xml_id,
        _promotion: promotion,
      },
      nzWidth: 900,
      nzFooter: [
        {
          label: upperFirst(this.translate.instant('close')),
          onClick: () => {
            modal.close();
          },
        },
        {
          label: upperFirst(this.translate.instant('save')),
          type: 'primary',
          loading: (component) => component.isLoading,
          onClick: (component) => {
            handleFormSubmit([component.form], () => {
              modal.close(component);
            });
          },
        },
      ],
    });
    return modal.afterClose.asObservable();
  }
}
