import { replaceAll } from './replace-all';

export function parseFileName(name: string): string {
  name = name.toLowerCase();

  name = replaceAll(name, ' ', '_');
  name = replaceAll(name, '[', '(');
  name = replaceAll(name, ']', ')');
  name = replaceAll(name, '/', '-');
  name = replaceAll(name, '\\', '-');
  name = replaceAll(name, ':', '');
  name = replaceAll(name, '?', '');
  name = replaceAll(name, '*', '');

  return name;
}
