import {
  DGFE_MPD_Status,
  DrawerState,
  FiscalPrinterStatus,
  OperationalStatus,
  PrinterStatus,
  ReceiptDocumentStatus,
} from '../fiscal-printer-status';

import { EpsonFiscalPrinterResponse } from './epson-fiscal-printer-response';

const printerStatusMapping = (code: string): PrinterStatus => {
  const map: { [code: number]: PrinterStatus } = {
    0: 'ok',
    2: 'paper_running_out',
    3: 'offline',
  };

  return map[code] || 'wrong_answer';
};

const dgfeMpdStatusMapping = (code: string): DGFE_MPD_Status => {
  const map: { [code: number]: DGFE_MPD_Status } = {
    0: 'ok',
    1: 'next_to_exhaustion',
    2: 'to_format',
    3: 'previous',
    4: 'of_another_meter',
    5: 'spent',
  };

  return map[code] || 'wrong_answer';
};

const drawerStateMapping = (code: string): DrawerState => {
  const map: { [code: number]: DrawerState } = {
    0: 'open',
    1: 'closed',
  };

  return map[code] || 'wrong_answer';
};

const receiptDocumentStatusMapping = (code: string): ReceiptDocumentStatus => {
  const map: { [code: string]: ReceiptDocumentStatus } = {
    0: 'open_tax',
    1: 'tax_non_tax_open',
    2: 'non_tax_open',
    3: 'payment_in_progress',
    4: 'last_esc_pos_command_error_with_tax_non_tax_open',
    5: 'negative_receipt',
    6: 'last_esc_pos_command_error_with_non_tax_open',
    7: 'waiting_for_receipt_closed_with_javapos_mode',
    8: 'fiscal_document_open',
    9: 'credit_note_opened_from_pc',
    A: 'open_title',
    B: 'closed_title',
  };

  return map[code] || 'wrong_answer';
};

const operationalStatusMapping = (code: string): OperationalStatus => {
  const map: { [code: number]: OperationalStatus } = {
    0: 'registration_status',
    1: 'x_status',
    2: 'z_status',
    3: 'set_status',
    4: 'ticketing_mode',
    5: 'credit_note_opened_from_keyboard',
  };

  return map[code] || 'wrong_answer';
};

export class EpsonFiscalPrinterStatusParser {
  parse(response: EpsonFiscalPrinterResponse): FiscalPrinterStatus {
    return {
      result: response._attributes.success === 'true',
      code: response._attributes.code,
      sfVersion: response.addInfo.cpuRel,
      mfVersion: response.addInfo.mfRel,
      mfStatus: response.addInfo.mfStatus,
      printerStatus: printerStatusMapping(
        response.addInfo.fpStatus.substring(0, 1),
      ),
      dgfeMpdStatus: dgfeMpdStatusMapping(
        response.addInfo.fpStatus.substring(1, 2),
      ),
      drawerState: drawerStateMapping(
        response.addInfo.fpStatus.substring(2, 3),
      ),
      receiptDocumentStatus: receiptDocumentStatusMapping(
        response.addInfo.fpStatus.substring(3, 4),
      ),
      operationalStatus: operationalStatusMapping(
        response.addInfo.fpStatus.substring(4, 5),
      ),
    };
  }
}
