import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AdminGlobalSearchModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AdminGlobalSearchService {
  constructor(private http: HttpClient) {}

  search(value: string, model: AdminGlobalSearchModel) {
    return this.http.post(`admin/search`, { value, search_in: model });
  }
}
