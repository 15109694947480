import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CleanablesService {
  constructor(private http: HttpClient) {}

  loadSlugs() {
    return this.http.get(`cleanables/slugs`);
  }

  loadMapping(propertyId: number) {
    return this.http.get(
      `properties/${propertyId}/housekeeper/cleanables/mapping`,
    );
  }
}
