const ERRORS = {
  '0': {
    code: '0',
    title: 'ERRORE <codice errore> SCONOSC. CONTROL BOX NON CONFIGURATA ',
    description:
      'È stato generato un errore sconosciuto. Prendere contatto con l’assistenza clienti. Control box non confgurata. Confgurare la control box. ',
  },
  '1': {
    code: '1',
    title: 'NON PRESENTE ',
    description: 'File o archivio non presente. ',
  },
  '2': {
    code: '2',
    title:
      'ERRORE LETTURA MF / MEM. RIEPILOGO UTENTE NON RICONOSCIUTO, CONTROLLARE USERNAME ',
    description:
      'Vi è stato un errore nella lettura dei dati della memoria fscale o di riepilogo. Si richiede l’intervento dell’assistenza tecnica. Problema di comunicazione con il portale fscale. Controllare i parametri di collegamento al portale fscale. ',
  },
  '3': {
    code: '3',
    title: 'VALORE NON VALIDO PASSWORD FTP ERRATA, CONTROLLARE PASSWORD ',
    description:
      'L’immissione fatta non è corretta, cancellare e ricominciare. Problema di comunicazione con il portale fscale. Controllare i parametri di collegamento al portale fscale. ',
  },
  '4': {
    code: '4',
    title:
      'ERRORE DI CODICE INTERNO FILE NON DISPONIBILE, CONTROLLARE SERVER DIR ',
    description:
      'Se l’operazione richiesta non è stata eseguita, ripeterla. Se l’errore persiste, contattare l’assistenza tecnica. Nel caso di dispositivo a batteria si può verifcare quando la batteria è scarica. Collegare il dispositivo alla rete elettrica. Il fle cercato sul portale non esiste. ',
  },
  '5': {
    code: '5',
    title: 'OPERAZIONE NON POSSIBILE ERRORE SINTASSI XML, CONTROLLA FILE XML ',
    description:
      'L’operazione eseguita non è corretta, terminare eventuali documenti in corso e ripetere. Errore di comunicazione con il portale dovuta a sintassi non corretta del fle XML. ',
  },
  '6': {
    code: '6',
    title: 'ERRORE SCRITTURA LOGOTIPO IN MF / MEM. RIEPILOGO ',
    description:
      'Impossibile scrivere il logotipo fscale nella memoria fscale o di riepilogo. ',
  },
  '7': {
    code: '7',
    title: 'IN SCRITTA TOTALE FUNZIONE NON AMMESSA ',
    description:
      'Si è tentato di inviare la scritta “Totale” in una vendita. La funzione richiesta non è applicabile alla situazione presente. ',
  },
  '8': {
    code: '8',
    title: 'GIÀ FATTO IMPOSTAZIONE ERRATA ',
    description:
      'È impossibile eseguirlo nuovamente. La quantità immessa supera le 65535,99 unità. ',
  },
  '9': {
    code: '9',
    title: 'TOTALE SCONTRINO ECCESSIVO ',
    description:
      'Superato, come importo, il valore massimo sul totale scontrino pari a €9999999,99. ',
  },
  '10': {
    code: '10',
    title: 'TOTALE GIORNALIERO ECCESSIVO ',
    description:
      'Superato, come importo, il valore massimo sul totale giornaliero pari a € 9999999,99. ',
  },
  '11': {
    code: '11',
    title: 'ERRORE IN IMPORTO ',
    description: 'Il prezzo/importo non è corretto. ',
  },
  '12': {
    code: '12',
    title: 'DATA ANTECEDENTE A MF ',
    description:
      'È stata inserita una data antecedente a quella memorizzata nella memoria fscale, correggere e reinserire. ',
  },
  '13': {
    code: '13',
    title: 'DATA / ORA NON VALIDA ',
    description:
      'Si è inserito una data / ora non valida, correggere e reimpostare. ',
  },
  '14': {
    code: '14',
    title: 'DATA DIVERSA ',
    description:
      'Si è inserito una data differente da quella memorizzata, correggere e reimpostare. ',
  },
  '15': {
    code: '15',
    title: 'ERRORE FATALE CB/MF/MEM. RIEPILOGO ',
    description: 'Control box/memoria fscale o di riepilogo danneggiata. ',
  },
  '16': {
    code: '16',
    title: 'FINE CARTA ',
    description: 'Errore per fne carta. Sostituire il rotolo. ',
  },
  '17': {
    code: '17',
    title: 'SLIP PRINTER ',
    description: 'Errore slip printer. ',
  },
  '18': {
    code: '18',
    title: 'RAGIONE SOCIALE MANCANTE ',
    description: 'Non è stata programmata l’intestazione del documento. ',
  },
  '19': {
    code: '19',
    title: 'CHIUSURA MF/MEM. RIEPILOGO NON POSSIBILE ',
    description:
      'Non si è riusciti a scrivere la chiusura in memoria fscale o di riepilogo. ',
  },
  '20': { code: '20', title: 'Riservato. ' },
  '21': {
    code: '21',
    title: 'DOCUMENTO IN CORSO ',
    description:
      'Un terminale remoto sta inviando un documento. Attendere che l’operazione in corso venga terminata. ',
  },
  '22': {
    code: '22',
    title: 'DATE DIVERSE ',
    description: 'Nella richiesta sono state inserite date incongruenti. ',
  },
  '23': {
    code: '23',
    title: 'TOTALE NEGATIVO ',
    description:
      'Il totale dello scontrino è negativo, aggiungere importi o annullare. ',
  },
  '24': {
    code: '24',
    title: 'IN LUNGHEZZA COMANDO ',
    description:
      'È stato inviato un comando di lunghezza errata o non supportata. ',
  },
  '25': {
    code: '25',
    title: 'PAGAMENTO INCOMPLETO ',
    description: 'È stato inviato un pagamento non completo. ',
  },
  '26': {
    code: '26',
    title: 'PROCEDURA INTERROTTA DALL’UTENTE ',
    description: 'La procedura è stata interrotta dall’utente. ',
  },
  '27': {
    code: '27',
    title: 'DB ENGINE COD. <codice errore> ',
    description:
      'Errore generico di database (viene visualizzato un sottocodice interno che defnisce il tipo di errore SQLite). Contattare l’assistenza tecnica. ',
  },
  '28': {
    code: '28',
    title: 'IVA NON PROGRAMMATA ',
    description: 'Impostare l’aliquota IVA. ',
  },
  '29': {
    code: '29',
    title: 'IVA NEGATIVA ',
    description: 'È stata impostata un’aliquota IVA negativa. ',
  },
  '30': {
    code: '30',
    title: 'DGFE/MEM. DETTAGLIO ESAURITO/A ',
    description:
      'Il giornale elettronico (DGFE) o memoria di dettaglio è esaurito/a. Procedere alla sostituzione. ',
  },
  '31': {
    code: '31',
    title: 'DGFE/MEM.DETTAGLIO QUASI ESAURITO/A ',
    description:
      'Si sta esaurendo il giornale elettronico (DGFE) o memoria di dettaglio. Sostituirlo/a il prima possibile. ',
  },
  '32': {
    code: '32',
    title: 'INIZIALIZZAZIONE DGFE/MEM. DETTAGLIO NON POSSIBILE ',
    description:
      'Non è stato possibile inizializzare il nuovo giornale elettronico (DGFE) o la nuova memoria di dettaglio. Sostituirlo/a con un altro/a ed avvisare l’assistenza tecnica. ',
  },
  '33': {
    code: '33',
    title: 'DGFE/MEM. DETTAGLIO NON PRESENTE ',
    description:
      'Manca il giornale elettronico (DGFE) o la memoria di dettaglio. Inserirlo/a nel dispositivo. ',
  },
  '34': {
    code: '34',
    title: 'DATI DGFE/MEM. DETTAGLIO ERRATI ',
    description:
      'Tentativo fallito di scrittura su giornale elettronico (DGFE) o su memoria di dettaglio. ',
  },
  '35': {
    code: '35',
    title: 'LA MF/MEM. RIEPILOGO È STATA SCOLLEGATA ',
    description:
      'La memoria fscale o memoria di riepilogo è stata scollegata. Contattare l’assistenza tecnica. ',
  },
  '36': {
    code: '36',
    title: 'NESSUN DATO TROVATO SU DGFE/MEM. DETTAGLIO ',
    description:
      'Mancano i dati del giornale elettronico (DGFE) o della memoria di dettaglio. Sostituire e avvisare l’assistenza tecnica. ',
  },
  '37': {
    code: '37',
    title: 'COPERCHIO APERTO ',
    description:
      'Il coperchio vano carta è stato aperto durante la stampa di un report fscale. ',
  },
  '38': {
    code: '38',
    title: 'DGFE/MEM. DETTAGLIO NON IDENTIFICATO ',
    description:
      'Il giornale elettronico (DGFE) o la memoria di dettaglio non è omologato/a. Contattare il rivenditore autorizzato. ',
  },
  '39': {
    code: '39',
    title: 'SD/MMC CON PASSWORD ',
    description:
      'La SD/MMC inserita non è utilizzabile in quanto protetta da password. Utilizzarne una non protetta. ',
  },
  '40': {
    code: '40',
    title: 'DATO DGFE/MEM. DETTAGLIO NON PRESENTE ',
    description:
      'Il dato cercato sul giornale elettronico (DGFE) o sulla memoria di dettaglio non è stato trovato. ',
  },
  '41': {
    code: '41',
    title: 'DGFE/MEM. DETTAGLIO ERRATO/A ',
    description:
      'È stato inserito un giornale elettronico (DGFE) o una memoria di dettaglio non associato/a al dispositivo. Inserire il supporto corretto. ',
  },
  '42': {
    code: '42',
    title: 'DATI DGFE/MEM. DETTAGLIO NON SALVATI ',
    description:
      'Riavviare la macchina. Se il problema persiste sostituire il giornale elettronico (DGFE) o la memoria di dettaglio. ',
  },
  '43': {
    code: '43',
    title: 'DGFE/MEM. DETTAGLIO CLONATO/A ',
    description:
      'Il giornale elettronico (DGFE) o la memoria di dettaglio è stato/a copiato/a o clonato/a. ',
  },
  '44': {
    code: '44',
    title: 'UPGRADE RICHIESTO ',
    description: 'Un aggiornamento frmware è pronto e necessita di conferma. ',
  },
  '47': {
    code: '47',
    title: 'NON FISCALIZZATO ',
    description:
      'Il dispositivo non è fscalizzato. Eseguire una fscalizzazione. ',
  },
  '48': {
    code: '48',
    title: 'CONTROL BOX OCCUPATA ',
    description: 'Errore di control box occupata. Riavviare la control box. ',
  },
  '49': {
    code: '49',
    title: 'CONTROL BOX SERIAL NUMBER ERRATO ',
    description: 'Numero di serie errato della control box. ',
  },
  '50': {
    code: '50',
    title: 'MF/MEM. RIEPILOGO NON PRESENTE ',
    description:
      'È stata riconosciuta una disconnessione della memoria fscale o memoria di riepilogo. Contattare l’assistenza tecnica. ',
  },
  '51': {
    code: '51',
    title: 'MF/MEM. RIEPILOGO PIENA ',
    description:
      'La memoria fscale o di riepilogo si è esaurita. Contattare l’assistenza tecnica. ',
  },
  '52': {
    code: '52',
    title: 'JUMPER HWINIT INSERITO ',
    description:
      'È stato lasciato inserito il jumper del hardware init. Contattare l’assistenza tecnica. ',
  },
  '53': {
    code: '53',
    title: 'DISPOSITIVO GIÀ SERIALIZZATO ',
    description:
      'Si sta tentando di serializzare una memoria fscale o di riepilogo già serializzata. ',
  },
  '54': {
    code: '54',
    title: 'CHIUSURA FISCALE NECESSARIA ',
    description: 'È richiesta la chiusura fscale. ',
  },
  '55': {
    code: '55',
    title: 'MODO TRAINING ATTIVO ',
    description: 'È attivo il modo di apprendimento. ',
  },
  '56': {
    code: '56',
    title: 'DISPLAY NON PRESENTE (è emesso un beep ogni 3 secondi) ',
    description: 'Il visore cliente è scollegato. ',
  },
  '57': {
    code: '57',
    title: 'DATA/ORA NON IMPOSTATI ',
    description: 'Non è stata inserita la data e l’ora. ',
  },
  '58': { code: '58', title: 'Riservato. ' },
  '59': {
    code: '59',
    title: 'DISPOSITIVO NON FISCALIZZATO ',
    description:
      'Dispositivo non fscalizzabile perché già fscalizzato o con problemi alla memoria fscale / memoria di riepilogo. ',
  },
  '60': {
    code: '60',
    title: 'DISPOSITIVO NON SERIALIZZATO ',
    description:
      'Il dispositivo non è ancora stato serializzato. Contattare l’assistenza tecnica. ',
  },
  '61': {
    code: '61',
    title: '24H DALL’ULTIMA CHIUSURA ',
    description:
      'Sono passate 24 ore dall’ultima chiusura fscale. Eseguire una chiusura fscale. ',
  },
  '62': {
    code: '62',
    title: 'RICEZIONE DATI IN CORSO ',
    description:
      'Ricezione dati da remoto a scontrino aperto; chiudere lo scontrino da tastiera. Lo scontrino in memoria verrà stampato alla chiusura della transazione corrente da tastiera. ',
  },
  '63': {
    code: '63',
    title: 'TRANSAZIONE FALLITA ',
    description: 'Transazione fallita su EFT-POS. ',
  },
  '64': {
    code: '64',
    title: 'COPERCHIO APERTO ',
    description: 'Assicurarsi che il coperchio sia chiuso. ',
  },
  '65': {
    code: '65',
    title: 'ERRORE ALIMENTAZIONE TESTINA ',
    description:
      'Tensione elevata su testina termica. Contattare l’assistenza tecnica. ',
  },
  '66': {
    code: '66',
    title: 'ERRORE TEMPERATURA TESTINA ',
    description:
      'Temperatura elevata su testina termica. Contattare l’assistenza tecnica. ',
  },
  '67': {
    code: '67',
    title: 'ERRORE TAGLIERINA ',
    description: 'Errore taglierina. Contattare l’assistenza tecnica. ',
  },
  '68': {
    code: '68',
    title: 'TESTINA SCOLLEGATA ',
    description: 'Contattare l’assistenza tecnica. ',
  },
  '69': {
    code: '69',
    title: 'LUNGHEZZA DGFE/MEM. DETTAGLIO ERRATA ',
    description:
      'Capienza del supporto del giornale elettronico (DGFE) o della memoria di dettaglio non supportata. Sostituire il supporto con uno di capienza adeguata. ',
  },
  '70': {
    code: '70',
    title: '24H SUPERATE ',
    description:
      'Sono passate più di 24 ore dalla prima vendita effettuata. Eseguire una chiusura fscale. ',
  },
  '71': {
    code: '71',
    title: '2° Z REPORT NON PERMESSO ',
    description:
      'Un secondo azzeramento fscale non è permesso. Eseguire almeno una vendita. ',
  },
  '72': {
    code: '72',
    title: 'HWINIT RICHIESTO ',
    description:
      'È richiesto un hardware init. Eseguire un’inizializzazione hardware. ',
  },
  '73': {
    code: '73',
    title: 'ASSISTENZA TECNICA ',
    description:
      'Verifca periodica scaduta, dispositivo bloccato. È necessario l’intervento dell’assistenza tecnica. Contattare l’assistenza  tecnica. ',
  },
  '74': {
    code: '74',
    title: 'RX TIMEOUT ',
    description: 'Timeout nella ricezione. ',
  },
  '75': {
    code: '75',
    title: 'EJ FISCALE ',
    description:
      'Sul dispositivo non fscale è presente un giornale elettronico o memoria di dettaglio fscale. ',
  },
  '76': {
    code: '76',
    title: 'EJ NON FISCALE ',
    description:
      'Sul dispositivo fscale è presente un giornale elettronico o memoria di dettaglio non fscale. ',
  },
  '77': {
    code: '77',
    title: 'MEMORIA FISCALE / MEM. RIEPILOGO CHIUSA ',
    description: 'La memoria fscale o di riepilogo è bloccata. ',
  },
  '81': {
    code: '81',
    title: 'NON SERIALIZZATO ',
    description: 'Il numero di serie è mancante. ',
  },
  '82': {
    code: '82',
    title: 'NON ATTIVATO ',
    description: 'È richiesta un’attivazione. ',
  },
  '83': {
    code: '83',
    title: 'DISPOSITIVO NON CENSITO ',
    description: 'Il dispositivo non è stato ancora censito. ',
  },
  '84': {
    code: '84',
    title: 'CERTIFICATO SCADUTO ',
    description: 'Il certifcato SSL del dispositivo è scaduto. ',
  },
  '85': {
    code: '85',
    title: 'ESEGUIRE CHIUSURA DOPO HWINIT ',
    description:
      'È necessario eseguire una chiusura fscale dopo un hardware init. ',
  },
  '86': {
    code: '86',
    title: 'NESSUN FILE TROVATO ',
    description: 'È fallita la ricerca del fle. ',
  },
  '87': {
    code: '87',
    title: 'FILE APERTO ',
    description: 'Un fle è ancora aperto. ',
  },
  '88': {
    code: '88',
    title: 'NESSUN FILE APERTO ',
    description: 'Nessun fle è aperto. ',
  },
  '89': {
    code: '89',
    title: 'ERRORE GENERICO ',
    description: 'Errore generico del fle system. ',
  },
  '90': {
    code: '90',
    title: 'FINE DEL FILE ',
    description: 'È stata raggiunta la fne del fle. ',
  },
  '91': {
    code: '91',
    title: 'DISCO PIENO ',
    description: 'Il volume richiesto è pieno. ',
  },
  '92': {
    code: '92',
    title: 'PARAMETRO NON VALIDO ',
    description: 'C’è un errore nel comando. ',
  },
  '93': {
    code: '93',
    title: 'SOLA SCRITTURA ',
    description: 'Il fle è in sola scrittura. ',
  },
  '94': {
    code: '94',
    title: 'SOLA LETTURA ',
    description: 'Il fle è in sola lettura. ',
  },
  '95': {
    code: '95',
    title: 'ERRORE LETTURA ',
    description: 'C’è un errore nella lettura del fle. ',
  },
  '96': {
    code: '96',
    title: 'ERRORE SCRITTURA ',
    description: 'C’è un errore nella scrittura del fle. ',
  },
  '97': { code: '97', title: 'ATTENDERE PREGO ' },
  '98': {
    code: '98',
    title: 'OCCUPATO ',
    description:
      'Errore disponibile come eco solo dal protocollo CUSTOM. Il dispositivo è impegnato a processare un’operazione eseguita da tastiera. Terminare l’operazione e tornare allo stato di standby. ',
  },
  '99': {
    code: '99',
    title: 'ECR ',
    description:
      'Errore disponibile come eco solo dal protocollo CUSTOM. Errore generico del motore gestionale (fare riferimento al comando 1015, vedere manuale del protocollo CUSTOM cod. 77100000000700). ',
  },
  '100': {
    code: '100',
    title: 'ERRORE <codice errore> SCONOSCIUTO ',
    description:
      'È stato generato un errore sconosciuto. Contattare l’assistenza tecnica. ',
  },
  '101': {
    code: '101',
    title: 'MEMORIA ESAURITA ',
    description:
      'La memoria disponibile per la memorizzazione delle operazioni transitorie è esaurita. È necessario effettuare i relativi azzeramenti (es. azzerare i preconti tramite azzeramento fnanziario). ',
  },
  '102': {
    code: '102',
    title: 'SUPERATO IMPORTO MAX ',
    description:
      'Il prezzo immesso al volo è superiore a quello massimo impostato per quel reparto. ',
  },
  '103': {
    code: '103',
    title: '<riga>-<colonna> NON ASSOCIATO ',
    description:
      'È stato premuto un tasto a cui non corrisponde alcuna funzione. ',
  },
  '104': {
    code: '104',
    title: 'TASTIERA NON SELEZIONATA ',
    description: 'Non è stata selezionata una tastiera. ',
  },
  '105': {
    code: '105',
    title: 'COMUNICAZIONE ERRATA ',
    description: 'Controllare i parametri di confgurazione di rete. ',
  },
  '106': {
    code: '106',
    title: 'BATTERIA SCARICA ',
    description:
      'Lasciare il dispositivo acceso per 24 ore. Se il problema persiste, contattare l’assistenza tecnica. ',
  },
  '107': {
    code: '107',
    title: 'UPGRADE FALLITO ',
    description:
      'L’upgrade del fle specifcato è fallito. Verificare che i fle utilizzati siano quelli corretti. ',
  },
  '108': {
    code: '108',
    title: 'IMPORTO MINIMO NON RAGGIUNTO ',
    description:
      'Il prezzo al volo immesso è inferiore a quello minimo programmato per quel reparto. ',
  },
  '109': {
    code: '109',
    title: 'CONTATORI NON AZZERATI ',
    description:
      'Non è stato possibile azzerare i contatori corrispondenti per cui la cancellazione risulta incompleta. ',
  },
  '111': {
    code: '111',
    title: 'MEMORIA DRAM PIENA ',
    description: 'Memoria piena. Riavviare la control box. ',
  },
  '121': {
    code: '121',
    title: 'MODIFICATORE ERRATO ',
    description: 'Lo sconto inserito è superiore al 100%. ',
  },
  '122': {
    code: '122',
    title: 'VALORE NON AMMESSO ',
    description:
      'Il valore immesso non è compreso tra il minimo ed il massimo previsti per quel parametro. ',
  },
  '123': {
    code: '123',
    title: 'PASSWORD ERRATA ',
    description:
      'La parola chiave non è stata digitata correttamente (o è errata o è stata cambiata). ',
  },
  '124': {
    code: '124',
    title: 'ALIQUOTA IVA ERRATA ',
    description: 'L’aliquota IVA selezionata non è corretta. ',
  },
  '125': {
    code: '125',
    title: 'COMANDO OBEX ERRATO ',
    description: 'Errore di trasmissione modem. ',
  },
  '126': {
    code: '126',
    title: 'DIMENSIONE FILE NON VALIDA, CONTROLLARE FILE JAD/JAR ',
    description:
      'Errore nella dimensione del fle utilizzato per aggiornare il modem. ',
  },
  '127': {
    code: '127',
    title: 'COMANDO AT ERRATO ',
    description: 'Errore di comunicazione modem. ',
  },
  '128': {
    code: '128',
    title: 'ERRORE COMUNICAZIONE MODEM ',
    description: 'Errore di comunicazione modem. ',
  },
  '129': {
    code: '129',
    title: 'CHECKSUM PROTOCOLLO ERRATO ',
    description: 'Errore di comunicazione modem. ',
  },
  '130': {
    code: '130',
    title: 'COMANDO PROTOCOLLO ERRATO ',
    description: 'Errore di comunicazione modem. ',
  },
  '131': {
    code: '131',
    title: 'PARAMETRO PROTOCOLLO ERRATO ',
    description: 'Errore di comunicazione modem. ',
  },
  '132': {
    code: '132',
    title: 'FATTURA DIFFERITA RICHIESTA ',
    description: 'È richiesta la fattura differita. ',
  },
  '133': {
    code: '133',
    title: 'FATTURA TICKET RICHIESTA ',
    description: 'È richiesta la fattura ticket. ',
  },
  '134': {
    code: '134',
    title: 'CHIUDERE DOCUMENTO ',
    description: 'È richiesta la chiusura del documento. ',
  },
  '135': {
    code: '135',
    title: 'RISCUOTERE CREDITO ',
    description:
      'Al cliente che si vuole eliminare è stato fatto credito. Non sarà possibile eliminarlo fnché non verrà riscosso e azzerato il credito residuo. ',
  },
  '136': {
    code: '136',
    title: 'CONTANTE INSUFFICIENTE ',
    description:
      'La quantità di contante presente in cassa non è suffciente per l’operazione richiesta. ',
  },
  '137': {
    code: '137',
    title: 'PAGAMENTO NON VALIDO ',
    description:
      'Il tipo di pagamento selezionato non è ammesso per concludere l’operazione in corso. ',
  },
  '138': {
    code: '138',
    title: 'QUANTITÀ NON SPECIFICATA ',
    description:
      'Si è tentato di inserire un Reparto/PLU senza specifcare la quantità. Occorre farlo coerentemente a quanto programmato per quel reparto o per il reparto a cui è collegato quel PLU. ',
  },
  '139': {
    code: '139',
    title: 'DESCRIZIONE MANCANTE ',
    description: 'La descrizione è mancante. ',
  },
  '140': {
    code: '140',
    title: 'OPERATORE GIÀ INSERITO ',
    description:
      'In modalità operatore obbligatorio e operatore a turno non è consentita la dichiarazione dello stesso operatore per più di una volta rispettivamente nello stesso documento e nello stesso turno. ',
  },
  '141': {
    code: '141',
    title: 'IMPORTO PAGAMENTO ECCESSIVO ',
    description:
      'L’importo immesso è superiore a quello massimo programmato per quel pagamento. ',
  },
  '142': {
    code: '142',
    title: 'RESTO ECCESSIVO ',
    description:
      'Il resto che si è prodotto è superiore a quello massimo programmato per quel pagamento. ',
  },
  '143': {
    code: '143',
    title: 'CREDITO CLIENTE ECCESSIVO ',
    description:
      'Il credito che si è tentato di concedere è superiore a quello massimo programmato per quel cliente. ',
  },
  '144': {
    code: '144',
    title: 'PAGAMENTO INESISTENTE ',
    description: 'Il pagamento specifcato è inesistente. ',
  },
  '145': {
    code: '145',
    title: 'PAGAMENTO GIÀ PRESENTE ',
    description: 'La modalità di pagamento è già presente. ',
  },
  '146': {
    code: '146',
    title: 'MODIFICATORE INESISTENTE ',
    description: 'Il modifcatore specifcato è inesistente. ',
  },
  '147': {
    code: '147',
    title: 'MODIFICATORE GIÀ PRESENTE ',
    description: 'Il modifcatore è già presente. ',
  },
  '148': {
    code: '148',
    title: 'REPARTO INESISTENTE ',
    description:
      'Il reparto richiesto non è utilizzabile poiché non esistente o cancellato. ',
  },
  '149': {
    code: '149',
    title: 'REPARTO GIÀ PRESENTE ',
    description: 'Il reparto è già presente. ',
  },
  '150': {
    code: '150',
    title: 'PLU INESISTENTE ',
    description:
      'Il PLU richiesto non è utilizzabile poiché non esistente o cancellato. ',
  },
  '151': {
    code: '151',
    title: 'PLU GIÀ PRESENTE ',
    description: 'Il PLU è già presente. ',
  },
  '152': {
    code: '152',
    title: 'OPERATORE INESISTENTE ',
    description:
      'L’operatore richiesto non è utilizzabile poiché non esistente o cancellato. ',
  },
  '153': {
    code: '153',
    title: 'OPERATORE GIÀ PRESENTE ',
    description: 'L’operatore è già presente. ',
  },
  '154': {
    code: '154',
    title: 'CLIENTE INESISTENTE ',
    description:
      'Il cliente richiesto non è utilizzabile poiché non esistente o cancellato. ',
  },
  '155': {
    code: '155',
    title: 'CLIENTE GIÀ PRESENTE ',
    description: 'Il cliente è già presente. ',
  },
  '156': {
    code: '156',
    title: 'NO CONVENZIONI ',
    description: 'Nessuna convenzione è associata alla società selezionata. ',
  },
  '157': {
    code: '157',
    title: 'CONVENZIONE INESISTENTE ',
    description: 'La convenzione è inesistente. ',
  },
  '158': {
    code: '158',
    title: 'CONVENZIONE GIÀ ESISTENTE ',
    description: 'La convenzione è già esistente. ',
  },
  '159': {
    code: '159',
    title: 'SOCIETÀ INESISTENTE ',
    description: 'La società è inesistente. ',
  },
  '160': {
    code: '160',
    title: 'SOCIETÀ GIÀ ESISTENTE ',
    description: 'La società è già esistente. ',
  },
  '161': {
    code: '161',
    title: 'PRECONTO <numero preconto> NON DISPONIBILE ',
    description:
      'Al numero di preconto richiesto non corrisponde alcun preconto salvato. ',
  },
  '162': { code: '162', title: 'Riservato. ' },
  '163': { code: '163', title: 'Riservato. ' },
  '164': {
    code: '164',
    title: 'TAVOLO PIENO ',
    description:
      'Il numero di battute al tavolo ha raggiunto il massimo valore delle battute per ogni documento (150). Chiudere il tavolo. ',
  },
  '165': {
    code: '165',
    title: 'TAVOLO VUOTO ',
    description: 'Nessuna battuta è stata inserita nel tavolo. ',
  },
  '166': {
    code: '166',
    title: 'CHIUDERE TAVOLO ',
    description:
      'Prima di eseguire l’operazione richiesta chiudere il tavolo. ',
  },
  '167': { code: '167', title: 'Riservato. ' },
  '168': { code: '168', title: 'Riservato. ' },
  '169': { code: '169', title: 'Riservato. ' },
  '170': {
    code: '170',
    title: 'ERRORE DI CONNESSIONE ',
    description:
      'Errore di connessione GPRS: verifcare i parametri dell’access point. ',
  },
  '171': {
    code: '171',
    title: 'ERRORE SERVER FTP ',
    description:
      'Errore di connessione FTP al server: controllare i parametri FTP. ',
  },
  '172': {
    code: '172',
    title: 'ERRORE DI SCRITTURA FILE ',
    description:
      'Errore durante la scrittura del fle sul server FTP: controllare il collegamento FTP con il server. ',
  },
  '173': {
    code: '173',
    title: 'ERRORE SIM CARD ',
    description:
      'Errore SIM card: controllare se la SIM card è presente e inserita correttamente nel modem. ',
  },
  '174': {
    code: '174',
    title: 'ERRORE FTP CHDIR, CONTROLLARE ID ',
    description:
      'Errore in connessione FTP: controllare nome cartella di trasferimento sul server. ',
  },
  '175': {
    code: '175',
    title: 'DIMENSIONE ERRATA FILE XML ',
    description:
      'Dimensione errata del fle XML: controllare fle XML sul fash-disk. ',
  },
  '176': {
    code: '176',
    title: 'INSERIRE PIN ',
    description: 'Richiesta codice PIN sulla SIM card. ',
  },
  '177': {
    code: '177',
    title: 'DIMENSIONE ERRATA JAD ',
    description: 'Dimensione errata del fle JAD: controllare sul fash-disk. ',
  },
  '178': {
    code: '178',
    title: 'ERRORE OPERATORE SELEZIONATO ',
    description:
      'Errore dell’operatore telefonico selezionato sulla SIM card. ',
  },
  '179': {
    code: '179',
    title: 'CONNESSIONE FTP GIÀ APERTA ',
    description:
      'Errore in collegamento FTP: la connessione è stata già aperta. ',
  },
  '180': {
    code: '180',
    title: 'EVENTO NON VALIDO ',
    description: 'Lo scontrino non è assegnato ad un evento valido. ',
  },
  '181': {
    code: '181',
    title: 'MASSIMA CAPACITÀ RAGGIUNTA ',
    description: 'È stata raggiunta la massima capacità. ',
  },
  '182': {
    code: '182',
    title: 'ERRORE FTP TRASFERIMENTO FILE ',
    description: 'Errore nel trasferire fles dal server FTP al dispositivo. ',
  },
  '183': {
    code: '183',
    title: 'ERRORE MODEM OCCUPATO ',
    description:
      'Modem occupato: comunicazione seriale occupata con un altro comando. ',
  },
  '184': {
    code: '184',
    title: 'ERRORE CODICE ACK ',
    description: 'Errore nel fle di ACK ricevuto. ',
  },
  '185': {
    code: '185',
    title: 'ERRORE INVIO Z REPORT ',
    description: 'Errore nell’invio della chiusura fscale. ',
  },
  '186': {
    code: '186',
    title: 'EFD NUMERO SERIE NON IMPOSTATO ',
    description:
      'Il parametro numero di serie non è impostato correttamente nel database del modem. ',
  },
  '187': {
    code: '187',
    title: 'CONNESSIONE FTP NON APERTA ',
    description: 'Fallimento dell’apertura della connessione FTP. ',
  },
  '188': {
    code: '188',
    title: 'ERRORE CANCELLAZIONE FILE FTP ',
    description: 'Errore durante la cancellazione del fle sul server FTP. ',
  },
  '189': {
    code: '189',
    title: 'CLIENTE OBBLIGATORIO ',
    description: 'Dichiarare il cliente prima di chiudere il documento. ',
  },
  '190': {
    code: '190',
    title: 'IMPORTO PRELIEVO TROPPO ALTO ',
    description: 'L’importo di prelievo è troppo elevato. ',
  },
  '191': {
    code: '191',
    title: 'FUNZIONE NON ATTIVATA ',
    description:
      'La funzione che si sta tentando di utilizzare deve essere abilitata dall’apposita voce di menù. ',
  },
  '192': {
    code: '192',
    title: 'MEMORIA ESAURITA, ESEGUIRE AZZERAMENTO FINANZIARIO ',
    description:
      'La memoria batterizzata ha raggiunto la capienza massima. Eseguire un azzeramento fnanziario. ',
  },
  '193': {
    code: '193',
    title: 'ERRORE SALVATAGGIO Z REPORT ',
    description:
      'Errore durante il salvataggio dell’ultimo azzeramento fscale sulla fash-disk. ',
  },
  '194': {
    code: '194',
    title: 'MANCATA CONFERMA ',
    description: 'Ripetere l’operazione di sincronizzazione. ',
  },
  '195': {
    code: '195',
    title: 'ERRORE FTP I/O ',
    description: 'Controllare i parametri di collegamento FTP. ',
  },
  '196': {
    code: '196',
    title: 'COMANDO ESTESO ERRATO ',
    description: 'Controllare la confgurazione generale. ',
  },
  '197': {
    code: '197',
    title: 'MANCA CODICE CLIENTE ',
    description: 'Inserire il codice cliente nei parametri di registrazione. ',
  },
  '198': {
    code: '198',
    title: 'INSERIRE VRN ',
    description:
      'Errore nell’inserimento del numero di licenza dell’esercizio commerciale (VAT Registration Number, VRN). ',
  },
  '199': {
    code: '199',
    title: 'INSERIRE TAX OFFICE ',
    description: 'È stata inserita un’aliquota IVA non valida. ',
  },
  '200': {
    code: '200',
    title: 'INSERIRE REG DATE ',
    description: 'È stata inserita una data di registrazione non valida. ',
  },
  '201': {
    code: '201',
    title: 'CODICE CLIENTE NON VALIDO ',
    description:
      'Verificare il codice cliente nei parametri di registrazione. ',
  },
  '202': {
    code: '202',
    title: 'SERIAL NUMBER ERRATO ',
    description: 'Verificare il numero di serie. ',
  },
  '203': {
    code: '203',
    title: 'LICENZA GIÀ ATTIVA ',
    description:
      'Verificare se il dispositivo è già stato registrato in precedenza. ',
  },
  '204': {
    code: '204',
    title: 'LICENZA NON ACQUISTATA ',
    description:
      'Esaurito il numero di licenze disponibili per nuovi dispositivi. ',
  },
  '205': {
    code: '205',
    title: 'ERRORE DI REGISTRAZIONE ',
    description: 'Contattare l’assistenza tecnica. ',
  },
  '206': {
    code: '206',
    title: 'BATTERIA ESAURITA ',
    description: 'Collegare alla rete elettrica e ricaricare. ',
  },
  '207': {
    code: '207',
    title: 'INSERIRE TIN ',
    description:
      'È richiesto l’inserimento dell’identifcativo del tecnico (Technical Identifcation Number, TIN). ',
  },
  '208': {
    code: '208',
    title: 'FILE ACK NON RICEVUTO ',
    description:
      'Non è stato possibile inviare alla Revenue Authority i dati della giornata. ',
  },
  '209': {
    code: '209',
    title: 'RISPOSTA SERVER HTTP ERRATA ',
    description:
      'Controllare i parametri di confgurazione di rete oppure i parametri di confgurazione del servizio Scontrino Sicuro. ',
  },
  '210': {
    code: '210',
    title: 'ERRORE CRITTOGRAFIA ',
    description: 'Errore nell’operazione di crittografa. ',
  },
  '211': {
    code: '211',
    title: 'ERRORE DOWNLOAD MMC ',
    description:
      'Controllare i parametri di confgurazione Ethernet oppure i parametri di confgurazione del servizio Scontrino sicuro. ',
  },
  '212': {
    code: '212',
    title: 'ERRORE DURANTE INIZIALIZZAZIONE FISCALE ',
    description: 'È subentrato un errore durante l’inizializzazione fscale. ',
  },
  '213': {
    code: '213',
    title: 'DISPOSITIVO OCCUPATO IN OPERAZIONI TELEMATICHE ',
    description:
      'Il dispositivo è occupato durante l’esecuzione di operazioni telematiche. ',
  },
  '214': {
    code: '214',
    title: 'ATTESA PERIODO DI INATTIVITÀ ',
    description: 'Deve essere eseguita una chiusura fscale giornaliera. ',
  },
  '215': {
    code: '215',
    title: 'CODICE IVA NON CORRETTO ',
    description: 'Il codice IVA inserito non è corretto. ',
  },
  '216': {
    code: '216',
    title: 'TAMPER OPEN ',
    description:
      'Il dispositivo è stato aperto/manomesso. Contattare l’assistenza tecnica. ',
  },
  '217': {
    code: '217',
    title: 'OCCUPATO DURANTE ESPORTAZIONE XML ',
    description:
      'Il dispositivo è occupato durante l’esportazione del fle XML. ',
  },
  '220': {
    code: '220',
    title: 'Configurazione FPU Mode/Protocollo errata',
    description:
      'Verificare che la stampante sia impostata con protocollo di comunicazione Custom e che la FPU Mode sia disabilitata',
  },
  '229': {
    code: '229',
    title: 'ERRORE DI SISTEMA ',
    description: 'Errore di timeout della sezione critica. ',
  },
  '232': {
    code: '232',
    title: 'BILANCIA NON PRESENTE ',
    description: 'Bilancia non presente, non collegata oppure spenta. ',
  },
  '233': {
    code: '233',
    title: 'IL PESO È ZERO ',
    description: 'Il peso rilevato e comunicato dalla bilancia è zero. ',
  },
  '235': {
    code: '235',
    title: 'ERRORE SERVIZIO ETHERNET ',
    description: 'Il servizio Ethernet non è stato avviato correttamente. ',
  },
  '236': {
    code: '236',
    title: 'MIGRAZIONE NON AUTORIZZATA ',
    description: 'La migrazione del dispositivo non è stata autorizzata. ',
  },
  '237': {
    code: '237',
    title: 'ERRORE MIGRAZIONE ',
    description: 'La migrazione del dispositivo è fallita. ',
  },
  '244': {
    code: '244',
    title: 'CERTIFICATO MANCANTE ',
    description: 'Il certifcato non è presente in memoria fscale. ',
  },
  '245': {
    code: '245',
    title: 'XML NON VALIDO ',
    description: 'Documento XML non valido. ',
  },
  '248': {
    code: '248',
    title: 'MIGRAZIONE GIÀ FATTA ',
    description: 'La migrazione del dispositivo è già stata eseguita. ',
  },
  '249': {
    code: '249',
    title: 'MESSAGGIO ERRORE ACK ',
    description: 'Errore in risposta al servizio Ethernet richiesto. ',
  },
  '250': {
    code: '250',
    title: 'DISPOSITIVO GIÀ ATTIVATO ',
    description: 'L’attivazione del dispositivo è già stata eseguita. ',
  },
  '251': {
    code: '251',
    title: 'ERRORE CARICAMENTO CRITTOGRAFIA ',
    description:
      'Errore nel caricamento della chiave privata o del certifcato del dispositivo. ',
  },
  '252': {
    code: '252',
    title: 'COMUNICAZIONE ETHERNET OCCUPATA ',
    description:
      'Semaforo per invio dati XML occupato a causa del task di invio automatico. ',
  },
  '253': {
    code: '253',
    title: 'CHIAVE PRIMARIA MANCANTE ',
    description: 'La chiave privata non è presente. ',
  },
  '254': {
    code: '254',
    title: 'ERRORE CREAZIONE CSR ',
    description:
      'Errore nella creazione della richiesta di certifcato frmato (CSR) del dispositivo. ',
  },
  '255': {
    code: '255',
    title: 'RISPOSTA XML NON VALIDA ',
    description: 'Errore nella creazione dell’oggetto di risposta XML. ',
  },
  '256': {
    code: '256',
    title: 'SERVIZIO ETH DISABILITATO ',
    description: 'Il servizio Ethernet non è abilitato. ',
  },
};

export function getError(code: string) {
  return (
    ERRORS[code] || {
      code,
      title: 'ERRORE SCONOSCIUTO',
      description:
        'È stato generato un errore sconosciuto. Comunica il codice errore al tecnico della stampante',
    }
  );
}
