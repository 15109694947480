import { FiscalPrinterRTStatus } from '../fiscal-printer-rt-status';

import { RhinoFiscalPrinterStatusResponse } from './rhino-fiscal-printer-response';

export class RhinoFiscalPrinterRTStatusParser {
  parse({ status }: RhinoFiscalPrinterStatusResponse): FiscalPrinterRTStatus {
    return {
      isActive: status,
      inactivePeriod: 'false',
      currentRTState: status ? 'active' : 'not_active',
      dayOpened: true,
    };
  }
}
