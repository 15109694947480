import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  flatMap,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { MediasService } from '../../services';
import { RootState } from '../root-state';

import * as featureActions from './actions';

@Injectable()
export class MediasStoreEffects {
  constructor(
    private dataService: MediasService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private store: Store<RootState>,
    private translate: TranslateService,
    private notifications: NotificationService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      withLatestFrom(this.store),
      flatMap(
        ([action, store]: [featureActions.LoadRequestAction, RootState]) => {
          const { all_size, type, type_id, format_size } = action.payload;
          const images = get(store.medias, `[${type}][${type_id}]`, null);
          if (images !== null) {
            return of(
              new featureActions.LoadSuccessAction({
                type,
                type_id,
                items: images,
              }),
            );
          }
          return this.dataService
            .load(type, type_id, all_size, format_size)
            .pipe(
              map(
                ({ data }: any) =>
                  new featureActions.LoadSuccessAction({
                    type,
                    type_id,
                    items: data,
                  }),
              ),
              catchError((error) => {
                //this.errorHandler.handle(error);
                return of(new featureActions.LoadFailureAction({ error }));
              }),
            );
        },
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      flatMap((action: featureActions.DeleteRequestAction) => {
        const { type, type_id, media_id } = action.payload;
        return this.dataService.delete(type, type_id, media_id).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.delete_success', {
                param: this.translate.instant('media'),
              }),
              type: 'success',
            });
            return new featureActions.DeleteSuccessAction({
              type,
              type_id,
              media_id,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.DeleteFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      flatMap((action: featureActions.CreateRequestAction) => {
        const { type, type_id, image, genre, replaceOld, disableNotify } =
          action.payload;
        return this.dataService.create(type, type_id, image, genre).pipe(
          map(({ data }: any) => {
            if (!disableNotify) {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.create_success',
                  {
                    param: this.translate.instant('media'),
                  },
                ),
                type: 'success',
              });
            }

            return new featureActions.CreateSuccessAction({
              type,
              type_id,
              genre,
              media: data.media,
              label: data.label || null,
              id: data.id,
              replaceOld,
              formats_size: data.formats_size,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.CreateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  sortRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SortRequestAction>(
        featureActions.ActionTypes.SORT_REQUEST,
      ),
      switchMap((action: featureActions.SortRequestAction) => {
        const { type, type_id, ids, genre } = action.payload;
        return this.dataService.sort(type, +type_id, ids, genre).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('medias'),
              }),
              type: 'success',
            });
            return new featureActions.SortSuccessAction({
              type,
              type_id: +type_id,
              genre,
              ids,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.SortFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
