import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { from, Observable } from 'rxjs';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { floatRound } from '../../../helpers/float-round';
import {
  FiscalPrinter,
  InvoiceDetails,
  InvoicePrintingSessionRequest,
  RegisterInvoice,
} from '../../../models';
import { InvoiceReceiptXml } from '../../../models/objects/invoice-receipt-xml';
import { InvoiceRegisterServiceParams } from '../../../models/objects/invoice-register-service-params';
import { InvoicesLayoutsService } from '../../../services/invoices-layouts.service';
import { WarningConfirmModalService } from '../../../services/warning-confirm-modal.service';
import { NotificationService } from '../../../ui/services/notification.service';
import { FiscalPrinterResolverService } from '../../../use-cases/fiscal-printers/fiscal-printer-resolver.service';

import { InvoiceRegisterService } from './invoice-register.service';
import { upperCase } from 'lodash';

function saveLog(log) {
  const logs: any[] = JSON.parse(
    localStorage.getItem('beddy_printer_errors') || '[]',
  ).slice(-9);

  logs.push({ ...log, datetime: new Date().toLocaleString() });

  localStorage.setItem('beddy_printer_errors', JSON.stringify(logs));
}

@Injectable({ providedIn: 'root' })
export class FiscalReceiptRegisterService extends InvoiceRegisterService {
  constructor(
    protected modalService: NzModalService,
    protected message: NzMessageService,
    protected translate: TranslateService,
    protected notification: NotificationService,
    protected warningConfirmModalService: WarningConfirmModalService,
    protected dateFormatter: DateFormatterService,
    protected invoicesLayoutsService: InvoicesLayoutsService,
    private fiscalPrinterServiceResolver: FiscalPrinterResolverService,
  ) {
    super(
      modalService,
      message,
      translate,
      notification,
      warningConfirmModalService,
      dateFormatter,
      invoicesLayoutsService,
    );
  }

  /**
   * @override
   *
   * @description flusso di registrazione con stampante fiscale:
   * - selezione stampante
   * - richiesta xml
   * - stampa documento fiscale
   * - chiamata di register
   */
  register(params: InvoiceRegisterServiceParams): Observable<InvoiceDetails> {
    const {
      invoice,
      invoiceCashOut,
      layout,
      properties,
      registerType,
      registerService,
      registerExtraPayload,
      xmlForRegisterExtraPayload,
    } = params;

    const propertiesIds = this.getPropertiesLayoutIntersection(
      properties || [invoice.property_id],
      layout,
    );

    const utils = this.registerUtilsByType[registerType];

    const warning = utils.checks({ invoice, invoiceCashOut, layout }) as string;

    if (warning) {
      this.notification.warning(warning);
      return throwError('Registration warnings');
    }

    if (this.hasFiscalRoundError(params)) {
      this.notification.warning('fiscal_printer_round_error');
      return throwError('fiscal round error');
    }

    // Apro la modale per la selezione della stampante
    return this.getFiscalPrinter(invoice, layout, propertiesIds).pipe(
      switchMap((userInput) => {
        if (!userInput) {
          throw new Error('Fiscal printer modal closed by user');
        }

        const {
          printer,
          property_id,
          number: printedReceiptNumber,
          clousure_number: printedReceiptClosureNumber,
          invoice_date: printedReceiptInvoiceDate,
          manual_fp_register,
        } = userInput;

        const registerPayload: RegisterInvoice =
          this.dateFormatter.formatObjectDates({
            ...registerExtraPayload,
            ...invoiceCashOut,
            invoice_id: invoice.id,
            fp_send: manual_fp_register ? 0 : 1,
            printer_id: printer.id,
            force_operation: true,
            type: registerType,
            property_id,
            manual_fp_register,
          });

        const usePrintedReceiptFlow$ = of({
          print_session_id: null,
          description: null,
          number: printedReceiptNumber,
          clousure_number: printedReceiptClosureNumber,
          document_date: this.dateFormatter.toServerFormat(
            printedReceiptInvoiceDate,
          ),
        });

        const defaultPrinterFlow$ = registerService
          .getPrinterXmlForRegister(invoice.id, {
            ...registerPayload,
            ...xmlForRegisterExtraPayload,
          })
          .pipe(
            switchMap((printRequest) =>
              this.printFiscalReceipt(printer, printRequest).pipe(
                switchMap((printerResponse) => {
                  // Verifico che la stampante non abbia tornato ZERO come numero
                  if (!printerResponse.number) {
                    return throwError(
                      (): InvoicePrintingSessionRequest => ({
                        status: 'ERROR',
                        description: printerResponse.description,
                        notification: {
                          code: upperCase(this.translate.instant('error')),
                          title: this.translate.instant('number_not_valid'),
                          description: this.translate.instant(
                            'number_provided_by_fiscal_printer_is_not_valid',
                          ),
                        },
                      }),
                    );
                  }

                  return of({
                    ...printerResponse,
                    print_session_id: printRequest.print_session_id,
                  });
                }),
                catchError((error: InvoicePrintingSessionRequest) => {
                  return registerService
                    .updatePrintingSession(
                      invoice.id,
                      printRequest.print_session_id,
                      error,
                    )
                    .pipe(
                      switchMap(() => {
                        if (error.notification) {
                          this.notification.push({
                            type: 'error',
                            title: `[${error.notification.code}] ${error.notification.title}`,
                            content: error.notification.description,
                          });
                        } else {
                          this.notification.error('fiscal_printer_error');
                        }

                        return throwError(error);
                      }),
                    );
                }),
              ),
            ),
          );

        const flow$: Observable<{
          number: string;
          clousure_number: number;
          document_date: string;
          description: string;
          print_session_id: number;
          external_id?: string;
        }> = !!printedReceiptNumber
          ? usePrintedReceiptFlow$
          : defaultPrinterFlow$;

        return flow$.pipe(
          switchMap((data) => {
            const {
              clousure_number,
              document_date,
              number,
              print_session_id,
              description,
              external_id,
            } = data;

            const messageRef = this.createRegisterLoading();
            return this.updateInvoice(params).pipe(
              switchMap(() =>
                registerService
                  .register({
                    ...registerPayload,
                    print_session_id,
                    description,
                    clousure_number,
                    invoice_date: document_date,
                    number,
                    external_id,
                  })
                  .pipe(
                    map(({ data: responseData }) => {
                      this.removeRegisterLoading(messageRef);
                      return responseData[0];
                    }),
                  ),
              ),
              catchError((error) => {
                this.removeRegisterLoading(messageRef);
                return throwError(error);
              }),
            );
          }),
          catchError((error) => {
            saveLog(error);
            return throwError(error);
          }),
        );
      }),
    );
  }

  /**
   * @description stampa una ricevuta fiscale comunicando con il driver della
   * stampante corretta
   */
  private printFiscalReceipt(
    fiscalPrinter: FiscalPrinter,
    printRequest: InvoiceReceiptXml,
  ): Observable<{
    number: string;
    clousure_number: number;
    document_date: string;
    description: string;
    external_id?: string;
  }> {
    const { device_model_id } = fiscalPrinter;

    const service =
      this.fiscalPrinterServiceResolver.getInstance(device_model_id);

    return from(service.printReceipt(printRequest));
  }

  /**
   * @description Verifica temporanea la presenza di importi decimali non validi in contanti
   */
  private hasFiscalRoundError(params: InvoiceRegisterServiceParams): boolean {
    const { invoice, invoiceCashOut } = params;

    let cashTotal = 0;

    invoiceCashOut.reservation_payments?.forEach(({ id, amount }) => {
      const paymentInstance = invoice.reservation_payments?.find(
        (payment) => payment.id === id,
      );

      if (paymentInstance.payment_method_id === 15) {
        cashTotal = floatRound(amount + cashTotal);
      }
    });

    invoiceCashOut.payment_methods?.forEach(({ id, amount }) => {
      if (id === 15) {
        cashTotal = floatRound(amount + cashTotal);
      }
    });

    const decimals = (`${cashTotal}`.split('.')[1] || '00')
      .padEnd(2, '0')
      .substr(0, 2);

    if (+decimals % 5 !== 0) {
      return true;
    }

    return false;
  }
}
