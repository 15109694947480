import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MediaType } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class MediasService {
  constructor(private http: HttpClient) {}

  load(
    type: MediaType,
    type_id: number,
    all_size: boolean,
    format_size?: string,
  ) {
    if (format_size) {
      return this.http.get(
        `media/${type}/${type_id}/${format_size}${
          all_size ? '?all_size=1' : ''
        }`,
      );
    }
    return this.http.get(`media/${type}/${type_id}?all_size=1`);
  }

  delete(type: MediaType, type_id: number, media_id: number) {
    return this.http.delete(`media/${type}/${type_id}/${media_id}`);
  }

  create(type: MediaType, type_id: number, image: any, genre: string) {
    const formData = new FormData();
    formData.append('file', image);

    if (genre) {
      formData.append('genre', genre);
    }

    return this.http.post(`media/${type}/${type_id}`, formData);
  }

  sort(type: MediaType, type_id: number, ids: number[], genre: string) {
    return this.http.post(`media/order/${type}/${type_id}`, {
      id: ids,
      genre,
    });
  }
}
