import {
  emailTemplateType,
  IEmailTemplateCustom,
  IEmailTemplatePreview,
} from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';
import { flatMap, orderBy, toLower } from 'lodash';
import {
  IEmailTemplateCustomLabels,
  IEmailTemplateCustomLabelsFlat,
} from '../../features/commons/email-templates/models/email-template-custom.model';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getLabelsFlat = (
  labels: IEmailTemplateCustomLabels[],
): IEmailTemplateCustomLabelsFlat[] => {
  return flatMap(labels, (propertyLabels) =>
    propertyLabels.items.map((item) => ({
      label: item.label,
      id: item.id,
      property_id: propertyLabels.property_id,
    })),
  );
};

export const getLabels = (state: State): IEmailTemplateCustomLabels[] =>
  state.allLabels;

export const getEmailTemplateCustomPreview = (
  state: State,
): IEmailTemplatePreview => state.customEmailPreview;

export const emailTemplatesCustomState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('emailTemplatesCustom');

export const selectAllEmailTemplatesCustomItems: (
  state: object,
) => IEmailTemplateCustom[] = featureAdapter.getSelectors(
  emailTemplatesCustomState,
).selectAll;

export const selectEmailTemplatesCustomById = (id: number) =>
  createSelector(
    selectAllEmailTemplatesCustomItems,
    (allEmailTemplateCustom: IEmailTemplateCustom[]) => {
      if (allEmailTemplateCustom) {
        return allEmailTemplateCustom.find((p) => p.id === id);
      } else {
        return null;
      }
    },
  );

export const selectEmailTemplatesCustomByType = (
  type: emailTemplateType,
  order?: { key: string; direction: 'desc' | 'asc' },
) =>
  createSelector(
    selectAllEmailTemplatesCustomItems,
    (
      allEmailTemplateCustom: IEmailTemplateCustom[],
    ): IEmailTemplateCustom[] => {
      if (allEmailTemplateCustom) {
        let allEmailTemplateCustomFiltered = allEmailTemplateCustom.filter(
          (template) => template.type === type,
        );

        if (order?.key) {
          allEmailTemplateCustomFiltered = orderBy(
            allEmailTemplateCustomFiltered,
            (item) => toLower(item[order.key]),
            [order.direction],
          );
        }

        return allEmailTemplateCustomFiltered;
      } else {
        return null;
      }
    },
  );

export const selectEmailTemplatesCustomSearch = (
  type: emailTemplateType,
  order?: { key: string; direction: 'desc' | 'asc' },
  search?: string,
) =>
  createSelector(
    selectEmailTemplatesCustomByType(type, order),
    (
      allEmailTemplateCustom: IEmailTemplateCustom[],
    ): IEmailTemplateCustom[] => {
      if (!search) {
        return allEmailTemplateCustom;
      }

      return allEmailTemplateCustom?.filter((template) =>
        toLower(template?.label)?.includes(toLower(search)),
      );
    },
  );
export const selectEmailTemplatesCustomPreviewByLang = (lang: string) =>
  createSelector(emailTemplatesCustomState, (state: State) => {
    if (state.customEmailPreview) {
      return state.customEmailPreview[lang];
    }
  });

export const selectAllLabels = createSelector(
  emailTemplatesCustomState,
  getLabels,
);

export const selectAllLabelsFlat = createSelector(
  selectAllLabels,
  getLabelsFlat,
);

export const selectEmailTemplatesCustomPreview: MemoizedSelector<object, any> =
  createSelector(emailTemplatesCustomState, getEmailTemplateCustomPreview);

export const selectEmailTemplatesCustomError: MemoizedSelector<object, any> =
  createSelector(emailTemplatesCustomState, getError);

export const selectEmailTemplatesCustomIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(emailTemplatesCustomState, getIsLoading);
