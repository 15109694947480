import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('invoiceNumber');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectNumberAlredyUsed = createSelector(
  selectState,
  (state) => state.alredy_used,
);
export const selectDateNumberNoSequential = createSelector(
  selectState,
  (state) => state.date_number_no_sequential,
);
