import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';

import { DateFormatterService } from '../core/services/date-formatter.service';
import { TokenService } from '../core/services/token.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadRatesService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private dateFormatter: DateFormatterService,
  ) {}

  downloadRates(propertyId: number, channelId: number, date_from, date_to) {
    const dateFrom = date_from || this.dateFormatter.toServerFormat(new Date());
    const dateTo =
      date_to ||
      this.dateFormatter.toServerFormat(moment().add(2, 'year').toDate());

    return this.http.get(
      `channelmanager/rates/${channelId}/${propertyId}/${dateFrom}/${dateTo}`,
    );
  }
}
