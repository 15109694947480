import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { capitalize, get, isEqual, omit } from 'lodash';
import moment from 'moment';
import { SubSink } from 'subsink';

import { DateFormatterService } from '../../core/services/date-formatter.service';
import {
  showChildrenRangesConditionMapConstant,
  showPeriodConditionMapConstant,
  showQuantityAndRateConditionMapConstant,
} from '../../helpers/bills-helpers';
import { calcDiscount } from '../../helpers/calc-discount';
import { EstimateAddonPriceRequest, PlaceVatQuote } from '../../models';
import { EstimateAddonModalData } from '../../models/objects/estimate-addon-modal';
import {
  ChildrenRangeStoreActions,
  ChildrenRangeStoreSelectors,
} from '../../root-store/children-range-store';
import {
  EstimateAddonPriceStoreActions,
  EstimateAddonPriceStoreSelectors,
} from '../../root-store/estimate-addon-price-store';
import { RootState } from '../../root-store/root-state';
import { NotificationService } from '../../ui/services/notification.service';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-addon-estimate-modal',
  templateUrl: './addon-estimate-modal.component.html',
  styleUrls: ['.//addon-estimate-modal.component.scss'],
})
export class AddonEstimateModalComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<AddonEstimateModalComponent> =
    inject(NZ_MODAL_DATA);

  @Input() set addons(addons: EstimateAddonModalData[]) {
    if (!addons || !addons.length) {
      return;
    }

    this.addonsForms = addons.reduce((acc, addon) => {
      const {
        date_ranges,
        addon_id,
        total_children,
        adults_number,
        category_title,
        category_id,
        price,
      } = addon;

      const { from, to } = get(date_ranges, '[0]', {}) as {
        from: string;
        to: string;
      };
      const children_number = (total_children || []).reduce(
        (sum, child) => (sum += child.quantity),
        0,
      );

      const dateFrom = moment(from).toDate();
      const dateTo = moment(to).toDate();

      // conservo le categorie degli addon per il filtro (select)
      this.categories = { ...this.categories, [category_id]: category_title };

      // genero il form group dell'addon
      const form = this.formBuilder.group({
        ...addon,
        date_range: [[dateFrom, dateTo]],
        single_date: dateFrom,
        total: price,
        original_estimated_total: 0,
        children_number,
        total_children: this.formBuilder.array(
          this.generateChildrenForm(total_children),
        ),
        adults_number,
        discount_value: 0,
      });

      // salvo le informazioni globali della camera
      // utili per controlli su date e input
      // anche se viene sovrascritto per ogni ciclo non ha importanza
      // sono dati della camera, non dell'addon
      this.accommodationData = {
        max_adults: adults_number,
        max_children: children_number,
        arrival_date: dateFrom,
        departure_date: dateTo,
      };

      // all'interno della seguente funzione mi sottoscrivo
      // al valueChange di ogni addon-form
      // this.formSubscription(form, addon_id);

      return (acc = {
        ...acc,
        [addon_id]: {
          form,
          expand: false,
          firstEstimateDone: false,
          valuesCache: { total: 0, price: 0 },
        },
      });
    }, {});

    this.loadWithProperty(addons[0].property_id);
  }

  @Input() cart: any[] = this.nzDataModal.cart || [];

  @Input() vatQuotes: PlaceVatQuote[] = this.nzDataModal.vatQuotes || [];

  @Input() currencySymbol: string = this.nzDataModal.currencySymbol || '';

  priceLoading$ = this.store.pipe(
    select(EstimateAddonPriceStoreSelectors.selectIsLoading),
  );

  estimateData$ = this.store.pipe(
    select(EstimateAddonPriceStoreSelectors.selectTotalAddons),
  );

  addonsForms: {
    [addon_id: number]: {
      form: UntypedFormGroup;
      expand: boolean;
      firstEstimateDone: boolean;
      childrenForm: number[];
      valuesCache: {
        total: number;
        price: number;
      };
    };
  };

  accommodationData: {
    max_adults: number;
    max_children: number;
    arrival_date: Date;
    departure_date: Date;
  };

  searchValue = '';
  selectedCategoryId = null;

  categories: { [category_id: number]: string } = {};

  childrenRanges = [];

  showPeriodConditionMapConstant = showPeriodConditionMapConstant;
  showChildrenRangesConditionMapConstant =
    showChildrenRangesConditionMapConstant;
  showQuantityAndRateConditionMapConstant =
    showQuantityAndRateConditionMapConstant;

  private subs = new SubSink();

  constructor(
    private store: Store<RootState>,
    private formBuilder: UntypedFormBuilder,
    private notification: NotificationService,
    private translate: TranslateService,
    private dateFormatter: DateFormatterService,
  ) {
    this.addons = this.nzDataModal.addons;
  }

  ngOnInit() {
    this.subs.add(
      this.store
        .pipe(select(ChildrenRangeStoreSelectors.selectAllchildrenRangeItems))
        .subscribe((ranges) => {
          this.childrenRanges = ranges.map(({ id: rangeId, combination }) => ({
            id: rangeId,
            age: `${combination.from} - ${combination.to}`,
            combination,
          }));
        }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(EstimateAddonPriceStoreActions.resetState());
  }

  onChangeAddonExpandStatus(status: boolean, addon_id: number) {
    this.addonsForms[addon_id].expand = status;
  }

  estimate(addon: EstimateAddonPriceRequest) {
    if (!addon) {
      return;
    }

    const { quantity, addon_id, adults } = addon;

    if ((!quantity && !adults) || !addon_id) {
      return;
    }

    this.store.dispatch(EstimateAddonPriceStoreActions.loadRequest(addon));
  }

  private generateChildrenForm(
    totalChildren: Array<{ id: number; quantity: number }>,
  ): UntypedFormGroup[] {
    return (totalChildren || []).map(({ id, quantity }) =>
      this.formBuilder.group({ id, quantity }),
    );
  }

  addToCart(addonId: number) {
    const addonForm = this.addonsForms[addonId].form.value;

    const { type, vat_quote_id } = addonForm;

    const addonVatQuote = (this.vatQuotes || []).find(
      ({ id }) => +id === +vat_quote_id,
    );
    const price = addonForm.price * addonForm.quantity;
    const addon = omit(
      {
        ...addonForm,
        price,
        price_without_discount: price,
        total: this.addonsForms[addonId].firstEstimateDone
          ? addonForm.total
          : addonForm.price * addonForm.quantity,
        adults: addonForm.adults_number,
        base_price: addonForm.price,
        children: (addonForm.total_children || []).map((child) => ({
          ...child,
          age: this.childrenRanges.find(({ id }) => +id === child.id)
            .combination.to,
        })),
        periods: [
          {
            date_from: this.dateFormatter.toServerFormat(
              this.showPeriodConditionMapConstant[type]
                ? addonForm.date_range[0]
                : addonForm.single_date,
            ),
            date_to: this.dateFormatter.toServerFormat(
              this.showPeriodConditionMapConstant[type]
                ? addonForm.date_range[1]
                : addonForm.single_date,
            ),
          },
        ],
        vat_quote_description: `${(+addonVatQuote.value).toFixed(0)}% - ${
          addonVatQuote.description
        }`,
      },
      'accommodations',
      'adults_number',
      'children_number',
      'date_range',
      'date_ranges',
      'original_estimated_total',
      'single_date',
      'total_children',
    );

    this.cart = this.cart || [];

    const sameAddonIndex = this.cart.findIndex((cartAddon) =>
      isEqual(
        omit(cartAddon, 'quantity', 'price', 'total'),
        omit(addon, 'quantity', 'price', 'total'),
      ),
    );

    if (
      sameAddonIndex !== -1 &&
      this.showQuantityAndRateConditionMapConstant[type]
    ) {
      this.cart = [
        ...this.cart.map((cartAddon, index) => {
          if (index === sameAddonIndex) {
            const {
              quantity: cartAddonQuantity,
              base_price: cartAddonBasePrice,
              discount_value: cartAddonDiscountValue,
            } = cartAddon;
            const { quantity: addedAddonQuantity } = addon;

            const newQuantity = cartAddonQuantity + (addedAddonQuantity || 1);

            // prezzo da inviare al backend senza sconto
            let price = cartAddonBasePrice * newQuantity;

            // prezzo da visualizzare con sconto applicato
            const total = cartAddonDiscountValue
              ? price - calcDiscount(price, cartAddonDiscountValue)
              : price;

            ////////////////////////////////////////////////////////
            ///// AL MOMENTO MANDIAMO PREZZO SCONTATO ALLE API /////
            price = total;
            ////////////////////////////////////////////////////////

            return {
              ...cartAddon,
              quantity: newQuantity,
              total,
              price,
            };
          }

          return cartAddon;
        }),
      ];
    } else {
      const total = addon.discount_value
        ? addon.price - calcDiscount(addon.price, addon.discount_value)
        : addon.price;

      const newAddon = {
        ...addon,
        // prezzo da visualizzare con sconto applicato
        total,
        ////////////////////////////////////////////////////////
        ///// AL MOMENTO MANDIAMO PREZZO SCONTATO ALLE API /////
        price: total,
        ////////////////////////////////////////////////////////
      };
      this.cart = [...this.cart, newAddon];
    }

    this.notification.push({
      title: capitalize(this.translate.instant('done')),
      content: this.translate.instant('addon_added_to_cart'),
      type: 'success',
    });
  }

  removeCartItem(addonIndex: number) {
    this.cart = [...this.cart.filter((_, index) => index !== addonIndex)];
    this.notification.push({
      title: capitalize(this.translate.instant('done')),
      content: this.translate.instant('addon_removed_from_cart'),
      type: 'success',
    });
  }

  private loadWithProperty(propertyId: number) {
    this.store.dispatch(
      new ChildrenRangeStoreActions.LoadRequestAction({
        propertyIds: [propertyId],
      }),
    );
  }
}
