import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';

import { EstimateAddonPriceRequest } from '../models';

interface EstimateResponse {
  data: {
    total: number;
    unit_price: number; // totale / unità
    quantity: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class EstimateAddonPriceService {
  constructor(private http: HttpClient) {}

  load(request: EstimateAddonPriceRequest): Observable<EstimateResponse> {
    return this.http.post<EstimateResponse>(
      `addon/${request.addon_id}/estimate`,
      request,
    );
  }
}
