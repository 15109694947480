<div nz-row class="by-mb-2">
  <strong>
    {{ invoicesTemplateData?.subject | translate | upperfirst }}
  </strong>
</div>
<div>
  <form nz-form [formGroup]="form" nzLayout="vertical">
    @if (customerEmail) {
      <nz-form-item>
        <nz-form-control
          [nzErrorTip]="
            'validation_form.required'
              | translate: { param: 'recipient' | translate }
          "
        >
          <label nz-checkbox formControlName="customer_email_flag">
            <div class="by-flex by-gap-5">
              <span>{{
                'send_email_to_customer' | translate | upperfirst
              }}</span>
              <span>({{ customerEmail }})</span>
            </div>
          </label>
        </nz-form-control>
      </nz-form-item>
    }
    <nz-form-item>
      <nz-form-label [nzRequired]="form?.controls?.email_to | isRequired">
        {{ 'email' | translate | upperfirst }} {{ 'recipient' | translate }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="emailsErrors">
        <nz-select
          nzMode="tags"
          style="width: 100%"
          nzPlaceHolder="{{ 'email' | translate | upperfirst }}"
          formControlName="email_to"
        >
        </nz-select
      ></nz-form-control>
      <ng-template #emailsErrors let-control>
        @if (control.hasError('required')) {
          {{
            'validation_form.required'
              | translate: { param: 'email' | translate }
              | capitalize
          }}
        }
        @if (control.hasError('emailArrayValidator')) {
          {{
            'validation_form.arrayemailvalidator'
              | translate: { param: 'email' | translate }
              | capitalize
          }}
        }
      </ng-template>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>
        {{ 'template_system_type' | translate | capitalize }}
      </nz-form-label>
      <nz-form-control>
        <nz-select formControlName="templateSystemType">
          <nz-option
            [nzLabel]="'system' | translate | capitalize"
            nzValue="system"
          ></nz-option>
          <nz-option
            [nzLabel]="'custom' | translate | capitalize"
            nzValue="custom"
            [nzDisabled]="!(customTemplates$ | async).length"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    @if (form.value.templateSystemType === 'custom') {
      <nz-form-item>
        <nz-form-control>
          <nz-select
            [nzAllowClear]="false"
            formControlName="template_custom_id"
          >
            @for (
              customTemplate of customTemplates$ | async;
              track customTemplate
            ) {
              <nz-option
                [nzLabel]="customTemplate.label"
                [nzValue]="customTemplate.id"
              ></nz-option>
            }
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    }

    <div nz-row>
      <div>
        <span>
          <i class="far fa-paperclip"></i>
          {{ 'attachments' | translate | upperfirst }}
        </span>
      </div>
      <nz-checkbox-wrapper style="width: 100%">
        <div nz-row>
          <div nz-col nzSpan="8">
            <label nz-checkbox nzDisabled formControlName="pdf">{{
              'pdf' | translate | upperfirst
            }}</label>
          </div>
          <div nz-col nzSpan="8">
            <label nz-checkbox formControlName="xml">{{
              'xml' | translate | upperfirst
            }}</label>
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>
    <div nz-row class="by-mb-2" nzJustify="end">
      <button class="by-mr-2" nz-button nzType="default" (click)="closeModal()">
        {{ 'cancel' | translate | upperfirst }}
      </button>
      <button nz-button nzType="primary" (click)="sendEmail()">
        {{ 'send_email' | translate | upperfirst }}
      </button>
    </div>
  </form>
</div>
