import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment.prod';
import { generateParamArray } from '../core/helpers/params-generator';
import {
  CreateBookingWidgetRequest,
  UpdateBookingWidgetRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class BookingWidgetsService {
  constructor(private http: HttpClient) {}

  load(properties: number[]) {
    return this.http.get(
      `booking_widgets?${generateParamArray('property_id', properties)}`,
    );
  }

  loadById(bookingWidgetId: number) {
    return this.http.get(`booking_widget/${bookingWidgetId}`);
  }

  create(request: CreateBookingWidgetRequest) {
    return this.http.post('booking_widget', request);
  }

  update({ booking_widget_id, ...request }: UpdateBookingWidgetRequest) {
    return this.http.put(`booking_widget/${booking_widget_id}`, request);
  }

  delete(bookingWidgetId: number) {
    return this.http.delete(`booking_widget/${bookingWidgetId}`);
  }

  loadTemplates() {
    return this.http.get(
      `${environment.cdnUrl}bol/bol-widgets-templates.json`,
      { responseType: 'json' },
    );
  }

  loadFonts() {
    return this.http.get(`${environment.cdnUrl}bol/bol-fonts.json`, {
      responseType: 'json',
    });
  }

  checkDns(dns: string) {
    return this.http.post(`booking_widget/check_dns`, { dns });
  }
}
