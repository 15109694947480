import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';

import { UpdateTableauDetailsRequest } from '../features/commons/accommodations/models/accommodation.model';

@Injectable({
  providedIn: 'root',
})
export class TableauNumbersService {
  constructor(private http: HttpClient) {}

  load(propertyId: number[], referenceDate?: string) {
    return this.http.get(
      `tableau/accommodations_mapping?${generateParamArray(
        'property_id',
        propertyId,
      )}&with_details=1${
        referenceDate ? `&reference_date=${referenceDate}` : ''
      }`,
    );
  }

  updateTableauDetails(data: Partial<UpdateTableauDetailsRequest>) {
    return this.http.put(
      `accommodation/number/${data.accommodation_tableau_number_id}`,
      data,
    );
  }

  order(accommodationId: number, tableauNumbesrId: number[]) {
    return this.http.post(`accommodation/${accommodationId}/tableau_order`, {
      tableau_id: tableauNumbesrId,
    });
  }
}
