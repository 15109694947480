import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';
import { Observable } from 'rxjs';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  BookingPromotion,
  BookingTargetsPromotion,
  CreateBookingPromotionRequest,
  DeleteBookingPromotionRequest,
  EditBookingPromotionRequest,
  LoadBookingPromotionRequest,
  LoadBookingPromotionsRequest,
  LoadBookingTargetsRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class BookingPromotionsService {
  constructor(private http: HttpClient) {}

  loadBookingPromotions(data: LoadBookingPromotionsRequest) {
    const { property_id, channel_id } = data;
    return this.http.get(
      `channelmanager/promotions/${channel_id}?${generateParamArray(
        'property_id',
        property_id,
      )}`,
    );
  }

  loadBookingPromotionDetails(data: LoadBookingPromotionRequest) {
    const { property_id, channel_id, promotion_id, property_xml_id } = data;
    return this.http.get(
      `channelmanager/promotion/${channel_id}/${promotion_id}?property_id=${property_id}&property_xml_id=${property_xml_id}`,
    ) as Observable<IResponseSuccess<BookingPromotion>>;
  }

  loadBookingTarget(data: LoadBookingTargetsRequest) {
    const { channel_id, property_id } = data;
    return this.http.get(
      `channelmanager/targetspromotion/${channel_id}?${generateParamArray(
        'property_id',
        property_id,
      )}`,
    ) as Observable<IResponseSuccess<BookingTargetsPromotion>>;
  }

  createBookingPromotion(data: CreateBookingPromotionRequest) {
    const { channel_id, property_id } = data;
    return this.http.post(
      `channelmanager/promotion/${channel_id}/${property_id}`,
      data,
    ) as Observable<IResponseSuccess>;
  }

  updateBookingPromotion(data: EditBookingPromotionRequest) {
    const { channel_id, property_id } = data;
    return this.http.put(
      `channelmanager/promotion/${channel_id}/${property_id}`,
      data,
    ) as Observable<IResponseSuccess>;
  }

  deleteBookingPromotion(data: DeleteBookingPromotionRequest) {
    const { channel_id, property_id, property_xml_id, promotion_id } = data;
    const url = `channelmanager/promotion/${channel_id}/${promotion_id}?property_id=${property_id}&property_xml_id=${property_xml_id}`;
    return this.http.delete(url) as Observable<IResponseSuccess>;
  }
}
