import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class HousekeeperRecordTasksService {
  constructor(private http: HttpClient) {}

  load(
    propertyId: number,
    params: { related_type: string; related_id: number; date: string },
  ) {
    return this.http.get(
      `properties/${propertyId}/housekeeper/records/tasks?${generateSearchQuery(
        params,
      )}`,
    );
  }

  setCompleted(
    propertyId: number,
    request: {
      related_type: string;
      related_id: number;
      date: string;
      task_id: number;
      completed: boolean;
    },
  ) {
    return this.http.post(
      `properties/${propertyId}/housekeeper/records/tasks`,
      request,
    );
  }
}
