import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';

import {
  NewsletterStats,
  NewsletterStatsWidget,
  NewsletterStatsWidgetRequest,
} from '../models';
import {
  NewsletterMailCounter,
  NewsletterMailCounterRequest,
} from '../models/objects/newsletter-mail-count';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class NewsletterStatsService {
  constructor(private http: HttpClient) {}

  load(property_ids: number[]) {
    return this.http.get<NewsletterStats>(
      `newsletters/counts?${generateParamArray('property_ids', property_ids)}`,
    );
  }

  loadWidget(data: NewsletterStatsWidgetRequest) {
    return this.http.get<NewsletterStatsWidget>(
      `statistics/newsletters/sent?${generateSearchQuery(data)}`,
    );
  }

  loadMailCount(data: NewsletterMailCounterRequest) {
    return this.http.get<IResponseSuccess<NewsletterMailCounter>>(
      `newsletters/monthly_quotas?${generateSearchQuery(data)}`,
    );
  }
}
