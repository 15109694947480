import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  AutomaticPaymentType,
  AutomaticPaymentsConfiguration,
  PaymentMethod,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class AutomaticPaymentsConfigurationService {
  constructor(private http: HttpClient) {}

  load(property_id: number[]) {
    return this.http.get<IResponseSuccess<AutomaticPaymentsConfiguration[]>>(
      `properties/payments/policies?${generateSearchQuery({ property_id })}`,
    );
  }

  loadDetails(id: number) {
    return this.http.get<IResponseSuccess<AutomaticPaymentsConfiguration[]>>(
      `properties/payments/policies/${id}`,
    );
  }

  loadPaymentMethods(
    payment_type: AutomaticPaymentType,
    property_id: number[],
  ) {
    return this.http.get<IResponseSuccess<PaymentMethod[]>>(
      `properties/payments/policies/payment_methods?${generateSearchQuery({ payment_type, property_id })}`,
    );
  }

  delete(id: number) {
    return this.http.delete<IResponseSuccess>(
      `properties/payments/policies/${id}`,
    );
  }

  create(
    automaticPaymentsConfiguration: AutomaticPaymentsConfiguration,
    force_operation = false,
  ) {
    return this.http.post<IResponseSuccess<[AutomaticPaymentsConfiguration]>>(
      `properties/payments/policies`,
      {
        ...automaticPaymentsConfiguration,
        ...(force_operation && { force_operation }),
      },
    );
  }

  edit(
    automaticPaymentsConfiguration: AutomaticPaymentsConfiguration,
    id: number,
    force_operation = false,
  ) {
    return this.http.put<IResponseSuccess<[AutomaticPaymentsConfiguration]>>(
      `properties/payments/policies/${id}`,
      {
        ...automaticPaymentsConfiguration,
        ...(force_operation && { force_operation }),
      },
    );
  }
}
