import { IEmailTemplateCustom } from '@commons/email-templates/models/email-template-custom.model';
import { IPropertiesData } from '@commons/properties/models/properties-data.model';
import { IProperty } from '@commons/properties/models/property.model';
import { Action } from '@ngrx/store';

import { IAvailableRoomRequest } from '../models/api/generics/available-room/available-room.model';
import { ICheckinOut } from '../models/api/generics/checkin-out/checkin-out.model';
import { ICurrency } from '../models/api/generics/currencies/currency.model';
import { IDiscountType } from '../models/api/generics/discount-types/discount-type.model';
import { ILanguage } from '../models/api/generics/languages/language.model';
import { IPaymentMethod } from '../models/api/generics/payment-methods/payment-method.model';
import { IVatQuoteHolderRequest } from '../models/api/generics/vat-quote-holder/vat-quote-holder.model';
import { IPropertyTreatment } from '../models/api/properties-treatments/property-treatment.model';
import {
  IMoveReservationRequest,
  IMoveReservationResponse,
} from '../models/api/reservation/move-reservation-request.model';
import { INewReservation } from '../models/api/reservation/reservaion.model';

import { IAvailableRoomData } from './../models/api/generics/available-room/available-room.model';
import { IPropertySelected } from './../models/api/property-selected/property-selected.model';
import {
  ISplitReservation,
  ISplitReservationResponse,
} from './../models/api/reservation/reservaion.model';

export enum CoreActionTypes {
  ResetAddBillModalRooms = '[Core] Load Core',

  LoadCore = '[Core] Load Core',

  SetLoading = '[Core] Set Loading',

  SelectProperty = '[Properties Page] Select Property',

  SortPropertiesSuccess = '[App Component] Sort Properties Success',

  LoadPropertiesTreatmentsRequest = '[App Component] Load Properties Treatments Request',
  LoadPropertiesTreatmentsSuccess = '[Core Effects] Load Properties Treatments Success',
  LoadPropertiesTreatmentsFailure = '[Core Effects] Load Properties Treatments Failure',

  LoadAllUserPropertiesRequest = '[User Core] Load All User Properties Request',
  LoadAllUserPropertiesSuccess = '[User Core] Load All User Properties Success',
  LoadAllUserPropertiesFailure = '[User Core] Load All User Properties Failure',

  LoadUserPropertiesRequest = '[User Core] Load User Properties Request',
  LoadUserPropertiesSuccess = '[User Core] Load User Properties Success',
  LoadUserPropertiesFailure = '[User Core] Load User Properties Failure',

  LoadPropertyDataSelectedRequest = '[Core] Load Property Data Selected Request',
  LoadPropertyDataSelectedSuccess = '[Core] Load Property Data Selected Success',
  LoadPropertyDataSelectedFailure = '[Core] Load Property Data Selected Failure',

  LoadGeneralDiscountTypesRequest = '[Core] Load General Discount Types Request',
  LoadGeneralDiscountTypesSuccess = '[Core] Load General Discount Types Success',
  LoadGeneralDiscountTypesFailure = '[Core] Load General Discount Types Failure',

  LoadLanguagesRequest = '[Core] Load Languages Request',
  LoadLanguagesSuccess = '[Core] Load Languages Success',
  LoadLanguagesFailure = '[Core] Load Languages Failure',

  LoadCurrenciesRequest = '[Core] Load Currencies Request',
  LoadCurrenciesSuccess = '[Core] Load Currencies Success',
  LoadCurrenciesFailure = '[Core] Load Currencies Failure',

  LoadCheckinOutRequest = '[Core] Load Checkin/out Request',
  LoadCheckinOutSuccess = '[Core] Load Checkin/out Success',
  LoadCheckinOutFailure = '[Core] Load Checkin/out Failure',

  SelectGroup = '[Core] Select Group',
  SelectProperties = '[Core] Select Properties',

  LoadReservationsRoomsCoreRequest = '[Core] Load Reservations Request',
  LoadReservationsRoomsCoreSuccess = '[Core] Load Reservations Success',
  LoadReservationsRoomsCoreFailure = '[Core] Load Reservations Failure',

  LoadReservationsRoomsToMoveCoreRequest = '[Core] Load ReservationsToMove Request',
  LoadReservationsRoomsToMoveCoreSuccess = '[Core] Load ReservationsToMove Success',
  LoadReservationsRoomsToMoveCoreFailure = '[Core] Load ReservationsToMove Failure',

  CreateNewReservationRequest = '[Core] Create New Reservation Request',
  CreateNewReservationSuccess = '[Core] Create New Reservation Success',
  CreateNewReservationFailure = '[Core] Create New Reservation Failure',

  LoadTemplateEmailCustomRequest = '[Core] Load Template Email Custom Request',
  LoadTemplateEmailCustomSuccess = '[Core] Load Template Email Custom Success',
  LoadTemplateEmailCustomFailure = '[Core] Load Template Email Custom Failure',

  LoadPaymentsMethodPropertyRequest = '[Core] Load PaymentsMethodProperty Request',
  LoadPaymentsMethodPropertySuccess = '[Core] Load PaymentsMethodProperty Success',
  LoadPaymentsMethodPropertyFailure = '[Core] Load PaymentsMethodProperty Failure',

  SplitReservationRequest = '[Core] Split Reservation Request',
  SplitReservationSuccess = '[Core] Split Reservation Success',
  SplitReservationWarningSuccess = '[Core] Split Reservation Warning Success',
  SplitReservationFailure = '[Core] Split Reservation Failure',

  MoveReservationRequest = '[Core] Move Reservation Request',
  MoveReservationSuccess = '[Core] Move Reservation Success',
  MoveReservationWarningSuccess = '[Core] SplMoveit Reservation Warning Success',
  MoveReservationFailure = '[Core] Move Reservation Failure',

  LoadAvailableRoomRequest = '[Core] Load Available Room Request',
  LoadAvailableRoomSuccess = '[Core] Load Available Room Success',
  LoadAvailableRoomFailure = '[Core] Load Available Room Failure',

  VatQuoteHolderRequest = '[Core] Vat Quote Holder Request',
  VatQuoteHolderSuccess = '[Core] Vat Quote Holder Success',
  VatQuoteHolderFailure = '[Core] Vat Quote Holder Failure',

  ResetFlashData = '[Core] Reset Flash Data',

  ResetOnChangeLanguage = '[Core] Reset On Change Language',

  ResetCore = '[Core] Reset',
}

export class SortPropertiesSuccess implements Action {
  readonly type = CoreActionTypes.SortPropertiesSuccess;
  constructor(public payload: { propertyIds: number[] }) {}
}

export class ResetAddBillModalRooms implements Action {
  readonly type = CoreActionTypes.ResetAddBillModalRooms;
}

export class ResetFlashData implements Action {
  readonly type = CoreActionTypes.ResetFlashData;
}

export class ResetOnChangeLanguage implements Action {
  readonly type = CoreActionTypes.ResetOnChangeLanguage;
}

export class LoadCore implements Action {
  readonly type = CoreActionTypes.LoadCore;
}

export class LoadPropertiesTreatmentsRequest implements Action {
  readonly type = CoreActionTypes.LoadPropertiesTreatmentsRequest;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class LoadPropertiesTreatmentsSuccess implements Action {
  readonly type = CoreActionTypes.LoadPropertiesTreatmentsSuccess;
  constructor(
    public payload: { propertiesTreatmentsData: IPropertyTreatment[] },
  ) {}
}

export class LoadPropertiesTreatmentsFailure implements Action {
  readonly type = CoreActionTypes.LoadPropertiesTreatmentsFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadPropertyDataSelectedRequest implements Action {
  readonly type = CoreActionTypes.LoadPropertyDataSelectedRequest;
}

export class LoadPropertyDataSelectedSuccess implements Action {
  readonly type = CoreActionTypes.LoadPropertyDataSelectedSuccess;
  constructor(public payload: { propertySelected: IPropertySelected }) {}
}

export class LoadPropertyDataSelectedFailure implements Action {
  readonly type = CoreActionTypes.LoadPropertyDataSelectedFailure;
  constructor(public payload: { error: Error }) {}
}

export class SelectProperty implements Action {
  readonly type = CoreActionTypes.SelectProperty;
  constructor(public payload: { propertyId: number }) {}
}

export class LoadGeneralDiscountTypesRequest implements Action {
  readonly type = CoreActionTypes.LoadGeneralDiscountTypesRequest;
}

export class LoadGeneralDiscountTypesSuccess implements Action {
  readonly type = CoreActionTypes.LoadGeneralDiscountTypesSuccess;
  constructor(public payload: { discountTypesData: IDiscountType[] }) {}
}

export class LoadGeneralDiscountTypesFailure implements Action {
  readonly type = CoreActionTypes.LoadGeneralDiscountTypesFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadLanguagesRequest implements Action {
  readonly type = CoreActionTypes.LoadLanguagesRequest;
}

export class LoadLanguagesSuccess implements Action {
  readonly type = CoreActionTypes.LoadLanguagesSuccess;
  constructor(public payload: { languages: ILanguage[] }) {}
}

export class LoadLanguagesFailure implements Action {
  readonly type = CoreActionTypes.LoadLanguagesFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadCurrenciesRequest implements Action {
  readonly type = CoreActionTypes.LoadCurrenciesRequest;
}

export class LoadCurrenciesSuccess implements Action {
  readonly type = CoreActionTypes.LoadCurrenciesSuccess;
  constructor(public payload: { currencies: ICurrency[] }) {}
}

export class LoadCurrenciesFailure implements Action {
  readonly type = CoreActionTypes.LoadCurrenciesFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadCheckinOutRequest implements Action {
  readonly type = CoreActionTypes.LoadCheckinOutRequest;
  constructor(
    public payload: { date_from?: string; propertiesIds: number[] },
  ) {}
}

export class LoadCheckinOutSuccess implements Action {
  readonly type = CoreActionTypes.LoadCheckinOutSuccess;
  constructor(
    public payload: { checkinOutData: ICheckinOut; date_from: string },
  ) {}
}

export class LoadCheckinOutFailure implements Action {
  readonly type = CoreActionTypes.LoadCheckinOutFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadAllUserPropertiesRequest implements Action {
  readonly type = CoreActionTypes.LoadAllUserPropertiesRequest;
  constructor(public payload: { userId: number }) {}
}
export class LoadAllUserPropertiesSuccess implements Action {
  readonly type = CoreActionTypes.LoadAllUserPropertiesSuccess;
  constructor(public payload: { allUserPropeties: IProperty[] }) {}
}
export class LoadAllUserPropertiesFailure implements Action {
  readonly type = CoreActionTypes.LoadAllUserPropertiesFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadUserPropertiesRequest implements Action {
  readonly type = CoreActionTypes.LoadUserPropertiesRequest;
  constructor(
    public payload: {
      userId: number;
      redirect?: string;
      skipWorkspaceSelection?: boolean;
      selectedPropertyId?: number;
    },
  ) {}
}

export class LoadUserPropertiesSuccess implements Action {
  readonly type = CoreActionTypes.LoadUserPropertiesSuccess;
  constructor(
    public payload: {
      properties: IPropertiesData;
      redirect?: string;
      selectedPropertyId?: number;
    },
  ) {}
}

export class LoadUserPropertiesFailure implements Action {
  readonly type = CoreActionTypes.LoadUserPropertiesFailure;
  constructor(public payload: { error: Error }) {}
}

export class SelectGroup implements Action {
  readonly type = CoreActionTypes.SelectGroup;
  constructor(public payload: { groupId: number }) {}
}

export class SelectProperties implements Action {
  readonly type = CoreActionTypes.SelectProperties;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class LoadReservationsRoomsCoreFailure implements Action {
  readonly type = CoreActionTypes.LoadReservationsRoomsCoreFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadReservationsRoomsToMoveCoreRequest implements Action {
  readonly type = CoreActionTypes.LoadReservationsRoomsToMoveCoreRequest;
  constructor(
    public payload: {
      propertiesIds?: number[];
      value?: string;
      field?: 'customer' | 'guest' | 'room_label';
      date: string;
    },
  ) {}
}

export class LoadReservationsRoomsToMoveCoreSuccess implements Action {
  readonly type = CoreActionTypes.LoadReservationsRoomsToMoveCoreSuccess;
  constructor(public payload: { reservations: any[] }) {}
}

export class LoadReservationsRoomsToMoveCoreFailure implements Action {
  readonly type = CoreActionTypes.LoadReservationsRoomsToMoveCoreFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadReservationsRoomsCoreRequest implements Action {
  readonly type = CoreActionTypes.LoadReservationsRoomsCoreRequest;
  constructor(
    public payload: {
      propertiesIds?: number[];
      value?: string;
      field?: 'customer' | 'guest' | 'room_label';
      date: string;
      noPagination?: boolean;
      status?: string[];
    },
  ) {}
}

export class LoadReservationsRoomsCoreSuccess implements Action {
  readonly type = CoreActionTypes.LoadReservationsRoomsCoreSuccess;
  constructor(public payload: { reservations: any[] }) {}
}

export class CreateNewReservationRequest implements Action {
  readonly type = CoreActionTypes.CreateNewReservationRequest;
  constructor(
    public payload: {
      reservationData: INewReservation;
      onSuccess?: () => void;
      onFailure?: () => void;
    },
  ) {}
}

export class CreateNewReservationSuccess implements Action {
  readonly type = CoreActionTypes.CreateNewReservationSuccess;
}

export class CreateNewReservationFailure implements Action {
  readonly type = CoreActionTypes.CreateNewReservationFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadTemplateEmailCustomRequest implements Action {
  readonly type = CoreActionTypes.LoadTemplateEmailCustomRequest;
  constructor(public payload: { property_id: number }) {}
}

export class LoadTemplateEmailCustomSuccess implements Action {
  readonly type = CoreActionTypes.LoadTemplateEmailCustomSuccess;
  constructor(
    public payload: {
      templatesEmailCustom: IEmailTemplateCustom[];
      property_id: number;
    },
  ) {}
}

export class LoadTemplateEmailCustomFailure implements Action {
  readonly type = CoreActionTypes.LoadTemplateEmailCustomFailure;
  constructor(public payload: { error: Error }) {}
}

export class LoadPaymentsMethodPropertyRequest implements Action {
  readonly type = CoreActionTypes.LoadPaymentsMethodPropertyRequest;
  constructor(public payload: { property_id: number }) {}
}

export class LoadPaymentsMethodPropertySuccess implements Action {
  readonly type = CoreActionTypes.LoadPaymentsMethodPropertySuccess;
  constructor(
    public payload: {
      paymentsMethodProperty: IPaymentMethod[];
      property_id: number;
    },
  ) {}
}

export class LoadPaymentsMethodPropertyFailure implements Action {
  readonly type = CoreActionTypes.LoadPaymentsMethodPropertyFailure;
  constructor(public payload: { error: Error }) {}
}

export class SplitReservationRequest implements Action {
  readonly type = CoreActionTypes.SplitReservationRequest;
  constructor(public payload: { splitReservation: ISplitReservation }) {}
}

export class SplitReservationSuccess implements Action {
  readonly type = CoreActionTypes.SplitReservationSuccess;
  constructor(
    public payload: { splitReservation: ISplitReservationResponse },
  ) {}
}
export class SplitReservationWarningSuccess implements Action {
  readonly type = CoreActionTypes.SplitReservationWarningSuccess;
  constructor(
    public payload: { splitReservation: ISplitReservationResponse },
  ) {}
}

export class SplitReservationFailure implements Action {
  readonly type = CoreActionTypes.SplitReservationFailure;
  constructor(public payload: { error: Error }) {}
}

export class MoveReservationRequest implements Action {
  readonly type = CoreActionTypes.MoveReservationRequest;
  constructor(public payload: { moveReservation: IMoveReservationRequest }) {}
}

export class MoveReservationSuccess implements Action {
  readonly type = CoreActionTypes.MoveReservationSuccess;
  constructor(public payload: { moveReservation: IMoveReservationResponse }) {}
}
export class MoveReservationWarningSuccess implements Action {
  readonly type = CoreActionTypes.MoveReservationWarningSuccess;
  constructor(public payload: { moveReservation: IMoveReservationResponse }) {}
}

export class MoveReservationFailure implements Action {
  readonly type = CoreActionTypes.MoveReservationFailure;
  constructor(public payload: { error: Error }) {}
}

export class SetLoading implements Action {
  readonly type = CoreActionTypes.SetLoading;
  constructor(public payload: { loading: boolean }) {}
}

export class LoadAvailableRoomRequest implements Action {
  readonly type = CoreActionTypes.LoadAvailableRoomRequest;
  constructor(public payload: { data: IAvailableRoomRequest }) {}
}

export class LoadAvailableRoomSuccess implements Action {
  readonly type = CoreActionTypes.LoadAvailableRoomSuccess;
  constructor(public payload: { data: IAvailableRoomData[] }) {}
}

export class LoadAvailableRoomFailure implements Action {
  readonly type = CoreActionTypes.LoadAvailableRoomFailure;
  constructor(public payload: { error: Error }) {}
}

export class VatQuoteHolderRequest implements Action {
  readonly type = CoreActionTypes.VatQuoteHolderRequest;
  constructor(public payload: { data: IVatQuoteHolderRequest }) {}
}

export class VatQuoteHolderSuccess implements Action {
  readonly type = CoreActionTypes.VatQuoteHolderSuccess;
  constructor(public payload: { data: any }) {}
}

export class VatQuoteHolderFailure implements Action {
  readonly type = CoreActionTypes.VatQuoteHolderFailure;
  constructor(public payload: { error: Error }) {}
}

export class ResetCore implements Action {
  readonly type = CoreActionTypes.ResetCore;
}

export type CoreActions =
  | LoadCore
  | SelectProperty
  | LoadGeneralDiscountTypesRequest
  | LoadGeneralDiscountTypesSuccess
  | LoadGeneralDiscountTypesFailure
  | LoadLanguagesRequest
  | LoadLanguagesSuccess
  | LoadLanguagesFailure
  | LoadCurrenciesRequest
  | LoadCurrenciesSuccess
  | LoadCurrenciesFailure
  | LoadCheckinOutRequest
  | LoadCheckinOutSuccess
  | LoadCheckinOutFailure
  | LoadPropertyDataSelectedRequest
  | LoadPropertyDataSelectedSuccess
  | LoadPropertyDataSelectedFailure
  | LoadUserPropertiesRequest
  | LoadUserPropertiesSuccess
  | LoadUserPropertiesFailure
  | LoadAllUserPropertiesRequest
  | LoadAllUserPropertiesSuccess
  | LoadAllUserPropertiesFailure
  | SelectGroup
  | SelectProperties
  | CreateNewReservationRequest
  | CreateNewReservationSuccess
  | CreateNewReservationFailure
  | LoadTemplateEmailCustomRequest
  | LoadTemplateEmailCustomSuccess
  | LoadTemplateEmailCustomFailure
  | LoadPaymentsMethodPropertyRequest
  | LoadPaymentsMethodPropertySuccess
  | LoadPaymentsMethodPropertyFailure
  | SplitReservationRequest
  | SplitReservationSuccess
  | SplitReservationWarningSuccess
  | SplitReservationFailure
  | MoveReservationRequest
  | MoveReservationSuccess
  | MoveReservationWarningSuccess
  | SetLoading
  | MoveReservationFailure
  | LoadPropertiesTreatmentsRequest
  | LoadPropertiesTreatmentsSuccess
  | LoadPropertiesTreatmentsFailure
  | LoadReservationsRoomsCoreRequest
  | LoadReservationsRoomsCoreSuccess
  | LoadReservationsRoomsCoreFailure
  | LoadReservationsRoomsToMoveCoreRequest
  | LoadReservationsRoomsToMoveCoreSuccess
  | LoadReservationsRoomsToMoveCoreFailure
  | ResetAddBillModalRooms
  | ResetFlashData
  | ResetOnChangeLanguage
  | LoadAvailableRoomRequest
  | LoadAvailableRoomSuccess
  | LoadAvailableRoomFailure
  | VatQuoteHolderRequest
  | VatQuoteHolderSuccess
  | VatQuoteHolderFailure
  | SortPropertiesSuccess
  | ResetCore;
