import { AbstractControl, ValidatorFn } from '@angular/forms';

export class AbstractControlHelper {
  static markAsPristineAndUpdateValueAndValidity(
    control: AbstractControl,
    opts?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ) {
    control?.markAsPristine(opts);

    control?.updateValueAndValidity(opts);
  }

  static markAsTouchedAndUpdateValueAndValidity(
    control: AbstractControl,
    opts?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ) {
    control?.markAsTouched(opts);

    control?.updateValueAndValidity(opts);
  }

  static addValidatorsAndUpdateValueAndValidity(
    control: AbstractControl,
    validators: ValidatorFn | ValidatorFn[],
    opts?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ) {
    control?.addValidators(validators);

    control?.updateValueAndValidity(opts);
  }

  static clearValidatorsAndUpdateValueAndValidity(
    control: AbstractControl,
    opts?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ) {
    control?.clearValidators();

    control?.updateValueAndValidity(opts);
  }
}
