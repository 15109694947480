import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { last } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap } from 'rxjs/operators';
import * as txml from 'txml';

import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { observableSequence } from '../../../helpers';
import { HttpProxyService } from '../../../services';
import { FiscalPrinterLoadingService } from '../../../services/fiscal-printer-loading.service';
import {
  Command,
  FiscalPrinterCommandService,
} from '../fiscal-printer-command.service';
import { FiscalPrinterDriver } from '../fiscal-printer-driver';
import { FiscalPrinterError } from '../fiscal-printer-error';

import { getError } from './rch-fiscal-printer-errors';
import { RchFiscalPrinterRTStatusParser } from './rch-fiscal-printer-rt-status-parser';
import { RchFiscalPrinterStatusParser } from './rch-fiscal-printer-status-parser';
import { RchFiscalPrinterStatusResponse } from './rch-fiscal-printer-status-response';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class RchFiscalPrinterDriverService implements FiscalPrinterDriver {
  constructor(
    private http: HttpClient,
    private proxy: HttpProxyService,
    private translate: TranslateService,
    private dateFormatter: DateFormatterService,
    private loading: FiscalPrinterLoadingService,
    private commandService: FiscalPrinterCommandService,
  ) {}

  async getStatus(printerId: number) {
    return this.commandService
      .getStatusCommand(printerId)
      .pipe(
        switchMap((command) => {
          return this.execute(command, true).pipe(
            map((printerResponse) => {
              return new RchFiscalPrinterStatusParser().parse(printerResponse);
            }),
          );
        }),
      )
      .toPromise();
  }

  async getRTStatus(printerId: number) {
    return this.commandService
      .getRTStatusCommand(printerId)
      .pipe(
        switchMap((command) => {
          return this.execute(command, true).pipe(
            map((printerResponse) => {
              return new RchFiscalPrinterRTStatusParser().parse(
                printerResponse,
              );
            }),
          );
        }),
      )
      .toPromise();
  }

  async toggleTrainingMode(printerId: number, status: boolean) {
    return this.commandService
      .getTrainingModeToggleCommand(printerId, status)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printNonTaxReceipt(printerId: number) {
    return this.commandService
      .getTestCommand(printerId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printReceipt(command: Command) {
    return this.execute(command)
      .pipe(
        map(({ Service, original }) => {
          if (Service.Request.printerError === '1') {
            throw original;
          }

          return {
            description: original,
            number: Service.Request.lastDocF,
            clousure_number: +Service.Request.lastZ + 1,
            document_date: this.dateFormatter.toServerFormat(new Date()),
          };
        }),
        catchError((error: FiscalPrinterError) => {
          return throwError({
            status: 'ERROR',
            description: error.message,
            notification: error.notification,
          });
        }),
      )
      .toPromise();
  }

  async dailyFiscalOperation(
    printerId: number,
    operations: Array<'daily_closure' | 'daily_report'>,
  ) {
    return this.commandService
      .getDailyOperationCommand(printerId, operations)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printReversalReceiptFiscal(printerId: number, invoiceId: number) {
    return this.commandService
      .getReversalCommand(printerId, invoiceId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(
            map(({ Service, original }) => {
              return {
                description: original,
                number: Service.Request.lastDocF,
                clousure_number: +Service.Request.lastZ + 1,
              };
            }),
            catchError((error: FiscalPrinterError) => {
              return throwError({
                status: 'ERROR',
                description: error.message,
                notification: error.notification,
              });
            }),
          );
        }),
      )
      .toPromise();
  }

  async printCustomCodeXml(command: Command) {
    return this.execute(command).toPromise();
  }

  async reprint(fiscalPrinterId: number, invoiceId: number): Promise<void> {
    return this.commandService
      .getReprintCommand(fiscalPrinterId, invoiceId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async reprintLastReceipt(fiscalPrinterId: number): Promise<void> {
    return this.commandService
      .getReprintLastReceiptCommand(fiscalPrinterId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async dailyReprint(fiscalPrinterId: number, date: string): Promise<void> {
    return this.commandService
      .getDailyReprintCommand(fiscalPrinterId, date)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  /**
   * @description COMMANDS EXECUTION FUNCTION
   */
  private execute(
    command: Command,
    disableLoading = false,
  ): Observable<RchFiscalPrinterStatusResponse> {
    if (!disableLoading) {
      this.loading.start();
    }

    return this.sendByHttpOrProxy(command).pipe(
      map((responses: any[]) => {
        // Success flow

        if (!disableLoading) {
          this.loading.stop();
        }

        const parsedResponses = responses.map(({ data }) =>
          txml.simplify(txml.parse(data)),
        );

        const original = responses
          .map(({ data }) => data)
          .join('<!-- END RESPONSE -->');

        const parsed: RchFiscalPrinterStatusResponse = last(parsedResponses);

        const errorResponse = parsedResponses.find(
          (parsedResponse) =>
            parsedResponse.Service.Request.printerError === '1' ||
            parsedResponse.Service.Request.errorCode !== '0',
        );

        const busyResponse = parsedResponses.find(
          (parsedResponse) => parsedResponse.Service.Request.busy !== '0',
        );

        // Extended Response Check
        if (!parsed.Service.Request.ECRStatus) {
          throw new FiscalPrinterError(original, getError('EC01'));
        }

        if (busyResponse) {
          throw new FiscalPrinterError(original, {
            code: 'BUSY',
            title: this.translate.instant('fiscal_printer_busy_error.title'),
            description: this.translate.instant(
              'fiscal_printer_busy_error.description',
            ),
          });
        }

        if (errorResponse) {
          throw new FiscalPrinterError(
            original,
            getError(errorResponse.Service.Request.errorCode),
          );
        }

        return { ...parsed, original };
      }),
      catchError((error: FiscalPrinterError) => {
        // Failure flow

        if (!disableLoading) {
          this.loading.stop();
        }

        if (!(error instanceof FiscalPrinterError)) {
          error = new FiscalPrinterError(JSON.stringify(error));
        }

        return throwError(error);
      }),
    );
  }

  private sendByHttpOrProxy(command: Command) {
    const { xml, ip, use_proxy } = command;

    if (use_proxy) {
      return this.proxy.send({
        requests: xml.map((xmlCommand) => {
          return {
            method: 'post',
            url: 'http://' + ip,
            payload: xmlCommand,
          };
        }),
      });
    }

    return observableSequence(
      xml.map((xmlCommand) => {
        return this.http
          .post('https://' + ip, xmlCommand, {
            responseType: 'text',
          })
          .pipe(
            map((xmlResponse) => {
              return { data: xmlResponse };
            }),
          );
      }),
    );
  }
}
