import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StripeCustomerService {
  constructor(private http: HttpClient) {}

  load(customerId: number, propertyId: number) {
    return this.http.get(
      `stripe/customers/${customerId}?property_id=${propertyId}`,
    );
  }
}
