import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class NewsletterCriteriasService {
  constructor(private http: HttpClient) {}

  load(searchParams: SearchParams) {
    return this.http.get(
      `newsletters/criteria?${generateSearchQuery(searchParams)}`,
    );
  }

  loadFeatures(criteriaID: number, searchParams: SearchParams) {
    return this.http.get(
      `newsletters/criteria/${criteriaID}/features?${generateSearchQuery(
        searchParams,
      )}`,
    );
  }
}
