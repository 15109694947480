import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ChildrenRange } from '../../models';

export const featureAdapter: EntityAdapter<ChildrenRange> = createEntityAdapter<
  ChildrenRange
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<ChildrenRange> {
  isLoading?: boolean;
  error?: any;
  maxGuests: any;
  propertiesChildrenRanges: { [property_id: number]: ChildrenRange[] };
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  maxGuests: null,
  propertiesChildrenRanges: null,
});
