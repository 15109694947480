<pdf-zoom-dropdown class="invisible"></pdf-zoom-dropdown>
<div class="by-attachment-viewer-zoom-toolbar">
  <button
    nz-button
    class="color-default by-mr-5 ant-btn-icon-only"
    nz-tooltip
    [nzTooltipTitle]="'zoom_out' | translate | upperfirst"
    nzTooltipPlacement="bottomLeft"
    (click)="onZoomOut()"
  >
    <i class="far fa-search-minus"></i>
  </button>
  <button
    nz-button
    class="color-default by-mr-5 ant-btn-icon-only"
    nz-tooltip
    [nzTooltipTitle]="'zoom_in' | translate | upperfirst"
    nzTooltipPlacement="bottomLeft"
    (click)="onZoomIn()"
  >
    <i class="far fa-search-plus"></i>
  </button>

  <nz-select
    class="by-attachment-viewer-tool-bar--desktop-item"
    [formControl]="zoomSelectControl"
    style="width: 200px"
  >
    <nz-option
      *ngIf="customZoomLevel"
      [nzValue]="customZoomLevel.value"
      [nzLabel]="customZoomLevel.label | translate | upperfirst"
    ></nz-option>
    <nz-option
      *ngFor="let level of zoomLevels"
      [nzValue]="level.value"
      [nzLabel]="level.label | translate | upperfirst"
    ></nz-option>
  </nz-select>
  <div></div>
</div>
