import { Injectable } from '@angular/core';
import { PlaceVatQuoteResponse } from '@app/models';
import { PlaceVatQuoteService } from '@app/services';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class PlaceVatQuoteStoreEffects {
  constructor(
    private placeVatQuoteService: PlaceVatQuoteService,
    private actions$: Actions,
    private _notifications: NotificationService,
    private _translate: TranslateService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        const { place_id, pagination } = action.payload;
        return this.placeVatQuoteService.load(place_id, pagination).pipe(
          map(
            ({ data }: PlaceVatQuoteResponse) =>
              new featureActions.LoadSuccessAction({
                quotes: data,
              }),
          ),
          catchError((error) => {
            this._notifications.push({
              title: this._translate.instant('error'),
              content: this._translate.instant('notifications.load_failure', {
                param: this._translate.instant('vat_quotes'),
              }),
              type: 'error',
            });
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
