import { Action } from '@ngrx/store';

import { INewChildrenRange } from '../../features/commons/children-range/models/new-children-range.model';
import { ChildrenRange } from '../../models';

export enum ActionTypes {
  LOAD_REQUEST = '[ChildrenRange] Load Request',
  LOAD_FAILURE = '[ChildrenRange] Load Failure',
  LOAD_SUCCESS = '[ChildrenRange] Load Success',

  LOAD_MAXGUESTS_REQUEST = '[ChildrenRange] Load Max Guests Request',
  LOAD_MAXGUESTS_FAILURE = '[ChildrenRange] Load Max Guests Failure',
  LOAD_MAXGUESTS_SUCCESS = '[ChildrenRange] Load Max Guests Success',

  CREATE_REQUEST = '[ChildrenRange]  Create Children Range Request',
  CREATE_SUCCESS = '[ChildrenRange] Create Children Range Success',
  CREATE_FAILURE = '[ChildrenRange] Create Children Range Failure',

  DELETE_REQUEST = '[ChildrenRange] Delete Children Range Request',
  DELETE_SUCCESS = '[ChildrenRange] Delete Children Range Success',
  DELETE_FAILURE = '[ChildrenRange] Create Children Range Failure',

  UPDATE_REQUEST = '[ChildrenRange] Update Children Range Request',
  UPDATE_SUCCESS = '[ChildrenRange] Update Children Range Success',
  UPDATE_FAILURE = '[ChildrenRange] Update Children Range Failure',

  RESET_STATE = '[ChildrenRange] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyIds: number[] }) {}
}
export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: ChildrenRange[] }) {}
}

export class LoadMaxGuestsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_MAXGUESTS_REQUEST;
  constructor(public payload: { propertyIds: number[] }) {}
}
export class LoadMaxGuestsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_MAXGUESTS_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadMaxGuestsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MAXGUESTS_SUCCESS;
  constructor(public payload: { items: ChildrenRange[] }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: {
      propertyId: number;
      childrenRanges: INewChildrenRange[];
      disableNotify?: boolean;
    },
  ) {}
}
export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { childrenRanges: ChildrenRange[] }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(
    public payload: {
      propertyId: number;
      childrenRangesIds: number[];
      disableNotify?: boolean;
    },
  ) {}
}
export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { ids: number[] }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      propertyId: number;
      childrenRange: INewChildrenRange[];
      disableNotify?: boolean;
      onSuccess?: () => void;
    },
  ) {}
}
export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { childrenRanges: ChildrenRange[] }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadMaxGuestsRequestAction
  | LoadMaxGuestsFailureAction
  | LoadMaxGuestsSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | ResetSuccessAction;
