import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { EmailValidator } from '../../../../core/validators/email.validators';
import { removeAllNullishValues } from '../../../../helpers';
import { TELEPHONE_MASK_PATTERN } from '../../../../helpers/mask-custom-patterns';
import {
  CompanyLookup,
  CustomersLookupOption,
  FormGetter,
  Language,
} from '../../../../models';

export interface Customer {
  name: string;
  surname: string;
  email: string;
  telephone: string;
  language_id: number;
  customer_id: number;
}

type OnChange = (customer: Customer) => void;

const REQUIRED_CONTROLS = ['name', 'surname'];

const CONTROLS_THAN_HAVING_CUSTOMER = ['email', 'telephone'];

@Component({
  selector: 'by-reservation-form-customer',
  templateUrl: './reservation-form-customer.component.html',
  styleUrls: ['./reservation-form-customer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReservationFormCustomerComponent),
      multi: true,
    },
  ],
})
export class ReservationFormCustomerComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, FormGetter
{
  @Input()
  languages: Language[];

  @Input()
  selectedCompany: CompanyLookup;

  @Input()
  isMobile = false;

  form = this.formBuilder.group({
    name: [null],
    surname: [null],
    email: [null, [EmailValidator.emailValidator]],
    telephone: [null],
    language_id: [1],
    customer_id: [null],
  });

  onTouched: () => void;

  private subs = new SubSink();

  private someControlsThanHavingCustomerFilled = false;

  readonly TELEPHONE_MASK_PATTERN = TELEPHONE_MASK_PATTERN;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.subscribeToControlsThanHavingCustomer();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { selectedCompany } = changes;

    if (selectedCompany) {
      this.setRequiredControlsValidators();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  writeValue(customer: Customer) {
    this.form.patchValue(customer, { emitEvent: false });
  }

  registerOnChange(onChange: OnChange) {
    this.subs.add(
      this.form.valueChanges.subscribe((formValue) => {
        onChange(removeAllNullishValues(formValue));
      }),
    );
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
      return;
    }

    this.form.enable();
  }

  onCustomerSelected(customerOption: CustomersLookupOption) {
    if (!customerOption) {
      return;
    }

    const { customer } = customerOption;

    this.form.patchValue({
      ...customer,
      ...customer.detail,
      customer_id: customer.id,
    });

    this.form.disable();
  }

  onRemoveSelectedCustomer() {
    this.form.reset({
      language_id: 1,
      customer_id: null,
    });
    this.form.enable();
  }

  getForms() {
    return [this.form];
  }

  get customerIsRequired() {
    return !this.selectedCompany || this.someControlsThanHavingCustomerFilled;
  }

  private setRequiredControlsValidators() {
    REQUIRED_CONTROLS.forEach((controlName) => {
      const control = this.form.get(controlName);

      control.clearValidators();

      if (this.customerIsRequired) {
        control.setValidators([Validators.required]);
      }

      control.updateValueAndValidity({ emitEvent: false });
    });
  }

  private subscribeToControlsThanHavingCustomer() {
    this.subs.add(
      combineLatest(
        CONTROLS_THAN_HAVING_CUSTOMER.map((controlName) => {
          const control = this.form.get(controlName);
          return control.valueChanges.pipe(startWith(control.value));
        }),
      ).subscribe((controlsValues) => {
        this.someControlsThanHavingCustomerFilled = controlsValues.some(
          (value) => !!value?.trim(),
        );

        this.setRequiredControlsValidators();
      }),
    );
  }

  get inputSize(): 'small' | 'default' {
    return this.isMobile ? 'default' : 'small';
  }
}
