import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProportionalDayPrice } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ProportionalDayPriceService {
  constructor(private http: HttpClient) {}

  calculate(data: ProportionalDayPrice) {
    return this.http.post(`general/proportional_day_price`, data);
  }
}
