import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';

import { PriceQuotationsStatsWidgetRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PriceQuotationsStatsService {
  constructor(private http: HttpClient) {}

  load(properties: number[]) {
    return this.http.get(
      `properties/quotes_stats?${generateParamArray(
        'property_id',
        properties,
      )}`,
    );
  }

  loadWidget(data: PriceQuotationsStatsWidgetRequest) {
    return this.http.get(
      `statistics/quote_conversion?${generateSearchQuery(data)}`,
    );
  }
}
