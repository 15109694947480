import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil, omitBy, snakeCase } from 'lodash';
import { Observable } from 'rxjs';

import { generateParamArray } from '../core/helpers/params-generator';
import { LoadStatisticsRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private http: HttpClient) {}

  load(request: LoadStatisticsRequest) {
    const {
      category,
      date_from,
      date_to,
      properties,
      date_reference,
      params,
    } = request;

    const otherParams = omitBy(params, (value) => isNil(value));
    let httpOptions: any = {};

    if (otherParams) {
      const httpParams: HttpParams = new HttpParams({
        fromObject: otherParams,
      });
      httpOptions = {
        ...httpOptions,
        params: httpParams,
      };
    }

    return this.http.get(
      `statistics/${snakeCase(
        category,
      )}/${date_from}/${date_to}?${generateParamArray(
        'property_id',
        properties,
      )}${date_reference ? `&date_reference=${date_reference}` : ''}`,
      httpOptions,
    ) as Observable<any>;
  }
}
