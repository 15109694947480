import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { observableSequence } from '../helpers';
import { NoteType, UserNote } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserNotesService {
  constructor(private http: HttpClient) {}

  load(userId: number, noteType: NoteType) {
    return this.http.get(`users/${userId}/notes?type=${noteType}`);
  }

  createOrUpdateOrDelete(notes: UserNote[]) {
    return observableSequence(
      notes.map((note) => {
        if (note?.toDelete) {
          return this.delete(note.userId, note.id);
        }
        return note.id ? this.update(note) : this.create(note);
      }),
    );
  }

  create(note: Omit<UserNote, 'id'>) {
    return this.http
      .post(`users/${note.userId}/notes?type=${note.type}`, note)
      .pipe(
        catchError((error) => {
          return of(error);
        }),
      ) as Observable<IResponseSuccess<UserNote[]>>;
  }

  update(note: UserNote) {
    return this.http.put(`users/${note.userId}/notes/${note.id}`, note).pipe(
      catchError((error) => {
        return of(error);
      }),
    ) as Observable<IResponseSuccess<UserNote[]>>;
  }

  delete(userId: number, noteId: number) {
    return this.http.delete(`users/${userId}/notes/${noteId}`).pipe(
      map((response: IResponseSuccess) => {
        response.data = {
          ...response.data,
          id: noteId,
          toDelete: true,
        };
        return response;
      }),
      catchError((error) => {
        return of(error);
      }),
    );
  }
}
