import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { TokenService } from '../core/services/token.service';

import { PaymentsService } from './payments.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerPaymentsService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private paymentsService: PaymentsService,
  ) {}

  load(customerId: number, properties: number[], page: number) {
    return this.http.get(
      `customer/${customerId}/payments?page=${page}&${generateParamArray(
        'property_id',
        properties,
      )}`,
    );
  }

  delete(reservationId: number, paymentId: number) {
    return this.paymentsService.delete(reservationId, paymentId);
  }
}
