<div
  *nzModalTitle
  class="
    by-flex
    by-flex-middle
    by-tableau-actions-modal-title
    by-tableau-actions-modal-title--bordered
  "
>
  <i class="by-mr-10 fas fa-paperclip"></i>
  <strong class="by-fs-16 by-mr-5">
    {{ params.row.data.label }}
  </strong>
  <span class="by-fs-11"
    >[{{ params.row.data.accommodation_details.name }}]</span
  >
</div>

<form
  [formGroup]="form"
  nz-form
  nzLayout="vertical"
  class="by-tableau-note-form"
>
  <by-reservation-form-dates
    class="by-tableau-note-form__dates"
    formControlName="dates"
    [showNights]="false"
    [dateFromLabel]="'date_from' | translate | upperfirst"
    [dateToLabel]="'date_to' | translate | upperfirst"
  ></by-reservation-form-dates>

  <div class="by-tableau-note-form__content">
    <nz-form-item>
      <nz-form-label class="required-input-red">
        {{ 'title' | translate | upperfirst }}
      </nz-form-label>

      <nz-form-control
        [nzErrorTip]="
          'validation_form.' + (form.controls.title.errors | keys | head)
            | translate: { param: 'title' | translate }
            | upperfirst
        "
      >
        <input
          type="text"
          nz-input
          [placeholder]="
            'placeholder.insert'
              | translate: { param: 'title' | translate }
              | upperfirst
          "
          autocomplete="off"
          formControlName="title"
        />
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<div *nzModalFooter>
  <button *ngIf="!note" nz-button (click)="onCancel()">
    <i class="by-mr-2 fas fa-undo"></i>
    {{ 'cancel' | translate | upperfirst }}
  </button>

  <button
    *ngIf="note"
    nz-button
    nzType="primary"
    nzDanger
    [nzLoading]="deleting"
    nz-popconfirm
    [nzPopconfirmTitle]="
      'actions.delete' | translate: { param: 'note' | translate }
    "
    (nzOnConfirm)="onDelete()"
  >
    <i class="by-mr-2 fas fa-trash-alt"></i>
    {{ 'delete' | translate | upperfirst }}
  </button>

  <button nz-button nzType="primary" [nzLoading]="saving" (click)="onSave()">
    {{ 'save' | translate | upperfirst }}
  </button>
</div>
