<by-spin [nzSpinning]="checkResultsLoading">
  <div nz-row *ngIf="usePrintedReceipt" class="by-mb-2">
    <nz-alert
      class="by-w-100"
      *ngIf="showPrintingSessionsMessage"
      nzType="warning"
      nzShowIcon
      [nzDescription]="descriptionPrintSession"
      [nzMessage]="'printing_sessions_pending' | translate | upperfirst"
    ></nz-alert>

    <ng-template #descriptionPrintSession>
      {{
        'printing_sessions_pending_description_modal' | translate | upperfirst
      }}
    </ng-template>
  </div>

  <by-question
    *ngIf="!selectedPrinterDriver?.disableEmitPrintedReceipt"
    [labelTemplate]="questionLabel"
    [(ngModel)]="usePrintedReceipt"
    (ngModelChange)="onUsePrintedReceiptChange()"
  ></by-question>

  <ng-template #questionLabel>
    <span>{{
      'register_emitted_fiscal_receipt' | translate | upperfirst
    }}</span>
    <i
      class="by-ml-5 fas fa-info-circle pointer color--blue"
      nz-tooltip
      [nzTooltipTitle]="
        'register_emitted_fiscal_receipt_description' | translate | upperfirst
      "
    ></i>
  </ng-template>

  <div [formGroup]="form" nz-form nzLayout="vertical">
    <nz-form-item *ngIf="propertiesIds?.length > 1">
      <nz-form-label>
        {{ 'property' | translate | capitalize }}
      </nz-form-label>
      <nz-form-control>
        <nz-select
          class="by-w-100"
          formControlName="property_id"
          [nzPlaceHolder]="'property' | translate"
        >
          <nz-option
            *ngFor="let propertyId of propertiesIds"
            [nzLabel]="propertiesNames$ | async | get: propertyId"
            [nzValue]="propertyId"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>
        {{ 'select_fiscal_printer' | translate | capitalize }}
      </nz-form-label>

      <nz-form-control>
        <nz-select
          formControlName="printer_id"
          nzPlaceHolder="{{
            'placeholder.select'
              | translate: { param: 'associated_fiscal_printers' | translate }
          }}"
          class="by-w-100"
        >
          <nz-option
            *ngFor="let fiscalPrinter of fiscalPrinters"
            [nzValue]="fiscalPrinter.id"
            [nzLabel]="fiscalPrinter.name"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <ng-container *ngIf="isReversalReceipt || usePrintedReceipt">
      <ng-container *ngIf="usePrintedReceipt">
        <nz-form-item>
          <nz-form-label>
            {{ 'receipt_number' | translate | capitalize }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="number" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label>
            {{ 'receipt_date' | translate | capitalize }}
          </nz-form-label>

          <nz-form-control>
            <nz-date-picker
              byDisabledMobileKeyboard
              formControlName="invoice_date"
              class="by-w-100"
              [ngStyle]="{ width: '100%' }"
              nzFormat="dd/MM/yyyy"
              [nzAllowClear]="false"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <nz-form-item *ngIf="!selectedPrinterDriver?.disableClosureNumber">
        <nz-form-label>
          {{ 'clousure_number' | translate | capitalize }}
        </nz-form-label>

        <nz-form-control>
          <nz-input-number
            class="by-w-100"
            [nzStep]="1"
            [nzMin]="0"
            byDecimalOnly
            formControlName="clousure_number"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </ng-container>
  </div>

  <by-fiscal-printer-check
    *ngIf="!usePrintedReceipt"
    [selectedPrinter]="selectedPrinter"
  ></by-fiscal-printer-check>
</by-spin>

<div *nzModalFooter>
  <button nz-button (click)="onCloseModal()">
    {{ 'close' | translate | upperfirst }}
  </button>

  <button
    nz-button
    nzType="primary"
    (click)="onSubmit()"
    [disabled]="submitIsDisabled"
  >
    {{ (usePrintedReceipt ? 'register' : 'print') | translate | upperfirst }}
  </button>
</div>
