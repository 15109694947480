import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateExtraEmailRequest, ExtraEmail } from '../models';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({ providedIn: 'root' })
export class ExtraEmailService {
  constructor(private http: HttpClient) {}

  createExtraEmail(request: CreateExtraEmailRequest) {
    return this.http.post<IResponseSuccess<ExtraEmail>>(
      'properties/extra-emails',
      request,
    );
  }
}
