import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  FloorRequest,
  OrderFloorRequest,
  OrderFloorTableauNumbersRequest,
  SyncFloorRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class FloorsService {
  constructor(private http: HttpClient) {}

  load(propertiesIds: number[]) {
    return this.http.get<IResponseSuccess>(
      `properties/floors?order=order:ASC&${generateParamArray(
        'property_id',
        propertiesIds,
      )}`,
    );
  }

  create(data: FloorRequest) {
    return this.http.post<IResponseSuccess>(
      `property/${data.property_id}/floor`,
      data,
    );
  }

  update(data: FloorRequest) {
    return this.http.put<IResponseSuccess>(
      `property/${data.property_id}/floor/${data.property_floor_id}`,
      data,
    );
  }

  order(data: OrderFloorRequest) {
    return this.http.post(`property/${data.property_id}/floors/order`, data);
  }

  orderTableauNumbers(data: OrderFloorTableauNumbersRequest) {
    return this.http.post(
      `property/${data.property_id}/floor/${data.property_floor_id}/order`,
      data,
    );
  }

  sync(data: SyncFloorRequest) {
    return this.http.post(`property/${data.property_id}/floor_sync`, data);
  }

  delete(propertyId: number, floorId: number) {
    return this.http.delete(`property/${propertyId}/floor/${floorId}`);
  }
}
