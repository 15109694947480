import { environment } from '../../environments/environment';
import { GuidePage } from '../models';

export const GUIDE_SECTIONS: { [section: string]: GuidePage[] } = {
  dashboard: [
    {
      label: 'dashboard',
      link: environment.guideUrl + 'bacheca/',
      icon: null,
    },
  ],
  reservationsList: [
    {
      label: 'reservations_list',
      link: environment.guideUrl + 'prenotazioni/lista-prenotazioni/',
      icon: null,
    },
  ],
  reservationDetails: [
    {
      label: 'title_detail_reservation_modal',
      link: environment.guideUrl + 'prenotazioni/dettaglio-prenotazione/',
      icon: null,
    },
  ],
  priceQuotations: [
    {
      label: 'priceQuotations',
      link: environment.guideUrl + 'prenotazioni/preventivi/',
      icon: null,
    },
  ],
  quoteRequests: [
    {
      label: 'quoteRequests',
      link: environment.guideUrl + 'prenotazioni/richieste-di-preventivo/',
      icon: null,
    },
  ],
  customersCompany: [
    {
      label: 'customers_company',
      link:
        environment.guideUrl + 'prenotazioni/anagrafiche-clienti-e-aziende/',
      icon: null,
    },
  ],
  dailyMeals: [
    {
      label: 'daily_meals',
      link: environment.guideUrl + 'prenotazioni/piano-pasti/',
      icon: null,
    },
  ],
  invoices: [
    {
      label: 'invoices',
      link: environment.guideUrl + 'documenti/fatture/',
      icon: null,
    },
  ],
  receipts: [
    {
      label: 'receipts',
      link: environment.guideUrl + 'documenti/ricevute/',
      icon: null,
    },
  ],
  openChargesPass: [
    {
      label: 'open_charges_pass',
      link: environment.guideUrl + 'documenti/conti-aperti-e-passanti/',
      icon: null,
    },
  ],
  istatGuestCards: [
    {
      label: 'istat_guest_cards',
      link: environment.guideUrl + 'documenti/schedine-istat-e-alloggiati/',
      icon: null,
    },
  ],
  cashDesk: [
    {
      label: 'cash_desk',
      link: environment.guideUrl + 'contabilita/cassa/',
      icon: null,
    },
  ],
  cashDeskClosing: [
    {
      label: 'cash_closing',
      link: environment.guideUrl + 'contabilita/chiusura-cassa/',
      icon: null,
    },
  ],
  dailyClosure: [
    {
      label: 'daily_closure',
      link: environment.guideUrl + 'contabilita/chiusura-giornaliera/',
      icon: null,
    },
  ],
  istatConfiguration: [
    {
      label: 'istat',
      link: environment.guideUrl + 'configurazione/istat/',
      icon: null,
    },
  ],
  suspended: [
    {
      label: 'open_invoices',
      link: environment.guideUrl + 'contabilita/sospesi/',
      icon: null,
    },
  ],
  fees: [
    {
      label: 'fees',
      link: environment.guideUrl + 'contabilita/corrispettivi/',
      icon: null,
    },
  ],
  deposits: [
    {
      label: 'accounts_deposits',
      link: environment.guideUrl + 'contabilita/acconti-e-caparre/',
      icon: null,
    },
  ],
  cityTaxSummary: [
    {
      label: 'city_tax',
      link: environment.guideUrl + 'contabilita/tassa-di-soggiorno/',
      icon: null,
    },
  ],
  dailyOverview: [
    {
      label: 'daily_overview',
      link:
        environment.guideUrl +
        'tariffe-e-disponibilita/panoramica-giornaliera/',
      icon: null,
    },
  ],
  bulkUpdate: [
    {
      label: 'bulk_update',
      link:
        environment.guideUrl + 'tariffe-e-disponibilita/aggiornamento-bulk/',
      icon: null,
    },
  ],
  ratesAndOffers: [
    {
      label: 'rates_and_offers',
      link: environment.guideUrl + 'tariffe-e-disponibilita/tariffe-e-offerte/',
      icon: null,
    },
  ],
  accommodationRulesPriceLists: [
    {
      label: 'accommodation_rules_price_lists',
      link:
        environment.guideUrl +
        'tariffe-e-disponibilita/listini-regole-alloggi/',
      icon: null,
    },
  ],
  housekeeper: [
    {
      label: 'housekeeper',
      link: environment.guideUrl + 'governante/',
      icon: null,
    },
  ],
  stats: [
    {
      label: 'stats',
      link: environment.guideUrl + 'statistiche/analisi-revenue/',
      icon: null,
    },
  ],
  property: [
    {
      label: 'property',
      link: environment.guideUrl + 'configurazione/struttura/',
      icon: null,
    },
  ],
  accommodations: [
    {
      label: 'accommodations',
      link: environment.guideUrl + 'configurazione/alloggi/',
      icon: null,
    },
  ],
  treatments: [
    {
      label: 'treatments',
      link: environment.guideUrl + 'configurazione/trattamenti/',
      icon: null,
    },
  ],
  addons: [
    {
      label: 'addons',
      link: environment.guideUrl + 'configurazione/servizi-extra/',
      icon: null,
    },
  ],
  operatingParams: [
    {
      label: 'operating_params',
      link: environment.guideUrl + 'configurazione/parametri-di-funzionamento/',
      icon: null,
    },
  ],
  emailTemplatesSms: [
    {
      label: 'email_templates_messages',
      link: environment.guideUrl + 'configurazione/modelli-e-mail-messaggi/',
      icon: null,
    },
  ],
  paymentMethods: [
    {
      label: 'payment_methods',
      link: environment.guideUrl + 'configurazione/metodi-di-pagamento/',
      icon: null,
    },
  ],
  cancellationPolicies: [
    {
      label: 'cancellation_policies',
      link:
        environment.guideUrl + 'configurazione/condizioni-di-cancellazione/',
      icon: null,
    },
  ],
  events: [
    {
      label: 'events',
      link: environment.guideUrl + 'configurazione/eventi/',
      icon: null,
    },
  ],

  cutoff: [
    {
      label: 'cutoff',
      link: environment.guideUrl + 'configurazione/cutoff/',
      icon: null,
    },
  ],
  invoicing: [
    {
      label: 'billing',
      link: environment.guideUrl + 'configurazione/fatturazione/',
      icon: null,
    },
  ],

  bookingEngine: [
    {
      label: 'booking_engine',
      link: environment.guideUrl + 'configurazione/booking-engine/',
      icon: null,
    },
  ],
  channelManager: [
    {
      label: 'integrations',
      link: environment.guideUrl + 'configurazione/integrazioni/',
      icon: null,
    },
  ],
  notifications: [
    {
      label: 'notifications_title',
      link: environment.guideUrl + 'configurazione/notifiche/',
      icon: null,
    },
  ],
  cityTax: [
    {
      label: 'city_tax',
      link: environment.guideUrl + 'configurazione/tassa-di-soggiorno/',
      icon: null,
    },
  ],

  housedWeb: [
    {
      label: 'housed_web',
      link: environment.guideUrl + 'configurazione/alloggiati-web/',
      icon: null,
    },
  ],

  usersRoles: [
    {
      label: 'users_roles',
      link: environment.guideUrl + 'configurazione/ruoli-e-utenti/',
      icon: null,
    },
  ],

  gdpr: [
    {
      label: 'gdpr',
      link: environment.guideUrl + 'configurazione/gdpr/',
      icon: null,
    },
  ],
  scaConfirmation: [
    {
      label: 'sca_confirmation_module_configuration',
      link: environment.guideUrl + 'configurazione/configurazione-sca/',
      icon: null,
    },
  ],

  tableau: [
    {
      label: 'tableau',
      link: environment.guideUrl + 'tableau/',
      icon: null,
    },
  ],

  newReservetion: [
    {
      label: 'new_reservation',
      link:
        environment.guideUrl +
        'prenotazioni/lista-prenotazioni/#inserire-una-prenotazione-individuale-o-di-gruppo',
      icon: null,
    },
  ],

  newPriceQuotation: [
    {
      label: 'new_estimate',
      link:
        environment.guideUrl + 'prenotazioni/preventivi/#creare-un-preventivo',
      icon: null,
    },
  ],

  newCustomer: [
    {
      label: 'customer_new',
      link:
        environment.guideUrl +
        'prenotazioni/anagrafiche-clienti-e-aziende/#visualizzazione-generale',
      icon: null,
    },
  ],

  newCompany: [
    {
      label: 'new_company',
      link:
        environment.guideUrl +
        'prenotazioni/anagrafiche-clienti-e-aziende/#visualizzazione-generale',
      icon: null,
    },
  ],

  newOfferRate: [
    {
      label: 'new_offer_rate',
      link:
        environment.guideUrl +
        'tariffe-e-disponibilita/tariffe-e-offerte/#nuovo-piano-tariffario-o-offerta',
      icon: null,
    },
  ],

  newBundle: [
    {
      label: 'new_bundle',
      link:
        environment.guideUrl +
        'tariffe-e-disponibilita/tariffe-e-offerte/#nuovo-pacchetto',
      icon: null,
    },
  ],

  newCoupon: [
    {
      label: 'new_coupon',
      link:
        environment.guideUrl +
        'tariffe-e-disponibilita/tariffe-e-offerte/#nuovo-coupon',
      icon: null,
    },
  ],

  houseKeeperSettings: [
    {
      label: 'housekeeper_settings',
      link: environment.guideUrl + 'governante/#impostazioni-governante',
      icon: null,
    },
  ],

  newAccomodation: [
    {
      label: 'new_accommodation',
      link:
        environment.guideUrl + 'configurazione/alloggi/#creare-nuovo-alloggio',
      icon: null,
    },
  ],

  newTreatment: [
    {
      label: 'new_treatment',
      link:
        environment.guideUrl +
        'configurazione/trattamenti/#lista-e-creazione-trattamenti',
      icon: null,
    },
  ],

  newExtraService: [
    {
      label: 'new_extra_services',
      link:
        environment.guideUrl +
        'configurazione/servizi-extra/#gestire-e-inserire-servizi-extra',
      icon: null,
    },
  ],

  newPolicy: [
    {
      label: 'policy',
      link:
        environment.guideUrl +
        'configurazione/condizioni-di-cancellazione/#inserire-una-nuova-policy-di-cancellazione',
      icon: null,
    },
  ],

  newInvoiceProfile: [
    {
      label: 'new_invoice_layout',
      link:
        environment.guideUrl +
        'configurazione/fatturazione/#creare-nuovo-profilo-di-fatturazione',
      icon: null,
    },
  ],

  newsletterAll: [
    {
      label: 'newsletter_campaigns',
      link: environment.guideUrl + 'newsletter/campagne-email/#campagne-email',
      icon: null,
    },
  ],

  newsletterNew: [
    {
      label: 'new_campaign',
      link:
        environment.guideUrl +
        'newsletter/campagne-email/#creare-una-nuova-campagna',
      icon: null,
    },
  ],

  newsletterMailingList: [
    {
      label: 'newsletter_mailing_lists',
      link: environment.guideUrl + 'newsletter/liste-di-invio/#liste-di-invio',
      icon: null,
    },
  ],

  newsletterConfig: [
    {
      label: 'newsletter_configuration',
      link:
        environment.guideUrl +
        'newsletter/configurazione/#configurazione-newsletter',
      icon: null,
    },
  ],
};
