import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WelcomeAutomaticEmailsTemplateService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get(`general/welcome_automatic_emails`);
  }
}
