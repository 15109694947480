import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ChannelsService } from '../../services';
import { ProportionalDayPriceService } from '../../services/proportional-day-price.service';

import * as fromActions from './actions';

@Injectable()
export class ProportionalDayPriceStoreEffects {
  constructor(
    private dataService: ProportionalDayPriceService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  calculate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.calculateRequest),
      switchMap(({ data }) =>
        this.dataService.calculate(data).pipe(
          map((response: any) =>
            fromActions.calculateSuccess({
              data: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.calculateFailure(error));
          }),
        ),
      ),
    ),
  );
}
