import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetService {
  constructor(private http: HttpClient) {}

  createPasswordReset(email: string) {
    return this.http.get(`user/passwordreset?email=${email}`);
  }

  checkToken(token: string, email: string) {
    return this.http.post(`user/passwordreset/checkreminder`, {
      reminder: token,
      token,
      email,
    });
  }

  confirmPasswordReset(token: string, password: string, email: string) {
    return this.http.post('user/passwordreset/complete', {
      reminder: token,
      token,
      password,
      email,
    });
  }
}
