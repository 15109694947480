import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PaymentMethodsService } from '../../services';

import * as featureActions from './actions';

@Injectable()
export class PaymentMethodsStoreEffects {
  constructor(
    private dataService: PaymentMethodsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.LOAD_REQUEST),
      switchMap((action: featureActions.LoadRequestAction) => {
        return this.dataService.load(action.payload.propertyId).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadPropertyPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        featureActions.ActionTypes.LOAD_PROPERTIES_PAYMENT_METHODS_REQUEST,
      ),
      switchMap(
        (action: featureActions.LoadPropertiesPaymentMethodsRequestAction) => {
          return this.dataService
            .loadPropertiesPaymentMethods(action.payload.propertiesIds)
            .pipe(
              map(
                ({ data }: any) =>
                  new featureActions.LoadPropertiesPaymentMethodsSuccessAction({
                    items: data,
                  }),
              ),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(
                  new featureActions.LoadPropertiesPaymentMethodsFailureAction({
                    error,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );
}
