import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacilitiesType } from '@app/models';
import { CreateAccommodationFacilitiesRequest } from '@app/models/requests/create-accommodation-facilities-request';
import { CreatePropertyFacilitiesRequest } from '@app/models/requests/create-property-facilities-request';

@Injectable({
  providedIn: 'root',
})
export class FacilitiesService {
  constructor(private http: HttpClient) {}

  loadFacilitiesGeneral(type: FacilitiesType) {
    return this.http.get(`general/facilities?type=${type}`);
  }

  loadFacilityCatefgories() {
    return this.http.get(`general/facilities/categories`);
  }

  loadFacilitiesFromResource(resourceId: number, type: FacilitiesType) {
    return this.http.get(`${type}/${resourceId}/facilities`);
  }

  createForAccommodation(
    accommodation_id: number,
    data: CreateAccommodationFacilitiesRequest,
  ) {
    return this.http.post(`accommodation/${accommodation_id}/facilities`, data);
  }

  createForProperty(data: CreatePropertyFacilitiesRequest) {
    return this.http.post(`properties/facilities`, data);
  }
}
