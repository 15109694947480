export const stringToColor = (str: string) => {
  const strToHash = str + 'adgks';

  let hash = 0;

  for (let i = 0; i < strToHash.length; i++) {
    hash = strToHash.charCodeAt(i) + ((hash << 5) - hash);
  }

  let colour = '#';

  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }

  return colour;
};
