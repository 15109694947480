import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '@app/core/helpers/params-generator';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  InvoiceReceiptExportFileRequest,
  UploadInvoiceReceiptXmlResponse,
} from '../models';

import { SearchParams } from '../models/objects/search-params';
import { ExportFileRequestSuccess } from '../models/types/export-file-request';
import { removeAllNullishValues } from '../helpers';
import { omit } from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class InvoicesReceiptsService {
  constructor(private http: HttpClient) {}

  load(vat_code: string, params: SearchParams) {
    return this.http.get(
      `invoices/received/${vat_code}?${generateSearchQuery(params)}`,
    );
  }

  loadDetail(invoice_id: number): Observable<any> {
    return this.http
      .get(`invoices/received/${invoice_id}/show`, {
        responseType: 'text',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          error = { ...error, error: JSON.parse(error.error) };
          return throwError(error);
        }),
      );
  }

  uploadInvoiceReceiptXmlFiles(files: NzUploadFile[]) {
    const formData = this.createFormData(files);

    return this.http.post(
      'invoices/received/import_xml',
      formData,
    ) as Observable<IResponseSuccess<UploadInvoiceReceiptXmlResponse[]>>;
  }

  createFormData(files: NzUploadFile[]): FormData {
    const formData = new FormData();
    files.forEach((file: any, index) => {
      formData.append(`xml_files[${index}]`, file);
    });
    return formData;
  }

  exportFile(data: InvoiceReceiptExportFileRequest) {
    const { vat_code } = data || {};

    return this.http.get<IResponseSuccess<ExportFileRequestSuccess>>(
      `invoices/received/${vat_code}?${generateSearchQuery(removeAllNullishValues(omit(data, 'vat_code')))}`,
    );
  }
}
