<nz-spin
  [nzSize]="nzSize"
  [nzDelay]="nzDelay"
  [nzSpinning]="nzSpinning"
  [nzIndicator]="prloaderTemplate"
  [nzSimple]="nzSimple"
  [nzTip]="nzTip"
>
  <ng-content></ng-content>
</nz-spin>

<ng-template #prloaderTemplate>
  <div [style.--background]="nzColor" class="preloader {{ nzSize }}">
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
  </div>
</ng-template>
