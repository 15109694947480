import { FiscalPrinterStatus } from '../fiscal-printer-status';

import { RhinoFiscalPrinterStatusResponse } from './rhino-fiscal-printer-response';

export class RhinoFiscalPrinterStatusParser {
  parse({ status }: RhinoFiscalPrinterStatusResponse): FiscalPrinterStatus {
    return {
      result: status,
      printerStatus: status ? 'ok' : 'offline',
      description: 'check_credentials',
    };
  }
}
