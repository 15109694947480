import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';

import { IAddonRequestRoot } from './../models/objects/addons';

@Injectable({
  providedIn: 'root',
})
export class AddonsService {
  constructor(private http: HttpClient) {}

  loadAccommodationAddons(accommodationId: number) {
    return this.http.get(
      `accommodations/addons?${generateParamArray('accommodation_id', [
        accommodationId,
      ])}`,
    );
  }

  load(propertiesIds: number[]) {
    return this.http.get(
      `properties/addons?${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  loadAddonsInvoice(invoiceDestinationId: number) {
    return this.http.get(`invoices/${invoiceDestinationId}/unrefunded`);
  }

  create(data: IAddonRequestRoot) {
    return this.http.post(`property/${data.propertiesIds[0]}/addons`, data);
  }

  delete(addonId: number) {
    return this.http.delete(`addon/${addonId}`);
  }

  update(data: IAddonRequestRoot) {
    return this.http.put(
      `property/${data.propertiesIds[0]}/addons/${data.id}`,
      data,
    );
  }

  loadAddonsByPropertyCategory(
    propertyIds: number[],
    withTrashed = false,
    withManual = false,
  ) {
    return this.http.get(
      `properties/categories/addons?${generateParamArray(
        'property_id',
        propertyIds,
      )}&with_trashed=${withTrashed}&with_manual_addon=${withManual}`,
    );
  }
}
