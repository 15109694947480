import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class ReservationsActiveService {
  constructor(private http: HttpClient) {}

  load(propertyIds: number[], now: string) {
    return this.http.get(
      `reservations/active?${generateParamArray(
        'property_id',
        propertyIds,
      )}&now=${now}`,
    );
  }
}
