export const SDI_IBAN_REGEX = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{11,30}/;

const SDI_CHARACTERS_NOT_ALLOWED_STRING =
  '€©®ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþ';

export const SDI_CHARACTERS_NOT_ALLOWED =
  SDI_CHARACTERS_NOT_ALLOWED_STRING.split('').join(', ');

export const SDI_REGEX = new RegExp(
  `^(?:(?![${SDI_CHARACTERS_NOT_ALLOWED_STRING}]).)*$`,
  `s`,
);
