import { IMedia } from '@app/features/commons/accommodation-details/models/media.model';
import { IInvoiceLayout } from '@app/models/objects/invoices-layouts';
import { Action } from '@ngrx/store';

import {
  IInvoiceLayoutCreateUpdate,
  ITaxSystem,
} from './../../models/objects/invoices-layouts';

export enum ActionTypes {
  LOAD_REQUEST = '[Invoices Layouts] Load Request',
  LOAD_FAILURE = '[Invoices Layouts] Load Failure',
  LOAD_SUCCESS = '[Invoices Layouts] Load Success',

  LOAD_ALL_REQUEST = '[Invoices Layouts] Load All Request',
  LOAD_ALL_FAILURE = '[Invoices Layouts] Load All Failure',
  LOAD_ALL_SUCCESS = '[Invoices Layouts] Load All Success',

  CREATE_REQUEST = '[Invoices Layouts] Create Request',
  CREATE_FAILURE = '[Invoices Layouts] Create Failure',
  CREATE_SUCCESS = '[Invoices Layouts] Create Success',

  DELETE_REQUEST = '[Invoices Layouts] Delete Request',
  DELETE_FAILURE = '[Invoices Layouts] Delete Failure',
  DELETE_SUCCESS = '[Invoices Layouts] Delete Success',

  UPDATE_REQUEST = '[Invoices Layouts] Update Request',
  UPDATE_FAILURE = '[Invoices Layouts] Update Failure',
  UPDATE_WARNING = '[Invoices Layouts] Update Warning',
  UPDATE_SUCCESS = '[Invoices Layouts] Update Success',

  ATTACH_PROPERTY_REQUEST = '[Invoice Layout] Attach Property Request',
  ATTACH_PROPERTY_SUCCESS = '[Invoice Layout] Attach Property Success',
  ATTACH_PROPERTY_FAILURE = '[Invoice Layout] Attach Property Failure',

  DETACH_PROPERTY_REQUEST = '[Invoice Layout] Detach Property Request',
  DETACH_PROPERTY_SUCCESS = '[Invoice Layout] Detach Property Success',
  DETACH_PROPERTY_FAILURE = '[Invoice Layout] Detach Property Failure',

  LOAD_DETAILS_REQUEST = '[Invoice Layout] Load Details Request',
  LOAD_DETAILS_SUCCESS = '[Invoice Layout] Load Details Success',
  LOAD_DETAILS_FAILURE = '[Invoice Layout] Load Details Failure',

  ADD_SECTIONAL_REQUEST = '[Invoice Layout] Load Sectional Request',
  ADD_SECTIONAL_SUCCESS = '[Invoice Layout] Load Sectional Success',
  ADD_SECTIONAL_FAILURE = '[Invoice Layout] Load Sectional Failure',

  LOAD_TAX_SYSTEM_REQUEST = '[Invoice Layout] Load Tax System Request',
  LOAD_TAX_SYSTEM_SUCCESS = '[Invoice Layout] Load Tax System Success',
  LOAD_TAX_SYSTEM_FAILURE = '[Invoice Layout] Load Tax System Failure',

  DELETE_SECTIONAL_REQUEST = '[Inoivce Layout] Delete Sectional Request',
  DELETE_SECTIONAL_SUCCESS = '[Inoivce Layout] Delete Sectional Success',
  DELETE_SECTIONAL_FAILURE = '[Inoivce Layout] Delete Sectional Failure',

  SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_REQUEST = '[Inoivce Layout] Set Default Property Invoice Layout Request',
  SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_SUCCESS = '[Inoivce Layout] Set Default Property Invoice Layout Success',
  SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_FAILURE = '[Inoivce Layout] Set Default Property Invoice Layout Failure',

  RESET_STATE = '[Inoivce Layout] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(
    public payload?: {
      invoice_module?: boolean;
      propertiesIds?: number[];
      fp_module?: boolean;
    },
  ) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(
    public payload: { items: IInvoiceLayout[]; propertiesIds?: number[] },
  ) {}
}

export class LoadAllRequestAction implements Action {
  readonly type = ActionTypes.LOAD_ALL_REQUEST;
}
export class LoadAllFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ALL_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class LoadAllSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ALL_SUCCESS;
  constructor(public payload: { items: IInvoiceLayout[] }) {}
}

export class LoadTaxSystemRequestAction implements Action {
  readonly type = ActionTypes.LOAD_TAX_SYSTEM_REQUEST;
}

export class LoadTaxSystemFailureAction implements Action {
  readonly type = ActionTypes.LOAD_TAX_SYSTEM_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadTaxSystemSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_TAX_SYSTEM_SUCCESS;
  constructor(public payload: { items: ITaxSystem[] }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(public payload: { request: IInvoiceLayoutCreateUpdate }) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { item: IInvoiceLayout; sectional?: string[] }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { invoice_layout_id: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { invoice_layout_id: number }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      request: IInvoiceLayoutCreateUpdate;
      invoiceLayoutId: number;
      sectional?: string[];
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class UpdateWarningAction implements Action {
  readonly type = ActionTypes.UPDATE_WARNING;
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { item: IInvoiceLayout }) {}
}

export class AttachPropertyRequestAction implements Action {
  readonly type = ActionTypes.ATTACH_PROPERTY_REQUEST;
  constructor(
    public payload: {
      invoiceLayoutId: number;
      properties: number[];
      fileList?: any;
    },
  ) {}
}

export class AttachPropertyFailureAction implements Action {
  readonly type = ActionTypes.ATTACH_PROPERTY_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class AttachPropertySuccessAction implements Action {
  readonly type = ActionTypes.ATTACH_PROPERTY_SUCCESS;
}

export class DetachPropertyRequestAction implements Action {
  readonly type = ActionTypes.DETACH_PROPERTY_REQUEST;
  constructor(
    public payload: { invoiceLayoutId: number; properties: number[] },
  ) {}
}

export class DetachPropertyFailureAction implements Action {
  readonly type = ActionTypes.DETACH_PROPERTY_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class DetachPropertySuccessAction implements Action {
  readonly type = ActionTypes.DETACH_PROPERTY_SUCCESS;
}

export class LoadDetailsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_REQUEST;
  constructor(public payload: { invoiceLayoutId: number }) {}
}

export class LoadDetailsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class LoadDetailsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_SUCCESS;
  constructor(public payload: { invoiceLayout: IInvoiceLayout }) {}
}

export class AddSectionalRequestAction implements Action {
  readonly type = ActionTypes.ADD_SECTIONAL_REQUEST;
  constructor(
    public payload: {
      invoiceLayoutId: number;
      data: string[];
      refresh?: boolean;
    },
  ) {}
}

export class AddSectionalFailureAction implements Action {
  readonly type = ActionTypes.ADD_SECTIONAL_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class AddSectionalSuccessAction implements Action {
  readonly type = ActionTypes.ADD_SECTIONAL_SUCCESS;
}

export class DeleteSectionalRequestAction implements Action {
  readonly type = ActionTypes.DELETE_SECTIONAL_REQUEST;
  constructor(
    public payload: { invoiceLayoutId: number; sectionalId: number },
  ) {}
}

export class DeleteSectionalFailureAction implements Action {
  readonly type = ActionTypes.DELETE_SECTIONAL_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class DeleteSectionalSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SECTIONAL_SUCCESS;
}

export class SetDefaultPropertyDefaultLayoutRequestAction implements Action {
  readonly type = ActionTypes.SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_REQUEST;
  constructor(
    public payload: { property_id: number[]; invoice_layout_id: number },
  ) {}
}

export class SetDefaultPropertyDefaultLayoutFailureAction implements Action {
  readonly type = ActionTypes.SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class SetDefaultPropertyDefaultLayoutSuccessAction implements Action {
  readonly type = ActionTypes.SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_SUCCESS;
  constructor(
    public payload: { property_id: number[]; invoice_layout_id: number },
  ) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadAllRequestAction
  | LoadAllFailureAction
  | LoadAllSuccessAction
  | LoadTaxSystemRequestAction
  | LoadTaxSystemFailureAction
  | LoadTaxSystemSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | UpdateWarningAction
  | AttachPropertyRequestAction
  | AttachPropertySuccessAction
  | AttachPropertyFailureAction
  | LoadDetailsRequestAction
  | LoadDetailsSuccessAction
  | LoadDetailsFailureAction
  | AddSectionalRequestAction
  | AddSectionalSuccessAction
  | AddSectionalFailureAction
  | DeleteSectionalRequestAction
  | DeleteSectionalSuccessAction
  | DeleteSectionalFailureAction
  | SetDefaultPropertyDefaultLayoutRequestAction
  | SetDefaultPropertyDefaultLayoutSuccessAction
  | SetDefaultPropertyDefaultLayoutFailureAction
  | ResetSuccessAction;
