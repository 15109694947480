import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PriceQuotationStatus, SearchParams } from '@app/models';
import { isNil, omitBy } from 'lodash';
import { Observable } from 'rxjs';

import { generateSearchQuery } from '../core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class PriceQuotationsService {
  constructor(private http: HttpClient) {}

  load(
    page: number,
    properties: number[],
    filters: { [key: string]: any },
    status?: PriceQuotationStatus,
  ) {
    let propertiesData = properties;

    if (filters) {
      const { property_id } = filters;
      if (property_id && property_id.length) {
        propertiesData = property_id;
      }
    }

    const data = omitBy(
      {
        page,
        status,
        property_id: propertiesData,
        ...filters,
      },
      isNil,
    );

    const url = 'reservations/quotes?' + generateSearchQuery(data);

    return this.http.get(url) as Observable<any>;
  }

  export(searchParams: SearchParams) {
    return this.http.get(
      `reservations/quotes?${generateSearchQuery({
        ...searchParams,
        export: 1,
      })}`,
    );
  }

  delete(priceQuotationId: number) {
    return this.http.delete(`reservations/quote/${priceQuotationId}`);
  }

  updateRoomPriceList(
    reservation_quote_option_acc_id: number,
    reservation_quote_id: number,
    reservation_quote_option_id: number,
    accommodation_tableau_number_id: number,
    accommodation_id: number,
  ) {
    return this.http.put(
      `reservations/quote/${reservation_quote_id}/option/${reservation_quote_option_id}/accommodation/${reservation_quote_option_acc_id}`,
      {
        accommodation_tableau_number_id,
        accommodation_id,
        change_only_modified: 1,
      },
    );
  }
}
