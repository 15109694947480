<by-page-header
  [byStyle]="{ paddingTop: 0 }"
  [title]="'reverse_auto_invoice' | translate | capitalize"
></by-page-header>

<div [formGroup]="form" nz-form nzLayout="vertical">
  <by-question
    *ngIf="isDepositPayment"
    [label]="'do_you_want_release_deposit' | translate | upperfirst"
    formControlName="release_deposit"
    [yesValue]="1"
    [noValue]="0"
  ></by-question>

  <by-question
    [label]="
      'reverse_auto_invoice_payment_question_description'
        | translate
        | upperfirst
    "
    formControlName="mark_as_paid"
    [yesValue]="1"
    [noValue]="0"
  ></by-question>

  <nz-form-item *ngIf="form.get('mark_as_paid').value">
    <nz-form-label nzRequired>
      <strong>{{ 'payment_method' | translate | capitalize }}</strong>
    </nz-form-label>
    <nz-form-control>
      <nz-select formControlName="payment_method_id">
        <nz-option
          [nzDisabled]="isDepositPayment && !form.value.release_deposit"
          nzCustomContent
          [nzValue]="0"
          [nzLabel]="
            'refound_credit_note_option'
              | translate
                : {
                    documentType: 'auto_invoice' | translate
                  }
              | upperfirst
          "
        >
          {{
            'refound_credit_note_option'
              | translate
                : {
                    documentType: 'auto_invoice' | translate
                  }
              | upperfirst
          }}
          <i
            *ngIf="isDepositPayment && !form.value.release_deposit"
            class="fal fa-info-circle pointer color--orange"
            nz-tooltip
            [nzTooltipTitle]="
              'is_deposit_payment_disabled' | translate | upperfirst
            "
          ></i>
        </nz-option>
        <ng-container
          *ngFor="let paymentMethod of (paymentMethodsGeneric$ | async) || []"
        >
          <nz-option
            *ngIf="
              invoicingAllowedPaymentMethodsIds
                | firstOrDefault: paymentMethod.id
            "
            [nzLabel]="paymentMethod.name"
            [nzValue]="paymentMethod.id"
          ></nz-option
        ></ng-container>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</div>
