import {
  IInvoiceLayout,
  ITaxSystem,
} from '@app/models/objects/invoices-layouts';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';
import { uniq } from 'lodash';

export const getEntities = (
  state: State,
): { [layoutId: number]: IInvoiceLayout } => state.entities;

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getDetails = (state: State): IInvoiceLayout => state.details;

export const getTaxSystem = (state: State): ITaxSystem[] => state.taxSystem;

export const getDefaultPropertiesLayoutsMap = (
  state: State,
): { [property_id: number]: number } => state.defaultPropertiesLayouts;

export const getInvoiceLayoutsProperties = (
  state: State,
): { [property_id: number]: IInvoiceLayout[] } =>
  state.invoiceLayoutsProperties;

export const getDefaultPropertyLayout =
  (propertyId: number) =>
  (state: State): number =>
    state.defaultPropertiesLayouts &&
    state.defaultPropertiesLayouts[propertyId];

export const getInvoicesLayouts = (state: State): IInvoiceLayout[] =>
  state.invoiceLayouts;

export const selectInvoiceLayoutsState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('invoicesLayouts');

export const selectInvoicesLayouts: (state: object) => IInvoiceLayout[] =
  featureAdapter.getSelectors(selectInvoiceLayoutsState).selectAll;

export const selectInvoicesLayoutsError: MemoizedSelector<object, any> =
  createSelector(selectInvoiceLayoutsState, getError);

export const selectInvoicesLayoutsIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectInvoiceLayoutsState, getIsLoading);

export const selectInvoicesLayoutDetails: MemoizedSelector<
  object,
  IInvoiceLayout
> = createSelector(selectInvoiceLayoutsState, getDetails);

export const selectTaxSystem: MemoizedSelector<object, ITaxSystem[]> =
  createSelector(selectInvoiceLayoutsState, getTaxSystem);

export const selectDefaultPropertiesLayoutsMap: MemoizedSelector<
  object,
  { [property_id: number]: number }
> = createSelector(selectInvoiceLayoutsState, getDefaultPropertiesLayoutsMap);

export const selectDefaultPropertyLayout = (propertyId: number) =>
  createSelector(selectInvoiceLayoutsState, (state) =>
    getDefaultPropertyLayout(propertyId)(state),
  );

export const selectInvoiceLayoutsProperties: MemoizedSelector<
  object,
  { [property_id: number]: IInvoiceLayout[] }
> = createSelector(selectInvoiceLayoutsState, getInvoiceLayoutsProperties);

export const selecInvoiceLayoutsEntities: MemoizedSelector<
  object,
  { [invoiceId: number]: IInvoiceLayout }
> = createSelector(selectInvoiceLayoutsState, getEntities);

export const selectAllInvoiceLayouts: MemoizedSelector<
  object,
  IInvoiceLayout[]
> = createSelector(
  selectInvoiceLayoutsState,
  (state: State) => state.allInvoiceLayouts,
);

export const selectVatCodes = createSelector(
  selectInvoicesLayouts,
  (invoiceLayouts) =>
    uniq(
      invoiceLayouts?.reduce<string[]>(
        (acc, { vat_code }) => [...acc, vat_code],
        [],
      ),
    ),
);
