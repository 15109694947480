import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import {
  ActiveChargesAddBillrequest,
  ActiveChargesAddManualBillrequest,
  ActiveChargesEditBillrequest,
  CreateActiveChargeRequest,
  InvoiceListRequest,
} from '@app/models';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class ActivChargeService {
  constructor(private http: HttpClient) {}

  load(params: SearchParams) {
    return this.http.get(
      `properties/general_invoices?${generateSearchQuery(
        params,
      )}&skip_rejected_advances=1`,
    );
  }

  create(data: CreateActiveChargeRequest) {
    return this.http.post(`properties/general_invoice`, data);
  }

  addBill(data: ActiveChargesAddBillrequest) {
    return this.http.post(`bill_row`, data);
  }

  editBill(data: ActiveChargesEditBillrequest) {
    return this.http.put(`bill_row/${data.bill_row_id}`, data);
  }

  deleteBill(generalInvoiceIid: number, chargeIds: number[]) {
    return this.http.delete(
      `general_invoice/${generalInvoiceIid}/delete?${generateParamArray(
        'charge_ids',
        chargeIds,
      )}`,
    );
  }

  deleteGeneralInvoice(invoiceId: number) {
    return this.http.delete(`general_invoice/${invoiceId}`);
  }

  loadDetails(invoice_id: number) {
    return this.http.get(`invoices/${invoice_id}`);
  }

  moveBills(
    invoice_id: number,
    bill_id: number[],
    createInvoicePayload?: CreateActiveChargeRequest,
  ): Observable<Object> {
    if (createInvoicePayload) {
      return this.create(createInvoicePayload).pipe(
        switchMap(({ data }: IResponseSuccess) => {
          const invoice = data[createInvoicePayload.property_id[0]];
          return this.moveBills(invoice.id, bill_id);
        }),
      );
    }

    return this.http.post(`general_invoice/move_bills`, {
      invoice_id,
      bill_id,
    });
  }
}
