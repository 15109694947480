import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function preventRequestCancellation<T>(
  httpRequest: Observable<T>,
): Observable<T> {
  const request$ = new Subject<T>();

  httpRequest
    .pipe(
      catchError((error) => {
        request$.error(error);
        return of(null);
      }),
    )
    .subscribe((response) => {
      if (response) {
        request$.next(response);
      }

      request$.complete();
    });

  return request$.asObservable();
}
