<ng-container [formGroup]="form">
  <nz-form-item
    class="by-reservation-form__item by-reservation-form__item--gray"
  >
    <nz-form-label>
      {{ 'language' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
    >
      <nz-select
        [nzDropdownMatchSelectWidth]="false"
        [nzSize]="inputSize"
        formControlName="language_id"
      >
        <nz-option
          *ngFor="let language of languages"
          [nzLabel]="language.name"
          [nzValue]="language.id"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="by-reservation-form__item">
    <nz-form-label [class.required-input-red]="customerIsRequired">
      {{ 'name' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
      [nzErrorTip]="
        'validation_form.' + (form.controls.name.errors | keys | head)
          | translate
      "
    >
      <div class="by-flex by-flex-middle">
        <i
          *ngIf="
            form.value.customer_id &&
            form.value.customer_id !== selectedCompany?.customer_id
          "
          class="pointer color--red by-mr-10 fas fa-user-times"
          (click)="onRemoveSelectedCustomer()"
        ></i>

        <by-customers-autocomplete
          formControlName="name"
          autocompleteFor="name"
          searchBy="name"
          [size]="inputSize"
          [width]="300"
          [placeHolder]="
            'placeholder.insert' | translate: { param: 'name' | translate }
          "
          (customerSelected)="onCustomerSelected($event)"
        ></by-customers-autocomplete>
      </div>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item
    class="by-reservation-form__item by-reservation-form__item--gray"
  >
    <nz-form-label [class.required-input-red]="customerIsRequired">
      {{ 'surname' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
      [nzErrorTip]="
        'validation_form.' + (form.controls.surname.errors | keys | head)
          | translate
      "
    >
      <by-customers-autocomplete
        formControlName="surname"
        autocompleteFor="surname"
        searchBy="surname"
        [size]="inputSize"
        [width]="300"
        [placeHolder]="
          'placeholder.insert' | translate: { param: 'surname' | translate }
        "
        (customerSelected)="onCustomerSelected($event)"
      ></by-customers-autocomplete>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="by-reservation-form__item">
    <nz-form-label>
      {{ 'e-mail' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
      [nzErrorTip]="
        'validation_form.' + (form.controls.email.errors | keys | head)
          | translate
      "
    >
      <input
        formControlName="email"
        [nzSize]="inputSize"
        type="email"
        nz-input
        [placeholder]="
          'placeholder.insert' | translate: { param: 'e-mail' | translate }
        "
        autocomplete="nope"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item
    class="by-reservation-form__item by-reservation-form__item--gray"
  >
    <nz-form-label>
      {{ 'telephone' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
      [nzErrorTip]="
        'validation_form.' + (form.controls.telephone.errors | keys | head)
          | translate
      "
    >
      <input
        formControlName="telephone"
        type="tel"
        mask="+111111111111111"
        [dropSpecialCharacters]="false"
        placeholder="+000000000000000"
        [patterns]="TELEPHONE_MASK_PATTERN"
        nz-input
        [nzSize]="inputSize"
        autocomplete="nope"
        [validation]="false"
      />
    </nz-form-control>
  </nz-form-item>
</ng-container>
