<ng-container [formGroup]="form">
  <nz-form-item
    class="by-reservation-form__item by-reservation-form__item--gray"
  >
    <nz-form-label>
      {{
        (isDeposit ? 'payment_method' : 'how_pay_the_reservation')
          | translate
          | upperfirst
      }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
    >
      <nz-select
        [nzDropdownMatchSelectWidth]="false"
        [nzSize]="inputSize"
        formControlName="payment_method_id"
        (nzBlur)="onTouched()"
      >
        <nz-option
          *ngIf="!isDeposit"
          [nzLabel]="'not_defined' | translate | upperfirst"
          [nzValue]="0"
        ></nz-option>

        <nz-option
          *ngFor="let paymentMethod of paymentMethods"
          [nzLabel]="paymentMethod.name"
          [nzValue]="paymentMethod.id"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <ng-container *ngIf="ENABLED_CREDIT_CARD_FORM[form.value.payment_method_id]">
    <nz-form-item class="by-reservation-form__item">
      <nz-form-label>
        {{ 'credit_card_ask' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control
        [class.by-full-width-control]="isMobile"
        class="by-reservation-form__control"
      >
        <nz-radio-group
          nzButtonStyle="solid"
          [nzSize]="inputSize"
          [formControl]="saveCreditCard"
        >
          <label nz-radio-button [nzValue]="true"
            >{{ 'yes' | translate | capitalize }}
          </label>
          <label nz-radio-button [nzValue]="false"
            >{{ 'no' | translate | capitalize }}
          </label>
        </nz-radio-group>
      </nz-form-control>
    </nz-form-item>

    @if (saveCreditCard.value) {
      <by-credit-card-form formControlName="credit_card" />
    }
  </ng-container>
</ng-container>
