export const SCA_PAYMENT_METHODS = {
  12: true,
  13: true,
  17: true,
};

export const STRIPE_PAYMENT_METHOD_ID = {
  18: true,
};

export const POS_PAYMENT_METHOD_ID = {
  1: true, // PayPal
  18: true, // Stripe
  28: true, // Nexi
};

export const ENABLED_CREDIT_CARD_PAYMENT_METHODS = {
  18: true,
  12: true,
  30: true,
};

export const ENABLED_CREDIT_CARD_FORM = { 12: true, 13: true, 17: true };

export const GUARANTEE_CREDIT_CARD_ID = 13;

export const CASH_PAYMENT_METHOD_ID = 15;

export enum PAYMENT_METHODS_IDS {
  stripe = 18,
  creditCard = 17,
  emailRequest = 30,
}

export enum PAYMENT_METHODS_SLUGS {
  stripe = 'cc_stripe',
  creditCard = 'cc_general',
}
