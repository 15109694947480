import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingPropertyTypeResponse } from '../models';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { removeNullishValues } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class BookingPropertyTypeService {
  constructor(private http: HttpClient) {}

  load(property_id: number, property_xml_id?: string) {
    return this.http.get<IResponseSuccess<BookingPropertyTypeResponse>>(
      `channelmanager/booking_onboard/${property_id}/type?${generateSearchQuery(
        removeNullishValues({ property_xml_id }),
      )}`,
    );
  }
}
