import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { openInNewTab } from '../../../helpers';
import { FileSaverService } from '../../../services';

@Component({
  selector: 'by-attachment-viewer-tool-bar',
  templateUrl: './attachment-viewer-tool-bar.component.html',
  styleUrls: ['./attachment-viewer-tool-bar.component.scss'],
})
export class AttachmentViewerToolBarComponent {
  @Input()
  printData: {
    src: string;
    fileName: string;
  };

  @Input()
  src: string;

  @Input()
  findState: 'success' | 'error' | 'validating';

  @Input()
  totalPages = 0;

  @Input()
  page = 0;

  @Input()
  title: string;

  @Output()
  pageChange = new EventEmitter<number>();

  @Output()
  zoomChange = new EventEmitter<number | string>();

  @Input()
  zoom: string | number;

  @Input()
  pageScale: number;

  showFindInputArea = false;

  private subs = new SubSink();

  private keydown$ = fromEvent(window, 'keydown').pipe(
    filter((event: KeyboardEvent) => event.code === 'KeyF' && event.ctrlKey),
  );

  constructor(
    private modal: NzModalRef,
    private fileSaver: FileSaverService,
    private translate: TranslateService,
  ) {
    this.subs.add(
      this.keydown$.subscribe(() => {
        this.showFindInputArea = true;
      }),
    );
  }

  onDestroy() {
    this.modal.destroy();
  }

  onPrint() {
    // const PDFViewerApplication: IPDFViewerApplication = (window as any)
    //   .PDFViewerApplication;
    // PDFViewerApplication.eventBus.dispatch('print');

    if (this.printData) {
      openInNewTab(this.printData?.src, this.printData?.fileName);

      return;
    }
    window.open(this.src);
  }

  onDownload() {
    this.fileSaver.saveAs(
      this.printData?.src || this.src,
      this.title ||
        this.printData?.fileName ||
        this.translate.instant('document'),
    );

    // const PDFViewerApplication: IPDFViewerApplication = (window as any)
    //   .PDFViewerApplication;
    // PDFViewerApplication.eventBus.dispatch('download');
  }

  onInputAreaVisibleChange(visible: boolean) {
    if (!visible) {
      this.showFindInputArea = false;
    }
  }
}
