import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { SearchParams, SetHousekeeperRecordUsersRequest } from '../models';
import { SetHousekeeperRecordRequest } from '../models/requests/set-housekeeper-record.request';

@Injectable({
  providedIn: 'root',
})
export class HousekeeperDashboardService {
  constructor(private http: HttpClient) {}

  load(searchParams: SearchParams) {
    return this.http.get(
      `properties/housekeeper/dashboard?${generateSearchQuery(searchParams)}`,
    );
  }

  setRecord(request: Omit<SetHousekeeperRecordRequest, 'group_id'>) {
    const { property_id, ...requestFields } = request;

    return this.http.post(
      `properties/${property_id}/housekeeper/records`,
      requestFields,
    );
  }

  setRecordUsers(request: SetHousekeeperRecordUsersRequest) {
    const { property_id, ...requestFields } = request;

    return this.http.post(
      `properties/${property_id}/housekeeper/records/users`,
      requestFields,
    );
  }
}
