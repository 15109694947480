import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { TableauChooseActionModalComponent } from '../features/commons/tableau-2/tableau-choose-action-modal/tableau-choose-action-modal.component';
import { TableauClosureModalComponent } from '../features/commons/tableau-2/tableau-closure-modal/tableau-closure-modal.component';
import { TableauNoteFormModalComponent } from '../features/commons/tableau-2/tableau-note-form-modal/tableau-note-form-modal.component';
import { TableauReservationFormModalComponent } from '../features/commons/tableau-2/tableau-reservation-form-modal/tableau-reservation-form-modal.component';
import {
  TableauActionParams,
  TableauActionsMethods,
  TableauNote,
} from '../models';

@Injectable({ providedIn: 'root' })
export class TableauActionsService implements TableauActionsMethods {
  constructor(private modalService: NzModalService) {}

  chooseAction(params: TableauActionParams) {
    this.modalService.create<
      TableauChooseActionModalComponent,
      Partial<TableauChooseActionModalComponent>
    >({
      nzContent: TableauChooseActionModalComponent,
      nzData: {
        params,
        actionsMethods: this,
      },
      nzWrapClassName: 'by-tableau-actions-modal',
      nzWidth: 380,
      nzBodyStyle: { padding: '15px' },
      nzFooter: null,
    });
  }

  createReservation = (params: TableauActionParams) => {
    this.modalService.create<
      TableauReservationFormModalComponent,
      Partial<TableauReservationFormModalComponent>
    >({
      nzContent: TableauReservationFormModalComponent,
      nzData: {
        params,
      },
      nzWrapClassName:
        'by-tableau-actions-modal by-tableau-actions-modal--blue-header',
      nzWidth: 380,
      nzBodyStyle: { padding: '0px' },
      nzFooter: null,
      nzMaskClosable: false,
    });
  };

  openNoteForm = (params: TableauActionParams, note?: TableauNote) => {
    this.modalService.create<
      TableauNoteFormModalComponent,
      Partial<TableauNoteFormModalComponent>
    >({
      nzContent: TableauNoteFormModalComponent,
      nzData: {
        params,
        note,
      },
      nzWrapClassName:
        'by-tableau-actions-modal by-tableau-actions-modal--blue-header',
      nzWidth: 380,
      nzBodyStyle: { padding: '0px' },
      nzFooter: null,
    });
  };

  closePeriod = (params: TableauActionParams) => {
    this.modalService.create<
      TableauClosureModalComponent,
      Partial<TableauClosureModalComponent>
    >({
      nzContent: TableauClosureModalComponent,
      nzData: {
        params,
        action: 'close',
      },
      nzWrapClassName:
        'by-tableau-actions-modal by-tableau-actions-modal--blue-header',
      nzWidth: 380,
      nzBodyStyle: { padding: '0px' },
      nzFooter: null,
    });
  };

  openPeriod = (params: TableauActionParams) => {
    this.modalService.create<
      TableauClosureModalComponent,
      Partial<TableauClosureModalComponent>
    >({
      nzContent: TableauClosureModalComponent,
      nzData: {
        params,
        action: 'open',
      },
      nzWrapClassName:
        'by-tableau-actions-modal by-tableau-actions-modal--blue-header',
      nzWidth: 380,
      nzBodyStyle: { padding: '0px' },
      nzFooter: null,
    });
  };
}
