import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { InvoiceNumberService } from '../../services/invoice-number.service';

import * as fromActions from './actions';

@Injectable()
export class InvoiceNumberStoreEffects {
  constructor(
    private dataService: InvoiceNumberService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  check$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkRequest),
      switchMap(({ request }) =>
        this.dataService.check(request).pipe(
          map(
            ({
              data,
            }: IResponseSuccess<
              { used: boolean; date_number_no_sequential: boolean }[]
            >) => {
              return fromActions.checkSuccess({
                alredy_used: data[0].used,
                date_number_no_sequential: data[0]?.date_number_no_sequential,
              });
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.checkFailure(error));
          }),
        ),
      ),
    ),
  );
}
