import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'by-spin',
  templateUrl: './spin.component.html',
  styleUrls: ['./spin.component.scss'],
})
export class SpinComponent implements OnInit {
  @Input() nzDelay: number;
  @Input() nzSize: 'large' | 'small' | 'default' = 'default';
  @Input() nzColor = '#047bf8';
  @Input() nzSpinning: boolean;
  @Input() nzSimple: boolean;
  @Input() nzTip: string;
  @Input() nzIndicator: TemplateRef<void>;

  constructor() {}

  ngOnInit() {}
}
