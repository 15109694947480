import { environment } from '../../environments/environment';
import { Message, MessageRoutingData } from '../models';

export function cloudMessageMapper(message: Message): Message {
  return {
    ...message,
    routingData: getRoutingData(message),
  };
}

function getRoutingData(message: Message): MessageRoutingData {
  const { web_url } = message;

  if (!web_url) {
    return null;
  }

  const url = new URL(web_url);

  const isSupport = web_url.includes(environment.supportUrl);

  const external = !environment.appUrl.includes(url.host) || isSupport;

  let params = null;

  if (!isSupport) {
    params = {};
    url.searchParams.forEach((value, key) => {
      params = {
        ...params,
        [key]: value,
      };
    });
  }
  return {
    route: [external ? web_url : url.pathname],
    external,
    params,
  };
}
