import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { ReservationFrom } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectReservationFromState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('reservationFroms');

export const selectAllReservationFromsItems: (
  state: object,
) => ReservationFrom[] = featureAdapter.getSelectors(selectReservationFromState)
  .selectAll;

export const selectReservationFromById = (id: string) =>
  createSelector(
    selectAllReservationFromsItems,
    (reservationFroms: ReservationFrom[]) => {
      if (reservationFroms) {
        return reservationFroms.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectReservationFromsError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectReservationFromState,
  getError,
);

export const selectReservationFromsIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectReservationFromState,
  getIsLoading,
);
