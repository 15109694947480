import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AdminPropertiesService {
  constructor(private _http: HttpClient) {}

  loadUsers(search: string, propertyId: number) {
    if (propertyId) {
      return this._http.get(`users?property_id=${propertyId}&${search}`);
    } else {
      return this._http.get(`users?${search}`);
    }
  }

  attachUser(data: { property_id: number[]; user_id: number }) {
    return this._http.post(`user/attach_property`, { ...data });
  }

  detachUser(data: { property_id: number[]; user_id: number }) {
    return this._http.post(`user/detach_property`, { ...data });
  }

  deleteProperty(propertyId: number) {
    return this._http.delete(`property/${propertyId}`);
  }

  enableProperty(propertyId: number) {
    return this._http.put(`property/${propertyId}/change_status`, {
      status: 1,
    });
  }
}
