import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';
import { GetAddonCategoriesResponse } from '@app/models';
import { CreateAddonCategoryRequest } from '@app/models/requests/create-addon-category-request';
import { UpdateAddonCategoryRequest } from '@app/models/requests/update-addon-category-request';
import { CreateAddonCategoryResponse } from '@app/models/responses/create-addon-category-response';

@Injectable({
  providedIn: 'root',
})
export class AddonCategoriesService {
  constructor(private http: HttpClient) {}

  load(propertiesIds: number[]) {
    return this.http.get<GetAddonCategoriesResponse>(
      `addon_categories?${generateParamArray('property_id', propertiesIds)}`,
    );
  }

  create({ propertiesIds, languages }: CreateAddonCategoryRequest) {
    return this.http.post<CreateAddonCategoryResponse>(
      `addon_category?${generateParamArray('property_id', propertiesIds)}`,
      { languages },
    );
  }

  delete(addonCategoryId: number) {
    return this.http.delete(`addon_category/${addonCategoryId}`);
  }

  update({ addonCategoryId, languages }: UpdateAddonCategoryRequest) {
    return this.http.put(`addon_category/${addonCategoryId}`, { languages });
  }
}
