import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { IPropertyTreatment } from '../core/models/api/properties-treatments/property-treatment.model';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { PropertiesTratmentsService } from '../core/services/properties-treatments.service';
import {
  AccommodationLookup,
  AddonsByPropertyCategories,
  Channel,
  ExportWidgetData,
  Rateplan,
  ReservationFrom,
  ReservationReason,
  StatsRequest,
  StatsResponse,
} from '../models';
import { RootState } from '../root-store/root-state';

import { AccommodationsService } from './accommodations.service';
import { AddonsService } from './addons.service';
import { ChannelsService } from './channels.service';
import { CustomersService } from './customers.service';
import { ExportService } from './export.service';
import { RateplansService } from './rateplans.service';
import { ReservationFromsService } from './reservation-froms.service';
import { ReservationReasonsService } from './reservation-reasons.service';
import { StatsService } from './stats.service';
import { ExportFileRequestSuccess } from '../models/types/export-file-request';
@Injectable({
  providedIn: 'root',
})
export class StatsProductionService extends StatsService {
  // interruptCalls$ = this.store
  //   .pipe(select(StatsProductionStoreSelectors.selectInterruptCalls))
  //   .pipe(skip(1));

  constructor(
    public http: HttpClient,
    public exportService: ExportService,
    public channelsService: ChannelsService,
    public accommodationsService: AccommodationsService,
    public rateplansService: RateplansService,
    public reservationFromsService: ReservationFromsService,
    public reservationReasonsService: ReservationReasonsService,
    public customersService: CustomersService,
    public translateService: TranslateService,
    private treatmentService: PropertiesTratmentsService,
    private addonService: AddonsService,
    dateFormatter: DateFormatterService,
    store: Store<RootState>,
  ) {
    super(
      http,
      exportService,
      channelsService,
      accommodationsService,
      rateplansService,
      reservationFromsService,
      reservationReasonsService,
      customersService,
      translateService,
      dateFormatter,
      store,
    );
  }

  loadFilters(propertyIds: number[]) {
    const filtersServices = [
      this.reservationReasonsService.load({
        property_id: propertyIds,
      }),
      this.channelsService.loadActive(propertyIds, {
        with_accommodation: 0,
        with_trashed: 1,
        allow_statistics: 1,
      }),
      this.accommodationsService.loadLookup(propertyIds, true),
      this.rateplansService.load(propertyIds, true),
      this.reservationFromsService.load(),
      this.customersService.loadCustomerCategories(),
      this.treatmentService.loadPropertiesTreatmentsArray(propertyIds, true),
      this.addonService.loadAddonsByPropertyCategory(propertyIds, true, true),
    ];
    return forkJoin(filtersServices).pipe(
      map(
        ([
          reservationReasonsResponse,
          channelsResponse,
          accommodationsResponse,
          ratePlansResponse,
          reservationFromsResponse,
          customerCategoriesResponse,
          treatmentsResponse,
          addonsResponse,
        ]: [
          IResponseSuccess<ReservationReason[]>,
          Channel[],
          IResponseSuccess<AccommodationLookup>,
          IResponseSuccess<Rateplan[]>,
          IResponseSuccess<ReservationFrom[]>,
          IResponseSuccess<{ id: number; name: string }[]>,
          IResponseSuccess<IPropertyTreatment[]>,
          IResponseSuccess<AddonsByPropertyCategories[]>,
        ]) => {
          const { data: reservationReasons } = reservationReasonsResponse;
          const { data: accommodations } = accommodationsResponse;
          const { data: ratePlans } = ratePlansResponse;
          const { data: reservationFroms } = reservationFromsResponse;
          const { data: customerCategories } = customerCategoriesResponse;
          const { data: propertiesTreatments } = treatmentsResponse;
          const { data: propertiesAddons } = addonsResponse;

          return {
            reservationReasons: reservationReasons,
            channels: this.channelByProperty(channelsResponse),
            accommodations: this.accommodationByProperty(accommodations || {}),
            ratePlans,
            reservationFroms,
            customerCategories: [
              ...customerCategories,
              { id: null, name: 'not_specified' },
            ],
            propertiesTreatments:
              this.treatmentsByProperty(propertiesTreatments),
            propertiesAddons,
          };
        },
      ),
    );
  }

  treatmentsByProperty(treatments: IPropertyTreatment[]): {
    [propertyId: number]: IPropertyTreatment;
  } {
    return treatments.reduce((acc, treatment) => {
      const { property_id } = treatment;
      acc = {
        ...acc,
        [+property_id]: uniqBy(
          treatments.filter((treat) => treat.property_id === +property_id),
          'treatment_id',
        ).map((_treatment) => {
          const {
            treatment: { name },
          } = _treatment;
          return {
            ..._treatment,
            name,
          };
        }),
      };
      return acc;
    }, {});
  }

  override load(data: Partial<StatsRequest>) {
    return this.http
      .post('statistics/production', data)
      .pipe(takeUntil(this.interruptCalls$)) as Observable<
      IResponseSuccess<StatsResponse>
    >;
  }
  export(exportData: ExportWidgetData, data: Partial<StatsRequest>) {
    return this.http.post<IResponseSuccess<ExportFileRequestSuccess>>(
      `statistics/production/export`,
      {
        ...this.dataWithReservationDates(data),
        export_format: exportData.format,
        export_columns: exportData.columns,
        service: 1, //11/01/2024 HOTFIX REMOVE THIS VALUE ON THE NEXT RELEASE
      },
    );
  }
}
