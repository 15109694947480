import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { items } = action.payload;
      return featureAdapter.setAll(items, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
