import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first, get, orderBy, toLower, upperFirst } from 'lodash';
import moment from 'moment';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { SimpleCompanyFormComponent } from '../../../../components/simple-company-form/simple-company-form/simple-company-form.component';
import { GUARANTEE_CREDIT_CARD_ID } from '../../../../config';
import {
  CreateNewReservationRequest,
  LoadTemplateEmailCustomRequest,
} from '../../../../core/+state/core.actions';
import {
  selectLanguages,
  selectTemplateEmailCustom,
} from '../../../../core/+state/core.reducer';
import { handleFormSubmit } from '../../../../core/helpers/handleFormSubmit';
import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';
import { ILanguage } from '../../../../core/models/api/generics/languages/language.model';
import { dateToServerFormat, removeNullishValues } from '../../../../helpers';
import {
  AutomaticPaymentSummary,
  CompanyLookup,
  IEmailTemplateCustom,
  ProportionalDayPrice,
  ReservationFormAccommodation,
  TableauActionParams,
} from '../../../../models';
import { CreateReservationRequest } from '../../../../models/requests/create-reservation-request';
import { EstimateRequest } from '../../../../models/requests/estimate-request';
import {
  AddonsStoreActions,
  AddonsStoreSelectors,
} from '../../../../root-store/addons-store';
import {
  ChildrenRangeStoreActions,
  ChildrenRangeStoreSelectors,
} from '../../../../root-store/children-range-store';
import { CompaniesStoreActions } from '../../../../root-store/companies-store';
import { DealersStoreSelectors } from '../../../../root-store/dealers-store';
import {
  EmailTemplatePreviewStoreActions,
  EmailTemplatePreviewStoreSelectors,
} from '../../../../root-store/email-template-preview-store';
import {
  EstimateActions,
  EstimateSelectors,
} from '../../../../root-store/estimate-store';
import {
  InvoicesLayoutsStoreActions,
  InvoicesLayoutsStoreSelectors,
} from '../../../../root-store/invoices-layouts-store';
import {
  InvoicesStoreActions,
  InvoicesStoreSelectors,
} from '../../../../root-store/invoices-store';
import { PaymentMethodsStoreSelectors } from '../../../../root-store/payment-methods-store';
import { PlaceVatQuotesSettingsStoreSelectors } from '../../../../root-store/place-vat-quotes-store';
import {
  ProportionalDayPriceStoreActions,
  ProportionalDayPriceStoreSelectors,
} from '../../../../root-store/proportional-day-price-store';
import { ReservationFromsStoreSelectors } from '../../../../root-store/reservation-froms-store';
import { ReservationTagStoreSelectors } from '../../../../root-store/reservation-tag-store';
import { RootState } from '../../../../root-store/root-state';
import { ModalDragService } from '../../../../services/modal-drag.service';
import { ReservationFormComponent } from '../../reservation-form/reservation-form/reservation-form.component';
import { UserPreferencesStoreSelectors } from '../../../../root-store/user-preferences-store';
import {
  AutomaticPaymentsSummaryStoreActions,
  AutomaticPaymentsSummaryStoreSelectors,
} from '../../../../components/automatic-payment-summary/+state';
import { ActiveModulesStoreSelectors } from '../../../../root-store/active-modules-store';
import { min } from 'date-fns';

@Component({
  selector: 'by-tableau-reservation-form-modal',
  templateUrl: './tableau-reservation-form-modal.component.html',
  styleUrls: ['./tableau-reservation-form-modal.component.scss'],
  providers: [ModalDragService],
})
export class TableauReservationFormModalComponent implements OnInit, OnDestroy {
  readonly nzModalData: Partial<TableauReservationFormModalComponent> =
    inject(NZ_MODAL_DATA);

  @ViewChild(ReservationFormComponent)
  formComponent: ReservationFormComponent;

  @Input()
  params: TableauActionParams = this.nzModalData.params;

  childrenRanges$ = this.store.pipe(
    select(ChildrenRangeStoreSelectors.selectAllchildrenRangeItems),
  );

  estimateResponse$ = this.store.pipe(select(EstimateSelectors.selectEstimate));

  estimating$ = this.store.pipe(select(EstimateSelectors.selectEstimating));

  rateplans$ = this.store.pipe(select(EstimateSelectors.selectRateplans));

  depositNextNumber$: Observable<number>;
  depositNextNumberLoading$ = this.store.pipe(
    select(InvoicesStoreSelectors.selectInvoicesLoadingNextNumber),
  );

  proportionalDayPriceResponse$ = this.store.pipe(
    select(ProportionalDayPriceStoreSelectors.selectData),
  );

  invoiceLayouts$ = this.store.pipe(
    select(InvoicesLayoutsStoreSelectors.selectInvoicesLayouts),
  );

  paymentMethods$ = this.store.pipe(
    select(PaymentMethodsStoreSelectors.selectAllPaymentMethodsItems),
    map((paymentMethods) =>
      paymentMethods?.filter(({ id }) => id !== GUARANTEE_CREDIT_CARD_ID),
    ),
  );

  reservationFroms$ = this.store.pipe(
    select(ReservationFromsStoreSelectors.selectAllReservationFromsItems),
  );

  dealers$ = this.store.pipe(select(DealersStoreSelectors.selectAllItems));

  tags$ = this.store.pipe(
    select(ReservationTagStoreSelectors.selectAllReservationTagItems),
  );

  vatQuotes$ = this.store.pipe(
    select(PlaceVatQuotesSettingsStoreSelectors.selectVatQuotesData),
  );

  addons$ = this.store.pipe(
    select(AddonsStoreSelectors.selectAccommodationsAddons),
  );

  emailPreviewBody$ = this.store.pipe(
    select(EmailTemplatePreviewStoreSelectors.selectPreview),
    map((preview) => preview?.body),
  );

  emailPreviewLoading$ = this.store.pipe(
    select(EmailTemplatePreviewStoreSelectors.selectTemplateIsLoading),
  );

  automaticPaymentSummary$ = this.store.pipe(
    select(
      AutomaticPaymentsSummaryStoreSelectors.selectAutomaticPaymentsSummary,
    ),
  );

  automaticPaymentSummary: AutomaticPaymentSummary[] = [];

  automaticPaymentModule$ = this.store.pipe(
    select(
      ActiveModulesStoreSelectors.selectPropertiesIdsWithActiveModule(
        'automatic-payments',
      ),
    ),
  );

  customEmailTeplates$: Observable<IEmailTemplateCustom[]>;

  defaultInvoiceLayoutId$: Observable<number>;

  loading$ = combineLatest([
    this.store.pipe(
      select(ChildrenRangeStoreSelectors.selectChildrenRangeIsLoading),
    ),
    this.store.pipe(select(EstimateSelectors.selectFetchingRateplans)),
    this.store.pipe(
      select(InvoicesLayoutsStoreSelectors.selectInvoicesLayoutsIsLoading),
    ),
  ]).pipe(map((loadings) => loadings.some((loading) => loading)));

  automaticPaymentsLoading$ = this.store.pipe(
    select(AutomaticPaymentsSummaryStoreSelectors.selectIsLoading),
  );

  dates: Date[];

  accommodation: ReservationFormAccommodation;

  currency: ICurrency;

  languages: ILanguage[];

  submitting = false;

  tagControl = this.formBuilder.control(null);

  private subs = new SubSink();

  constructor(
    private modalRef: NzModalRef,
    private store: Store<RootState>,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private modalService: NzModalService,
    private modalDragService: ModalDragService,
  ) {
    this.modalDragService.handle(this.modalRef);
  }

  ngOnInit() {
    this.subs.add(
      this.store.pipe(select(selectLanguages)).subscribe((languages) => {
        this.languages = languages;
      }),
    );
    this.subs.add(
      this.automaticPaymentSummary$.subscribe((automaticPaymentSummary) => {
        this.automaticPaymentSummary = automaticPaymentSummary;
      }),
    );

    this.subs.add(
      this.estimateResponse$.subscribe((estimate) => {
        if (estimate) {
          this.loadAutomaticPaymentsSummary({ daily_rates: estimate.days });
        }
      }),
    );

    this.subs.add(
      this.proportionalDayPriceResponse$.subscribe((prices) => {
        if (prices) {
          this.loadAutomaticPaymentsSummary({ daily_rates: prices.days });
        }
      }),
    );

    this.setParams();
    this.setDefaultInvoiceLayoutSource();

    this.loadAddons();
    this.loadRateplans();
    this.loadChildrenRanges();
    this.loadInvoiceLayouts();
    this.loadCustomEmailTemplates();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();

    this.store.dispatch(EstimateActions.resetState());
    this.store.dispatch(new AddonsStoreActions.ResetSuccessAction());
    this.store.dispatch(new ChildrenRangeStoreActions.ResetSuccessAction());
    this.store.dispatch(new InvoicesLayoutsStoreActions.ResetSuccessAction());
  }

  onEstimate(request: EstimateRequest) {
    this.store.dispatch(
      EstimateActions.estimateRequest({
        requests: [request],
      }),
    );
  }

  onProportionalDayPrice(data: ProportionalDayPrice) {
    this.store.dispatch(
      ProportionalDayPriceStoreActions.calculateRequest({
        data,
      }),
    );
  }

  onLoadDepositNextNumber(invoice_layout_id: number) {
    this.depositNextNumber$ = this.store.pipe(
      select(InvoicesStoreSelectors.selectInvoiceNextNumber),
      map((layouts) => {
        return get(layouts, [invoice_layout_id, 'deposit', 0, 'number']);
      }),
    );

    this.store.dispatch(
      new InvoicesStoreActions.LoadInvoiceNextNumberRequest({
        invoice_layout_id,
        type: 'deposit',
      }),
    );
  }

  onCloseModal() {
    this.modalRef.close();
  }

  onSubmit() {
    // Double click prevention
    if (this.submitting) {
      return;
    }
    handleFormSubmit(this.formComponent.getForms(), () => {
      this.submitting = true;

      const reservationData = removeNullishValues({
        ...this.formComponent.getRequest(),
        reservation_tag_id: this.tagControl.value,
      });

      this.store.dispatch(
        new CreateNewReservationRequest({
          reservationData,
          onSuccess: () => {
            this.modalRef.close();
          },
          onFailure: () => {
            this.submitting = false;
          },
        }),
      );
    });
  }

  onShowEmailPreview(
    payload: CreateReservationRequest,
    content: TemplateRef<void>,
  ) {
    const {
      customer,
      template_email_id,
      template_email_type,
      payment_policy_id,
    } = payload;

    const lang_iso_code = this.languages.find(
      ({ id }) => id === customer.language_id,
    )?.iso_code;

    handleFormSubmit(this.formComponent.getForms(), () => {
      this.store.dispatch(
        EmailTemplatePreviewStoreActions.loadRequest({
          payload,
          lang_iso_code,
          template_email_type,
          template_email_id,
          expected_payments: this.automaticPaymentSummary?.find(
            (automaticPayment) =>
              payment_policy_id === automaticPayment.payment_policy_id,
          )?.expected_payments,
        }),
      );

      this.modalService
        .create({
          nzTitle: upperFirst(this.translate.instant('email_preview')),
          nzContent: content,
          nzBodyStyle: { padding: '0px' },
          nzClassName: 'email-template-modal',
          nzFooter: null,
        })
        .afterClose.subscribe(() => {
          this.store.dispatch(EmailTemplatePreviewStoreActions.resetState());
        });
    });
  }

  onCreateCompany() {
    let creating = false;

    const { dealer_id } = this.formComponent.getRequest();

    const modal = this.modalService.create({
      nzTitle: upperFirst(this.translate.instant('new_company')),
      nzContent: SimpleCompanyFormComponent,
      nzFooter: [
        {
          label: upperFirst(this.translate.instant('cancel')),
          type: 'default',
          onClick: () => {
            modal.close();
          },
        },
        {
          label: upperFirst(this.translate.instant('save')),
          type: 'primary',
          loading: () => creating,
          onClick: ({ value, form }) => {
            handleFormSubmit([form], () => {
              creating = true;

              this.store.dispatch(
                new CompaniesStoreActions.CreateRequestAction({
                  request: { ...value, dealer_id },
                  onComplete: (company) => {
                    this.formComponent.patchCompany({
                      ...company,
                      email: first(company.customer.details)?.email,
                      internal_notes: company.customer.note,
                    } as CompanyLookup);

                    modal.close();
                  },
                  onFailure: () => {
                    creating = false;
                  },
                }),
              );
            });
          },
        },
      ],
    });

    modal.afterOpen
      .pipe(
        switchMap(() => {
          const component = modal.getContentComponent();
          return component.companySelected.asObservable();
        }),
        take(1),
      )
      .subscribe((company) => {
        this.formComponent.patchCompany(company);
        modal.close();
      });
  }

  private setParams() {
    const { row, currency, dateFrom, dateTo } = this.params;

    if (moment(dateFrom).isSame(moment(dateTo), 'day')) {
      this.dates = [dateFrom, moment(dateTo).add(1, 'day').toDate()];
    } else {
      this.dates = [dateFrom, dateTo];
    }

    this.accommodation = {
      ...row.data.accommodation_details,
      tableau_label: row.data.label,
      property_id: row.propertyId,
      accommodation_tableau_number_id: row.data.id,
    };

    this.currency = currency;
  }

  private loadChildrenRanges() {
    this.store.dispatch(
      new ChildrenRangeStoreActions.LoadRequestAction({
        propertyIds: [this.accommodation.property_id],
      }),
    );
  }

  private loadRateplans() {
    this.store.dispatch(
      EstimateActions.loadRateplansRequest({
        accommodationId: this.accommodation.id,
      }),
    );
  }

  private loadInvoiceLayouts() {
    this.store.dispatch(
      new InvoicesLayoutsStoreActions.LoadRequestAction({
        propertiesIds: [this.accommodation.property_id],
      }),
    );
  }

  private loadCustomEmailTemplates() {
    this.customEmailTeplates$ = this.store.pipe(
      select(
        UserPreferencesStoreSelectors.selectUserPreferencesDataByCategory(
          'messageTemplateOrder',
        ),
      ),
      take(1),
      switchMap(({ orderByEmail }) => {
        return this.store
          .pipe(
            select(selectTemplateEmailCustom(this.accommodation.property_id)),
          )
          .pipe(
            map((templates: IEmailTemplateCustom[]) => {
              let emailsFiltered = templates?.filter(
                ({ type }) => type === 'email',
              );

              if (orderByEmail?.key) {
                emailsFiltered = orderBy(
                  emailsFiltered,
                  (item) => toLower(item[orderByEmail?.key]),
                  [orderByEmail?.direction],
                );
              }

              return emailsFiltered;
            }),
          );
      }),
    );

    this.store.dispatch(
      new LoadTemplateEmailCustomRequest({
        property_id: this.accommodation.property_id,
      }),
    );
  }

  private loadAddons() {
    this.store.dispatch(
      new AddonsStoreActions.LoadAccommodationAddonsRequestAction({
        accommodationId: this.accommodation.id,
      }),
    );
  }

  loadAutomaticPaymentsSummary(data: {
    daily_rates: { [day: string]: number };
    discount_type_id?: 4 | 5;
    discount_value?: number;
  }) {
    const firstDay = dateToServerFormat(
      min(Object.keys(data.daily_rates).map((date) => new Date(date))),
    );

    this.store.dispatch(
      AutomaticPaymentsSummaryStoreActions.loadFromTableauRequest({
        data: {
          property_id: this.accommodation.property_id,
          daily_rates: data.daily_rates,
          accommodation_id: this.accommodation.id,
          rateplan_id:
            this.formComponent.firstStepComponent.form.value.rateplan
              .rateplan_id,
          addons: first(
            this.formComponent.firstStepRequest?.accommodations,
          )?.addons?.map((addon) => ({
            type: addon.type,
            total: addon.total,
            periods: [{ date_from: firstDay, date_to: firstDay }],
          })),
          discount_type_id: data?.discount_type_id,
          discount_value: data?.discount_value,
        },
      }),
    );
  }

  private setDefaultInvoiceLayoutSource() {
    this.defaultInvoiceLayoutId$ = this.store.pipe(
      select(
        InvoicesLayoutsStoreSelectors.selectDefaultPropertyLayout(
          this.accommodation.property_id,
        ),
      ),
    );
  }
}
