import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PropertyUsersService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`property/${propertyId}/users`);
  }

  detach(propertyId: number, userId: number) {
    return this.http.post(
      `property/${propertyId}/acl/user/${userId}/detach`,
      {},
    );
  }

  attach(propertyId: number, userId: number, roleId: number) {
    return this.http.post(`property/${propertyId}/acl/user/${userId}/attach`, {
      property_role_id: roleId,
    });
  }
}
