import { generateSearchQuery } from '../core/helpers/params-generator';
import { SearchParams } from '../models/objects/search-params';

export class StringUrl {
  private hostName: string;

  private params: SearchParams = {};

  constructor(url: string) {
    const [hostName, stringParams] = url.split('?');

    this.hostName = hostName;

    stringParams?.split('&')?.forEach((stringParam) => {
      const [key, value] = stringParam.split('=');
      this.setParam(key, value);
    });
  }

  setParam(key: string, value: string | string[]) {
    const isArray = key.includes('[]');

    if (isArray) {
      key = key.replace('[]', '');
      value = [...((this.params[key] as string[]) || []), value as string].map(
        decodeURIComponent,
      );
    } else {
      value = decodeURIComponent(value as string);
    }

    this.params = { ...this.params, [key]: value };
  }

  /**
   * @override
   */
  toString() {
    return `${this.hostName}?${generateSearchQuery(this.params)}`;
  }
}
