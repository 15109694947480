import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as InvoicesStoreActions from '@app/root-store/invoices-store/actions';
import * as InvoicesStoreSelectors from '@app/root-store/invoices-store/selectors';
import { RootState } from '@app/root-store/root-state';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isNil, omitBy } from 'lodash';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs/internal/Subscription';
import { handleFormSubmit } from '../../core/helpers/handleFormSubmit';
import { EmailValidator } from '../../core/validators/email.validators';

import {
  InvoiceModalData,
  ModalInvoiceSendEmailForm,
  SendMailInvoiceRequest,
} from '../../models';
import {
  EmailTemplatesCustomStoreActions,
  EmailTemplatesCustomStoreSelectors,
} from '../../root-store/email-templates-custom-store';

@Component({
  selector: 'by-modal-invoice-send-email',
  templateUrl: './modal-invoice-send-email.component.html',
  styleUrls: ['./modal-invoice-send-email.component.scss'],
})
export class ModalInvoiceSendEmailComponent implements OnInit, OnDestroy {
  readonly nzModalData: Partial<ModalInvoiceSendEmailComponent> =
    inject(NZ_MODAL_DATA);

  set show(show: boolean) {
    this.showModalSendEmail = show;
    if (show && this.dataInvoice && this.dataInvoice.invoice_id) {
      this.onModalSendShow(this.dataInvoice);
    }
  }

  set data(data: InvoiceModalData) {
    if (!data) {
      this.dataInvoice = null;
      return;
    }
    this.dataInvoice = data;
    if (
      this.showModalSendEmail &&
      this.dataInvoice &&
      this.dataInvoice.invoice_id
    ) {
      this.onModalSendShow(this.dataInvoice);
    }
  }

  isReminder: boolean = this.nzModalData.isReminder || false;

  propertyId: number = this.nzModalData.propertyId;

  invoicesTemplateData: {
    email_to: string[];
    message: string;
    pdf: boolean;
    subject: string;
    xml: boolean;
  } = {
    email_to: [],
    message: '',
    pdf: true,
    subject: '',
    xml: true,
  };

  attachOptions = [
    {
      label: this.translate.instant('invoice_pdf'),
      value: 'pdf',
      checked: true,
      disabled: true,
    },
    {
      label: this.translate.instant('invoice_xml'),
      value: 'xml',
      checked: true,
    },
  ];

  invoicesModalData: InvoiceModalData = <InvoiceModalData>{};

  showModalSendEmail = false;

  dataInvoice: InvoiceModalData = null;

  customTemplates$ = this._rootStore.pipe(
    select(
      EmailTemplatesCustomStoreSelectors.selectEmailTemplatesCustomByType(
        'email',
      ),
    ),
  );

  form = this.formBuilder.group({
    email_to: this.formBuilder.control<string[]>([]),
    customer_ids: this.formBuilder.control<number[]>(null),
    customer_email_flag: this.formBuilder.control(false),
    templateSystemType: this.formBuilder.control<'system' | 'custom'>('system'),
    template_custom_id: this.formBuilder.control<number>(null),
    pdf: this.formBuilder.control(true),
    xml: this.formBuilder.control(false),
  });

  customerEmail: string;

  customerId: number;

  private subs = new Subscription();

  constructor(
    private _rootStore: Store<RootState>,
    private translate: TranslateService,
    private moldal: NzModalRef,
    private formBuilder: FormBuilder,
  ) {
    this.show = this.nzModalData.show;
    this.data = this.nzModalData.data;
  }

  ngOnInit() {
    this._rootStore.dispatch(
      new EmailTemplatesCustomStoreActions.LoadRequestAction({
        propertyId: this.propertyId,
      }),
    );

    this.subs.add(
      this.form.controls.templateSystemType.valueChanges.subscribe((value) => {
        this.form.controls.template_custom_id.removeValidators(
          Validators.required,
        );

        if (value === 'custom') {
          this.form.controls.template_custom_id.addValidators(
            Validators.required,
          );
        }

        this.form.controls.template_custom_id.updateValueAndValidity();
      }),
    );

    this.subs.add(
      this._rootStore
        .pipe(select(InvoicesStoreSelectors.selectEmailTemplate))
        .subscribe((data) => {
          if (!data) {
            return;
          }
          const { email_to, pdf, xml, customer_id, ...dataValue } = data;

          this.customerEmail = email_to;

          this.customerId = customer_id;

          this.form.patchValue({
            ...dataValue,
            customer_ids: this.customerEmail ? [this.customerId] : null,
            customer_email_flag: !!this.customerEmail,
          });

          let email_to_array = [];

          if (email_to) {
            email_to_array = [email_to];
          }

          this.invoicesTemplateData = {
            ...data,
            email_to: email_to_array,
          };
        }),
    );

    this.manageDestinationsValidation(this.form.value);

    this.subs.add(
      this.form.valueChanges.subscribe((value) => {
        this.manageDestinationsValidation(value);
      }),
    );

    this.subs.add(
      this.form
        .get('customer_email_flag')
        .valueChanges.subscribe((customer_email_flag) => {
          this.form.patchValue(
            {
              customer_ids: customer_email_flag ? [this.customerId] : null,
            },
            { emitEvent: false },
          );
        }),
    );
  }

  onModalSendShow(data: InvoiceModalData) {
    const { invoice_id } = data;
    this.invoicesModalData = data;
    this.invoicesTemplateData = {
      email_to: [],
      message: '',
      pdf: true,
      subject: '',
      xml: true,
    };
    this._rootStore.dispatch(
      new InvoicesStoreActions.LoadEmailTemplateRequestAction({
        invoice_id,
      }),
    );
    this.showModalSendEmail = true;
  }

  closeModal() {
    this.moldal.close();
    this.showModalSendEmail = false;
    this.invoicesModalData = <InvoiceModalData>{};
  }

  sendEmail() {
    handleFormSubmit([this.form], () => {
      if (this.dataInvoice && this.dataInvoice.invoice_id) {
        const {
          email_to: email,
          xml,
          pdf,
          customer_ids,
          customer_email_flag,
          template_custom_id,
          templateSystemType,
        } = this.form.value;
        const { invoice_id } = this.dataInvoice;

        const request: SendMailInvoiceRequest = <SendMailInvoiceRequest>omitBy(
          {
            email: email?.length ? email : null,
            customer_ids:
              customer_email_flag && customer_ids ? customer_ids : null,
            template_custom_id:
              templateSystemType === 'custom' ? template_custom_id : null,
            xml,
            pdf,
            invoice_id,
            is_reminder: this.isReminder,
          },
          isNil,
        );
        this._rootStore.dispatch(
          new InvoicesStoreActions.SendEmailRequestAction({
            request,
          }),
        );
      }
      this.closeModal();
    });
  }

  manageDestinationsValidation(
    value: Partial<ModalInvoiceSendEmailForm>,
  ): void {
    const { customer_email_flag } = value;

    const {
      email_to: emailToControl,
      customer_ids: customerIdsControl,
      customer_email_flag: customerEmailFlagControl,
    } = this.form?.controls;

    emailToControl.clearValidators();

    emailToControl.addValidators([EmailValidator.emailArrayValidator]);

    customerIdsControl.clearValidators();

    customerEmailFlagControl.clearValidators();

    if (!customer_email_flag) {
      emailToControl.addValidators([
        Validators.required,
        EmailValidator.emailArrayValidator,
      ]);
    }

    emailToControl.updateValueAndValidity({ emitEvent: false });

    customerIdsControl.updateValueAndValidity({ emitEvent: false });

    customerEmailFlagControl.updateValueAndValidity({ emitEvent: false });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
