import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { CartType } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectCartTypeState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('cartTypes');

export const selectAllCartTypesItems: (
  state: object,
) => CartType[] = featureAdapter.getSelectors(selectCartTypeState).selectAll;

export const selectCartTypeById = (id: string) =>
  createSelector(
    selectAllCartTypesItems,
    (cartTypes: CartType[]) => {
      if (cartTypes) {
        return cartTypes.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectCartTypesError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectCartTypeState,
  getError,
);

export const selectCartTypesIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectCartTypeState,
  getIsLoading,
);
