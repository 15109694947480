import { Media, MediaType } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { get } from 'lodash';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getMedias = (
  state: State,
): {
  [type: string]: {
    [type_id: number]: Media[];
  };
} => state.data;

export const getType =
  (type: MediaType) =>
  (state: State): any =>
    state.data[type];

export const getAllTypeId =
  (type: MediaType, typeId: number) =>
  (state: State): any =>
    get(state.data, `[${type}][${typeId}]`, []) as Media[];

export const getGenre =
  (type: MediaType, typeId: number, genre: any) =>
  (state: State): any =>
    (get(state.data, `[${type}][${typeId}]`, []) as Media[]).find(
      (media) => media.genre === genre,
    );

export const getAllGenre =
  (type: MediaType, typeId: number, genre: any) =>
  (state: State): any =>
    (get(state.data, `[${type}][${typeId}]`, []) as Media[]).filter(
      (media) => media.genre === genre,
    );

export const selectMediaState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('medias');

export const selectMediasError: MemoizedSelector<object, any> = createSelector(
  selectMediaState,
  getError,
);

export const selectMediasIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectMediaState, getIsLoading);

export const selectMediasType = (
  type: MediaType,
): MemoizedSelector<object, Record<number, Media[]>> =>
  createSelector(selectMediaState, (state) => getMedias(state)[type]);

export const selectMediasGenre = (
  type: MediaType,
  typeId: number,
  genre: any,
) =>
  createSelector(selectMediaState, (state) => {
    return getGenre(type, typeId, genre)(state);
  });

export const selectAllMediasGenre = (
  type: MediaType,
  typeId: number,
  genre: any,
) =>
  createSelector(selectMediaState, (state) => {
    return getAllGenre(type, typeId, genre)(state);
  });

export const selectAllMediasTypeId = (type: MediaType, typeId: number) =>
  createSelector(selectMediaState, (state) => {
    return getAllTypeId(type, typeId)(state);
  });

export const selectMediasAll = createSelector(selectMediaState, getMedias);
