import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PrivacyModuleService {
  constructor(private http: HttpClient) {}

  loadPrivacyPolicy() {
    return this.http
      .get('checkinonline/privacy_policy', {
        responseType: 'text',
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          e = { ...e, error: JSON.parse(e.error) };
          return throwError(e);
        }),
      );
  }
}
