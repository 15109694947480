import {
  CreditCardDepositRequest,
  CreditCardFormValue,
  CreditCardRequest,
} from '../models';

export const creditCardValueToApi = (
  formValue: CreditCardFormValue,
): CreditCardRequest => {
  const {
    credit_card_holder,
    credit_card_code,
    credit_card_number,
    credit_card_expire_date,
    credit_card_type,
  } = formValue;

  const creditCardExpireDate = credit_card_expire_date
    .replace(/\s/g, '')
    .split('/');

  return {
    credit_card_type,
    credit_card_type_id: credit_card_type,
    credit_card_holder,
    credit_card_number: credit_card_number.replace(/\s/g, ''),
    credit_card_expire_date:
      creditCardExpireDate[0] + '-' + creditCardExpireDate[1],
    credit_card_code: credit_card_code.length ? credit_card_code : undefined,
  };
};

export const creditCardDepositValueToApi = (
  formValue: CreditCardFormValue,
): CreditCardDepositRequest => {
  const {
    credit_card_holder: deposit_credit_card_holder,
    credit_card_code,
    credit_card_number,
    credit_card_expire_date,
    credit_card_type: deposit_credit_card_type,
  } = formValue;

  const creditCardExpireDate = credit_card_expire_date
    .replace(/\s/g, '')
    .split('/');

  return {
    deposit_credit_card_type,
    deposit_credit_card_type_id: deposit_credit_card_type,
    deposit_credit_card_holder,
    deposit_credit_card_number: credit_card_number.replace(/\s/g, ''),
    deposit_credit_card_expire_date:
      creditCardExpireDate[0] + '-' + creditCardExpireDate[1],
    deposit_credit_card_code: credit_card_code.length
      ? credit_card_code
      : undefined,
  };
};
