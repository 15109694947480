import { formatDate } from './format-date';

export function formatEventLabel(params: {
  title: string;
  date_from: string;
  date_to: string;
}): string {
  const { title, date_from, date_to } = params;

  return `${title} (${formatDate(
    new Date(date_from),
  )} - ${formatDate(new Date(date_to))})`;
}
