import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateUpdateMediaRequest } from '@app/models/requests/create-update-media-request';
import { intersectionWith } from 'lodash';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { DateFormatterService } from '../core/services/date-formatter.service';

import {
  IInvoiceLayout,
  IInvoiceLayoutCreateUpdate,
} from './../models/objects/invoices-layouts';

@Injectable({
  providedIn: 'root',
})
export class InvoicesLayoutsService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(params: {
    property_id?: number[];
    invoice_module?: number;
    fp_module?: number;
  }) {
    return this.http
      .get(`invoices/layouts?${generateSearchQuery(params)}`)
      .pipe(
        map((response: IResponseSuccess<IInvoiceLayout[]>) => {
          let { data } = response;

          if (params?.property_id?.length) {
            data = (data || []).filter(({ properties_layouts }) => {
              const commonValues = intersectionWith(
                properties_layouts,
                params?.property_id,
                (a, b) => a.property_id === b,
              );

              return !!commonValues?.length;
            });
          }

          return { ...response, data };
        }),
      );
  }

  create(data: IInvoiceLayoutCreateUpdate) {
    const { fileList, ...newData } = data;
    return this.http.post(`invoices/layout`, {
      ...newData,
    });
  }

  delete(invoice_layout_id: number) {
    return this.http.delete(`invoices/layout/${invoice_layout_id}`);
  }

  update(invoiceLayoutId: number, data: IInvoiceLayoutCreateUpdate) {
    return this.http.put(`invoices/layout/${invoiceLayoutId}`, data);
  }

  createUpdateMedia(data: CreateUpdateMediaRequest) {
    return this.http.post(`media/${data.type}/${data.type_id}`, data.file);
  }

  loadMedia(invoiceLayoutId: number) {
    return this.http.get(`media/invoicelayout/${invoiceLayoutId}?all_size=1`);
  }

  deleteMedia(invoiceLayoutId: number, mediaId: number) {
    return this.http.delete(
      `media/invoicelayout/${invoiceLayoutId}/${mediaId}`,
    );
  }

  attachProperties(invoiceLayoutId: number, properties: number[]) {
    return forkJoin(
      ...properties.map((propertyId) => {
        return this.http.post('invoices/layout/attach_property', {
          invoice_layout_id: invoiceLayoutId,
          property_id: propertyId,
        });
      }),
    );
  }

  detachProperties(invoiceLayoutId: number, properties: number[]) {
    return forkJoin(
      ...properties.map((propertyId) => {
        return this.http.post(`invoices/layout/detach_property`, {
          invoice_layout_id: invoiceLayoutId,
          property_id: propertyId,
        });
      }),
    );
  }

  loadDetails(invoiceLayoutId: number) {
    const now = this.dateFormatter.toServerFormat(new Date());
    return this.http.get(`invoices/layout/${invoiceLayoutId}?now=${now}`);
  }

  loadTaxSystem() {
    return this.http.get(`general/tax_system`);
  }

  deleteSectional(invoice_layout_id, invoice_layout_sectional_id) {
    return this.http.delete(
      `invoices/layout/${invoice_layout_id}/sectional/${invoice_layout_sectional_id}`,
    );
  }

  addSectional(invoice_layout_id, data) {
    return this.http.post(`invoices/layout/${invoice_layout_id}/sectional`, {
      sectionals: data,
    });
  }

  setDefaultPropertyInvoiceLayout(payload: {
    property_id: number[];
    invoice_layout_id: number;
  }) {
    return this.http.post('invoices/layout/set_property_default', payload);
  }
}
