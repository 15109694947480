import { format } from 'date-fns';
import { Nullable } from '../models';

export const dateToServerFormat = (
  date: Date | string,
  formatStr = 'yyyy-MM-dd',
): Nullable<string> => {
  if (!date) {
    return null;
  }
  return format(new Date(date), formatStr);
};
