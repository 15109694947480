import { createAction, props } from '@ngrx/store';

import { EstimateAddonPriceRequest } from '../../models';

export const loadRequest = createAction(
  '[Estimate Addon Price] Load Request',
  props<EstimateAddonPriceRequest>(),
);

export const loadSuccess = createAction(
  '[Estimate Addon Price] Load Success',
  props<{
    total: number;
    unit_price: number;
    addonId: number;
    billId?: number;
    quantity: number;
  }>(),
);

export const loadFailure = createAction(
  '[Estimate Addon Price] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Estimate Addon Price] Reset State');
