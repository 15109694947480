export function applyDiscountByType(
  price: number,
  discountTypeId: number,
  discountValue: number,
) {
  switch (discountTypeId) {
    case 2:
      return +price + discountValue;
    case 3:
      return +price + (discountValue / 100) * +price;
    case 4: {
      let newPrice: number;
      if (+price - discountValue < 0) {
        newPrice = 0;
      } else {
        newPrice = +price - discountValue;
      }
      return newPrice;
    }
    case 5: {
      let newPrice: number;
      if (+price - (discountValue / 100) * +price < 0) {
        newPrice = 0;
      } else {
        newPrice = +price - (discountValue / 100) * +price;
      }
      return newPrice;
    }
  }
}
