<by-page-header [title]="pageTitle | translate | upperfirst"> </by-page-header>
<div class="by-mb-2">
  {{
    'booking_messages.' + type + '.details_header_message'
      | translate
      | upperfirst
  }}.
</div>
<form nz-form nzLayout="vertical" [formGroup]="form">
  <div nz-row [nzGutter]="20">
    <div nz-col [nzXs]="24" [nzSm]="24" *ngIf="showXmlSelect">
      <nz-form-item>
        <nz-form-label>
          {{ 'select_booking_account' | translate | upperfirst }}
        </nz-form-label>
        <nz-form-control style="max-width: 350px">
          <by-multi-select
            [options]="xmlOptions"
            [noEmpty]="true"
            formControlName="property_xml_id"
            [nzAllowClear]="false"
            mode="default"
          ></by-multi-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label>
          {{
            (type === 'mobile_rate' ? 'platform' : 'country')
              | translate
              | upperfirst
          }}
        </nz-form-label>
        <nz-form-control
          style="max-width: 350px"
          [nzErrorTip]="
            'validation_form.' +
              (form.get('target_channel').errors | keys | head) | translate
          "
        >
          <by-multi-select
            *ngIf="type === 'geo_rate'"
            [options]="targetOptions"
            formControlName="target_channel"
            [value]="'target_channel'"
            [label]="'target_label'"
            [nzAllowClear]="false"
            [mode]="mode"
          ></by-multi-select>
          <nz-select
            *ngIf="type === 'mobile_rate'"
            formControlName="target_channel"
          >
            <ng-container *ngFor="let target of targetOptions">
              <nz-option
                [nzValue]="target.target_channel"
                [nzLabel]="target.target_label"
              ></nz-option>
            </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col *ngIf="targetChannel === 'app'">
      <div class="target-message-container">
        <div class="arrow-message"></div>
        <div class="target-message by-flex">
          <i class="fal fa-lightbulb-on by-mr-5 by-mt-5"></i>
          <div
            [innerHtml]="
              'booking_messages.geo_rate.target_message'
                | translate
                | upperfirst
            "
          ></div>
        </div>
      </div>
    </div>
    <div nz-col [nzSm]="24">
      <nz-form-item>
        <nz-form-label>
          <span class="by-mr-5">
            {{ 'discount' | translate | upperfirst }}
          </span>
        </nz-form-label>
        <nz-form-control
          [nzErrorTip]="
            'validation_form.' + (form.get('discount').errors | keys | head)
              | translate : { param: 'discount' | translate, min: 10, max: 100 }
          "
        >
          <nz-input-number
            formControlName="discount"
            [nzMin]="10"
            [nzMax]="80"
            [nzStep]="1"
            [nzFormatter]="formatterPercent"
            [nzParser]="parserPercent"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSm]="24">
      <div class="by-flex by-mb-10">
        <i class="fal fa-info-circle by-fs-20 by-mr-10 color--orange"></i>
        <div class="by-flex by-flex-direction--column">
          <div class="by-mb-2">
            <span class="bolder by-mr-10"
              >{{
                'booking_messages.cumulative_discount_title'
                  | translate
                  | upperfirst
              }}:</span
            >
            <span>{{
              'booking_messages.' + type + '.cumulative_discount_message'
                | translate
                | upperfirst
            }}</span>
          </div>
          <div class="by-mb-2">
            <span class="bolder by-mr-10"
              >{{
                'booking_messages.not_cumulative_discount_title'
                  | translate
                  | upperfirst
              }}:</span
            >
            <span>{{
              'booking_messages.' + type + '.not_cumulative_discount_message'
                | translate
                | upperfirst
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div nz-row>
    <div nz-col [nzXs]="24">
      <nz-form-item style="margin-bottom: 0">
        <nz-form-control>
          <by-question
            [isDisabled]="promotion"
            [formControl]="showExludedDates"
            [label]="'block_dates_question' | translate"
          ></by-question>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row>
    <div nz-col>
      <div *ngIf="showExludedDates.value">
        <div class="booking-promotion-label">
          {{ 'exluded_dates_message' | translate | upperfirst }}
        </div>
        <div formArrayName="excluded_dates">
          <div
            *ngFor="
              let date of excludedDatesControl.controls;
              index as i;
              let last = last
            "
          >
            <div class="by-flex by-flex-middle by-mt-10">
              <nz-form-control class="range-control">
                <nz-range-picker
                  byDisabledDateFixer
                  byRangePicker
                  [nzDisabled]="promotion"
                  [nzDisabledDate]="disableDate"
                  [formControlName]="i"
                  nzFormat="dd/MM/yyyy"
                ></nz-range-picker>
              </nz-form-control>

              <ng-container *ngIf="!promotion">
                <button
                  nzType="primary"
                  nzDanger
                  nzGhost
                  nz-button
                  nzShape="circle"
                  (click)="onDeleteExludedDates(i)"
                  class="by-ml-10 ant-btn-icon-only"
                >
                  <i class="fas fa-trash-alt pointer"></i>
                </button>

                <button
                  *ngIf="last"
                  nzType="primary"
                  nzGhost
                  nz-button
                  nzShape="circle ant-btn-icon-only"
                  (click)="addExludendDateRange(null)"
                  class="by-ml-10"
                >
                  <i class="fas fa-plus color--azure"></i>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <nz-alert
          class="by-mt-10"
          *ngIf="excludedDatesControl.errors"
          nzType="error"
          [nzMessage]="
            'validation_form.' + (excludedDatesControl.errors | keys | head)
              | translate : { limit: 30, item: 'days' | translate }
          "
          nzShowIcon
        ></nz-alert>
      </div>
    </div>
  </div>
</form>
