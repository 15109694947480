import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectEmailTemplatePreviewState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('emailTemplatePreview');

export const selectPreview = createSelector(
  selectEmailTemplatePreviewState,
  (state) => state.preview,
);

export const selectTemplateIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectEmailTemplatePreviewState, getIsLoading);
