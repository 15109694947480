import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { FiscalPrinterLoadingService } from '../../../services/fiscal-printer-loading.service';
import {
  Command,
  FiscalPrinterCommandService,
} from '../fiscal-printer-command.service';
import { FiscalPrinterDriver } from '../fiscal-printer-driver';
import { FiscalPrinterError } from '../fiscal-printer-error';

import { RhinoFiscalPrinterStatusParser } from './rhino-fiscal-printer-status-parser';
import { RhinoFiscalPrinterRTStatusParser } from './rhino-fiscal-printer-rt-status-parser';
import { FiscalPrinterCheckMethods } from '../../../models';
import {
  RhinoFiscalPrinterStatusResponse,
  RhinoFiscalPrinterPrintResponse,
} from './rhino-fiscal-printer-response';

@Injectable({
  providedIn: 'root',
})
export class RhinoFiscalPrinterDriverService implements FiscalPrinterDriver {
  disableClosureNumber = true;

  disableEmitPrintedReceipt = true;

  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
    private loading: FiscalPrinterLoadingService,
    private commandService: FiscalPrinterCommandService,
  ) {}

  getCheckMethodsToSkip(): (keyof FiscalPrinterCheckMethods)[] {
    return ['inactivePeriodCheck', 'printerCheck'];
  }

  async getStatus(printerId: number) {
    return this.commandService
      .getStatusCommand(printerId)
      .pipe(
        switchMap((command) => {
          return this.execute<RhinoFiscalPrinterStatusResponse>(
            command,
            true,
          ).pipe(
            map((printerResponse) => {
              return new RhinoFiscalPrinterStatusParser().parse(
                printerResponse,
              );
            }),
          );
        }),
      )
      .toPromise();
  }

  async getRTStatus(printerId: number) {
    return this.commandService
      .getStatusCommand(printerId)
      .pipe(
        switchMap((command) => {
          return this.execute<RhinoFiscalPrinterStatusResponse>(
            command,
            true,
          ).pipe(
            map((printerResponse) => {
              return new RhinoFiscalPrinterRTStatusParser().parse(
                printerResponse,
              );
            }),
          );
        }),
      )
      .toPromise();
  }

  async printReceipt(command: Command) {
    return this.execute<RhinoFiscalPrinterPrintResponse>(command)
      .pipe(
        map(({ original, progressive, date, _id }) => {
          return {
            description: original,
            number: progressive,
            clousure_number: 0,
            external_id: _id,
            document_date: this.dateFormatter.toServerFormat(new Date(date)),
          };
        }),
        catchError((error: FiscalPrinterError) => {
          return throwError({
            status: 'ERROR',
            description: error.message,
            notification: error.notification,
          });
        }),
      )
      .toPromise();
  }

  async printReversalReceiptFiscal(printerId: number, invoiceId: number) {
    return this.commandService
      .getReversalCommand(printerId, invoiceId)
      .pipe(
        switchMap((command) => {
          return this.execute<RhinoFiscalPrinterPrintResponse>(command).pipe(
            map(({ original, progressive, _id }) => {
              return {
                description: original,
                number: progressive,
                clousure_number: 0,
                external_id: _id,
              };
            }),
            catchError((error: FiscalPrinterError) => {
              return throwError({
                status: 'ERROR',
                description: error.message,
                notification: error.notification,
              });
            }),
          );
        }),
      )
      .toPromise();
  }

  async printCustomCodeXml(command: Command) {
    return this.execute(command).toPromise();
  }

  /**
   * @description COMMANDS EXECUTION FUNCTION
   */
  private execute<R>(command: Command, disableLoading = false) {
    if (!disableLoading) {
      this.loading.start();
    }

    const {
      xml: [xmlCommand],
      ip,
    } = command;

    return this.http.post(ip, JSON.parse(xmlCommand)).pipe(
      map((data: R) => {
        // Success flow

        if (!disableLoading) {
          this.loading.stop();
        }

        const original = JSON.stringify(data);

        return { ...data, original };
      }),
      catchError((httpError: HttpErrorResponse) => {
        // Failure flow

        if (!disableLoading) {
          this.loading.stop();
        }

        return throwError(
          () => new FiscalPrinterError(JSON.stringify(httpError)),
        );
      }),
    );
  }
}
