import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { generateParamArray } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  CreateGuestCardRequest,
  DeleteGuestCardRequest,
  GuestCard,
  GuestCardLoadRequestAction,
  GuestCardsLoadRequestAction,
  GuestCardType,
  UpdateGuestCardRequest,
} from '../models';
@Injectable({
  providedIn: 'root',
})
export class GuestCardService {
  constructor(private http: HttpClient) {}

  load(data: GuestCardsLoadRequestAction) {
    return this.http.get(
      `reservations/${data.reservation_id}/guest_cards?${generateParamArray(
        'roomreservation_id',
        data.roomreservation_id,
      )}`,
    ) as Observable<IResponseSuccess<GuestCard[]>>;
  }

  loadDetails(data: GuestCardLoadRequestAction) {
    return (
      this.http.get(
        `reservations/${data.reservation_id}/guest_card/${data.reservation_guest_card_id}`,
      ) as Observable<IResponseSuccess<GuestCard[]>>
    ).pipe(
      map((response) => ({
        ...response,
        data: response?.data?.[0],
      })),
    ) as Observable<IResponseSuccess<GuestCard>>;
  }
  loadCardTypes(data: { externalServiceId: number; propertyId: number }) {
    return this.http.get(
      `external_services/guest_cards_type/${data.externalServiceId}/${data.propertyId}`,
    ) as Observable<IResponseSuccess<GuestCardType[]>>;
  }

  create(data: CreateGuestCardRequest) {
    return this.http.post(
      `reservations/${data.reservation_id}/guest_card`,
      data,
    ) as Observable<IResponseSuccess<GuestCard[]>>;
  }

  update(data: UpdateGuestCardRequest) {
    return (
      this.http.put(
        `reservations/${data.reservation_id}/guest_card/${data.reservation_guest_card_id}`,
        data,
      ) as Observable<IResponseSuccess<GuestCard[]>>
    ).pipe(
      map((response) => ({
        ...response,
        data: response.data[0],
      })),
    ) as Observable<IResponseSuccess<GuestCard>>;
  }

  delete(data: DeleteGuestCardRequest) {
    return this.http.delete(
      `reservations/${data?.reservation_id}/guest_card/${data?.id}`,
    ) as Observable<IResponseSuccess<number>>;
  }
}
