import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { LoadLogsRequest, XmlLog } from '../models';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  constructor(private http: HttpClient) {}

  load(request: LoadLogsRequest) {
    const { property_id, page } = request;

    const queryString = request.queryString
      ? this.formatQueryString(request.queryString)
      : '';

    return this.http.get(
      `properties/logs?v=2&${generateParamArray(
        'property_id',
        property_id,
      )}&page=${page || 1}&${queryString}`,
    );
  }

  loadDetails(
    properties: number[],
    operationCode: string,
    accommodationId: number,
    queryString: string,
  ) {
    queryString = queryString ? this.formatQueryString(queryString) : '';

    return this.http.get(
      `properties/logs/details?${generateParamArray(
        'property_id',
        properties,
      )}&operation_code=${operationCode}&accommodations[]=${accommodationId}&${queryString}`,
    );
  }

  loadXml(logId: string[], properties: number[]) {
    return this.http.post<IResponseSuccess<XmlLog[]>>(
      `properties/logs/details/xml`,
      { property_id: properties, logId },
    );
  }

  private formatQueryString(queryString: string) {
    return queryString
      .replace('?', '')
      .replace('rateplan_id', 'rateplans')
      .replace('channel_id', 'channels');
  }
}
