<div class="by-attachment-viewer-paging-area">
  <button
    nz-button
    class="by-mr-5 ant-btn-icon-only"
    nz-tooltip
    [nzTooltipTitle]="'go_to_the_first_page' | translate | upperfirst"
    nzTooltipPlacement="bottomLeft"
    (click)="onFirstPage()"
    [disabled]="disabledFirstPage"
  >
    <i class="far fa-arrow-to-left"></i>
  </button>
  <button
    nz-button
    class="by-mr-5 ant-btn-icon-only"
    nz-tooltip
    [nzTooltipTitle]="'previous_page' | translate | upperfirst"
    nzTooltipPlacement="bottomLeft"
    id="previous"
  >
    <i class="far fa-arrow-left"></i>
  </button>
  <nz-input-number
    [formControl]="pageControl"
    [nzMin]="1"
    [nzMax]="totalPages"
    class="by-mr-5"
  ></nz-input-number>
  <span id="numPages" class="by-mr-5 by-fs-15"></span>
  <button
    nz-button
    class="by-mr-5 ant-btn-icon-only"
    nz-tooltip
    [nzTooltipTitle]="'next_page' | translate | upperfirst"
    nzTooltipPlacement="bottomLeft"
    id="next"
  >
    <i class="far fa-arrow-right"></i>
  </button>
  <button
    nz-button
    class="by-mr-5 ant-btn-icon-only"
    nz-tooltip
    [nzTooltipTitle]="'go_to_the_last_page' | translate | upperfirst"
    nzTooltipPlacement="bottomLeft"
    (click)="onLastPage()"
    [disabled]="disabledLastPage"
  >
    <i class="far fa-arrow-to-right"></i>
  </button>
</div>
