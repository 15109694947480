import {
  DGFE_MPD_Status,
  FiscalPrinterStatus,
  OperationalStatus,
  ReceiptDocumentStatus,
} from '../fiscal-printer-status';

import { RchFiscalPrinterStatusResponse } from './rch-fiscal-printer-status-response';

const receiptDocumentStatusMapping = (code: string): ReceiptDocumentStatus => {
  const map: { [code: string]: ReceiptDocumentStatus } = {
    0: 'no_operation_in_progress',
    1: 'fiscal_document_open',
    2: 'payment_in_progress',
    3: 'non_tax_open',
    4: 'fidelity_mode',
  };

  return map[code] || 'wrong_answer';
};

const operationalStatusMapping = (code: string): OperationalStatus => {
  const map: { [code: string]: OperationalStatus } = {
    REG: 'registration_status',
    X: 'x_status',
    Z: 'z_status',
  };

  return map[code] || 'wrong_answer';
};

const dgfeMpdStatusMapping = (code: string): DGFE_MPD_Status => {
  const map: { [code: string]: DGFE_MPD_Status } = {
    0: 'ok',
    E31: 'next_to_exhaustion',
    E10: 'to_format',
  };

  return map[code] || 'wrong_answer';
};

export class RchFiscalPrinterStatusParser {
  parse({
    Service: { Request, ECRStatus },
  }: RchFiscalPrinterStatusResponse): FiscalPrinterStatus {
    const ecrStatus = ECRStatus || Request.ECRStatus;

    const operationalStatus =
      Request.busy === '1' ? 'busy' : operationalStatusMapping(ecrStatus?.mode);

    return {
      result: Request.errorCode === '0',
      printerStatus: Request.paperEnd === '1' ? 'paper_running_out' : 'ok',
      drawerState: Request.coverOpen === '1' ? 'open' : 'closed',
      receiptDocumentStatus: receiptDocumentStatusMapping(ecrStatus?.idleState),
      operationalStatus,
      dgfeMpdStatus: dgfeMpdStatusMapping(Request.errorCode),
    };
  }
}
