import { Router } from '@angular/router';

export function upsertQueryParams(
  router: Router,
  queryParams: Record<string, any>,
) {
  router.navigate([location.pathname], {
    queryParams,
    queryParamsHandling: 'merge',
    replaceUrl: true,
  });
}

export function setQueryParams(
  router: Router,
  queryParams: Record<string, any>,
) {
  router.navigate([location.pathname], {
    queryParams,
    queryParamsHandling: '',
    replaceUrl: true,
  });
}

export function setAllQueryParams(
  router: Router,
  queryParams: Record<string, any>,
) {
  setQueryParams(router, queryParams);
}
