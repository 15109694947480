import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AutomaticPaymentSummary,
  AutomaticPaymentSummaryTableauRequest,
} from '../../../models';

export default createActionGroup({
  source: 'Automatic Payments Summary',
  events: {
    'Load From Tableau Request': props<{
      data: AutomaticPaymentSummaryTableauRequest;
    }>(),
    'Load From Booking Engine Request': props<{
      booking_engine_cart_id: number;
    }>(),
    'Load From Price Quotation Request': props<{
      reservation_quote_id: number;
      reservation_quote_option_id: number;
    }>(),
    'Load Success': props<{
      automaticPaymentsSummary: AutomaticPaymentSummary[];
    }>(),
    'Load Failure': props<{ error: any }>(),

    'Reset State': emptyProps(),
  },
});
