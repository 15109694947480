import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { EstimateAddonPriceService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class EstimateAddonPriceStoreEffects {
  constructor(
    private dataService: EstimateAddonPriceService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ type, ...request }) => {
        const { addon_id: addonId, billId } = request;
        return this.dataService.load(request).pipe(
          map(({ data: { total, unit_price, quantity } }) =>
            fromActions.loadSuccess({
              total,
              unit_price,
              addonId,
              quantity,
              billId,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );
}
