import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  CreateFiscalPrinterRequest,
  UpdateFiscalPrinterRequest,
} from '../models';
import { FiscalPrinterRTStatus } from '../use-cases/fiscal-printers/fiscal-printer-rt-status';
import { FiscalPrinterStatus } from '../use-cases/fiscal-printers/fiscal-printer-status';
import { PrinterLog } from '../models/objects/fiscal-printer-log';

@Injectable({
  providedIn: 'root',
})
export class FiscalPrintersService {
  constructor(private http: HttpClient) {}

  load(invoiceLayoutId: number, trashed: boolean, page: number) {
    return this.http.get(
      `settingreceipt/fiscalprinters/${invoiceLayoutId}?with_trashed=${
        trashed ? 1 : 0
      }&page=${page}&order='created_at:DESC'`,
    );
  }

  getLogChanges(id: string, page: number) {
    return this.http.get<IResponseSuccess<PrinterLog[]>>(
      `fiscalprinter/${id}/history?page=${page}`,
    );
  }

  loadDetails(fiscalPrinterId: number) {
    return this.http
      .get(`settingreceipt/fiscalprinter/${fiscalPrinterId}`)
      .pipe(
        switchMap((fiscalPrinterResponse: IResponseSuccess) => {
          return this.loadDepartments(fiscalPrinterResponse.data[0].id).pipe(
            map((departmentsResponse: IResponseSuccess) => {
              return {
                ...fiscalPrinterResponse.data[0],
                departments: departmentsResponse.data,
              };
            }),
          );
        }),
      );
  }

  delete(fiscalPrinterId: number, force_delete: boolean) {
    const force_delete_string = force_delete ? 'force_delete=1' : '';

    return this.http.delete<IResponseSuccess>(
      `settingreceipt/fiscalprinter/${fiscalPrinterId}/?${force_delete_string}`,
    );
  }

  create(fiscalPrinter: CreateFiscalPrinterRequest) {
    return this.http.post('settingreceipt/fiscalprinter', fiscalPrinter);
  }

  update({ id, ...body }: UpdateFiscalPrinterRequest) {
    return this.http.put(`settingreceipt/fiscalprinter/${id}`, body);
  }

  setStatus(
    fiscalPrinterId: number,
    params: {
      status: FiscalPrinterStatus;
      rtStatus: FiscalPrinterRTStatus;
    },
  ) {
    return this.http.post(
      `settingreceipt/fiscalprinter/${fiscalPrinterId}/set_status`,
      params,
    );
  }

  setNumber(
    receiptId: number,
    number: number,
    status: 'success' | 'error',
    printer_id: number,
    clousure_number?: number,
    document_date?: string,
  ) {
    return this.http.post(`fpreceipt/${receiptId}/setnumreceip`, {
      invoice_id: receiptId,
      number,
      status,
      printer_id,
      clousure_number,
      invoice_date: document_date,
    });
  }

  private loadDepartments(receipt_module_id: number) {
    return this.http.get(
      `settingreceipt/fiscalprinter/department/${receipt_module_id}`,
    );
  }
}
