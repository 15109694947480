import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { BillDepartment } from '../models';
import { SearchParams } from '../models/objects/search-params';

interface BillDepartmentResponse extends Omit<BillDepartment, 'property_id'> {
  property_department: Array<{
    id: number;
    bill_department_id: number;
    property_id: number;
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class BillsDepartmentsService {
  constructor(private http: HttpClient) {}

  read(propertiesIds: number[], filters: SearchParams) {
    return this.http.get(
      `general/bill_department?${generateSearchQuery({
        property_id: propertiesIds,
        ...filters,
      })}`,
    );
  }

  create(billDepartment: Partial<BillDepartment>) {
    return this.http.post(`general/bill_department`, billDepartment).pipe(
      map((response: IResponseSuccess<BillDepartmentResponse>) => {
        return this.parse(response);
      }),
    );
  }

  update({ id, ...billDepartment }: BillDepartment) {
    return this.http.put(`general/bill_department/${id}`, billDepartment).pipe(
      map((response: IResponseSuccess<BillDepartmentResponse>) => {
        return this.parse(response);
      }),
    );
  }

  delete(billDepartmentId: number) {
    return this.http.delete(`general/bill_department/${billDepartmentId}`);
  }

  private parse(
    response: IResponseSuccess<BillDepartmentResponse>,
  ): IResponseSuccess<BillDepartment> {
    const { property_department, ...data } = response.data;

    return {
      ...response,
      data: {
        ...data,
        property_id: property_department.map(({ property_id }) => property_id),
      },
    };
  }
}
