import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '@app/core/helpers';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { PropertyUser, SearchParams } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class PropertyGroupUsersService {
  constructor(private http: HttpClient) {}

  load(propertyGroupId: number, params?: SearchParams) {
    let url = `property/group/${propertyGroupId}/users`;

    if (params) {
      url = `${url}?${generateSearchQuery(params)}`;
    }

    return this.http.get<IResponseSuccess<PropertyUser[]>>(url);
  }
}
