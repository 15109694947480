<by-lookup
  [options]="customersOptions"
  [nzAllowClear]="allowClear"
  [optionTemplate]="optionTemplate"
  [serverSearch]="true"
  [dropdownMatchWidth]="false"
  [formControl]="control"
  [size]="size"
  [placeholder]="placeHolder"
  (search)="onSearchCustomers($event)"
  (selectBlur)="onTouched()"
></by-lookup>
<ng-template #optionTemplate let-option="option">
  <i class="{{ option.icon }} by-mr-5"></i>
  <span [class]="option.class">{{ option.name }}</span>
</ng-template>
