<by-spin [nzSpinning]="loading$ | async">
  <div [formGroup]="form" nz-form nzLayout="vertical">
    <nz-form-item *ngIf="propertiesIds?.length > 1">
      <nz-form-label>
        {{ 'property' | translate | capitalize }}
      </nz-form-label>
      <nz-form-control>
        <nz-select
          class="by-w-100"
          formControlName="property_id"
          [nzPlaceHolder]="'property' | translate"
        >
          <nz-option
            *ngFor="let propertyId of propertiesIds"
            [nzLabel]="propertiesNames$ | async | get: propertyId"
            [nzValue]="propertyId"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired>
        {{ 'receipt_date' | translate | capitalize }}
      </nz-form-label>
      <nz-form-control>
        <nz-date-picker
          byDisabledMobileKeyboard
          byDisabledDateFixer
          formControlName="invoice_date"
          [nzAllowClear]="false"
          nzPlaceHolder="{{
            'placeholder.select' | translate: { param: 'date' | translate }
          }}"
          nzFormat="dd/MM/yyyy"
          class="by-w-100"
          [nzPopupStyle]="{ width: '100%' }"
          [nzDisabledDate]="disabledStartDate"
        >
        </nz-date-picker> </nz-form-control
    ></nz-form-item>
    <nz-form-item>
      <nz-form-label>
        {{ 'receipt_number' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control>
        <nz-input-number
          class="by-w-100"
          byNumberOnly
          [nzMin]="1"
          formControlName="invoice_number"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>

    <nz-alert
      *ngIf="numberAlredyUsed$ | async"
      nzType="warning"
      [nzMessage]="'this_invoice_number_alredy_used' | translate | upperfirst"
      nzShowIcon
    ></nz-alert>
  </div>
</by-spin>
