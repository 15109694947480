export const windowScrollTop = (options?: ScrollToOptions): void => {
  let defaultOptions: ScrollToOptions = {
    behavior: 'smooth',
    top: 0,
  };
  if (options) {
    defaultOptions = {
      ...defaultOptions,
      ...options,
    };
  }
  window.scrollTo(defaultOptions);
};
