import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TokenService } from '../core/services/token.service';
import {
  AirbnbContentAPIConfiguration,
  VrboContentAPIConfiguration,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ChannelContentAPIService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  load() {
    return this.http.get('general/dealers');
  }

  update(
    propertyId: number,
    accommodationChannelId: number,
    channelId: number,
    more: AirbnbContentAPIConfiguration | VrboContentAPIConfiguration,
  ) {
    return this.http.post(
      `channelmanager/content_api/${channelId}/${propertyId}/accommodation/${accommodationChannelId}`,
      more,
    );
  }
}
