import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';

import { ExportService } from './export.service';
import { PrintOrientation } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DailySummaryService {
  constructor(private http: HttpClient, private exportService: ExportService) {}

  loadSplitted(properties: number[], dateFrom: string, dateTo: string) {
    return this.http.get(
      `daily_summary/splitted?${generateParamArray(
        'properties',
        properties,
      )}&date_from=${dateFrom}&date_to=${dateTo}`,
    );
  }

  loadGrouped(properties: number[], dateFrom: string, dateTo: string) {
    return this.http.get(
      `daily_summary/grouped?${generateParamArray(
        'properties',
        properties,
      )}&date_from=${dateFrom}&date_to=${dateTo}`,
    );
  }

  exportGrouped(
    properties: number[],
    dateFrom: string,
    dateTo: string,
    columns: string[],
    orientation: PrintOrientation = 'vertical',
  ) {
    const url = `daily_summary/grouped/export?${generateParamArray(
      'properties',
      properties,
    )}&orientation=${orientation}&date_from=${dateFrom}&date_to=${dateTo}&export_format=pdf&${generateParamArray(
      'export_columns',
      columns,
    )}`;

    this.exportService.directExport(url);
  }

  exportSplitted(
    properties: number[],
    dateFrom: string,
    dateTo: string,
    columns: string[],
    orientation: PrintOrientation = 'vertical',
  ) {
    const url = `daily_summary/splitted/export?${generateParamArray(
      'properties',
      properties,
    )}&orientation=${orientation}&date_from=${dateFrom}&date_to=${dateTo}&export_format=pdf&${generateParamArray(
      'export_columns',
      columns,
    )}`;

    this.exportService.directExport(url);
  }
}
