import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IstatGuestReport, SendToExternalRequest } from '../models';
import { SearchParams } from '../models/objects/search-params';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class IstatGuestsReportService {
  constructor(private http: HttpClient) {}

  loadAgencySpecifications(groupId: number) {
    return this.http.get(`property/${groupId}/reporting/details`);
  }

  load(groupId: number, params: SearchParams) {
    return this.http.get(
      `property/${groupId}/reporting?${generateSearchQuery(params)}`,
    );
  }

  send(groupId: number, params: SearchParams) {
    return this.http.post(
      `property/${groupId}/reporting?${generateSearchQuery(params)}`,
      null,
    );
  }

  deleteGuestSent(groupId: number, params: SearchParams) {
    return this.http.post<IResponseSuccess<IstatGuestReport>>(
      `property/${groupId}/reporting?${generateSearchQuery(params)}`,
      null,
    );
  }

  setCustomFields(groupId: number, customFields) {
    return this.http.put(`property/${groupId}/reporting/customs`, customFields);
  }

  sendToExternal(data: SendToExternalRequest) {
    return this.http.post(`reservations/toggle_entries`, data);
  }
}
