import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class ActiveModulesService {
  constructor(private http: HttpClient) {}

  load(properties: number[]) {
    return this.http.get(
      `properties/modules?${generateParamArray('property_id', properties)}`,
    );
  }
}
