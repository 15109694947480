import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';

import { IResponseSuccess } from '../../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../../core/services/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class PlacesService {
  constructor(private http: HttpClient, private errorHandler: ErrorHandlerService) { }

  loadCountries() {
    return this.http.get('places/country/lookup');
  }

  loadStates(countryId: number) {
    return this.http.get(`places/country/${countryId}/states/lookup`);
  }

  loadCounties(countryId: number, stateId: number) {
    return this.http.get(
      `places/country/${countryId}/state/${stateId}/counties/lookup`,
    );
  }

  loadCities(countryId: number, stateId: number, countyId: number) {
    return this.http.get(
      `places/country/${countryId}/state/${stateId}/county/${countyId}/cities/lookup`,
    ) as Observable<IResponseSuccess<{
      id: number
      name: string
      zip_code: string
    }[]>>;
  }

  searchPlace(
    type: string,
    value: string,
    searchParams: { [key: string]: number },
  ) {
    let getValue = '';
    if (searchParams) {
      Object.keys(searchParams).forEach((key) => {
        const valueKey = searchParams[key];
        if (value) {
          getValue += `&${key}=${valueKey}`;
        }
      });
    }
    return this.http.get(
      `places/lookup?type=${type}&value=${value}${getValue}`,
    );
  }

  searchNewPlace(data: {
    country: string;
    state?: string;
    county?: string;
    city?: string;
    forceRemoteMapping?: boolean;
  }) {
    return this.http.get(
      `places/search?${Object.keys(data)
        .map((key) => `${key}=${data[key]}`)
        .join('&')}`,
    );
  }

  loadPlace(placeId) {
    return this.http.get(`place/${placeId}`);
  }

  getZipCode(countryId: number, stateId: number, countyId: number, cityId: number): Observable<string> {
    if (!countryId || !countryId || !countryId || !cityId) {
      return of(null);
    }
    return this.loadCities(countryId, stateId, countyId).pipe(
      take(1),
      map(({ data }) => {
        return data?.find(({ id }) => id === cityId)?.zip_code;
      }),
      catchError((error) => {
        this.errorHandler.handle(error);
        return of(null);
      }))
  }
}
