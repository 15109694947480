import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { Company } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getDetails = (state: State): Company => state.companyDetails;

export const getPagination = (state: State): IPagination => state.pagination;

export const getUploadErrors = (state: State) => state.uploadErrors;

export const getExportFileId = (state: State) => state.exportFileId;

export const selectCompanyState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('companies');

export const selectAllCompaniesItems: (state: object) => Company[] =
  featureAdapter.getSelectors(selectCompanyState).selectAll;

export const selectCompanyById = (id: string) =>
  createSelector(selectAllCompaniesItems, (companies: Company[]) => {
    if (companies) {
      return companies.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectCompaniesError: MemoizedSelector<object, any> =
  createSelector(selectCompanyState, getError);

export const selectCompaniesIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectCompanyState, getIsLoading);

export const selectCompanyDetails: MemoizedSelector<object, Company> =
  createSelector(selectCompanyState, getDetails);

export const selectPagination: MemoizedSelector<object, IPagination> =
  createSelector(selectCompanyState, getPagination);

export const selectUploadErrors: MemoizedSelector<
  object,
  { [row: string]: string[] }
> = createSelector(selectCompanyState, getUploadErrors);

export const selectExportFileId = createSelector(
  selectCompanyState,
  getExportFileId,
);
