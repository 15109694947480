/**
 * @param str stringa su cui effettuare il replace
 * @param searchValue stringa da sostiture
 * @param replaceValue stringa con cui sostituire
 */
export const replaceAll = (
  str: string,
  searchValue: any,
  replaceValue: string,
): string => {
  let regex: RegExp;

  try {
    regex = new RegExp(searchValue, 'g');
  } catch {
    regex = searchValue;
  }

  return str?.replace(regex, replaceValue);
};
