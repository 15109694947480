import { Component, Input, OnInit, inject } from '@angular/core';

import { IInvoiceLayout } from '../../../models';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-invoice-change-layout',
  templateUrl: './invoice-change-layout.component.html',
  styleUrls: ['./invoice-change-layout.component.scss'],
})
export class InvoiceChangeLayoutComponent implements OnInit {
  readonly nzDataModal: Partial<InvoiceChangeLayoutComponent> =
    inject(NZ_MODAL_DATA);

  invoicesLayouts: IInvoiceLayout[] = this.nzDataModal.invoicesLayouts || [];

  selectedInvoiceLayoutId: number =
    this.nzDataModal.selectedInvoiceLayoutId || null;

  layoutWasDeleted: boolean;

  constructor() {}

  ngOnInit() {
    this.layoutWasDeleted = !this.invoicesLayouts.some(
      ({ id }) => +id === +this.selectedInvoiceLayoutId,
    );
  }
}
