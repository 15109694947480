import { Component, Input, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

import { handleFormSubmit } from '../../../../core/helpers/handleFormSubmit';
import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import { TableauActionParams, TableauNote } from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import * as TableauActions from '../../../../root-store/tableau-store/actions';
import { ModalDragService } from '../../../../services/modal-drag.service';

@Component({
  selector: 'by-tableau-note-form-modal',
  templateUrl: './tableau-note-form-modal.component.html',
  styleUrls: ['./tableau-note-form-modal.component.scss'],
  providers: [ModalDragService],
})
export class TableauNoteFormModalComponent implements OnInit {
  readonly nzModalData: Partial<TableauNoteFormModalComponent> =
    inject(NZ_MODAL_DATA);

  params: TableauActionParams = this.nzModalData.params;

  note: TableauNote = this.nzModalData.note;

  form = this.formBuilder.group({
    dates: [null],
    title: [null, [Validators.required]],
    color: ['ffa234'],
  });

  saving = false;

  deleting = false;

  constructor(
    private modalRef: NzModalRef,
    private store: Store<RootState>,
    private formBuilder: UntypedFormBuilder,
    private modalDragService: ModalDragService,
    private dateFormatter: DateFormatterService,
  ) {
    this.modalDragService.handle(this.modalRef);
  }

  ngOnInit() {
    const { dateFrom, dateTo } = this.params;

    let dates = [dateFrom, dateTo];

    if (this.note) {
      dates = [
        new Date(this.note.date_from),
        moment(this.note.date_to).subtract(1, 'day').toDate(),
      ];
    }

    this.form.patchValue({ ...this.note, dates });
  }

  onCancel() {
    this.modalRef.close();
  }

  onSave() {
    handleFormSubmit([this.form], () => {
      this.note ? this.updateNoteRequest() : this.createNoteRequest();
    });
  }

  onDelete() {
    this.deleting = true;

    this.store.dispatch(
      TableauActions.deleteNoteRequest({
        noteId: this.note.id,
        onSuccess: () => {
          this.deleting = false;
          this.modalRef.close();
        },
        onFailure: () => {
          this.deleting = false;
        },
      }),
    );
  }

  get formValuesAsNote(): Partial<TableauNote> {
    const {
      dates: [dateFrom, dateTo],
      ...formValues
    } = this.form.value;

    const {
      row: { data: room },
    } = this.params;

    return {
      ...this.note,
      ...formValues,
      accommodation_id: room.accommodation_details.id,
      accommodation_tableau_number_id: room.id,
      date_from: this.dateFormatter.toServerFormat(dateFrom),
      date_to: this.dateFormatter.toServerFormat(dateTo),
    };
  }

  private createNoteRequest() {
    this.saving = true;

    this.store.dispatch(
      TableauActions.createNoteRequest({
        note: this.formValuesAsNote,
        onSuccess: () => {
          this.saving = false;
          this.modalRef.close();
        },
        onFailure: () => {
          this.saving = false;
        },
      }),
    );
  }

  private updateNoteRequest() {
    this.saving = true;

    this.store.dispatch(
      TableauActions.updateNoteRequest({
        note: this.formValuesAsNote,
        onSuccess: () => {
          this.saving = false;
          this.modalRef.close();
        },
        onFailure: () => {
          this.saving = false;
        },
      }),
    );
  }
}
