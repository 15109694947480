import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ResendWelcomeAutomaticEmailsRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class WelcomeAutomaticEmailsService {
  constructor(private http: HttpClient) {}

  loadLogs(propertyId: number) {
    return this.http.get(
      `properties/${propertyId}/welcome_automatic_email/logs`,
    );
  }

  resend(data: ResendWelcomeAutomaticEmailsRequest) {
    const { property_id, welcome_automatic_email_id, ...request } = data;
    return this.http.post(
      `properties/${property_id}/welcome_automatic_email/${welcome_automatic_email_id}/send`,
      request,
    );
  }
}
