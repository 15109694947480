import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EstimateStoreEffects } from './effects';
import { estimateReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('estimate', estimateReducer),
    EffectsModule.forFeature([EstimateStoreEffects]),
  ],
})
export class EstimateStoreModule {}
