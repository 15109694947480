import { isArray, isPlainObject, mapValues } from 'lodash';

/**
 * @description
 * The function receives a Json,
 * parses it and maps the values that satisfy the checkFn by executing the callBack.
 *
 * @param json to parse.
 * @param callback function that map the value
 * @returns json with the mapped values
 */
export const iterateJson = (json: any, callback: (value: any) => any) => {
  if (isArray(json)) {
    return json.map((item) => iterateJson(item, callback));
  }

  if (isPlainObject(json)) {
    return mapValues(json, (value) => {
      return iterateJson(value, callback);
    });
  }

  return callback(json);
};
