import { select, Store } from '@ngrx/store';
import { intersection } from 'lodash';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectAllProperties } from '../core/+state/core.reducer';
import { Module } from '../models';
import { ActiveModulesStoreSelectors } from '../root-store/active-modules-store';

export const groupPropertiesWithModule = (store: Store, module: Module) =>
  combineLatest([
    store.pipe(
      select(selectAllProperties),
      map((properties) => properties.map(({ id }) => id)),
    ),
    store.pipe(
      select(ActiveModulesStoreSelectors.selectActiveModuleBySlug(module)),
      map((quoteModule) => {
        if (!quoteModule) {
          return [];
        }

        return Object.entries(quoteModule.properties)
          .filter(([_, propertyModule]) => propertyModule.status)
          .map(([propertyId]) => +propertyId);
      }),
    ),
  ]).pipe(map((properties) => intersection(...properties)));
