<div class="by-attachment-viewer-find-input-area">
  <nz-form-item style="margin-bottom: 0">
    <nz-form-control
      [nzValidateStatus]="findState"
      [nzHasFeedback]="!!findControl?.value"
    >
      <input
        #findInput
        (after-if)="onFocus()"
        nz-input
        [placeholder]="'find_in_document' | translate | upperfirst"
        style="padding: 4px 11px"
        [formControl]="findControl"
        (ngModelChange)="onFind($event)"
      />
    </nz-form-control>
  </nz-form-item>

  <div class="by-attachment-viewer-find-input-area__arrows by-ml-10">
    <button
      id="findPrevious"
      nz-button
      (click)="onFindPrevious()"
      nz-tooltip
      [nzTooltipTitle]="
        'find_occurrence_with_direction'
          | translate : { direction: 'previous' | translate }
          | upperfirst
      "
      nzTooltipPlacement="bottomLeft"
      class="by-mr-5 ant-btn-icon-only"
      [disabled]="!findControl?.value || findState === 'error'"
    >
      <i class="far fa-arrow-left"></i>
    </button>
    <button
      id="findNext"
      class="ant-btn-icon-only"
      nz-button
      (click)="onFindNext()"
      nz-tooltip
      [nzTooltipTitle]="
        'find_occurrence_with_direction'
          | translate
            : { direction: 'following' | translate : { gender: 'female' } }
          | upperfirst
      "
      nzTooltipPlacement="bottomLeft"
      [disabled]="!findControl?.value || findState === 'error'"
    >
      <i class="far fa-arrow-right"></i>
    </button>
  </div>
</div>
