import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

export function getManagementDataForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
  return formBuilder.group({
    type: [null, [Validators.required, Validators.maxLength(20)]],
    text_reference: [null, [Validators.maxLength(60)]],
    number_reference: [null],
    date_reference: [null],
  });
}
