import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { get, uniqBy } from 'lodash';

import { PaymentMethod } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getPropertiesPaymentMethods = (
  state: State,
): { [propertyId: number]: PaymentMethod[] } => state.propertiesPaymentMethods;

export const selectPaymentMethodState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('paymentMethods');

export const selectAllPaymentMethodsItems: (
  state: object,
) => PaymentMethod[] = featureAdapter.getSelectors(selectPaymentMethodState)
  .selectAll;

export const selectAllPropertiesPaymentMethodsItems: (
  state: object,
) => { [propertyId: number]: PaymentMethod[] } = createSelector(
  selectPaymentMethodState,
  getPropertiesPaymentMethods,
);

export const selectAllPaymentMethodsRecords: (
  state: object,
) => Record<number, PaymentMethod> = createSelector(
  selectAllPaymentMethodsItems,
  (paymentMethods) =>
    (paymentMethods || []).reduce(
      (records, paymentMethod) => ({
        ...records,
        [paymentMethod.id]: paymentMethod,
      }),
      {},
    ),
);

export const selectAllPropertyPaymentMethodsRecords: (
  state: object,
) => Record<number, PaymentMethod> = createSelector(
  selectAllPropertiesPaymentMethodsItems,
  (paymentMethods) =>
    Object.keys(paymentMethods || {}).reduce((records, propertyId) => {
      const allPropertypaymentMethods = paymentMethods[propertyId].reduce(
        (acc, curr) => ({ ...acc, [curr.id]: curr }),
        {},
      );

      return { ...records, ...allPropertypaymentMethods };
    }, {}),
);
export const selectAllPropertyPaymentMethodsItems: (
  state: object,
) => Record<number, PaymentMethod> = createSelector(
  selectAllPropertiesPaymentMethodsItems,
  (paymentMethods) =>
    Object.keys(paymentMethods || {}).reduce((records, propertyId) => {
      return uniqBy([...records, paymentMethods[propertyId]], 'id');
    }, []),
);

export const selectPaymentMethodById = (id: string) =>
  createSelector(
    selectAllPaymentMethodsItems,
    (paymentMethods: PaymentMethod[]) => {
      if (paymentMethods) {
        return paymentMethods.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectPaymentsMethodsByPropertyId = (propertyId: number) =>
  createSelector(
    selectAllPropertiesPaymentMethodsItems,
    (propertiesPaymentMethods) => {
      return get(propertiesPaymentMethods, propertyId, null);
    },
  );

export const selectPaymentMethodsError: MemoizedSelector<
  object,
  any
> = createSelector(selectPaymentMethodState, getError);

export const selectPaymentMethodsIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectPaymentMethodState, getIsLoading);
