import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InvoicePrintSetting } from '../models';

interface GetInvoicePrintSettingsResponse {
  data: InvoicePrintSetting;
}

@Injectable({
  providedIn: 'root',
})
export class InvoicePrintSettingsService {
  constructor(private http: HttpClient) {}

  load(invoiceId: number) {
    return this.http.get<GetInvoicePrintSettingsResponse>(
      `invoices/${invoiceId}/print_settings`,
    );
  }

  save({ invoice_id, ...data }: InvoicePrintSetting) {
    return this.http.post(`invoices/${invoice_id}/print_settings`, data);
  }
}
