import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isArray, mapValues, omit, reduce } from 'lodash';

import { isJsonString, setAllQueryParams, upsertQueryParams } from '../helpers';
import { Nullable } from '../models';

@Injectable({ providedIn: 'root' })
export class QueryParamsService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  upsert(queryParams: Record<string, any>): void {
    upsertQueryParams(this.router, queryParams);
  }

  set(queryParams: Record<string, any>): void {
    setAllQueryParams(this.router, queryParams);
  }

  removeAll() {
    setAllQueryParams(this.router, {});
  }

  removeOne(name: string) {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.set(omit(queryParams, [name]));
  }

  removeMany(names: string[]) {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.set(omit(queryParams, names));
  }

  getQueryParamsDecodedByKey<T = any>(key: string): Nullable<T>;
  getQueryParamsDecodedByKey<T = any>(key: string[]): Nullable<T>;
  getQueryParamsDecodedByKey<T = any>(
    key: string | string[],
  ): Nullable<T> | Nullable<Record<string, T>> {
    if (isArray(key)) {
      return key.reduce((acc, key) => {
        acc = {
          ...acc,
          [key]: this.getQueryParamsDecodedByKey(key),
        };
        return acc;
      }, {});
    }

    const data = this.activatedRoute.snapshot.queryParams[key];

    if (!data) {
      return null;
    }
    const dataDecoded = window.atob(data);

    return (
      isJsonString(dataDecoded) ? JSON.parse(dataDecoded) : dataDecoded
    ) as T;
  }

  encodeQueryParams(params: Record<string, any>): Record<string, string> {
    const encoded = (data: any) => window.btoa(JSON.stringify(data));

    return mapValues(params, (value) => encoded(value));
  }

  setQueryParamsEncodedByKey(params: Record<string, any>): void {
    this.upsert(this.encodeQueryParams(params));
  }
}
