import { ChildrenRange } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getMaxGuests = (state: State): boolean => state.maxGuests;

export const selectChildrenRangeState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('childrenRange');

export const selectAllchildrenRangeItems: (state: object) => ChildrenRange[] =
  featureAdapter.getSelectors(selectChildrenRangeState).selectAll;

export const selectAllchildrenRangeInOrderItems = createSelector(
  selectAllchildrenRangeItems,
  (childrenRange: ChildrenRange[]) => {
    return childrenRange
      .sort((a, b) => b.combination.from - a.combination.from)
      .map(({ id: rangeId, combination, property_id }) => ({
        id: rangeId,
        property_id,
        is_baby: combination.from === 0 && combination.to <= 5,
        age: `${combination.from} - ${combination.to}`,
        combination,
      }));
  },
);

export const selectChildrenRangeById = (id: string) =>
  createSelector(
    selectAllchildrenRangeItems,
    (childrenRange: ChildrenRange[]) => {
      if (childrenRange) {
        return childrenRange.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectChildrenRangeError: MemoizedSelector<object, any> =
  createSelector(selectChildrenRangeState, getError);

export const selectChildrenRangeIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectChildrenRangeState, getIsLoading);
export const selectgetMaxGuests: MemoizedSelector<object, any> = createSelector(
  selectChildrenRangeState,
  getMaxGuests,
);
