import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Property } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class PropertyDetailsService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`property/${propertyId}`);
  }

  update(propertyId: number, property: Property) {
    return this.http.put(`property/${propertyId}`, property);
  }
}
