import { get, isArray, isNil, mapValues, merge, mergeWith } from 'lodash';

import { removeNullishValues } from '../../helpers';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      return {
        ...state,
        data: action.payload.items || {},
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.UPDATE_SUCCESS: {
      function customizer(objValue, srcValue) {
        return removeNullishValues({
          ...objValue,
          ...mapValues(srcValue, (value, key) => {
            if (isNil(value)) {
              return null;
            }

            if (isArray(value)) {
              return value;
            }

            if (typeof value === 'object') {
              return {
                ...merge({}, get(objValue, [key]), value),
              };
            }

            return value;
          }),
        });
      }

      const data = mergeWith(
        { ...state.data },
        { ...action.payload.preferences },
        customizer,
      );

      return {
        ...state,
        data: { ...data, last_update: new Date() },
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
