import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HousekeeperSortable } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HousekeeperSortablesService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`properties/${propertyId}/housekeeper/sortables`);
  }

  sort(property_id: number[], rows: HousekeeperSortable[], date?: string) {
    return this.http.post(`properties/housekeeper/sortables`, {
      property_id,
      rows,
      date,
    });
  }
}
