import { mapValues } from 'lodash';

import {
  DGFE_MPD_Status,
  FiscalPrinterStatus,
  PrinterStatus,
  ReceiptDocumentStatus,
} from '../fiscal-printer-status';

import { CustomQ3XFiscalPrinterStatusResponse } from './custom-q3x-fiscal-printer-status-response';

interface ParsedPrinterStatus {
  drawerOpened: boolean;
  paperEnd: boolean;
  paperRunningOut: boolean;
  dailyJournalFull: boolean;
  dailyJournalAlmostFull: boolean;
}

interface ParsedFpStatus {
  fiscalDocumentOpen: boolean;
  notFiscalDocumentOpen: boolean;
  invoiceOpen: boolean;
}

const printerStatusParser = (
  printerStatusString: string,
): ParsedPrinterStatus => {
  const [
    drawerOpened,
    paperEnd,
    paperRunningOut,
    dailyJournalFull,
    dailyJournalAlmostFull,
  ] = printerStatusString;

  return mapValues(
    {
      drawerOpened,
      paperEnd,
      paperRunningOut,
      dailyJournalFull,
      dailyJournalAlmostFull,
    },
    (value) => !!+value,
  );
};

const fpStatusParser = (fpStatusString: string): ParsedFpStatus => {
  const [fiscalDocumentOpen, notFiscalDocumentOpen, invoiceOpen] =
    fpStatusString;

  return mapValues(
    {
      fiscalDocumentOpen,
      notFiscalDocumentOpen,
      invoiceOpen,
    },
    (value) => !!+value,
  );
};

const receiptDocumentStatusMapping = (
  parsedFpStatus: ParsedFpStatus,
): ReceiptDocumentStatus => {
  if (parsedFpStatus.fiscalDocumentOpen) {
    return 'fiscal_document_open';
  }

  if (parsedFpStatus.notFiscalDocumentOpen) {
    return 'non_tax_open';
  }

  if (parsedFpStatus.invoiceOpen) {
    return 'open_tax';
  }

  return 'no_operation_in_progress';
};

const dgfeMpdStatusMapping = (
  parsedPrinterStatus: ParsedPrinterStatus,
): DGFE_MPD_Status => {
  if (parsedPrinterStatus.dailyJournalFull) {
    return 'to_format';
  }

  if (parsedPrinterStatus.dailyJournalAlmostFull) {
    return 'next_to_exhaustion';
  }

  return 'ok';
};

const printerStatusMapping = (
  parsedPrinterStatus: ParsedPrinterStatus,
): PrinterStatus => {
  if (parsedPrinterStatus.paperEnd) {
    return 'out_of_paper';
  }

  if (parsedPrinterStatus.paperRunningOut) {
    return 'paper_running_out';
  }

  return 'ok';
};

export class CustomQ3XFiscalPrinterStatusParser {
  parse({
    response,
  }: CustomQ3XFiscalPrinterStatusResponse): FiscalPrinterStatus {
    const parsedPrinterStatus = printerStatusParser(
      response.addInfo.printerStatus,
    );

    const parsedFpStatus = fpStatusParser(response.addInfo.fpStatus);

    return {
      result: response._attributes.status === '0',
      printerStatus: printerStatusMapping(parsedPrinterStatus),
      drawerState: parsedPrinterStatus.drawerOpened ? 'open' : 'closed',
      receiptDocumentStatus: receiptDocumentStatusMapping(parsedFpStatus),
      operationalStatus: 'registration_status',
      dgfeMpdStatus: dgfeMpdStatusMapping(parsedPrinterStatus),
    };
  }
}
