import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { IInvoiceNextNumber } from '@app/features/commons/reservation-details/models/reservation.charges.model';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import {
  Invoice,
  InvoiceDetails,
  InvoiceTotals,
  InvoiceTypes,
  StatsHeaderWidget,
  StatusSdiInfo,
} from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getWidgetsLoading = (state: State): boolean => state.isLoading;

export const getIsLoadingNextNumber = (state: State): boolean =>
  state.loadingNextNumber;

export const getDetail = (state: State): InvoiceDetails => state.invoiceDetails;

export const getExportFileId = (state: State): number => state.exportFileId;

export const getStatusSdiInfo = (state: State): StatusSdiInfo =>
  state.statusSdiInfo;

export const getPagination = (state: State): IPagination => state.pagination;

export const getEmailTemplate = (state: State): any => state.emailTemplateData;

export const getInvoiceNextNumber = (
  state: State,
): {
  [invoice_layout_id: number]: IInvoiceNextNumber;
} => state.invoiceNextNumber;

export const selectInvoicesState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('invoices');

export const selectAllInvoicesItems: (state: object) => Invoice[] =
  featureAdapter.getSelectors(selectInvoicesState).selectAll;

export const selectInvoicesById = (id: string) =>
  createSelector(selectAllInvoicesItems, (allInvoices: any[]) => {
    if (allInvoices) {
      return allInvoices.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectInvoiceNextNumber: MemoizedSelector<
  object,
  {
    [invoice_layout_id: number]: IInvoiceNextNumber;
  }
> = createSelector(selectInvoicesState, getInvoiceNextNumber);

export const selectInvoicesError: MemoizedSelector<object, any> =
  createSelector(selectInvoicesState, getError);

export const selectInvoicesIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectInvoicesState, getIsLoading);

export const selectInvoicesLoadingNextNumber: MemoizedSelector<
  object,
  boolean
> = createSelector(selectInvoicesState, getIsLoadingNextNumber);

export const selectWidgtesLoading: MemoizedSelector<object, boolean> =
  createSelector(selectInvoicesState, getWidgetsLoading);

export const selectInvoicesDetail: MemoizedSelector<object, InvoiceDetails> =
  createSelector(selectInvoicesState, getDetail);

export const selectInvoicesWidgets: MemoizedSelector<
  object,
  StatsHeaderWidget[]
> = createSelector(selectInvoicesState, (state: State) => state.widgets);

export const selectInvoicePayments: MemoizedSelector<object, any[]> =
  createSelector(
    selectInvoicesState,
    (state: State) => state.invoiceDetails?.reservation_payments,
  );

export const selectStatusSdiInfo: MemoizedSelector<object, StatusSdiInfo> =
  createSelector(selectInvoicesState, getStatusSdiInfo);

export const selecExportFileId: MemoizedSelector<object, number> =
  createSelector(selectInvoicesState, getExportFileId);

export const selectInvoicesPagination: MemoizedSelector<object, any> =
  createSelector(selectInvoicesState, getPagination);

export const selectEmailTemplate: MemoizedSelector<object, any> =
  createSelector(selectInvoicesState, getEmailTemplate);

export const selectRegisterLoading: MemoizedSelector<object, InvoiceTypes> =
  createSelector(selectInvoicesState, (state: State) => state.registerLoading);

export const selectInvoiceTotals: MemoizedSelector<object, InvoiceTotals> =
  createSelector(selectInvoicesState, (state: State) => state.invoiceTotals);

export const selectUsingHolidayVoucher: MemoizedSelector<object, boolean> =
  createSelector(
    selectInvoicesState,
    (state: State) => state.usingHolidayVoucher,
  );

export const selectSaving: MemoizedSelector<object, boolean> = createSelector(
  selectInvoicesState,
  (state: State) => state.saving,
);
