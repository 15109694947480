import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IReservationTagRequest } from '@app/models/objects/reservation-tag';
import {
  generateParamArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { removeAllNullishValues } from '../helpers';
import { isEmpty } from 'lodash';
import { SearchParams } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ReservationTagService {
  constructor(private http: HttpClient) {}

  load(propertiesIDS: number[]) {
    return this.http.get(
      `reservations/tags?${generateParamArray('property_ids', propertiesIDS)}`,
    );
  }

  delete(data: { reservation_tag_id: number; searchParams?: SearchParams }) {
    const { reservation_tag_id, searchParams } = data;

    let params: string = '';

    if (!isEmpty(searchParams)) {
      params = `?${generateSearchQuery(removeAllNullishValues(searchParams))}`;
    }

    const url = `reservations/tag/${reservation_tag_id}${params}`;

    return this.http.delete<IResponseSuccess>(url);
  }

  create(data: IReservationTagRequest) {
    return this.http.post(`reservations/tag`, data);
  }

  update(data: IReservationTagRequest) {
    return this.http.put(`reservations/tag/${data.reservation_tag_id}`, data);
  }

  updateReservationTag(reservation_tag_id: number, reservation_id: number) {
    return this.http.put(`reservation/${reservation_id}/update_tag`, {
      reservation_tag_id,
    });
  }
}
