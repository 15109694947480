import { get } from 'lodash';

import { IInvoiceLayout } from '../../models';

import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

// su ogni invoice layout metto un array di tutte le property ad esso associate
const mapItems = (item: IInvoiceLayout) => {
  const { properties_layouts } = item;
  const allPropertiesIds = (properties_layouts || []).map(
    ({ property_id }) => property_id,
  );

  return { ...item, allPropertiesIds };
};

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_ALL_REQUEST:
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      let items = action.payload.items;

      items = items.map(mapItems);

      //Genero l'oggetto da puntare con gli invoice layout delle property

      const invoiceLayoutsProperties = items.reduce(
        (map: { [propertyId: number]: IInvoiceLayout[] }, item) => {
          const { properties_layouts } = item;
          properties_layouts.forEach((propertyLayout) => {
            const { property_id } = propertyLayout;
            map = {
              ...map,
              [property_id]: [...get(map, property_id, []), item],
            };
          });
          return { ...map };
        },
        {},
      );

      // Genero la mappa [property_id => invoice_layout_id] degli invoice layout di default

      const defaultPropertiesLayouts = items.reduce((map, item) => {
        const { properties_layouts } = item;
        properties_layouts.forEach((propertyLayout) => {
          const { property_id, default: _default } = propertyLayout;

          if (_default) {
            map = { ...map, [property_id]: item.id };
          }
          //temporaneo quando non ho nessun layout di default
          if (!_default && !get(map, property_id)) {
            map = { ...map, [property_id]: item.id };
          }
        });
        return { ...map };
      }, {});

      return featureAdapter.setAll(items, {
        ...state,
        defaultPropertiesLayouts: defaultPropertiesLayouts || {},
        invoiceLayoutsProperties: invoiceLayoutsProperties,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allInvoiceLayouts: action.payload.items.map(mapItems),
      };
    }

    case ActionTypes.LOAD_ALL_FAILURE:
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_TAX_SYSTEM_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_TAX_SYSTEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        taxSystem: action.payload.items,
      };
    }

    case ActionTypes.LOAD_TAX_SYSTEM_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_DETAILS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_DETAILS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case ActionTypes.LOAD_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        details: action.payload.invoiceLayout,
      };
    }
    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.CREATE_SUCCESS: {
      return featureAdapter.addOne(action.payload.item, {
        ...state,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return featureAdapter.updateOne(
        {
          id: action.payload.item.id,
          changes: action.payload.item,
        },
        {
          ...state,
          isLoading: false,
          error: null,
        },
      );
    }
    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_WARNING: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_REQUEST: {
      return {
        ...state,
      };
    }

    case ActionTypes.SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_SUCCESS: {
      const { property_id, invoice_layout_id } = action.payload;
      let { defaultPropertiesLayouts } = state;

      property_id.forEach(
        (id) =>
          (defaultPropertiesLayouts = {
            ...defaultPropertiesLayouts,
            [id]: invoice_layout_id,
          }),
      );

      return {
        ...state,
        defaultPropertiesLayouts,
      };
    }

    case ActionTypes.SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
