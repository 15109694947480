import { Injectable, OnDestroy } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { fromEvent, timer } from 'rxjs';
import { SubSink } from 'subsink';

const DRAGGABLE_ELEMENT_CLASSES =
  'modalDraggableMove|ant-modal-header|ant-modal-title';

@Injectable()
export class ModalDragService implements OnDestroy {
  private posX: number;
  private posY: number;

  private mouseX: number;
  private mouseY: number;

  private translateX: number;
  private translateY: number;

  private dragEnabled = false;

  private isMouseHolded = false;

  private modalContainer: HTMLElement;
  private dragElement: HTMLElement;

  private listeners = new SubSink();

  constructor() {}

  ngOnDestroy() {
    this.listeners.unsubscribe();
  }

  handle(modalRef: NzModalRef) {
    if (!modalRef) {
      return;
    }

    this.initialize();

    const modalContainer = modalRef.containerInstance.getNativeElement();

    if (this.modalContainer !== modalContainer) {
      this.listeners.unsubscribe();
    }

    this.modalContainer = modalRef.containerInstance?.getNativeElement();
    this.dragElement = this.modalContainer.querySelector('.ant-modal-content');
    this.dragElement.style.willChange = 'transform';
    this.dragElement.style.userSelect = 'none';

    this.listeners.add(
      fromEvent(this.modalContainer, 'mousedown').subscribe(this.onMouseDown),
      fromEvent(this.modalContainer, 'mouseup').subscribe(this.onMouseUp),
      fromEvent(this.modalContainer, 'mouseleave').subscribe(this.onMouseLeave),
      fromEvent(this.modalContainer, 'mousemove').subscribe(this.onMouseMove),
    );
  }

  private initialize() {
    this.posX = 0;
    this.posY = 0;

    this.mouseX = 0;
    this.mouseY = 0;

    this.translateX = 0;
    this.translateY = 0;

    this.dragEnabled = false;

    this.isMouseHolded = false;
  }

  private onMouseDown = (e) => {
    e = e || window.event;

    const clickedElement: HTMLElement = e.target;
    const clickedElementParent: HTMLElement = clickedElement.parentElement;

    if (
      clickedElement?.classList.value.match(DRAGGABLE_ELEMENT_CLASSES) ||
      clickedElementParent?.classList.value.match(DRAGGABLE_ELEMENT_CLASSES)
    ) {
      this.mouseX = e.clientX;
      this.mouseY = e.clientY;
      this.isMouseHolded = true;

      timer(100).subscribe(() => {
        if (this.isMouseHolded) {
          this.dragEnabled = true;
        }
      });
    }
  };

  private onMouseUp = () => {
    this.dragEnabled = false;
    this.isMouseHolded = false;
  };

  private onMouseLeave = () => {
    this.dragEnabled = false;
    this.isMouseHolded = false;
  };

  private onMouseMove = (e) => {
    if (!this.dragEnabled) {
      return;
    }

    e = e || window.event;

    // calculate the new cursor position:
    this.posX = this.mouseX - e.clientX;
    this.posY = this.mouseY - e.clientY;
    this.mouseX = e.clientX;
    this.mouseY = e.clientY;

    // set the element's new position:
    this.translateX -= this.posX;
    this.translateY -= this.posY;

    this.dragElement.style.transform = `translate(${this.translateX}px, ${this.translateY}px)`;
  };
}
