export const DEFAULT_COLORS = {
  arrived: '#71db83',
  confirmed: '#0dbd6a',
  departure: '#aaaaaa',
  quotes: '#d135bd',
  guaranteed: '#0d81be',
  optionable: '#00a2b9',
  pending: '#ffad01',
  cancelled: '#e7503b',
  arriving: '#ffa500',
  stay: '#047bf8',
  expired: '#f17329',
};

export const RESERVATION_PAYMENTS_COLRS = {
  paid: '#027f07',
  to_pay: '#646466',
  suspended: '#ffa234',
  to_be_invoiced: '#19c8d2',
};

export const DEFAULT_PAYMENTS_COLORS = {
  ...RESERVATION_PAYMENTS_COLRS,
  unpaid: '#646466',
  partially_paid: '#ffa234',
  to_be_invoiced: '#19c8d2',
  opened: '#f5dc61',
};
