<div
  *nzModalTitle
  class="
    by-flex
    by-flex-middle
    by-tableau-actions-modal-title
    by-tableau-actions-modal-title--bordered
  "
>
  <strong class="by-fs-16 by-mr-5">
    {{ params.row.data.label }}
  </strong>
  <span class="by-fs-11"
    >[{{ params.row.data.accommodation_details.name }}]</span
  >
</div>

<div class="by-tableau-choose-action__wrapper">
  <button
    *ngIf="enabledActions?.createReservation"
    nz-button
    nzType="primary"
    class="
      by-tableau-choose-action__button
      by-tableau-choose-action__button--create-reservation
    "
    (click)="onTriggerAction('createReservation')"
  >
    <i class="by-mr-10 fas fa-pen"></i>
    {{ 'new_reservation' | translate | upperfirst }}
  </button>

  <button
    *ngIf="enabledActions?.openNoteForm"
    nz-button
    nzType="primary"
    class="
      by-tableau-choose-action__button
      by-tableau-choose-action__button--create-note
    "
    (click)="onTriggerAction('openNoteForm')"
  >
    <i class="by-mr-10 fas fa-paperclip"></i>
    {{ 'new_note' | translate | upperfirst }}
  </button>

  <button
    *ngIf="enabledActions?.closePeriod"
    nz-button
    nzType="primary"
    class="
      by-tableau-choose-action__button
      by-tableau-choose-action__button--close-period
    "
    (click)="onTriggerAction('closePeriod')"
  >
    <i class="by-mr-10 fas fa-lock"></i>
    {{ 'close_period' | translate | upperfirst }}
  </button>

  <button
    *ngIf="enabledActions?.openPeriod"
    nz-button
    nzType="primary"
    class="
      by-tableau-choose-action__button
      by-tableau-choose-action__button--open-period
    "
    (click)="onTriggerAction('openPeriod')"
  >
    <i class="by-mr-10 fas fa-unlock"></i>
    {{ 'delete_close_period' | translate | upperfirst }}
  </button>
</div>
