import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalTopUpButtonService {
  private hidden$ = new BehaviorSubject(false);

  constructor() {}

  hide() {
    this.hidden$.next(true);
  }

  show() {
    this.hidden$.next(false);
  }

  isHidden() {
    return this.hidden$.asObservable();
  }
}
