import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IPaymentRequest,
  RestorePaymentRequest,
  SendPaymentViaEmail,
  TransferPaymentRequest,
} from '@app/models';

import {
  generateParamArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { LoadAdvanceInvoicesRequestFilters } from '../models/requests/load-advance-invoices.request';
import { PaymentsSearchRequest } from '../models/requests/payments-search.request';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(reservationId: number, reservation_accommodation_id: number[]) {
    const params = generateSearchQuery({
      reservation_accommodation_id,
    });

    return this.http.get(
      `reservation/${reservationId}/payments${params ? '?' + params : ''}`,
    );
  }

  loadDetails(paymentId: number) {
    return this.http.get(`payment/${paymentId}`);
  }

  search({ propertiesIds, ...params }: PaymentsSearchRequest) {
    return this.http.get(
      `payments/lookup?${generateParamArray('property_id', propertiesIds)}${
        params ? '&' + generateSearchQuery(params) : ''
      }`,
    );
  }

  loadAdvances(
    propertiesIds: number[],
    filters: LoadAdvanceInvoicesRequestFilters,
  ) {
    const url = `invoices/advances?${generateParamArray(
      'property_id',
      propertiesIds,
    )}${filters ? '&' + generateSearchQuery(filters) : ''}&show_groups=0`;
    return this.http.get(url);
  }

  create(reservationId: number, data: IPaymentRequest, newVersion = false) {
    let url = `reservation/${reservationId}/payment`;
    if (newVersion) {
      url = `add_payment`;
    }

    data = {
      ...data,
      now: this.dateFormatter.toServerFormat(new Date()),
    } as any;

    return this.http.post(url, data);
  }

  delete(reservationId: number, paymentId: number) {
    if (reservationId) {
      return this.http.delete(
        `reservation/${reservationId}/payment/${paymentId}`,
      );
    } else {
      return this.http.delete(`payment/${paymentId}`);
    }
  }

  update(
    reservationId: number,
    paymentId: number,
    data: IPaymentRequest,
    newVersion: boolean,
  ) {
    let url = `reservation/${reservationId}/payment/${paymentId}`;

    if (newVersion) {
      url = `payment/${paymentId}`;
    }

    return this.http.put(url, data);
  }

  sendViaEmail(data: SendPaymentViaEmail) {
    const { paymentId, lang } = data;
    return this.http.post(
      `payments/${paymentId}/send_email?lang=${lang}`,
      data,
    );
  }

  transfer(data: TransferPaymentRequest) {
    const { reservationId, reservationPaymentIds, destinationReservationId } =
      data || {};
    return this.http.post(
      `reservation/${reservationId}/payments/move?${generateSearchQuery({
        reservation_payment_ids: reservationPaymentIds,
        destination_reservation_id: destinationReservationId,
      })}`,
      {},
    );
  }

  restore(data: RestorePaymentRequest) {
    const { reservationId, paymentId } = data || {};
    return this.http.post(
      `reservation/${reservationId}/payments/${paymentId}/restore`,
      {},
    );
  }
}
