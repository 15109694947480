import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { ArchiveType } from '../models';
import { SearchParams } from '../models/objects/search-params';

import { InvoicesReceiptsService } from './invoices-receipts.service';
import { InvoicesService } from './invoices.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceArchivesService {
  constructor(
    private http: HttpClient,
    private invoicesService: InvoicesService,
    private invoicesReceiptsService: InvoicesReceiptsService,
  ) {}

  load(properties: number[], type) {
    return this.http.get(
      `invoices/invoices_archives?${generateParamArray(
        'property_id',
        properties,
      )}&type=${type}`,
    );
  }

  create(params: SearchParams, archiveExportFormat: ArchiveType) {
    return this.invoicesService.load({
      ...params,
      archive_export: 1,
      archive_export_format: archiveExportFormat,
    });
  }

  createInvoicesReceiptsArchive(
    params: SearchParams,
    archiveExportFormat: ArchiveType,
  ) {
    if (!params.vat_code) {
      return;
    }

    const { vat_code, ...datafilter } = params;

    return this.invoicesReceiptsService.load(vat_code as string, {
      ...datafilter,
      archive_export: 1,
      archive_export_format: archiveExportFormat,
    });
  }

  delete(id: number) {
    return this.http.delete(`invoices/invoice_archive/${id}`);
  }

  cancelCreation(id: number) {
    return this.http.delete(`invoices/invoice_archive/abort/${id}`);
  }

  updateProgressPercentages(ids: number[]) {
    return this.http.get(
      `invoices/invoice_archive/read?${generateParamArray(
        'queue_invoice_archive_ids',
        ids,
      )}`,
    );
  }
}
