import { Component, Input, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import { TableauActionParams } from '../../../../models';
import { RootState } from '../../../../root-store/root-state';
import * as TableauActions from '../../../../root-store/tableau-store/actions';
import { ModalDragService } from '../../../../services/modal-drag.service';

@Component({
  selector: 'by-tableau-closure-modal',
  templateUrl: './tableau-closure-modal.component.html',
  styleUrls: ['./tableau-closure-modal.component.scss'],
  providers: [ModalDragService],
})
export class TableauClosureModalComponent implements OnInit {
  readonly nzModalData: Partial<TableauClosureModalComponent> =
    inject(NZ_MODAL_DATA);

  params: TableauActionParams = this.nzModalData.params;

  action: 'open' | 'close' = this.nzModalData.action;

  saving = false;

  dates = this.formBuilder.control(null);

  constructor(
    private modalRef: NzModalRef,
    private store: Store<RootState>,
    private formBuilder: UntypedFormBuilder,
    private modalDragService: ModalDragService,
    private dateFormatter: DateFormatterService,
  ) {
    this.modalDragService.handle(this.modalRef);
  }

  ngOnInit() {
    const { dateFrom, dateTo } = this.params;
    this.dates.setValue([dateFrom, dateTo]);
  }

  onCancel() {
    this.modalRef.close();
  }

  onSave() {
    this.saving = true;

    const { accommodation_details, id } = this.params.row.data;
    const [dateFrom, dateTo] = this.dates.value;

    this.store.dispatch(
      TableauActions.setClosureRequest({
        request: {
          accommodation_tableau_number_id: id,
          accommodation_id: accommodation_details.id,
          date_from: this.dateFormatter.toServerFormat(dateFrom),
          date_to: this.dateFormatter.toServerFormat(dateTo),
          action: this.action,
        },
        onSuccess: () => {
          this.saving = false;
          this.modalRef.close();
        },
        onFailure: () => {
          this.saving = false;
        },
      }),
    );
  }
}
