import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContentAPILeadTimeService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('general/content_api?field=booking_lead_time');
  }
}
