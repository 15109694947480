import { Injectable } from '@angular/core';
import { isValid } from 'date-fns';
import { isNil, omitBy } from 'lodash';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateFormatterService {
  public toServerFormat(date: Date | string) {
    return date && moment(date).format('YYYY-MM-DD');
  }

  public toUiFormat(date: Date) {
    return date && moment(date).format('DD/MM/YYYY');
  }

  formatObjectDates(object: Object): any {
    return Object.keys(object).reduce((data, key) => {
      const value = object[key];
      const isDate = value instanceof Date;
      return omitBy(
        {
          ...data,
          [key]: isDate ? this.toServerFormat(value) : value,
        },
        (i) => isNil(i) || i === '',
      );
    }, {});
  }

  formatTime(time: string) {
    return moment(time).format('HH:mm');
  }

  toServerFormatWithTime(
    date: Date | string,
    formatTime = 'YYYY-MM-DD HH:mm:ss',
  ) {
    if (!date || !isValid(date)) {
      return null;
    }
    return moment(date).format(formatTime);
  }
}
