import {
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import { ByClass } from '../../models';

@Component({
  selector: 'by-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent implements OnInit {
  @Input()
  title = '';

  @Input()
  noBlue = false;

  @Input()
  titleTemplate: TemplateRef<any>;

  @Input()
  byStyle = {};

  @Input()
  byClass = '';

  @Input()
  byContentStyle = {};

  @Input()
  byTitleStyle = {};

  @Input()
  byContentContainerClass: ByClass = 'flex-content';

  @Input()
  icon = null;

  @Input()
  draggable = false;

  @Input()
  size: 'default' | 'small' = 'default';

  @ContentChild('title')
  titleContentTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
