import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import { omit } from 'lodash';
import { FeesExportRequest } from '../models/requests/fees-request';
import { removeNullishValues } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class FeesService {
  constructor(private http: HttpClient) {}

  load(
    invoice_layout_id: number[],
    date_from: string,
    date_to: string,
    propertiesIds: number[],
    options,
  ) {
    return this.http.get(
      `invoices/fees?${generateParamArray(
        'invoice_layout_id',
        invoice_layout_id,
      )}&date_from=${date_from}&date_to=${date_to}${
        options.invoice_types
          ? '&' + generateParamArray('invoice_types', options.invoice_types)
          : ''
      }&type=${options.view || 'block'}&${generateParamArray(
        'property_id',
        propertiesIds,
      )}`,
    );
  }

  export(params: FeesExportRequest) {
    const {
      property_id,
      show_groups,
      invoice_layout_id,
      export_format,
      isDownload,
      payload,
    } = params || {};

    return this.http.post(
      `invoices/fees/print?show_groups=${show_groups}&export=${+isDownload}&${generateSearchQuery(
        removeNullishValues({ property_id, invoice_layout_id, export_format }),
      )}`,
      { ...payload },
    );
  }
}
