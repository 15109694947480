export enum TableauRowComponents {
  Event = 'TableauEventRowComponent',
  Property = 'TableauPropertyRowComponent',
  PropertyStats = 'TableauPropertyStatsRowComponent',
  Accommodation = 'TableauAccommodationRowComponent',
  Floor = 'TableauFloorRowComponent',
  Room = 'TableauRoomRowComponent',
  Ob = 'TableauObRowComponent',
  Footer = 'TableauFooterRowComponent',
}

export enum TableauBoxComponents {
  Reservation = 'TableauReservationBoxComponent',
  Quote = 'TableauQuoteBoxComponent',
  Note = 'TableauNoteBoxComponent',
}
