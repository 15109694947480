import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrmCartItem } from '@commons/crm/models/crm-cart-item';

import { removeNullishValues } from '../helpers';
import { CrmCart } from '../features/commons/crm/models/crm-cart';

@Injectable({
  providedIn: 'root',
})
export class PriceQuotationService {
  constructor(private http: HttpClient) {}

  load(priceQuotationId: number) {
    return this.http.get(`reservations/quote/${priceQuotationId}`);
  }

  update(priceQuotationId: number, data: any) {
    return this.http.put(`reservations/quote/${priceQuotationId}`, data);
  }

  sendEmail(priceQuotationId: number, email_address: string[]) {
    return this.http.post(`reservations/quote/${priceQuotationId}/send_email`, {
      email_address: email_address,
    });
  }

  updateCartItem(
    priceQuotationId: number,
    cartId: number,
    cartItemId: number,
    cartItem: Partial<CrmCartItem>,
  ) {
    return this.http.put(
      `reservations/quote/${priceQuotationId}/option/${cartId}/accommodation/${cartItemId}`,
      cartItem,
    );
  }

  deleteCartItem(priceQuotationId: number, cartId: number, cartItemId: number) {
    return this.http.delete(
      `reservations/quote/${priceQuotationId}/option/${cartId}/accommodation/${cartItemId}`,
    );
  }

  addCartItem(priceQuotationId: number, cartId: number, cartItem: CrmCartItem) {
    return this.http.post(
      `reservations/quote/${priceQuotationId}/option/${cartId}/accommodation`,
      cartItem,
    );
  }

  addCart(priceQuotationId: number) {
    return this.http.post(`reservations/quote/${priceQuotationId}/option`, {});
  }

  deleteCart(priceQuotationId: number, cartId: number) {
    return this.http.delete(
      `reservations/quote/${priceQuotationId}/option/${cartId}`,
    );
  }

  updateCart(
    priceQuotationId: number,
    optionId: number,
    cart: Partial<CrmCart>,
  ) {
    return this.http.put(
      `reservations/quote/${priceQuotationId}/option/${optionId}`,
      cart,
    );
  }

  confirmOption(
    priceQuotationId: number,
    cartId: number,
    force_operation: boolean,
  ) {
    let payload = {};

    if (force_operation) {
      payload = { force_operation: 1 };
    }

    return this.http.post(
      `reservations/quote/${priceQuotationId}/option/${cartId}/confirm_option_reservation`,
      payload,
    );
  }

  uploadPriceQuotationMedia(file: any, reservation_quote_id: number) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(
      `reservations/quote/${reservation_quote_id}/attachment`,
      formData,
    );
  }

  deletePriceQuotationMedia(
    reservation_quote_attachment_id: number,
    reservation_quote_id: number,
  ) {
    return this.http.delete(
      `reservations/quote/${reservation_quote_id}/attachment/${reservation_quote_attachment_id}`,
    );
  }

  addMandatoryAddons(
    priceQuotationId: number,
    cartId: number,
    cartItemId: number,
  ) {
    return this.http.post(
      `reservations/quote/${priceQuotationId}/option/${cartId}/mandatoryaddon`,
      removeNullishValues({ booking_engine_cart_acc_id: cartItemId }),
    );
  }

  deleteMandatoryAddons(priceQuotationId: number, cartId: number) {
    return this.http.delete(
      `reservations/quote/${priceQuotationId}/option/${cartId}/mandatoryaddon`,
    );
  }
}
