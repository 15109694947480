import {
  CurrentRTState,
  FiscalPrinterRTStatus,
  RTDeviceType,
  RTExpireDate,
  RTInactivePeriod,
} from '../fiscal-printer-rt-status';

import { EpsonFiscalPrinterResponse } from './epson-fiscal-printer-response';

const rtDeviceTypeMapping = (code: string): RTDeviceType => {
  const map: { [code: string]: RTDeviceType } = {
    I: 'internal',
    E: 'external',
    P: 'palmtop',
    M: 'fiscal_meter_in_rt',
    S: 'server_rt',
  };

  return map[code] || 'not_defined';
};

const currentRTStateMapping = (main: string, sub: string): CurrentRTState => {
  switch (main) {
    case '01':
      switch (sub) {
        case '02':
          return 'missing_certificated';
        case '03':
        case '04':
          return 'incomplete_certificates';
        case '05':
          return 'loaded_certificates';
        case '06':
          return 'census';
        case '07':
          return 'active';
        case '08':
          return 'pre_service';
        default:
          return 'indeterminate_state';
      }

    case '02':
      return 'in_service';
    default:
      return 'indeterminate_state';
  }
};

const inactivePeriodMapping = (code: string): RTInactivePeriod => {
  if (code === '0') {
    return 'false';
  }
  if (code === '1') {
    return 'waiting_for_daily_closure';
  }
  return 'indeterminate_state';
};

const expireDateMapping = (date: string): RTExpireDate => {
  if (!date) {
    return 'not_read';
  }
  if (date.length === 8) {
    return (
      date.substring(6, 8) +
      '/' +
      date.substring(4, 6) +
      '/' +
      date.substring(0, 4)
    );
  }
  return 'wrong_parameter';
};

export class EpsonFiscalPrinterRTStatusParser {
  parse(response: EpsonFiscalPrinterResponse): FiscalPrinterRTStatus {
    return {
      isActive:
        response._attributes.success === 'true' &&
        response._attributes.status === '2',
      rtDeviceType: rtDeviceTypeMapping(response.addInfo.rtType),
      mainState: response.addInfo.rtMainStatus,
      subState: response.addInfo.rtSubStatus,
      currentRTState: currentRTStateMapping(
        response.addInfo.rtMainStatus,
        response.addInfo.rtSubStatus,
      ),
      dayOpened: !!response.addInfo.rtDailyOpen,
      inactivePeriod: inactivePeriodMapping(response.addInfo.rtNoWorkingPeriod),
      filesToSend: +response.addInfo.rtFileToSend,
      oldFilesToSend: +response.addInfo.rtOldFileToSend,
      rejectedFiles: +response.addInfo.rtFileRejected,
      deviceCertificateExpiration: expireDateMapping(
        response.addInfo.rtExpiryCD,
      ),
      caAECertificateExpiration: expireDateMapping(response.addInfo.rtExpiryCA),
      trainingMode:
        response.addInfo.rtTrainingMode !== null
          ? !!+response.addInfo.rtTrainingMode
          : 'not_read',
    };
  }
}
