import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { RelatedUser } from '../models';

@Injectable({ providedIn: 'root' })
export class RelatedUsersServices {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('user/me/related_users') as Observable<
      IResponseSuccess<RelatedUser[]>
    >;
  }
}
