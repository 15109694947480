import { PlaceVatQuote, VatQuotesSettings } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getVatQuotes = (state: State): PlaceVatQuote[] => state.quotes;

export const selectPlaceVatQuotesState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('placeVatQuotes');

export const selectVatQuotesData: MemoizedSelector<
  object,
  PlaceVatQuote[]
> = createSelector(selectPlaceVatQuotesState, getVatQuotes);

export const selectVatQuotesError: MemoizedSelector<
  object,
  any
> = createSelector(selectPlaceVatQuotesState, getError);

export const selectVatQuotesLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectPlaceVatQuotesState, getIsLoading);

export const selectVatQuotesGroupedById: MemoizedSelector<
  object,
  { [vatQuoteId: number]: PlaceVatQuote }
> = createSelector(selectPlaceVatQuotesState, (state: State) =>
  (state.quotes || []).reduce(
    (groupedVatQuotes, vatQuote) =>
      (groupedVatQuotes = { ...groupedVatQuotes, [vatQuote.id]: vatQuote }),
    {},
  ),
);
