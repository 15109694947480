<form nz-form [formGroup]="form" [nzLayout]="formLayout">
  <nz-form-item
    class="by-reservation-form__item"
    [class.by-reservation-form__item--gray]="!companyFormItemIsVisible"
  >
    <nz-form-label>
      {{ 'reservation_from' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
    >
      <nz-select
        nzShowSearch
        [nzDropdownMatchSelectWidth]="false"
        [nzSize]="inputSize"
        formControlName="reservation_from_id"
      >
        <nz-option
          *ngFor="let reservationFrom of reservationFroms"
          [nzLabel]="reservationFrom.name"
          [nzValue]="reservationFrom.id"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item
    class="by-reservation-form__item"
    [class.by-reservation-form__item--gray]="companyFormItemIsVisible"
  >
    <nz-form-label>
      {{ 'booker_type' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
    >
      <nz-radio-group
        nzButtonStyle="solid"
        [nzSize]="inputSize"
        formControlName="dealer_id"
      >
        <label nz-radio-button [nzValue]="1"
          >{{ 'customer' | translate | capitalize }}
        </label>
        <label nz-radio-button [nzValue]="2"
          >{{ 'company' | translate | capitalize }}
        </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item
    [hidden]="!companyFormItemIsVisible"
    class="by-reservation-form__item"
  >
    <nz-form-label>
      {{ 'company' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control
      [class.by-full-width-control]="isMobile"
      class="by-reservation-form__control"
      [nzErrorTip]="
        'validation_form.' + (companyIdControl.errors | keys | head) | translate
      "
    >
      <div class="by-flex by-flex-middle">
        <a
          (click)="createCompany.emit()"
          nz-tooltip
          [nzTooltipTitle]="'new_company' | translate | upperfirst"
        >
          <i class="fas fa-plus color--green by-mr-10 by-fs-15"></i>
        </a>

        <by-companies-lookup
          #companiesLookup
          formControlName="company_id"
          [size]="inputSize"
          [placeHolder]="'search' | translate | upperfirst"
          [allowClear]="true"
          (customerSelected)="onCompanySelected($event?.company)"
        ></by-companies-lookup>
      </div>
    </nz-form-control>
  </nz-form-item>

  <by-reservation-form-customer
    #formGetter
    [hidden]="!customerFormIsVisible"
    [selectedCompany]="selectedCompany"
    formControlName="customer"
    [languages]="languages"
    [isMobile]="isMobile"
  ></by-reservation-form-customer>

  <nz-form-item
    class="by-reservation-form__item by-reservation-form__note"
    [class.by-reservation-form__item--gray]="!customerFormIsVisible"
  >
    <nz-form-control [class.by-full-width-control]="isMobile">
      <textarea
        [nzSize]="inputSize"
        formControlName="internal_remarks"
        nz-input
        [nzAutosize]="{ minRows: 2, maxRows: 6 }"
        [placeholder]="
          'placeholder.insert'
            | translate: { param: 'note' | translate }
            | upperfirst
        "
        byPastedHtmlPurifier
      ></textarea>
    </nz-form-control>
  </nz-form-item>

  <by-reservation-form-summary
    #formGetter
    [firstStepRequest]="firstStepRequest"
    [estimateResponse]="estimateResponse"
    [estimateRateplans]="estimateRateplans"
    [accommodation]="accommodation"
    [proportionalDayPriceResponse]="proportionalDayPriceResponse"
    [currency]="currency"
    [priceLoading]="priceLoading"
    [isMobile]="isMobile"
    (proportionalDayPrice)="proportionalDayPrice.emit($event)"
    (priceChanged)="priceChanged.emit($event)"
    (discountChanged)="discountChanged.emit()"
    (addAddons)="addAddons.emit()"
  ></by-reservation-form-summary>

  @if (automaticPaymentSummary?.length && automaticPaymentModule) {
    <by-spin [nzSpinning]="automaticPaymentsLoading">
      <by-reservation-form-automatic-payment
        [automaticPaymentSummary]="automaticPaymentSummary"
        [currency]="currency"
        [isMobile]="isMobile"
        [inputSize]="inputSize"
        formControlName="payment_policy_id"
        class="by-mt-15"
    /></by-spin>
  }

  <by-reservation-form-notification
    #formGetter
    formControlName="notification"
    [selectedCustomer]="selectedCustomer"
    [selectedCompany]="selectedCompany"
    [customEmailTeplates]="customEmailTeplates"
    (showEmailPreview)="showEmailPreview.emit()"
  ></by-reservation-form-notification>
</form>
