import { Media } from '../../models';

export interface State {
  data: {
    [type: string]: {
      [type_id: number]: Media[];
    };
  };
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  data: {},
  isLoading: false,
  error: null,
};
