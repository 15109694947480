<by-page-header
  [byStyle]="{ paddingTop: 0 }"
  [title]="
    (invoice?.type === 'receipt' ? 'reversal_receipt' : 'credit_note')
      | translate
      | capitalize
  "
></by-page-header>

<div [formGroup]="form" nz-form nzLayout="vertical">
  <!-- <by-question
  *ngIf="hasDiscounts"
  [label]="'credit_note_include_discounts_question' | translate | upperfirst"
  formControlName="include_discounts"
  [yesValue]="1"
  [noValue]="0"
></by-question> -->

  @if (isDepositPayment) {
    <by-question
      [label]="'do_you_want_release_deposit' | translate | upperfirst"
      formControlName="release_deposit"
      [yesValue]="1"
      [noValue]="0"
    ></by-question>
  }

  <by-question
    [label]="
      (invoice?.type === 'receipt'
        ? 'reversal_payment_question_description'
        : 'credit_note_payment_question_description'
      )
        | translate
        | upperfirst
    "
    formControlName="mark_as_paid"
    [yesValue]="1"
    [noValue]="0"
  ></by-question>

  @if (
    form.value.mark_as_paid &&
    (invoice.reservation_payments | pluck: 'payment_method_id' | hasPosPayments)
  ) {
    <nz-alert
      nzType="warning"
      [nzMessage]="title"
      nzShowIcon
      class="by-pb-10"
    ></nz-alert>
  }

  <ng-template #title>
    <div [innerHtml]="'warning_pos_credit_note' | translate | upperfirst"></div>
  </ng-template>

  @if (form.get('mark_as_paid').value) {
    <nz-form-item>
      <nz-form-label nzRequired>
        <strong>{{ 'payment_method' | translate | capitalize }}</strong>
      </nz-form-label>
      <nz-form-control>
        <nz-select formControlName="payment_method_id">
          <nz-option
            [nzDisabled]="isDepositPayment && !form.value.release_deposit"
            nzCustomContent
            [nzValue]="0"
            [nzLabel]="
              'refound_credit_note_option'
                | translate
                  : {
                      documentType:
                        ((invoice?.type === 'receipt' ? 'receipt' : 'invoice')
                        | translate)
                    }
                | upperfirst
            "
          >
            {{
              'refound_credit_note_option'
                | translate
                  : {
                      documentType:
                        ((invoice?.type === 'receipt' ? 'receipt' : 'invoice')
                        | translate)
                    }
                | upperfirst
            }}
            @if (isDepositPayment && !form.value.release_deposit) {
              <i
                class="fal fa-info-circle pointer color--orange"
                nz-tooltip
                [nzTooltipTitle]="
                  'is_deposit_payment_disabled' | translate | upperfirst
                "
              ></i>
            }
          </nz-option>
          @for (
            paymentMethod of (paymentMethodsGeneric$ | async) || [];
            track paymentMethod.id
          ) {
            @if (
              invoicingAllowedPaymentMethodsIds
                | firstOrDefault: paymentMethod.id
            ) {
              <nz-option
                [nzLabel]="paymentMethod.name"
                [nzValue]="paymentMethod.id"
              ></nz-option>
            }
          }
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  }
</div>
