import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  CreateFundMovementRequest,
  UpdateFundMovementRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class FundMovementsService {
  constructor(private http: HttpClient) {}

  loadById(propertyId: number, fundMovementId: number) {
    return this.http.get(
      `property/${propertyId}/fund_movement/${fundMovementId}`,
    );
  }

  create({ property_id, ...request }: CreateFundMovementRequest) {
    return this.http.post(`property/${property_id}/fund_movement`, {
      ...request,
      payment_method_id: 15,
    });
  }

  update({ property_id, id, ...request }: UpdateFundMovementRequest) {
    return this.http.put(`property/${property_id}/fund_movement/${id}`, {
      ...request,
      payment_method_id: 15,
    });
  }

  delete(propertyId: number, fundMovementId: number) {
    return this.http.delete(
      `property/${propertyId}/fund_movement/${fundMovementId}`,
    );
  }
}
