import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>('estimate');

export const selectEstimate = createSelector(
  selectState,
  (state: State) => state.estimate,
);

export const selectRateplans = createSelector(
  selectState,
  (state: State) => state.rateplans,
);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.estimating || state.fetchingRateplans,
);

export const selectEstimating = createSelector(
  selectState,
  (state: State) => state.estimating,
);

export const selectFetchingRateplans = createSelector(
  selectState,
  (state: State) => state.fetchingRateplans,
);
