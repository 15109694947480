import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { INVOICING_ALLOWED_PAYMENT_METHODS_ID } from '../../../config/invoicing-allowed-payment-methods.config';
import { InvoiceDetails } from '../../../models';
import { PaymentMethodsStoreSelectors } from '../../../root-store/payment-methods-store';
import { RootState } from '../../../root-store/root-state';

@Component({
  selector: 'by-reverse-autoinvoice-register-modal-register-modal',
  templateUrl: './reverse-autoinvoice-register-modal.component.html',
  styleUrls: ['./reverse-autoinvoice-register-modal.component.scss'],
})
export class ReverseAutoinvoiceRegisterModalComponent implements OnInit {
  @Input() invoice: InvoiceDetails;
  @Input() isDepositPayment: boolean;

  paymentMethodsGeneric$ = this.store.pipe(
    select(PaymentMethodsStoreSelectors.selectAllPaymentMethodsItems),
  );

  form: UntypedFormGroup;

  invoicingAllowedPaymentMethodsIds = INVOICING_ALLOWED_PAYMENT_METHODS_ID;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<RootState>,
  ) {
    this.form = this.formBuilder.group({
      payment_method_id: [0],
      mark_as_paid: [0],
      release_deposit: [0],
    });
  }

  get value() {
    const { payment_method_id, mark_as_paid } = this.form.value;

    if (mark_as_paid && +payment_method_id > 0) {
      return this.form.value;
    }

    return { ...this.form.value, payment_method_id: null };
  }

  ngOnInit() {
    if (this.isDepositPayment) {
      this.form.get('payment_method_id').setValue(15);
    }
  }
}
