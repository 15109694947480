import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

class AppShellElement {
  private show$ = new BehaviorSubject<boolean>(true);

  setShow(show: boolean) {
    this.show$.next(show);
  }

  getShow(): Observable<boolean> {
    return this.show$.asObservable();
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppShellService {
  public footer = new AppShellElement();
  public header = new AppShellElement();
}
