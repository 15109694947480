import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { DateFormatterService } from '../../core/services/date-formatter.service';
import { Company, CompanyLookup, CustomersLookupOption } from '../../models';
import { AppStore } from '../../models/types/app-store';
import { CompaniesService, CustomersService } from '../../services';
import { CustomersLookupComponent } from '../customers-lookup/customers-lookup.component';

@Component({
  selector: 'by-companies-lookup',
  templateUrl: './companies-lookup.component.html',
  styleUrls: ['./companies-lookup.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CompaniesLookupComponent,
      multi: true,
    },
  ],
})
export class CompaniesLookupComponent extends CustomersLookupComponent {
  /**
   * @override
   */
  searchIn: Array<'customers' | 'companies'> = ['companies'];

  constructor(
    protected store: Store<AppStore>,
    protected customersService: CustomersService,
    protected companiesService: CompaniesService,
    protected dateFormatter: DateFormatterService,
    protected formBuilder: UntypedFormBuilder,
    protected modalService: NzModalService,
    protected translate: TranslateService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(
      store,
      customersService,
      companiesService,
      dateFormatter,
      formBuilder,
      modalService,
      translate,
      cdr,
    );
  }

  /**
   * @override
   */
  getCompanyId(company: CompanyLookup) {
    return company.id;
  }

  /**
   * @override
   */
  getOptionFromId(companyId: number): Observable<CustomersLookupOption> {
    return this.companiesService.loadDetails(companyId).pipe(
      map(({ data: [company] }: IResponseSuccess<Company[]>) => {
        return {
          id: company.id,
          name: `${company.name}${
            company.vat_code ? ` (${company.vat_code})` : ''
          }`,
          icon: this.COMPANY_ICON,
        };
      }),
    );
  }
}
