import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  inject,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { get, isNil, omit, omitBy } from 'lodash';
import moment from 'moment';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { SubSink } from 'subsink';

import { datapickerDisabledStartDateFormArray } from '../../../../core/datapickers/datapicker-disabled-date';
import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import { LimitDaysValidator } from '../../../../core/validators/limit-days.validator';
import {
  BookingPromotion,
  BookingRangeDates,
  BookingTargetItem,
  BookingTargetsPromotionType,
  CreateBookingPromotionRequest,
  EditBookingPromotionRequest,
} from '../../../../models';

@Component({
  selector: 'by-booking-promotion-form',
  templateUrl: './booking-promotion-form.component.html',
  styleUrls: ['./booking-promotion-form.component.scss'],
})
export class BookingPromotionFormComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<BookingPromotionFormComponent> =
    inject(NZ_MODAL_DATA);

  @Input('property_xml_id') set _property_xml_id(property_xml_id: string) {
    if (!property_xml_id) {
      return;
    }
    this.property_xml_id = property_xml_id;
  }

  @Input('type') set _type(type: BookingTargetsPromotionType) {
    this.type = type;

    if (this.type && !this.form.get('type').value) {
      this.form.patchValue({ type: this.type });
    }
  }

  @Input('targetOptions') set _targetOptions(
    targetOptions: BookingTargetItem[],
  ) {
    this.targetOptions = targetOptions;

    if (
      this.targetOptions &&
      this.type &&
      !this.form.get('target_channel').value
    ) {
      this.form.patchValue({
        target_channel:
          this.type === 'mobile_rate'
            ? this.targetOptions[0]?.target_channel
            : [this.targetOptions[0]?.target_channel],
      });
    }
  }

  @Input('xmlOptions') set _xmlOptions(xmlOptions: string[]) {
    if (!xmlOptions || !xmlOptions.length) {
      return;
    }
    this.xmlOptions = xmlOptions;
    if (!this.form.get('property_xml_id').value) {
      this.form.patchValue({
        property_xml_id: this.xmlOptions[0],
      });
    }
  }

  @Input('isLoading') set _isLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Input('promotion') set _promotion(promotion: BookingPromotion) {
    this.promotion = promotion;

    if (this.promotion) {
      const {
        type,
        target_channel: targetChannle,
        excluded_dates: exludedDates,
        id,
      } = this.promotion;
      const target_channel =
        type === 'mobile_rate' ? targetChannle : [targetChannle];

      const excluded_dates = this.buildRanges(exludedDates);

      excluded_dates.forEach((dates, index) => {
        this.excludedDatesControl.setControl(
          index,
          new UntypedFormControl(dates, [Validators.required]),
        );
      });

      if (excluded_dates.length) {
        this.showExludedDates.setValue(true);
      }

      this.form.patchValue({
        ...omit(this.promotion, 'excluded_dates'),
        promotion_id: id,
        target_channel,
      });
    }
  }

  @Input('showXmlSelect') set _showXmlSelect(showXmlSelect: boolean) {
    this.showXmlSelect = showXmlSelect;
  }

  @Output() save = new EventEmitter<
    CreateBookingPromotionRequest | EditBookingPromotionRequest
  >();

  type: BookingTargetsPromotionType = null;
  targetOptions: BookingTargetItem[] = [];
  xmlOptions: string[] = [];
  isLoading = false;
  promotion: BookingPromotion;
  showXmlSelect = true;

  form = this.formBuilder.group({
    promotion_id: [null],
    discount: [
      10,
      [Validators.required, Validators.min(10), Validators.max(80)],
    ],
    excluded_dates: this.formBuilder.array([], LimitDaysValidator(30)),
    type: [null, [Validators.required]],
    target_channel: [null, [Validators.required]],
    property_xml_id: [null, [Validators.required]],
  });

  showExludedDates = new UntypedFormControl(false);

  private subs = new SubSink();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dateFormatter: DateFormatterService,
    @Optional() private modalRef: NzModalRef,
  ) {
    this._property_xml_id = this.nzDataModal._property_xml_id;
    this._type = this.nzDataModal._type;
    this._targetOptions = this.nzDataModal?._targetOptions || [];
    this._xmlOptions = this.nzDataModal?._xmlOptions || [];
    this._isLoading = this.nzDataModal._isLoading;
    this._promotion = this.nzDataModal._promotion;
    this._showXmlSelect = this._showXmlSelect;
  }

  ngOnInit(): void {
    this.subs.add(
      this.excludedDatesControl.valueChanges.subscribe((dates) => {
        if (!dates.length) {
          this.showExludedDates.setValue(false, { emitEvent: false });
        }
        this.excludedDatesControl.controls.forEach((control) => {
          setTimeout(() => {
            this.checkDuplicateForm();
          });
        });
      }),
    );
    this.subs.add(
      this.showExludedDates.valueChanges.subscribe((show) => {
        if (show && !this.excludedDdatesValue?.length) {
          this.addExludendDateRange(null);
          return;
        }
      }),
    );
  }

  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace('%', '');

  addExludendDateRange(date?: Date[]) {
    const newControl = new UntypedFormControl(date, [Validators.required]);
    this.excludedDatesControl.push(newControl);
  }

  checkDuplicateForm() {
    this.excludedDatesControl.controls.forEach((control) => {
      if (!control.value) {
        return;
      }
      const [from, to] = control.value;
      const indexesToRemove = [];
      this.excludedDatesControl.controls.forEach((range, index) => {
        if (!range.value) {
          return;
        }
        const [rangeFrom, rangeTo] = range.value;
        if (
          moment(from).startOf('day').isSame(moment(rangeFrom), 'days') &&
          moment(to).startOf('day').isSame(moment(rangeTo), 'days')
        ) {
          return;
        }
        if (
          moment(rangeFrom)
            .startOf('day')
            .isSameOrAfter(moment(from), 'days') &&
          moment(rangeTo).startOf('day').isSameOrBefore(moment(to), 'days')
        ) {
          indexesToRemove.push(index);
        }
      });

      indexesToRemove.forEach((index) =>
        this.excludedDatesControl.removeAt(index),
      );
    });
  }

  get targetChannel() {
    return this.form.get('target_channel').value;
  }

  get excludedDatesControl(): UntypedFormArray {
    return this.form.get('excluded_dates') as UntypedFormArray;
  }

  get excludedDdatesValue() {
    return this.form.get('excluded_dates').value;
  }

  get property_xml_id() {
    return get(this.form.value, ['property_xml_id', 0], null);
  }

  set property_xml_id(property_xml_id: string) {
    this.form.get('property_xml_id').setValue(property_xml_id);
  }

  get mode() {
    return this.type === 'geo_rate' ? 'multiple' : 'default';
  }

  get modalInstance() {
    return this.modalRef;
  }

  disableDate = (current: Date) => {
    const { excluded_dates } = this.form.value;
    const formPeriodsFiltered = excluded_dates
      .filter((rangeDate) => rangeDate)
      .map((rangeDate) => ({
        date_to: rangeDate[1],
        date_from: rangeDate[0],
      }));
    return datapickerDisabledStartDateFormArray(
      formPeriodsFiltered,
      current,
      'date_to',
      'date_from',
    );
  };

  onDeleteExludedDates(index: number) {
    this.excludedDatesControl.removeAt(index);
  }

  getDaysBetweenDates(startDate: Date, endDate: Date): string[] {
    const now = moment(startDate).clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(this.dateFormatter.toServerFormat(now.toDate()));
      now.add(1, 'days');
    }
    return dates;
  }

  buildRanges(excluded_dates: BookingRangeDates[]): Date[][] {
    return excluded_dates.map(({ date_from, date_to }) => [
      new Date(date_from),
      new Date(date_to),
    ]);
  }

  get value(): CreateBookingPromotionRequest | EditBookingPromotionRequest {
    const { target_channel, excluded_dates } = this.form.value;
    const value: CreateBookingPromotionRequest | EditBookingPromotionRequest = {
      ...this.form.value,
      target_channel:
        this.type === 'mobile_rate' ? [target_channel] : target_channel,
      excluded_dates: !this.showExludedDates.value
        ? []
        : excluded_dates.map((dates) => ({
            date_from: get(dates, [0], null)
              ? this.dateFormatter.toServerFormat(dates[0])
              : null,
            date_to: get(dates, [1], null)
              ? this.dateFormatter.toServerFormat(dates[1])
              : null,
          })),
    };

    return omitBy(value, isNil) as
      | CreateBookingPromotionRequest
      | EditBookingPromotionRequest;
  }

  get pageTitle() {
    return `booking_messages.${this.type}.create`;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
