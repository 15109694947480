import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

import { generateParamArray } from '../core/helpers/params-generator';
import { removeUndefinedValues } from '../helpers';
import { CreateExpenseRequest, UpdateExpenseRequest } from '../models';

import { PaymentsService } from './payments.service';

@Injectable({
  providedIn: 'root',
})
export class ExpensesService {
  constructor(
    private http: HttpClient,
    private paymentsService: PaymentsService,
  ) {}

  load(propertiesIds: number[], params: HttpParams) {
    return this.http.get(
      `expenses?${generateParamArray('property_id', propertiesIds)}`,
      { params },
    );
  }

  loadDetails(expenseId: number) {
    return this.http.get(`expense/${expenseId}`);
  }

  create(payload: CreateExpenseRequest) {
    return this.http.post('expense', payload);
  }

  update(payload: UpdateExpenseRequest) {
    const { payments, id: expenseId, ...expenseData } = payload;

    const requests = [
      ...payments.map(({ id, ...payment }) =>
        this.paymentsService.update(
          null,
          id,
          removeUndefinedValues({
            ...expenseData,
            ...payment,
            label: expenseData?.title,
          }),
          true,
        ),
      ),
      this.http.put(`expense/${expenseId}`, expenseData),
    ];

    return forkJoin(...requests.map((request) => request));
  }

  delete(expenseId: number) {
    return this.http.delete(`expense/${expenseId}`);
  }
}
