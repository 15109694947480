import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import fromActions from './actions';
import { ErrorHandlerService } from '../../../core/services/error-handler.service';
import { AutomaticPaymentsSummaryService } from '../../../services';

@Injectable()
export class AutomaticPaymentsSummaryStoreEffects {
  constructor(
    private dataService: AutomaticPaymentsSummaryService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadFromTableau$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFromTableauRequest),
      switchMap(({ data }) =>
        this.dataService.loadFromTableau(data).pipe(
          map((response) =>
            fromActions.loadSuccess({
              automaticPaymentsSummary: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handleErrors(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadFromBookingEngine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFromBookingEngineRequest),
      switchMap(({ booking_engine_cart_id }) =>
        this.dataService.loadFromBookingEngine(booking_engine_cart_id).pipe(
          map((response) =>
            fromActions.loadSuccess({
              automaticPaymentsSummary: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handleErrors(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadFromPriceQuotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFromPriceQuotationRequest),
      switchMap(({ reservation_quote_id, reservation_quote_option_id }) =>
        this.dataService
          .loadFromPriceQuotation(
            reservation_quote_id,
            reservation_quote_option_id,
          )
          .pipe(
            map((response) =>
              fromActions.loadSuccess({
                automaticPaymentsSummary: response.data[0],
              }),
            ),
            catchError((error) => {
              this.errorHandler.handleErrors(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );
}
