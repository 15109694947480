import { NgModule } from '@angular/core';
import {
  NgCapitalizePipeModule,
  NgDefaultsPipeModule,
  NgFirstOrDefaultPipeModule,
  NgGroupByPipeModule,
  NgHeadPipeModule,
  NgIntersectionPipeModule,
  NgIsNilPipeModule,
  NgJoinPipeModule,
  NgKeysPipeModule,
  NgLastPipeModule,
  NgLeftPadPipeModule,
  NgMapPipeModule,
  NgOrderByPipeModule,
  NgPluckPipeModule,
  NgRangePipeModule,
  NgReplacePipeModule,
  NgSplitPipeModule,
  NgSumPipeModule,
  NgToArrayPipeModule,
  NgTrimPipeModule,
  NgTruncatePipeModule,
  NgUnionPipeModule,
  NgUpperFirstPipeModule,
  NgWherePipeModule,
  NgWithoutPipeModule,
} from '@z-trippete/angular-pipes';

@NgModule({
  exports: [
    NgUpperFirstPipeModule,
    NgKeysPipeModule,
    NgHeadPipeModule,
    NgFirstOrDefaultPipeModule,
    NgCapitalizePipeModule,
    NgOrderByPipeModule,
    NgGroupByPipeModule,
    NgRangePipeModule,
    NgTrimPipeModule,
    NgIsNilPipeModule,
    NgTruncatePipeModule,
    NgWherePipeModule,
    NgLastPipeModule,
    NgWithoutPipeModule,
    NgPluckPipeModule,
    NgJoinPipeModule,
    NgSplitPipeModule,
    NgLeftPadPipeModule,
    NgReplacePipeModule,
    NgMapPipeModule,
    NgIntersectionPipeModule,
    NgDefaultsPipeModule,
    NgToArrayPipeModule,
    NgUnionPipeModule,
    NgSumPipeModule,
  ],
})
export class NgPipesModule {}
