import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Providers } from '../config';
import { TokenService } from '../core/services/token.service';
import { ChangePasswordRequest, UpdateUserMeRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserMeService {
  private httpClientWithoutInterceptor: HttpClient;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private tokenService: TokenService,
  ) {
    this.httpClientWithoutInterceptor = new HttpClient(this.httpBackend);
  }

  load() {
    return this.http.get('user/me');
  }

  update({ id, ...payload }: UpdateUserMeRequest) {
    return this.http.put('user/me', payload);
  }

  changePassword(request: ChangePasswordRequest) {
    return this.http.post('user/me/change_password', request);
  }

  setNotificationToken(token: string) {
    return this.http.post('user/me/notifications/token', {
      token,
      provider: Providers.Notifications,
    });
  }

  unsetNotificationToken(token: string) {
    return this.httpClientWithoutInterceptor.delete(
      `${environment.apiUrl}user/me/notifications/token/${Providers.Notifications}/${token}`,
      {
        headers: {
          Authorization: `Bearer ${this.tokenService.get()}`,
        },
      },
    );
  }
}
