import heicConverter from 'heic2any';

const SUPPORTED_MIME_TYPES = ['image/png', 'image/jpeg', 'image/gif'];

/**
 * @param file File da convertire
 * @description Converte un'immagine in formato HEIC/HEIF in JPG e ne esegue la compressione. NB Se l'immagine è già in un formato
 * leggibile da un browser, non viene convertita e nella risposta il valore di isHEIC è false.
 */
export async function heicToJpg(
  file: File,
): Promise<{ file: File; isHEIC: boolean }> {
  if (file.type) {
    return Promise.resolve({ isHEIC: false, file });
  }

  // Try to convert HEIC file
  try {
    const blob = (await heicConverter({
      blob: file,
      toType: 'image/jpeg',
      quality: 0,
    })) as Blob;

    return Promise.resolve({
      isHEIC: true,
      file: new File([blob], file.name, { type: blob.type }),
    });
  } catch (error) {
    // If HEIC conversion failed, check if file is supported
    if (error?.code === 1) {
      const type = SUPPORTED_MIME_TYPES.find((supportedMIMEType) =>
        error.message?.includes(supportedMIMEType),
      );

      if (type) {
        return Promise.resolve({
          isHEIC: false,
          file: new File([file], file.name, { type }),
        });
      }
    }

    return Promise.resolve({ isHEIC: false, file });
  }
}
