import { createSelector } from '@ngrx/store';
import { mapValues } from 'lodash';

import { DEFAULT_COLORS } from '../core/helpers/default-colors';
import { selectUserPreferencesDataByCategory } from '../root-store/user-preferences-store/selectors';

export const selectColorsPreferences = createSelector(
  selectUserPreferencesDataByCategory('tableau'),
  (preferredColors) => {
    if (!preferredColors) {
      return DEFAULT_COLORS;
    }

    return mapValues(DEFAULT_COLORS, (value, key) => {
      return preferredColors[key + '-color'] || value;
    });
  },
);
