import { AddonsEstimateTotals } from '../../models';

export interface State {
  isLoading?: boolean;
  error?: any;
  totalsAddons: AddonsEstimateTotals;
  totalsBills: AddonsEstimateTotals;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  totalsAddons: {},
  totalsBills: {},
};
