import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChannelOptionsService {
  constructor(private http: HttpClient) {}

  load(channelId: number) {
    return this.http.get(`channelmanager/options/${channelId}`);
  }

  loadExtraOptions(
    channelId: number,
    propertyId: number,
    fields: { [field: string]: any },
  ) {
    return this.http.post(
      `property/${propertyId}/channel/${channelId}/get_options`,
      fields,
    );
  }
}
