import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { merge } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { IResponseSuccess } from '../../../core/models/response-sucess.model';
import { DateFormatterService } from '../../../core/services/date-formatter.service';
import {
  IInvoiceLayout,
  InvoiceDetails,
  IReverseAutoinvoiceRequestRoot,
  RegisterInvoice,
} from '../../../models';
import { InvoiceRegisterServiceParams } from '../../../models/objects/invoice-register-service-params';
import { InvoicesLayoutsService } from '../../../services/invoices-layouts.service';
import { TemporaryInvoiceService } from '../../../services/temporary-invoice.service';
import { WarningConfirmModalService } from '../../../services/warning-confirm-modal.service';
import { NotificationService } from '../../../ui/services/notification.service';
import { InvoiceRegisterModalOutput } from '../invoice-register-modal/invoice-register-modal.component';
import { ReverseAutoinvoiceRegisterModalComponent } from '../reverse-autoinvoice-register-modal/reverse-autoinvoice-register-modal.component';

import { InvoiceRegisterService } from './invoice-register.service';

@Injectable({ providedIn: 'root' })
export class ReverseAutoinvoiceRegisterService extends InvoiceRegisterService {
  registerLoading: boolean;

  constructor(
    protected modalService: NzModalService,
    protected message: NzMessageService,
    protected translate: TranslateService,
    protected notification: NotificationService,
    protected warningConfirmModalService: WarningConfirmModalService,
    protected dateFormatter: DateFormatterService,
    protected invoicesLayoutsService: InvoicesLayoutsService,
    private temporaryInvoiceService: TemporaryInvoiceService,
  ) {
    super(
      modalService,
      message,
      translate,
      notification,
      warningConfirmModalService,
      dateFormatter,
      invoicesLayoutsService,
    );
  }

  /**
   * @override
   *
   * @description flusso di registrazione dell annullo di un autofattura:
   * - apertura modale annullo autofattura
   * - richiesta xml (se si tratta di uno storno fiscale)
   * - stampa documento fiscale
   * - chiamata di register
   */
  register(params: InvoiceRegisterServiceParams): Observable<InvoiceDetails> {
    const { invoice, layout, registerService } = params;

    return this.getRegisterReverseAutoinvoicePayload(invoice, layout).pipe(
      switchMap((payload) => {
        if (!payload) {
          throw new Error(
            'Register reverse auto invoice note modal closed by user',
          );
        }

        return registerService.createReverseAutoinvoice(payload).pipe(
          switchMap((response: IResponseSuccess<InvoiceDetails[]>) => {
            const reverseAutoinvoice: InvoiceDetails = response.data[0];

            const { sdi_send } = payload;

            const utils =
              this.registerUtilsByType[invoice.type as 'reverse_auto_invoice'];

            const modalOutput$ = new Subject<InvoiceRegisterModalOutput>();

            this.registerLoading = false;

            const modal: NzModalRef = this.modalService.create({
              nzTitle: upperFirst(
                this.translate.instant('register_reverse_auto_invoice'),
              ),
              nzContent: utils.class,
              nzData: {
                invoice: {
                  ...invoice,
                  number: null,
                  invoice_date: null,
                  sectional_id: null,
                },
                layout,
              },
              nzClosable: false,
              nzMaskClosable: false,
              nzFooter: [
                {
                  label: upperFirst(this.translate.instant('cancel')),
                  type: 'default',
                  disabled: () => this.registerLoading,
                  onClick: () => modal.close(),
                },
                {
                  label: upperFirst(
                    this.translate.instant(
                      sdi_send ? 'register_and_send_to_sdi' : 'save',
                    ),
                  ),
                  type: 'primary',
                  loading: () => this.registerLoading,
                  disabled: utils.disabled,
                  onClick: (component) => modalOutput$.next(component.value),
                },
              ],
            });

            return merge(
              modalOutput$.asObservable(),
              modal.afterClose.asObservable(),
            ).pipe(
              switchMap((modalOutput?: InvoiceRegisterModalOutput) => {
                if (!modalOutput) {
                  modal.close();
                  throw new Error('Invoice register modal closed by user');
                }

                const { number, invoice_layout_sectional_id, invoice_date } =
                  modalOutput;
                return this.updateInvoice({
                  ...params,
                  invoice: reverseAutoinvoice,
                }).pipe(
                  switchMap(() =>
                    this.registerRequest(reverseAutoinvoice, payload, {
                      number,
                      invoice_layout_sectional_id,
                      invoice_date: invoice_date,
                    }).pipe(
                      tap(() => {
                        modal.close();
                      }),
                    ),
                  ),
                  catchError((error) => {
                    modal.close();
                    return throwError(error);
                  }),
                );
              }),
            );
          }),
        );
      }),
    );
  }

  private registerRequest(
    reverseAutoinvoice: InvoiceDetails,
    request: IReverseAutoinvoiceRequestRoot,
    partialPayload?: Partial<RegisterInvoice>,
  ) {
    this.registerLoading = true;
    return this.temporaryInvoiceService
      .register(
        this.dateFormatter.formatObjectDates({
          ...request,
          type: 'reverse_auto_invoice',
          invoice_id: reverseAutoinvoice.id,
          invoice_date: new Date(),
          force_operation: true,
          invoice_layout_sectional_id: reverseAutoinvoice.invoice_sectional_id,
          ...partialPayload,
        }),
      )
      .pipe(
        map(({ data }: IResponseSuccess<InvoiceDetails[]>) => {
          this.registerLoading = false;
          return data[0];
        }),
      );
  }

  /**
   * @override
   */
  protected updateInvoice(params: InvoiceRegisterServiceParams) {
    const { invoice, updatePayload } = params;

    return updatePayload
      ? this.temporaryInvoiceService.update(invoice.id, updatePayload)
      : of({});
  }

  private getRegisterReverseAutoinvoicePayload(
    invoice: InvoiceDetails,
    layout: IInvoiceLayout,
  ): Observable<IReverseAutoinvoiceRequestRoot> {
    const payload: Partial<IReverseAutoinvoiceRequestRoot> = {
      total: 1,
      invoice_id: invoice.id,
      invoice_date: invoice.invoice_date,
      property_id: invoice.property_id,
      sdi_send: +(
        !!layout.invoice_module?.status && invoice.type === 'invoice'
      ),
    };

    if (invoice && +invoice.paid === 0) {
      return of({ ...payload, payment_method_id: null, mark_as_paid: 1 });
    }

    const isDepositPayment = invoice.reservation_payments?.some(
      ({ deposit, amount_used_here }) => deposit && amount_used_here > 0,
    );

    const modal = this.modalService.create({
      nzContent: ReverseAutoinvoiceRegisterModalComponent,
      nzData: {
        invoice,
        isDepositPayment,
      },
      nzTitle: null,
      nzFooter: [
        {
          label: upperFirst(this.translate.instant('cancel')),
          type: 'default',
          onClick: () => {
            modal.close();
          },
        },
        {
          label: upperFirst(this.translate.instant('save')),
          type: 'primary',
          onClick: (content) => {
            modal.close({ ...payload, ...content.value });
          },
        },
      ],
    });

    return modal.afterClose.asObservable();
  }
}
