import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class InvoiceNumberService {
  constructor(private http: HttpClient) {}

  check({ invoice_layout_id, ...request }) {
    return this.http.get(
      `invoices/layout/${invoice_layout_id}/check_number?${generateSearchQuery(
        request,
      )}`,
    );
  }
}
