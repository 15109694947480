import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  AttachExternalServiceRequest,
  UpdateExternalServiceRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class PropertyExternalServicesService {
  constructor(private http: HttpClient) {}

  load(propertyId: number, isAdmin: boolean) {
    if (isAdmin) {
      return this.http.get(`admin/external_services/property/${propertyId}`);
    } else {
      return this.http.get(`external_services/property/${propertyId}`);
    }
  }

  loadDetails(propertyId: number, externalServiceId: number, isAdmin: boolean) {
    let prefix = '';

    if (isAdmin) {
      prefix = 'admin/';
    }
    return this.http.get(
      `${prefix}external_services/get/${externalServiceId}/${propertyId}`,
    );
  }

  update(request: UpdateExternalServiceRequest) {
    return this.http.post(
      `admin/property/${request.property_id}/external_service/${request.external_service_id}`,
      request,
    );
  }

  attach({
    external_service_id,
    property_id,
    ...infos
  }: AttachExternalServiceRequest) {
    return this.http.post(
      `admin/property/${property_id}/attach_service/${external_service_id}`,
      infos,
    );
  }

  detach(propertyId: number, externalServiceId: number) {
    return this.http.delete(
      `admin/external_services/${externalServiceId}/${propertyId}/delete?property_id${propertyId}&external_service_id=${externalServiceId}`,
    );
  }
}
