const ERRORS = {
  E01: {
    code: 'E01',
    title: 'VALORE NON VALIDO [CL]',
    description:
      'Indica che l’ultimo tasto premuto non è nella sequenza corretta con la procedura in corso.',
  },
  E02: {
    code: 'E02',
    title: 'FUNZIONE ERRATA Premere [CL]',
    description:
      'Indica un errore nella procedura in corso. Premere [CL] e riverificare l’inserimento dati.',
  },
  E03: {
    code: 'E03',
    title: 'DATA ERRATA Premere [CL]',
    description:
      'Il formato della data inserita non è corretto (vedi cap. 3.11 del manuale UTENTE).',
  },
  E04: {
    code: 'E04',
    title: 'ORA ERRATA Premere [CL]',
    description:
      'Il formato dell’ora inserita non è corretto (vedi cap. 3.11 del manuale UTENTE).',
  },
  E05: {
    code: 'E05',
    title: 'Voce TOTALE non ammessa [CL]',
    description:
      'Non è possibile programmare una parola che contenga la scritta TOTALE.',
  },
  E06: {
    code: 'E06',
    title: 'CODICE ARTICOLO ERRATO [CL]',
    description:
      'Indica che il codice a barre o il codice numerico dell’articolo inserito non è corretto; premere [CL] e riverificare il codice.',
  },
  E07: {
    code: 'E07',
    title: 'COD. FISCALE ERRATO [CL]',
    description:
      'Indica che il codice fiscale inserito non è corretto; premere [CL] e riprovare.',
  },
  E10: {
    code: 'E10',
    title: 'FORMATO MMC NON VALIDO! [CL]',
    description:
      'Indica che la MMC inserita non è stata riconosciuta dal sistema. Verificare la MMC utilizzata, eventualmente pulendola e reinserendola. Se il problema persiste contattare il centro assistenza per la verifica e l’eventuale sostituzione.',
  },
  E11: {
    code: 'E11',
    title: "MMC GIA' UTILIZZATA [CL]",
    description:
      'Indica che la MMC (giornale elettronico) inserita non è vergine. Inserire un’altra MMC vergine.',
  },
  E12: {
    code: 'E12',
    title: 'DGFE ASSENTE O ERRATO [CL]',
    description:
      'Indica che manca la MMC (giornale elettronico), oppure che il numero di progressivo DGFE è errato. Inserire una nuova MMC, oppure inserire il DGFE corretto.',
  },
  E14: {
    code: 'E14',
    title: 'ERR IN SCRITTURA MMC',
    description:
      'Indica che si è verificato un errore durante la scrittura dei dati nel giornale elettronico (DGFE); spegnere e riaccendere la cassa, annullando completamente il documento in corso (Annullo Scontrino). Se il problema si ripresenta più volte, contattare il centro assistenza.',
  },
  E15: {
    code: 'E15',
    title: 'ERR. RTC',
    description:
      'Indica che in apertura di documento o chiusura giornaliera, la data del sistema è differente di oltre un anno, rispetto alla data dell’ultima chiusura giornaliera effettuata. Contattare il centro assistenza per la verifica hardware e il ripristino del sistema.',
  },
  E17: {
    code: 'E17',
    title: 'FORMATO MMC NON CORRETTO! [CL]',
    description:
      'Indica che la MMC inserita non è del tipo fornito dal produttore del registratore. Inserire solo MMC originali.',
  },
  E20: {
    code: 'E20',
    title: 'SEQUENZA ERR Premere [CL]',
    description:
      'indica che la pressione dell’ultimo tasto premuto non è corretta nella sequenza di comandi in corso.',
  },
  E21: {
    code: 'E21',
    title: 'OPERAZIONE NON AMMESSA! [CL]',
    description:
      'Indica che l’ultima operazione eseguita non è permessa nella procedura in corso.',
  },
  E23: {
    code: 'E23',
    title: 'MANCA TASTO DI CONTROLLO[CL]',
    description:
      'Significa che in tastiera mancano uno o più tasti obbligatori. I tasti obbligatori (o di controllo) sono: [CONTANTI], [SUBTOT.], [X/ORA], [REPARTO], [PREZZO REPARTO] e [CL], [STORNO] ,[RESO M.]. Questi tasti devono sempre essere presenti.',
  },
  E24: {
    code: 'E24',
    title: 'IMPORTO OBBLIGATORIO [CL]',
    description:
      'Indica che è necessario inserire l’importo fornito dal cliente, prima di premere il tasto Totale relativo alla forma di pagamento effettuata dal cliente (es. CONTANTI).',
  },
  E25: {
    code: 'E25',
    title: 'SUBTOTALE OBBLIGATORIO ! [CL]',
    description:
      'Prima di chiudere il documento è necessario premere il tasto SUBTOTALE per visualizzare l’importo raggiunto dal documento.',
  },
  E26: {
    code: 'E26',
    title: 'CLIENTE NON TROVATO [CL]',
    description:
      'Indica che non è stato correttamente inserito il codice numerico riferito al cliente. Il codice non è presente in memoria.',
  },
  E27: {
    code: 'E27',
    title: 'CLIENTE MOVIMENTATO [CL]',
    description:
      'Indica che in memoria del sistema sono presenti dei documenti con corrispettivo non riscosso, relativi al cliente che si sta cercando di cancellare. Prima di procedere alla eliminazione dalla anagrafica, è necessario emettere la fattura riepilogativa.',
  },
  E30: {
    code: 'E30',
    title: 'DGFE ESAURITO Premere [CL]',
    description:
      'Indica che il giornale elettronico (MMC) è esaurito. Sostituire la MMC (vedi cap. 2.2 del manuale UTENTE).',
  },
  E31: {
    code: 'E31',
    title: 'DGFE IN ESAURIMENTO![CL]',
    description:
      'Indica che il giornale elettronico (MMC) è quasi saturo e che bisogna cambiarlo al più presto.(vedi cap. 2.2 del manuale UTENTE).',
  },
  E40: {
    code: 'E40',
    title: 'SCONTRINO NEGATIVO [CL]',
    description:
      'La cassa accetta che il subtotale possa essere negativo, ma impedisce la chiusura del documento; vendere altri articoli per chiudere il documento a zero o in positivo.',
  },
  E41: {
    code: 'E41',
    title: 'SUPER.LIMITE SCONTRINO [CL]',
    description:
      'L’importo del documento in corso ha superato il limite consentito; annullare il documento o chiuderlo e aprirne uno nuovo per gli articoli non registrati.',
  },
  E42: {
    code: 'E42',
    title: 'SUPER. LIMITE NETTO GIORNO[CL]',
    description:
      'Il totalizzatore giornaliero VENDITE ha raggiunto il limite; chiudere o annullare il documento in corso ed operare una chiusura.',
  },
  E43: {
    code: 'E43',
    title: 'SUPER. LIMITE NETTO PROGR.[CL]',
    description:
      'La cassa ha raggiunto il massimo valore totalizzabile per il Gran Totale; chiudere o annullare il documento in corso e chiamare l’assistenza.',
  },
  E44: {
    code: 'E44',
    title: 'RAGG. LIMITE VOCI SCONTR.[CL]',
    description:
      'Indica il raggiungimento massimo degli articoli registrabili per il documento in corso. Chiudere il documento e aprirne uno nuovo per registrare gli articoli mancanti.',
  },
  E45: {
    code: 'E45',
    title: 'RESTO NON AMMESSO [CL]',
    description:
      'Il totale di chiusura utilizzato è programmato per non accettare il resto. Utilizzare un altro Totale o riprogrammare quello utilizzato.',
  },
  E46: {
    code: 'E46',
    title: 'RAGGIUNTO LIMITE NUMERO ARTICOLI',
    description:
      'Non è possibile registrare ulteriori articoli, perché è stato raggiunto il numero massimo di articoli per documento commerciale.',
  },
  E47: {
    code: 'E47',
    title: 'LIMITE NUM. CLIENTI [CL]',
    description:
      'Indica che la memoria dedicata ai clienti è satura. Cancellare se possibile alcuni clienti non più utilizzati, prima di programmarne altri.',
  },
  E48: {
    code: 'E48',
    title: 'MASSIMO NUMERO RIGHE IN TESTA [CL]',
    description:
      'Indica che è stato superato il numero massimo di righe alfanumeriche che possono essere stampate sotto l’intestazione del documento commerciale.',
  },
  E51: {
    code: 'E51',
    title: "MEM. FISCALE GIA' SERIAL. [CL]",
    description:
      'Non è possibile eseguire la procedura di serializzazione (già operata dal costruttore).',
  },
  E52: {
    code: 'E52',
    title: 'DATI NON AZZERATI [CL]',
    description:
      'Indica che prima di procedere, è obbligatorio eseguire una chiusura.',
  },
  E53: {
    code: 'E53',
    title: 'DATA PRECED. ULTIMA CHIUS[CL]',
    description:
      'Indica che durante il ripristino della cassa, è stata inserita una data antecedente l’ultima chiusura.',
  },
  E55: {
    code: 'E55',
    title: 'ERRORE DATA! Chiama assistenza.',
    description:
      'Questo controllo impedisce l’esecuzione della chiusura giornaliera. Verificare la data presente nella cassa e chiamare il centro assistenza.',
  },
  E56: {
    code: 'E56',
    title: 'DICHIARAZ. DA ESEGUIRE [CL]',
    description:
      'Indica che è necessario eseguire la dichiarazione di cassa prima di procedere con la chiusura (controllo abilitato).',
  },
  E57: {
    code: 'E57',
    title: 'CERTIFICATO RT IN SCADENZA TRA n GG',
    description:
      'Il certificato del registratore telematico scadrà tra (n=numero) giorni.',
  },
  E58: {
    code: 'E58',
    title: 'CERTIFICATO SCADUTO',
    description: 'Il certificato del registratore telematico è scaduto.',
  },
  E60: {
    code: 'E60',
    title: 'FINE CARTA Premere [CL]',
    description:
      'Indica che il rotolo di carta è terminato, sostituire il rotolo di carta come spiegato nel cap. 2.1 del manuale UTENTE.',
  },
  E61: {
    code: 'E61',
    title: 'ERR. TESTINA Premere [CL]',
    description:
      'Indica un surriscaldamento della testina di stampa, oppure che il connettore della testina termica (stampante) non è collegato alla scheda logica della stampante. Spegnere il sistema per alcuni minuti e riavviare; se il problema persiste, contattare il centro assistenza per la verifica del sistema.',
  },
  E62: {
    code: 'E62',
    title: 'ERR. ALIMENT. Premere [CL]',
    description:
      'Indica che la cassa non è correttamente alimentata; contattare il centro assistenza per la verifica della stampante.',
  },
  E63: {
    code: 'E63',
    title: 'ERR. STAMPANTE Premere [CL]',
    description:
      'Indica una anomalia sul funzionamento della stampante; può indicare la non corretta chiusura della parte superiore del coperchio carta. Se il problema persiste, contattare il centro assistenza per la verifica; può indicare anche la mancata connessione del connettore sulla scheda logica.',
  },
  E64: {
    code: 'E64',
    title: 'TESTINA SOLLEVATA [CL]',
    description:
      'Indica che la sostituzione del rotolo carta non è avvenuta correttamente; rileggere il cap. 2.1 del manuale UTENTE. Se il problema persiste contattare il centro assistenza.',
  },
  E65: {
    code: 'E65',
    title: 'TAGLIERINA INCEPPATA [CL]',
    description:
      'Indica la presenza di carta incastrata nella taglierina; rimuovere la carta e premere [CL]. Se il coperchio non si apre, spegnere e riavviare il sistema. Se al riavvio la taglierina non torna nella posizione iniziale, contattare il centro assistenza per il riallineamento.',
  },
  E71: {
    code: 'E71',
    title: 'RT NON REGISTRATO [CL]',
    description:
      'Il registratore non è stato ancora censito. Contattare il centro assistenza.',
  },
  E72: {
    code: 'E72',
    title: 'RT GIA’ REGISTRATO [CL]',
    description:
      'Il registratore è già stato censito. Non è necessario ripetere la procedura.',
  },
  E73: {
    code: 'E73',
    title: 'RT NON ATTIVATO [CL]',
    description:
      'Il registratore non è ancora stato attivato. Contattare il centro assistenza per l’attivazione.',
  },
  E74: {
    code: 'E74',
    title: 'RT GIA’ ATTIVATO [CL]',
    description:
      'Il registratore è già stato attivato. Non è necessario ripetere la procedura.',
  },
  E75: {
    code: 'E75',
    title: 'NO DATA MESSA IN SERVIZIO [CL]',
    description:
      'Per attivare il registratore è obbligatorio inserire la data di messa in servizio.',
  },
  E76: {
    code: 'E76',
    title: 'ERRORE DURANTE SCARICO FILES',
    description:
      'Si è verificato un problema durante la procedura di scarico dei file pendenti. Se il problema persiste, contattare il centro assistenza.',
  },
  E77: {
    code: 'E77',
    title: 'ERRORE DI FIRMA IN UPDATE',
    description:
      'Si è verificato un problema durante l’aggiornamento del registratore. Verificare il file utilizzato. Nel caso il problema persistesse, contattare il centro assistenza.',
  },
  E78: {
    code: 'E78',
    title: 'DATI RT PENDENTI [CL]',
    description:
      'Non c’è più spazio nella memoria dedicata ai file in attesa di invio. E’ necessario ripristinare l’invio telematico dei dati e forzarne l’invio con la funzione INVIA FILE PENDENTI. Se permane la condizione di impossibilità nell’invio dei file, è necessario scaricarli con la procedura SCARICA PENDENZE (vedi manuale UTENTE).',
  },
  E79: {
    code: 'E79',
    title: 'MASSIMO NUMERO EVENTI PENDENTI [CL]',
    description:
      'Non c’è più spazio nella memoria dedicata agli eventi in attesa di invio. E’ necessario ripristinare l’invio telematico dei dati e forzarne l’invio con la funzione INVIA FILE PENDENTI. Se permane la condizione di impossibilità nell’invio dei file, è necessario scaricarli con la procedura SCARICA PENDENZE (vedi manuale UTENTE).',
  },
  E80: {
    code: 'E80',
    title: 'MEM. FISCALE NON CONNESSA',
    description:
      'Indica che la memoria di riepilogo non è collegata; contattare il centro assistenza per il ripristino del funzionamento.',
  },
  E81: {
    code: 'E81',
    title: 'ERR. LETTURA MEMORIA FISCALE',
    description:
      'Indica un problema di accesso alla memoria di riepilogo; spegnere e riaccendere la cassa. Se il problema persiste, contattare il centro di assistenza.',
  },
  E82: {
    code: 'E82',
    title: 'ERR. SCRITT. MEMORIA FISCALE',
    description:
      'Indica un problema di scrittura nella memoria di riepilogo; contattare il centro di assistenza.',
  },
  E83: {
    code: 'E83',
    title: 'MEM. FISCALE ESAURITA',
    description:
      'Indica che sono stati eseguiti tutti gli azzeramenti disponibili. Chiamare il centro di assistenza.',
  },
  E84: {
    code: 'E84',
    title: 'MEM. FISCALE NON SERIALIZZATA',
    description:
      'Indica che non è possibile fiscalizzare la cassa, poiché la memoria di riepilogo non ha il numero di serie.',
  },
  E85: {
    code: 'E85',
    title: 'DISPLAY LCD NON CONNESSO',
    description:
      'Indica che il display non è collegato; contattare il centro assistenza per verificare il display.',
  },
  E86: {
    code: 'E86',
    title: 'ERRORE MEMORIA RAM (- RAM2 e - RAM3)',
    description:
      'Si è verificato un malfunzionamento nella memoria RAM della cassa. Contattare il centro assistenza per il ripristino del sistema. MEMORIA VERSIONE; dopo un aggiornamento del sistema, non è stato eseguito correttamente il ripristino. Procedere al ripristino verificando la corretta procedura nel manuale tecnico.',
  },
  E87: {
    code: 'E87',
    title: 'ERRORE RAM / MEM. FISC',
    description:
      'Si è verificato un malfunzionamento tra la memoria RAM e la memoria di riepilogo della cassa. Contattare il centro assistenza per il ripristino del sistema.',
  },
  E88: {
    code: 'E88',
    title: 'ERR. DATA/ORA',
    description:
      'Indica che durante l’avvio del sistema, si è verificata un’anomalia su formato data/ora (circuito RTC). Contattare l’assistenza tecnica per il controllo dell’hardware e il ripristino del sistema.',
  },
  E89: {
    code: 'E89',
    title: 'DISPLAY CLIENTE NON CONNESSO!',
    description:
      'Indica il mancato funzionamento del display. Contattare il centro assistenza.',
  },
  E90: {
    code: 'E90',
    title: 'ERRORE COMUNICAZIONE [CL]',
    description:
      'Indica la mancata comunicazione tra la stampante fiscale e il PC. Controllare i cavi di collegamento.',
  },
  E91: {
    code: 'E91',
    title: 'ERRORE ETHERNET [CL]',
    description:
      'Indica la mancata comunicazione LAN tra la stampante fiscale e il PC. Controllare il collegamento e la rete (router, ecc.).',
  },
  E92: {
    code: 'E92',
    title: 'DATI DA +4GG SCARICARE O INVIARE [CL]',
    description:
      'Il registratore non invia i corrispettivi da quattro giorni. Ripristinare l’invio telematico o scaricare i file pendenti dalla memoria del registratore (vedi cap.9.4.2 del manuale UTENTE), per il successivo invio con dispositivo esterno. Contattare il centro assistenza per la verifica del registratore.',
  },
  E93: {
    code: 'E93',
    title: 'RT INATTIVO. ESEGUIRE CHIUSURA [CL]',
    description:
      'Il registratore è rimasto inutilizzato per uno o più giorni (periodo di inattività da comunicare). Per effettuare le registrazioni, è obbligatorio effettuare una chiusura giornaliera.',
  },
  E94: {
    code: 'E94',
    title: 'MANCATA CONNESSIONE CON IL SERVER MANCATA RISPOSTA [CL]',
    description:
      'Durante la procedura di invio dei corrispettivi, si è verificato un errore. Riprovare ad inviarli in un altro momento. Se il problema persiste per più giorni, contattare il centro assistenza.',
  },
  E95: {
    code: 'E95',
    title: 'RISPOSTA SERVER: YYY CODICE : ZZZZZ',
    description:
      'yyy è il codice HTTP della risposta del server AdE zzzzz è il codice di errore previsto dalla normativa.',
  },
  E96: {
    code: 'E96',
    title: 'REG. TELEMATICO DISMESSO [CL]',
    description:
      'Il registratore telematico è stato dismesso. La procedura è irreversibile.',
  },
  E97: {
    code: 'E97',
    title: 'M.F. ASSENTE – Chiamare assistenza',
    description:
      'Indica che la memoria di riepilogo non è collegata; verificare il collegamento dei connettori e tentare il ripristino del sistema.',
  },
  E98: {
    code: 'E98',
    title: 'RT DISATTIVATO. RIAVVIARE RT',
    description:
      'Il registratore telematico è stato disattivato. E’ necessario riavviarlo per operare nuovamente.',
  },
  E99: {
    code: 'E99',
    title: 'MEMORIA PERMANENTE DI RIEPILOGO OLTRE 90% [CL]',
    description:
      'Indica il raggiungimento e superamento della soglia 90% di riempimento della memoria permanente di riepilogo. Informare il centro assistenza.',
  },
  E100: {
    code: 'E100',
    title: 'ESEGUIRE CHIUSURA GIORNALIERA',
    description:
      'Questo messaggio avvisa che è necessario eseguire la chiusura giornaliera prima della mezzanotte.',
  },
  E101: {
    code: 'E101',
    title: 'NESSUNA RISPOSTA – Operazione annullata',
    description: `L'operazione in corso è stata annullata per timeout.`,
  },
  E102: {
    code: 'E102',
    title: 'RISPOSTA NON ATTESA: ERRORE HTTP XXX',
    description: `xxx codice HTTP, diverso da 200, ricevuto dal Server.Errore Non GESTIBILE. Se l’errore ricevuto dal server è maggiore di 255`,
  },
  E103: {
    code: 'E103',
    title: 'RISPOSTA SERVER NON INTERPRETABILE',
    description:
      'Contattare il centro assistenza per verificare lo stato del servizio.',
  },
  E104: {
    code: 'E104',
    title: 'DATI DEL SERVER NON VALIDI',
    description:
      '(durante la procedura di inizializzazione del DGFE). Contattare il centro assistenza per verificare lo stato del servizio.',
  },
  E105: {
    code: 'E105',
    title: 'ERRORE NEL FILE DI ABILITAZIONE',
    description:
      'Verificare che il file utilizzato sia allineato con la versione caricata.',
  },
  E110: {
    code: 'E110',
    title: 'ORARIO INTERDETTO ALLA TRASMISSIONE',
    description:
      'Da normativa, non è possibile inviare i corrispettivi nella fascia oraria interdetta 03:00-05:00.',
  },

  E111: {
    code: 'E111',
    title: 'IMPORTO MINIMO NON RAGGIUNTO',
    description: '',
  },
  E112: {
    code: 'E112',
    title: 'SUPERATO IMPOR. MAX CONSENTITO',
    description: '',
  },
  E113: {
    code: 'E113',
    title: 'NON COMPATIBILE CON PAGAMENTO POS',
    description: '',
  },
  E114: {
    code: 'E114',
    title: 'IMPORTO PARZIALE NON AMMESSO',
    description: '',
  },
  E115: {
    code: 'E115',
    title: 'TRANS. FALLITA',
    description: `x (x mostra dettagli errore: “POS irraggiungibile” o codice o desc. errore)`,
  },
  E116: {
    code: 'E116',
    title: 'DISPOSITIVO EFT NON TROVATO',
    description: '',
  },
  E117: {
    code: 'E117',
    title: 'OPERAZIONE POS FALLITA',
    description: '',
  },
  E120: {
    code: 'E120',
    title: 'PIN LETTURE NON IMPOSTATO [CL]',

    description: `Per bloccare la lettura delle memorie di riepilogo e di dettaglio è necessario impostare un PIN (vedi cap. PIN PER LA
        LETTURA DELLE MEMORIE)`,
  },
  E121: {
    code: 'E121',
    title: 'LETTURA NON AUTORIZZATA [CL]',

    description: `Se è stato inibito l’accesso alla lettura delle memorie (MR e DGFE). Vedi cap. PROTEZIONE LETTURE MEMORIE PERMANENTI.`,
  },
  E122: {
    code: 'E122',
    title: 'PIN DIVERSI [CL]',
    description: `Il RT segnala errore se durante l’impostazione del PIN (lettura delle memorie) viene digitato un PIN diverso a conferma
    del nuovo PIN precedentemente inserito.`,
  },
  E124: {
    code: 'E124',
    title: 'ERRORE SPI FLASH [CL]',
    description: `Errore di lettura/scrittura nella memoria interna del RT. Contattare il centro assistenza per la verifica del RT.`,
  },
  E125: {
    code: 'E125',
    title: 'ERRORE UPLOAD [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML si è verificato un errore durante l’archiviazione XML dei file.
    `,
  },
  E126: {
    code: 'E126',
    title: 'COLLEGAMENTO ASSENTE [CL]',
    description: `Connessione ethernet assente; verificare le programmazioni e il collegamento con le periferiche collegate`,
  },
  E127: {
    code: 'E127',
    title: 'ERR.CONNESSIONE A RBS [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML il RT non è riuscito a stabilire una connessione al server dei
    servizi.`,
  },
  E128: {
    code: 'E128',
    title: 'ERRORE TOKEN [CL]',
    description: `Durante la richiesta di autenticazione al server dei servizi, per il servizio di archiviazione XML, il token inviato non è
    compatibile.`,
  },
  E129: {
    code: 'E129',
    title: 'ERRORE REGISTRAZIONE [CL]',
    description: `Non è stata validata l’autenticazione su server dei servizi per l’archiviazione XML.`,
  },
  E130: {
    code: 'E130',
    title: 'CREDENZIALI NON VALIDE [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, l’errore segnala che le credenziali fornite per l’autenticazione al
    server dei servizi non sono valide.`,
  },
  E131: {
    code: 'E131',
    title: 'ERR. SCRITTURA FLASH [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML si è verificata una anomalia durante la scrittura nella memoria
    interna del RT. Contattare il centro assistenza per l’analisi della segnalazione.`,
  },
  E132: {
    code: 'E132',
    title: 'ERR. LETTURA DA FLASH [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML si è verificata una anomalia durante la lettura della memoria
    interna del RT. Contattare il centro assistenza per l’analisi della segnalazione.`,
  },

  E133: {
    code: 'E133',
    title: 'ERR.IMP.INDICI XML FLASH [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML l’impostazione degli indici indicativi del primo e ultimo XML
    da archiviare su server dei servizi non sono correttamente impostati. Contattare il centro assistenza per l’analisi della
    segnalazione.`,
  },
  E134: {
    code: 'E134',
    title: 'ERR. XML INFO DA FLASH [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, il recupero del progressivo e degli indirizzi del DGFE (relativi
        agli XML da archiviare sul server dei servizi) non è andato a buon fine. Contattare il centro assistenza per l’analisi della
        segnalazione.`,
  },
  E135: {
    code: 'E135',
    title: 'ERR.DGFE N. DA FLASH [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, il progressivo del DGFE relativo all’XML da archiviare sul server dei servizi non ha un valore valido.
    Contattare il centro assistenza per l’analisi della segnalazione.`,
  },
  E136: {
    code: 'E136',
    title: 'ERR. RECUPERO XML [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML la ricostruzione dell’XML da archiviare sul server dei servizi non
    è andata a buon fine. La causa può essere che non vengono trovati i puntatori nel DGFE per il recupero dei dati. Verificare se il DGFE è stato sostituito recentemente o presenta problemi di lettura/scrittura.`,
  },
  E137: {
    code: 'E137',
    title: 'ERRORE TRASMISSIONE [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML si è verificato un errore durante la trasmissione al server dei
    servizi degli XML da archiviare. Tentare successivamente una nuova trasmissione oppure attendere la schedulazione
    automatica degli invii o la prossima chiusura. Se il problema persiste, contattare il centro assistenza per l’analisi della
    segnalazione.`,
  },
  E139: {
    code: 'E139',
    title: 'INSERIRE DGFE NUMERO XX [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML il progressivo del DGFE inserito nel RT non corrisponde al progressivo di DGFE da cui è possibile ricostruire l’XML da archiviare. Inserire il DGFE richiesto per procedere con l’archiviazione dei file; al termine della procedura, inserire nuovamente il DGFE corrente.`,
  },
  E140: {
    code: 'E140',
    title: 'INESSUNA RISP. DAL SERVER RBS [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, il server dei servizi non risponde alle richieste del RT. Contattare il centro assistenza per l’analisi della segnalazione.`,
  },
  E141: {
    code: 'E141',
    title: 'ERRORE ALLOC. BUFFER [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, il server dei servizi non risponde alle richieste del RT. Contattare il centro assistenza per l’analisi della segnalazione.`,
  },
  E142: {
    code: 'E142',
    title: 'DISPOSITIVO NON REGISTRATO [CL]',
    description: `Il RT non è autenticato su server dei servizi. Per accedere al servizio di archiviazione XML è necessario autenticarsi.
    Contattare il centro assistenza per la corretta registrazione al servizio.`,
  },
  E144: {
    code: 'E144',
    title: 'ERRORE REFRESH TOKEN [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, la procedura di recupero di un nuovo Token, in seguito all’invio
    del Token scaduto, non è andata a buon fine. Si consiglia di riprovare a forzare nuovamente l’invio dei file dalla funzione
    ARCHIVIAZIONE DEI CORRISPETTIVI.
    `,
  },
  E145: {
    code: 'E145',
    title: 'ERR.SALVATAGGIO CREDENZIALI [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, le credenziali inserite nella procedura di REGISTRAZIONE per
    l’autenticazione su server dei servizi non sono state correttamente salvate. Verificare i dati forniti e provare nuovamente
    la funzione di REGISTRAZIONE.
    `,
  },
  E146: {
    code: 'E146',
    title: 'ERR.CANCELLAZ. ARCHIVIO [CL]',
    description: `Funzione ARCHIVIAZIONE XML. La richiesta di cancellazione dell’archivio XML da inviare al server dei servizi non è
    stata completata. Riprovare in un secondo momento; se l’anomalia persiste contattare il centro assistenza per l’analisi
    della segnalazione`,
  },
  E147: {
    code: 'E147',
    title: 'ERR.SALVATAGGIO TOKEN [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, il Token ricevuto dal server dei servizi per il servizio di REGISTRAZIONE non è stato correttamente salvato. Verificare i dati forniti e provare nuovamente la funzione di REGISTRAZIONE.`,
  },
  E148: {
    code: 'E148',
    title: 'ERRORE CAMBIO MATRICOLA [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, il RT ha rilevato che la scheda logica è stata sostituita e non è
    possibile avviare il servizio di archiviazione XML. Deve essere eseguita una REGISTRAZIONE.`,
  },
  E149: {
    code: 'E149',
    title: 'ERRORE DGFE ASSENTE [CL]',
    description: `Durante la gestione dell’ARCHIVIAZIONE DEI FILE XML, il DGFE non risulta essere inserito correttamente.`,
  },

  // Custom Errors
  EC01: {
    code: 'EC01',
    title: 'RISPOSTA ESTESA NON ATTIVA',
    description: `La risposta esteva non risulta attiva nella configurazione della stampante.`,
  },
};

export function getError(code: string) {
  return (
    ERRORS[code] || {
      code,
      title: 'ERRORE SCONOSCIUTO',
      description:
        'È stato generato un errore sconosciuto. Comunica il codice errore al tecnico della stampante',
    }
  );
}
