import { Injectable } from '@angular/core';
import { first, last } from 'lodash';
import moment from 'moment';

import { getDatesFromInterval } from '../helpers';
import { TableauReservation } from '../models';

@Injectable({ providedIn: 'root' })
export class TableauSumParentAccommodationReservationsService {
  get(reservations: TableauReservation[]): TableauReservation[] {
    const childReservations: TableauReservation[] = [];
    const notChildReservations: TableauReservation[] = [];

    reservations.forEach((reservation) =>
      reservation.child_reservation
        ? childReservations.push(reservation)
        : notChildReservations.push(reservation),
    );

    const blockedDays = childReservations
      .map(({ date_from, date_to }) =>
        getDatesFromInterval(
          date_from,
          moment(date_to).subtract(1, 'days').format('YYYY-MM-DD'),
        ),
      )
      .reduce((acc, dates) => {
        dates.forEach((date) => {
          acc = { ...acc, [date]: true };
        });
        return acc;
      }, {});

    const blockedPeriods = [];

    let currentPeriod: string[] = [];
    Object.keys(blockedDays)
      .sort()
      .forEach((day, index, days) => {
        currentPeriod.push(day);

        const nextDay = days[index + 1];

        if (!nextDay || moment(nextDay).diff(moment(day), 'days') > 1) {
          currentPeriod.push(moment(day).add(1, 'day').format('YYYY-MM-DD'));

          blockedPeriods.push(currentPeriod);

          currentPeriod = [];
        }
      });

    return [
      ...notChildReservations,
      ...blockedPeriods.map((period: string[]) => {
        const date_from = first(period);
        const date_to = last(period);
        return {
          ...childReservations[0],
          date_from,
          date_to,
          arrival_date: date_from,
          departure_date: date_to,
        };
      }),
    ];
  }
}
