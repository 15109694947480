<by-spin [nzSpinning]="showLoading && checkResultsLoading">
  <nz-alert
    *ngFor="let warning of warnings"
    class="by-mb-2"
    nzType="warning"
    [nzMessage]="'attenction' | translate | upperfirst"
    [nzDescription]="warning"
    nzShowIcon
  ></nz-alert>

  <div *ngIf="checkResults" class="fiscal-printer-checks">
    <div
      *ngFor="let result of checkResults?.results"
      nz-row
      class="fiscal-printer-checks__item"
    >
      <div nz-col>{{ result?.label | translate | upperfirst }}</div>
      <div nz-col nzFlex="1" class="fiscal-printer-checks__dashes"></div>
      <div nz-col>
        <i
          [class]="result?.iconClass"
          nz-tooltip
          [nzTooltipTitle]="result?.iconTooltip ? iconTooltip : null"
        ></i>
        <ng-template #iconTooltip>
          <span
            [innerHTML]="
              result?.iconTooltip
                | translate: { ipAddress: this.selectedPrinter?.ip_address }
                | upperfirst
            "
          ></span>
        </ng-template>
      </div>
    </div>
    <div
      *ngIf="
        checkResults?.results | where: ['error', true] | head as errorResult
      "
      class="fiscal-printer-error-description"
    >
      <span
        [innerHTML]="
          errorResult?.iconTooltip
            | translate: { ipAddress: this.selectedPrinter?.ip_address }
            | upperfirst
        "
      ></span>

      <p
        class="by-mt-5"
        [innerHTML]="
          'question_go_to_guide_url'
            | translate
              : {
                  url: 'configurazione/fatturazione/#errore-comunicazione-stampante'
                }
            | upperfirst
        "
      ></p>
    </div>

    <div *ngIf="!checkResults?.checkPassed" class="text-align--end">
      <a (click)="onCheckStatus()"
        ><i class="far fa-sync-alt"></i>
        {{ 'refresh' | translate | upperfirst }}</a
      >
    </div>
  </div>
</by-spin>
