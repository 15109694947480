import { set } from 'date-fns';

export const formatTimeFromApi = (time: string): Date => {
  const timeSplited = time?.split(':');

  return timeSplited
    ? set(new Date(), {
        hours: +timeSplited?.[0],
        minutes: +timeSplited?.[1],
      })
    : null;
};
