import moment from 'moment';

export const RANGE_PICKER_PRESET = {
  thirty_day_zero_day: [
    moment().subtract(30, 'days').toDate(),
    moment().toDate(),
  ],
  thirty_day_sixty_day: [
    moment().subtract(60, 'days').toDate(),
    moment().subtract(30, 'days').toDate(),
  ],
  sixty_day_ninety_day: [
    moment().subtract(90, 'days').toDate(),
    moment().subtract(60, 'days').toDate(),
  ],
  six_month_ninety_day: [
    moment().subtract(6, 'months').toDate(),
    moment().subtract(90, 'days').toDate(),
  ],
  one_year_six_month: [
    moment().subtract(1, 'years').toDate(),
    moment().subtract(6, 'months').toDate(),
  ],
  one_year: [moment().subtract(10, 'years').toDate(), new Date()],
};
