import { ActiveModule, Module } from '../../models';

export type Data = Record<Module, ActiveModule>;

export interface State {
  data: Data;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  data: null,
  isLoading: false,
  error: null,
};
