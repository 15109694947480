import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SaveNewsletterConditionRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NewsletterConditionsService {
  constructor(private http: HttpClient) {}

  load(mailingListId: number) {
    return this.http.get(
      `newsletters/sending_lists/${mailingListId}/conditions`,
    );
  }

  create(mailingListId: number, request: SaveNewsletterConditionRequest) {
    return this.http.post(
      `newsletters/sending_lists/${mailingListId}/conditions`,
      request,
    );
  }

  update(
    mailingListId: number,
    newsletterConditionId: number,
    request: SaveNewsletterConditionRequest,
  ) {
    return this.http.put(
      `newsletters/sending_lists/${mailingListId}/conditions/${newsletterConditionId}`,
      request,
    );
  }

  delete(mailingListId: number, conditionId: number) {
    return this.http.delete(
      `newsletters/sending_lists/${mailingListId}/conditions/${conditionId}`,
    );
  }
}
