import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FiscalPrinterModelsService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('general/printer_models');
  }

  loadFeatures(fiscalPrinterModelId: number) {
    return this.http.get(
      `general/printer_models/${fiscalPrinterModelId}/features`,
    );
  }

  loadFields(fiscalPrinterModelId: number) {
    return this.http.get(
      `general/printer_models/${fiscalPrinterModelId}/fields`,
    );
  }
}
