import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiPartnerManagerService {
  constructor(private http: HttpClient) {}

  load(apiPartnerId: number) {
    return this.http.get(`api_partner/${apiPartnerId}/manager`);
  }

  attach(apiPartnerId: number, userId?: number) {
    return this.http.post(`api_partner/${apiPartnerId}/manager/set`, {
      user_id: userId,
    });
  }
}
