import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoleUserDetailsService {
  constructor(private http: HttpClient) {}

  load(propertyId: number, userId: number) {
    return this.http.get(`property/${propertyId}/acl/user/${userId}`);
  }

  setRole(property_id: number, property_role_id: number, userId: number) {
    return this.http.post(
      `property/${property_id}/acl/user/${userId}/set_role`,
      { property_role_id },
    );
  }
}
