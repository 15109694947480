import { createAction, props } from '@ngrx/store';

import { InvoiceTypes } from '../../models';

export const checkRequest = createAction(
  '[Invoice Number] Check Request',
  props<{
    request: {
      invoice_layout_id: number;
      number: number;
      type: InvoiceTypes;
      invoice_layout_sectional_id?: number;
      year?: number;
    };
  }>(),
);
export const checkSuccess = createAction(
  '[Invoice Number] Check Success',
  props<{ alredy_used: boolean; date_number_no_sequential: boolean }>(),
);
export const checkFailure = createAction(
  '[Invoice Number] Move Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Invoice Number] Reset State');
