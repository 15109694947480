import { Media } from '@app/models';
import { GallerySliderImage } from '../../models/types/gallery-slider';
import { BookingComPhoto } from '../../models/objects/booking-com-photos';

export function mapMediasToGallery(medias: Media[]): GallerySliderImage[] {
  return (medias || []).map(({ id, media, formats_size, label }) => ({
    data: {
      uid: id,
      src: media,
      thumb: formats_size.small,
      formats_size,
      label,
    },
  }));
}

export function mapBookingComPhotosToGallery(
  medias: Array<
    Pick<BookingComPhoto, 'photo_id' | 'sample' | 'name'> &
      Partial<BookingComPhoto>
  >,
): GallerySliderImage[] {
  return (medias || []).map(({ photo_id, sample, name }) => ({
    data: {
      uid: photo_id,
      src: sample,
      thumb: sample,
      formats_size: { large: sample, medium: sample, small: sample },
      label: name,
    },
  }));
}
