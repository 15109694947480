import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailChannelsService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('mail_channels');
  }

  loadFields(emailChannelID: number) {
    return this.http.get(`mail_channels/${emailChannelID}/fields`);
  }
}
