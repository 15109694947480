import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PropertyApiPartnerUsersService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`property/${propertyId}/api_users`);
  }

  detach(propertyId: number, userId: number) {
    return this.http.post(`property/${propertyId}/detach_api_partner_user`, {
      user_id: userId,
    });
  }

  attach(propertyId: number, userId: number) {
    return this.http.post(`property/${propertyId}/attach_api_partner_user`, {
      user_id: userId,
    });
  }
}
