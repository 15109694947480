import { createAction, props } from '@ngrx/store';

import { LoginRequest } from '../../models';

export const loginRequest = createAction(
  '[Auth] Login Request',
  props<{ loginRequest: LoginRequest }>(),
);
export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ token: string }>(),
);
export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>(),
);

export const logoutRequest = createAction(
  '[Auth] Logout Request',
  props<{ loginRedirect?: string }>(),
);
export const logoutSuccess = createAction(
  '[Auth] Logout Success',
  props<{ loginRedirect?: string }>(),
);
export const logoutFailure = createAction('[Auth] Logout Failure');

export const resetState = createAction('[Auth] Reset State');
