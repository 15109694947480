import { UserPreferences } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { get } from 'lodash';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getData = (state: State): UserPreferences => state.data;

export const getDataByCategory = (category: string) => (state: State) =>
  get(state.data, category, {});

export const getColumnsByTable =
  (table: string) =>
  (state: State): string[] => {
    return get(state.data, ['tablecolumnView', table], []);
  };

export const selectUserPreferenceState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('userPreferences');

export const selectUserPreferencesError: MemoizedSelector<object, any> =
  createSelector(selectUserPreferenceState, getError);

export const selectUserPreferencesIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectUserPreferenceState, getIsLoading);

export const selectUserPreferencesData: MemoizedSelector<
  object,
  UserPreferences
> = createSelector(selectUserPreferenceState, getData);

export const selectUserPreferencesDataByCategory = (
  category: string,
): MemoizedSelector<any, any> =>
  createSelector(selectUserPreferenceState, getDataByCategory(category));

export const selectColumnsByTable = (
  table: string,
): MemoizedSelector<object, string[]> =>
  createSelector(selectUserPreferenceState, getColumnsByTable(table));
