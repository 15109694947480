import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'by-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LookupComponent,
      multi: true,
    },
  ],
})
export class LookupComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() options: Array<any> = [];
  @Input() dropdownMatchWidth = true;
  @Input() nzAllowClear = true;
  @Input() nzMaxMultipleCount = 100;
  @Input() placeholder = 'search_something';
  @Input() showArrow = true;
  @Input() groupedOptions = false;
  @Input() size: 'small' | 'default' | 'large' = 'default';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() serverSearch = false;
  @Input() filterEmptySearch = true;
  @Input() byClass = 'by-w-100';
  @Input() byStyle = {};

  @Input() valuePath = 'id';
  @Input() labelPath = 'name';
  @Input() disabledPath = 'disabled';

  @Input() nzModeSelect: 'multiple' | 'default' = 'default';

  @Input() optionTemplate: TemplateRef<void>;

  @Output() search = new EventEmitter<string>();

  @Output() valueChange = new EventEmitter<any>();

  @Output() selectBlur = new EventEmitter<void>();

  selectedValue = null;

  private search$ = new Subject<string>();

  private subs = new SubSink();

  constructor() {}

  onTouched: any = () => {};
  onChange: any = () => {};

  /**
   * @description Sottoscrizione al subject search per filtrare alcune ricerche e emettere il valore.
   */
  ngOnInit() {
    this.subs.add(
      this.search$
        .pipe(
          filter((value) => value !== '' || !this.filterEmptySearch),
          debounceTime(150),
        )
        .subscribe((value) => {
          this.search.emit(value);
        }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * @description Passa il valore della ricerca al subject search.
   */
  onSearch(value: string) {
    this.search$.next(value);
  }

  writeValue(value: any): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
