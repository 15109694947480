import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, debounceTime, filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { DefaultInvoicingReceiverCodes } from '../../../config';
import { SDI_IBAN_REGEX, SDI_REGEX } from '../../../core/helpers/sdi-regex';
import { IResponseSuccess } from '../../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../../core/services/error-handler.service';
import { EmailValidator } from '../../../core/validators/email.validators';
import { requiredIfValidator } from '../../../core/validators/required-if.validators';
import { autoHideBillingPlacesFn, removeNullishValues } from '../../../helpers';
import {
  Company,
  CompanyLookup,
  CustomerDetail,
  ITaxSystem,
  Places,
  PlacesInputIds,
  SearchCompanyRequest,
} from '../../../models';
import { PlacesCustomRequired } from '../../../models/objects/places-custom-required';
import {
  CompaniesStoreActions,
  CompaniesStoreSelectors,
} from '../../../root-store/companies-store';
import { RootState } from '../../../root-store/root-state';
import { CompaniesService, VatCodeGeneratorService } from '../../../services';
import { PlacesService } from '../../../shared/places/services/places.service';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { omit } from 'lodash';

const ITALIAN_COUNTRY_CODE_ID = 1;
@Component({
  selector: 'by-simple-company-form',
  templateUrl: './simple-company-form.component.html',
  styleUrls: ['./simple-company-form.component.scss'],
})
export class SimpleCompanyFormComponent implements OnInit, OnDestroy {
  readonly nzDataModal: Partial<SimpleCompanyFormComponent> =
    inject(NZ_MODAL_DATA);

  @Input() onlySuppliers: boolean = this.nzDataModal?.onlySuppliers || false;

  @Input() set companyId(companyId: number) {
    if (!companyId) {
      return;
    }

    this.creationMode = false;
    this.loadCompanyDetails(companyId);
  }

  @Input() taxSystem: ITaxSystem[];

  @Output() companySelected = new EventEmitter<CompanyLookup>();

  companies$ = this.store.pipe(
    select(CompaniesStoreSelectors.selectAllCompaniesItems),
  );

  countries$ = this.placesService
    .loadCountries()
    .pipe(map(({ data }: IResponseSuccess) => data));

  autoHideBillingPlacesFn = autoHideBillingPlacesFn;

  requiredPlaces: PlacesCustomRequired = {
    countryId: [null],
    cityId: [null],
    stateId: [
      null,
      [requiredIfValidator<PlacesInputIds>(({ countryId }) => countryId === 1)],
    ],
    countyId: [
      null,
      [requiredIfValidator<PlacesInputIds>(({ countryId }) => countryId === 1)],
    ],
  };

  form = this.fb.group({
    id: this.fb.control<number>(null),
    customer_id: this.fb.control<number>(null),
    nick_name: this.fb.control<string>(null),
    name: this.fb.control<string>(null, [
      Validators.pattern(SDI_REGEX),
      Validators.required,
    ]),
    vat_code: this.fb.control<string>(null),
    tax_code: this.fb.control<string>(''),
    tax_system_id: this.fb.control<number>(18),
    places: this.fb.control(null),
    address: this.fb.control(null),
    zip_code: this.fb.control(null),
    receiver_code: this.fb.control(null, [Validators.minLength(6)]),
    public_service: 0,
    email: this.fb.control<string>(null, [EmailValidator.emailValidator]),
    pec: this.fb.control<string>(null, [EmailValidator.emailValidator]),
    vat_country_id: this.fb.control<number>(1, [Validators.required]),
    credit_institution: this.fb.control<string>('', [
      Validators.pattern(SDI_REGEX),
    ]),
    iban: this.fb.control<string>('', [Validators.pattern(SDI_REGEX)]),
    fiscalAgentShow: this.fb.control<boolean>(false),
  });

  formFiscalAgent = this.fb.group({
    fiscal_agent_vat_country_id: this.fb.control<number>(
      ITALIAN_COUNTRY_CODE_ID,
      [Validators.required],
    ),
    fiscal_agent_vat_code: this.fb.control<string>('', [Validators.required]),
    fiscal_agent_fullname: this.fb.control<string>('', [Validators.required]),
    fiscal_agent_name: this.fb.control<string>(''),
    fiscal_agent_surname: this.fb.control<string>(''),
  });

  companyTypes = [
    {
      name: 'private_company',
      id: 0,
    },
    {
      name: 'public_company',
      id: 1,
    },
  ];

  loading = false;

  creationMode = true;

  /*
    billingDetail contiene tutti i dati di fatturazione legati al customer della company.
    Sono costretto ad inserirlo nel value persché il backend se non invio tali dati li elimina.

    Questo oggetto può essere rimosso appena il backend fixa tale comportamento
    */

  billingDetail: Partial<CustomerDetail> = {};

  private subs = new SubSink();

  constructor(
    private fb: FormBuilder,
    private companyService: CompaniesService,
    private error: ErrorHandlerService,
    private store: Store<RootState>,
    private vatCodeGeneratorService: VatCodeGeneratorService,
    private placesService: PlacesService,
  ) {}

  ngOnInit() {
    this.companyId = this.nzDataModal?.companyId;

    this.subs.add(
      this.form
        .get('name')
        .valueChanges.pipe(
          debounceTime(300),
          filter(() => this.creationMode),
        )
        .subscribe((name) => this.searchCompanies(name)),
    );

    this.subs.add(
      this.vatCountryControl.valueChanges.subscribe((vat_country_id) =>
        this.setDefaultReceiverCode({
          ...this.form.value,
          receiver_code: null,
          vat_country_id,
        }),
      ),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.store.dispatch(new CompaniesStoreActions.ResetRequest());
  }

  loadCompanyDetails(companyId: number) {
    this.loading = true;
    this.subs.add(
      this.companyService
        .loadDetails(companyId)
        .pipe(
          catchError((error) => {
            this.error.handle(error);
            return of();
          }),
        )
        .subscribe(({ data }: IResponseSuccess<Company[]>) => {
          this.loading = false;

          const company = data[0];
          this.billingDetail = company.customer.details.find(
            ({ customer_address_type_id }) => customer_address_type_id === 2,
          );

          this.form.patchValue(
            {
              ...this.billingDetail,
              ...company,
              tax_code: company.customer.nic,
              places: {
                countryId: company.country_id,
                country: company.country_name,
                stateId: company.state_id,
                state: company.state_name,
                countyId: company.county_id,
                county: company.county_name,
                cityId: company.city_id,
                city: company.city_name,
              },
            },
            { emitEvent: false },
          );

          const {
            customer: { details },
          } = company || {};

          if (details[0] && details[0].fiscal_agent_vat_code) {
            const {
              fiscal_agent_fullname,
              fiscal_agent_name,
              fiscal_agent_surname,
              fiscal_agent_vat_code,
              fiscal_agent_vat_country_id,
            } = details[0];

            this.form.patchValue({
              fiscalAgentShow: true,
            });

            this.formFiscalAgent.patchValue({
              fiscal_agent_fullname,
              fiscal_agent_name,
              fiscal_agent_surname,
              fiscal_agent_vat_code,
              fiscal_agent_vat_country_id,
            });
          }
        }),
    );
  }

  setDefaultReceiverCode(formValue = this.form.value) {
    const { receiver_code, vat_country_id } = formValue;

    if (
      receiver_code &&
      receiver_code !== DefaultInvoicingReceiverCodes.Italian &&
      receiver_code !== DefaultInvoicingReceiverCodes.NotItalian
    ) {
      return;
    }

    this.form.patchValue(
      {
        receiver_code:
          vat_country_id === 1
            ? DefaultInvoicingReceiverCodes.Italian
            : DefaultInvoicingReceiverCodes.NotItalian,
      },
      { emitEvent: false },
    );
  }

  onSelectCompany(company: CompanyLookup) {
    if (!company) {
      return;
    }

    this.companySelected.emit(company);
  }

  get countrySelectedFiscalAgent() {
    return this.formFiscalAgent?.value?.fiscal_agent_vat_country_id;
  }

  get isAgentEnabled() {
    return this.form.controls.fiscalAgentShow.value;
  }

  onPlacesChange(places: Places) {
    const { zip_code } = places;
    this.form.patchValue({ zip_code });
  }

  get value() {
    /*
    billingDetail contiene tutti i dati di fatturazione legati al customer della company.
    Sono costretto ad inserirlo nel value persché il backend se non invio tali dati li elimina.

    Questo oggetto può essere rimosso appena il backend fixa tale comportamento
    */

    const valuesToOmit = !this.isAgentEnabled
      ? [...Object.keys(this.formFiscalAgent.getRawValue())]
      : [];

    let cleanValue = removeNullishValues({
      ...this.billingDetail,
      ...this.form.getRawValue(),
      ...this.form.value.places,
      ...this.formFiscalAgent.getRawValue(),
    });

    cleanValue = {
      ...omit(cleanValue, valuesToOmit),
    };

    return {
      ...cleanValue,
      vat_code: this.form.value.vat_code || null,
    };
  }

  private searchCompanies(value: string) {
    let data: SearchCompanyRequest = {
      value,
    };

    if (this.onlySuppliers) {
      data = {
        ...data,
        only_suppliers: 1,
      };
    }

    this.store.dispatch(new CompaniesStoreActions.SearchRequestAction(data));
  }

  get disabledCalculateEsterVatCode() {
    return (
      !this.companyName ||
      !this.vatCountryControl.value ||
      this.vatCountryControl.value === 1
    );
  }

  get vatCountryControl() {
    return this.form.get('vat_country_id');
  }

  get companyName() {
    return this.form.get('name').value;
  }

  get vatCodeControl() {
    return this.form.get('vat_code');
  }

  get countryId() {
    return this.form?.value?.places?.countryId;
  }

  onCalculateVatCode() {
    this.vatCodeGeneratorService.generate(
      { name: this.companyName, countryId: this.vatCountryControl.value },
      (vatCode: string) => {
        this.vatCodeControl.setValue(vatCode);
      },
    );
  }
}
