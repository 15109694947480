import { isEmpty, isNil } from 'lodash';
import { ICustomerDetails } from '../features/commons/customer-details/models/customer-details.model';
import { CreateCustomer, CreateCustomerDetails } from '../models';

export const parseCustomerDetailsToCreateCustomer = (
  customer: ICustomerDetails,
): CreateCustomer => {
  if (isEmpty(customer) || isNil(customer)) {
    return;
  }
  const { details, documents } = customer;
  return {
    ...customer,
    birth_country: customer?.birth_country?.name,
    birth_city: customer?.birth_city?.name,
    birth_county: customer?.birth_county?.name,
    birth_state: customer?.birth_state?.name,
    documents: isEmpty(documents)
      ? null
      : (documents || []).map((document) => {
          const {
            city_name: city,
            country_name: country,
            county_name: county,
            state_name: state,
          } = document;
          return {
            ...document,
            document_type_id: document.customer_document_type_id,
            city,
            county,
            country,
            state,
          };
        }),
    details: details.reduce(
      (
        details: CreateCustomerDetails,
        { customer_address_type_id, ...detail },
      ) =>
        (details = {
          ...details,
          [customer_address_type_id]: {
            customer_address_type_id,
            ...detail,
          },
        }),
      {},
    ),
  };
};
