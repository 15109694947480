import { NgZone, inject } from '@angular/core';
import { Observable, Subject, filter } from 'rxjs';
import { BroadcastMessage } from '../models/objects/broadcast-message';
import { runInZone } from '../helpers';

export class BroadcastService {
  private broadcastChannel: BroadcastChannel;

  private message$ = new Subject<BroadcastMessage>();

  private zone = inject(NgZone);

  constructor(broadcastChannelName: string) {
    this.broadcastChannel = new BroadcastChannel(broadcastChannelName);

    this.broadcastChannel.onmessage = (message) => {
      this.message$.next(message?.data);
    };
  }

  publish(data: BroadcastMessage) {
    this.broadcastChannel.postMessage(data);
  }

  messagesOfType(type: string): Observable<any> {
    return this.message$.pipe(
      runInZone(this.zone),
      filter((message) => message?.type === type),
    );
  }
}
