export const showPeriodConditionMapConstant = {
  xnight: true,
  xpax: true,
  xbook: false,
  xbookxpax: false,
  xweekxpax: false,
  xweek: false,
};

export const showChildrenRangesConditionMapConstant = {
  xnight: false,
  xpax: true,
  xbook: false,
  xbookxpax: true,
  xweekxpax: true,
  xweek: false,
};

export const showQuantityAndRateConditionMapConstant = {
  xunit: true,
  xnight: false,
  xpax: false,
  xbook: true,
  xbookxpax: false,
  xweekxpax: false,
  xweek: false,
};
