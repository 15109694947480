<by-reservation-form-first-step
  [hidden]="currentStep !== Steps.First"
  [rateplans]="rateplans"
  [estimateResponse]="estimateResponse"
  [proportionalDayPriceResponse]="proportionalDayPriceResponse"
  [accommodation]="accommodation"
  [childrenRanges]="childrenRanges"
  [dates]="dates"
  [priceLoading]="priceLoading"
  [currency]="currency"
  [invoiceLayouts]="invoiceLayouts"
  [defaultInvoiceLayoutId]="defaultInvoiceLayoutId"
  [nextDepositNumber]="nextDepositNumber"
  [nextDepositNumberLoading]="nextDepositNumberLoading"
  [paymentMethods]="paymentMethods"
  [isMobile]="isMobile$ | async"
  (estimate)="onEstimate($event)"
  (proportionalDayPrice)="proportionalDayPrice.emit($event)"
  (loadDepositNextNumber)="loadDepositNextNumber.emit($event)"
  (discountChanged)="onLoadAutomaticPayments()"
></by-reservation-form-first-step>

<by-reservation-form-second-step
  [hidden]="currentStep !== Steps.Second"
  [reservationFroms]="reservationFroms"
  [dealers]="dealers"
  [languages]="languages"
  [firstStepRequest]="firstStepRequest"
  [estimateResponse]="estimateResponse"
  [estimateRateplans]="rateplans"
  [proportionalDayPriceResponse]="proportionalDayPriceResponse"
  [currency]="currency"
  [priceLoading]="priceLoading"
  [accommodation]="accommodation"
  [customEmailTeplates]="customEmailTeplates"
  [isMobile]="isMobile$ | async"
  [automaticPaymentModule]="automaticPaymentModule"
  [automaticPaymentSummary]="automaticPaymentSummary"
  [automaticPaymentsLoading]="automaticPaymentsLoading"
  (proportionalDayPrice)="proportionalDayPrice.emit($event)"
  (estimate)="onEstimate($event)"
  (companySelected)="onCompanySelected($event)"
  (priceChanged)="onPriceChanged($event)"
  (addAddons)="onAddAddons()"
  (showEmailPreview)="onShowEmailPreview()"
  (createCompany)="createCompany.emit()"
  (discountChanged)="onLoadAutomaticPayments()"
></by-reservation-form-second-step>
