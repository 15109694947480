import { IRoomModel } from '@app/shared/add-bill-modal/models/rooms.model';
import { IEmailTemplateCustom } from '@commons/email-templates/models/email-template-custom.model';
import { IProperty } from '@commons/properties/models/property.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { filter, find, get, keyBy, merge, sortBy, uniqBy } from 'lodash';
import moment from 'moment';

import { DISCOUNT_TYPES_ICONS } from '../../config';
import { PropertySubscriptionInfo } from '../../models';
import { IAvailableRoomData } from '../models/api/generics/available-room/available-room.model';
import { ICheckinOut } from '../models/api/generics/checkin-out/checkin-out.model';
import { ICurrency } from '../models/api/generics/currencies/currency.model';
import { IDiscountType } from '../models/api/generics/discount-types/discount-type.model';
import { ILanguage } from '../models/api/generics/languages/language.model';
import { IPaymentMethod } from '../models/api/generics/payment-methods/payment-method.model';
import { IVatQuoteHolder } from '../models/api/generics/vat-quote-holder/vat-quote-holder.model';
import { IPropertyTreatment } from '../models/api/properties-treatments/property-treatment.model';
import { ISplitReservationResponse } from '../models/api/reservation/reservaion.model';

import { IMoveReservationResponse } from './../models/api/reservation/move-reservation-request.model';
import { CoreActions, CoreActionTypes } from './core.actions';
import { IPropertyGroup } from '../../features/commons/properties/models/property-group.model';

export interface ICoreState {
  generics: {
    loading: boolean;
    loadingNewReservation: boolean;
    paymentMethods: IPaymentMethod[];
    paymentMethodsProperty: { [property_id: number]: IPaymentMethod[] };
    languages: ILanguage[];
    currencies: ICurrency[];
    discount_types: IDiscountType[];
    checkinOut: ICheckinOut;
  };
  selectedGroup: { id: number; name: string; properties?: { id: number }[] };
  allProperties: IProperty[];
  selectedProperty: {
    id: number;
    name: string;
    country_id?: number;
    city_name: string;
    enable_reservation_channel_editing?: 0 | 1;
  };
  allGroups: IPropertyGroup[];
  allUserPropeties: IProperty[];
  genericsSearchInput: {
    reservations: any[];
    reservationsToMove: any[];
  };
  flashData: {
    reservationSplit: ISplitReservationResponse;
    reservationMove: IMoveReservationResponse;
    availableRoom: IAvailableRoomData[];
    vatQuoteHolderSearch: IVatQuoteHolder;
  };
  templatesEmailCustom: { [property_id: number]: IEmailTemplateCustom[] };
  propertiesTreatments: IPropertyTreatment[];
}

export const initialState: ICoreState = {
  generics: {
    loading: false,
    loadingNewReservation: false,
    paymentMethods: [],
    paymentMethodsProperty: {},
    languages: [],
    currencies: [],
    discount_types: [],
    checkinOut: null,
  },
  selectedProperty: null,
  allProperties: [],
  selectedGroup: null,
  allGroups: [],
  allUserPropeties: [],
  genericsSearchInput: {
    reservations: [],
    reservationsToMove: [],
  },
  flashData: {
    reservationSplit: null,
    reservationMove: null,
    availableRoom: null,
    vatQuoteHolderSearch: null,
  },
  templatesEmailCustom: {},
  propertiesTreatments: [],
};

export function reducer(state = initialState, action: CoreActions): ICoreState {
  switch (action.type) {
    case CoreActionTypes.ResetCore: {
      return initialState;
    }

    case CoreActionTypes.ResetAddBillModalRooms: {
      return {
        ...state,
        genericsSearchInput: initialState.genericsSearchInput,
      };
    }
    case CoreActionTypes.ResetFlashData: {
      return {
        ...state,
        flashData: initialState.flashData,
      };
    }
    case CoreActionTypes.LoadCore:
      return state;

    case CoreActionTypes.LoadGeneralDiscountTypesSuccess:
      return {
        ...state,
        generics: {
          ...state.generics,
          discount_types: action.payload.discountTypesData?.map(
            (discountType) => {
              const icon = DISCOUNT_TYPES_ICONS[discountType.id];
              return {
                ...discountType,
                icon,
              };
            },
          ),
        },
      };

    case CoreActionTypes.LoadLanguagesSuccess:
      return {
        ...state,
        generics: { ...state.generics, languages: action.payload.languages },
      };

    case CoreActionTypes.LoadCurrenciesSuccess:
      return {
        ...state,
        generics: { ...state.generics, currencies: action.payload.currencies },
      };

    case CoreActionTypes.LoadCheckinOutSuccess: {
      const { checkinOutData, date_from } = action.payload;
      return {
        ...state,
        generics: {
          ...state.generics,
          checkinOut: {
            ...state.generics.checkinOut,
            [date_from]: checkinOutData[0],
          },
        },
      };
    }

    case CoreActionTypes.LoadAllUserPropertiesSuccess: {
      const allUserPropeties = action.payload.allUserPropeties
        .slice()
        .sort((a, b) => a.order - b.order);
      return { ...state, allUserPropeties };
    }

    case CoreActionTypes.LoadUserPropertiesSuccess: {
      const { selectedPropertyId, properties } = action.payload;

      let groups = [];

      if (properties.groups) {
        groups = properties.groups.filter(
          (group) => group.properties !== null && group.properties.length > 0,
        );
      }
      let allUserPropeties = [];
      const groupLocalStorageID = localStorage.getItem('selectedGroupId');
      let allGroups = groups;
      if (properties.default.length > 0) {
        allGroups = [
          ...allGroups,
          {
            id: 0,
            name: 'default',
            properties: properties.default,
            owner: 0,
          },
        ];
      }
      allGroups = allGroups.map((group) => {
        const { properties: groupProperties } = group;

        allUserPropeties = [...allUserPropeties, ...groupProperties];
        const sortProperties = groupProperties
          .slice()
          .sort((a, b) => a.order - b.order);
        return {
          ...group,
          properties: sortProperties,
        };
      });
      let selectedGroup = null;
      if (groupLocalStorageID) {
        selectedGroup = allGroups.find(
          (group) => group.id === +groupLocalStorageID,
        );
        if (!selectedGroup) {
          localStorage.removeItem('selectedGroupId');
          selectedGroup =
            properties.default.length > 0
              ? { id: 0, name: 'default' }
              : { id: groups[0].id, name: groups[0].name };
        }
      } else {
        selectedGroup =
          properties.default.length > 0
            ? { id: 0, name: 'default' }
            : { id: groups[0].id, name: groups[0].name };
      }
      const selectedGroupProperties =
        selectedGroup.id > 0
          ? groups.find((group) => group.id === selectedGroup.id).properties
          : properties.default;

      const allProperties = selectedGroupProperties
        .slice()
        .sort((a, b) => a.order - b.order);

      const propertyLocalStorageID = localStorage.getItem('selectedPropertyId');
      let selectedProperty = null;
      if (propertyLocalStorageID) {
        selectedProperty = allProperties.find(
          (property) => property.id === +propertyLocalStorageID,
        );
        if (!selectedProperty) {
          localStorage.removeItem('selectedPropertyId');
          selectedProperty = {
            id: selectedGroupProperties[0].id,
            name: selectedGroupProperties[0].name,
            city_name: selectedGroupProperties[0].city_name,
            enable_reservation_channel_editing:
              selectedGroupProperties[0].enable_reservation_channel_editing,
          };
        }
      } else {
        selectedProperty = {
          id: selectedGroupProperties[0].id,
          name: selectedGroupProperties[0].name,
          city_name: selectedGroupProperties[0].city_name,
          enable_reservation_channel_editing:
            selectedGroupProperties[0].enable_reservation_channel_editing,
        };
      }

      // selectedPropertyId è la property selezionata dall'admin sulla lista delle strutture, quindi in questo caso
      // la modale afterLoginModal darà come valori di default nella select la struttura selezionata o il gruppo a cui appartiene
      // la struttura selezionata
      if (selectedPropertyId && selectedGroupProperties) {
        selectedGroup = allGroups.find(
          ({ properties: propertiesGroup }) =>
            !!propertiesGroup.find(({ id }) => id === selectedPropertyId),
        );
        selectedProperty = get(selectedGroup, 'properties', []).find(
          ({ id }) => id === selectedPropertyId,
        );
      }
      return {
        ...state,
        selectedGroup,
        selectedProperty,
        allProperties,
        allUserPropeties: allUserPropeties
          .slice()
          .sort((a, b) => a.order - b.order),
        allGroups,
      };
    }

    case CoreActionTypes.SortPropertiesSuccess: {
      const { propertyIds } = action.payload;
      const { allProperties, allUserPropeties, allGroups } = state;

      const sortedAllUserPropeties = sortBy(allUserPropeties, (item) =>
        propertyIds.indexOf(item.id),
      ).map((property, index) => {
        return {
          ...property,
          order: index,
        };
      });

      const sortedAllProperties = sortBy(allProperties, (item) =>
        propertyIds.indexOf(item.id),
      );

      const sortedAllGroups = allGroups.map((group) => {
        const { properties: groupProperties } = group;
        const sortProperties = groupProperties
          .slice()
          .sort((a, b) => a.order - b.order);
        return {
          ...group,
          properties: sortProperties,
        };
      });
      return {
        ...state,
        allUserPropeties: sortedAllUserPropeties,
        allProperties: sortedAllProperties,
        allGroups: sortedAllGroups,
      };
    }

    case CoreActionTypes.SelectProperty: {
      const { propertyId } = action.payload;
      localStorage.setItem('selectedPropertyId', propertyId.toString());
      const property = state.allProperties.find((p) => p.id === propertyId);
      return { ...state, selectedProperty: property };
    }

    case CoreActionTypes.SelectGroup: {
      const { groupId } = action.payload;

      const selectedGroup = state.allGroups.find(
        (group) => group.id === groupId,
      );

      localStorage.setItem('selectedGroupId', groupId.toString());
      localStorage.removeItem('selectedPropertyId');

      if (!selectedGroup) {
        return state;
      }

      const allProperties = selectedGroup.properties;
      return {
        ...state,
        allProperties,
        selectedGroup,
        selectedProperty: {
          id: allProperties[0].id,
          name: allProperties[0].name,
          city_name: allProperties[0].city_name,
        },
      };
    }

    case CoreActionTypes.SelectProperties: {
      const { selectedGroup, allGroups } = state;
      const groupProperties = allGroups.find(
        (group) => group.id === selectedGroup.id,
      ).properties;
      const { propertiesIds } = action.payload;
      return {
        ...state,
        allProperties: groupProperties.filter((property) => {
          return propertiesIds.find((propertyId) => propertyId === property.id);
        }),
      };
    }

    case CoreActionTypes.LoadReservationsRoomsCoreRequest:
      return { ...state };

    case CoreActionTypes.LoadReservationsRoomsCoreSuccess:
      return {
        ...state,
        genericsSearchInput: {
          ...state.genericsSearchInput,
          reservations: action.payload.reservations,
        },
      };

    case CoreActionTypes.LoadReservationsRoomsCoreFailure:
      return { ...state };

    case CoreActionTypes.LoadReservationsRoomsToMoveCoreRequest:
      return { ...state };

    case CoreActionTypes.LoadReservationsRoomsToMoveCoreSuccess:
      return {
        ...state,
        genericsSearchInput: {
          ...state.genericsSearchInput,
          reservationsToMove: action.payload.reservations,
        },
      };

    case CoreActionTypes.LoadReservationsRoomsToMoveCoreFailure:
      return { ...state };

    case CoreActionTypes.LoadTemplateEmailCustomRequest:
      return { ...state };

    case CoreActionTypes.LoadTemplateEmailCustomSuccess: {
      const { templatesEmailCustom, property_id } = action.payload;
      const templatesEmailCustomData = merge({}, state.templatesEmailCustom, {
        [property_id]: templatesEmailCustom,
      });
      return { ...state, templatesEmailCustom: templatesEmailCustomData };
    }

    case CoreActionTypes.LoadTemplateEmailCustomFailure:
      return { ...state };

    case CoreActionTypes.LoadPaymentsMethodPropertyRequest:
      return { ...state };

    case CoreActionTypes.LoadPropertiesTreatmentsRequest:
      return { ...state };

    case CoreActionTypes.LoadPropertiesTreatmentsSuccess: {
      const { propertiesTreatmentsData } = action.payload;
      return {
        ...state,
        propertiesTreatments: [
          ...state.propertiesTreatments,
          ...propertiesTreatmentsData,
        ],
      };
    }

    case CoreActionTypes.LoadPropertiesTreatmentsFailure:
      return { ...state };

    case CoreActionTypes.CreateNewReservationRequest:
      return {
        ...state,
        generics: { ...state.generics, loadingNewReservation: true },
      };

    case CoreActionTypes.LoadPaymentsMethodPropertySuccess: {
      const { paymentsMethodProperty, property_id } = action.payload;
      const { paymentMethods } = state.generics;
      const newPaymentsMethodProperty = filter(
        paymentsMethodProperty,
        'status',
      );

      const newPaymentMethods = paymentMethods
        .map((data) => {
          const valueFind = find(
            newPaymentsMethodProperty,
            (o: any) => (data.id || data.payment_id) === o.payment_id,
          );
          if (valueFind) {
            return data;
          }
        })
        .filter((val) => val !== undefined);

      const paymentMethodsProperty = merge(
        {},
        state.generics.paymentMethodsProperty,

        {
          [property_id]: newPaymentMethods.length
            ? newPaymentMethods
            : newPaymentsMethodProperty,
        },
      );

      return {
        ...state,
        generics: {
          ...state.generics,
          paymentMethodsProperty,
          loading: true,
        },
      };
    }

    case CoreActionTypes.LoadPaymentsMethodPropertyFailure:
      return { ...state };

    case CoreActionTypes.CreateNewReservationSuccess: {
      return {
        ...state,
        generics: { ...state.generics, loadingNewReservation: false },
      };
    }

    case CoreActionTypes.CreateNewReservationFailure:
      return {
        ...state,
        generics: { ...state.generics, loadingNewReservation: false },
      };

    case CoreActionTypes.SplitReservationRequest: {
      return { ...state, generics: { ...state.generics, loading: false } };
    }

    case CoreActionTypes.SplitReservationRequest: {
      return {
        ...state,
        flashData: initialState.flashData,
      };
    }

    case CoreActionTypes.SplitReservationSuccess: {
      const { splitReservation } = action.payload;
      return {
        ...state,
        generics: { ...state.generics, loading: false },
        flashData: { ...state.flashData, reservationSplit: splitReservation },
      };
    }

    case CoreActionTypes.SplitReservationWarningSuccess: {
      const { splitReservation } = action.payload;
      return {
        ...state,
        generics: { ...state.generics, loading: false },
        flashData: { ...state.flashData, reservationSplit: splitReservation },
      };
    }
    case CoreActionTypes.SplitReservationFailure:
      return {
        ...state,
        generics: { ...state.generics, loading: false },
        flashData: { ...state.flashData, reservationSplit: { error: true } },
      };

    case CoreActionTypes.MoveReservationRequest: {
      return { ...state, generics: { ...state.generics, loading: false } };
    }

    case CoreActionTypes.MoveReservationSuccess: {
      const { moveReservation } = action.payload;
      return {
        ...state,
        generics: { ...state.generics, loading: false },
        flashData: { ...state.flashData, reservationMove: moveReservation },
      };
    }

    case CoreActionTypes.MoveReservationWarningSuccess: {
      const { moveReservation } = action.payload;
      return {
        ...state,
        generics: { ...state.generics, loading: false },
        flashData: { ...state.flashData, reservationMove: moveReservation },
      };
    }

    case CoreActionTypes.MoveReservationFailure:
      return {
        ...state,
        generics: { ...state.generics, loading: false },
        flashData: { ...state.flashData, reservationMove: { error: true } },
      };

    case CoreActionTypes.SetLoading: {
      const { loading } = action.payload;

      return {
        ...state,
        generics: { ...state.generics, loading },
      };
    }
    case CoreActionTypes.LoadAvailableRoomRequest: {
      return {
        ...state,
        flashData: initialState.flashData,
      };
    }
    case CoreActionTypes.LoadAvailableRoomFailure: {
      return {
        ...state,
        flashData: initialState.flashData,
      };
    }

    case CoreActionTypes.LoadAvailableRoomSuccess: {
      const { data } = action.payload;
      return {
        ...state,
        flashData: { ...state.flashData, availableRoom: data },
      };
    }

    case CoreActionTypes.VatQuoteHolderRequest: {
      return {
        ...state,
        flashData: initialState.flashData,
      };
    }
    case CoreActionTypes.VatQuoteHolderFailure: {
      return {
        ...state,
        flashData: initialState.flashData,
      };
    }

    case CoreActionTypes.VatQuoteHolderSuccess: {
      const { data } = action.payload;
      return {
        ...state,
        flashData: { ...state.flashData, vatQuoteHolderSearch: data },
      };
    }

    case CoreActionTypes.ResetOnChangeLanguage: {
      return {
        ...state,
        generics: {
          ...state.generics,
          discount_types: initialState.generics.discount_types,
          paymentMethods: initialState.generics.paymentMethods,
          paymentMethodsProperty: initialState.generics.paymentMethodsProperty,
        },
      };
    }

    default:
      return state;
  }
}

export const selectCoreState = createFeatureSelector<ICoreState>('core');

export const selectGenerics = createSelector(
  selectCoreState,
  (state: ICoreState) => state.generics,
);

export const selectDiscountTypes = createSelector(
  selectGenerics,
  (state) => state.discount_types,
);

export const selectDiscountTypesObject = createSelector(
  selectGenerics,
  (state) => {
    if (state.discount_types) {
      let newDiscountTypes = {};
      state.discount_types.forEach((el) => {
        newDiscountTypes = { ...newDiscountTypes, [el.id]: el };
      });
      return newDiscountTypes;
    }
    return {};
  },
);

export const selectDiscountTypesWithoutFree = createSelector(
  selectDiscountTypes,
  (discountTypes: IDiscountType[]) =>
    discountTypes.filter(
      (discountType: IDiscountType) =>
        discountType.discount_operation !== 'free',
    ),
);

export const selectLanguages = createSelector(selectGenerics, (state) => {
  return state.languages;
});

export const selectLanguagesMap = createSelector(selectLanguages, (languages) =>
  keyBy(languages, 'iso_code'),
);

export const selectAvailableLanguages = createSelector(
  selectGenerics,
  (state) =>
    state.languages.filter((language) => {
      return language.iso_code === 'it' || language.iso_code === 'en';
    }),
);

export const selectLangIsocode = (langId: number) =>
  createSelector(
    selectGenerics,
    (state): string =>
      state.languages.find((lang) => lang.id === langId).iso_code,
  );

export const selectCurrencies = createSelector(
  selectGenerics,
  (state) => state.currencies,
);

export const selectCurrentPropertyId = createSelector(
  selectCoreState,
  (core: ICoreState) => core.selectedProperty,
);

export const selectCheckinOut = createSelector(
  selectGenerics,
  (state) => state.checkinOut,
);

export const selectAllProperties = createSelector(
  selectCoreState,
  (state: ICoreState) => state.allProperties,
);

export const allPropertiesIsDemo = createSelector(
  selectCoreState,
  (state: ICoreState) =>
    !state.allProperties?.find((property) => !property?.demo),
);

export const selectAllPropertiesNames = createSelector(
  selectCoreState,
  (state: ICoreState): { [propertyId: number]: string } =>
    (state.allProperties || []).reduce(
      (propertiesNames, property) =>
        (propertiesNames = {
          ...propertiesNames,
          [property.id]: property.name,
        }),
      {},
    ),
);

export const selectAllPropertiesSubscriptionInfoWarnings = createSelector(
  selectCoreState,
  (state: ICoreState): PropertySubscriptionInfo[] =>
    (state.allProperties || [])
      .reduce((propertiesSubscriptionInfo, property) => {
        const { id, expire_date, name } = property;
        const days_to_expiration = moment(expire_date).diff(moment(), 'days');

        if (days_to_expiration > 14) {
          return propertiesSubscriptionInfo;
        }

        const propertyData = {
          id,
          name,
          expire_date,
          status: days_to_expiration < 0 ? 'Expired' : 'Expiring',
          days_to_expiration,
        };
        return [...propertiesSubscriptionInfo, propertyData];
      }, [])
      .sort((a, b) => a.days_to_expiration - b.days_to_expiration),
);

export const selectAllGroups = createSelector(
  selectCoreState,
  (state: ICoreState) => state.allGroups,
);

export const selectSelectedProperty = createSelector(
  selectCoreState,
  (state: ICoreState) => state.selectedProperty,
);

export const selectedPropertyCanReservationChannelEdit = createSelector(
  selectSelectedProperty,
  (property) => !!property?.enable_reservation_channel_editing,
);

export const selectPropertyCurrency = createSelector(
  selectCoreState,
  (state: ICoreState) => {
    if (
      state.selectedProperty &&
      state.allUserPropeties &&
      state.allUserPropeties.length &&
      state.generics.currencies &&
      state.generics.currencies.length
    ) {
      const findPropertySelected = state.allUserPropeties.find(
        (property) => property.id === state.selectedProperty.id,
      );
      const { currency_id } = findPropertySelected;
      return state.generics.currencies.find(
        (currency) => currency.id === currency_id,
      );
    }
  },
);

export const selectSelectedGroup = createSelector(
  selectCoreState,
  (state: ICoreState) => state.selectedGroup,
);

export const selectUserAllProperties = createSelector(
  selectCoreState,
  (state: ICoreState) => state.allUserPropeties,
);

export const selectUserAllPropertiesIds = createSelector(
  selectCoreState,
  (state: ICoreState) =>
    (state.allUserPropeties || []).map((property) => property.id),
);

export const selectPropertySetting = (propertyId: number) =>
  createSelector(selectCoreState, (state: ICoreState) =>
    state.allUserPropeties.find((property) => property.id === +propertyId),
  );

export const selectPaymentMethods = createSelector(
  selectCoreState,
  (state: ICoreState) => state.generics.paymentMethods,
);

export const selectCurrencyProperty = createSelector(
  selectCoreState,
  (state: ICoreState) => {
    if (
      state.allProperties &&
      state.allProperties.length &&
      state.generics.currencies &&
      state.generics.currencies.length
    ) {
      const { id: firstPropertyId } = state.allProperties[0];
      const propertyDetails = (state.allUserPropeties || []).find(
        ({ id }) => firstPropertyId === id,
      );
      const currency_id = get(propertyDetails, 'currency_id', '');
      return state.generics.currencies.find(
        (currency) => currency.id === currency_id,
      );
    }
  },
);

export const selectCurrencySymbolMapping = createSelector(
  selectCoreState,
  (state: ICoreState) => {
    if (state.generics.currencies && state.generics.currencies.length) {
      return state.generics.currencies.reduce(
        (map, { id, symbol }) => (map = { ...map, [id]: symbol }),
        {},
      );
    }
  },
);

export const selectPropertySelectedLanguage = createSelector(
  selectCoreState,
  (state: ICoreState): string => {
    if (!state.selectedProperty) {
      return null;
    }
    const selectedPropertyId = state.selectedProperty.id;
    const findProperty = state.allUserPropeties.find(
      (property) => property.id === selectedPropertyId,
    );
    if (findProperty) {
      return findProperty.lang_iso_code;
    } else {
      return null;
    }
  },
);

export const selectLanguagesOrderByPropertyLanguage = createSelector(
  selectLanguages,
  selectPropertySelectedLanguage,
  (allLanguages, languagePropertySelected) => {
    if (!languagePropertySelected) {
      return allLanguages;
    }

    return allLanguages.reduce((orderedLanguages, currentLanguage) => {
      if (currentLanguage.iso_code === languagePropertySelected) {
        orderedLanguages.unshift(currentLanguage);
      } else {
        orderedLanguages.push(currentLanguage);
      }

      return orderedLanguages;
    }, []);
  },
);

export const selectGenericsLoading = createSelector(
  selectCoreState,
  (state: ICoreState) => state.generics.loading,
);

export const selectFlashDataReservationSplit = createSelector(
  selectCoreState,
  (state: ICoreState) => state.flashData.reservationSplit,
);

export const selectLoadingNewReservation = createSelector(
  selectCoreState,
  (state: ICoreState) => state.generics.loadingNewReservation,
);

export const selectFlashDataAvailableRoom = createSelector(
  selectCoreState,
  (state: ICoreState) => state.flashData.availableRoom,
);

export const selectFlashDatavatQuoteHolderSearch = createSelector(
  selectCoreState,
  (state: ICoreState) => state.flashData.vatQuoteHolderSearch,
);

export const selectTemplateEmailCustom = (propertyId?: number) =>
  createSelector(
    selectCoreState,
    (
      state: ICoreState,
    ):
      | { [propery_id: number]: IEmailTemplateCustom[] }
      | IEmailTemplateCustom[] => {
      if (propertyId) {
        return state.templatesEmailCustom[propertyId];
      }
      return state.templatesEmailCustom;
    },
  );

export const selectPaymentsMethodProperty = (propertyId?: number) =>
  createSelector(
    selectCoreState,
    (
      state: ICoreState,
    ): { [propery_id: number]: IPaymentMethod[] } | IPaymentMethod[] => {
      if (propertyId) {
        return state.generics.paymentMethodsProperty[propertyId];
      }
      return state.generics.paymentMethodsProperty;
    },
  );

export const selectPropertiesTreatments = (propertyId?: number) =>
  createSelector(selectCoreState, (state: ICoreState): IPropertyTreatment[] => {
    if (propertyId) {
      return state.propertiesTreatments.filter(
        (propertyTreatment) => propertyTreatment.property_id === propertyId,
      );
    }
    const newData: any = uniqBy(state.propertiesTreatments, 'treatment_id');
    return newData;
  });

export const selectPropertiesTreatmentsObjects = createSelector(
  selectCoreState,
  (state: ICoreState): { [propertyId: number]: IPropertyTreatment[] } => {
    return state.propertiesTreatments.reduce((treatmentsObj, treatment) => {
      const { property_id } = treatment;
      if (!treatmentsObj[property_id]) {
        treatmentsObj[property_id] = [];
      }
      treatmentsObj = {
        ...treatmentsObj,
        [property_id]: [...treatmentsObj[property_id], treatment]
          .slice()
          .sort(
            (a: IPropertyTreatment, b: IPropertyTreatment) =>
              a.treatment_id - b.treatment_id,
          ),
      };
      return treatmentsObj;
    }, {});
  },
);

export const selectReservationRooms = (
  date?: string,
  dateChekout?: string,
  reservationAccommodationId?: number,
  tableau_room_id?: number,
) =>
  createSelector(selectCoreState, (state: ICoreState): IRoomModel[] => {
    let rooms;
    const selectReservationActive =
      state.genericsSearchInput.reservations.filter(
        (reservation) => reservation.status === 'Confirmed',
      );
    rooms = selectReservationActive.reduce((_rooms: any[], reservation) => {
      const payment_agreement = reservation.payment_agreement;
      const reservationDepartureDate = moment(
        reservation.departure_date,
      ).format('YYYY-MM-DD');
      const booker = reservation.booker;
      const currencySymbol = reservation.currency.symbol;
      const newRooms: IRoomModel[] = reservation.accommodations.reduce(
        (accRooms: any[], accommodation) => {
          const guests = accommodation.guests;
          const splitReferences = accommodation.split_references;
          const reservationId = accommodation.reservation_id;
          const propertyId = accommodation.property_id;
          const max_adults = get(accommodation, 'accommodation.max_adults', '');
          const max_children = get(
            accommodation,
            'accommodation.max_children',
            '',
          );
          const newAccRooms = accommodation.rooms.map((accRoom) => {
            return {
              ...accRoom,
              payment_agreement,
              guests,
              reservationId: reservationId,
              booker,
              originalBooker: booker,
              currencySymbol,
              split_references: splitReferences,
              reservationDepartureDate,
              propertyId,
              max_adults,
              max_children,
              tableau: {
                ...accRoom.tableau,
                label:
                  accRoom.tableau && accRoom.tableau.label
                    ? accRoom.tableau.label
                    : 'OB',
              },
            };
          });
          accRooms = [...accRooms, ...newAccRooms];
          return accRooms;
        },
        [],
      );
      _rooms = [..._rooms, ...newRooms];
      return _rooms;
    }, []);
    if (date && dateChekout) {
      rooms = rooms.filter((room) => {
        const _date = moment(date, 'YYYY-MM-DD');
        const arrivalDate = moment(room.arrival_date, 'YYYY-MM-DD');
        const departureDate = moment(room.departure_date, 'YYYY-MM-DD');
        return (
          arrivalDate.isSameOrBefore(_date) &&
          departureDate.isSameOrAfter(dateChekout)
        );
      });
    }
    if (reservationAccommodationId) {
      rooms = rooms.filter((__room) => {
        return (
          __room.reservation_accommodation_id === reservationAccommodationId
        );
      });
    }
    if (tableau_room_id) {
      rooms = rooms.filter((___rooms) => {
        return ___rooms.tableau.id === tableau_room_id;
      });
    }
    return rooms;
  });

export const selectReservationRoomsToMove = (roomToFilterId: number) =>
  createSelector(selectCoreState, (state: ICoreState): IRoomModel[] => {
    let rooms;
    const selectReservationActive =
      state.genericsSearchInput.reservationsToMove.filter(
        (reservation) => reservation.status === 'Confirmed',
      );
    rooms = selectReservationActive.reduce((_rooms: any[], reservation) => {
      const reservationDepartureDate = moment(
        reservation.departure_date,
      ).format('YYYY-MM-DD');
      const booker = reservation.booker;
      const currency = reservation.currency.symbol;
      const newRooms: IRoomModel[] = reservation.accommodations.reduce(
        (accRooms: any[], accommodation) => {
          const guests = accommodation.guests;
          const splitReferences = accommodation.split_references;
          const reservationId = accommodation.reservation_id;
          const newAccRooms = accommodation.rooms.map((accRoom) => {
            const propertyId = accommodation.property_id;
            return {
              ...accRoom,
              guests,
              reservationId: reservationId,
              booker,
              originalBooker: booker,
              currency,
              split_references: splitReferences,
              reservationDepartureDate,
              propertyId,
            };
          });
          accRooms = [...accRooms, ...newAccRooms];
          return accRooms;
        },
        [],
      );
      _rooms = [..._rooms, ...newRooms];
      return _rooms;
    }, []);
    return rooms;
  });
