import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CreateHousekeeperTaskRequest, HousekeeperTask } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HousekeeperTasksService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`properties/${propertyId}/housekeeper/tasks`);
  }

  create(propertyId: number, request: CreateHousekeeperTaskRequest) {
    return this.http.post(
      `properties/${propertyId}/housekeeper/tasks`,
      request,
    );
  }

  update(propertyId: number, request: HousekeeperTask) {
    const { id, ...task } = request;

    return this.http.put(
      `properties/${propertyId}/housekeeper/tasks/${id}`,
      task,
    );
  }

  delete(propertyId: number, taskId: number) {
    return this.http.delete(
      `properties/${propertyId}/housekeeper/tasks/${taskId}`,
    );
  }

  detach(propertyId: number, cleanableId: number, taskId: number) {
    return this.http.delete(
      `properties/${propertyId}/housekeeper/cleanables/${cleanableId}/tasks/${taskId}`,
    );
  }

  order(propertyId: number, cleanableId: number, taskIds: number[]) {
    return this.http.post(
      `properties/${propertyId}/housekeeper/cleanables/${cleanableId}/tasks/order`,
      { cleanable_housekeeper_task_order: taskIds },
    );
  }

  setActive(
    propertyId: number,
    cleanableId: number,
    taskId: number,
    active: boolean,
  ) {
    return this.http.put(
      `properties/${propertyId}/housekeeper/cleanables/${cleanableId}/tasks/${taskId}/active`,
      { active },
    );
  }
}
