import { Pipe, PipeTransform } from '@angular/core';

import { Invoice, InvoiceDetails } from '../../../models';

@Pipe({
  name: 'invoiceDestination',
})
export class InvoiceDestinationPipe implements PipeTransform {
  constructor() {}

  transform(invoice: Invoice | InvoiceDetails | Partial<Invoice>): string {
    if (!invoice) {
      return '';
    }

    invoice = invoice as InvoiceDetails;

    if (invoice.destination) {
      return (
        invoice.destination.business_name ||
        `${invoice.destination.surname} ${invoice.destination.name || ''}`
      );
    }

    return (
      invoice['recipient_business_name'] ||
      invoice.company?.name ||
      (invoice.customer
        ? invoice.customer.surname + ' ' + invoice.customer.name
        : invoice.label)
    );
  }
}
