import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

const maxAge = 3600000;

type ApiToCache = 'channelmanager/accommodations_mapping';

interface CachedApi {
  url: string;
  response: any;
  lastRead: number;
}

@Injectable({
  providedIn: 'root',
})
export class RequestCacheService {
  API_TO_CACHE: ApiToCache[] = ['channelmanager/accommodations_mapping'];

  cache = new Map<string, CachedApi>();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;

    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < Date.now() - maxAge;

    if (isExpired) {
      return undefined;
    }

    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.url;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach((expiredEntry) => {
      if (expiredEntry.lastRead < expired) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }

  delete(apiToDeleteFromCache: ApiToCache) {
    this.cache.forEach((cachedApi) => {
      if (cachedApi.url.includes(apiToDeleteFromCache)) {
        this.cache.delete(cachedApi.url);
      }
    });
  }

  shouldCache(url: string) {
    return (
      this.API_TO_CACHE.findIndex((urlToCache) => url.includes(urlToCache)) !==
      -1
    );
  }
}
