<div
  [ngClass]="{ modalDraggableMove: draggable }"
  class="page-header-component"
  [ngStyle]="byStyle"
  [ngClass]="byClass"
>
  <div
    [ngClass]="{ modalDraggableMove: draggable }"
    class="rectangle"
    *ngIf="!noBlue"
  ></div>
  <span
    [ngClass]="{
      modalDraggableMove: draggable,
      'by-fs-16': size === 'default',
      'by-fs-14': size === 'small'
    }"
    class="title"
    [ngStyle]="byTitleStyle"
    *ngIf="title !== ''"
    ><i class="{{ icon ? icon + 'by-mr-10' : '' }}"></i
    >{{ title | upperfirst }}</span
  >
  <span
    [ngClass]="{
      modalDraggableMove: draggable,
      'by-fs-16': size === 'default',
      'by-fs-14': size === 'small'
    }"
    class="title"
    [ngStyle]="byTitleStyle"
    *ngIf="title === ''"
  >
    <ng-container
      *ngTemplateOutlet="titleTemplate || titleContentTemplate"
    ></ng-container>
  </span>
  <div
    [class.modalDraggableoMve]="draggable"
    [ngClass]="byContentContainerClass"
    [ngStyle]="byContentStyle"
  >
    <ng-content></ng-content>
  </div>
</div>
