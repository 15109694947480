import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root',
})
export class LoadingModalService implements OnInit {
  private modal: NzModalRef;

  private openedModals = 0;

  public imageUrl: string;

  public get message(): string {
    return this.translateService.instant('loading_modal_default_message');
  }

  constructor(
    public modalService: NzModalService,
    public translateService: TranslateService,
  ) {}

  ngOnInit() {}

  public start() {
    this.openedModals++;

    if (this.modal) {
      return;
    }

    this.modal = this.modalService.create({
      nzContent: this.template,
      nzWidth: '400px',
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
    });
  }

  public stop() {
    this.openedModals--;

    if (!this.modal || this.openedModals > 0) {
      return;
    }

    this.modal.destroy();
    this.modal = null;
  }

  private get template(): string {
    return `
      <div class="loading-modal-template">
        <strong>${this.message}</strong>
        ${this.imageUrl ? `<img src="${this.imageUrl}">` : ''}
      </div>`;
  }
}
