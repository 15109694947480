import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { UpdateTableauReservationTag } from '@app/features/commons/tableau/+state/tableau.actions';
import { ReservationTagService } from '@app/services/reservation-tag.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';
import { IReservationTag } from '../../models/objects/reservation-tag';
import { WarningConfirmModalService } from '../../services/warning-confirm-modal.service';

@Injectable()
export class ReservationTagStoreEffects {
  constructor(
    private dataService: ReservationTagService,
    private actions$: Actions,
    private _errorHandler: ErrorHandlerService,
    private _notifications: NotificationService,
    private _translate: TranslateService,
    private warningModalService: WarningConfirmModalService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService.load(action.payload.propertiesIDS).pipe(
          map(
            ({ data }: IResponseSuccess<IReservationTag[]>) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this._errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap(
        ({ payload: { request } }: featureActions.CreateRequestAction) =>
          this.dataService.create(request).pipe(
            map(({ data }: IResponseSuccess<IReservationTag[]>) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.create_success',
                  {
                    param: this._translate.instant('reservation_tag'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.CreateSuccessAction({
                item: data[0],
              });
            }),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.CreateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({
          payload: { reservation_tag_id, force_operation },
        }: featureActions.DeleteRequestAction) =>
          this.dataService
            .delete({ reservation_tag_id, searchParams: { force_operation } })
            .pipe(
              map(({ meta: { status, confirm_required, warnings } }) => {
                if (status === 'warning' && confirm_required) {
                  this.warningModalService.open({
                    message: warnings[0],
                    labelAction: 'delete',
                    action: new featureActions.DeleteRequestAction({
                      reservation_tag_id,
                      force_operation: 1,
                    }),
                  });

                  return new featureActions.DeleteSuccessAction({
                    id: null,
                  });
                }

                this._notifications.push({
                  title: this._translate.instant('done'),
                  content: this._translate.instant(
                    'notifications.delete_success',
                    {
                      param: this._translate.instant('reservation_tag'),
                    },
                  ),
                  type: 'success',
                });

                return new featureActions.DeleteSuccessAction({
                  id: reservation_tag_id,
                });
              }),
              catchError((error) => {
                this._errorHandler.handle(error);
                return of(new featureActions.DeleteFailureAction({ error }));
              }),
            ),
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap(
        ({
          payload: { request, reload },
        }: featureActions.UpdateRequestAction) =>
          this.dataService.update(request).pipe(
            mergeMap((response: IResponseSuccess) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.update_success',
                  {
                    param: this._translate.instant('reservation_tag'),
                  },
                ),
                type: 'success',
              });
              if (reload) {
                return [
                  new featureActions.UpdateSuccessAction({
                    item: response.data[0],
                  }),
                ];
              }
              return [
                new featureActions.UpdateSuccessAction({
                  item: response.data[0],
                }),
              ];
            }),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.UpdateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  updateReservationTagRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.AttachRequestAction>(
        featureActions.ActionTypes.ATTACH_REQUEST,
      ),
      switchMap(
        ({ payload: { request } }: featureActions.AttachRequestAction) =>
          this.dataService
            .updateReservationTag(
              request.reservation_tag_id,
              request.reservation_id,
            )
            .pipe(
              mergeMap((response: IResponseSuccess) => {
                this._notifications.push({
                  title: this._translate.instant('done'),
                  content: this._translate.instant(
                    'notifications.update_success',
                    {
                      param: this._translate.instant('reservation_tag'),
                    },
                  ),
                  type: 'success',
                });
                if (request.noUpdate) {
                  return [new featureActions.AttachSuccessAction()];
                }
                if (request.tagData) {
                  return [
                    new featureActions.AttachSuccessAction(),
                    new UpdateTableauReservationTag({
                      reservation_id: request.reservation_id,
                      tag: request.tagData,
                    }),
                  ];
                }
                return [
                  new featureActions.AttachSuccessAction(),
                  new UpdateTableauReservationTag({
                    reservation_id: request.reservation_id,
                    reset: true,
                  }),
                ];
              }),
              catchError((error) => {
                this._errorHandler.handle(error);
                return of(new featureActions.AttachFailureAction({ error }));
              }),
            ),
      ),
    ),
  );
}
