export const calcDiscount = (
  originalPrice: number,
  percentageDiscount: number,
): number => {
  return (originalPrice * percentageDiscount) / 100;
};

export const removeDiscount = (
  discountedPrice: number,
  percentageDiscount: number,
): number => {
  return (discountedPrice * 100) / (100 - percentageDiscount);
};
