import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { CreditCardValidators } from 'angular-cc-library';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { creditCardValueToApi } from '../../helpers/credit-card-helper';
import { IShowCreditCard } from '../../models';
import {
  CartTypesStoreActions,
  CartTypesStoreSelectors,
} from '../../root-store/cart-types-store';
import { RootState } from '../../root-store/root-state';

@Component({
  selector: 'by-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreditCardFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CreditCardFormComponent),
      multi: true,
    },
  ],
})
export class CreditCardFormComponent
  implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {
  @Input() creditCardData: IShowCreditCard;

  creditCardTypes$ = this.store.pipe(
    select(CartTypesStoreSelectors.selectAllCartTypesItems),
  );

  CDN_URL = environment.cdnUrl;

  subs = new SubSink();

  form = this.formBuilder.group({
    credit_card_type: [null],
    credit_card_holder: ['', [Validators.required]],
    credit_card_code: ['', [Validators.minLength(3), Validators.maxLength(4)]],
    credit_card_number: [null, [CreditCardValidators.validateCCNumber]],
    credit_card_expire_date: ['', [CreditCardValidators.validateExpDate]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<RootState>,
    private cd: ChangeDetectorRef,
  ) {}

  onTouched: any = () => {};
  onChange: any = () => {};

  ngOnInit() {
    this.store.dispatch(new CartTypesStoreActions.LoadRequestAction());

    this.subs.add(
      this.creditCardTypes$.subscribe((creditCardTypes) => {
        if (
          this.form.get('credit_card_type').value !== null ||
          !creditCardTypes?.length
        ) {
          return;
        }
        this.form.patchValue({
          credit_card_type: creditCardTypes[0].id,
        });
      }),
    );
  }

  formatExpireDate(expireDate: string): string {
    if (!expireDate) {
      return '';
    }
    const creditCardExpireDate = expireDate.replace(/\s/g, '').split(/[/-]/g);

    return creditCardExpireDate[0] + ' / ' + creditCardExpireDate[1];
  }

  ngOnChanges(changes: SimpleChanges) {
    const { creditCardData } = changes;

    if (creditCardData && this.creditCardData) {
      const {
        cvc,
        holder,
        expire_date,
        number,
        cc_type_id,
      } = this.creditCardData;

      const credit_card_expire_date = this.formatExpireDate(expire_date);

      this.form.patchValue({
        credit_card_type: cc_type_id,
        credit_card_holder: holder,
        credit_card_code: cvc,
        credit_card_number: number.replace(/\d{4}(?=.)/g, '$& '),
        credit_card_expire_date,
      });
    }
  }

  valueForAPI() {
    return creditCardValueToApi(this.form.value);
  }

  writeValue(value: any): void {
    if (value) {
      const credit_card_expire_date = this.formatExpireDate(
        value.credit_card_expire_date,
      );
      this.form.patchValue({ ...value, credit_card_expire_date });
      this.cd.markForCheck();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
    this.subs.add(
      this.form.valueChanges.subscribe((value) => {
        fn(value);
      }),
    );
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return {
      'credit-card-error': {
        value: this.form.value,
      },
    };
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
