import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { last } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap } from 'rxjs/operators';
import * as txml from 'txml';

import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { observableSequence } from '../../../helpers';
import { HttpProxyService } from '../../../services';
import { FiscalPrinterLoadingService } from '../../../services/fiscal-printer-loading.service';
import {
  Command,
  FiscalPrinterCommandService,
} from '../fiscal-printer-command.service';
import { FiscalPrinterDriver } from '../fiscal-printer-driver';
import { FiscalPrinterError } from '../fiscal-printer-error';

import { getError } from './custom-q3x-fiscal-printer-errors';
import { CustomQ3XFiscalPrinterRTStatusParser } from './custom-q3x-fiscal-printer-rt-status-parser';
import { CustomQ3XFiscalPrinterStatusParser } from './custom-q3x-fiscal-printer-status-parser';
import { CustomQ3XFiscalPrinterStatusResponse } from './custom-q3x-fiscal-printer-status-response';

@Injectable({
  providedIn: 'root',
})
export class CustomQ3XFiscalPrinterDriverService
  implements FiscalPrinterDriver
{
  constructor(
    private http: HttpClient,
    private proxy: HttpProxyService,
    private dateFormatter: DateFormatterService,
    private loading: FiscalPrinterLoadingService,
    private commandService: FiscalPrinterCommandService,
  ) {}

  async getStatus(printerId: number) {
    return this.commandService
      .getStatusCommand(printerId)
      .pipe(
        switchMap((command) => {
          return this.execute(command, true).pipe(
            map((printerResponse) => {
              return new CustomQ3XFiscalPrinterStatusParser().parse(
                printerResponse,
              );
            }),
          );
        }),
      )
      .toPromise();
  }

  async getRTStatus(printerId: number) {
    return this.commandService
      .getRTStatusCommand(printerId)
      .pipe(
        switchMap((command) => {
          return this.execute(command, true).pipe(
            map((printerResponse) => {
              return new CustomQ3XFiscalPrinterRTStatusParser().parse(
                printerResponse,
              );
            }),
          );
        }),
      )
      .toPromise();
  }

  async toggleTrainingMode(printerId: number, status: boolean) {
    return this.commandService
      .getTrainingModeToggleCommand(printerId, status)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printNonTaxReceipt(printerId: number) {
    return this.commandService
      .getTestCommand(printerId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printReceipt(command: Command) {
    return this.execute(command)
      .pipe(
        map(({ response, original }) => {
          return {
            description: original,
            number: response.addInfo.fiscalDoc,
            clousure_number: +response.addInfo.nClose,
            document_date: this.dateFormatter.toServerFormat(
              new Date(response.addInfo.dateTime),
            ),
          };
        }),
        catchError((error: FiscalPrinterError) => {
          return throwError({
            status: 'ERROR',
            description: error.message,
            notification: error.notification,
          });
        }),
      )
      .toPromise();
  }

  async dailyFiscalOperation(
    printerId: number,
    operations: Array<'daily_closure' | 'daily_report'>,
  ) {
    return this.commandService
      .getDailyOperationCommand(printerId, operations)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async printReversalReceiptFiscal(printerId: number, invoiceId: number) {
    return this.commandService
      .getReversalCommand(printerId, invoiceId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(
            map(({ original, responses }) => {
              const [_, number, closure] = responses;

              return {
                description: original,
                number: number.response.addInfo.responseBuf.substring(10, 14),
                clousure_number:
                  +closure.response.addInfo.responseBuf.substring(0, 4) + 1,
              };
            }),
            catchError((error: FiscalPrinterError) => {
              return throwError({
                status: 'ERROR',
                description: error.message,
                notification: error.notification,
              });
            }),
          );
        }),
      )
      .toPromise();
  }

  async printCustomCodeXml(command: Command) {
    return this.execute(command).toPromise();
  }

  async reprint(fiscalPrinterId: number, invoiceId: number): Promise<void> {
    return this.commandService
      .getReprintCommand(fiscalPrinterId, invoiceId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async reprintLastReceipt(fiscalPrinterId: number): Promise<void> {
    return this.commandService
      .getReprintLastReceiptCommand(fiscalPrinterId)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  async dailyReprint(fiscalPrinterId: number, date: string): Promise<void> {
    return this.commandService
      .getDailyReprintCommand(fiscalPrinterId, date)
      .pipe(
        switchMap((command) => {
          return this.execute(command).pipe(mapTo(null));
        }),
      )
      .toPromise();
  }

  /**
   * @description COMMANDS EXECUTION FUNCTION
   */
  private execute(
    command: Command,
    disableLoading = false,
  ): Observable<CustomQ3XFiscalPrinterStatusResponse> {
    if (!disableLoading) {
      this.loading.start();
    }

    return this.sendByHttpOrProxy(command).pipe(
      map((responses: any[]) => {
        // Success flow

        if (!disableLoading) {
          this.loading.stop();
        }

        const parsedResponses = responses.map(({ data }) =>
          txml.simplify(txml.parse(data)),
        );

        const original = responses
          .map(({ data }) => data)
          .join('<!-- END RESPONSE -->');

        const parsed =
          last<CustomQ3XFiscalPrinterStatusResponse>(parsedResponses);

        const errorResponse = parsedResponses.find(
          (parsedResponse) =>
            parsedResponse.response._attributes.status !== '0' ||
            parsedResponse.response._attributes.success === 'false',
        );

        if (errorResponse) {
          throw new FiscalPrinterError(
            original,
            getError(errorResponse.response._attributes.status),
          );
        }

        return { ...parsed, original, responses: parsedResponses };
      }),
      catchError((error: FiscalPrinterError) => {
        // Failure flow

        if (!disableLoading) {
          this.loading.stop();
        }

        if (!(error instanceof FiscalPrinterError)) {
          error = new FiscalPrinterError(JSON.stringify(error));
        }

        return throwError(error);
      }),
    );
  }

  private sendByHttpOrProxy(command: Command) {
    const { xml, ip, username, password, use_proxy } = command;

    const headers = {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    };

    if (use_proxy) {
      return this.proxy.send({
        requests: xml.map((xmlCommand) => {
          return {
            method: 'post',
            url: 'http://' + ip,
            payload: xmlCommand,
            config: { headers },
          };
        }),
      });
    }

    return observableSequence(
      xml.map((xmlCommand) => {
        return this.http
          .post('https://' + ip, xmlCommand, {
            responseType: 'text',
            headers,
          })
          .pipe(
            map((xmlResponse) => {
              return { data: xmlResponse };
            }),
          );
      }),
    );
  }
}
