<form nz-form [formGroup]="form" [nzLayout]="formLayout">
  <by-reservation-form-guests
    #formGetter
    formControlName="guests"
    [isMobile]="isMobile"
    [accommodation]="accommodation"
    [childrenRanges]="childrenRanges"
  ></by-reservation-form-guests>

  <by-reservation-form-rateplan
    #formGetter
    formControlName="rateplan"
    [rateplans]="rateplans"
    [isMobile]="isMobile"
  ></by-reservation-form-rateplan>

  <by-reservation-form-estimate-warnings
    [estimateResponse]="estimateResponse"
  ></by-reservation-form-estimate-warnings>

  <by-reservation-form-status
    #formGetter
    formControlName="status"
    [dates]="dates"
    [isMobile]="isMobile"
  ></by-reservation-form-status>

  <ng-container
    *ngIf="form.controls.guests.valid && form.controls.rateplan.valid"
  >
    <nz-form-item
      class="by-reservation-form__item by-reservation-form__item--gray"
    >
      <nz-form-label>
        {{ 'checkin_hour' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control
        [class.by-full-width-control]="isMobile"
        class="by-reservation-form__control"
      >
        <nz-time-picker
          [nzSize]="inputSize"
          nzPlaceHolder=" "
          formControlName="checkin_hour"
          nzFormat="HH:mm"
          [nzMinuteStep]="15"
        ></nz-time-picker>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="by-reservation-form__item">
      <nz-form-label>
        {{ 'checkout_hour' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control
        [class.by-full-width-control]="isMobile"
        class="by-reservation-form__control"
      >
        <nz-time-picker
          [nzSize]="inputSize"
          nzPlaceHolder=" "
          formControlName="checkout_hour"
          nzFormat="HH:mm"
          [nzMinuteStep]="15"
        ></nz-time-picker>
      </nz-form-control>
    </nz-form-item>

    <by-reservation-form-price
      #formGetter
      formControlName="price"
      [estimateResponse]="estimateResponse"
      [proportionalDayPriceResponse]="proportionalDayPriceResponse"
      [currency]="currency"
      [priceLoading]="priceLoading"
      [isMobile]="isMobile"
      (proportionalDayPrice)="proportionalDayPrice.emit($event)"
      (discountChanged)="discountChanged.emit()"
    >
      <by-reservation-form-price-warnings
        [accommodation]="accommodation"
        [guests]="form.value.guests"
        [estimateResponse]="estimateResponse"
      ></by-reservation-form-price-warnings>
    </by-reservation-form-price>

    <by-reservation-form-deposit
      #formGetter
      formControlName="deposit"
      [currency]="currency"
      [invoiceLayouts]="invoiceLayouts"
      [defaultInvoiceLayoutId]="defaultInvoiceLayoutId"
      [nextDepositNumber]="nextDepositNumber"
      [nextDepositNumberLoading]="nextDepositNumberLoading"
      [totalPrice]="form.value.price?.price_total"
      [isMobile]="isMobile"
      (loadDepositNextNumber)="loadDepositNextNumber.emit($event)"
    ></by-reservation-form-deposit>

    <by-reservation-form-payment-method
      #formGetter
      formControlName="payment_method"
      [paymentMethods]="paymentMethods"
      [isDeposit]="!!form.value.deposit"
      [isMobile]="isMobile"
      [currency]="currency"
    ></by-reservation-form-payment-method>
  </ng-container>
</form>
