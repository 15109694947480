import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PropertyTypesService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('general/properties/types');
  }
}
