import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import {
  NewsletterMailingList,
  SaveNewsletterMailingListRequest,
} from '../models';
import { SearchParams } from '../models/objects/search-params';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class NewsletterMailingListService {
  constructor(private http: HttpClient) {}

  load(searchParams: SearchParams) {
    return this.http.get(
      `newsletters/sending_lists?${generateSearchQuery(searchParams)}`,
    );
  }

  loadDetails(newsletterMailingListID: number) {
    return this.http.get<IResponseSuccess<NewsletterMailingList[]>>(
      `newsletters/sending_lists/${newsletterMailingListID}`,
    );
  }

  create(request: SaveNewsletterMailingListRequest) {
    return this.http.post(`newsletters/sending_lists`, request);
  }

  update(
    newsletterMailingListID: number,
    request: SaveNewsletterMailingListRequest,
  ) {
    return this.http.put(
      `newsletters/sending_lists/${newsletterMailingListID}`,
      request,
    );
  }

  delete(newsletterMailingListID: number) {
    return this.http.delete(
      `newsletters/sending_lists/${newsletterMailingListID}`,
    );
  }

  duplicate(newsletterMailingListID: number) {
    return this.http.post(
      `newsletters/sending_lists/${newsletterMailingListID}/duplicate`,
      {},
    );
  }

  syncCustomers(newsletterMailingListID: number) {
    return this.http.post<IResponseSuccess<NewsletterMailingList[]>>(
      `newsletters/sending_lists/${newsletterMailingListID}/contacts/refresh`,
      {},
    );
  }
}
