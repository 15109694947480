import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { IReservationTableau } from '../features/commons/tableau/models/tableau-reservation.model';
import { TableauReservationDetails } from '../models';

@Injectable({ providedIn: 'root' })
export class TableauReservationDetailsService {
  constructor(private http: HttpClient) {}

  loadReservation(
    reservation_id: number,
    reservation_accommodation_room_id: number,
  ) {
    return this.http.get(
      `tableau/reservations/${reservation_id}/room/${reservation_accommodation_room_id}`,
    ) as Observable<IResponseSuccess<TableauReservationDetails>>;
  }
}
