import { Injectable, Injector } from '@angular/core';

import { CustomK3FiscalPrinterDriverService } from './custom-k3/custom-k3-printer-driver.service';
import { CustomKubellxFFiscalPrinterDriverService } from './custom-kubellx-f/custom-kubellx-f-printer-driver.service';
import { CustomQ3XFiscalPrinterDriverService } from './custom-q3x/custom-q3x-printer-driver.service';
import { EpsonFiscalPrinterDriverService } from './epson/epson-fiscal-printer-driver.service';
import { FiscalPrinterDriver } from './fiscal-printer-driver';
import { ItaRetailPR2FiscalPrinterDriverService } from './itaretail-pr2/itaretail-pr2-printer-driver.service';
import { ItaRetailPRXFiscalPrinterDriverService } from './itaretail-prx/itaretail-prx-printer-driver.service';
import { RchFiscalPrinterDriverService } from './rch/rch-printer-driver.service';
import { ItaRetailPR3FiscalPrinterDriverService } from './itaretail-pr3/itaretail-pr3-printer-driver.service';
import { RhinoFiscalPrinterDriverService } from './rhino/rhino-printer-driver.service';
import { EpsonFP90FiscalPrinterDriverService } from './epson-fp90/epson-fp90-fiscal-printer-driver.service';
import { ItaRetailPR4FiscalPrinterDriverService } from './itaretail-pr4/itaretail-pr4-printer-driver.service';

@Injectable({
  providedIn: 'root',
})
export class FiscalPrinterResolverService {
  constructor(private injector: Injector) {}

  getInstance(deviceModelId: number): FiscalPrinterDriver {
    const drivers = {
      1: EpsonFiscalPrinterDriverService,
      2: RchFiscalPrinterDriverService,
      3: CustomQ3XFiscalPrinterDriverService,
      4: CustomK3FiscalPrinterDriverService,
      5: CustomKubellxFFiscalPrinterDriverService,
      6: ItaRetailPRXFiscalPrinterDriverService,
      7: ItaRetailPR2FiscalPrinterDriverService,
      8: ItaRetailPR3FiscalPrinterDriverService,
      9: RhinoFiscalPrinterDriverService,
      11: EpsonFP90FiscalPrinterDriverService,
      12: ItaRetailPR4FiscalPrinterDriverService,
    };

    const driverClass = drivers[deviceModelId];

    if (!driverClass) {
      return null;
    }

    return this.injector.get(driverClass);
  }
}
