import { isValid } from 'date-fns';

import { FormItemsTypes } from '../components/dynamic-form/form-item-factory';
import {
  FormField,
  ButtonFormItemConfig,
  DateFormItemConfig,
  FormItemConfig,
  NumberFormItemConfig,
  SelectFormItemConfig,
  FormItemType,
  DateRangeFormItemConfig,
  PriceTypedFormItemConfig,
} from '../models';

export class ParseFormFieldToFormItemConfig {
  public static getFormItemConfig(
    type:
      | FormItemsTypes.password
      | FormItemsTypes.question
      | FormItemsTypes.checkbox
      | FormItemsTypes.text
      | FormItemsTypes.username
      | FormItemsTypes.city
      | FormItemsTypes.country
      | FormItemsTypes.price_typed,
    formField: FormField,
  ): FormItemConfig;

  public static getFormItemConfig(
    type: FormItemsTypes.price_typed,
    formField: FormField,
  ): PriceTypedFormItemConfig;

  public static getFormItemConfig(
    type: FormItemsTypes.number,
    formField: FormField,
  ): NumberFormItemConfig;

  public static getFormItemConfig(
    type: FormItemsTypes.select,
    formField: FormField,
  ): SelectFormItemConfig;

  public static getFormItemConfig(
    type: FormItemsTypes.date,
    formField: FormField,
  ): DateFormItemConfig;

  public static getFormItemConfig(
    type: FormItemsTypes.date_range,
    agencyField: FormField,
  ): DateRangeFormItemConfig;

  public static getFormItemConfig(
    type: FormItemsTypes.button,
    formField: FormField,
  ): ButtonFormItemConfig;

  public static getFormItemConfig(
    type: FormItemsTypes,
    formField: FormField,
  ): FormItemConfig {
    const FORM_ITEM_FACTORY_TYPE: Record<
      FormItemsTypes,
      (formField: FormField) => FormItemConfig
    > = {
      button: parseToButtonFormItem,
      select: parseToSelectFormItem,
      date: parseToDateFormItem,
      number: parseToFormItemConfig,
      password: parseToPasswordFormItem,
      question: parseToQuestionFormItem,
      text: parseToFormItemConfig,
      username: parseToFormItemConfig,
      checkbox: parseToQuestionFormItem,
      city: parseCityFormItem,
      state: parseCityFormItem,
      county: parseCityFormItem,
      country: parseToFormItemConfig,
      price_typed: parsePriceTypedFormItem,
      date_range: parseToDateRangeFormItem,
      multi_select: parseToSelectFormItem,
    };
    return FORM_ITEM_FACTORY_TYPE[type](formField);
  }
}

function parseToFormItemConfig(formField: FormField): FormItemConfig {
  return {
    key: formField.name,
    label: formField.label,
    controlType: formField.type.split(':')[0] as FormItemType,
    rules: formField.rules,
    required: formField?.rules?.split('|')?.includes('required'),
    value: formField?.defaultValue || null,
    visible: !formField.hidden,
    description: formField?.description || '',
    isAdminFormItem: false,
    order: formField?.order,
    data: formField?.data,
    infoTooltipText: formField?.infoTooltipText,
  };
}

function parseCityFormItem(formField: FormField): FormItemConfig<boolean> {
  return {
    ...parseToFormItemConfig(formField),
    value: !!formField.defaultValue,
  };
}

function parseToDateFormItem(formField: FormField): DateFormItemConfig {
  return {
    ...parseToFormItemConfig(formField),
    disabledDate: formField.disabledDate,
    allowClear: formField.allowClear,
    value: isValid(formField.defaultValue)
      ? new Date(formField.defaultValue)
      : null,
  };
}

function parseToDateRangeFormItem(agencyField: FormField): DateFormItemConfig {
  return {
    ...parseToFormItemConfig(agencyField),
    disabledDate: agencyField.disabledDate,
    allowClear: agencyField.allowClear,
    value: agencyField?.defaultValue?.map((date) =>
      isValid(new Date(date)) ? new Date(date) : null,
    ),
  };
}

function parseToQuestionFormItem(
  formField: FormField,
): FormItemConfig<boolean> {
  return {
    ...parseToFormItemConfig(formField),
    value: !!formField.defaultValue,
  };
}

function parseToButtonFormItem(formField: FormField): ButtonFormItemConfig {
  return {
    ...parseToFormItemConfig(formField),
    with_fields: formField.with_fields,
  };
}

function parseToSelectFormItem(formField: FormField): SelectFormItemConfig {
  const options = formField?.values?.map(
    ({ name: label, slug: value, ...option }) => ({
      value,
      label,
      ...option,
    }),
  );
  return { ...parseToFormItemConfig(formField), options };
}

function parseToPasswordFormItem(formField: FormField): FormItemConfig<string> {
  return { ...parseToFormItemConfig(formField), type: 'password' };
}

function parsePriceTypedFormItem(
  formField: FormField<
    any,
    {
      priceTypeFormField: FormField;
      amountPriceFormField: FormField;
      percentageFormField: FormField;
    }
  >,
): PriceTypedFormItemConfig<{
  price_type: 'amount' | 'percentage';
  price: number;
}> {
  return {
    ...parseToSelectFormItem(formField),
    type: 'price_typed',
    value: {
      price_type: 'amount',
      price: null,
    },
  };
}
