import { createFeatureSelector, createSelector } from '@ngrx/store';
import { mapValues, pick } from 'lodash';

import { ActiveModule, Module } from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getData = (state: State) => state.data;

export const selectActiveModuleState =
  createFeatureSelector<State>('activeModules');

export const selectActiveModulesError = createSelector(
  selectActiveModuleState,
  getError,
);

export const selectActiveModulesIsLoading = createSelector(
  selectActiveModuleState,
  getIsLoading,
);

export const selectActiveModulesData = createSelector(
  selectActiveModuleState,
  getData,
);

export const selectActiveModulesOfProperties = (properties: number[]) =>
  createSelector(selectActiveModulesData, (activeModules) => {
    return mapValues(activeModules, (activeModule) => {
      return {
        ...activeModule,
        properties: pick(activeModule.properties, ...properties),
      };
    });
  });

export const selectActiveModuleBySlug = (slug: Module) =>
  createSelector(
    selectActiveModulesData,
    (activeModules: Record<Module, ActiveModule>) =>
      activeModules && activeModules[slug],
  );

export const selectPropertiesIdsWithActiveModule = (slug: Module) =>
  createSelector(selectActiveModuleBySlug(slug), (activeModule) => {
    return Object.entries(activeModule?.properties ?? {})
      .filter(([_, propertyModule]) => !!propertyModule?.status)
      .map(([propertyId]) => +propertyId);
  });
