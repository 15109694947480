import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { sumBy, upperFirst } from 'lodash';

import { PaymentModalInput } from '../components/payment-form/payment-modal/input.model';
import { SdiStatus } from '../config/sdi-status.config';
import { DateFormatterService } from '../core/services/date-formatter.service';
import {
  Company,
  CreateExpenseRequest,
  Customer,
  Invoice,
  InvoiceDetails,
  InvoiceModalData,
  InvoiceTableActions,
  InvoiceTypes,
  PrintFormat,
} from '../models';
import { InvoiceDestinationPipe } from '../shared/invoice/pipes/invoice-destination.pipe';

import { ExportService } from './export.service';
import { InvoiceSendMailModalService } from './invoice-send-mail-modal.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceActionsService {
  private invoiceDestinationPipe = new InvoiceDestinationPipe();

  readonly SdiStatus = SdiStatus;

  constructor(
    private exportService: ExportService,
    private router: Router,
    private invoiceSendMailService: InvoiceSendMailModalService,
    private translateService: TranslateService,
    private dateFormatter: DateFormatterService,
  ) {}

  actionTableEvent(
    event: {
      emitKey: InvoiceTableActions;
      props: { [propertyName: string]: any };
    },
    invoiceType: InvoiceTypes,
  ) {
    const { emitKey, props } = event;
    const {
      reservation_id,
      roomreservation_id,
      number_complete,
      invoice_id,
      advance,
      status_sdi,
      property_id,
      customer_id,
    } = props;

    switch (emitKey) {
      case 'view': {
        if (reservation_id) {
          let queryParams = {
            subTab: 3,
            invoiceId: invoice_id,
          } as any;
          if (roomreservation_id) {
            queryParams = {
              ...queryParams,
              roomReservationId: reservation_id + roomreservation_id,
            };
          }
          if (advance && status_sdi === this.SdiStatus.Rejected) {
            queryParams = { subTab: 4 };
          }

          this.router.navigate(['reservation', reservation_id], {
            queryParams,
          });
        } else {
          this.router.navigate([
            `documents/${
              invoiceType === 'credit_note' ? 'credit-note' : invoiceType
            }`,
            invoice_id,
          ]);
        }
        break;
      }

      case 'download_pdfA4':
      case 'download_pdfA5': {
        this.exportService.printInvoice({
          invoice_id,
          download: 1,
        });
        break;
      }

      case 'printA4':
      case 'printA5': {
        this.exportService.printInvoice({
          invoice_id,
          download: 0,
        });
        break;
      }
      case 'send_to_email':
        const data: InvoiceModalData = {
          number_complete,
          invoice_id,
        };
        this.invoiceSendMailService.createComponentModal({
          show: true,
          data,
          propertyId: property_id,
        });
        break;
      case 'download_xml':
        const exportLink = `invoices/${invoice_id}/xml?invoice_id=${invoice_id}`;
        this.exportService.directExport(exportLink);
        break;
    }
  }

  view(invoice: Partial<Invoice>) {
    const {
      reservation_id,
      id,
      invoice_id,
      roomreservation_id,
      advance,
      status_sdi,
      type,
    } = invoice;

    const invoiceId = id || invoice_id;

    if (reservation_id) {
      let queryParams = {
        subTab: 3,
        invoiceId,
      } as any;
      if (roomreservation_id) {
        queryParams = {
          ...queryParams,
          roomReservationId: reservation_id + roomreservation_id,
        };
      }

      if (advance && status_sdi === this.SdiStatus.Rejected) {
        queryParams = { subTab: 4 };
      }

      this.router.navigate(['reservation', reservation_id], {
        queryParams,
      });
    } else {
      this.router.navigate([
        `documents/${type === 'credit_note' ? 'credit-note' : type}`,
        invoiceId,
      ]);
    }
  }

  sendToEmail(invoice: Partial<Invoice>, isReminder = false) {
    const { number_complete, invoice_id, id, customer_id } = invoice;

    const data: InvoiceModalData = {
      number_complete,
      invoice_id: invoice_id || id,
    };

    this.invoiceSendMailService.createComponentModal({
      show: true,
      data,
      isReminder,
      propertyId: invoice.property_id,
    });
  }

  getPayloadToPayInvoice(invoice: Invoice): PaymentModalInput {
    const {
      invoice_id,
      id,
      reservation_id,
      property_id,
      customer_id,
      total,
      paid,
      to_pay,
      company_id,
      invoice_layout_id,
      number_complete,
      invoice_date,
      type,
    } = invoice;

    const invoiceId = invoice_id || id;

    const recipient_business_name =
      this.invoiceDestinationPipe.transform(invoice);

    const guests = this.getGuests(
      customer_id,
      recipient_business_name,
      company_id,
    );

    const invoices = [
      {
        business_name: recipient_business_name,
        reservation_invoice_id: invoiceId,
        invoice_date,
        status,
        type,
        paid,
        total,
        to_pay,
        number_complete,
        invoice_layout_id,
        property_id,
      },
    ] as Invoice[];

    return {
      data: {
        invoiceId,
        invoices,
        data: {
          reservation_invoice_id: invoiceId,
          property_id,
          invoice_layout_id,
          amount: +to_pay,
          max_amount: +to_pay,
        },
        reservationId: reservation_id,
        propertiesIds: [property_id],
        guests: guests as [Customer[], Company[]],
      },
      options: {
        dataToFetch: {
          defaultInvoiceLayoutId: true,
          currencySymbol: true,
          invoicesLayouts: true,
          posStoreData: true,
          vatQuotes: true,
        },
        fieldToBeDisabled: {
          advance: true,
          deposit: true,
          invoiceLayout: true,
          invoice: true,
        },
      },
    };
  }

  private getGuests(
    customer_id: number,
    recipient_business_name: string,

    company_id: number,
  ) {
    let guests = [[], []];

    if (company_id) {
      guests = [
        [],
        [
          {
            id: company_id,
            name: recipient_business_name,
            customer_id,
          },
        ],
      ];
    } else if (customer_id) {
      guests = [
        [
          {
            id: customer_id,
            surname: recipient_business_name,
            name: '',
            type: 'customer',
          },
        ],
        [],
      ];
    }
    return guests;
  }

  getPayloadToRefundInvoice(invoice: Invoice): PaymentModalInput {
    const {
      invoice_id,
      id,
      reservation_id,
      property_id,
      total,
      paid,
      to_pay,
      invoice_layout_id,
      number_complete,
      invoice_date,
      customer_id,
      company_id,
      type,
    } = invoice;

    const invoiceId = invoice_id || id;

    const recipient_business_name =
      this.invoiceDestinationPipe.transform(invoice);

    const guests = this.getGuests(
      customer_id,
      recipient_business_name,
      company_id,
    );

    const invoices = [
      {
        business_name: recipient_business_name,
        reservation_invoice_id: invoiceId,
        invoice_date,
        status,
        type,
        paid,
        total,
        to_pay,
        number_complete,
        invoice_layout_id,
        property_id,
      },
    ] as Invoice[];

    return {
      data: {
        invoiceId: invoiceId,
        invoices,
        isExpense: true,
        data: {
          reservation_invoice_id: invoiceId,
          property_id,
          invoice_layout_id,
          amount: to_pay,
          max_amount: to_pay,
        },
        reservationId: reservation_id,
        propertiesIds: [property_id],
        guests: guests as [Customer[], Company[]],
      },

      options: {
        dataToFetch: {
          vatQuotes: true,
          currencySymbol: true,
          posStoreData: true,
        },
        fieldToBeDisabled: {
          paymentType: true,
          label: true,
          hideInvoiceSelection: true,
        },
      },
    } as PaymentModalInput;
  }

  getPayloadToRefundInvoiceDetails(invoice: InvoiceDetails): PaymentModalInput {
    const {
      id: invoice_id,
      reservation_id,
      property_id,
      destination,
      total,
      paid,
      status,
      layout,
      number_complete,
      invoice_date,
      type,
    } = invoice;

    const invoice_layout_id = layout.id;
    const recipient_business_name = destination.type
      ? `${destination.name} ${destination.surname}`
      : destination.name;
    const company_id = destination.type !== 'customer' ? destination.id : null;
    const customer_id = destination.type === 'customer' ? destination.id : null;
    const guests = this.getGuests(
      customer_id,
      recipient_business_name,
      company_id,
    );

    const invoices = [
      {
        business_name: recipient_business_name,
        reservation_invoice_id: invoice_id,
        invoice_date,
        status,
        type,
        paid,
        total,
        to_pay: +total - +paid,
        number_complete,
        invoice_layout_id,
        property_id,
      },
    ] as Invoice[];
    const maxAmount = +total - paid;

    return {
      data: {
        invoiceId: invoice_id,
        invoices,
        isExpense: true,
        data: {
          reservation_invoice_id: invoice_id,
          property_id,
          invoice_layout_id,
          amount: +total - +paid,
          max_amount: maxAmount,
        },
        reservationId: reservation_id,
        propertiesIds: [property_id],
        guests: guests as [Customer[], Company[]],
      },

      options: {
        dataToFetch: {
          vatQuotes: true,
          currencySymbol: true,
          posStoreData: true,
        },
        fieldToBeDisabled: {
          paymentType: true,
          label: true,
          hideInvoiceSelection: true,
        },
      },
    } as PaymentModalInput;
  }

  /**
   * @param invoice: object with fields "company" or "destination", "type", "number_complete", "invoice_date"
   */
  getSaveRefundFunction =
    (
      invoice: Invoice | InvoiceDetails | Partial<Invoice>,
      maxAmount: number,
      onSave: (expense: CreateExpenseRequest) => void,
    ) =>
    (expense: CreateExpenseRequest) => {
      const { payments } = expense;

      const totalAmount = sumBy(payments, 'amount');

      let translationKeyPrefix = 'received';

      const vatCode =
        (invoice as InvoiceDetails).destination?.vat_code ||
        (invoice as Invoice).company?.vat_code;

      if (invoice.type === 'credit_note') {
        translationKeyPrefix = 'reversal';
      }

      let labelTranslate = `${translationKeyPrefix}_invoice_expense_partial_label`;

      if (+totalAmount >= +maxAmount) {
        labelTranslate = `${translationKeyPrefix}_invoice_expense_total_label`;
      }

      const title = upperFirst(
        this.translateService.instant(labelTranslate, {
          invoiceNumber: invoice.number_complete,
          businessName: this.invoiceDestinationPipe.transform(invoice),
          vatCode,
          invoiceDate: this.dateFormatter.toUiFormat(
            new Date(invoice.invoice_date),
          ),
        }),
      );

      onSave({ ...expense, title });
    };

  navigateToList(type: InvoiceTypes, reversal: boolean) {
    if (type === 'invoice') {
      this.router.navigate(['documents', 'invoices', 'issued']);
      return;
    }

    if (type === 'credit_note' && !reversal) {
      this.router.navigate(['documents', 'invoices', 'credit-notes']);
      return;
    }

    if (type === 'receipt') {
      this.router.navigate(['documents', 'receipts', 'issued']);
      return;
    }

    if (type === 'credit_note' && reversal) {
      this.router.navigate(['documents', 'receipts', 'reversal']);
      return;
    }
  }
}
