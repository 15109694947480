import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get, upperFirst } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SdiStatus } from '../../../config/sdi-status.config';
import { IInvoiceLayout, InvoiceDetails } from '../../../models';
import { InvoiceRegisterServiceParams } from '../../../models/objects/invoice-register-service-params';
import { InvoicesLayoutsService } from '../../../services/invoices-layouts.service';
import { InvoiceChangeLayoutComponent } from '../invoice-change-layout/invoice-change-layout.component';

import { CreditNoteRegisterService } from './credit-note-register.service';
import { FiscalReceiptRegisterService } from './fiscal-receipt-register.service';
import { InvoiceRegisterService } from './invoice-register.service';
import { ReverseAutoinvoiceRegisterService } from './reverse-autoinvoice-register.service';

@Injectable({ providedIn: 'root' })
export class InvoiceRegisterResolverService {
  readonly SdiStatus = SdiStatus;

  constructor(
    private injector: Injector,
    private invoicesLayoutsService: InvoicesLayoutsService,
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {}

  register(params: InvoiceRegisterServiceParams): Observable<InvoiceDetails> {
    const { layout } = params;

    if (!layout) {
      return this.changeInvoiceLayout(params).pipe(
        switchMap((newInvoiceLayout) => {
          return this.register({
            ...params,
            layout: newInvoiceLayout,
            updatePayload: { invoice_layout_id: newInvoiceLayout.id },
          });
        }),
      );
    }

    const service = this.getServiceInstance(params);

    return service.register(params);
  }

  getServiceInstance(
    params: InvoiceRegisterServiceParams,
  ): InvoiceRegisterService {
    const { layout, registerType, invoice } = params;

    if (layout?.fiscalprinters?.length && registerType === 'receipt') {
      return this.injector.get(FiscalReceiptRegisterService);
    }

    if (
      registerType === 'credit_note' &&
      invoice?.status_sdi !== this.SdiStatus.Rejected
    ) {
      return this.injector.get(CreditNoteRegisterService);
    }

    if (
      registerType === 'reverse_auto_invoice' &&
      invoice?.status_sdi !== this.SdiStatus.Rejected
    ) {
      return this.injector.get(ReverseAutoinvoiceRegisterService);
    }

    return this.injector.get(InvoiceRegisterService);
  }

  private changeInvoiceLayout(
    params: InvoiceRegisterServiceParams,
  ): Observable<IInvoiceLayout> {
    const { properties, invoice, registerService } = params;

    return this.invoicesLayoutsService
      .load({
        property_id: properties || [invoice.property_id],
        fp_module: 0,
        invoice_module: 0,
      })
      .pipe(
        switchMap(({ data: invoicesLayouts }) => {
          const modal = this.modalService.create<
            InvoiceChangeLayoutComponent,
            Partial<InvoiceChangeLayoutComponent>
          >({
            nzTitle: upperFirst(
              this.translate.instant('change_invoice_layout'),
            ),
            nzContent: InvoiceChangeLayoutComponent,
            nzData: {
              invoicesLayouts,
            },
            nzFooter: [
              {
                label: upperFirst(this.translate.instant('save')),
                type: 'primary',
                disabled: ({ selectedInvoiceLayoutId }) =>
                  selectedInvoiceLayoutId === get(invoice, 'layout.id'),
                onClick: ({ selectedInvoiceLayoutId }) => {
                  modal.close(
                    invoicesLayouts.find(
                      ({ id }) => +id === selectedInvoiceLayoutId,
                    ),
                  );
                },
              },
            ],
          });

          return modal.afterClose.asObservable().pipe(
            switchMap((newInvoiceLayout) => {
              if (!newInvoiceLayout) {
                throw new Error('New layout not selected by user');
              }

              return of(newInvoiceLayout);
            }),
          );
        }),
      );
  }
}
