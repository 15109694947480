import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { flatten, omit } from 'lodash';
import { SubSink } from 'subsink';

import { CompaniesLookupComponent } from '../../../../components/companies-lookup/companies-lookup.component';
import { ICurrency } from '../../../../core/models/api/generics/currencies/currency.model';
import { INewReservation } from '../../../../core/models/api/reservation/reservaion.model';
import { removeNullishValues } from '../../../../helpers';
import {
  AutomaticPaymentSummary,
  CompanyLookup,
  Dealer,
  EstimateRateplan,
  FormGetter,
  IEmailTemplateCustom,
  Language,
  ProportionalDayPrice,
  ReservationFormAccommodation,
  ReservationFrom,
} from '../../../../models';
import { EstimateRequest } from '../../../../models/requests/estimate-request';
import { EstimateResponse } from '../../../../models/responses/estimate.response';
import {
  Customer,
  ReservationFormCustomerComponent,
} from '../reservation-form-customer/reservation-form-customer.component';
import { Price } from '../reservation-form-price/reservation-form-price.component';

const CUSTOMER_DEALER_ID = 1;

@Component({
  selector: 'by-reservation-form-second-step',
  templateUrl: './reservation-form-second-step.component.html',
  styleUrls: ['./reservation-form-second-step.component.scss'],
})
export class ReservationFormSecondStepComponent
  implements OnInit, OnDestroy, FormGetter
{
  @ViewChildren('formGetter')
  formGetters: QueryList<FormGetter>;

  @ViewChild('companiesLookup', { read: CompaniesLookupComponent })
  companiesLookupComponent: CompaniesLookupComponent;

  @ViewChild(ReservationFormCustomerComponent)
  customerFormComponent: ReservationFormCustomerComponent;

  @Input()
  reservationFroms: ReservationFrom[];

  @Input()
  dealers: Dealer[];

  @Input()
  languages: Language[];

  @Input()
  firstStepRequest: Partial<INewReservation>;

  @Input()
  estimateResponse: EstimateResponse;

  @Input()
  proportionalDayPriceResponse: ProportionalDayPrice;

  @Input()
  priceLoading: boolean;

  @Input()
  currency: ICurrency;

  @Input()
  estimateRateplans: EstimateRateplan[];

  @Input()
  accommodation: ReservationFormAccommodation;

  @Input()
  customEmailTeplates: IEmailTemplateCustom[];

  @Input()
  automaticPaymentSummary: AutomaticPaymentSummary[];

  @Input()
  automaticPaymentModule: boolean;

  @Input()
  automaticPaymentsLoading: boolean;

  @Input() isMobile = false;

  @Output()
  proportionalDayPrice = new EventEmitter<ProportionalDayPrice>();

  @Output()
  estimate = new EventEmitter<Partial<EstimateRequest>>();

  @Output()
  companySelected = new EventEmitter<CompanyLookup>();

  @Output()
  priceChanged = new EventEmitter<Price>();

  @Output()
  discountChanged = new EventEmitter();

  @Output()
  addAddons = new EventEmitter();

  @Output()
  showEmailPreview = new EventEmitter();

  @Output()
  createCompany = new EventEmitter();

  form = this.formBuilder.group({
    reservation_from_id: [1],
    dealer_id: [CUSTOMER_DEALER_ID],
    company_id: [null],
    customer: [null],
    internal_remarks: [null],
    notification: [null],
    payment_policy_id: [null],
  });

  selectedCompany: CompanyLookup;

  selectedCustomer: Customer;

  private subs = new SubSink();

  readonly CUSTOMER_DEALER_ID = CUSTOMER_DEALER_ID;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.subs.add(
      this.companyIdControl.valueChanges.subscribe((company_id) => {
        this.estimate.emit({ company_id });
      }),
    );

    this.subs.add(
      this.customerControl.valueChanges.subscribe((customer) => {
        this.selectedCustomer = customer;
      }),
    );

    this.subs.add(
      this.dealerIdControl.valueChanges.subscribe((dealerId) => {
        this.selectedCompany = null;
        this.selectedCustomer = null;

        this.companiesLookupComponent.resetOptions();

        if (this.companyIdControl.value) {
          this.companyIdControl.setValue(null);
        }

        this.customerFormComponent.onRemoveSelectedCustomer();

        this.companyIdControl.clearValidators();

        if (dealerId !== CUSTOMER_DEALER_ID) {
          this.companyIdControl.setValidators([Validators.required]);
        }

        this.companyIdControl.updateValueAndValidity({ emitEvent: false });
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getForms() {
    return [
      this.form,
      ...flatten(this.formGetters.map((formGetter) => formGetter.getForms())),
    ];
  }

  getEstimateRequest() {
    const { company_id } = this.form.value;
    return { company_id };
  }

  onCompanySelected(company: CompanyLookup) {
    this.selectedCompany = company;
    this.companySelected.emit(company);
  }

  patchCompany(company: CompanyLookup) {
    this.selectedCompany = company;

    this.form.patchValue(
      removeNullishValues({
        company_id: company.id,
        dealer_id: company.dealer_id,
      }),
      { emitEvent: false },
    );
  }

  get value() {
    let { customer } = this.form.value;

    // Company was selected but customer is empty
    if (!Object.keys(omit(customer, 'language_id')).length) {
      customer = { customer_id: this.selectedCompany?.customer_id };
    }

    return { ...this.form.value, customer };
  }

  get companyIdControl() {
    return this.form.get('company_id');
  }

  get dealerIdControl() {
    return this.form.get('dealer_id');
  }

  get customerControl() {
    return this.form.get('customer');
  }

  get companyFormItemIsVisible() {
    const { dealer_id } = this.form.value;
    return dealer_id !== CUSTOMER_DEALER_ID;
  }

  get customerFormIsVisible() {
    return (
      this.dealerIdControl.value === CUSTOMER_DEALER_ID ||
      this.companyIdControl.value
    );
  }

  get formLayout(): 'horizontal' | 'vertical' {
    return this.isMobile ? 'vertical' : 'horizontal';
  }

  get inputSize(): 'small' | 'default' {
    return this.isMobile ? 'default' : 'small';
  }
}
