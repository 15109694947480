import { Action, createAction, props } from '@ngrx/store';

import {
  Event,
  Floors,
  ISplitReservation,
  LoadReservationAccommodationDetailsRequest,
  SetReservationCheckinAction,
  SetReservationTagActionRequest,
  SetTableauNumber,
  SplitReservationAccommodationResponse,
  TableauAvailabilities,
  TableauClosureRequest,
  TableauClousures,
  TableauHousekeeperInformation,
  TableauHousekeeperInformations,
  TableauMapping,
  TableauMoveReservationRequest,
  TableauNote,
  TableauNotes,
  TableauQuote,
  TableauQuotes,
  TableauReservation,
  TableauReservationAccommodationDetails,
  TableauReservationDetailsModalData,
  TableauReservations,
  TableauReservationTag,
  TableauRoom,
  TableauRow,
  TableauSwappedReservation,
  TableauViewMode,
  TableauViewOptions,
} from '../../models';
import { LoadEventRequest } from '../../models/requests/load-event-request';

import { StateResources } from './state';
import { IReservationTag } from '../../models/objects/reservation-tag';

export const buildRowsRequest = createAction(
  '[Tableau 2] Build Rows Request',
  props<{ skipLoading?: boolean }>(),
);
export const buildRowsSuccess = createAction(
  '[Tableau 2] Build Rows Success',
  props<{ rows: TableauRow[] }>(),
);

export const setRoomDetailsRequest = createAction(
  '[Tableau 2] Set Room Details Request',
  props<SetTableauNumber>(),
);
export const setRoomDetailsSuccess = createAction(
  '[Tableau 2] Set Room Details Success',
  props<SetTableauNumber>(),
);
export const setRoomDetailsFailure = createAction(
  '[Tableau 2] Set Room Details Failure',
  props<{ error: any }>(),
);

export const setRoomCleanStatusRequest = createAction(
  '[Tableau 2] Set Room Clean Status Request',
  props<{ row: TableauRow<TableauRoom>; isClean: boolean }>(),
);
export const setRoomCleanStatusSuccess = createAction(
  '[Tableau 2] Set Room Clean Status Success',
  props<{
    affected: {
      [tableauNumberId: number]: Partial<TableauHousekeeperInformation>;
    };
  }>(),
);
export const setRoomCleanStatusFailure = createAction(
  '[Tableau 2] Set Room Clean Status Failure',
  props<{ error: any }>(),
);

export const setCheckinStatus = createAction(
  '[Tableau 2] Set Checkin Status',
  props<SetReservationCheckinAction>(),
);

export const setReservationTagRequest = createAction(
  '[Tableau 2] Attach Tag Request',
  props<SetReservationTagActionRequest>(),
);
export const setReservationTagSuccess = createAction(
  '[Tableau 2] Attach Tag Success',
  props<{ tag: TableauReservationTag; reservation_id: number }>(),
);
export const setReservationTagFailure = createAction(
  '[Tableau 2] Attach Tag Failure',
  props<{ error: any }>(),
);

export const loadMappingRequest = createAction(
  '[Tableau 2] Load Mapping Request',
  props<{ propertyIds: number[] }>(),
);
export const loadMappingSuccess = createAction(
  '[Tableau 2] Load Mapping Success',
  props<{ mapping: TableauMapping }>(),
);
export const loadMappingFailure = createAction(
  '[Tableau 2] Load Mapping Failure',
  props<{ error: any }>(),
);

export const loadFloorsRequest = createAction(
  '[Tableau 2] Load Floors Request',
  props<{ propertyIds: number[] }>(),
);
export const loadFloorsSuccess = createAction(
  '[Tableau 2] Load Floors Success',
  props<{ floors: Floors }>(),
);
export const loadFloorsFailure = createAction(
  '[Tableau 2] Load Floors Failure',
  props<{ error: any }>(),
);

export const loadEventsRequest = createAction(
  '[Tableau 2] Load Events Request',
  props<{
    request: LoadEventRequest;
  }>(),
);
export const loadEventsSuccess = createAction(
  '[Tableau 2] Load Events Success',
  props<{ events: Event[] }>(),
);
export const loadEventsFailure = createAction(
  '[Tableau 2] Load Events Failure',
  props<{ error: any }>(),
);

export const loadReservationsRequest = createAction(
  '[Tableau 2] Load Reservations Request',
  props<{ propertyIds: number[]; dateFrom: string; dateTo: string }>(),
);
export const loadReservationsSuccess = createAction(
  '[Tableau 2] Load Reservations Success',
  props<{ reservations: TableauReservations }>(),
);
export const loadReservationsFailure = createAction(
  '[Tableau 2] Load Reservations Failure',
  props<{ error: any }>(),
);

export const loadHousekeeperInformationsRequest = createAction(
  '[Tableau 2] Load Housekeeper Informations Request',
  props<{ propertyIds: number[]; referenceDate: string }>(),
);
export const loadHousekeeperInformationsSuccess = createAction(
  '[Tableau 2] Load Housekeeper Informations Success',
  props<{ housekeeperInformations: TableauHousekeeperInformations }>(),
);
export const loadHousekeeperInformationsFailure = createAction(
  '[Tableau 2] Load Housekeeper Informations Failure',
  props<{ error: any }>(),
);

export const loadQuotesRequest = createAction(
  '[Tableau 2] Load Quotes Request',
  props<{ propertyIds: number[]; dateFrom: string; dateTo: string }>(),
);
export const loadQuotesSuccess = createAction(
  '[Tableau 2] Load Quotes Success',
  props<{ quotes: TableauQuotes }>(),
);
export const loadQuotesFailure = createAction(
  '[Tableau 2] Load Quotes Failure',
  props<{ error: any }>(),
);

export const loadAvailabilitiesRequest = createAction(
  '[Tableau 2] Load Availabilities Request',
  props<{ propertyIds: number[]; dateFrom: string; dateTo: string }>(),
);
export const loadAvailabilitiesSuccess = createAction(
  '[Tableau 2] Load Availabilities Success',
  props<{ availabilities: TableauAvailabilities }>(),
);
export const loadAvailabilitiesFailure = createAction(
  '[Tableau 2] Load Availabilities Failure',
  props<{ error: any }>(),
);

export const loadClousuresRequest = createAction(
  '[Tableau 2] Load Clousures Request',
  props<{ propertyIds: number[]; dateFrom: string; dateTo: string }>(),
);
export const loadClousuresSuccess = createAction(
  '[Tableau 2] Load Clousures Success',
  props<{ clousures: TableauClousures }>(),
);
export const loadClousuresFailure = createAction(
  '[Tableau 2] Load Clousures Failure',
  props<{ error: any }>(),
);

export const loadNotesRequest = createAction(
  '[Tableau 2] Load Notes Request',
  props<{ propertyIds: number[]; dateFrom: string; dateTo: string }>(),
);
export const loadNotesSuccess = createAction(
  '[Tableau 2] Load Notes Success',
  props<{ notes: TableauNotes }>(),
);
export const loadNotesFailure = createAction(
  '[Tableau 2] Load Notes Failure',
  props<{ error: any }>(),
);

export const createNoteRequest = createAction(
  '[Tableau 2] Create Note Request',
  props<{
    note: Partial<TableauNote>;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const createNoteSuccess = createAction(
  '[Tableau 2] Create Note Success',
  props<{ note: TableauNote }>(),
);
export const createNoteFailure = createAction(
  '[Tableau 2] Create Note Failure',
  props<{ error: any }>(),
);

export const updateNoteRequest = createAction(
  '[Tableau 2] Update Note Request',
  props<{
    note: Partial<TableauNote>;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const updateNoteSuccess = createAction(
  '[Tableau 2] Update Note Success',
  props<{ note: TableauNote }>(),
);
export const updateNoteFailure = createAction(
  '[Tableau 2] Update Note Failure',
  props<{ error: any }>(),
);

export const deleteNoteRequest = createAction(
  '[Tableau 2] Delete Note Request',
  props<{
    noteId: number;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const deleteNoteSuccess = createAction(
  '[Tableau 2] Delete Note Success',
  props<{ noteId: number }>(),
);
export const deleteNoteFailure = createAction(
  '[Tableau 2] Delete Note Failure',
  props<{ error: any }>(),
);

export const setClosureRequest = createAction(
  '[Tableau 2] Set Closure Request',
  props<{
    request: TableauClosureRequest;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const setClosureSuccess = createAction(
  '[Tableau 2] Set Closure Success',
);
export const setClosureFailure = createAction(
  '[Tableau 2] Set Closure Failure',
  props<{ error: any }>(),
);

export const moveReservationRequest = createAction(
  '[Tableau 2] Move Reservation Request',
  props<TableauMoveReservationRequest>(),
);
export const moveReservationWarning = createAction(
  '[Tableau 2] Move Reservation Warning',
  props<{
    warnings: string[];
    splitData: SplitReservationAccommodationResponse;
    splitRequest: ISplitReservation;
    sourceAction: Action & TableauMoveReservationRequest;
  }>(),
);
export const moveReservationSuccess = createAction(
  '[Tableau 2] Move Reservation Success',
  props<{ reservation: TableauReservation }>(),
);
export const moveReservationFailure = createAction(
  '[Tableau 2] Move Reservation Failure',
  props<{ error: any }>(),
);

export const moveQuoteRequest = createAction(
  '[Tableau 2] Move Quote Request',
  props<{
    quote: TableauQuote;
    sourceRow: TableauRow<TableauRoom>;
    destinationRow: TableauRow<TableauRoom>;
  }>(),
);
export const moveQuoteSuccess = createAction('[Tableau 2] Move Quote Success');
export const moveQuoteFailure = createAction(
  '[Tableau 2] Move Quote Failure',
  props<{ error: any }>(),
);

export const loadReservationAccommodationDetailsRequest = createAction(
  '[Tableau 2] Load Reservation Accommodation Details Request',
  props<{
    request: LoadReservationAccommodationDetailsRequest;
    onSuccess?: (details: TableauReservationAccommodationDetails) => void;
  }>(),
);
export const loadReservationAccommodationDetailsSuccess = createAction(
  '[Tableau 2] Load Reservation Accommodation Details Success',
  props<{
    request: LoadReservationAccommodationDetailsRequest;
    details: TableauReservationAccommodationDetails;
  }>(),
);
export const loadReservationAccommodationDetailsFailure = createAction(
  '[Tableau 2] Load Reservation Accommodation Details Failure',
  props<{ error: any }>(),
);

export const addReservationToSwap = createAction(
  '[Tableau 2] Add Reservation To Swap',
  props<{
    swappedReservation: TableauSwappedReservation;
  }>(),
);
export const removeReservationFromSwap = createAction(
  '[Tableau 2] Remove Reservation From Swap',
  props<{
    swappedReservation: TableauSwappedReservation;
  }>(),
);
export const moveReservationFromSwapRequest = createAction(
  '[Tableau 2] Move Reservation From Swap Request',
  props<TableauMoveReservationRequest>(),
);
export const resetSwap = createAction('[Tableau 2] Reset Swap');

export const setPropertiesOrder = createAction(
  '[Tableau 2] Set Properties Order',
  props<{ sortedProperties: number[] }>(),
);

export const setLoadedProperties = createAction(
  '[Tableau 2] Set Loaded Properties',
  props<{ loadedProperties: number[] }>(),
);

export const resetLoadedProperties = createAction(
  '[Tableau 2] Reset Loaded Properties',
);

export const resetLoadedResources = createAction(
  '[Tableau 2] Reset Loaded Resources',
  props<{ resources?: Array<keyof StateResources> }>(),
);

export const splitReservation = createAction(
  '[Tableau 2] Split Reservation',
  props<{ rowId: string; splittedReservation: TableauReservation[] }>(),
);

export const setPeriod = createAction(
  '[Tableau 2] Set Period',
  props<{ start: Date }>(),
);

export const setViewMode = createAction(
  '[Tableau 2] Set View Mode',
  props<{ viewMode: TableauViewMode }>(),
);

export const setViewOptions = createAction(
  '[Tableau 2] Set View Options',
  props<{ viewOptions: Partial<TableauViewOptions> }>(),
);

export const zoomIn = createAction('[Tableau 2] Zoom In');
export const zoomOut = createAction('[Tableau 2] Zoom Out');
export const setZoom = createAction(
  '[Tableau 2] Set Zoom',
  props<{ zoom: number }>(),
);

export const readPreferences = createAction('[Tableau 2] Read Preferences');
export const setPreferences = createAction(
  '[Tableau 2] Set Preferences',
  props<{ preferences: any; tags?: IReservationTag[] }>(),
);

export const toggleAccommodationExpand = createAction(
  '[Tableau 2] Toggle Accommodation Expand',
  props<{ accommodationId: number; expand: boolean }>(),
);

export const toggleAllAccommodationsExpand = createAction(
  '[Tableau 2] Toggle All Accommodations Expand',
  props<{ expand: boolean }>(),
);

export const openQuoteDetailsModal = createAction(
  '[Tableau 2] Open Quote Details Modal',
  props<{
    quote: TableauQuote;
    reservationsColors: Record<string, string>;
    row: TableauRow<TableauRoom>;
    userCanWrite: boolean;
  }>(),
);

export const openBedTypesModal = createAction(
  '[Tableau 2] Open Bed Types Modal',
  props<{
    property_id: number;
    accommodationId: number;
  }>(),
);

export const openReservationDetailsModal = createAction(
  '[Tableau 2] Open Reservation Details Modal',
  props<TableauReservationDetailsModalData>(),
);

export const openMaintenanceModal = createAction(
  '[Tableau 2] Open Maintenance Modal',
  props<{ row: TableauRow<TableauRoom>; canWrite: boolean }>(),
);

export const setRoomMaintenanceStatus = createAction(
  '[Tableau 2] Set Room Maintenance Status',
  props<{ row: TableauRow<TableauRoom>; under_maintenance: boolean }>(),
);

export const openActionsModal = createAction(
  '[Tableau 2] Open Actions Modal',
  props<{ row: TableauRow<TableauRoom>; dateFrom: Date; dateTo: Date }>(),
);

export const openNoteModal = createAction(
  '[Tableau 2] Open Note Modal',
  props<{ row: TableauRow<TableauRoom>; note: TableauNote }>(),
);

export const toggleSplitMode = createAction(
  '[Tableau 2] Toggle Split Mode',
  props<{ splitMode: boolean }>(),
);

export const setFilteredAccommodations = createAction(
  '[Tableau 2] Set Filtered Accommodations',
  props<{ filteredAccommodations: number[] }>(),
);

export const exportRequest = createAction('[Tableau 2] Export Request');
export const exportSuccess = createAction('[Tableau 2] Export Success');

export const forceRender = createAction('[Tableau 2] Force Render');

export const resetState = createAction('[Tableau 2] Reset State');
