import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  ApiPartner,
  ApiPartnerContact,
  ApiPartnerContactType,
  ApiPartnerSearchRequest,
  ApiPartnerService,
  ApiPartnerTag,
} from '../models';
import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class ApiPartnersService {
  constructor(private http: HttpClient) {}

  load(searchParams: ApiPartnerSearchRequest) {
    return this.http.get(`api_partners?${generateSearchQuery(searchParams)}`);
  }

  loadDetails(apiPartnerId: number) {
    return this.http.get(
      `api_partner/${apiPartnerId}?format=object`,
    ) as Observable<IResponseSuccess<ApiPartner[]>>;
  }

  loadContacts(apiPartnerId: number) {
    return this.http.get(
      `admin/api_partners/${apiPartnerId}/contacts`,
    ) as Observable<IResponseSuccess<ApiPartnerContact[]>>;
  }

  createApiPartner(apiPartner: ApiPartner) {
    return this.http.post<IResponseSuccess<ApiPartner>>(
      `admin/api_partners`,
      apiPartner,
    );
  }

  editApiPartner(apiPartner: ApiPartner) {
    return this.http.put<IResponseSuccess<ApiPartner>>(
      `admin/api_partners/${apiPartner.id}`,
      apiPartner,
    );
  }

  deleteApiPartner(apiPartnerId: number) {
    return this.http.delete(`admin/api_partners/${apiPartnerId}`);
  }

  deleteApiPartnerContact(apiPartnerId: number, contactId: number) {
    return this.http.delete(
      `admin/api_partners/${apiPartnerId}/contacts/${contactId}`,
    );
  }

  loadServices() {
    return this.http.get(`admin/api_partners/services`) as Observable<
      IResponseSuccess<ApiPartnerService[]>
    >;
  }

  loadContactTypes() {
    return this.http.get(`admin/api_partners/contacts_types`) as Observable<
      IResponseSuccess<ApiPartnerContactType[]>
    >;
  }

  loadTags() {
    return this.http.get(`admin/api_partners/tags`) as Observable<
      IResponseSuccess<ApiPartnerTag[]>
    >;
  }

  lookup(searchParams: SearchParams) {
    return this.http.get(
      `api_partners/lookup?${generateSearchQuery(searchParams)}`,
    );
  }
}
