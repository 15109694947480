export interface State {
  isLoading?: boolean;
  error?: any;
  preview: { body: string; subject?: string };
}

export const initialState: State = {
  isLoading: false,
  error: null,
  preview: null,
};
