import {
  CurrentRTState,
  FiscalPrinterRTStatus,
  RTInactivePeriod,
} from '../fiscal-printer-rt-status';

import { RchFiscalPrinterStatusResponse } from './rch-fiscal-printer-status-response';

const currentRTStateMapping = (code: string): CurrentRTState => {
  switch (code) {
    case '58':
      return 'missing_certificated';
    case '71':
      return 'not_registered';
    case '73':
      return 'not_active';
    default:
      return 'active';
  }
};

const inactivePeriodMapping = (code: string): RTInactivePeriod => {
  if (code === '0') {
    return 'false';
  }

  if (code === '93') {
    return 'waiting_for_daily_closure';
  }

  return 'indeterminate_state';
};

export class RchFiscalPrinterRTStatusParser {
  parse({
    Service: { Request },
  }: RchFiscalPrinterStatusResponse): FiscalPrinterRTStatus {
    return {
      isActive: Request.printerError === '0',
      inactivePeriod: inactivePeriodMapping(Request.errorCode),
      currentRTState: currentRTStateMapping(Request.errorCode),
      dayOpened: Request.errorCode !== '75',
    };
  }
}
