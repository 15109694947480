import { get, isEqual, isNil, omit, omitBy } from 'lodash';

import {
  GuestStatusInfo,
  IIstatCardGuest,
  IstatAccommodation,
  IstatGuest,
  IstatReservation,
  IstatReservationResponseAccommodation,
  Places,
  PlacesInputIds,
} from '../models';

import { getNullableDate } from './nullable-date';

const buildIstatGuests = (
  guests: IIstatCardGuest[],
  guestsStatusInfo: GuestStatusInfo[],
): IstatGuest[] => {
  return guests.map((guest) => {
    const {
      guest_type_id,
      id: res_acc_room_guest_id,
      send_to_external,
      customer_id,
      customer,
      arrival_date,
      departure_date,
      city_tax_exemption_id,
      deleted_on_portal,
    } = guest;

    const {
      birthday_date,
      citizenship_country_name,
      citizenship_country_id,
      details,
      language_id,
      sex,
      type,
      surname,
      name,
      documents,
      birth_country_name,
      birth_state_name,
      birth_county_name,
      birth_city_name,
      birth_country_id,
      birth_state_id,
      birth_county_id,
      birth_city_id,
    } = customer;

    const guestStatusInfo = guestsStatusInfo.find(
      (g) => g.id === res_acc_room_guest_id,
    );

    const domicile = details.find(
      (detail) => detail.customer_address_type_id === 1,
    );

    const document = get(documents || {}, '[0]', {});

    const citizenshipPlaces = {
      countryId: citizenship_country_id,
      country: citizenship_country_name,
    };

    const birthPlaces = {
      countryId: birth_country_id,
      countyId: birth_county_id,
      stateId: birth_state_id,
      cityId: birth_city_id,
      country: birth_country_name,
      county: birth_county_name,
      state: birth_state_name,
      city: birth_city_name,
    };

    const domicilePlaces = {
      countryId: get(domicile, 'country_id'),
      countyId: get(domicile, 'county_id'),
      stateId: get(domicile, 'state_id'),
      cityId: get(domicile, 'city_id'),
      country: get(domicile, 'country_name'),
      county: get(domicile, 'county_name'),
      state: get(domicile, 'state_name'),
      city: get(domicile, 'city_name'),
    };

    const documentPlaces = {
      countryId: get(document, 'country_id', null),
      countyId: get(document, 'county_id', null),
      stateId: get(document, 'state_id', null),
      cityId: get(document, 'city_id', null),
      country: get(document, 'country_name', null),
      county: get(document, 'county_name', null),
      state: get(document, 'state_name', null),
      city: get(document, 'city_name', null),
    };

    const placeNamekeys: (keyof Places)[] = [
      'country',
      'county',
      'state',
      'city',
    ];

    const domicilePlaceIds = omit(
      domicilePlaces,
      placeNamekeys,
    ) as PlacesInputIds;

    const birthPlaceIds = omit(birthPlaces, placeNamekeys) as PlacesInputIds;

    const isDomicilePlacesEmpty = !Object.keys(omitBy(domicilePlaceIds, isNil))
      .length;

    return {
      birthday_date,
      citizenshipPlaces,
      birthPlaces,
      documentPlaces,
      document_type_id: get(document, 'customer_document_type_id'),
      document_number: get(document, 'document_number'),
      expire_date: getNullableDate(get(document, 'expire_date')),
      domicilePlaces,
      guest_type_id,
      language_id,
      name,
      surname,
      res_acc_room_guest_id,
      send_to_external,
      sex,
      type,
      isDifferentDomicile: isDomicilePlacesEmpty
        ? true
        : !isEqual(domicilePlaceIds, birthPlaceIds),
      checkin: get(guestStatusInfo, 'checkin'),
      reportedStatus: get(guestStatusInfo, 'reportedStatus'),
      customer_id,
      arrival_date,
      departure_date,
      city_tax_exemption_id,
      deleted_on_portal,
    };
  });
};

export const buildIstatAccommodation = (
  accommodations: IstatReservationResponseAccommodation[],
  guestsStatusInfo: GuestStatusInfo[],
): IstatAccommodation[] => {
  return accommodations.map((accommodation) => {
    const {
      reservation_accommodation_room_id,
      roomreservation_id,
      send_to_external,
      guests,
      name,
      accommodation_tableau_label,
      children_number,
      adults_number,
      arrival_date,
      departure_date,
      id,
      alloggiatiweb_group_id,
    } = accommodation;

    const newGuests = buildIstatGuests(guests, guestsStatusInfo);
    return {
      id,
      allGuestsSent: !newGuests.length
        ? false
        : newGuests.every((guest) => guest.reportedStatus === 'sync'),
      atLeastOneGuestSent: !newGuests.length
        ? false
        : newGuests.some(
            (guest) =>
              guest.reportedStatus === 'sync' ||
              guest.reportedStatus === 'needs_update',
          ),
      totalGuests: children_number + adults_number,
      accommodation_tableau_label,
      name,
      reservation_accommodation_room_id,
      alloggiatiweb_group_id,
      roomreservation_id,
      send_to_external,
      arrival_date,
      departure_date,
      guests: newGuests,
    };
  });
};

export const checkGuestsSendStatus = (
  reservation: IstatReservation[],
): {
  allGuestsSent: boolean;
  atLeastOneGuestNeedsUpdate: boolean;
} => {
  let allGuestsSent = true;
  let atLeastOneGuestNeedsUpdate = false;

  reservation.forEach(({ accommodations }) => {
    accommodations
      .filter(({ send_to_external }) => send_to_external)
      .forEach(({ guests }) => {
        if (guests.length) {
          guests
            .filter(({ send_to_external }) => send_to_external)
            .forEach((guest) => {
              const { reportedStatus } = guest;
              if (reportedStatus === 'needs_update') {
                atLeastOneGuestNeedsUpdate = true;
              }
              if (reportedStatus !== 'sync') {
                allGuestsSent = false;
              }
            });
        } else {
          atLeastOneGuestNeedsUpdate = false;
          allGuestsSent = false;
        }
      });
  });
  return {
    allGuestsSent,
    atLeastOneGuestNeedsUpdate,
  };
};
