import { Injectable } from '@angular/core';
import { isNil } from 'lodash';

import { TableauRowIndexData, TableauRowIndexDataLabel } from '../models';

interface IndexOject {
  p?: number; // Property
  a?: number; // Accommodation
  f?: string | number; // Floor
  r?: string | number; // Room
  l?: TableauRowIndexDataLabel; // Label
  i?: number; // Index
  s?: number; // Span Length
}

@Injectable({ providedIn: 'root' })
export class TableauRowIndexService {
  encode(data: TableauRowIndexData): string {
    const {
      property_id,
      accommodation_id,
      floor_id,
      room_id,
      index,
      label,
      spanLength,
    } = data;

    const indexObject: IndexOject = {};

    if (property_id) {
      indexObject.p = property_id;
    }

    if (accommodation_id) {
      indexObject.a = accommodation_id;
    }

    if (!isNil(floor_id)) {
      indexObject.f = floor_id;
    }

    if (room_id) {
      indexObject.r = room_id;
    }

    if (label) {
      indexObject.l = label;
    }

    indexObject.i = index || 0;

    indexObject.s = spanLength || 1;

    return Object.entries(indexObject)
      .map(([key, value]) => `${key}${value}`)
      .join('-');
  }

  decode(index: string): TableauRowIndexData {
    const { s, p, a, r, l, i, f }: IndexOject = index
      .split('-')
      .reduce((indexObject, pairs) => {
        const [key, ...value] = pairs;

        let joinedValue: string | number = value.join('');

        if (!Number.isNaN(+joinedValue)) {
          joinedValue = +joinedValue;
        }

        indexObject = { ...indexObject, [key]: joinedValue };

        return indexObject;
      }, {});

    return {
      spanLength: s,
      property_id: p,
      accommodation_id: a,
      floor_id: f,
      room_id: r,
      label: l,
      index: i,
    };
  }
}
