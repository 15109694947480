import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';

import { DateFormatterService } from '../core/services/date-formatter.service';
import { TokenService } from '../core/services/token.service';

@Injectable({
  providedIn: 'root',
})
export class RemoteCheckAccommodationStatusService {
  constructor(private http: HttpClient) {}

  check(propertyId: number, channelId: number, accommodationChannelId: number) {
    return this.http.get(
      `channelmanager/remoteCheckAccommodationStatus/${channelId}/${propertyId}/${accommodationChannelId}`,
    );
  }
}
