import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.estimateRequest, (state) => ({
    ...state,
    estimating: true,
    error: null,
  })),
  on(fromActions.estimateSuccess, (state, { response }) => ({
    ...state,
    estimate: response,
    estimating: false,
  })),
  on(fromActions.estimateFailure, (state, { error }) => ({
    ...state,
    estimating: false,
    error,
  })),

  on(fromActions.loadRateplansRequest, (state) => ({
    ...state,
    fetchingRateplans: true,
    error: null,
  })),
  on(fromActions.loadRateplansSuccess, (state, { rateplans }) => ({
    ...state,
    rateplans,
    fetchingRateplans: false,
  })),
  on(fromActions.loadRateplansFailure, (state, { error }) => ({
    ...state,
    fetchingRateplans: false,
    error,
  })),

  on(fromActions.resetState, () => ({
    ...fromState.initialState,
  })),
);

export function estimateReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
