import { Component, Input, OnInit, inject } from '@angular/core';
import moment from 'moment';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

import { datesFromRange } from '../../../../core/helpers/dates-from-range';
import { DateFormatterService } from '../../../../core/services/date-formatter.service';
import { TableauActionParams, TableauActionsMethods } from '../../../../models';
import { ModalDragService } from '../../../../services/modal-drag.service';

@Component({
  selector: 'by-tableau-choose-action-modal',
  templateUrl: './tableau-choose-action-modal.component.html',
  styleUrls: ['./tableau-choose-action-modal.component.scss'],
  providers: [ModalDragService],
})
export class TableauChooseActionModalComponent implements OnInit {
  readonly nzModalData: Partial<TableauChooseActionModalComponent> =
    inject(NZ_MODAL_DATA);

  @Input()
  params: TableauActionParams = this.nzModalData.params;

  @Input()
  actionsMethods: TableauActionsMethods = this.nzModalData.actionsMethods;

  enabledActions: Record<keyof TableauActionsMethods, boolean>;

  constructor(
    private modalRef: NzModalRef,
    private modalDragService: ModalDragService,
    private dateFormatter: DateFormatterService,
  ) {
    this.modalDragService.handle(this.modalRef);
  }

  ngOnInit() {
    this.setEnabledActions();
  }

  onTriggerAction(action: keyof TableauActionsMethods) {
    const method = this.actionsMethods[action];

    method(this.params);

    this.modalRef.close();
  }

  private setEnabledActions() {
    this.enabledActions = {
      createReservation: this.createReservationActionIsEnabled(),
      openNoteForm: true,
      closePeriod: true,
      openPeriod: true,
    };
  }

  private createReservationActionIsEnabled(): boolean {
    const { row, dateFrom, dateTo } = this.params;
    const { closures, accommodation_details } = row.data;

    if (accommodation_details?.is_virtual) {
      return false;
    }

    if (!closures) {
      return true;
    }

    const dates = datesFromRange(
      dateFrom,
      moment(dateTo).add(1, 'day').toDate(),
      false,
    );

    return dates.every((date) => {
      return !closures[this.dateFormatter.toServerFormat(date)];
    });
  }
}
