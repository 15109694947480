import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  ICoreState,
  selectAllProperties,
} from '../../core/+state/core.reducer';
import { CompaniesService } from '../../services';

import * as featureActions from './actions';
import { WarningConfirmModalService } from '../../services/warning-confirm-modal.service';
import { IResponseSuccess } from '../../core/models/response-sucess.model';

@Injectable()
export class CompaniesStoreEffects {
  constructor(
    private dataService: CompaniesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
    private coreStore: Store<ICoreState>,
    private warningModalService: WarningConfirmModalService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        const { properties, params } = action.payload;
        return this.dataService.load(properties || [], params || {}).pipe(
          map(
            ({ data, meta }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
                pagination: meta.pagination,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadDetailsRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadDetailsRequestAction>(
        featureActions.ActionTypes.LOAD_DETAILS_REQUEST,
      ),
      switchMap((action: featureActions.LoadDetailsRequestAction) => {
        const { companyId } = action.payload;
        return this.dataService.loadDetails(companyId).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadDetailsSuccessAction({
                companyDetails: data[0],
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadDetailsFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  searchRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SearchRequestAction>(
        featureActions.ActionTypes.SEARCH_REQUEST,
      ),
      withLatestFrom(this.coreStore.pipe(select(selectAllProperties))),
      exhaustMap(
        ([action, allProperties]: [
          featureActions.SearchRequestAction,
          any[],
        ]) => {
          const { properties, value, dealer_id, only_suppliers } =
            action.payload;
          if (!value || value === '') {
            return of(
              new featureActions.SearchSuccessAction({
                items: [],
              }),
            );
          }
          return this.dataService
            .search(
              properties || allProperties.map(({ id }) => id) || [],
              value,
              { dealer_id, only_suppliers },
            )
            .pipe(
              map(
                ({ data }: any) =>
                  new featureActions.SearchSuccessAction({
                    items: data,
                  }),
              ),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(new featureActions.SearchFailureAction({ error }));
              }),
            );
        },
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({ payload: { companyId } }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(companyId).pipe(
            map(
              () =>
                new featureActions.DeleteSuccessAction({
                  companyId,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      withLatestFrom(this.coreStore.pipe(select(selectAllProperties))),
      switchMap(
        ([
          {
            payload: {
              request,
              onComplete,
              onFailure,
              disableNotification,
              force_operation,
            },
          },
          allProperties,
        ]: [featureActions.CreateRequestAction, any[]]) => {
          return this.dataService
            .create({
              property_id: allProperties.map(({ id }) => id),
              ...request,
              force_operation,
            })
            .pipe(
              map(({ data, meta }: any) => {
                if (!meta.confirm_required) {
                  if (onComplete) {
                    onComplete(data[0]);
                  }
                  if (!disableNotification) {
                    this.notifications.push({
                      title: this.translate.instant('done'),
                      content: this.translate.instant(
                        'notifications.create_success',
                        {
                          param: this.translate.instant('company'),
                        },
                      ),
                      type: 'success',
                    });
                  }

                  return new featureActions.CreateSuccessAction({
                    item: data[0],
                  });
                }

                this.warningModalService.open({
                  message: meta.warnings.toString(),
                  action: new featureActions.CreateRequestAction({
                    request,
                    onComplete,
                    onFailure,
                    disableNotification,
                    force_operation: true,
                  }),
                });

                return new featureActions.CreateFailureAction({
                  error: null,
                });
              }),
              catchError((error) => {
                this.errorHandler.handle(error);

                if (onFailure) {
                  onFailure();
                }

                return of(new featureActions.CreateFailureAction({ error }));
              }),
            );
        },
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap(
        ({
          payload: { request, onSuccess, force_operation },
        }: featureActions.UpdateRequestAction) => {
          return this.dataService.update({ ...request, force_operation }).pipe(
            map(({ data, meta }) => {
              if (!meta.confirm_required) {
                this.notifications.push({
                  title: this.translate.instant('done'),
                  content: this.translate.instant(
                    'notifications.update_success',
                    {
                      param: this.translate.instant('company'),
                    },
                  ),
                  type: 'success',
                });

                if (onSuccess) {
                  onSuccess();
                }

                return new featureActions.UpdateSuccessAction({
                  item: data[0],
                });
              }

              this.warningModalService.open({
                message: meta.warnings.toString(),
                action: new featureActions.UpdateRequestAction({
                  request,
                  onSuccess,
                  force_operation: true,
                }),
              });

              return new featureActions.UpdateFailureAction({
                error: null,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.UpdateFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  upload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.UPLOAD_REQUEST),
      switchMap(
        ({
          payload: { properties, file, selectedDateFormat, onSuccess, onError },
        }: featureActions.UploadRequestAction) => {
          return this.dataService
            .upload(properties, file, selectedDateFormat)
            .pipe(
              map(() => {
                onSuccess();
                this.notifications.success('upload');
                return new featureActions.UploadSuccessAction();
              }),
              catchError((error) => {
                onError();
                return of(
                  new featureActions.UploadFailureAction({
                    error,
                    uploadErrors: error.error.meta.errors,
                  }),
                );
              }),
            );
        },
      ),
    ),
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.EXPORT_FILE_REQUEST),
      switchMap(({ payload }: featureActions.ExportFileRequest) => {
        return this.dataService.exportFile(payload).pipe(
          map(({ data: { export_ids } }) => {
            return new featureActions.ExportFileSucces({
              exportFileId: export_ids[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.ExportFileError());
          }),
        );
      }),
    ),
  );
}
