import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlaceVatQuoteService {
  constructor(private http: HttpClient) {}

  load(place_id: number, pagination: boolean) {
    return this.http.get(
      `general/vat_quotes/search/?place_id=${place_id}&no_pagination=${
        pagination ? 0 : 1
      }`,
    );
  }
}
