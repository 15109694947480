import { get } from 'lodash';

export const generateValidationObj = (
  validationString: string,
): { name: string; value: any }[] => {
  const rules = validationString.split('|').map((rule) => {
    const subRules = rule.split(':');
    return {
      name: subRules[0],
      value: get(subRules, '[1]'),
    };
  });
  return rules;
};
