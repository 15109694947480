import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  constructor(private http: HttpClient) {}

  load(value: string, now: string, propertiesIds: number[]) {
    return this.http.get(
      `user/me/search?value=${value}&now=${now}&${generateParamArray(
        'property_id',
        propertiesIds,
      )}`,
    );
  }
}
