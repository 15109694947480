export function getBase64(
  file: Blob | File,
  callback: (img: string) => void,
): void {
  const reader = new FileReader();

  reader.addEventListener(
    'load',
    () =>
      callback(
        reader.result
          .toString()
          .replace(
            'application/octet-stream',
            file.type || 'application/octet-stream',
          ),
      ),
    {
      once: true,
      passive: true,
      capture: true,
    },
  );

  reader.readAsDataURL(file);
}
