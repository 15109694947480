import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DateFormatterService } from '../../../core/services/date-formatter.service';
import { HttpProxyService } from '../../../services';
import { FiscalPrinterLoadingService } from '../../../services/fiscal-printer-loading.service';
import { CustomQ3XFiscalPrinterDriverService } from '../custom-q3x/custom-q3x-printer-driver.service';
import { FiscalPrinterCommandService } from '../fiscal-printer-command.service';

@Injectable({
  providedIn: 'root',
})
export class ItaRetailPR3FiscalPrinterDriverService extends CustomQ3XFiscalPrinterDriverService {
  constructor(
    http: HttpClient,
    proxy: HttpProxyService,
    dateFormatter: DateFormatterService,
    loading: FiscalPrinterLoadingService,
    commandService: FiscalPrinterCommandService,
  ) {
    super(http, proxy, dateFormatter, loading, commandService);
  }
}
