import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { InvoiceModalData } from '../models';
import { ModalInvoiceSendEmailComponent } from '../shared/modal-invoice-send-email/modal-invoice-send-email.component';

@Injectable({
  providedIn: 'root',
})
export class InvoiceSendMailModalService {
  constructor(private modalService: NzModalService) {}

  createComponentModal(modalData: {
    show: boolean;
    data: InvoiceModalData;
    isReminder?: boolean;
    propertyId: number;
  }): void {
    const { data, show, propertyId, isReminder } = modalData;

    this.modalService.create<
      ModalInvoiceSendEmailComponent,
      Partial<ModalInvoiceSendEmailComponent>
    >({
      nzContent: ModalInvoiceSendEmailComponent,
      nzData: {
        show,
        data,
        isReminder: !!isReminder,
        propertyId,
      },
      nzFooter: null,
    });
  }
}
