import { Injectable, InjectionToken, inject } from '@angular/core';
import { BroadcastService } from './broadcast.service';
import { Observable } from 'rxjs';
import { BroadcastMethod, BroadcastLogoutMessageType } from '../models';
import { BroadcastMessage } from '../models/objects/broadcast-message';

export const TOKEN = new InjectionToken<BroadcastService>(
  'logout-broadcast-token',
  {
    providedIn: 'root',
    factory: () => {
      return new BroadcastService('logout-broadcast');
    },
  },
);

@Injectable({
  providedIn: 'root',
})
export class LogoutBroadcastService implements BroadcastMethod {
  broadcast = inject(TOKEN);

  publish(data: { type: 'logout-in-all-tabs' }): void;
  publish(data: {
    type: 'remove-impersonate-in-all-tabs';
    payload: { redirect: string };
  }): void;
  publish(data: BroadcastMessage<BroadcastLogoutMessageType>): void {
    this.broadcast.publish(data);
  }

  messagesOfType(
    type: 'remove-impersonate-in-all-tabs',
  ): Observable<
    BroadcastMessage<BroadcastLogoutMessageType, { redirect: string }>
  >;
  messagesOfType(
    type: 'logout-in-all-tabs',
  ): Observable<
    BroadcastMessage<BroadcastLogoutMessageType, { redirect: string }>
  >;
  messagesOfType(
    type: BroadcastLogoutMessageType,
  ): Observable<BroadcastMessage<BroadcastLogoutMessageType>> {
    return this.broadcast.messagesOfType(type);
  }
}
