import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getSummary = (state: State) => state.automaticPaymentsSummary;

export const selectState = createFeatureSelector<State>(
  'automaticPaymentsSummary',
);

export const selectAutomaticPaymentsSummary = createSelector(
  selectState,
  getSummary,
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);
