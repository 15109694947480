import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';

export interface Command {
  xml: string[];
  ip: string;
  use_proxy?: boolean;
  username?: string;
  password?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FiscalPrinterCommandService {
  constructor(private http: HttpClient) {}

  getStatusCommand(receipt_module_id: number) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/status', { receipt_module_id }),
    );
  }

  getRTStatusCommand(receipt_module_id: number) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/rtstatus', { receipt_module_id }),
    );
  }

  getResetCommand(receipt_module_id: number) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/reset', { receipt_module_id }),
    );
  }

  getReprintCommand(receipt_module_id: number, invoice_id: number) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/print_again', {
        invoice_id,
        receipt_module_id,
      }),
    );
  }

  getReprintLastReceiptCommand(receipt_module_id: number) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/last_receipt_reprint', {
        receipt_module_id,
      }),
    );
  }

  getDailyReprintCommand(receipt_module_id: number, reference_date: string) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/daily_reprint', {
        receipt_module_id,
        reference_date,
      }),
    );
  }

  getTestCommand(receipt_module_id: number) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/test_receipt', {
        receipt_module_id,
      }),
    );
  }

  getReversalCommand(receipt_module_id: number, invoice_id: number) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/receipt_reversal', {
        receipt_module_id,
        invoice_id,
      }),
    );
  }

  getDailyOperationCommand(
    receipt_module_id: number,
    operations: Array<'daily_closure' | 'daily_report'>,
  ) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/fiscal_report', {
        receipt_module_id,
        operations,
      }),
    );
  }

  getTrainingModeToggleCommand(receipt_module_id: number, active: boolean) {
    return this.parseToCommand(
      this.http.post('fiscalprinter/training', {
        receipt_module_id,
        active,
      }),
    );
  }

  private parseToCommand(response$: Observable<Object>): Observable<Command> {
    return response$.pipe(
      map((response: IResponseSuccess<Command>) => response.data),
    );
  }
}
