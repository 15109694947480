import { get, mapValues, sortBy } from 'lodash';

import { ProductionStatsEntity, StatsResponse } from '../models';

export function buildDailyClosureProductionState<T>(
  state: T,
  stats: StatsResponse,
): T {
  return {
    ...state,
    rows: sortBy(buildDailyClosureRow(stats), 'property_id'),
    totals: buildDailyClosureTotals(stats),
  };
}

function buildDailyClosureRow(stats: StatsResponse): ProductionStatsEntity[] {
  const { values, dictionary } = stats;

  return Object.keys(dictionary).map((key) => {
    const findValue = values.find((value) => key === value.key);

    if (findValue) {
      return {
        name: dictionary[findValue.key].label,
        property_name: get(dictionary, [findValue.key, 'parent_label']),
        property_id: get(dictionary, [findValue.key, 'property_id']),
        ...mapValues(findValue.collected, (val) => val.current as number),
      };
    }

    return {
      name: dictionary[key].label,
      property_name: get(dictionary, [key, 'parent_label']),
      property_id: get(dictionary, [key, 'property_id']),
    };
  });
}

function buildDailyClosureTotals(stats: StatsResponse): ProductionStatsEntity {
  const { totals } = stats;

  const data = mapValues(totals.collected, (val) => val.current as number);
  return {
    name: 'totals',
    ...data,
  };
}
