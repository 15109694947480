<form
  class="credit-card-form"
  nz-form
  [formGroup]="form"
  [nzLayout]="'vertical'"
>
  <nz-form-item class="credit-card-form__form-item">
    <nz-form-control>
      <nz-select
        class="credit-card-form__select"
        nzBorderless
        formControlName="credit_card_type"
      >
        <nz-option
          nzCustomContent
          *ngFor="let creditCardType of creditCardTypes$ | async"
          [nzLabel]="creditCardType.name"
          [nzValue]="creditCardType.id"
        >
          <i class="fab fa-cc-{{ creditCardType.name | lowercase }}"></i>
          {{ creditCardType.name }}
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="credit-card-form__form-item">
    <nz-form-control>
      <input
        class="credit-card-form__input"
        [placeholder]="'name_and_surname' | translate | capitalize"
        nz-input
        formControlName="credit_card_holder"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="credit-card-form__form-item">
    <nz-form-control nzHasFeedback>
      <input
        class="credit-card-form__input"
        id="cc-number"
        placeholder="•••• •••• •••• ••••"
        formControlName="credit_card_number"
        type="tel"
        nz-input
        ccNumber
      />
    </nz-form-control>
  </nz-form-item>
  <div nz-row [nzGutter]="20">
    <div nz-col [nzMd]="8">
      <nz-form-item class="credit-card-form__form-item">
        <nz-form-label>
          {{ 'expiration' | translate | capitalize }}
        </nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            class="credit-card-form__input"
            nz-input
            type="tel"
            maxlength="7"
            placeholder="•• / ••"
            formControlName="credit_card_expire_date"
            ccExp
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzMd]="6">
      <nz-form-item class="credit-card-form__form-item">
        <nz-form-label>
          {{ 'credit_card_code' | translate | uppercase }}
        </nz-form-label>
        <nz-form-control nzHasFeedback>
          <input
            class="credit-card-form__input"
            nz-input
            type="tel"
            maxlength="4"
            placeholder="***"
            formControlName="credit_card_code"
            ccCVC
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzMd]="10" style="text-align: right">
      <img
        *ngIf="
          form.value.credit_card_type === 1 || form.value.credit_card_type === 2
        "
        width="60px"
        height="60px"
        src="{{ CDN_URL }}images/credit-cards/{{
          form.value.credit_card_type
        }}/logo.svg"
      />
    </div>
  </div>
</form>

<nz-alert
  *ngIf="creditCardData?.cc_activation_date"
  nzType="info"
  class="by-mt-2"
  [nzMessage]="'virtual_card' | translate | upperfirst"
  [nzDescription]="virtualCard"
  nzShowIcon
>
</nz-alert>
<ng-template #virtualCard>
  <div
    nz-row
    class="by-mt-5 by-mb-5"
    nzAlign="middle"
    nzJustify="space-between"
  >
    <div nz-col>
      <strong>{{ 'cc_activation_date' | translate | upperfirst }}:</strong>
      {{ creditCardData?.cc_activation_date | formatDate }}
    </div>

    <div nz-col>
      <strong>{{ 'credit_card_expire_date' | translate | upperfirst }}:</strong>
      {{ creditCardData?.vcc_expiration_date | formatDate }}
    </div>
    <div nz-col>
      <strong>{{ 'cc_current_balance' | translate | upperfirst }}:</strong>

      <by-currency-format
        [currencyValue]="creditCardData?.cc_current_balance"
      ></by-currency-format>
    </div>
  </div>
</ng-template>
