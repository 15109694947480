import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewsletterMailingListContact, SearchParams } from '../models';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { removeAllNullishValues } from '../helpers';

@Injectable({ providedIn: 'root' })
export class NewsletterContactsStatsService {
  constructor(private http: HttpClient) {}

  load(request: { newsletterID: number; searchParams: SearchParams }) {
    const { newsletterID, searchParams } = request;

    return this.http.get<IResponseSuccess<NewsletterMailingListContact[]>>(
      `newsletters/${newsletterID}/contacts?${generateSearchQuery(
        removeAllNullishValues(searchParams),
      )}`,
    );
  }
}
