import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BookingVacationPropertyCheckinMethod,
  BookingStreamVariationNameType,
  BookingVacationPropertyCheckinResponse,
  LoadBookingVacationRentalPhotoResponse,
  SetBookingPropertyCheckinMethodsRequeste,
} from '../models';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { removeNullishValues } from '../helpers';
import { BookingComPropertyResponse } from '../models/objects/booking-com-property';
import { map } from 'rxjs';
import { omit } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class BookingVacationPropertyCheckinService {
  constructor(private http: HttpClient) {}

  loadPropertyCheckinMethods(data: {
    property_id: number;
    property_xml_id: string;
    type: BookingStreamVariationNameType;
  }) {
    const { property_xml_id, property_id, type } = data;

    return this.http.get<
      IResponseSuccess<{
        checkin_methods: BookingVacationPropertyCheckinMethod[];
      }>
    >(
      `channelmanager/booking_onboard/checkin_methods?${generateSearchQuery(
        removeNullishValues({ property_xml_id, property_id, type }),
      )}`,
    );
  }

  setPropertyCheckinMethods(data: {
    property_id: number;
    property_xml_id: string;
    methods: Partial<SetBookingPropertyCheckinMethodsRequeste>;
  }) {
    const { property_xml_id, property_id, methods } = data;

    return this.http.post<
      IResponseSuccess<BookingVacationPropertyCheckinResponse>
    >(
      `channelmanager/booking_onboard/${property_id}/vacation/property/checkin?${generateSearchQuery(
        removeNullishValues({ property_xml_id, property_id }),
      )}`,
      methods,
    );
  }

  loadPropertyCheckin(data: { property_id: number; property_xml_id: string }) {
    const { property_xml_id, property_id } = data;
    return this.http.get<
      IResponseSuccess<BookingVacationPropertyCheckinResponse>
    >(
      `channelmanager/booking_onboard/${property_id}/vacation/property/checkin?${generateSearchQuery(
        removeNullishValues({ property_xml_id }),
      )}`,
    );
  }

  loadProperty(property_id: number, property_xml_id?: string) {
    return this.http.get<IResponseSuccess<BookingComPropertyResponse>>(
      `channelmanager/booking_onboard/${property_id}/property_details?${generateSearchQuery(
        removeNullishValues({ property_xml_id }),
      )}`,
    );
  }

  loadCheckinPhotos(data: { property_id: number; property_xml_id: string }) {
    const { property_xml_id, property_id } = data;
    return this.http
      .get<
        IResponseSuccess<Record<number, LoadBookingVacationRentalPhotoResponse>>
      >(
        `channelmanager/booking_onboard/${property_id}/vacation/property/checkin/photos?${generateSearchQuery(
          removeNullishValues({ property_xml_id }),
        )}`,
      )
      .pipe(map((response) => omit(response.data, 'errors', 'warnings')));
  }

  uploadCheckinPhotos(data: {
    property_id: number;
    property_xml_id: string;
    photo: File;
  }) {
    const { property_xml_id, property_id } = data;

    const formData = new FormData();
    formData.append('photo', data.photo);

    return this.http
      .post<IResponseSuccess<{ photo_ids: number[] }>>(
        `channelmanager/booking_onboard/${property_id}/vacation/property/checkin/photos?${generateSearchQuery(
          removeNullishValues({ property_xml_id }),
        )}`,
        formData,
      )
      .pipe(
        map(({ data }) => {
          return data?.photo_ids;
        }),
      );
  }

  deleteCheckinPhotos(data: {
    property_id: number;
    property_xml_id: string;
    photoID: number;
  }) {
    const { property_xml_id, property_id, photoID } = data;

    return this.http.delete<
      IResponseSuccess<Record<number, LoadBookingVacationRentalPhotoResponse>>
    >(
      `channelmanager/booking_onboard/${property_id}/vacation/property/checkin/photos/${photoID}?${generateSearchQuery(
        removeNullishValues({ property_xml_id }),
      )}`,
    );
  }
}
