import { Action, createReducer, on } from '@ngrx/store';
import { isNil } from 'lodash';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadSuccess,
    (state, { total, addonId, unit_price, quantity, billId }) => {
      //  const id = isNil(billId) ? addonId : billId;
      const estymateType = isNil(billId) ? 'totalsAddons' : 'totalsBills';
      const id = isNil(billId) ? addonId : billId;
      return {
        ...state,
        [estymateType]: {
          // ...state[estymateType],
          [id]: {
            total,
            unit_price,
            quantity,
          },
        },
        isLoading: false,
        error: null,
      };
    },
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, (state) => ({
    ...state,
    isLoading: false,
    error: null,
    totalsBills: {},
    totalsAddons: {},
  })),
);

export function estimateAddonPriceReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
