import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NotificationsSettings } from '../models';

@Injectable({
  providedIn: 'root',
})
export class NotificationsSettingService {
  constructor(private http: HttpClient) {}

  load(propertyGroupId: number) {
    return this.http.get(`property/group/${propertyGroupId}/notifications`);
  }

  update(
    propertyGroupId: number,
    notificationsSettings: NotificationsSettings,
  ) {
    return this.http.put(
      `property/group/${propertyGroupId}/notifications`,
      notificationsSettings,
    );
  }
}
