import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AddonsEstimateTotals } from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getTotalsAddons = (state: State): AddonsEstimateTotals =>
  state.totalsAddons;

export const getTotalsBills = (state: State): AddonsEstimateTotals =>
  state.totalsBills;

export const selectState = createFeatureSelector<State>('estimateAddonPrice');

export const selectError = createSelector(
  selectState,
  getError,
);

export const selectIsLoading = createSelector(
  selectState,
  getIsLoading,
);

export const selectTotalAddons = createSelector(
  selectState,
  getTotalsAddons,
);

export const selectTotalBills = createSelector(
  selectState,
  getTotalsBills,
);
