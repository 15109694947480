<div
  nz-row
  nzType="flex"
  nzJustify="space-between"
  nzAlign="middle"
  [nzGutter]="20"
>
  <div nz-col nzSm="24" nzXs="24" nz-form nzLayout="vertical">
    <nz-alert
      *ngIf="layoutWasDeleted"
      class="by-mb-2"
      nzType="warning"
      [nzMessage]="'attenction' | translate | upperfirst"
      [nzDescription]="
        'invoice_layout_deleted_warning' | translate | upperfirst
      "
      nzShowIcon
    ></nz-alert>

    <nz-form-item>
      <nz-form-label>
        {{ 'invoice_layout' | translate | capitalize }}
        <i
          class="fas fa-info-circle by-ml-10"
          nz-popover
          [nzPopoverTitle]="null"
          [nzPopoverContent]="informationPopover"
        ></i>
        <ng-template #informationPopover>
          <div>
            <i class="fas fa-plug by-mr-5 color--azure"></i>
            {{ 'electronic_invoicing' | translate | upperfirst }}
          </div>
          <div>
            <i class="fas fa-cash-register by-mr-5 color--azure"></i>
            {{ 'associated_fiscal_printers' | translate | upperfirst }}
          </div>
        </ng-template>
      </nz-form-label>
      <nz-form-control>
        <nz-select
          [(ngModel)]="selectedInvoiceLayoutId"
          class="by-w-100"
          [nzPlaceHolder]="'invoice_layout' | translate | capitalize"
        >
          <nz-option
            *ngFor="let layout of invoicesLayouts"
            [nzLabel]="layout.name"
            [nzValue]="layout.id"
            nzCustomContent
          >
            {{ layout.name }}
            <i
              class="fas fa-plug by-ml-5 color--azure"
              *ngIf="layout?.invoice_module?.status"
            ></i>
            <i
              class="fas fa-cash-register by-ml-5 color--azure"
              *ngIf="layout?.fiscalprinters?.length"
            ></i>
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
