import { isDate } from 'lodash';
import { iterateJson } from './iterate-json';
import { dateToServerFormat } from './date-to-server-format';
import { isIsoString } from './is-iso-string';

/**
 * @description
 * Given a Json parses it and replaces the isoString values with Date values
 */
export class JsonDateParser {
  /**
   * @param value to parse
   * @returns Json with iso string date fields replaced with type Date
   */
  static toDate = (value: any) =>
    iterateJson(value, (value: string) => {
      if (isIsoString(value)) {
        return new Date(value);
      }
      return value;
    });

  /**
   * @param value to parse
   * @returns Json with Date fields replaced with type iso string
   */
  static toServerFormat = <T = any>(value: any): T => {
    return iterateJson(value, (valueToParse: any) => {
      if (isDate(valueToParse)) {
        return dateToServerFormat(valueToParse);
      }
      return valueToParse;
    });
  };

  /**
   * @param value to parse
   * @returns Json with Date fields replaced with type iso string
   */
  static toServerFormatWithTime = (value: any) => {
    return iterateJson(value, (valueToParse: any) => {
      if (isDate(valueToParse)) {
        return dateToServerFormat(valueToParse, 'yyyy-MM-dd HH:mm:ss');
      }
      return valueToParse;
    });
  };
}
