import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  intersection,
  intersectionBy,
  intersectionWith,
  upperFirst,
} from 'lodash';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { merge } from 'rxjs';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PrintReceiptModalComponent } from '../../../components/print-receipt-modal/print-receipt-modal.component';
import { DateFormatterService } from '../../../core/services/date-formatter.service';
import {
  FiscalPrinter,
  IInvoiceLayout,
  InvoiceCashOut,
  InvoiceDetails,
  RegisterInvoice,
} from '../../../models';
import { InvoiceRegisterServiceParams } from '../../../models/objects/invoice-register-service-params';
import { InvoicesLayoutsService } from '../../../services/invoices-layouts.service';
import { WarningConfirmModalService } from '../../../services/warning-confirm-modal.service';
import { NotificationService } from '../../../ui/services/notification.service';
import { InvoiceRegisterAsReceiptModalComponent } from '../invoice-register-as-receipt-modal/invoice-register-as-receipt-modal.component';
import {
  InvoiceRegisterModalComponent,
  InvoiceRegisterModalOutput,
} from '../invoice-register-modal/invoice-register-modal.component';

interface ChecksParams {
  invoice: InvoiceDetails;
  layout: IInvoiceLayout;
  invoiceCashOut: InvoiceCashOut;
}

const getUsedAdvances = (params: ChecksParams): InvoiceDetails[] => {
  const { invoice, invoiceCashOut } = params;

  return intersectionBy(
    invoice.reservation_advances,
    invoiceCashOut.reservation_advances,
    'id',
  );
};

@Injectable({ providedIn: 'root' })
export class InvoiceRegisterService {
  protected registerUtilsByType = {
    invoice: {
      class: InvoiceRegisterModalComponent,
      title: 'generate_invoice',
      checks: this.invoiceRegisterChecks,
      disabled: (component: InvoiceRegisterModalComponent) =>
        !component.invoiceLayoutSectionalControlIsValid ||
        component.form.invalid,
    },
    credit_note: {
      class: InvoiceRegisterModalComponent,
      title: 'generate_invoice',
      checks: this.invoiceRegisterChecks,
      disabled: (component: InvoiceRegisterModalComponent) =>
        !component.invoiceLayoutSectionalControlIsValid ||
        component.form.invalid,
    },
    reverse_auto_invoice: {
      class: InvoiceRegisterModalComponent,
      title: 'generate_invoice',
      checks: this.invoiceRegisterChecks,
      disabled: (component: InvoiceRegisterModalComponent) =>
        !component.invoiceLayoutSectionalControlIsValid ||
        component.form.invalid,
    },
    receipt: {
      class: InvoiceRegisterAsReceiptModalComponent,
      title: 'register_receipt',
      checks: this.receiptRegisterChecks,
      disabled: (component: InvoiceRegisterAsReceiptModalComponent) =>
        component.form.invalid,
    },
  };

  constructor(
    protected modalService: NzModalService,
    protected message: NzMessageService,
    protected translate: TranslateService,
    protected notification: NotificationService,
    protected warningConfirmModalService: WarningConfirmModalService,
    protected dateFormatter: DateFormatterService,
    protected invoicesLayoutsService: InvoicesLayoutsService,
  ) {}

  /**
   * @description flusso di registrazione standard:
   * - apertura modale per inserimento data e numero fattura
   * - chiamata di register
   * - gestione di eventuali warnings
   */
  register(params: InvoiceRegisterServiceParams): Observable<InvoiceDetails> {
    const {
      invoice,
      layout,
      invoiceCashOut,
      properties,
      registerType,
      registerService,
      registerExtraPayload,
    } = params;

    const utils =
      this.registerUtilsByType[
        registerType as 'invoice' | 'receipt' | 'credit_note'
      ];

    const sdi_send = Number(
      !!layout.invoice_module?.status &&
        (registerType === 'invoice' || registerType === 'credit_note'),
    );

    const warning = utils.checks({ invoice, invoiceCashOut, layout }) as string;

    if (warning) {
      this.notification.warning(warning);
      return throwError('Registration warnings');
    }

    const propertiesIds = this.getPropertiesLayoutIntersection(
      properties || [invoice.property_id],
      layout,
    );

    const flow$ = new Subject<InvoiceRegisterModalOutput>();

    let registerLoading = false;

    const modal: NzModalRef = this.modalService.create({
      nzTitle: upperFirst(this.translate.instant(utils.title)),
      nzContent: utils.class,
      nzData: { invoice, propertiesIds, layout },
      nzClosable: false,
      nzMaskClosable: false,
      nzFooter: [
        {
          label: upperFirst(this.translate.instant('cancel')),
          type: 'default',
          disabled: () => registerLoading,
          onClick: () => modal.close(),
        },
        {
          label: upperFirst(
            this.translate.instant(
              sdi_send ? 'register_and_send_to_sdi' : 'save',
            ),
          ),
          type: 'primary',
          loading: () => registerLoading,
          disabled: utils.disabled,
          onClick: (component) => flow$.next(component.value),
        },
      ],
    });

    return merge(flow$.asObservable(), modal.afterClose.asObservable()).pipe(
      switchMap((userInput) => {
        // La modale di registrazione è stata chiusa dall'utente
        if (!userInput) {
          modal.close();
          throw new Error('Invoice register modal closed by user');
        }

        const registerPayload: RegisterInvoice =
          this.dateFormatter.formatObjectDates({
            ...registerExtraPayload,
            ...invoiceCashOut,
            ...userInput,
            sdi_send,
            invoice_id: invoice.id,
            type: registerType,
          });

        registerLoading = true;
        return this.updateInvoice(params).pipe(
          switchMap(() =>
            // Primo tentativo di register
            registerService.register(registerPayload).pipe(
              switchMap(({ data, meta }) => {
                // Presenza di warnings e di richiesta conferma
                if (meta.warnings?.length && meta.confirm_required) {
                  // Apertura modale per richiesta conferma
                  return this.warningConfirmModalService
                    .open({ message: meta.warnings[0] })
                    .pipe(
                      switchMap((result) => {
                        // Annullamento dell'operazione da parte dell'utente
                        if (result === 'cancel') {
                          throw new Error(
                            'Invoice register warning not accepted by user',
                          );
                        }

                        // L'utente conferma, effettuo la seconda chiamata di register
                        return registerService
                          .register({
                            ...registerPayload,
                            force_operation: true,
                          })
                          .pipe(
                            // Secondo tentativo di register in success, fine del flusso
                            map(({ data: response }) => {
                              registerLoading = false;
                              modal.close();
                              return response[0];
                            }),
                            // Secondo tentativo di register fallito, fine del flusso
                            catchError((error) => {
                              registerLoading = false;
                              modal.close();
                              return throwError(error);
                            }),
                          );
                      }),
                    );
                }

                registerLoading = false;
                // Primo tentativo di register in success, fine del flusso
                modal.close();
                return of(data[0]);
              }),
            ),
          ),
          // Primo tentativo di register fallito, fine del flusso
          catchError((error) => {
            registerLoading = false;
            modal.close();
            return throwError(error);
          }),
        );
      }),
    );
  }

  /**
   * @description apre la modale per la selezione della stampante fiscale
   */
  protected getFiscalPrinter(
    invoice: InvoiceDetails,
    layout: IInvoiceLayout,
    propertiesIds?: number[],
  ): Observable<{
    printer: FiscalPrinter;
    printer_id: number;
    property_id: number;
    clousure_number?: number;
    invoice_date?: Date;
    number?: string;
    manual_fp_register?: number;
  }> {
    const modal = this.modalService.create({
      nzTitle: upperFirst(this.translate.instant('fiscal_printers')),
      nzContent: PrintReceiptModalComponent,
      nzData: {
        fiscalPrinters: layout.fiscalprinters,
        propertiesIds,
        invoice,
      },
    });

    return modal.afterClose.asObservable();
  }

  /**
   * @returns TRUE when there aren't warnings/errors
   * @returns FALSE when there are warnings/errors
   */
  protected receiptRegisterChecks(params: ChecksParams): string {
    const { invoice, layout, invoiceCashOut } = params;

    if (!layout) {
      return 'invoice_layout_not_found_warning';
    }

    if (invoice.vat_payment && invoice.vat_payment !== 'I') {
      return 'no_receipt_without_immediate_payment';
    }

    const usedAdvances = getUsedAdvances(params);

    if (usedAdvances.some(({ type }) => type === 'invoice')) {
      return 'receipt_advance_warning';
    }

    return null;
  }

  /**
   * @returns TRUE when there aren't warnings/errors
   * @returns FALSE when there are warnings/errors
   */
  protected invoiceRegisterChecks(params: ChecksParams): string {
    const { layout } = params;

    if (!layout) {
      return 'invoice_layout_not_found_warning';
    }

    const usedAdvances = getUsedAdvances(params);

    if (usedAdvances.some(({ type }) => type === 'receipt')) {
      return 'invoice_advance_warning';
    }

    return null;
  }

  /**
   * @description ritorna l'intersezione tra le strutture date in input
   * e quelle associate al profilo di fatturazione
   */
  protected getPropertiesLayoutIntersection(
    properties: number[],
    invoiceLayout: IInvoiceLayout,
  ): number[] {
    return intersection(
      properties,
      invoiceLayout.properties_layouts?.map(({ property_id }) => property_id),
    );
  }

  /**
   * @description crea un loading di registrazione
   */
  protected createRegisterLoading() {
    return this.message.loading(
      upperFirst(this.translate.instant('register_loading_message')),
      { nzDuration: 0 },
    );
  }

  /**
   * @description elimina un loading di registrazione
   */
  protected removeRegisterLoading(messageRef: NzMessageRef) {
    this.message.remove(messageRef.messageId);
  }

  /**
   * @description Se necessario effettua l'update dell'invoice
   */
  protected updateInvoice(params: InvoiceRegisterServiceParams) {
    const { invoice, registerService, updatePayload } = params;

    return updatePayload
      ? registerService.update(invoice.id, updatePayload)
      : of({});
  }
}
