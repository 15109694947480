import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Pipe({
  name: 'isRequired',
  pure: false,
})
export class ControlIsRequiredPipe implements PipeTransform {
  transform(control: AbstractControl) {
    return control.hasValidator(Validators.required);
  }
}
