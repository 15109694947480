import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CreateHousekeeperSettingRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HousekeeperSettingsService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(
      `properties/${propertyId}/housekeeper/default_configuration`,
    );
  }

  create(request: CreateHousekeeperSettingRequest) {
    const { property_id, ...payload } = request;

    return this.http.post(
      `properties/${property_id}/housekeeper/default_configuration`,
      payload,
    );
  }

  delete(propertyId: number, settingId: number) {
    return this.http.delete(
      `properties/${propertyId}/housekeeper/default_configuration/${settingId}`,
    );
  }
}
