import { IEmailTemplateCustom, IEmailTemplatePreview } from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IEmailTemplateCustomLabels } from '../../features/commons/email-templates/models/email-template-custom.model';

export const featureAdapter: EntityAdapter<IEmailTemplateCustom> =
  createEntityAdapter<IEmailTemplateCustom>({
    selectId: (model) => model.id,
  });
export interface State extends EntityState<IEmailTemplateCustom> {
  isLoading?: boolean;
  error?: any;
  customEmailPreview: IEmailTemplatePreview;
  allLabels: IEmailTemplateCustomLabels[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  customEmailPreview: null,
  allLabels: null,
});
