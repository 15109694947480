import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { IBreadcrumb } from './models/breadcrumb.model';

@Component({
  selector: 'by-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit {
  @Input()
  public items: IBreadcrumb[];
  @Input()
  public home: boolean;
  @Input()
  public customClass = '';
  @Input()
  public extraContent: TemplateRef<any>;

  constructor(private cookieService: CookieService) {}

  public ngOnInit() {}

  get fromMobileApp() {
    return this.cookieService.get('from_mobile_app');
  }
}
