import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { generateParamArray } from '../helpers/params-generator';

import { IResponse } from './../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class PropertiesTratmentsService {
  constructor(private _http: HttpClient) {}

  public loadPropertiesTreatments(
    propertiesIds: number[],
  ): Observable<IResponse[]> {
    return forkJoin(
      propertiesIds.map(
        (propertyId) =>
          this._http.get(
            `property/${propertyId}/treatments`,
          ) as Observable<IResponse>,
      ),
    );
  }

  loadPropertiesTreatmentsArray(propertiesIds: number[], withTrashed = false) {
    return this._http.get(
      `properties/treatments?${generateParamArray(
        'property_id',
        propertiesIds,
      )}&with_trashed=${+withTrashed}`,
    );
  }
}
