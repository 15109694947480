import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get, upperCase, upperFirst } from 'lodash';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { VatGenerateInput } from '../models';
import { PlacesService } from '../shared/places/services/places.service';
import { NotificationService } from '../ui/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class VatCodeGeneratorService {
  constructor(
    private placesService: PlacesService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  generate(
    inputData: VatGenerateInput,
    onCalculate: (value: string) => void,
  ): void {
    const { name, countryId } = inputData;

    this.placesService
      .loadPlace(countryId)
      .toPromise()
      .then(({ data: response }: IResponseSuccess) => {
        const countryCode = get(response, '[0].country_code');

        const vatCode = this.createVatCode(name, countryCode);
        onCalculate(vatCode);
      })
      .catch(() => {
        this.notifications.push({
          title: upperFirst(this.translate.instant('error')),
          type: 'error',
          content: upperFirst(this.translate.instant('vat_generation_error')),
        });
      });
  }

  createVatCode(name: string, countryCode: string): string {
    const companyName = name;
    return upperCase(countryCode + companyName)
      .replace(/\s/g, '')
      .substring(0, 28);
  }
}
