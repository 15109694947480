import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  AutomaticPaymentSummary,
  AutomaticPaymentSummaryTableauRequest,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class AutomaticPaymentsSummaryService {
  constructor(private http: HttpClient) {}

  loadFromTableau(data: AutomaticPaymentSummaryTableauRequest) {
    return this.http.post<IResponseSuccess<[AutomaticPaymentSummary[]]>>(
      `reservation/tableau/payments/expect_timetable`,
      { ...data, list_all: true },
    );
  }
  loadFromBookingEngine(booking_engine_cart_id: number) {
    return this.http.get<IResponseSuccess<[AutomaticPaymentSummary[]]>>(
      `booking_engine/cart/${booking_engine_cart_id}/payments/expect_timetable`,
    );
  }

  loadFromPriceQuotation(
    reservation_quote_id: number,
    reservation_quote_option_id: number,
  ) {
    return this.http.get<IResponseSuccess<[AutomaticPaymentSummary[]]>>(
      `reservations/quote/${reservation_quote_id}/option/${reservation_quote_option_id}/payments/expect_timetable`,
    );
  }
}
