import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { InvoicesLayoutsService } from '@app/services/invoices-layouts.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  flatMap,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';

import { WarningConfirmModalService } from '../../services/warning-confirm-modal.service';
import { MediasStoreActions } from '../medias-store';

import { GetInvoicesLayoutsResponse } from './../../models/responses/invoices-layouts-response';
import * as featureActions from './actions';

@Injectable()
export class InvoicesLayoutsStoreEffects {
  constructor(
    private dataService: InvoicesLayoutsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private translate: TranslateService,
    private notifications: NotificationService,
    private _router: Router,
    private warningModalService: WarningConfirmModalService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) => {
        const invoiceModuleIsActive =
          get(action, 'payload.invoice_module.status') || false;

        const fpModule = get(action, 'payload.fp_module') || false;

        const propertiesIds = get(action, 'payload.propertiesIds') || null;

        return this.dataService
          .load({
            property_id: propertiesIds,
            invoice_module: +invoiceModuleIsActive,
            fp_module: +fpModule,
          })
          .pipe(
            map(({ data }: GetInvoicesLayoutsResponse) => {
              return new featureActions.LoadSuccessAction({
                items: data,
                propertiesIds,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadAllRequestAction>(
        featureActions.ActionTypes.LOAD_ALL_REQUEST,
      ),
      switchMap((action: featureActions.LoadAllRequestAction) => {
        return this.dataService.load({ fp_module: 0, invoice_module: 0 }).pipe(
          map(({ data }: GetInvoicesLayoutsResponse) => {
            return new featureActions.LoadAllSuccessAction({ items: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadAllFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.LOAD_DETAILS_REQUEST),
      switchMap((action: featureActions.LoadDetailsRequestAction) =>
        this.dataService.loadDetails(action.payload.invoiceLayoutId).pipe(
          map(
            ({ data }: IResponseSuccess) =>
              new featureActions.LoadDetailsSuccessAction({
                invoiceLayout: data[0],
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadDetailsFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  loadTaxSystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.LOAD_TAX_SYSTEM_REQUEST),
      switchMap((action: featureActions.LoadTaxSystemRequestAction) =>
        this.dataService.loadTaxSystem().pipe(
          map(
            ({ data }: IResponseSuccess) =>
              new featureActions.LoadTaxSystemSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadTaxSystemFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap((action: featureActions.CreateRequestAction) => {
        const { request } = action.payload;
        return this.dataService.create(request).pipe(
          mergeMap((response: IResponseSuccess) => {
            const shouldConfirm = get(response, 'meta.confirm_required');
            const message = get(response, 'meta.warnings', 0);
            if (shouldConfirm) {
              const { request } = action.payload;
              this.warningModalService.open({
                message,
                labelAction: 'yes',
                labelDiscardAction: 'no',
                action: new featureActions.CreateRequestAction({
                  request: {
                    ...request,
                    force_operation: true,
                    force_mode: 'override',
                  },
                }),
                discardAction: new featureActions.CreateRequestAction({
                  request: {
                    ...request,
                    force_operation: true,
                    force_mode: 'bypass',
                  },
                }),
              });

              return [
                new featureActions.CreateFailureAction({
                  error: null,
                }),
              ];
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.create_success',
                  {
                    param: this.translate.instant('invoice_layout'),
                  },
                ),
                type: 'success',
              });
              return [
                new featureActions.CreateSuccessAction({
                  item: response.data[0],
                }),
                new featureActions.AttachPropertyRequestAction({
                  invoiceLayoutId: response.data[0].id,
                  properties: request.properties,
                  fileList: request.fileList,
                }),
              ];
            }
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.CreateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({
          payload: { invoice_layout_id },
        }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(invoice_layout_id).pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('invoice_layout'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.DeleteSuccessAction({
                invoice_layout_id,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap(({ payload }: featureActions.UpdateRequestAction) => {
        const { request, invoiceLayoutId } = payload;
        return this.dataService.update(invoiceLayoutId, request).pipe(
          mergeMap((response: IResponseSuccess) => {
            const { status, warnings } = response.meta;
            if (status === 'warning') {
              warnings.forEach((warning) => {
                const content = warning;

                this.notifications.push({
                  title: this.translate.instant('warning'),
                  content,
                  type: 'warning',
                });
              });
              return of(new featureActions.UpdateWarningAction());
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('invoice_layout'),
                  },
                ),
                type: 'success',
              });
              if (request.sectionals && request.sectionals.length) {
                // return [
                //   new featureActions.UpdateSuccessAction({
                //     item: response.data[0],
                //   }),
                //   new featureActions.AddSectionalRequestAction({
                //     invoiceLayoutId,
                //     data: request.sectionals,
                //     refresh: true,
                //   }),
                // ];
                return [
                  new featureActions.UpdateSuccessAction({
                    item: response.data[0],
                  }),

                  new featureActions.LoadDetailsRequestAction({
                    invoiceLayoutId,
                  }),
                ];
              }
              return [
                new featureActions.UpdateSuccessAction({
                  item: response.data[0],
                }),
              ];
            }
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.UpdateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  attachProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.ATTACH_PROPERTY_REQUEST),
      exhaustMap((action: featureActions.AttachPropertyRequestAction) => {
        const { invoiceLayoutId, properties, fileList } = action.payload;
        return this.dataService
          .attachProperties(invoiceLayoutId, properties)
          .pipe(
            mergeMap(() => {
              const actions: any[] = [
                new featureActions.AttachPropertySuccessAction(),
              ];
              if (fileList && fileList.length) {
                actions.push(
                  new MediasStoreActions.CreateRequestAction({
                    type: 'invoicelayout',
                    image: fileList[0],
                    type_id: invoiceLayoutId,
                    disableNotify: true,
                  }),
                );
              }
              this._router.navigate([
                '/configuration/invoicing/invoice-layouts',
              ]);
              return actions;
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.AttachPropertyFailureAction({ error }),
              );
            }),
          );
      }),
    ),
  );

  detachProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.DETACH_PROPERTY_REQUEST),
      exhaustMap((action: featureActions.DetachPropertyRequestAction) => {
        const { invoiceLayoutId, properties } = action.payload;
        return this.dataService
          .detachProperties(invoiceLayoutId, properties)
          .pipe(
            map(() => new featureActions.DetachPropertySuccessAction()),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.DetachPropertyFailureAction({ error }),
              );
            }),
          );
      }),
    ),
  );

  deleteSectional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.ActionTypes.DELETE_SECTIONAL_REQUEST),
      exhaustMap((action: featureActions.DeleteSectionalRequestAction) => {
        const { invoiceLayoutId, sectionalId } = action.payload;
        return this.dataService
          .deleteSectional(invoiceLayoutId, sectionalId)
          .pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('invoice_layout'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.DeleteSectionalSuccessAction();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.DeleteSectionalFailureAction({ error }),
              );
            }),
          );
      }),
    ),
  );

  addSectional$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.AddSectionalRequestAction>(
        featureActions.ActionTypes.ADD_SECTIONAL_REQUEST,
      ),
      switchMap(({ payload }: featureActions.AddSectionalRequestAction) => {
        const { invoiceLayoutId, data, refresh } = payload;
        return this.dataService.addSectional(invoiceLayoutId, data).pipe(
          map((response: IResponseSuccess) => {
            if (refresh) {
              return new featureActions.LoadDetailsRequestAction({
                invoiceLayoutId,
              });
            }
            return new featureActions.AddSectionalSuccessAction();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.AddSectionalFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  setDefaultPropertyInvoiceLayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SetDefaultPropertyDefaultLayoutRequestAction>(
        featureActions.ActionTypes.SET_DEFAULT_PROPERTY_INVOICE_LAYOUT_REQUEST,
      ),
      flatMap(
        ({
          payload,
        }: featureActions.SetDefaultPropertyDefaultLayoutRequestAction) => {
          return this.dataService.setDefaultPropertyInvoiceLayout(payload).pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('default_invoice_layouts'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.SetDefaultPropertyDefaultLayoutSuccessAction(
                payload,
              );
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.SetDefaultPropertyDefaultLayoutFailureAction(
                  {
                    error,
                  },
                ),
              );
            }),
          );
        },
      ),
    ),
  );
}
