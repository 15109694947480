export const DATE_FORMAT_MAP: { [partitioning: string]: string } = {
  yearly: 'yyyy',
  monthly: 'MMM. y',
  daily: 'dd/MM/yyyy',
};

export const DATE_FORMAT_WITH_DAY_MAP: { [partitioning: string]: string } = {
  ...DATE_FORMAT_MAP,
  daily: 'dd/MM/yyyy  EEE.',
};
