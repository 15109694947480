import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapValues } from 'lodash';
import { map } from 'rxjs/operators';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  ISplitReservation,
  TableauAvailabilities,
  TableauClosureRequest,
  TableauNote,
  TableauReservation,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class TableauService {
  constructor(private http: HttpClient) {}

  loadMapping(property_id: number[], reference_date: string) {
    return this.http.get(
      `tableau/accommodations_mapping?${generateSearchQuery({
        property_id,
        reference_date,
        with_details: 1,
      })}`,
    );
  }

  loadReservations(property_id: number[], date_from: string, date_to: string) {
    return this.http
      .get(
        `tableau/reservations?${generateSearchQuery({
          property_id,
          date_from,
          date_to,
        })}`,
      )
      .pipe(
        map((response: any) => {
          const { reservations, reservations_tableau_mapping } = response.data;

          const data = mapValues(
            reservations_tableau_mapping,
            (tableauNumbers) =>
              mapValues(tableauNumbers, (roomReservations) =>
                roomReservations.map(
                  (accommodation): Partial<TableauReservation> => {
                    const reservationData =
                      reservations[accommodation.reservation_id];

                    return {
                      reservation_id: accommodation.reservation_id,
                      roomreservation_id: accommodation.roomreservation_id,
                      reservation_accommodation_id:
                        accommodation.reservation_accommodation_id,
                      reservation_accommodation_room_id:
                        accommodation.reservation_accommodation_room_id,
                      arrival_date: accommodation.arrival_date,
                      departure_date: accommodation.departure_date,
                      child_reservation: accommodation.child_reservation,
                      checkin: accommodation.checkin,
                      checkout: accommodation.checkout,
                      guest: accommodation.guest,
                      total_adults: accommodation.total_adults,
                      total_children: accommodation.total_childrens,
                      total_price: accommodation.total_price,
                      discounted_price: accommodation.discounted_price,
                      keep_accommodation: accommodation.keep_accommodation,
                      status: reservationData.status,
                      payment_status: reservationData.payment_status,
                      tag: reservationData.tag,
                      company_id: reservationData.company_id,
                      company_name: reservationData.company_name,
                      company_logo: reservationData.company_logo,
                      channel_id: reservationData.channel_id,
                      channel_name: reservationData.channel_name,
                      booker_name: reservationData.booker_name,
                      booker_surname: reservationData.booker_surname,
                      metasearch: reservationData.metasearch,
                      booker: reservationData.booker,
                      company: reservationData.company,
                      label_name: reservationData.label_name,
                      expiration_date: reservationData.expiration_date,
                      currency_symbol: reservationData.currency_symbol,
                    };
                  },
                ),
              ),
          );

          return { ...response, data };
        }),
      );
  }

  loadQuotes(property_id: number[], date_from: string, date_to: string) {
    return this.http.get(
      `tableau/reservations/quotes?${generateSearchQuery({
        property_id,
        date_from,
        date_to,
      })}`,
    );
  }

  loadAvailabilities(
    property_id: number[],
    date_from: string,
    date_to: string,
  ) {
    return this.http
      .get(
        `tableau/availabilities?${generateSearchQuery({
          property_id,
          date_from,
          date_to,
        })}`,
      )
      .pipe(
        map((response: any) => {
          const responseData = response.data[0];
          return {
            availabilities: responseData,
            clousures: this.getClousuresData(responseData),
          };
        }),
      );
  }

  loadClousures(property_id: number[], date_from: string, date_to: string) {
    return this.http.get(
      `tableau/availabilities?${generateSearchQuery({
        property_id,
        date_from,
        date_to,
      })}`,
    );
  }

  loadNotes(property_id: number[], date_from: string, date_to: string) {
    return this.http.get(
      `tableau/notes?${generateSearchQuery({
        property_id,
        date_from,
        date_to,
      })}`,
    );
  }

  loadHousekeeperInformations(property_id: number[], reference_date: string) {
    return this.http
      .get(
        `tableau/housekeeper?${generateSearchQuery({
          property_id,
          reference_date,
        })}`,
      )
      .pipe(
        map((response: IResponseSuccess) => {
          return {
            ...response,
            data: Object.values(response.data).reduce(
              (tableauNumbers: Object, propertyTableauNumbers: Object) => ({
                ...tableauNumbers,
                ...propertyTableauNumbers,
              }),
              {},
            ),
          };
        }),
      );
  }

  createNote(note: Partial<TableauNote>) {
    const { accommodation_tableau_number_id } = note;

    return this.http.post(
      `tableau/${accommodation_tableau_number_id}/note`,
      note,
    );
  }

  updateNote(note: Partial<TableauNote>) {
    return this.http.put(`tableau/note/${note.id}`, note);
  }

  deleteNote(noteId: number) {
    return this.http.delete(`tableau/note/${noteId}`);
  }

  moveReservation(data: ISplitReservation) {
    return this.http.post(`tableau/split_reservation_accommodation`, data);
  }

  setClosure(request: TableauClosureRequest) {
    const { action, accommodation_id, ...payload } = request;

    const uri = `tableau/${accommodation_id}/close`;

    if (action === 'close') {
      return this.http.post(uri, payload);
    }

    return this.http.delete(`${uri}?${generateSearchQuery(payload)}`);
  }

  loadReservationAccommodationDetails(
    reservationId: number,
    reservationAccommodationId: number,
    referenceDate: string,
  ) {
    return this.http.get(
      `reservation/${reservationId}/accommodation/${reservationAccommodationId}/details?reference_date=${referenceDate}`,
    );
  }

  getClousuresData(responseData: TableauAvailabilities[]) {
    let data = {};

    Object.entries(responseData || {}).forEach(
      ([date, { tableau_closures }]: any[]) => {
        Object.entries(tableau_closures || {}).forEach(
          ([accommodationId, tableauNumbers]) => {
            Object.entries(tableauNumbers || {}).forEach(
              ([tableauNumberId, isClosed]) => {
                data = {
                  ...data,
                  [accommodationId]: {
                    ...data[accommodationId],
                    [tableauNumberId]: {
                      ...(data[accommodationId] &&
                        data[accommodationId][tableauNumberId]),
                      [date]: isClosed,
                    },
                  },
                };
              },
            );
          },
        );
      },
    );
    return data;
  }
}
