import { Injectable } from '@angular/core';
import { omit, uniq } from 'lodash';
import { Observable } from 'rxjs/internal/Observable';
import { from } from 'rxjs/internal/observable/from';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  FiscalPrinter,
  FiscalPrinterCheckMethods,
  FiscalPrinterCheckMethodsType,
  FiscalPrinterResponse,
  FiscalPrinterResult,
  FiscalStatusCheck,
} from '../../models';
import { FiscalPrintersService } from '../../services';

import { FiscalPrinterResolverService } from './fiscal-printer-resolver.service';

const SUCCESS_ICON_CLASS = 'fas fa-check-circle color--green';
const FAILURE_ICON_CLASS = 'fas fa-times-circle color--red';
const WARNING_ICON_CLASS = 'fas fa-exclamation-circle color--orange';

export interface FiscalPrinterCheckResult {
  checkPassed: boolean;
  results: FiscalPrinterResult[];
}

const getSuccessItem = ({ label, iconTooltip }): FiscalStatusCheck => {
  return {
    checkPassed: true,
    result: {
      label,
      iconTooltip,
      iconClass: SUCCESS_ICON_CLASS,
      error: false,
    },
  };
};

const getErrorItem = ({ label, iconTooltip }): FiscalStatusCheck => {
  return {
    checkPassed: false,
    result: {
      label,
      iconTooltip,
      iconClass: FAILURE_ICON_CLASS,
      error: true,
    },
  };
};

const getWarningItem = ({ label, iconTooltip }): FiscalStatusCheck => {
  return {
    checkPassed: true,
    result: {
      label,
      iconTooltip,
      iconClass: WARNING_ICON_CLASS,
      error: false,
    },
  };
};

const getPendingResult = (label: string): FiscalPrinterResult => {
  return {
    label,
    iconTooltip: 'impossible_to_check',
    iconClass: WARNING_ICON_CLASS,
    error: false,
  };
};

@Injectable({
  providedIn: 'root',
})
export class FiscalPrinterChecksService {
  private checkMethods: FiscalPrinterCheckMethods = {
    inactivePeriodCheck: this.inactivePeriodCheck,
    printerCheck: this.printerCheck,
  };

  private checkMethodsToSkip: FiscalPrinterCheckMethodsType[] = [];

  constructor(
    private fiscalPrinterResolverService: FiscalPrinterResolverService,
    private fiscalPrintersService: FiscalPrintersService,
  ) {}

  check(
    fiscalPrinter: FiscalPrinter,
    checkMethodsToSkip?: FiscalPrinterCheckMethodsType[],
  ): Observable<FiscalPrinterCheckResult> {
    const { device_model_id } = fiscalPrinter;

    this.checkMethodsToSkip = checkMethodsToSkip || [];

    const service =
      this.fiscalPrinterResolverService.getInstance(device_model_id);

    if (service.getCheckMethodsToSkip) {
      this.checkMethodsToSkip = uniq([
        ...this.checkMethodsToSkip,
        ...service.getCheckMethodsToSkip(),
      ]);
    }

    return from(service.getStatus(fiscalPrinter.id)).pipe(
      switchMap((status) =>
        from(service.getRTStatus(fiscalPrinter.id)).pipe(
          switchMap((rtStatus) => {
            // Invio degli status ricevuti alle API
            return this.fiscalPrintersService
              .setStatus(fiscalPrinter.id, { status, rtStatus })
              .pipe(map(() => this.getResult({ status, rtStatus })));
          }),
        ),
      ),
      catchError((httpError) =>
        of(this.getResult({ httpError, useProxy: fiscalPrinter?.use_proxy })),
      ),
    );
  }

  private getResult(
    responses: FiscalPrinterResponse,
  ): FiscalPrinterCheckResult {
    let checkPassed = true;
    const results: FiscalPrinterResult[] = [];

    const httpCheck: FiscalStatusCheck = this.httpCheck(responses);
    results.push(httpCheck.result);
    checkPassed = checkPassed && httpCheck.checkPassed;

    this.methodsToChek.forEach((method) => {
      const check: FiscalStatusCheck = method(responses);

      // Se fallisce il check http tutti gli altri check rimangono in pending
      if (!httpCheck.checkPassed) {
        results.push(getPendingResult(check.result.label));
        return;
      }

      results.push(check.result);
      checkPassed = checkPassed && check.checkPassed;
    });

    return { results, checkPassed };
  }

  // ----------- CHECKS ------------

  private httpCheck({
    httpError,
    useProxy,
    status,
  }: FiscalPrinterResponse): FiscalStatusCheck {
    if (status?.printerStatus === 'offline') {
      return getErrorItem({
        label: 'link_status',
        iconTooltip: status?.description ?? 'generic_link_print_error',
      });
    }

    if (!httpError) {
      return getSuccessItem({
        label: 'link_status',
        iconTooltip: null,
      });
    }

    if (httpError.notification) {
      const { code, title, description } = httpError.notification;
      return getErrorItem({
        label: `[${code}] ${title}`,
        iconTooltip: description,
      });
    }

    return getErrorItem({
      label: 'link_status',
      iconTooltip: useProxy ? 'fp_proxy_error' : 'fp_link_error',
    });
  }

  private inactivePeriodCheck({
    rtStatus,
  }: FiscalPrinterResponse): FiscalStatusCheck {
    if (rtStatus?.inactivePeriod === 'false') {
      return getSuccessItem({
        label: 'fp_clousure',
        iconTooltip: null,
      });
    }

    return getErrorItem({
      label: 'fp_clousure',
      iconTooltip: 'fp_clousure_error',
    });
  }

  private printerCheck({ status }: FiscalPrinterResponse): FiscalStatusCheck {
    if (status?.printerStatus === 'ok') {
      return getSuccessItem({
        label: 'printer_status',
        iconTooltip: null,
      });
    }

    if (status?.printerStatus === 'paper_running_out') {
      return getWarningItem({
        label: 'printer_status',
        iconTooltip: 'printer_paper_running_out_warning',
      });
    }

    return getErrorItem({
      label: 'printer_status',
      iconTooltip: 'printer_status_error',
    });
  }

  get methodsToChek() {
    const methodsToChekObj = omit(this.checkMethods, this.checkMethodsToSkip);
    return Object.keys(methodsToChekObj).map(
      (methodKey) => methodsToChekObj[methodKey],
    );
  }
}
