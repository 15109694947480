import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { preventRequestCancellation } from '../helpers';
import { LoginRequest, LoginResponse } from '../models';
import { CookieService } from 'ngx-cookie-service';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) {}

  login(request: LoginRequest) {
    return this.http
      .post<LoginResponse>(
        'auth/login',
        {
          ...request,
          login: request.username,
        },
        { headers: { 'x-login-token': this.getLoginToken() } },
      )
      .pipe(tap(({ loginToken }) => this.checkLoginToken(loginToken)));
  }

  logout(token: string) {
    return this.http.post<LoginResponse>(
      `${API_URL}auth/logout`,
      {},
      { headers: this.getHeaders(token) },
    );
  }

  refresh(oldToken: string, isAdmin = false): Observable<string> {
    return preventRequestCancellation(
      this.http.get<{ token: string }>(`${API_URL}auth/refresh_token`, {
        headers: this.getHeaders(oldToken),
      }),
    ).pipe(
      map(({ token }) => token),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return throwError(null);
        }

        return of(oldToken);
      }),
    );
  }

  private getHeaders(token: string) {
    return { Authorization: 'Bearer ' + token };
  }

  private checkLoginToken(loginToken: string) {
    const currentCookiesLogin = this.getLoginToken();

    if (!currentCookiesLogin) {
      this.setLoginToken(loginToken);
      return;
    }

    const currentCookiesLoginSplit = currentCookiesLogin.split(',');

    if (!currentCookiesLoginSplit.some((value) => value === loginToken)) {
      this.setLoginToken(`${currentCookiesLoginSplit.join()},${loginToken}`);
    }
  }

  private setLoginToken(token: string): void {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 100);

    this.cookieService.set(
      'login_token',
      token,
      expirationDate,
      '/',
      null,
      null, // Default Value
      null, // Default Value
    );
  }

  private getLoginToken(): string {
    return this.cookieService.get('login_token');
  }
}
