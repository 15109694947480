<nz-select
  nzShowSearch
  [nzServerSearch]="serverSearch"
  [nzLoading]="loading"
  [nzDropdownMatchSelectWidth]="false"
  [nzAllowClear]="nzAllowClear"
  [nzMode]="nzModeSelect"
  [nzMaxMultipleCount]="nzMaxMultipleCount"
  [(ngModel)]="selectedValue"
  (ngModelChange)="onChange($event); valueChange.emit($event)"
  (nzOnSearch)="onSearch($event)"
  [ngClass]="byClass"
  [ngStyle]="byStyle"
  [nzPlaceHolder]="placeholder | translate | capitalize"
  [nzDropdownMatchSelectWidth]="dropdownMatchWidth"
  [nzShowArrow]="showArrow"
  [nzSize]="size"
  [nzDisabled]="disabled"
  [nzNotFoundContent]="searchSelectNotFound"
  (nzBlur)="onTouched(); selectBlur.emit()"
>
  <ng-container *ngIf="groupedOptions; else standardOptions">
    <nz-option-group
      *ngFor="let groupName of options | keys"
      [nzLabel]="groupName | translate | upperfirst"
    >
      <nz-option
        *ngFor="let option of options[groupName]"
        [nzLabel]="option | get: labelPath"
        [nzDisabled]="option | get: disabledPath:false"
        [nzValue]="{ id: (option | get: valuePath), group: groupName }"
      ></nz-option>
    </nz-option-group>
  </ng-container>
  <ng-template #standardOptions>
    <nz-option
      *ngFor="let option of options"
      [nzHide]="
        (option | get: valuePath) === selectedValue && options?.length === 1
      "
      [nzLabel]="
        option?.surname
          ? option.name + ' ' + option?.surname
          : (option | get: labelPath)
      "
      [nzValue]="option | get: valuePath:option"
      [nzCustomContent]="!!optionTemplate"
      [nzDisabled]="option | get: disabledPath:false"
    >
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            option: option
          }
        "
      ></ng-container>
    </nz-option>
  </ng-template>
</nz-select>

<ng-template #searchSelectNotFound>
  <div class="text-align--center by-fs-25 color--azure by-mt-2">
    <i class="far fa-search"></i>
  </div>
  <div class="text-align--center color--azure by-mb-2">
    {{ 'search_for_more_results' | translate | upperfirst }}
  </div>
</ng-template>
