import { IEmailTemplateCustom, IEmailTemplatePreview } from '@app/models';
import { Action } from '@ngrx/store';
import {
  EmailTemplatesType,
  IEmailTemplateCustomLabels,
} from '../../features/commons/email-templates/models/email-template-custom.model';

export enum ActionTypes {
  LOAD_REQUEST = '[Templates Email Custom] Load Request',
  LOAD_FAILURE = '[Templates Email Custom] Load Failure',
  LOAD_SUCCESS = '[Templates Email Custom] Load Success',

  LOAD_PREVIEW_REQUEST = '[Templates Email Custom] Load Preview Request',
  LOAD_PREVIEW_FAILURE = '[Templates Email Custom] Load Preview Failure',
  LOAD_PREVIEW_SUCCESS = '[Templates Email Custom] Load Preview Success',

  SEND_REQUEST = '[Templates Email Custom] Send Request',
  SEND_FAILURE = '[Templates Email Custom] Send Failure',
  SEND_SUCCESS = '[Templates Email Custom] Send Success',

  LOAD_LABELS_REQUEST = '[Templates Email Custom] Load Labels Request',
  LOAD_LABELS_FAILURE = '[Templates Email Custom] Load Labels Failure',
  LOAD_LABELS_SUCCESS = '[Templates Email Custom] Load Labels Success',

  RESET_STATE = '[Templates Email Custom] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId?: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: IEmailTemplateCustom[] }) {}
}

export class LoadPreviewRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PREVIEW_REQUEST;
  constructor(
    public payload: {
      reservationId: number;
      emailTemplateCustomId: number;
      lang_iso_code: string;
    },
  ) {}
}

export class LoadPreviewFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PREVIEW_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadPreviewSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PREVIEW_SUCCESS;
  constructor(public payload: { data: IEmailTemplatePreview }) {}
}

export class SendRequestAction implements Action {
  readonly type = ActionTypes.SEND_REQUEST;
  constructor(
    public payload: {
      reservationId: number;
      emailTemplateCustomId: number;
      langIsoCode: string;
      onComplete?: () => void;
    },
  ) {}
}

export class SendFailureAction implements Action {
  readonly type = ActionTypes.SEND_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SendSuccessAction implements Action {
  readonly type = ActionTypes.SEND_SUCCESS;
}

export class LoadLabelsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_LABELS_REQUEST;
  constructor(
    public payload: { propertyIds: number[]; type: EmailTemplatesType },
  ) {}
}

export class LoadLabelsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_LABELS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadLabelsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_LABELS_SUCCESS;
  constructor(public payload: { labels: IEmailTemplateCustomLabels[] }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadPreviewRequestAction
  | LoadPreviewSuccessAction
  | LoadPreviewFailureAction
  | SendRequestAction
  | SendSuccessAction
  | SendFailureAction
  | LoadLabelsRequestAction
  | LoadLabelsSuccessAction
  | LoadLabelsFailureAction
  | ResetSuccessAction;
