import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';
import { TokenService } from '../core/services/token.service';
import {
  AccommodationContentApiDownloadRequest,
  AccommodationContentAPIForm,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class AccommodationContentApiService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  load(accommodationId: number) {
    return this.http.get(`accommodation/${accommodationId}/content_api`);
  }

  download(data: AccommodationContentApiDownloadRequest) {
    return this.http.get(
      `channelmanager/content_api/${data.channelId}/${
        data.propertyId
      }/accommodations?${generateParamArray(
        'accommodation_channel_id',
        data.accommodationChannelId,
      )}`,
    );
  }

  update(accommodationId: number, contentAPI: AccommodationContentAPIForm) {
    return this.http.put(
      `accommodation/${accommodationId}/content_api`,
      contentAPI,
    );
  }
}
