import { ScrollData } from '../models';

export const elementScrollTop = (data: ScrollData): void => {
  const { element, options } = data;
  if (!element) {
    return;
  }
  let defaultOptions: ScrollToOptions = {
    behavior: 'smooth',
    top: 0,
  };
  if (options) {
    defaultOptions = {
      ...defaultOptions,
      ...options,
    };
  }
  element.scrollTo(defaultOptions);
};
