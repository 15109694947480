import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_DETAILS_REQUEST:
    case ActionTypes.SEARCH_REQUEST:
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.SEARCH_SUCCESS: {
      const { items } = action.payload;
      return featureAdapter.setAll(items, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { items, pagination } = action.payload;
      return featureAdapter.setAll(items, {
        ...state,
        pagination,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.LOAD_DETAILS_FAILURE:
    case ActionTypes.SEARCH_FAILURE:
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_DETAILS_SUCCESS: {
      const { companyDetails } = action.payload;
      return {
        ...state,
        companyDetails,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.DELETE_SUCCESS: {
      return featureAdapter.removeOne(action.payload.companyId, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.CREATE_SUCCESS: {
      return featureAdapter.addOne(action.payload.item, {
        ...state,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return featureAdapter.updateOne(
        {
          id: action.payload.item.id,
          changes: action.payload.item,
        },
        {
          ...state,
          isLoading: false,
          error: null,
        },
      );
    }
    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.UPLOAD_REQUEST: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.UPLOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.UPLOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        uploadErrors: action.payload.uploadErrors,
      };
    }

    case ActionTypes.RESET_UPLOAD_ERRORS: {
      return {
        ...state,
        isLoading: false,
        uploadErrors: {},
      };
    }
    case ActionTypes.EXPORT_FILE_SUCCESS: {
      return {
        ...state,
        exportFileId: action.payload.exportFileId,
      };
    }

    case ActionTypes.RESET_REQUEST: {
      return featureAdapter.setAll([], {
        ...state,
        isLoading: false,
        error: null,
        companyDetails: null,
        exportFileId: null,
      });
    }

    default: {
      return state;
    }
  }
}
