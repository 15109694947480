export interface State {
  alredy_used: boolean;
  date_number_no_sequential: boolean;
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  alredy_used: false,
  date_number_no_sequential: false,
  isLoading: false,
  error: null,
};
