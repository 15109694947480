import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { DateFormatterService } from '../core/services/date-formatter.service';
import {
  ZdlakeFilters,
  ZdlakeSearchRequest,
  ZdlakeWidgetType,
} from '../models';

@Injectable({ providedIn: 'root' })
export class ZdlakeService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(params: ZdlakeSearchRequest) {
    return this.http.get(
      `statistics/z_data_lake/search/${params.widget}?${generateSearchQuery(
        params,
      )}`,
    );
  }

  buildZdlakeRequest(
    filtersValue: ZdlakeFilters,
    widget: ZdlakeWidgetType,
  ): Omit<ZdlakeSearchRequest, 'order'> {
    return {
      date_from: this.dateFormatter.toServerFormat(
        filtersValue.search_dates[0],
      ),
      date_to: this.dateFormatter.toServerFormat(filtersValue.search_dates[1]),
      stay_date_from: this.dateFormatter.toServerFormat(
        filtersValue.search_stay_dates[0],
      ),
      stay_date_to: this.dateFormatter.toServerFormat(
        filtersValue.search_stay_dates[1],
      ),
      group: filtersValue.group,
      widget: widget,
      radius_in_km: filtersValue.radius_in_km,
      property_id: filtersValue.property_id,
    };
  }
}
