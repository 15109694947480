import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FullscreenService {
  private fullscreen$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  active() {
    this.fullscreen$.next(true);
  }

  deactive() {
    this.fullscreen$.next(false);
  }

  get changes(): Observable<boolean> {
    return this.fullscreen$.asObservable();
  }
}
