import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { CashClosingExportRequest, CashClosingResponse } from '../models';
import { SearchParams } from '../models/objects/search-params';
import { removeNullishValues } from '../helpers';

@Injectable({ providedIn: 'root' })
export class CashClosingService {
  constructor(private http: HttpClient) {}

  load(params: SearchParams) {
    return this.http.get(
      `properties/cash_desk_closure?${generateSearchQuery(params)}`,
    ) as Observable<IResponseSuccess<CashClosingResponse>>;
  }

  export(params: CashClosingExportRequest) {
    const url = `properties/print_cash_desk_closure/view_pdf?${generateSearchQuery(
      removeNullishValues(params),
    )}`;

    if (params.export) {
      return this.http.get(url);
    }

    return this.http.get(url, { responseType: 'blob' });
  }
}
