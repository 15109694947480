import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';
import {
  CreatePolicyRequest,
  CreatePolicyResponse,
  GetAddonCategoriesResponse,
  UpdatePolicyRequest,
} from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class PoliciesService {
  constructor(private http: HttpClient) {}

  load(properties: number[]) {
    return this.http.get(
      `policy?${generateParamArray('property_id', properties)}`,
    );
  }

  loadSinglePolicy(policyId: number) {
    return this.http.get(`policy/${policyId}`);
  }

  create(request: CreatePolicyRequest) {
    return this.http.post<CreatePolicyResponse>(`policy`, request);
  }

  delete(policyId: number, properties: number[], force_operation?: 1 | 0) {
    return this.http.delete(
      `policy/${policyId}?${generateParamArray(
        'property_id',
        properties,
      )}&force_operation=${force_operation ? force_operation : 0}`,
    );
  }

  update(policyId: number, request: UpdatePolicyRequest) {
    return this.http.put(`policy/${policyId}`, request);
  }

  loadSelectGeneralPolicyType() {
    return this.http.get(`policy/conditions_types`);
  }

  loadGeneralPolicyText() {
    return this.http.get(`policy/texts`);
  }
}
