import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { removeNullishValues } from '../helpers';
import {
  BookingVacationRental,
  BookingVacationRentalMessagesResponse,
  HotelierMessage,
  HotelierMessageResponse,
  LoadBookingVacationRentalPhotoResponse,
  UpsertVacantionRentalProfileRequest,
} from '../models';
import { forkJoin } from 'rxjs';
import { omit } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class BookingVacationRentalService {
  constructor(private http: HttpClient) {}

  load(property_id: number, property_xml_id?: string) {
    return this.http.get<IResponseSuccess<BookingVacationRental>>(
      `channelmanager/booking_onboard/${property_id}/vacation/property/profile?${generateSearchQuery(
        removeNullishValues({ property_xml_id }),
      )}`,
    );
  }

  upsertProfile(data: {
    profile: Partial<UpsertVacantionRentalProfileRequest>;
    property_id: number;
    property_xml_id?: string;
  }) {
    const { profile, property_id, property_xml_id } = data;
    return this.http.post<IResponseSuccess<BookingVacationRental>>(
      `channelmanager/booking_onboard/${property_id}/vacation/property/profile?${generateSearchQuery(
        removeNullishValues({ property_xml_id }),
      )}`,
      profile,
    );
  }

  loadMessages(property_id: number, property_xml_id?: string) {
    return this.http.get<
      IResponseSuccess<BookingVacationRentalMessagesResponse>
    >(
      `channelmanager/booking_onboard/${property_id}/vacation/property/hotelier_message?${generateSearchQuery(
        removeNullishValues({ property_xml_id }),
      )}`,
    );
  }

  upsertMessage(
    message: HotelierMessage,
    property_id: number,
    property_xml_id?: string,
  ) {
    return this.http.post<IResponseSuccess<HotelierMessageResponse>>(
      `channelmanager/booking_onboard/${property_id}/vacation/property/hotelier_message?${generateSearchQuery(
        removeNullishValues({ property_xml_id }),
      )}`,
      omit(message, 'id'),
    );
  }

  deleteMessage(data: {
    message: HotelierMessage;
    property_id: number;
    property_xml_id?: string;
  }) {
    return this.http.delete<IResponseSuccess<HotelierMessageResponse>>(
      `channelmanager/booking_onboard/${
        data.property_id
      }/vacation/property/hotelier_message/${
        data.message.id
      }?${generateSearchQuery(
        removeNullishValues({ property_xml_id: data.property_xml_id }),
      )}`,
    );
  }

  deleteMessages(data: {
    messages: HotelierMessage[];
    property_id: number;
    property_xml_id?: string;
  }) {
    return forkJoin(
      data.messages.map((message) => {
        return this.deleteMessage({
          message,
          property_id: data.property_id,
          property_xml_id: data.property_xml_id,
        });
      }),
    );
  }

  upsertMessages(data: {
    property_id: number;
    property_xml_id?: string;
    messages: HotelierMessage[];
  }) {
    return forkJoin(
      data.messages.map((message) => {
        return this.upsertMessage(
          message,
          data.property_id,
          data.property_xml_id,
        );
      }),
    );
  }

  uploadPhoto(data: {
    property_id: number;
    property_xml_id?: string;
    photo: File;
  }) {
    const formData = new FormData();
    formData.append('photo', data.photo);

    return this.http.post<IResponseSuccess<{ photo_batch_id: string }>>(
      `channelmanager/booking_onboard/${
        data.property_id
      }/vacation/property/photo?${generateSearchQuery(
        removeNullishValues({ property_xml_id: data.property_xml_id }),
      )}`,
      formData,
    );
  }

  loadPhoto(data: {
    property_id: number;
    property_xml_id?: string;
    skipRepeat: boolean;
  }) {
    return this.http.get<
      IResponseSuccess<LoadBookingVacationRentalPhotoResponse>
    >(
      `channelmanager/booking_onboard/${
        data.property_id
      }/vacation/property/photo?${generateSearchQuery(
        removeNullishValues({ property_xml_id: data.property_xml_id }),
      )}`,
    );
  }

  deletePhoto(data: { property_id: number; property_xml_id?: string }) {
    return this.http.delete<
      IResponseSuccess<LoadBookingVacationRentalPhotoResponse>
    >(
      `channelmanager/booking_onboard/${
        data.property_id
      }/vacation/property/photo?${generateSearchQuery(
        removeNullishValues({ property_xml_id: data.property_xml_id }),
      )}`,
    );
  }
}
