import { CashClosingDocument, Invoice, InvoicePayment } from '../models';

export const cashClosingDocumentToInvoiceParser = (
  document: CashClosingDocument,
): Invoice => {
  const { payments } = document;
  const paymentsParsed: InvoicePayment[] = payments.reduce((rows, payment) => {
    const { rows: paymentRows } = payment;
    rows = [...rows, ...paymentRows];
    return rows;
  }, []);

  const documentParsed: Invoice = {
    ...document,
    invoice_id: document.id,
    payments: paymentsParsed,
  };
  return documentParsed;
};
