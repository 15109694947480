import { Injectable } from '@angular/core';

declare global {
  interface Window {
    smartlook: any;
  }
}

@Injectable({ providedIn: 'root' })
export class SmartlookService {
  constructor() {}
  initialize(): void {
    if (!window.smartlook) {
      (function (d) {
        var o: any = (window.smartlook = function () {
            o.api.push(arguments);
          }),
          h = d.getElementsByTagName('head')[0];

        var c = d.createElement('script');
        o.api = new Array();
        c.async = true;
        c.type = 'text/javascript';
        c.id = 'beddy-smartlook';

        c.charset = 'utf-8';
        c.src = 'https://web-sdk.smartlook.com/recorder.js';
        h.appendChild(c);
      })(document);

      window.smartlook('init', '2175106a7a237b088d05ad63e33692f96a7e640f', {
        region: 'eu',
      });
    }
  }
}
