import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { removeAllNullishValues } from '../helpers';
import { Warning } from '../models';

@Injectable({
  providedIn: 'root',
})
export class WarningsService {
  constructor(private http: HttpClient) {}

  load(withDisabled: boolean) {
    return this.http.get(
      `warnings?${generateSearchQuery(
        removeAllNullishValues({ with_disabled: withDisabled ? 1 : null }),
      )}`,
    );
  }

  create(payload: Omit<Warning, 'id'>) {
    return this.http.post('warnings', payload);
  }

  update({ id, ...payload }: Warning) {
    return this.http.put(`warnings/${id}`, payload);
  }

  delete(itemId: number) {
    return this.http.delete(`warnings/${itemId}`);
  }

  loadPropertiesWarnings(property_id: number[], date_from) {
    return this.http.get(
      `properties/warnings?${generateSearchQuery(
        removeAllNullishValues({ property_id, date_from }),
      )}`,
    );
  }

  deletePropertiesWarning(itemId: number) {
    return this.http.delete(`properties/warnings/${itemId}`);
  }
}
