import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { generateParamArray } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { ErrorHandlerService } from '../core/services/error-handler.service';
import { IstatGroup } from '../models';
import { NotificationService } from '../ui/services/notification.service';

import { IstatSettingsService } from './istat-settings.service';

@Injectable({
  providedIn: 'root',
})
export class IstatGroupsService {
  constructor(
    private http: HttpClient,
    private istatSettingsService: IstatSettingsService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
  ) {}

  load(propertyIds: number[]) {
    return this.http.get(
      `properties/istat?${generateParamArray('property_id', propertyIds)}`,
    ) as Observable<IResponseSuccess<IstatGroup[]>>;
  }

  delete(id: number) {
    return this.http.delete(`properties/istat/${id}`) as Observable<
      IResponseSuccess<IstatGroup[]>
    >;
  }

  loadDetails(id: number) {
    return this.http.get(`properties/istat/${id}`) as Observable<
      IResponseSuccess<IstatGroup>
    >;
  }

  create(group: IstatGroup) {
    return this.http.post(`properties/istat`, group) as Observable<
      IResponseSuccess<IstatGroup[]>
    >;
  }

  update(group: IstatGroup) {
    return this.http.put(`properties/istat/${group.id}`, group) as Observable<
      IResponseSuccess<IstatGroup[]>
    >;
  }

  updateAndSetCustomsFields(
    group: IstatGroup,
    agencyCustoms: Record<string, any>,
  ) {
    return this.update(group).pipe(
      concatMap(({ data }) => {
        if (!agencyCustoms) {
          return of(data);
        }

        return this.istatSettingsService
          .setCustomsFields(data[0].id, agencyCustoms)
          .pipe(map(() => data));
      }),
      catchError((error) => {
        this.errorHandler.handle(error);

        return of(error);
      }),
    );
  }

  createAndSetCustomsFields(
    group: IstatGroup,
    agencyCustoms: Record<string, any>,
  ) {
    return this.create(group).pipe(
      concatMap((response) => {
        if (!this.errorHandler.handleWarnings(response)) {
          this.notifications.createSuccess('group');

          this.router.navigate(['configuration', 'istat-groups']);
        }

        if (!agencyCustoms) {
          return of(response);
        }

        return this.istatSettingsService
          .setCustomsFields(response.data[0].id, agencyCustoms)
          .pipe(map(() => response.data));
      }),
      catchError((error) => {
        this.errorHandler.handle(error);

        return of(error);
      }),
    );
  }
}
