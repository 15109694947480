import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import * as messaging from 'firebase/messaging';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { cloudMessageMapper } from '../helpers';
import { Message } from '../models';

import { UserMeService } from './user-me.service';

type ProviderMessageDataType = Omit<Message, 'references'> & {
  references: string;
};

@Injectable({
  providedIn: 'root',
})
export class CloudMessagingService {
  private messages$ = new Subject<Message>();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private userMeService: UserMeService,
  ) {}

  init() {
    messaging.isSupported().then((isSupported) => {
      if (isSupported) {
        this.angularFireMessaging.getToken.subscribe((token) => {
          this.setUserMeToken(token);
        });

        this.angularFireMessaging.requestPermission.subscribe();

        this.angularFireMessaging.messages.subscribe((providerMessage) => {
          const dataParsed =
            providerMessage.data as unknown as ProviderMessageDataType;

          this.messages$.next(
            cloudMessageMapper({
              ...dataParsed,
              references: JSON.parse(dataParsed.references),
              user_group_id: +dataParsed.user_group_id,
            }),
          );
        });
      }
    });
  }

  destroy() {
    return from(messaging.isSupported()).pipe(
      switchMap((isSupported) => {
        if (isSupported) {
          return this.angularFireMessaging.getToken.pipe(
            take(1),
            switchMap((token) => {
              if (!token) {
                return of(null);
              }

              return this.userMeService.unsetNotificationToken(token).pipe(
                switchMap(() => {
                  return this.angularFireMessaging.deleteToken(token);
                }),
              );
            }),
            catchError(() => {
              return of(null);
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  getMessages(): Observable<Message> {
    return this.messages$.asObservable();
  }

  private setUserMeToken(token: string) {
    if (!token) {
      return;
    }

    this.userMeService.setNotificationToken(token).subscribe();
  }
}
