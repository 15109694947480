import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      return featureAdapter.setAll(action.payload.items, {
        ...state,
        isLoading: false,
        error: null,
      });
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_PREVIEW_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_PREVIEW_SUCCESS: {
      return {
        ...state,
        customEmailPreview: action.payload.data,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.SEND_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.SEND_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.SEND_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.LOAD_LABELS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_LABELS_SUCCESS: {
      return {
        ...state,
        allLabels: action.payload.labels,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_LABELS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
