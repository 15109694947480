import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SubSink } from 'subsink';

interface ZoomLevel {
  value: string | number;
  label: string;
}

@Component({
  selector: 'by-attachment-viewer-zoom-toolbar',
  templateUrl: './attachment-viewer-zoom-toolbar.component.html',
  styleUrls: ['./attachment-viewer-zoom-toolbar.component.scss'],
})
export class AttachmentViewerZoomToolbarComponent
  implements OnDestroy, OnChanges
{
  @Input()
  zoom: string | number;

  @Input()
  pageScale: number;

  @Output()
  zoomChange = new EventEmitter<number | string>();

  zoomSelectControl = new UntypedFormControl('jack');

  zoomLevels: ZoomLevel[] = [
    {
      value: 'auto',
      label: 'automatic_zoom',
    },
    {
      value: 'page-actual',
      label: 'page_actual',
    },
    {
      value: 'page-fit',
      label: 'page_fit',
    },
    {
      value: 'page-width',
      label: 'page_width',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
    {
      value: 125,
      label: '125%',
    },
    {
      value: 150,
      label: '150%',
    },
    {
      value: 200,
      label: '200%',
    },
    {
      value: 300,
      label: '300%',
    },
    {
      value: 400,
      label: '400%',
    },
  ];

  customZoomLevel: ZoomLevel;

  private subs = new SubSink();

  constructor() {
    this.zoomSelectControl.setValue(this.zoomLevels[0].value);

    this.subs.add(
      this.zoomSelectControl.valueChanges.subscribe((zoom) => {
        this.onScaleChanged(zoom);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { zoom } = changes;

    if (zoom && this.zoom) {
      this.updateUI();
    }
  }

  updateUI(): void {
    const zoomLevel = this.zoomLevels.find(({ value }) => value === this.zoom);
    if (zoomLevel) {
      this.customZoomLevel = null;
    } else {
      this.customZoomLevel = {
        label: `${this.zoom}%`,
        value: this.zoom,
      };
    }
    this.zoomSelectControl.setValue(
      zoomLevel?.value || this.customZoomLevel?.value,
      { emitEvent: false },
    );
  }

  onScaleChanged(value: string | number) {
    this.zoomChange.emit(value);
  }

  onZoomIn() {
    const zoomValue = (this.pageScale + 0.2) * 100;
    const zoomRouded = Math.round(zoomValue);
    this.onScaleChanged(zoomRouded);
  }

  onZoomOut() {
    const zoomValue = (this.pageScale - 0.2) * 100;
    const zoomRouded = Math.round(zoomValue);
    this.onScaleChanged(zoomRouded);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
