import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '@app/core/helpers/params-generator';
import { CrmSearchRequest } from '@app/models';

import { removeNullishValues } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class CrmSearchService {
  constructor(private http: HttpClient) {}

  search(request: CrmSearchRequest) {
    const { date_from, date_to, properties, check_avail_cart_id } = request;

    return this.http.post(
      `booking_engine/search?${generateSearchQuery(
        removeNullishValues({
          property_id: properties,
          date_from,
          date_to,
          check_avail_cart_id,
        }),
      )}`,
      {},
    );
  }
}
