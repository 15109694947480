import { Observable, Subject } from 'rxjs';

export class BySet<T> extends Set<T> {
  private sizeChanges$ = new Subject<number>();

  private valuesChanges$ = new Subject<T[]>();

  constructor(values?: readonly T[] | null) {
    super(values);

    this.sizeNext();

    this.valuesNext();
  }

  get sizeChange(): Observable<number> {
    return this.sizeChanges$.asObservable();
  }

  get valuesChanges(): Observable<T[]> {
    return this.valuesChanges$.asObservable();
  }

  override add(value: T): this {
    super.add(value);

    this.sizeNext();

    this.valuesNext();

    return this;
  }

  override clear(): void {
    super.clear();

    this.sizeNext();

    this.valuesNext();
  }

  override delete(value: T): boolean {
    const existsAndDeleted = super.delete(value);

    this.sizeNext();

    this.valuesNext();

    return existsAndDeleted;
  }

  sizeNext(): void {
    this.sizeChanges$.next(this.size);
  }

  valuesNext(): void {
    this.valuesChanges$.next(Array.from(this.values()));
  }

  setAll(values: T[]): void {
    this.clear();

    values?.forEach((value) => {
      this.add(value);
    });
  }
}
