import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class DailyClosureStatsService {
  constructor(private http: HttpClient) {}

  loadWidgets(payload: SearchParams) {
    return this.http.get('');
  }
}
