import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { Company } from '../../models';

export const featureAdapter: EntityAdapter<Company> =
  createEntityAdapter<Company>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<Company> {
  isLoading?: boolean;
  error?: any;
  pagination: IPagination;
  companyDetails: Company;
  uploadErrors: {
    [row: number]: string[];
  };
  exportFileId: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  pagination: null,
  companyDetails: null,
  uploadErrors: {},
  exportFileId: null,
});
