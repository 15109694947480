import { createAction, props } from '@ngrx/store';

import {
  ChangePasswordRequest,
  UpdateUserMeRequest,
  UserMe,
} from '../../models';

export const loadRequest = createAction(
  '[User Me] Load Request',
  props<{
    redirect: string;
    skipWorkspaceSelection?: boolean;
    selectedPropertyId?: number;
  }>(),
);
export const loadSuccess = createAction(
  '[User Me] Load Success',
  props<{ item: UserMe }>(),
);
export const loadFailure = createAction(
  '[User Me] Load Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[User Me] Update Request',
  props<{ request: UpdateUserMeRequest; noLoading?: boolean }>(),
);
export const updateSuccess = createAction(
  '[User Me] Update Success',
  props<{ item: UserMe }>(),
);
export const updateFailure = createAction(
  '[User Me] Update Failure',
  props<{ error: any }>(),
);

export const changePasswordRequest = createAction(
  '[User Me] Change Password Request',
  props<{ request: ChangePasswordRequest }>(),
);
export const changePasswordSuccess = createAction(
  '[User Me] Change Password Success',
);
export const changePasswordFailure = createAction(
  '[User Me] Change Password Failure',
  props<{ error: any }>(),
);

export const cleanup = createAction('[User Me] Cleanup');

export const resetState = createAction('[User Me] Reset state');
