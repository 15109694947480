import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  generateParamArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { DateFormatterService } from '../core/services/date-formatter.service';
import {
  ActiveChargesAddBillrequest,
  ActiveChargesEditBillrequest,
  IInvoiceOverride,
  InvoiceDetails,
  InvoicePrintingSessionRequest,
  RegisterInvoice,
} from '../models';
import { InvoiceReceiptXml } from '../models/objects/invoice-receipt-xml';
import { TemporaryInvoiceConfig } from '../models/objects/temporary-invoice-config';
import { RegisterInvoiceService } from '../shared/invoice/models/register-invoice-service.model';

@Injectable({
  providedIn: 'root',
})
export class TemporaryInvoiceService implements RegisterInvoiceService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  // Invoice Methods

  create(payload: TemporaryInvoiceConfig) {
    return this.http.post('invoice/temporary', payload);
  }

  load(invoiceId: number, advance_invoice_id?: number) {
    return this.http.get(
      `invoice/temporary/${invoiceId}?${
        advance_invoice_id
          ? generateSearchQuery({
              advance_invoice_id,
            })
          : ''
      }`,
    );
  }

  createInvoiceRejected(invoiceId: number) {
    return this.http.post(`invoice/rejected/${invoiceId}`, {});
  }

  update(invoiceId: number, payload: Partial<InvoiceDetails>) {
    return this.http.put<IResponseSuccess>(
      `invoice/temporary/${invoiceId}`,
      payload,
    );
  }

  delete(invoiceId: number) {
    return this.http.delete(`invoice/temporary/${invoiceId}`);
  }

  getPrinterXmlForRegister(
    invoiceId: number,
    payload: RegisterInvoice,
  ): Observable<InvoiceReceiptXml> {
    return this.http
      .post<{ data: InvoiceReceiptXml[] }>(
        `invoice/temporary/${invoiceId}/xml`,
        payload,
      )
      .pipe(map(({ data }) => data[0]));
  }

  updatePrintingSession(
    invoiceId: number,
    printSessionId: number,
    payload: InvoicePrintingSessionRequest,
  ) {
    return this.http.put<IResponseSuccess>(
      `invoice/${invoiceId}/print_session/${printSessionId}`,
      omit(payload, 'notification'),
    );
  }

  register(data: RegisterInvoice) {
    return this.http.post(
      `invoice/temporary/${data.invoice_id}/register`,
      this.dateFormatter.formatObjectDates({
        ...data,
        now: new Date(),
      }),
    ) as Observable<IResponseSuccess>;
  }

  // Invoice Rows Methods

  createRow(invoiceId: number, payload: ActiveChargesAddBillrequest) {
    return this.http.post(`invoice/temporary/${invoiceId}/bill_row`, payload);
  }

  updateRow(
    invoiceId: number,
    { bill_row_id, ...payload }: ActiveChargesEditBillrequest,
  ) {
    return this.http.put(
      `invoice/temporary/${invoiceId}/bill_row/${bill_row_id[0]}`,
      payload,
    );
  }

  deleteRows(invoiceId: number, billRowsIds: number[]) {
    return this.http.delete(
      `invoice/temporary/${invoiceId}/bills_rows?${generateParamArray(
        'charge_ids',
        billRowsIds,
      )}`,
    );
  }

  // Override Methods

  createOverride(invoiceId: number, payload: IInvoiceOverride) {
    return this.http.post(`invoice/temporary/${invoiceId}/overrides`, payload);
  }

  updateOverride(
    invoiceId: number,
    { bill_row_id, ...payload }: IInvoiceOverride,
  ) {
    return this.http.put(
      `invoice/temporary/${invoiceId}/override/${bill_row_id[0]}`,
      payload,
    );
  }

  deleteOverride(invoiceId: number, overrideId: number) {
    return this.http.delete(
      `invoice/temporary/${invoiceId}/override/${overrideId}`,
    );
  }
}
