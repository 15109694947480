import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { INotificationData } from '../models/notification-data.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationsSubject = new Subject<INotificationData>();

  constructor(private translate: TranslateService) {}

  push(notification: INotificationData) {
    this.notificationsSubject.next(notification);
  }

  notifications(): Observable<INotificationData> {
    return this.notificationsSubject.asObservable();
  }

  success(content: string, entity?: string, onClick?: () => void) {
    this.push({
      title: upperFirst(this.translate.instant('done')),
      content: upperFirst(
        this.translate.instant(content, {
          param: entity ? this.translate.instant(entity) : '',
        }),
      ),
      type: 'success',
      onClick,
    });
  }

  error(content: string) {
    this.push({
      title: upperFirst(this.translate.instant('error')),
      content: upperFirst(this.translate.instant(content)),
      type: 'error',
    });
  }

  warning(content: string, entity?: string, onClick?: () => void) {
    this.push({
      title: upperFirst(this.translate.instant('warning')),
      content: upperFirst(
        this.translate.instant(content, {
          param: entity ? this.translate.instant(entity) : '',
        }),
      ),
      type: 'warning',
      onClick,
    });
  }

  createSuccess(entity?: string) {
    this.success('notifications.create_success', entity);
  }

  updateSuccess(entity?: string) {
    this.success('notifications.update_success', entity);
  }

  deleteSuccess(entity?: string) {
    this.success('notifications.delete_success', entity);
  }

  done(message: string) {
    this.push({
      title: this.translate.instant('done'),
      content: this.translate.instant(message),
      type: 'success',
    });
  }
}
