import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'formatDate',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  constructor() {}

  transform(
    value: string | Date,
    format = 'DD/MM/YYYY',
    valueFormat?: string,
  ): string {
    if (!value) {
      return null;
    }

    const date = moment(value, valueFormat || null).toDate();

    if (!moment(date).isValid()) {
      return value as any;
    }

    return moment(date).format(format);
  }
}
