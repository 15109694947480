import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  generateFormDataFromObject,
  generateParamArray,
  generateSearchQuery,
} from '../core/helpers/params-generator';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  CheckMessageReadStatusResponse,
  CheckMessageStatusRequest,
  CreateExtraPhoneRequest,
  DeleteMessageRequest,
  GetConversationRequest,
  LoadReservationMessageParticipantsRequest,
  LoadReservationMessagesRequest,
  MessageCustomersLookupRequest,
  MessageReplyNeededRequest,
  ResendReservationMessageRequest,
  ReservationMessage,
  ReservationMessageParticipant,
  ReservationMessageUpdated,
  SendReservationMessageRequest,
  SetMessagesReadStatusRequest,
} from '../models';
import { ReservationMessagesStatistic } from '../models/objects/reservation-messages-statistics';

@Injectable({ providedIn: 'root' })
export class ReservationMessagesService {
  constructor(private http: HttpClient) {}

  load(data: LoadReservationMessagesRequest) {
    const { conversable_id, conversable_type, params } = data;

    return this.http.get(
      `conversations/${conversable_type}/${conversable_id}/messages?${generateSearchQuery(
        params,
      )}`,
    ) as Observable<IResponseSuccess<ReservationMessage[]>>;
  }

  loadStatistics(reservationId: number) {
    return this.http.get(
      `conversations/reservation/${reservationId}/stats`,
    ) as Observable<IResponseSuccess<ReservationMessagesStatistic[]>>;
  }

  resend(data: ResendReservationMessageRequest) {
    const { conversable_id, conversable_type, conversation_message_id } = data;

    return this.http.post(
      `conversations/${conversable_type}/${conversable_id}/messages/${conversation_message_id}/resend`,
      { conversable_id, conversable_type, conversation_message_id },
    ) as Observable<IResponseSuccess<ReservationMessage[]>>;
  }

  send(data: SendReservationMessageRequest) {
    const { conversable_id, conversable_type, lang } = data;

    let url = `conversations/${conversable_type}/${conversable_id}/messages`;

    if (lang) {
      url = `${url}?lang=${lang}`;
    }

    const formData = generateFormDataFromObject(data);

    return this.http.post(url, formData) as Observable<
      IResponseSuccess<ReservationMessage[]>
    >;
  }

  loadParticipants(data: LoadReservationMessageParticipantsRequest) {
    const { conversable_id, conversable_type } = data;

    return this.http.get(
      `conversations/${conversable_type}/${conversable_id}/participants`,
    ) as Observable<IResponseSuccess<ReservationMessageParticipant[]>>;
  }

  checkStatus(data: CheckMessageStatusRequest) {
    return this.http.get(
      `conversations/${data.conversable_type}/${
        data.conversable_id
      }/messages/status?${generateParamArray(
        'conversation_message_ids',
        data.conversation_message_ids,
      )}`,
    ) as Observable<IResponseSuccess<ReservationMessageUpdated[]>>;
  }

  messageCustomersLookup(data: MessageCustomersLookupRequest) {
    return this.http.get(
      `conversations/${data.conversable_type}/${
        data.conversable_id
      }/participants/customers/lookup?${generateSearchQuery(data)}`,
    );
  }

  deleteMessage(data: DeleteMessageRequest) {
    return this.http.delete(
      `conversations/${data.conversable_type}/${data.conversable_id}/messages/${data.id}`,
    );
  }

  replyNeeded(data: MessageReplyNeededRequest) {
    return this.http.put(
      `conversations/${data.conversable_type}/${data.conversable_id}/messages/${data.id}/reply_needed`,
      data,
    );
  }

  setMessagesReadStatus(data: SetMessagesReadStatusRequest) {
    const { conversable_id, conversable_type } = data;

    return this.http.post(
      `conversations/${conversable_type}/${conversable_id}/messages/read`,
      data,
    ) as Observable<IResponseSuccess<CheckMessageReadStatusResponse[]>>;
  }

  getUnreadReceivedMessagesCount(data: GetConversationRequest) {
    const { conversable_id, conversable_type } = data;

    return this.http.get(
      `conversations/${conversable_type}/${conversable_id}/messages/unread/count`,
    ) as Observable<IResponseSuccess<{ count: number }>>;
  }

  createExtraPhone(data: CreateExtraPhoneRequest) {
    return this.http.post('properties/extra-phone-numbers', data) as Observable<
      IResponseSuccess<ReservationMessageParticipant>
    >;
  }
}
