import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { GetEmailTemplateCustomPreviewResponse } from '@app/models/responses/get-email-template-custom-preview';
import { GetEmailTemplateCustomResponse } from '@app/models/responses/get-email-template-custom-response';
import { EmailTemplateCustomService } from '@app/services/email-templates-custom.service';
import { NotificationService } from '@app/ui/services/notification.service';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';

import * as featureActions from './actions';

@Injectable()
export class EmailTemplatesCustomStoreEffects {
  constructor(
    private dataService: EmailTemplateCustomService,
    private actions$: Actions,
    private _store: Store<{ core: ICoreState }>,
    private translate: TranslateService,
    private notifications: NotificationService,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      withLatestFrom(this._store),
      switchMap(
        ([action, { core }]: [
          featureActions.LoadRequestAction,
          { core: ICoreState },
        ]) => {
          let propertyId = core.selectedProperty.id;
          if (action.payload.propertyId) {
            propertyId = action.payload.propertyId;
          }

          return this.dataService.load(propertyId).pipe(
            map(({ data }: GetEmailTemplateCustomResponse) => {
              return new featureActions.LoadSuccessAction({
                items: data,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  loadMultiplePropertiesRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadLabelsRequestAction>(
        featureActions.ActionTypes.LOAD_LABELS_REQUEST,
      ),
      switchMap((action) => {
        const propertiesIds = action.payload.propertyIds;

        const type = action.payload.type;

        return this.dataService.loadLabels(propertiesIds, type).pipe(
          map(({ data }) => {
            return new featureActions.LoadLabelsSuccessAction({
              labels: data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadLabelsFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadPreviewEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadPreviewRequestAction>(
        featureActions.ActionTypes.LOAD_PREVIEW_REQUEST,
      ),
      switchMap((action: featureActions.LoadPreviewRequestAction) => {
        const { emailTemplateCustomId, reservationId, lang_iso_code } =
          action.payload;
        return this.dataService
          .loadPreview(reservationId, emailTemplateCustomId, lang_iso_code)
          .pipe(
            map(({ data }: GetEmailTemplateCustomPreviewResponse) => {
              return new featureActions.LoadPreviewSuccessAction({
                data,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.LoadPreviewFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  sendEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SendRequestAction>(
        featureActions.ActionTypes.SEND_REQUEST,
      ),
      switchMap((action: featureActions.SendRequestAction) => {
        const {
          emailTemplateCustomId,
          reservationId,
          langIsoCode,
          onComplete,
        } = action.payload;
        return this.dataService
          .send(reservationId, emailTemplateCustomId, langIsoCode)
          .pipe(
            map(({ data }: any) => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.action_success',
                  {
                    param: this.translate.instant('sending'),
                  },
                ),
                type: 'success',
              });
              if (onComplete) {
                onComplete();
              }
              return new featureActions.SendSuccessAction();
            }),
            catchError((error) => {
              if (onComplete) {
                onComplete();
              }
              this.errorHandler.handle(error);
              return of(new featureActions.SendFailureAction({ error }));
            }),
          );
      }),
    ),
  );
}
