<div class="by-attachment-viewer">
  <div
    class="by-attachment-viewer__content"
    [class.by-attachment-viewer__content--image]="isImage"
  >
    <by-spin [nzSpinning]="loading"> </by-spin>
    <ng-container *ngIf="error">
      <nz-result nzStatus="500" [nzTitle]="'server_error' | translate">
      </nz-result>
    </ng-container>

    <ng-container *ngIf="type === 'pdf'">
      <ngx-extended-pdf-viewer
        class="ngx-extended-pdf-viewer-custom"
        [customToolbar]="customToolBar"
        [showFindButton]="false"
        [textLayer]="true"
        [language]="lang"
        [(zoom)]="zoom"
        [src]="blob"
        [height]="height"
        [mobileFriendlyZoom]="isMobile ? '130%' : '100%'"
        (updateFindState)="setFindState($event)"
        [(page)]="page"
        (pagesLoaded)="onSetPageLoaded($event)"
        (currentZoomFactor)="onSetPageScale($event)"
      ></ngx-extended-pdf-viewer>
    </ng-container>

    <ng-template #customToolBar>
      <by-attachment-viewer-tool-bar
        [findState]="findState"
        [totalPages]="totalPages"
        [page]="page"
        [zoom]="zoom"
        [printData]="printData"
        [src]="src"
        [title]="title"
        [pageScale]="pageScale"
        (pageChange)="onPageChange($event)"
        (zoomChange)="onSetZoom($event)"
      ></by-attachment-viewer-tool-bar>
    </ng-template>

    <ng-container *ngIf="isImage">
      <div class="by-attachment-viewer__img">
        <img [src]="src" />
      </div>
    </ng-container>
  </div>
</div>
