import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { IInvoiceNextNumber } from '@app/features/commons/reservation-details/models/reservation.charges.model';
import {
  Invoice,
  InvoiceDetails,
  InvoiceTypes,
  StatsHeaderWidget,
  StatusSdiInfo,
} from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<Invoice> =
  createEntityAdapter<Invoice>({
    selectId: (model) => model.invoice_id,
  });

export interface State extends EntityState<Invoice> {
  isLoading?: boolean;
  error?: any;
  pagination?: IPagination;
  statusSdiInfo?: StatusSdiInfo;
  emailTemplateData?: any;
  invoiceDetails: InvoiceDetails;
  invoiceTotals: { total: number; to_pay: number; paid: number };
  usingHolidayVoucher: boolean;
  widgets: StatsHeaderWidget[];
  widgetsLoading: boolean;
  invoiceNextNumber: {
    [invoice_layout_id: number]: IInvoiceNextNumber;
  };
  loadingNextNumber: boolean;
  registerLoading: InvoiceTypes;
  saving: boolean;
  exportFileId: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  pagination: null,
  statusSdiInfo: null,
  emailTemplateData: null,
  invoiceDetails: null,
  invoiceTotals: null,
  usingHolidayVoucher: null,
  widgets: null,
  widgetsLoading: false,
  invoiceNextNumber: null,
  loadingNextNumber: null,
  registerLoading: null,
  saving: false,
  exportFileId: null,
});
