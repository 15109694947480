import { Injectable } from '@angular/core';
import { uniqueId } from 'lodash';
import { fromEvent, interval, merge, of, throwError } from 'rxjs';
import { filter, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

const MAX_CONNECTION_TIME = 60;

interface ProxyRequest {
  labels?: { title?: string; description?: string };
  requests: Array<{
    method: 'post' | 'get';
    url: string;
    payload: any;
    config?: {
      headers?: Record<string, string>;
    };
  }>;
}

interface ProxyResponse {
  isBeddyProxy: boolean;
  status: 'failure' | 'success';
  data: any;
  proxyRequestId: string;
}

@Injectable({
  providedIn: 'root',
})
export class HttpProxyService {
  send(request: ProxyRequest) {
    const connectionId = uniqueId();

    const popup = window.open(
      environment.httpProxy,
      `Beddy Proxy #${connectionId}`,
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=10,height=10,left=0,top=5000',
    );

    const requestSubscription = interval(1000).subscribe(() => {
      if (!popup?.postMessage) {
        return;
      }
      popup.postMessage({ isBeddyProxy: true, request }, '*');
    });

    let time = 0;

    const timer$ = interval(1000).pipe(
      tap(() => time++),
      filter(() => time > MAX_CONNECTION_TIME || popup.closed),
      mapTo(null),
    );

    const response$ = fromEvent<MessageEvent<ProxyResponse>>(
      window,
      'message',
    ).pipe(
      filter(
        ({ data, source }) => !!data && data.isBeddyProxy && popup === source,
      ),
      map(({ data }) => data),
    );

    const logID = `[Beddy Proxy] Connection #${connectionId}`;

    // tslint:disable-next-line: no-console
    console.time(logID);

    return merge(timer$, response$).pipe(
      take(1),
      switchMap((response: ProxyResponse) => {
        popup.close();

        requestSubscription.unsubscribe();

        // tslint:disable-next-line: no-console
        console.timeLog(logID, request, response);

        if (!response) {
          return throwError('[Beddy Proxy] Connection Timeout');
        }

        if (response.status === 'failure') {
          return throwError(response.data);
        }

        return of(response.data);
      }),
    );
  }
}
