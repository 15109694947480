import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  constructor(private http: HttpClient) {}

  load(userId: number) {
    return this.http.get(`user_preferences/?user_id=${userId}`);
  }

  update(preferences: any) {
    return this.http.post('user_preferences', preferences);
  }
}
