import { IReservationTag } from '@app/models/objects/reservation-tag';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectReservationTagState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('reservationTag');

export const selectAllReservationTagItems: (
  state: object,
) => IReservationTag[] = featureAdapter.getSelectors(selectReservationTagState)
  .selectAll;

export const selectReservationTagById = (id: string) =>
  createSelector(
    selectAllReservationTagItems,
    (allReservationTag: IReservationTag[]) => {
      if (allReservationTag) {
        return allReservationTag.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectReservationTagError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectReservationTagState,
  getError,
);

export const selectReservationTagIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectReservationTagState,
  getIsLoading,
);
