import { EstimateRateplan } from '../../models';
import { EstimateResponse } from '../../models/responses/estimate.response';

export interface State {
  estimate: EstimateResponse;
  rateplans: EstimateRateplan[];
  estimating: boolean;
  fetchingRateplans: boolean;
  error: any;
}

export const initialState: State = {
  estimate: null,
  rateplans: null,
  estimating: false,
  fetchingRateplans: false,
  error: null,
};
