import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { Observable } from 'rxjs';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { ReservationReason } from '../models';
import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class ReservationReasonsService {
  constructor(private http: HttpClient) {}

  load(searchParams: SearchParams) {
    return this.http.get(
      `properties/reservations/reasons?${generateSearchQuery(searchParams)}`,
    ) as Observable<IResponseSuccess<ReservationReason[]>>;
  }

  create(item: Omit<ReservationReason, 'id'>) {
    return this.http.post(
      `properties/reservations/reasons`,
      item,
    ) as Observable<IResponseSuccess<ReservationReason[]>>;
  }

  update(item: ReservationReason) {
    return this.http.put(
      `properties/reservations/reasons/${item.id}`,
      item,
    ) as Observable<IResponseSuccess<ReservationReason[]>>;
  }

  delete(id: number) {
    return this.http.delete(
      `properties/reservations/reasons/${id}`,
    ) as Observable<IResponseSuccess>;
  }
}
