import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { removeNullishValues } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class ReservationCancellationPolicyService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  loadCalendar(params: {
    reservation_id: number;
    reference_date?: Date;
    reservation_accommodation_id?: number[];
  }) {
    const { reservation_id, ...searchQueryData } = params;

    const searchQuery = `${generateSearchQuery(
      this.dateFormatter.formatObjectDates(
        removeNullishValues(searchQueryData),
      ),
    )}`;

    return this.http.get(
      `reservation/${reservation_id}/cancellation_policy/calendar?${searchQuery}`,
    );
  }

  loadExtras(
    reservationId: number,
    days: string[],
    reservation_accommodation_id: number[],
  ) {
    const params = `${generateSearchQuery({
      reservation_accommodation_id,
      days,
    })}`;

    return this.http.get(
      `reservation/${reservationId}/cancellation_policy/addons?${params}`,
    );
  }
}
