import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateParamArray,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import { QuoteRequestsStatsWidgetRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class QuoteRequestsStatsService {
  constructor(private http: HttpClient) {}

  load(properties: number[]) {
    return this.http.get(
      `quote_requests/stats?${generateParamArray('property_id', properties)}`,
    );
  }

  loadWidget(request: QuoteRequestsStatsWidgetRequest) {
    return this.http.get(
      `statistics/quote_request_conversion?${generateSearchQuery(request)}`,
    );
  }
}
