import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class QuoteRequestsService {
  constructor(private http: HttpClient) {}

  index(propertiesIds: number[], params: SearchParams) {
    return this.http.get(
      `quote_requests?${generateSearchQuery({
        ...params,
        property_id: propertiesIds,
      })}`,
    );
  }

  show(quoteRequestId: number) {
    return this.http.get(`quote_request/${quoteRequestId}`);
  }

  setArchived(quoteRequestId: number, archive: boolean) {
    return this.http.post(`quote_request/${quoteRequestId}/archive`, {
      archive,
    });
  }
}
