import { IReservationTagRequest } from '@app/models/objects/reservation-tag';
import { Action } from '@ngrx/store';

import { IReservationTag } from './../../models/objects/reservation-tag';

export enum ActionTypes {
  LOAD_REQUEST = '[Reservation Tag] Load Request',
  LOAD_FAILURE = '[Reservation Tag] Load Failure',
  LOAD_SUCCESS = '[Reservation Tag] Load Success',

  CREATE_REQUEST = '[Reservation Tag] Create Request',
  CREATE_FAILURE = '[Reservation Tag] Create Failure',
  CREATE_SUCCESS = '[Reservation Tag] Create Success',

  DELETE_REQUEST = '[Reservation Tag] Delete Request',
  DELETE_FAILURE = '[Reservation Tag] Delete Failure',
  DELETE_SUCCESS = '[Reservation Tag] Delete Success',

  UPDATE_REQUEST = '[Reservation Tag] Update Request',
  UPDATE_FAILURE = '[Reservation Tag] Update Failure',
  UPDATE_SUCCESS = '[Reservation Tag] Update Success',

  ATTACH_REQUEST = '[Reservation Tag] Attach Request',
  ATTACH_FAILURE = '[Reservation Tag] Attach Failure',
  ATTACH_SUCCESS = '[Reservation Tag] Attach Success',

  RESET_STATE = '[Reservation Tag] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertiesIDS: number[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: IReservationTag[] }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(public payload: { request: IReservationTagRequest }) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { item: IReservationTag }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(
    public payload: { reservation_tag_id: number; force_operation?: number },
  ) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { id: number }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: { request: IReservationTagRequest; reload?: boolean },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { item: IReservationTag }) {}
}
export class AttachRequestAction implements Action {
  readonly type = ActionTypes.ATTACH_REQUEST;
  constructor(
    public payload: {
      request: {
        reservation_id: number;
        reservation_tag_id: number;
        tagData?: IReservationTag;
        noUpdate?: boolean;
      };
    },
  ) {}
}

export class AttachFailureAction implements Action {
  readonly type = ActionTypes.ATTACH_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AttachSuccessAction implements Action {
  readonly type = ActionTypes.ATTACH_SUCCESS;
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | AttachRequestAction
  | AttachFailureAction
  | AttachSuccessAction
  | ResetSuccessAction;
