import {
  AfterContentInit,
  Directive,
  EventEmitter,
  Output,
} from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[after-if]' })
export class AfterIfDirective implements AfterContentInit {
  @Output('after-if')
  after: EventEmitter<AfterIfDirective> = new EventEmitter();

  public ngAfterContentInit(): void {
    setTimeout(() => {
      // timeout helps prevent unexpected change errors
      this.after.emit();
    });
  }
}
