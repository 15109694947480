import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import {
  AgencyDetails,
  FormField,
  AgencySettings,
  IstatFilteredProperties,
} from '../models';
import { generateParamArray } from '../core/helpers/params-generator';
import { IResponse } from '../core/models/response.model';

@Injectable({
  providedIn: 'root',
})
export class IstatSettingsService {
  constructor(private http: HttpClient) {}

  loadAgencyConfigurationSettingsFields(agency_id: number) {
    return this.http.get(
      `properties/modules/guests_reporting/agency/${agency_id}/options/specifications`,
    ) as Observable<IResponseSuccess<FormField[]>>;
  }

  loadAgencyOptionsSpecifications(agency_id: number) {
    return this.http.get(
      `properties/modules/guests_reporting/agency/${agency_id}/options/customs/specifications`,
    ) as Observable<IResponseSuccess<FormField[]>>;
  }

  loadAgencyConfigurationSettingsExtraFields(
    agency_id: number,
    fields: { [field: string]: any },
  ) {
    return this.http.post(
      `properties/modules/guests_reporting/agency/${agency_id}/options/extra/specifications`,
      fields,
    ) as Observable<IResponseSuccess<FormField[]>>;
  }

  loadAgencies() {
    return this.http.get(`properties/modules/guests_reporting/agencies`);
  }

  loadAgencyDetails(groupId: number) {
    return this.http.get(`property/${groupId}/reporting/details`) as Observable<
      IResponseSuccess<AgencyDetails>
    >;
  }

  loadAssociatedAgency(propertyId: number) {
    return this.http.get(`property/${propertyId}/modules/reporting/agency`);
  }

  agencyAttach(propertyId: number, agency_id: number) {
    return this.http.post(`property/${propertyId}/modules/reporting/agency`, {
      agency_id,
    });
  }

  sendAgencyData(propertyId: number, data: AgencySettings) {
    return this.http.post(`property/${propertyId}/modules/reporting/agency`, {
      ...data,
    });
  }

  setCustomsFields(groupId: number, fields: Record<string, any>) {
    return this.http.put(
      `property/${groupId}/reporting/customs`,
      fields,
    ) as Observable<IResponseSuccess<AgencyDetails>>;
  }

  loadFilteredAccommodations(propertiesIds: number[]) {
    return this.http.get<IResponseSuccess<IstatFilteredProperties[]>>(
      `accommodations_filtered/lookup?${generateParamArray('property_ids', propertiesIds)}`,
    );
  }
}
