import { isAfter, isBefore, isEqual } from 'date-fns';
import { dateToServerFormat } from './date-to-server-format';

export class ByDateHelpers {
  public static isBetween(
    date: Date,
    range: { start: Date; end: Date },
    isEquals?: [boolean, boolean],
  ): boolean {
    if (!isEquals) {
      return isAfter(date, range.start) && isBefore(date, range.end);
    }

    const conditionStart = isEquals[0]
      ? isAfter(date, range.start) || isEqual(date, range.start)
      : isAfter(date, range.start);

    const conditionEnd = isEquals[1]
      ? isBefore(date, range.end) || isEqual(date, range.end)
      : isBefore(date, range.end);

    return conditionStart && conditionEnd;
  }

  public static toServerFormat(date: Date | string) {
    return dateToServerFormat(date);
  }
}
