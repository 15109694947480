import { MonoTypeOperatorFunction, pipe, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export type Hook<T = any, U = void> = (hookData?: T) => U;

export interface Hooks<T = any> {
  onSuccess?: Hook<T>;
  onFailure?: Hook<Error>;
}

export function effectHooks<T = any>(
  hooks?: Hooks<T>,
): MonoTypeOperatorFunction<T> {
  return pipe(
    tap((data) => hooks?.onSuccess && hooks.onSuccess(data)),
    catchError((error) => {
      if (hooks?.onFailure) {
        hooks.onFailure(error);
      }

      return throwError(() => error);
    }),
  );
}
