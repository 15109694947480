import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  eachMonthOfInterval,
  eachYearOfInterval,
  lastDayOfMonth,
  lastDayOfYear,
} from 'date-fns';
import { uniqBy } from 'lodash';
import { forkJoin, Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  retry,
  skip,
  takeUntil,
} from 'rxjs/operators';

import { generateParamArray } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { observableSequence } from '../helpers';
import {
  AccommodationLookup,
  Channel,
  ExportWidgetData,
  Rateplan,
  ReservationFrom,
  ReservationReason,
  StatsRequest,
  StatsResponse,
} from '../models';
import { RootState } from '../root-store/root-state';
import { StatsStoreSelectors } from '../root-store/stats-store';

import { AccommodationsService } from './accommodations.service';
import { ChannelsService } from './channels.service';
import { CustomersService } from './customers.service';
import { ExportService } from './export.service';
import { RateplansService } from './rateplans.service';
import { ReservationFromsService } from './reservation-froms.service';
import { ReservationReasonsService } from './reservation-reasons.service';
import { ExportFileRequestSuccess } from '../models/types/export-file-request';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  interruptCalls$ = new Subject<void>();

  constructor(
    public http: HttpClient,
    public exportService: ExportService,
    public channelsService: ChannelsService,
    public accommodationsService: AccommodationsService,
    public rateplansService: RateplansService,
    public reservationFromsService: ReservationFromsService,
    public reservationReasonsService: ReservationReasonsService,
    public customersService: CustomersService,
    public translateService: TranslateService,
    private dateFormatter: DateFormatterService,
    public store: Store<RootState>,
  ) {}

  loadAccommodationTableauNumber(data: Partial<StatsRequest>[]) {
    return forkJoin(
      data.map((statsToSend) => {
        return this.http
          .post('statistics/revenue', statsToSend)
          .pipe(retry(3), debounceTime(500), takeUntil(this.interruptCalls$));
      }),
    );
  }

  loadFilters(propertyIds: number[]) {
    const filtersServices = [
      this.reservationReasonsService.load({
        property_id: propertyIds,
      }),
      this.channelsService.loadActive(propertyIds, {
        with_accommodation: 0,
        with_trashed: 1,
        allow_statistics: 1,
      }),
      this.accommodationsService.loadLookup(propertyIds, true),
      this.rateplansService.load(propertyIds, true),
      this.reservationFromsService.load(),
      this.customersService.loadCustomerCategories(),
    ];
    return forkJoin(filtersServices).pipe(
      map(
        ([
          reservationReasonsResponse,
          channelsResponse,
          accommodationsResponse,
          ratePlansResponse,
          reservationFromsResponse,
          customerCategoriesResponse,
        ]: [
          IResponseSuccess<ReservationReason[]>,
          Channel[],
          IResponseSuccess<AccommodationLookup>,
          IResponseSuccess<Rateplan[]>,
          IResponseSuccess<ReservationFrom[]>,
          IResponseSuccess<{ id: number; name: string }[]>,
        ]) => {
          const { data: reservationReasons } = reservationReasonsResponse;
          const { data: accommodations } = accommodationsResponse;
          const { data: ratePlans } = ratePlansResponse;
          const { data: reservationFroms } = reservationFromsResponse;
          const { data: customerCategories } = customerCategoriesResponse;

          return {
            reservationReasons: reservationReasons,
            channels: this.channelByProperty(channelsResponse),
            accommodations: this.accommodationByProperty(accommodations || {}),
            ratePlans,
            reservationFroms,
            customerCategories: [
              ...customerCategories,
              { id: null, name: 'not_specified' },
            ],
          };
        },
      ),
    );
  }

  accommodationByProperty(
    accommodations: AccommodationLookup,
  ): AccommodationLookup {
    return Object.keys(accommodations).reduce((acc, propertyId) => {
      acc = {
        ...acc,
        [propertyId]: [
          ...accommodations[propertyId].map((accommodation) => {
            return {
              ...accommodation,
              propertyId: +propertyId,
            };
          }),
        ],
      };
      return acc;
    }, {});
  }

  channelByProperty(channels: Channel[]): { [propertyId: number]: Channel[] } {
    return (channels || []).reduce((acc, channel) => {
      const { properties } = channel;
      properties.forEach((propertyId) => {
        if (!acc[+propertyId]) {
          acc = {
            ...acc,
            [+propertyId]: [],
          };
        }
        acc = {
          ...acc,
          [+propertyId]: [...uniqBy([...acc[+propertyId], channel], 'id')],
        };
      });
      return acc;
    }, {});
  }

  load(data: Partial<StatsRequest>) {
    return (
      this.http.post('statistics/production_over_time', data) as Observable<
        IResponseSuccess<StatsResponse>
      >
    )
      .pipe(
        map((resp) => ({
          ...resp,
          data: {
            ...resp.data,
            reservation_date_from: data.reservation_date_from,
            reservation_date_to: data.reservation_date_to,
          },
        })),
        catchError((err) => {
          return throwError(() => err);
        }),
      )
      .pipe(takeUntil(this.interruptCalls$)) as Observable<
      IResponseSuccess<StatsResponse>
    >;
  }

  loadMinDate(properties: number[]) {
    const url = `statistics/oldest_date?${generateParamArray(
      'property_id',
      properties,
    )}`;
    return this.http.get(url);
  }

  buildPeriods(
    start: Date,
    end: Date,
    granularity: 'year' | 'month',
  ): { date_from: string; date_to: string }[] {
    let periods: Date[];

    if (granularity === 'year') {
      periods = eachYearOfInterval({
        start,
        end: new Date(end),
      });
    }

    if (granularity === 'month') {
      periods = eachMonthOfInterval({
        start,
        end: new Date(end),
      });
    }

    return periods.map((firstDayOfperiod, index) => {
      let date_from = firstDayOfperiod;

      let date_to: Date;

      if (granularity === 'year') {
        date_to = lastDayOfYear(firstDayOfperiod);
      }

      if (granularity === 'month') {
        date_to = lastDayOfMonth(firstDayOfperiod);
      }

      if (index === 0) {
        date_from = start;
      }

      if (index === periods.length - 1) {
        date_to = new Date(end);
      }

      return {
        date_from: this.dateFormatter.toServerFormat(date_from),
        date_to: this.dateFormatter.toServerFormat(date_to),
      };
    });
  }

  getSplittedPeriods(
    data: Partial<StatsRequest>,
  ): { date_from: string; date_to: string }[] {
    const start = new Date(data.date_from);

    const granularity: 'year' | 'month' =
      data.partitioning === 'yearly' ? 'year' : 'month';

    return this.buildPeriods(start, new Date(data?.date_to), granularity);
  }

  splitRequest(
    data: Partial<StatsRequest>,
    callBack: (
      data: IResponseSuccess<StatsResponse>[],
      observables: Observable<IResponseSuccess<StatsResponse>>[],
    ) => void,
    translations: {
      currentYear: string;
      lastYear: string;
      total: string;
    },
  ) {
    const dataWithReservationDates = this.dataWithReservationDates(data);

    const periods = this.getSplittedPeriods(data);

    const callsSplitted = periods.map(({ date_from, date_to }) => {
      const requestSplitted = {
        ...dataWithReservationDates,
        date_from,
        date_to,
      };

      const call = this.load(requestSplitted).pipe(
        takeUntil(this.interruptCalls$),
      );

      return call;
    });

    return observableSequence(callsSplitted, callBack);
  }

  export(exportData: ExportWidgetData, data: Partial<StatsRequest>) {
    return this.http.post<IResponseSuccess<ExportFileRequestSuccess>>(
      'statistics/revenue/export',
      {
        ...this.dataWithReservationDates(data),
        export_format: exportData.format,
        export_columns: exportData.columns,
        service: 1, //11/01/2024 HOTFIX REMOVE THIS VALUE ON THE NEXT RELEASE
      },
    );
  }

  dataWithReservationDates(data: Partial<StatsRequest>): Partial<StatsRequest> {
    const reservation_date_from = new Date(data.date_from);

    const reservation_date_to = new Date(data.date_to);

    return {
      ...data,
      reservation_date_from: this.dateFormatter.toServerFormat(
        reservation_date_from,
      ),
      reservation_date_to:
        this.dateFormatter.toServerFormat(reservation_date_to),
    };
  }
}
