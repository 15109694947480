import { Action } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { Company, ExportFormat, SearchCompanyRequest } from '../../models';
import { ExportFileRequestType } from '../../models/types/export-file-request';

export enum ActionTypes {
  LOAD_REQUEST = '[Companies] Load Request',
  LOAD_FAILURE = '[Companies] Load Failure',
  LOAD_SUCCESS = '[Companies] Load Success',

  LOAD_DETAILS_REQUEST = '[Companies] Load Details Request',
  LOAD_DETAILS_FAILURE = '[Companies] Load Details Failure',
  LOAD_DETAILS_SUCCESS = '[Companies] Load Details Success',

  CREATE_REQUEST = '[Companies] Create Request',
  CREATE_FAILURE = '[Companies] Create Failure',
  CREATE_SUCCESS = '[Companies] Create Success',

  UPDATE_REQUEST = '[Companies] Update Request',
  UPDATE_FAILURE = '[Companies] Update Failure',
  UPDATE_SUCCESS = '[Companies] Update Success',

  SEARCH_REQUEST = '[Companies] Search Request',
  SEARCH_FAILURE = '[Companies] Search Failure',
  SEARCH_SUCCESS = '[Companies] Search Success',

  DELETE_REQUEST = '[Companies] Delete Request',
  DELETE_FAILURE = '[Companies] Delete Failure',
  DELETE_SUCCESS = '[Companies] Delete Success',

  UPLOAD_REQUEST = '[Companies] Upload Request',
  UPLOAD_FAILURE = '[Companies] Upload Failure',
  UPLOAD_SUCCESS = '[Companies] Upload Success',

  RESET_UPLOAD_ERRORS = '[Companies] Reset Upload Errors Request',

  RESET_REQUEST = '[Companies] Reset Request',

  EXPORT_FILE_REQUEST = '[Companies] Export File',
  EXPORT_FILE_SUCCESS = '[Companies] Export File Success',
  EXPORT_FILE_ERROR = '[Companies] Export File Error',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(
    public payload: {
      properties: number[];
      params?: {
        [param: string]: string | number;
      };
    },
  ) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: Company[]; pagination: IPagination }) {}
}

export class LoadDetailsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_REQUEST;
  constructor(
    public payload: {
      companyId: number;
    },
  ) {}
}

export class LoadDetailsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadDetailsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_SUCCESS;
  constructor(public payload: { companyDetails: Company }) {}
}

export class SearchRequestAction implements Action {
  readonly type = ActionTypes.SEARCH_REQUEST;
  constructor(public payload: SearchCompanyRequest) {}
}

export class SearchFailureAction implements Action {
  readonly type = ActionTypes.SEARCH_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SearchSuccessAction implements Action {
  readonly type = ActionTypes.SEARCH_SUCCESS;
  constructor(public payload: { items: Company[] }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { companyId: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { companyId: number }) {}
}
export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: {
      request: any;
      onComplete?: (company: Company) => void;
      onFailure?: () => void;
      disableNotification?: boolean;
      force_operation?: boolean;
    },
  ) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { item: any }) {}
}
export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      request: any;
      onSuccess?: () => void;
      force_operation?: boolean;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { item: any }) {}
}

export class UploadRequestAction implements Action {
  readonly type = ActionTypes.UPLOAD_REQUEST;
  constructor(
    public payload: {
      properties: number[];
      file: File;
      selectedDateFormat: string;
      onSuccess: () => void;
      onError: () => void;
    },
  ) {}
}

export class UploadFailureAction implements Action {
  readonly type = ActionTypes.UPLOAD_FAILURE;
  constructor(
    public payload: {
      error: string;
      uploadErrors: { [row: string]: string[] };
    },
  ) {}
}

export class UploadSuccessAction implements Action {
  readonly type = ActionTypes.UPLOAD_SUCCESS;
}

export class ResetUploadErrorsRequest implements Action {
  readonly type = ActionTypes.RESET_UPLOAD_ERRORS;
}
export class ResetRequest implements Action {
  readonly type = ActionTypes.RESET_REQUEST;
}

export class ExportFileRequest implements Action {
  readonly type = ActionTypes.EXPORT_FILE_REQUEST;
  constructor(public payload: ExportFileRequestType) {}
}
export class ExportFileSucces implements Action {
  readonly type = ActionTypes.EXPORT_FILE_SUCCESS;
  constructor(public payload: { exportFileId: number }) {}
}
export class ExportFileError implements Action {
  readonly type = ActionTypes.EXPORT_FILE_ERROR;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadDetailsRequestAction
  | LoadDetailsFailureAction
  | LoadDetailsSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | SearchRequestAction
  | SearchFailureAction
  | SearchSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UploadRequestAction
  | UploadFailureAction
  | UploadSuccessAction
  | ResetUploadErrorsRequest
  | ResetRequest
  | ExportFileSucces;
