import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateParamArray } from '../core/helpers/params-generator';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    let url = `general/payment_methods/generic`;
    if (propertyId) {
      url = `general/payment_methods/generic?property_id=${propertyId}`;
    }
    return this.http.get(url);
  }

  loadPropertiesPaymentMethods(properties: number[]) {
    return this.http.get(
      `properties/payment_methods/generic?${generateParamArray(
        'property_id',
        properties,
      )}`,
    );
  }
}
